import React from 'react'
import { Circle, H5, Icon, MoreBar, Status, Text, Token } from '@revolut/ui-kit'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { meetingsTemplatesFormRequests } from '@src/api/meetings'
import { MeetingTemplateEditStep } from '../interfaces'

interface Props {
  data?: MeetingTemplateInterface
  isLoading: boolean
  onEdit: (step: MeetingTemplateEditStep, onSaveGoBack: boolean) => void
}

export const PreviewHeader = ({ data, isLoading, onEdit }: Props) => {
  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const handleDelete = () => {
    confirmationPopup.show({
      showHeader: false,
      body: (
        <H5 color={Token.color.foreground}>
          Are you sure you want to delete this template?
        </H5>
      ),
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        if (data?.id) {
          await meetingsTemplatesFormRequests.delete?.(data, { id: data.id.toString() })
          navigateTo(ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES)
          showStatusPopup({
            title: 'Template deleted',
            status: 'success',
          })
        }
      },
    })
  }

  return (
    <PageHeader
      isLoading={isLoading}
      title={
        <PageHeader.Title
          avatar={
            <Circle bg={Token.color.greyTone10} size={52}>
              <Icon name="Statement" size={32} />
            </Circle>
          }
          labels={
            data?.created_by ? (
              <Status useIcon="Profile">{data.created_by.display_name}</Status>
            ) : undefined
          }
          title={data?.name}
          actions={
            <MoreBar maxCount={1}>
              <MoreBar.Action
                onClick={() => onEdit('general', false)}
                use="button"
                useIcon={<Icon size={16} name="Pencil" />}
              >
                Edit template
              </MoreBar.Action>
              <MoreBar.Action
                onClick={handleDelete}
                use="button"
                useIcon={<Icon color={Token.color.danger} size={16} name="Delete" />}
              >
                <Text color={Token.color.danger}>Delete template</Text>
              </MoreBar.Action>
            </MoreBar>
          }
        />
      }
      backUrl={ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES}
    />
  )
}
