import React, { useState } from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { Avatar, Button, Item, Subheader, VStack } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { JobPostingFlowParams } from '@src/features/JobPostingFlow/types'
import { HiringStagesList } from '@src/features/JobPostingFlow/HiringProcess/HiringStagesList'
import { PageActions } from '@components/Page/PageActions'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { SetDefaultHiringProcessPopup } from '@src/features/JobPostingFlow/components/SetDefaultHiringProcessPopup'
import { useGetJobPostingHiringProcess } from '@src/api/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetSpecialisationHiringStages } from '@src/api/hiringProcess'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetSpecialisation } from '@src/api/specialisations'

interface HiringProcessProps {
  onAfterSubmit: VoidFunction
}

export const HiringProcess = ({ onAfterSubmit }: HiringProcessProps) => {
  const permissions = useSelector(selectPermissions)
  const params = useParams<JobPostingFlowParams>()
  const formValidator = useFormValidator()
  const { values, submit } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingHiringProcess, isLoading: isJobPostingHiringProcessLoading } =
    useGetJobPostingHiringProcess(values.id)

  const canChangeSpecialisation = permissions.includes(
    PermissionTypes.ChangeSpecialisation,
  )

  const { data: specialisation, isLoading: isLoadingSpecialisation } =
    useGetSpecialisation(values.specialisation?.id)

  const {
    data: specialisationHiringProcess,
    isLoading: isSpecialisationHiringProcessLoading,
  } = useGetSpecialisationHiringStages(values.specialisation?.id)
  const [showDefaultHiringProcessPopup, setShowDefaultHiringProcessPopup] =
    useState(false)

  const isLoading =
    isJobPostingHiringProcessLoading ||
    isLoadingSpecialisation ||
    isSpecialisationHiringProcessLoading

  const onSubmit = () =>
    formValidator?.validate ? formValidator.validate(submit) : submit

  const onProceed = async () => {
    await onSubmit()
    onAfterSubmit()
    navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.PUBLISH, params))
  }

  return (
    <>
      <PageBody>
        <VStack gap="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="BulkSelection" />
            </Item.Avatar>
            <Item.Content>
              <Item.Description>
                This is your default hiring process template. You can add, remove and
                modify stages. Any changes you make will only apply to this job posting
              </Item.Description>
            </Item.Content>
          </Item>
          <Subheader>
            <Subheader.Title>Hiring stages</Subheader.Title>
          </Subheader>
          <HiringStagesList
            isHiringProcessLocked={!!specialisation?.is_hiring_process_locked}
          />
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params))
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>

        <Button
          elevated
          disabled={isLoading}
          onClick={() => {
            if (
              jobPostingHiringProcess?.results?.length &&
              !specialisationHiringProcess?.results.length &&
              canChangeSpecialisation
            ) {
              setShowDefaultHiringProcessPopup(true)
              return
            }

            onProceed()
          }}
        >
          Next
        </Button>

        {showDefaultHiringProcessPopup &&
          !!jobPostingHiringProcess?.results?.length &&
          values.specialisation && (
            <SetDefaultHiringProcessPopup
              specialisationId={Number(values.specialisation.id)}
              jobPostingHiringProcess={jobPostingHiringProcess.results}
              onClose={() => setShowDefaultHiringProcessPopup(false)}
              onSuccess={onProceed}
            />
          )}
      </PageActions>
    </>
  )
}
