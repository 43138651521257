import React from 'react'
import {
  DistributionTooltip,
  DistributionTooltipData,
} from '@src/features/ReviewCycle/Analytics/Charts/components/DistributionTooltip'
import {
  NipsHistoryDataKey,
  NipsHistoryItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/NipsBarChart/ChartData'
import { NIPS_COLOR } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/hooks/useChartData'

export const Tooltip = (
  props: NipsHistoryItemData,
  dataKeys?: NipsHistoryDataKey[],
  colors?: string[],
) => {
  const data = (dataKeys ?? []).map<DistributionTooltipData>((dataKey, index) => {
    const { nips } = props.rawData

    return {
      count: undefined,
      percentage: nips,
      ...dataKey,
      color: colors ? colors[index] : NIPS_COLOR,
    }
  })

  return <DistributionTooltip title={props.label} data={data} />
}
