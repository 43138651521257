import React, { useMemo } from 'react'
import { Avatar, Banner, useToggle } from '@revolut/ui-kit'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { ReviewVisibility } from '@src/interfaces/settings'
import { ReviewCategory, ReviewerRelation } from '@src/interfaces/performance'

const visibilityContentMap: Record<ReviewVisibility, { description: string }> = {
  [ReviewVisibility.RevieweeAndManagers]: {
    description:
      'Only the reviewee and their managers will have access to your feedback.',
  },
  [ReviewVisibility.Managers]: {
    description: "Only the reviewee's managers will have access to your feedback.",
  },
  [ReviewVisibility.ReviewerSet]: {
    description:
      'You can choose to share your review with the reviewee and their managers or just their managers.',
  },
}

interface Props {
  category: ReviewCategory | undefined
  reviewerRelation: ReviewerRelation | undefined
}

export const ScorecardVisibilityBanner = ({ category, reviewerRelation }: Props) => {
  const { data: performanceSettings } = useGetPerformanceSettings()
  const [isVisible, setIsVisible] = useToggle({ defaultState: true })

  const visibilitySetting = useMemo(() => {
    if (category === ReviewCategory.Upwards) {
      return performanceSettings?.upwards_reviews_visibility?.id
    }
    if (
      category === ReviewCategory.Performance &&
      reviewerRelation === ReviewerRelation.Other
    ) {
      return performanceSettings?.peer_reviews_visibility?.id
    }
    return undefined
  }, [category, reviewerRelation, performanceSettings])

  if (!category || !visibilitySetting || !isVisible) {
    return null
  }

  return (
    <Banner labelButtonClear="Clear visibility banner" onClear={setIsVisible.off}>
      <Banner.Avatar>
        <Avatar useIcon="EyeHide" />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>Your review is confidential</Banner.Title>
        <Banner.Description>
          {visibilityContentMap[visibilitySetting].description}
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
