import React from 'react'
import { Box, Flex, Token } from '@revolut/ui-kit'
import { Text } from '@src/pages/Landing/components/Text'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'

interface Props {
  label: string
  tools: string[]
}

export const ToolsUsed = ({ label, tools }: Props) => {
  return (
    <Box bg="#161618">
      <ContentBlockLayout>
        <Text
          use="h4"
          variant="h4"
          textAlign="center"
          color={Token.color.greyTone50}
          pb="s-48"
        >
          {label}
        </Text>
        <Flex flexDirection={{ md: 'row', all: 'column' }} gap="s-24">
          {tools.map(tool => (
            <Flex
              bg="#000"
              flex={1}
              radius={20}
              p="s-20"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              key={tool}
            >
              {tool}
            </Flex>
          ))}
        </Flex>
      </ContentBlockLayout>
    </Box>
  )
}
