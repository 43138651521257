import { useTable, UseTableOptions } from '@components/TableV2/hooks'
import { kpisRequests } from '@src/api/kpis'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiParentColumn,
  kpiPerformanceColumn,
  kpiStatusColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { rowHighlight } from '@src/features/KPI'
import { Statuses } from '@src/interfaces'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import { KpiInterface } from '@src/interfaces/kpis'

export const ROW: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 400,
    },
    {
      ...kpiWeightColumn,
      width: 110,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
    {
      ...kpiInitialValueColumn,
      width: 90,
    },
    {
      ...kpiCurrentValueColumn,
      width: 90,
    },
    {
      ...kpiTargetColumn,
      width: 90,
    },
    {
      ...kpiUnitColumn,
      width: 90,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...kpiParentColumn,
      width: 80,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 120,
    },
    {
      ...kpiStatusColumn,
      width: 80,
    },
  ],
}

export const usePersonalKPITable = (
  data: Pick<EmployeeInterface, 'id'>,
  options?: UseTableOptions,
  filters: FilterByInterface[] = [],
  skipStatusFilter?: boolean,
  goalsEnabled?: boolean,
) => {
  const initialFilter = [
    {
      columnName: 'goals_related',
      filters: [
        { id: goalsEnabled ? 'true' : 'false', name: goalsEnabled ? 'true' : 'false' },
      ],
      nonResettable: true,
    },
    ...(skipStatusFilter
      ? []
      : [
          {
            filters: [
              { name: Statuses.active, id: Statuses.active },
              { name: Statuses.draft, id: Statuses.draft },
              { name: 'new', id: 'new' },
            ],
            columnName: 'status',
          },
        ]),
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_employee',
      nonResettable: true,
    },
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'owner__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.future, id: Statuses.future },
        { name: Statuses.completed, id: Statuses.completed },
      ],
      columnName: 'target_status',
      nonResettable: true,
    },
    ...filters,
  ]

  return useTable<KpiInterface>(kpisRequests, initialFilter, [], options)
}
