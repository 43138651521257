import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import {
  Checkbox,
  CheckboxGroup,
  Text,
  VStack,
  Widget,
  Token,
  chain,
} from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { integrationNameMap } from '@src/pages/Hub/Integrations/components/constants'
import { MergeConnectionInterface } from '@src/interfaces/integrations'
import {
  useGetMergeConnections,
  useUpdateMergeConnection,
  useInvalidateMergeConnections,
} from '@src/api/integrations'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import PageLoading from '@components/PageLoading/PageLoading'
import { connect } from 'lape'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack } from '@src/actions/RouterActions'

interface IntegrationSettingsProps {
  onSuccess?: (values: MergeConnectionInterface) => void
  useNextButton?: boolean
}

const IntegrationSettingsForm = ({
  onSuccess,
  useNextButton,
}: IntegrationSettingsProps) => {
  const { values } = useLapeContext<MergeConnectionInterface>()
  const invalidateConnections = useInvalidateMergeConnections()

  const integrationName =
    integrationNameMap[values.integration_type] || values.integration_type

  const afterSubmit = async () => {
    await invalidateConnections()
    onSuccess ? onSuccess?.(values) : goBack()
  }

  return (
    <PageWrapper>
      <PageHeader
        title={`${integrationName} integration settings`}
        subtitle={`Select fields and employee statuses in ${integrationName} to sync with Revolut People`}
        backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
      />
      <PageBody>
        <VStack gap="s-16">
          <Widget p="s-16">
            <Text pb="s-16" use="div" color={Token.color.greyTone50}>
              {chain(
                'Sync employees with statuses',
                `${values?.sync_all_statuses ? 2 : 1} of 2`,
              )}
            </Text>

            <VStack space="s-32">
              <Checkbox disabled checked>
                <Checkbox.Label>Active</Checkbox.Label>
                <Checkbox.Description>Required</Checkbox.Description>
              </Checkbox>
              <Checkbox
                checked={Boolean(values.sync_all_statuses)}
                onChange={e => {
                  values.sync_all_statuses = e.currentTarget.checked
                }}
              >
                <Checkbox.Label>Inactive</Checkbox.Label>
              </Checkbox>
            </VStack>
          </Widget>
          <Widget p="s-16">
            <Text pb="s-16" use="div" color={Token.color.greyTone50}>
              {chain(
                'Sync employee fields',
                `${values?.selected_fields.length} of ${values?.available_fields.length}`,
              )}
            </Text>
            <CheckboxGroup
              onChange={value => {
                values.selected_fields = values.available_fields
                  .filter(field => value.includes(field.id))
                  .map(({ id, name }) => ({
                    id,
                    name,
                  }))
              }}
              value={values?.selected_fields.map(field => field.id)}
            >
              {group => (
                <VStack space="s-32">
                  {values?.available_fields.map(field => (
                    <Checkbox
                      {...group.getInputProps({ value: field.id })}
                      disabled={field.required}
                      key={field.id}
                    >
                      <Checkbox.Label>{field.name}</Checkbox.Label>
                      {field.required ? (
                        <Checkbox.Description>Required</Checkbox.Description>
                      ) : null}
                    </Checkbox>
                  ))}
                </VStack>
              )}
            </CheckboxGroup>
          </Widget>
        </VStack>
      </PageBody>
      <PageActions>
        {useNextButton ? (
          <NewSaveButtonWithPopup
            useValidator
            onAfterSubmit={afterSubmit}
            noPopup
            hideWhenNoChanges={false}
          >
            Continue
          </NewSaveButtonWithPopup>
        ) : (
          <NewSaveButtonWithPopup
            successText="Settings updated successfully"
            useValidator
            onAfterSubmit={afterSubmit}
          />
        )}
      </PageActions>
    </PageWrapper>
  )
}

export const IntegrationSettings = connect(
  ({ onSuccess, useNextButton }: IntegrationSettingsProps) => {
    const { id } = useParams<{ id: string }>() // integration type
    const { data: integrations, isLoading } = useGetMergeConnections()
    const { mutateAsync: update } = useUpdateMergeConnection()

    const handleSubmit = async (integration: MergeConnectionInterface) => {
      return update([
        integration?.id,
        {
          selected_fields: integration.selected_fields,
          sync_all_statuses: integration.sync_all_statuses,
        },
      ]).then(resp => resp.data)
    }

    if (isLoading) {
      return <PageLoading />
    }

    const currentIntegration = integrations?.results?.find(
      int => int.integration_type === id,
    )

    if (!currentIntegration) {
      return <PageNotFoundWidget />
    }

    return (
      <LapeForm<MergeConnectionInterface>
        initialValues={currentIntegration}
        onSubmit={({ values }) => handleSubmit(values)}
      >
        <IntegrationSettingsForm onSuccess={onSuccess} useNextButton={useNextButton} />
      </LapeForm>
    )
  },
)
