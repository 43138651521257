import React from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@components/Page/PageBody'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OfficeSuiteEnableButton } from '@src/pages/Hub/Integrations/components/OfficeSuiteEnableButton'

export const GoogleCommercial = () => {
  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Google Workspace"
          subtitle="Collaboration"
          backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        >
          <OfficeSuiteEnableButton provider="google" />
        </PageHeader>
        <PageBody mb="s-64" mt="s-16">
          <OnboardingIntro videoPosterUrl="/integrations/Integration%20Google.png">
            <OnboardingIntroTextWidget
              title={
                <FormattedMessage
                  id="integrations.google.intro.title"
                  defaultMessage="About integration"
                />
              }
            >
              <p>
                <FormattedMessage
                  id="integrations.google.intro.text"
                  defaultMessage="Use Revolut People integration with Google Calendar and Gmail. Your employees will manage calendar events, send and receive emails directly in Revolut People."
                />
              </p>
            </OnboardingIntroTextWidget>
            <OnboardingIntroTips
              title={
                <FormattedMessage
                  id="integrations.google.intro.tips.title"
                  defaultMessage="How to use"
                />
              }
              items={[
                {
                  title: (
                    <FormattedMessage
                      id="integrations.google.intro.tips.sync.title"
                      defaultMessage="Automatically synchronise employee’s approved time-offs to their Google Calendar"
                    />
                  ),
                  icon: 'Profile',
                  text: (
                    <FormattedMessage
                      id="integrations.google.intro.tips.sync.text"
                      defaultMessage="Employees can connect their calendar to synchronise time-off events"
                    />
                  ),
                },
                {
                  title: (
                    <FormattedMessage
                      id="integrations.google.intro.tips.schedule.title"
                      defaultMessage="Schedule interviews with candidates without leaving Revolut People"
                    />
                  ),
                  icon: 'AddContact',
                  text: (
                    <FormattedMessage
                      id="integrations.google.intro.tips.schedule.text"
                      defaultMessage="Connect your Google Calendar and Gmail to schedule interviews directly from candidate profiles."
                    />
                  ),
                },
                {
                  title: (
                    <FormattedMessage
                      id="integrations.google.intro.tips.email.title"
                      defaultMessage="Send emails to the candidates from Revolut people and synchronise them with Gmail"
                    />
                  ),
                  icon: 'Envelope',
                  text: (
                    <FormattedMessage
                      id="integrations.google.intro.tips.email.text"
                      defaultMessage="Connect your work Gmail account to send emails and receive replies invites directly in candidate profiles."
                    />
                  ),
                },
              ]}
            />
          </OnboardingIntro>
        </PageBody>
      </PageWrapper>
    </>
  )
}
