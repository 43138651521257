import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { BookingLinksTable } from '@src/features/BookingLinks/Recruiter/BookingLinksTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useParams } from 'react-router-dom'

export const BookingLinks = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams()
  return (
    <PageWrapper>
      <PageHeader
        title="Booking Links"
        backUrl={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params)}
      />
      <PageBody maxWidth="100%">
        <BookingLinksTable jobPosting={values} />
      </PageBody>
    </PageWrapper>
  )
}
