import { noop } from 'lodash'
import {
  EligibilityValidationError,
  EligibleEmployeesInterface,
  ValidationErrorType,
} from '@src/interfaces/supportTool/eligibleEmployees'
import { IconName } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InvalidEligibilityItemProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/components/EligibilityList/ListItems'
import { GetRequestInterface } from '@src/interfaces'
import { useOpenNewTab } from '@src/actions/RouterActions'

export const useAdaptInvalidEligibilityObjects = (
  pages: GetRequestInterface<EligibleEmployeesInterface>[],
): InvalidEligibilityItemProps[] => {
  const openInNewTab = useOpenNewTab()

  return pages
    .reduce<EligibleEmployeesInterface[]>((eligibleEmployees, { results }) => {
      return [...eligibleEmployees, ...results]
    }, [])
    .reduce<InvalidEligibilityItemProps[]>((errors, eligibilityObject) => {
      const { id, validation_errors } = eligibilityObject

      return [
        ...errors,
        ...(validation_errors ?? []).map<InvalidEligibilityItemProps>(validationError => {
          const {
            error: errorMessage,
            error_type: errorType,
            object_id: errorObjectId,
          } = validationError
          const titleLinkGetter = errorTypeToTitleLinkGetter[errorType]
          const actionLinkGetter = errorTypeToActionLinkGetter[errorType] ?? noop
          const titleGetter =
            errorTypeToTitleGetter[errorType] ?? unknownErrorTypeLabelGetter
          const descriptionGetter =
            errorTypeToDescriptionGetter[errorType] ?? unknownErrorTypeLabelGetter

          return {
            key: `${id}:${errorMessage}`,
            title: titleGetter(validationError, eligibilityObject),
            description: descriptionGetter(validationError),
            iconName: errorTypeToIconNameMap[errorType],
            actionLabel: errorTypeToActionLabelMap[errorType],
            titleLink: titleLinkGetter ? titleLinkGetter(eligibilityObject) : undefined,
            handleActionClick: () =>
              openInNewTab(actionLinkGetter(errorObjectId, eligibilityObject)),
          }
        }),
      ]
    }, [])
}

export const errorTypeToIconNameMap: Record<ValidationErrorType, IconName> = {
  [ValidationErrorType.ManagerDeactivated]: 'Profile',
  [ValidationErrorType.NoSeniority]: 'RepairTool',
  [ValidationErrorType.NoSpecialisation]: 'RepairTool',
  [ValidationErrorType.NoSkills]: 'RepairTool',
  [ValidationErrorType.NoSkillsDistribution]: 'RepairTool',
}

export const errorTypeToActionLabelMap: Record<ValidationErrorType, string> = {
  [ValidationErrorType.ManagerDeactivated]: 'Assign new manager',
  [ValidationErrorType.NoSeniority]: 'Set seniority',
  [ValidationErrorType.NoSpecialisation]: 'Set specialisation',
  [ValidationErrorType.NoSkills]: 'Set skills',
  [ValidationErrorType.NoSkillsDistribution]: 'Set skills distribution',
}

const employeeProfilePath = ROUTES.FORMS.EMPLOYEE.PROFILE
const competencyMatrixPath = ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX
const editEligibleEmployeePath = ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.GENERAL

type LinkCreatorFunction = (
  id: number | null,
  eligibilityObject: EligibleEmployeesInterface,
) => string

const getEmployeeProfilePath = (id: string | number): string =>
  pathToUrl(employeeProfilePath, { id })
const getCompetencyMatrixPath: LinkCreatorFunction = id =>
  pathToUrl(competencyMatrixPath, { id })
const getEditEligibleEmployeePath: LinkCreatorFunction = (_id, eligibilityObject) =>
  pathToUrl(editEligibleEmployeePath, { id: eligibilityObject.id })

const errorTypeToActionLinkGetter: Record<ValidationErrorType, LinkCreatorFunction> = {
  [ValidationErrorType.ManagerDeactivated]: (id, eligibilityObject) =>
    getEditEligibleEmployeePath(id, eligibilityObject),
  [ValidationErrorType.NoSeniority]: (id, eligibilityObject) =>
    getEditEligibleEmployeePath(id, eligibilityObject),
  [ValidationErrorType.NoSpecialisation]: (id, eligibilityObject) =>
    getEditEligibleEmployeePath(id, eligibilityObject),
  [ValidationErrorType.NoSkills]: (id, eligibilityObject) =>
    getCompetencyMatrixPath(id, eligibilityObject),
  [ValidationErrorType.NoSkillsDistribution]: (id, eligibilityObject) =>
    getCompetencyMatrixPath(id, eligibilityObject),
}

const errorTypeToTitleLinkGetter: Partial<
  Record<ValidationErrorType, (eligibilityObject: EligibleEmployeesInterface) => string>
> = {
  [ValidationErrorType.ManagerDeactivated]: (
    eligibilityObject: EligibleEmployeesInterface,
  ) => getEmployeeProfilePath(eligibilityObject.employee.id),
  [ValidationErrorType.NoSeniority]: (eligibilityObject: EligibleEmployeesInterface) =>
    getEmployeeProfilePath(eligibilityObject.employee.id),
  [ValidationErrorType.NoSpecialisation]: (
    eligibilityObject: EligibleEmployeesInterface,
  ) => getEmployeeProfilePath(eligibilityObject.employee.id),
}

const errorTypeToTitleGetter: Record<
  ValidationErrorType,
  (
    error: EligibilityValidationError,
    eligibilityObject: EligibleEmployeesInterface,
  ) => string
> = {
  [ValidationErrorType.ManagerDeactivated]: (_error, eligibilityObject) =>
    eligibilityObject.employee.name,
  [ValidationErrorType.NoSeniority]: (error, eligibilityObject) =>
    error.object_title ?? eligibilityObject.employee.name,
  [ValidationErrorType.NoSpecialisation]: (error, eligibilityObject) =>
    error.object_title ?? eligibilityObject.employee.name,
  [ValidationErrorType.NoSkills]: (error, eligibilityObject) =>
    error.object_title ?? eligibilityObject.employee.name,
  [ValidationErrorType.NoSkillsDistribution]: (error, eligibilityObject) =>
    error.object_title ?? eligibilityObject.employee.name,
}

const errorTypeToDescriptionGetter: Record<
  ValidationErrorType,
  (error: EligibilityValidationError) => string
> = {
  [ValidationErrorType.ManagerDeactivated]: ({
    error: errorMessage,
    object_title: errorObjectTitle,
  }) => `${errorMessage} (${errorObjectTitle})`,
  [ValidationErrorType.NoSeniority]: ({ error: errorMessage }) => errorMessage,
  [ValidationErrorType.NoSpecialisation]: ({ error: errorMessage }) => errorMessage,
  [ValidationErrorType.NoSkills]: ({ error: errorMessage }) => errorMessage,
  [ValidationErrorType.NoSkillsDistribution]: ({ error: errorMessage }) => errorMessage,
}

const unknownErrorTypeLabelGetter = () => 'Unknown error'
