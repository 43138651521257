import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { requisitionSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { RequistionList } from '@src/pages/Settings/Requisitions/List'
import { JobPostingList } from '@src/pages/Settings/JobPosting/List'
import { CareersIntegrations } from '@src/pages/Settings/JobPosting/CareerPage/CareersIntegrations'
import { HiringProcessList } from '@src/pages/Settings/HiringProcess/List'
import { RequiresFeatureFlagWrapper } from '@src/components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'

const ListPage = () => {
  const permissions = useSelector(selectPermissions)
  const canViewRequisition = permissions.includes(
    PermissionTypes.ViewRequisitionPreferences,
  )
  const canViewJobPosting = permissions.includes(
    PermissionTypes.ViewJobPostingPreferences,
  )
  const canViewHiringProcess = permissions.includes(
    PermissionTypes.ViewHiringProcessPreferences,
  )

  return (
    <PageBody mb="s-64">
      <AutoStepper>
        {canViewRequisition && (
          <>
            <NewStepperTitle noAutoStep title="Requisition" />
            <RequistionList />
          </>
        )}
        {canViewJobPosting && (
          <>
            <NewStepperTitle noAutoStep title="Job postings" />
            <JobPostingList />
          </>
        )}
        <RequiresFeatureFlagWrapper flags={[FeatureFlags.JobPostingFlowSettings]}>
          {canViewHiringProcess && (
            <>
              <NewStepperTitle noAutoStep title="Hiring process" />
              <HiringProcessList />
            </>
          )}
        </RequiresFeatureFlagWrapper>
        {canViewJobPosting && <CareersIntegrations />}
      </AutoStepper>
    </PageBody>
  )
}

const routes = [
  {
    title: 'Jobs settings',
    description: 'Requisitions settings, job posting settings and integrations',
    path: ROUTES.SETTINGS.JOBS.LIST,
    url: ROUTES.SETTINGS.JOBS.LIST,
    canView: [
      PermissionTypes.ViewRequisitionPreferences,
      PermissionTypes.ViewJobPostingPreferences,
    ],
    component: ListPage,
  },
]

export const List = () => {
  return <SettingsForm routes={routes} api={requisitionSettings} />
}
