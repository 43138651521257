import { filterSortPageIntoQuery } from '../utils/table'
import { api } from './index'
import { API } from '../constants/api'
import { RequestInterface, RequestInterfaceNew } from '../interfaces'
import {
  DefaultRecruiterInterface,
  RecruitmentGroupsInterface,
} from '../interfaces/recruitmentGroups'
import { useFetchV2 } from '@src/utils/reactQuery'

export const recruitmentGroupsRequests: RequestInterface<RecruitmentGroupsInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.RECRUITMENT_GROUPS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.RECRUITMENT_GROUPS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.RECRUITMENT_GROUPS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.RECRUITMENT_GROUPS}/${id}`),
  postItem: async data => api.post(API.RECRUITMENT_GROUPS, data),
}

export const recruitmentGroupsRequestsNew: RequestInterfaceNew<RecruitmentGroupsInterface> =
  {
    get: async ({ id }) => api.get(`${API.RECRUITMENT_GROUPS}/${id}`),
    update: async (data, { id }) => api.patch(`${API.RECRUITMENT_GROUPS}/${id}`, data),
    submit: async data => api.post(API.RECRUITMENT_GROUPS, data),
    delete: async ({ id }) => api.delete(`${API.RECRUITMENT_GROUPS}/${id}`),
  }

export const getRequisitionRecruiter = async (
  specialisationId: number | string,
  seniorityId?: number,
  locationId?: number,
) => {
  const params = {
    specialisation_id: specialisationId,
    seniority_id: seniorityId,
    location_id: locationId,
  }

  return api
    .get<DefaultRecruiterInterface>(
      `${API.RECRUITMENT_GROUPS}/getRequisitionDefaultRecruiter`,
      {
        params,
      },
    )
    .then(res => res.data.recruiter)
}

interface UseGetRequisitionRecruiterArg {
  specialisationId?: number | string
  seniorityId?: number
  locationId?: number
  enabled: boolean
}

export const useGetRequisitionRecruiter = ({
  specialisationId,
  seniorityId,
  locationId,
  enabled,
}: UseGetRequisitionRecruiterArg) =>
  useFetchV2<DefaultRecruiterInterface>({
    url: `${API.RECRUITMENT_GROUPS}/getRequisitionDefaultRecruiter`,
    params: {
      params: {
        specialisation_id: specialisationId,
        seniority_id: seniorityId,
        location_id: locationId,
      },
    },
    queryOptions: {
      enabled,
    },
  })
