import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { useRouteMatch } from 'react-router-dom'
import { FilterByInterface } from '@src/interfaces/data'
import { ProgressWithNavigation } from './ProgressWithNavigation'
import { OverallProgress } from './OverallProgress'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'
import {
  fetchEntityGoalsGraph,
  GoalsStats,
  SupportedEntityGraphPath,
} from '@src/api/goals'
import { EntityTypes } from '@src/constants/api'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { isNumber } from 'lodash'
import { useIsSingleEntitySelected } from '../common/useIsSingleEntitySelected'
import { EmployeeProgressNavigation } from './EmployeeProgressNavigation'

interface Props {
  tableFilters: FilterByInterface[]
  tableStats: GoalsStats | undefined
  entity: OrgEntityInterface
}

export const GoalTableInfo = ({ tableFilters, tableStats, entity }: Props) => {
  const isPerformanceRoute = useRouteMatch(ROUTES.PERFORMANCE.ANY)
  const isCompanyRoute = useRouteMatch(ROUTES.ORGANISATION.COMPANY.GOALS.ANY)
  const isOnboarding = isOnboardingPath()
  const isSingleEntitySelected = useIsSingleEntitySelected(tableFilters)

  const entityTypePerformanceGraphPath: SupportedEntityGraphPath | undefined = {
    [EntityTypes.company]: 'company' as const,
    [EntityTypes.companyV2]: 'company' as const,
    [EntityTypes.department]: 'departments' as const,
    [EntityTypes.team]: 'teams' as const,
    [EntityTypes.teams]: 'teams' as const,
    [EntityTypes.employee]: 'employees' as const,
    [EntityTypes.employees]: 'employees' as const,
    [EntityTypes.function]: undefined,
    [EntityTypes.role]: undefined,
    [EntityTypes.specialisation]: undefined,
  }[entity.type]

  let warnAboutMandatoryGoal = false

  if (!(isPerformanceRoute || isOnboarding)) {
    warnAboutMandatoryGoal = isNumber(tableStats?.mandatory_progress)
      ? Number(tableStats?.mandatory_progress) < 1
      : false
  }

  if (isPerformanceRoute) {
    return <ProgressWithNavigation goalFilters={tableFilters} />
  }
  if (isCompanyRoute) {
    return <CompanyNavigation goalFilters={tableFilters} />
  }

  if ([EntityTypes.employee, EntityTypes.employees].includes(entity.type)) {
    return (
      <EmployeeProgressNavigation
        showBarGraph={isSingleEntitySelected}
        warnAboutMandatoryGoal={warnAboutMandatoryGoal}
        value={tableStats?.overall_progress}
        fetchData={
          entityTypePerformanceGraphPath
            ? fetchEntityGoalsGraph(entityTypePerformanceGraphPath)
            : undefined
        }
        id={entity?.data.id}
      />
    )
  }
  return (
    <OverallProgress
      showBarGraph={isSingleEntitySelected}
      warnAboutMandatoryGoal={warnAboutMandatoryGoal}
      value={tableStats?.overall_progress}
      fetchData={
        entityTypePerformanceGraphPath
          ? fetchEntityGoalsGraph(entityTypePerformanceGraphPath)
          : undefined
      }
      id={entity?.data.id}
    />
  )
}
