import React, { useEffect } from 'react'
import { Flex } from '@revolut/ui-kit'

import { useTable, UseTableOptions } from '@components/TableV2/hooks'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  kpiMetricColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
} from '@src/constants/columns/kpi'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { getKpiPerformanceGraph } from '@src/api/employees'
import { useQuery } from '@src/utils/queryParamsHooks'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import {
  foreignGoalColumn,
  goalTargetNameColumn,
  targetsTypeColumn,
} from '@src/constants/columns/goals'
import { useGetCyclesSelector } from '@src/features/Goals/useCyclesSelector'
import { EntityTypes } from '@src/constants/api'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { targetsTableWithoutChildren } from '@src/api/goals'
import Table from '@src/components/TableV2/Table'

interface StructureProps {
  data: EmployeeInterface
  navigation?: React.ReactElement
}

export const ROW: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...goalTargetNameColumn,
      width: 350,
    },
    {
      ...foreignGoalColumn,
      width: 200,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
    {
      ...kpiInitialValueColumn,
      width: 70,
    },
    {
      ...kpiMetricColumn,
      width: 70,
    },
    {
      ...kpiTargetColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 70,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...targetsTypeColumn,
      width: 120,
    },
  ],
}

export const usePersonalKPITable = (
  data: EmployeeInterface,
  options?: UseTableOptions,
  filters: FilterByInterface[] = [],
) => {
  const initialFilter = [
    ...getCommonFilters(),
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
        { name: 'new', id: 'new' },
      ],
      columnName: 'status',
    },
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_employee',
      nonResettable: true,
    },
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'owner__id',
      nonResettable: true,
      nonInheritable: true,
    },
    ...filters,
  ]

  return useTable<KpiInterface>(targetsTableWithoutChildren, initialFilter, [], options)
}

export const EmployeeTargets = ({ data, navigation }: StructureProps) => {
  const { query, deleteQueryParam } =
    useQuery<{ cycle__id: string; review_cycle__offset?: string }>()
  const table = usePersonalKPITable(data, { disable: !query.cycle__id })
  const { initialCycle, cycleSelector } = useGetCyclesSelector({
    data,
    type: EntityTypes.employees,
  })

  useEffect(() => {
    if (!initialCycle || query.cycle__id) {
      return
    }

    table.onFilterChange({
      filters: [{ name: String(initialCycle.name), id: String(initialCycle.id) }],
      columnName: 'cycle__id',
    })
  }, [initialCycle])

  useEffect(() => {
    if (query.review_cycle__offset !== undefined) {
      const cycleId = query.review_cycle__offset?.split(',')?.[0]

      deleteQueryParam('review_cycle__offset')

      if (cycleId !== undefined) {
        table.onFilterChange({
          filters: [{ name: String(cycleId), id: String(cycleId) }],
          columnName: 'cycle__id',
        })
      }
    }
  }, [])

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <GoalCycleFilter
          isFilterButtonUI={false}
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          filter={table.filterBy}
          selector={cycleSelector}
        />
        <OverallProgress
          value={table?.stats?.avg_performance}
          fetchData={getKpiPerformanceGraph}
          id={data.id}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>{refreshButton}</Table.Widget.MoreBar>
      </Table.Widget.Actions>
      {navigation && (
        <Table.Widget.Search>
          <Flex justifyContent="flex-end">{navigation}</Flex>
        </Table.Widget.Search>
      )}
      <Table.Widget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.EmployeePersonalKPIs}
          useWindowScroll
          dataType="KPI"
          row={ROW}
          {...table}
          noDataMessage="Please add your personal KPIs."
          onRowClick={row =>
            navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: row.id }))
          }
        />
        {refreshNote}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
