import {
  clickupIntegrationPreferences,
  jiraPreferencesRequests,
  linearIntegationPreferences,
  lookerIntegrationPreferences,
  salesforceIntegrationPreferences,
  tableauIntegrationPreferences,
} from '@src/api/integrations'
import { RequestInterfaceNew } from '@src/interfaces'
import {
  ClickupIntegrationInterface,
  JiraPreferencesInterface,
  LinearIntegrationInterfacePayload,
  LookerIntegrationInterface,
  SalesforceIntergrationInterface,
  TableauIntegrationInterface,
} from '@src/interfaces/integrations'
import { UpdateTypes } from '@src/interfaces/kpis'
import { string } from 'yup'

export interface IntegrationForm
  extends Partial<LookerIntegrationInterface>,
    Partial<ClickupIntegrationInterface>,
    Partial<JiraPreferencesInterface>,
    Partial<LinearIntegrationInterfacePayload>,
    Partial<TableauIntegrationInterface>,
    Partial<SalesforceIntergrationInterface> {
  id: number
  enabled: boolean
}

export const integrationApiFormMap: Record<
  UpdateTypes,
  RequestInterfaceNew<IntegrationForm> | undefined
> = {
  [UpdateTypes.looker]: lookerIntegrationPreferences,
  [UpdateTypes.roadmap]: jiraPreferencesRequests,
  [UpdateTypes.clickup]: clickupIntegrationPreferences,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: tableauIntegrationPreferences,
  [UpdateTypes.salesforce]: salesforceIntegrationPreferences,
  [UpdateTypes.linear]: linearIntegationPreferences,
}

const addHttps = (val: string) => {
  if (val.startsWith('https://')) {
    return val
  }
  if (val.startsWith('http://')) {
    return `https://${val.slice(7)}`
  }
  return `https://${val}`
}

export const serverNameValidators = {
  [UpdateTypes.looker]: {
    server: string()
      .trim()
      .url()
      .transform(val => addHttps(val).replace(/\.com.*$/, '.com'))
      .matches(/^https:\/\/[\w\d-.]+\.looker.com$/, 'Provide correct server URL'),
  },
  [UpdateTypes.roadmap]: {
    server: string()
      .trim()
      .url()
      .transform(val => addHttps(val).replace(/\.net.*$/, '.net'))
      .matches(/^https:\/\/[\w\d-.]+\.atlassian\.net$/, 'Provide correct server URL'),
  },
  [UpdateTypes.salesforce]: {
    server: string()
      .trim()
      .url()
      .transform(val =>
        addHttps(val)
          .replace(/lightning\.force/, 'salesforce') // url in browser has lightning.force but in should be with salesforce that can be found in profile, added for easy of copy
          .replace(/\.com.*$/, '.com'),
      )
      .matches(/^https:\/\/[\w\d-.]+\.salesforce\.com$/, 'Provide correct server URL'),
  },
  [UpdateTypes.clickup]: undefined,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.linear]: undefined,
  [UpdateTypes.tableau]: {
    server: string()
      .trim()
      .url()
      .transform(val => addHttps(val))
      .matches(
        /^https:\/\/[\w\d-.]+\.tableau\.com\/[\w\d/\-#:=?._]*$/,
        'Provide correct server URL',
      ),
  },
} as const
