import React, { useState } from 'react'
import { BottomSheet, Header, TextArea, useToggle, VStack, Widget } from '@revolut/ui-kit'
import { GrowthPlanHeader } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/GrowthPlanHeader'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/ActionItemsWidget'
import { CommentsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/Comments/Comments'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import {
  getGrowthPlanCommentsAPI,
  useGetGrowthPlanApprovals,
  useUpdateGrowthPlan,
} from '@src/api/growthPlans'
import { useCanAccessGrowthPlanComments } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import useApprovalFlow from '@src/features/ApprovalFlow/useApprovalFlow'
import { useGetEmployeeLatestPublishedReviewCycle } from '@src/api/reviewCycles'
import { Summary as PerformanceSummary } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Summary'
import { GrowthPlanActions } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/GrowthPlanActions'
import { GeneralInfoWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GeneralInfo/GeneralInfoWidget'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { PromotionEligibility } from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/PromotionEligibility'
import { useGlobalSettings } from '@src/api/settings'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  refetch: VoidFunction
}

export const GrowthPlan = ({ employee, growthPlan, refetch }: Props) => {
  const commentsAPI = getGrowthPlanCommentsAPI(growthPlan.id)
  const comments = commentsAPI.useGetComments()
  const canAccessComments = useCanAccessGrowthPlanComments(growthPlan)
  const { data: reviewCycle, isLoading: isReviewCycleLoading } =
    useGetEmployeeLatestPublishedReviewCycle(employee.id)
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { settings: appSettings } = useGlobalSettings()

  const [rejectionComment, setRejectionComment] = useState<string | null>()
  const [isRejectionReasonPopupOpened, rejectionReasonPopupToggler] = useToggle()

  const handleOnViewRejectionReasonClick = (comment?: string | null) => {
    setRejectionComment(comment)
    rejectionReasonPopupToggler.on()
  }

  const handleOnMarkAction = async () => {
    await comments.refetch()
  }

  const { approvalFlow, refetchApproval } = useApprovalFlow({
    data: { id: growthPlan.id, field_options: growthPlan.field_options },
    useGetApprovals: useGetGrowthPlanApprovals,
    api: useUpdateGrowthPlan,
    onViewRejectionReasonClick: handleOnViewRejectionReasonClick,
  })

  return (
    <VStack space="s-16">
      <Widget p="s-16">
        <GrowthPlanHeader
          employee={employee}
          growthPlan={growthPlan}
          actions={
            <GrowthPlanActions
              employee={employee}
              growthPlan={growthPlan}
              refetch={refetch}
              refetchApproval={refetchApproval}
            />
          }
        />
      </Widget>
      <TwoColumnsLayout
        left={
          <VStack space="s-16">
            {performanceSettings?.enable_growth_plan_approvals && (
              <Widget>{approvalFlow}</Widget>
            )}
            {growthPlan.description && <GeneralInfoWidget growthPlan={growthPlan} />}
            {appSettings?.promotions_enabled && (
              <HideIfCommercial>
                <PromotionEligibility employee={employee} growthPlan={growthPlan} />
              </HideIfCommercial>
            )}
            {growthPlan.actions.length > 0 && (
              <ActionItemsWidget
                growthPlan={growthPlan}
                onMarkAction={handleOnMarkAction}
              />
            )}
            {canAccessComments && (
              <Widget px="s-16">
                <CommentsWidget api={commentsAPI} />
              </Widget>
            )}
          </VStack>
        }
        right={
          <PerformanceSummary
            employee={employee}
            growthPlan={growthPlan}
            reviewCycle={reviewCycle ?? null}
            isLoading={isReviewCycleLoading}
          />
        }
        leftFlex={1.5}
      />
      <BottomSheet
        open={isRejectionReasonPopupOpened}
        onClose={rejectionReasonPopupToggler.off}
      >
        <Header>
          <Header.Title>Rejection reason</Header.Title>
        </Header>
        {rejectionComment && <TextArea value={rejectionComment} maxRows={5} disabled />}
      </BottomSheet>
    </VStack>
  )
}
