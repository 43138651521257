import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GeneralInfoWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/GeneralInfoWidget'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItemsWidget'
import { PromotionEligibility } from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/PromotionEligibility'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useGlobalSettings } from '@src/api/settings'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector | null
  isLoading: boolean
}

export const EditGrowthPlanFormContent = ({
  employee,
  reviewCycle,
  isLoading,
}: Props) => {
  const { values } = useLapeContext<GrowthPlanInterface>()
  const { settings: appSettings } = useGlobalSettings()

  return (
    <VStack space="s-16">
      <GeneralInfoWidget employee={employee} />
      {appSettings?.promotions_enabled && (
        <HideIfCommercial>
          <PromotionEligibility employee={employee} growthPlan={values} />
        </HideIfCommercial>
      )}
      <ActionItemsWidget
        employee={employee}
        reviewCycle={reviewCycle}
        isLoading={isLoading}
      />
    </VStack>
  )
}
