import { RoadmapInterface, JiraRoadmapPriorities } from '@src/interfaces/roadmaps'
import { Token } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const usePriorityColor = (priority: JiraRoadmapPriorities) => {
  switch (priority) {
    case JiraRoadmapPriorities.TRIVIAL:
      return Token.color.greyTone50
    case JiraRoadmapPriorities.MINOR:
      return Token.color.green
    case JiraRoadmapPriorities.MEDIUM:
      return Token.color.warning
    case JiraRoadmapPriorities.HIGH:
      return Token.color.red
    case JiraRoadmapPriorities.MAJOR:
    case JiraRoadmapPriorities.CRITICAL:
    case JiraRoadmapPriorities.COMPANY:
    case JiraRoadmapPriorities.BLOCKER:
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const getRoadmapEntityName = ({
  team,
  employee,
  department,
}: RoadmapInterface): string => {
  return team?.name || employee?.display_name || department?.name || ''
}

export const getRoadmapBackUrl = ({
  team,
  employee,
  department,
}: RoadmapInterface): string => {
  if (team) {
    return pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, { id: team.id })
  }
  if (employee) {
    return pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, { id: employee.id })
  }
  if (department) {
    return pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, { id: department.id })
  }
  return ''
}
