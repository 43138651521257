import React, { useMemo } from 'react'
import {
  Action,
  Box,
  Copyable,
  DetailsCell,
  DetailsCellSkeleton,
  Ellipsis,
  Flex,
  Text,
  TextSkeleton,
} from '@revolut/ui-kit'
import {
  CandidateInterface,
  InterviewRoundInterface,
  UTMSource,
} from '@src/interfaces/interviewTool'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { getLinkedinUrl } from '@src/utils/hiring'
import OpenInNewTab, { extractHostname } from '@components/OpenInNewTab/OpenInNewTab'
import { pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { useGetJobPosting } from '@src/api/jobPosting'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import upperFirst from 'lodash/upperFirst'
import { removeUnderscore } from '@src/utils/string'
import pluralize from 'pluralize'
import SalaryDetails from '@src/features/InterviewTool/components/SalaryDetails'
import { useGetHiringProcessSettings } from '@src/api/settings'
import { useGetRightToWorkOptions } from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/RightToWorkInputField'

type CandidateDataCellProps = {
  title: string
  children: React.ReactNode
}

type CandidateAtomProps = {
  candidate: CandidateInterface
}

type RoundAtomProps = {
  round?: InterviewRoundInterface
}

export const CandidateDataCell = ({ title, children }: CandidateDataCellProps) => (
  <DetailsCell data-testid={`candidateData_${title}`}>
    <DetailsCell.Title>{title}</DetailsCell.Title>
    <DetailsCell.Content>{children}</DetailsCell.Content>
  </DetailsCell>
)

const getUTMSource = (utmSource: UTMSource) => {
  if (utmSource === 'linkedin') {
    return 'LinkedIn'
  }
  return upperFirst(removeUnderscore(utmSource))
}

export const CandidateEmail = ({ candidate }: CandidateAtomProps) => {
  if (!candidate.email) {
    return null
  }

  return (
    <CandidateDataCell title="Email">
      <Flex alignItems="center">
        <Box mr="10px">
          <Copyable
            value={candidate.email}
            labelButtonCopy="Copy"
            onCopy={() => {
              pushNotification({
                value: 'Email copied to clipboard',
                duration: SUCCESS_DEFAULT_DURATION,
                type: NotificationTypes.success,
              })
            }}
          />
        </Box>
        <Ellipsis>{candidate.email}</Ellipsis>
      </Flex>
    </CandidateDataCell>
  )
}

export const CandidatePhone = ({ candidate }: CandidateAtomProps) => {
  if (!candidate.phone) {
    return null
  }

  return <CandidateDataCell title="Mobile">{candidate.phone}</CandidateDataCell>
}

export const CandidateLinks = ({ candidate }: CandidateAtomProps) => {
  const linkedIn = getLinkedinUrl(candidate.links)
  const links = candidate.links?.filter(link => link !== linkedIn)

  if (!links?.length) {
    return null
  }

  return (
    <CandidateDataCell title="Links">
      {links.map(link => (
        <OpenInNewTab key={link} link={link} label={extractHostname(link)} />
      ))}
    </CandidateDataCell>
  )
}

export const CandidateProblemSolving = ({ url }: { url?: string }) => {
  if (!url) {
    return null
  }

  return (
    <CandidateDataCell title="Problem Solving Case Study">
      <OpenInNewTab link={url} label="Open" noIcon />
    </CandidateDataCell>
  )
}

export const CandidateHometask = ({ url }: { url?: string }) => {
  if (!url) {
    return null
  }

  return (
    <CandidateDataCell title="Hometask">
      <OpenInNewTab link={url} label="Open" noIcon />
    </CandidateDataCell>
  )
}

export const CandidateCurrentCompany = ({ candidate }: CandidateAtomProps) => {
  if (!candidate.headline) {
    return null
  }

  return (
    <CandidateDataCell title="Current company">{candidate.headline}</CandidateDataCell>
  )
}

export const CandidateCurrentLocation = ({ candidate }: CandidateAtomProps) => {
  if (!candidate.country && candidate.location) {
    return (
      <CandidateDataCell title="Current location">{candidate.location}</CandidateDataCell>
    )
  }

  return null
}

export const CandidateCurrentCountry = ({ candidate }: CandidateAtomProps) => {
  if (!candidate.country) {
    return null
  }

  return (
    <CandidateDataCell title="Current country">
      {candidate.country.name}
    </CandidateDataCell>
  )
}

export const CandidateCurrentPosition = ({ candidate }: CandidateAtomProps) => {
  if (!candidate.current_position) {
    return null
  }

  return (
    <CandidateDataCell title="Current position">
      {candidate.current_position}
    </CandidateDataCell>
  )
}

export const CandidateYearsExp = ({ candidate }: CandidateAtomProps) => {
  if (candidate.years_of_experience === null) {
    return null
  }

  return (
    <CandidateDataCell title="Years of experience">
      {candidate.years_of_experience}
    </CandidateDataCell>
  )
}

export const CandidateCurrentStage = ({ stage }: { stage?: string }) => {
  if (!stage) {
    return null
  }

  return <CandidateDataCell title="Current stage">{stage}</CandidateDataCell>
}

export const CandidateName = ({
  candidate,
  canView,
}: CandidateAtomProps & { canView: boolean }) => {
  if (!candidate.full_name) {
    return null
  }

  return (
    <CandidateDataCell title="Candidate">
      {canView ? (
        <OpenInNewTab
          link={pathToUrlWithBaseUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
            id: candidate.id,
          })}
          label={candidate.full_name}
          noIcon
        />
      ) : (
        candidate.full_name
      )}
    </CandidateDataCell>
  )
}

export const CandidateInterviewer = ({
  interviewer,
}: {
  interviewer?: EmployeeOptionInterface
}) => {
  if (!interviewer) {
    return null
  }

  return (
    <CandidateDataCell title="Interviewer">
      <UserWithAvatar {...interviewer} usePathWithBaseUrl />
    </CandidateDataCell>
  )
}

export const CandidateSeniority = ({ round }: RoundAtomProps) => {
  if (!round) {
    return null
  }

  return (
    <CandidateDataCell title="Seniority">
      {round?.seniority?.name || '-'}
    </CandidateDataCell>
  )
}

export const CandidateSpecialisation = ({ round }: RoundAtomProps) => {
  if (!round?.specialisation) {
    return null
  }

  return (
    <CandidateDataCell title="Specialisation">
      <OpenInNewTab
        link={pathToUrlWithBaseUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
          id: round.specialisation.id,
        })}
        label={round.specialisation.name}
        noIcon
      />
    </CandidateDataCell>
  )
}

export const CandidateJobPosting = ({ round }: RoundAtomProps) => {
  if (!round?.application?.job_posting || !round.specialisation) {
    return null
  }

  return (
    <CandidateDataCell title="Job posting">
      <OpenInNewTab
        link={pathToUrlWithBaseUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
          specId: round.specialisation.id,
          id: round.application.job_posting.id,
        })}
        label={round.application.job_posting.name}
        noIcon
      />
    </CandidateDataCell>
  )
}

export const CandidateHiringProcess = ({
  round,
  candidate,
  canViewJobs,
}: RoundAtomProps & CandidateAtomProps & { canViewJobs: boolean }) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const baseInterviewRoundOnJobPosting = featureFlags.includes(
    FeatureFlags.BaseInterviewRoundOnJobPosting,
  )

  // checking permissions is crucial here as this component is used in multiple places and users might not have permissions to fullInterviewRounds endpoint
  const { data: rounds, isLoading: isRoundsLoading } = useGetFullInterviewRounds(
    canViewJobs ? candidate.id : null,
  )
  const fullRound = rounds?.find(({ id }) => round?.id === id)
  const { data: jobPosting, isLoading: isLoadingJobPosting } = useGetJobPosting(
    fullRound?.application?.job_posting?.id,
  )

  if (!round || !round.specialisation) {
    return null
  }

  return (
    <CandidateDataCell title="Hiring Process">
      {isLoadingJobPosting || isRoundsLoading ? (
        <TextSkeleton />
      ) : (
        <OpenInNewTab
          link={pathToUrlWithBaseUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
            id: round.specialisation.id,
          })}
          label={
            baseInterviewRoundOnJobPosting
              ? jobPosting?.hiring_process?.name || round.specialisation.name
              : round.specialisation.name
          }
          noIcon
        />
      )}
    </CandidateDataCell>
  )
}

export const CandidateRecruiter = ({
  recruiter,
}: {
  recruiter?: EmployeeOptionInterface
}) => {
  if (!recruiter) {
    return null
  }

  return (
    <CandidateDataCell title="Recruiter assigned">
      <UserWithAvatar {...recruiter} usePathWithBaseUrl />
    </CandidateDataCell>
  )
}

export const CandidateCoordinator = ({
  coordinator,
}: {
  coordinator?: EmployeeOptionInterface
}) => {
  return (
    <HideIfCommercial>
      <CandidateDataCell title="Coordinator assigned">
        {coordinator ? <UserWithAvatar {...coordinator} usePathWithBaseUrl /> : '-'}
      </CandidateDataCell>
    </HideIfCommercial>
  )
}

export const CandidateHiringManager = ({
  hiringManager,
}: {
  hiringManager?: EmployeeOptionInterface | null
}) => {
  return (
    <HideIfCommercial>
      <CandidateDataCell title="Hiring manager">
        {hiringManager ? <UserWithAvatar {...hiringManager} usePathWithBaseUrl /> : '-'}
      </CandidateDataCell>
    </HideIfCommercial>
  )
}

export const CandidateOrigin = ({ round }: RoundAtomProps) => {
  const { data: originSelector } = useGetSelectors(
    round?.origin ? selectorKeys.candidate_origin_choices : null,
  )
  const origin = useMemo(
    () => originSelector?.find(item => item.id === round?.origin)?.name,
    [originSelector, round],
  )

  if (!origin) {
    return null
  }

  return <CandidateDataCell title="Source">{origin}</CandidateDataCell>
}

export const CandidateJobBoard = ({ round }: RoundAtomProps) => {
  if (!round?.application_utm_data?.utm_source) {
    return null
  }

  return (
    <CandidateDataCell title="Job board">
      {getUTMSource(round.application_utm_data.utm_source)}
    </CandidateDataCell>
  )
}

export const CandidateCreatedBy = ({ round }: RoundAtomProps) => {
  if (!round?.created_by) {
    return null
  }

  return (
    <CandidateDataCell title={round.origin === 'referral' ? 'Referred by' : 'Sourced by'}>
      <UserWithAvatar {...round.created_by} usePathWithBaseUrl />
    </CandidateDataCell>
  )
}

export const CandidateAppliedFor = ({
  candidate,
  onOpen,
}: CandidateAtomProps & { onOpen?: () => void }) => {
  const { data: rounds, isLoading: isRoundsLoading } = useGetFullInterviewRounds(
    candidate.id,
  )

  return (
    <>
      {isRoundsLoading ? (
        <DetailsCellSkeleton />
      ) : (
        <CandidateDataCell title="Applied for">
          {rounds ? (
            <>
              {onOpen ? (
                <Action onClick={onOpen}>
                  {rounds.length} applied {pluralize('job', rounds.length)}
                </Action>
              ) : (
                <Text>
                  {rounds.length} applied {pluralize('job', rounds.length)}
                </Text>
              )}
            </>
          ) : (
            '-'
          )}
        </CandidateDataCell>
      )}
    </>
  )
}

export const CandidateRequisition = ({ round }: RoundAtomProps) => {
  if (!round?.requisition) {
    return null
  }

  return (
    <CandidateDataCell title="Requisition">
      <OpenInNewTab
        link={pathToUrlWithBaseUrl(ROUTES.FORMS.REQUISITION.ROLE, {
          id: round.requisition.id,
        })}
        label={round.requisition.requisition_title}
        noIcon
      />
    </CandidateDataCell>
  )
}

export const CandidatePreferredLocations = ({ round }: RoundAtomProps) => {
  if (!round?.preferred_location?.name) {
    return null
  }

  return (
    <CandidateDataCell title="Desired location">
      {round?.preferred_location?.name}
    </CandidateDataCell>
  )
}

export const CandidateDesiredSalary = ({ round }: RoundAtomProps) => {
  if (
    round?.converted_expected_salary === null &&
    round?.local_expected_salary === null
  ) {
    return null
  }

  return (
    <CandidateDataCell title="Desired salary (annual)">
      <SalaryDetails
        canView={(round?.field_options?.actions ?? []).includes('view_salary')}
        convertedCurrency={round?.conversion_currency}
        convertedSalary={round?.converted_expected_salary}
        localCurrency={round?.local_currency}
        localSalary={round?.local_expected_salary}
        additionalCommentary={round?.additional_information_about_expected_salary}
        candidateDeclinedToDisclose={
          round?.candidate_declined_to_disclose_expected_salary
        }
      />
    </CandidateDataCell>
  )
}

export const CandidateRightToWork = ({ candidate }: CandidateAtomProps) => {
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()
  const { value: rightToWorkValue } = useGetRightToWorkOptions(candidate.right_to_work)

  if (!hiringProcessSettings?.enable_right_to_work_collecting) {
    return null
  }

  return (
    <>
      {rightToWorkValue && (
        <CandidateDataCell title="Right to work">
          {rightToWorkValue.name}
        </CandidateDataCell>
      )}
      {typeof candidate.is_eligible_to_relocate === 'boolean' && (
        <CandidateDataCell title="Eligibility to relocate">
          {candidate.is_eligible_to_relocate ? 'Yes' : 'No'}
        </CandidateDataCell>
      )}
      {typeof candidate.is_ready_to_relocate === 'boolean' && (
        <CandidateDataCell title="Ready to relocate">
          {candidate.is_ready_to_relocate ? 'Yes' : 'No'}
        </CandidateDataCell>
      )}
    </>
  )
}
