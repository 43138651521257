import { getLocationDescriptor } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { pathToUrl } from '@src/utils/router'
import { groupFiltersIntoURLQuery } from '@src/utils/table'

export const useGetGoalUrl = () => {
  const isOnboarding = isOnboardingPath()
  const getGoalUrl = (goal: GoalsInterface, tableFilters: FilterByInterface[]) => {
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW
    const onboardingRoute =
      goal.status.id === Statuses.draft
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
        : ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW

    return {
      ...getLocationDescriptor(
        pathToUrl(isOnboarding ? onboardingRoute : route, { id: goal.id }),
      ),
      search: new URLSearchParams(groupFiltersIntoURLQuery(tableFilters)).toString(),
    }
  }

  return getGoalUrl
}
