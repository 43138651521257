import React from 'react'
import { Action, StatusWidget } from '@revolut/ui-kit'
import { ERRORS } from '@src/constants/notifications'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'

type Props = {
  id?: number
  isContractError?: boolean
  isEmployeeProfileError?: boolean
  title: string | null
}

export const ErrorWidget = ({
  id,
  isContractError,
  isEmployeeProfileError,
  title,
}: Props) => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D274.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D274@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D274@3x.png',
        }}
      />
      <StatusWidget.Title>{title || ERRORS.UNKNOWN_REFRESH}</StatusWidget.Title>
      {id && isContractError && (
        <StatusWidget.Actions
          style={{ textDecoration: 'none' }}
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, { id })}
          use={InternalLink}
        >
          <Action>Go to contracts</Action>
        </StatusWidget.Actions>
      )}
      {id && isEmployeeProfileError && (
        <StatusWidget.Actions
          style={{ textDecoration: 'none' }}
          // @ts-expect-error ui-kit does not correctly infer `to` prop type by passed `use` component value
          to={{
            pathname: pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
            search: 'sidebar=workDetails',
          }}
          use={InternalLink}
        >
          <Action>Go to work details</Action>
        </StatusWidget.Actions>
      )}
    </StatusWidget>
  )
}
