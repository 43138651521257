import React from 'react'
import { useSelector } from 'react-redux'

import Loader from '@components/CommonSC/Loader'
import { useGetCompany } from '@src/api/company'
import { getScopeFilters } from '@src/features/Engagement/helpers'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { SurveryResultsTabPublished as SurveyResultsPublishedV2 } from '@src/pages/Performance/Engagement/components/v2/ResultsTab/Published'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

const DEFAULT_COMPANY_ID = 1

export const Engagement = () => {
  const { data, isLoading } = useGetCompany()

  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  if (isLoading) {
    return <Loader />
  }
  if (isEngagementV2) {
    return (
      <SurveyResultsPublishedV2
        isCompany
        scopeFilters={getScopeFilters(data, 'company')}
      />
    )
  }
  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Company,
        id: DEFAULT_COMPANY_ID,
        permissions: data?.field_options.permissions,
      }}
    />
  )
}
