import React from 'react'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { PageBody } from '@components/Page/PageBody'
import { Box, VStack } from '@revolut/ui-kit'
import { PageActions } from '@components/Page/PageActions'
import { TeamSettingsFields, TeamSettingsSaveButton } from './common'

const TeamsSettings = () => {
  return (
    <>
      <PageBody>
        <Box mt="s-24">
          <VStack space="s-16">
            <TeamSettingsFields />
          </VStack>
        </Box>
      </PageBody>

      <PageActions>
        <TeamSettingsSaveButton />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Teams',
    path: ROUTES.SETTINGS.ORGANISATION.TEAMS,
    url: ROUTES.SETTINGS.ORGANISATION.TEAMS,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: TeamsSettings,
  },
]

export const Teams = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Teams settings"
      subtitle="Settings that apply to all teams"
    />
  )
}
