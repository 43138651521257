import React, { useState } from 'react'
import { Dropdown, MoreBar, Text, Token, useDropdown } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { history, pathToUrl } from '@src/utils/router'
import { Link, useLocation } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessSettingsInterface } from '@src/interfaces/settings'
import { DefaultHiringProcessLocationState } from './types'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { getHiringStage, useGetCompanyHiringStage } from '@src/api/hiringProcess'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { BaseOptionId, OptionInterface } from '@src/interfaces/selectors'

const useMountAddHiringStage = () => {
  const { state: locationState } = useLocation<DefaultHiringProcessLocationState>()
  const { values } = useLapeContext<HiringProcessSettingsInterface>()
  const { data: hiringStage } = useGetCompanyHiringStage(locationState?.stageId)
  if (hiringStage && locationState?.stageId) {
    values.hiring_process_rounds = [...(values.hiring_process_rounds || []), hiringStage]
    history.replace({ state: { ...locationState, stageId: undefined } })
  }
}

export const AddHiringStage = () => {
  useMountAddHiringStage()

  const { values } = useLapeContext<HiringProcessSettingsInterface>()

  const dropdown = useDropdown()

  const { data: hiringStages } = useGetSelectors(selectorKeys.hiring_stages)

  const [loadingIds, setLoadingIds] = useState<(number | string)[]>([])

  const showErrorPopup = useErrorPopup()

  const handleAddStage = async (stageOption: OptionInterface<BaseOptionId>) => {
    const stageId = stageOption.id
    setLoadingIds([...loadingIds, stageId])
    try {
      const res = await getHiringStage(stageId)
      values.hiring_process_rounds = [...(values.hiring_process_rounds || []), res.data]
    } catch (error) {
      showErrorPopup.show({
        error,
        fallbackTitle: `Could not add ${stageOption.name} to default hiring process`,
      })
    } finally {
      setLoadingIds(loadingIds.filter(id => id !== stageId))
    }
  }

  const currentHiringStages = hiringStages?.filter(
    hiringStageOption =>
      !values?.hiring_process_rounds?.findIndex(
        hiringStage => hiringStage.id !== hiringStageOption.id,
      ),
  )
  return (
    <>
      <Dropdown
        {...dropdown.getTargetProps()}
        aria-label="Hiring stages dropdown"
        autoClose
      >
        <Dropdown.Item
          color={Token.color.accent}
          to={pathToUrl(ROUTES.FORMS.HIRING_STAGES.DEFAULT)}
          use={Link}
        >
          <Text color={Token.color.accent}>+ Add new</Text>
        </Dropdown.Item>
        {currentHiringStages?.map(stage => {
          return (
            <Dropdown.Item
              key={stage.id}
              use="button"
              disabled={loadingIds.includes(Number(stage.id))}
              onClick={() => handleAddStage(stage)}
            >
              {stage.name}
            </Dropdown.Item>
          )
        })}
      </Dropdown>
      <MoreBar.Action {...dropdown.getAnchorProps()} useIcon="ChevronDown">
        Add a round
      </MoreBar.Action>
    </>
  )
}
