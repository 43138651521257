import React, { useEffect, useState } from 'react'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { HStack, Icon, TextButton, Text, InputGroup, Side } from '@revolut/ui-kit'

import { RoadmapInterfaceForm } from '@src/interfaces/roadmaps'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import SideBar from '@src/components/SideBar/SideBar'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { useRoadmapTypeOptions } from './useRoadmapTypeOptions'
import { IdAndName } from '@src/interfaces'
import { ItemType } from '@src/interfaces/deliverables'
import Form from '@src/features/Form/Form'
import { addRoadmaps, roadmapsRequestsNew } from '@src/api/roadmaps'
import { RoadmapForm } from '@src/pages/Forms/RoadmapForm/RoadmapForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useOwnerId } from '@src/features/Goals/components/useOwnerId'
import { captureException } from '@sentry/react'
import { LinkClickupTasksForm } from '../../GoalForm/Form/SidebarForms/LinkClickupTasksForm'
import { LinkRoadmapsForm } from '../../GoalForm/Form/SidebarForms/LinkRoadmapsForm'
import { LinkLinearTasksForm } from '../../GoalForm/Form/SidebarForms/LinkLinearTasksForm'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

export interface AddRoadmapActionProps extends CreateRoadmapProps {
  entityType: EntityTypes
  id: number
  onAfterSubmit: () => void
  reviewCycle: ReviewCyclesInterface | null
  tags?: string[]
  variant?: 'text' | 'default'
}

export type RoadmapInitialValues = Pick<
  RoadmapInterfaceForm,
  | 'team'
  | 'department'
  | 'employee'
  | 'review_cycle'
  | 'owner'
  | 'priority'
  | 'start_date'
  | 'due_date'
  | 'is_company'
>

export interface CreateRoadmapProps {
  backUrl?: string
  name: string
  reviewCycle: ReviewCyclesInterface | null
}

export interface CreateRoadmapLocationState
  extends LocationStateWithInitialValues<RoadmapInitialValues> {
  backUrl?: string
  name: string
  genericRoadmapCreationType?: 'company' | 'department' | 'team'
}

// Feel this needs to be declared somewhere else. perhaps around form implementation where initialValues is read
export interface LocationStateWithInitialValues<T extends {} = {}> {
  initialValues: T
}

export const AddRoadmapAction = ({
  variant,
  onAfterSubmit,
  entityType,
  id,
  reviewCycle,
  name,
}: AddRoadmapActionProps) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const showErrorPopup = useErrorPopup()
  const ownerId = useOwnerId()
  const { options: roadmapTypeOptions, isLoading } = useRoadmapTypeOptions()
  const [selectedRoadmapType, setSelectedRoadmapType] =
    useState<IdAndName<ItemType> | null>(null)

  const onClose = () => {
    setIsSideBarOpen(false)
  }

  useEffect(() => {
    if (roadmapTypeOptions.length) {
      setSelectedRoadmapType(roadmapTypeOptions[0].value)
    }
  }, [isLoading])

  const submitIntegratedRoadmap = async (
    cycle: ReviewCyclesInterface,
    keys: string[],
  ): Promise<void> => {
    try {
      setPendingSubmit(true)
      if (!selectedRoadmapType?.id) {
        throw new Error('select roadmap type')
      }

      await addRoadmaps({
        keys,
        review_cycle: cycle.id,
        item_type: selectedRoadmapType.id,
        entityType,
        id, // entity type id
      })
    } catch (err) {
      captureException(err)
      showErrorPopup.show({ error: err, fallbackTitle: 'Failed to add roadmap' })
    } finally {
      setPendingSubmit(false)
    }
  }

  return (
    <>
      {variant === 'text' ? (
        <TextButton onClick={() => setIsSideBarOpen(true)}>
          <HStack space="s-8">
            <Icon name="Plus" size={16} />
            <Text>Add new roadmap</Text>
          </HStack>
        </TextButton>
      ) : (
        <PrimaryAction onClick={() => setIsSideBarOpen(true)} useIcon="Plus">
          Add new roadmap
        </PrimaryAction>
      )}
      <SideBar
        title="Add roadmap"
        subtitle={name && `${name} ${entityType}`}
        isOpen={isSideBarOpen}
        onClose={onClose}
      >
        <InputGroup>
          <RadioSelectInput
            label="Roadmap type"
            searchable={false}
            value={selectedRoadmapType}
            options={roadmapTypeOptions}
            loading={isLoading}
            onChange={val => setSelectedRoadmapType(val)}
          />

          {selectedRoadmapType?.id === ItemType.Clickup && (
            <LinkClickupTasksForm
              pending={pendingSubmit}
              initialCycle={reviewCycle || undefined}
              ownerId={ownerId}
              selected={[]}
              submit={async ({ cycle, epics }) => {
                await submitIntegratedRoadmap(
                  cycle,
                  epics.map(ep => ep.key),
                )
                onAfterSubmit()
                onClose()
              }}
            />
          )}

          {selectedRoadmapType?.id === ItemType.Linear && (
            <LinkLinearTasksForm
              pending={pendingSubmit}
              initialCycle={reviewCycle || undefined}
              ownerId={ownerId}
              selected={[]}
              submit={async ({ cycle, epics }) => {
                await submitIntegratedRoadmap(
                  cycle,
                  epics.map(ep => ep.key),
                )
                onAfterSubmit()
                onClose()
              }}
            />
          )}

          {selectedRoadmapType?.id === ItemType.Jira && (
            <LinkRoadmapsForm
              pending={pendingSubmit}
              initialCycle={reviewCycle || undefined}
              ownerId={ownerId}
              submit={async ({ cycle, epics }) => {
                await submitIntegratedRoadmap(
                  cycle,
                  epics.map(ep => ep.key),
                )
                onAfterSubmit()
                onClose()
              }}
            />
          )}

          {selectedRoadmapType?.id === ItemType.Manual && (
            <Form
              forceParams={{ id: undefined }}
              api={roadmapsRequestsNew}
              initialValues={{
                [entityType]: { id },
                owner: { id: ownerId },
                review_cycle: reviewCycle || undefined,
                item_type: ItemType.Manual,
              }}
              disableLocalStorageCaching
              useLocalStorageCaching={false}
            >
              <RoadmapForm />
              <Side.Actions>
                <NewSaveButtonWithPopup
                  onAfterSubmit={() => {
                    onAfterSubmit()
                    onClose()
                  }}
                  useValidator
                />
              </Side.Actions>
            </Form>
          )}
        </InputGroup>
      </SideBar>
    </>
  )
}
