import { OptionInterface } from '@src/interfaces/selectors'
import { IdAndName } from '@src/interfaces/index'
import { ApplicationFormSettingsInterface } from '@src/interfaces/settings'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { LocationType } from '@src/interfaces/locations'

export interface CareersPosition {
  id: string
  internalId?: number
  text: string
  locations: CareersPositionLocation[]
  description: string
  team: string
  creation_date_time?: string
  status?: PublishingStatuses
  presentation_video_url?: string
}

export type CareersQuestion =
  | CareersQuestionCheckbox
  | CareersQuestionRadio
  | CareersQuestionInput
  | CareersQuestionText
  | CareersQuestionDropdown

export interface CareersSectionsItem {
  internal_data_id: string
  internal_data_type: string
  title: string
  description: string
  questions: CareersQuestion[]
}

export interface CareersSections {
  internal_data_id: string
  internal_data_type: string
  sections: CareersSectionsItem[]
}

interface CareersQuestionBase {
  title: string
  subtitle: string
  optional: boolean
  internal_data_type: CareersQuestionType
  internal_data_id: string
  locations: string[]
}

type CareersQuestionType =
  | 'checkbox_application_form_question'
  | 'option_application_form_question'
  | 'text_application_form_question'
  | 'textarea_application_form_question'
  | 'dropdown_application_form_question'

export interface CareersQuestionCheckbox extends CareersQuestionBase {
  internal_data_type: 'checkbox_application_form_question'
  options: CareersQuestionCheckboxOption[]
  answer?: CareersQuestionCheckboxOption[]
}

interface CareersQuestionCheckboxOption extends CareersQuestionOption {
  answer: boolean
}

export interface CareersQuestionRadio extends CareersQuestionBase {
  internal_data_type: 'option_application_form_question'
  options: CareersQuestionOption[]
  answer: CareersQuestionOption | null
}
export interface CareersQuestionInput extends CareersQuestionBase {
  internal_data_type: 'text_application_form_question'
  placeholder: string
  answer: string
}
export interface CareersQuestionText extends CareersQuestionBase {
  internal_data_type: 'textarea_application_form_question'
  placeholder: string
  answer: string
}
export interface CareersQuestionDropdown extends CareersQuestionBase {
  internal_data_type: 'dropdown_application_form_question'
  options: CareersQuestionOption[]
  answer: CareersQuestionOption | null
  placeholder?: string
}

export interface CareersQuestionOption {
  internal_data_id: string
  internal_data_type: string
  id?: string
  name?: string
  option: string
}

export interface CareersPositionLocation {
  name: string
  type: LocationType
  country: string | null
}

interface CareersPositionResponseLocation {
  name: string
  type: LocationType
  country?: {
    name: string
  }
}

export interface CareersPositionResponse {
  id: string
  title: string
  locations: CareersPositionResponseLocation[]
  function?: {
    name: string
  }
  description?: string
  creation_date_time?: string
  presentation_video_url?: string
}

export interface CareersPositionPreviewResponse extends CareersPositionResponse {
  internal_id: number
  status: PublishingStatuses
}

export const CAREERS_TEAM_OTHER = 'Other'

export enum CareersLocationsVariant {
  Default = 'default',
  Small = 'small',
}

export interface CareersApplicationInterface {
  id?: string | number
  posting_id?: string
  full_name?: string
  email?: string
  phone?: string
  current_company?: string
  links?: string[]
  captcha?: string
  preferred_locations?: OptionInterface[]
  gender?: OptionInterface | null
  ethnicity?: OptionInterface | null
  country?: OptionInterface | null
  additional_questions?: CareersSections | null
  file?: File | null
  educations?: CareersApplicationEducationInterface[]
  work_experiences?: CareersApplicationWorkInterface[]
  consent_to_re_engage?: boolean | null
  consent_to_interview_ai_summarisation?: boolean | null
}

export enum CareersApplicationStatusCode {
  Unknown = '1000',
  PostingMissing = '1001',
  BadCaptcha = '1002',
  ValidationError = '1003',
}

export interface CareersCompanyLinkInterface {
  url: string
  label: string
}

export interface CareersFunctionGroupInterface {
  name: string
  functions: IdAndName[]
}

export interface CareersSettingsInterface {
  enabled: boolean
  publish_career_website: boolean
  data_privacy_notice_url?: string
  slogan: string
  company_logo: IdAndName & { url: string }
  company_links: CareersCompanyLinkInterface[]
  about_the_company: string
  group_by_function: boolean
  function_groups: CareersFunctionGroupInterface[]
  creation_date_time: string
  update_date_time: string
  company_name: string
  company_website?: string
}

export interface CareersApplicationSettingsInterface
  extends Pick<
    ApplicationFormSettingsInterface,
    | 'enable_phone_number_field'
    | 'enable_current_company_field'
    | 'enable_preferred_work_location_field'
    | 'enable_resume_field'
    | 'enable_portfolio_link_field'
    | 'is_phone_number_optional'
    | 'is_current_company_optional'
    | 'is_preferred_work_location_optional'
    | 'is_resume_field_optional'
    | 'is_portfolio_link_optional'
    | 'enable_work_experience_field'
    | 'enable_education_field'
    | 'is_education_optional'
    | 'is_work_experience_optional'
  > {
  enable_diversity_field: boolean
}

export interface CareersApplicationEducationInterface {
  university: IdAndName
  degree: string
  major: IdAndName
  start_date: string
  end_date: string
  gpa?: number
}

export interface CareersApplicationWorkInterface {
  company: IdAndName | null
  other_company_name?: string
  position: IdAndName | null
  other_position_title?: string
  start_date: string
  end_date?: string | null
}

export enum PredefinedSectionKeys {
  resume = 'resume',
  links = 'links',
  general = 'general',
  work_experience = 'work_experience',
  education = 'education',
  diversity = 'diversity',
}

export enum GeneralSectionQuestionsKeys {
  full_name = 'full_name',
  email = 'email',
  phone = 'phone',
  current_company = 'current_company',
  country = 'country',
  preferred_locations = 'preferred_locations',
}

export enum CareersFeature {
  ai_interview_summarisation_candidate = 'ai_interview_summarisation_candidate',
}

export interface CareersFeaturesInterface {
  feature_settings: CareersFeature[]
}
