import React from 'react'
import { Flex, Circle, Token, Icon, Group, Text } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceSelector,
  ReviewSummaryInterface,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { data } from '@src/pages/Forms/CommonTalentTab/GradesExplanationSidebar/example'
import { selectUser } from '@src/store/auth/selectors'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { pathToUrl } from '@src/utils/router'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PerformanceActionButton } from './PerformanceActionButton'

interface Props {
  event: SingleTimelineEventInterface | undefined
  employee: EmployeeInterface
  selectedPeriod: PerformanceSelector
  goalsCount: number
  summaryData: ReviewSummaryInterface | undefined
  currentCycleReviewsPublishingDay: string | null | undefined
}

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
  [ButtonStates.Continue]: 'Pencil' as const,
}

export const PerformanceReviewers = ({
  event,
  employee,
  selectedPeriod,
  goalsCount,
  summaryData,
  currentCycleReviewsPublishingDay,
}: Props) => {
  const isCalibrationStep = event?.category === 'calibration'

  const user = useSelector(selectUser)

  const visibleReviews = useMemo(() => {
    if (employee.id && isCalibrationStep && event?.reviews) {
      return event?.reviews.filter(({ reviewer }) => reviewer?.id !== employee.id)
    }
    return event?.reviews || []
  }, [data, event?.reviews, isCalibrationStep])

  const isGoalsStep = event?.category === 'kpi' || event?.category === 'goal'

  const currentUserReview = event?.reviews?.find(
    review => review.reviewer?.id === user.id,
  )

  const buttonDisabled = currentUserReview ? !currentUserReview.can_submit : true

  let state = ButtonStates.Add
  if (currentUserReview?.status === Statuses.completed) {
    state = ButtonStates.Edit
  }
  if (currentUserReview?.status === Statuses.draft) {
    state = ButtonStates.Continue
  }

  const reviewLink = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT

  const subText = isGoalsStep ? 'Set quarterly goals' : ''

  if (!event?.reviews?.length) {
    if (event?.category === 'kpi' || event?.category === 'goal') {
      return selectedPeriod?.status === ReviewCycleStatus.closed ? (
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap="s-16">
            <Circle bg={Token.color.greyTone10} size={40}>
              <Icon name="InfoSign" size={24} />
            </Circle>
            <Flex gap="s-2" flexDirection="column">
              <Text whiteSpace="nowrap" variant="emphasis1">
                No goal assigned
              </Text>
              <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                This employee has not been assigned any goals for this quarter. Assessment
                is provided based on Team Goals.
              </Text>
            </Flex>
          </Flex>
          <PerformanceActionButton
            category={event.category}
            employee={employee}
            selectedPeriod={selectedPeriod}
          />
        </Flex>
      ) : (
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap="s-16" alignItems="center">
            <Circle bg={Token.color.greyTone10} size={40}>
              <Icon name="InfoSign" size={24} />
            </Circle>
            <Flex gap="s-2" flexDirection="column">
              <Text variant="emphasis1">
                {goalsCount ? 'Performance goals are set' : 'Performance goals pending'}
              </Text>
              {!goalsCount && (
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  This employee has not been assigned any goals yet.
                </Text>
              )}
            </Flex>
          </Flex>
          <PerformanceActionButton
            category={event.category}
            employee={employee}
            selectedPeriod={selectedPeriod}
          />
        </Flex>
      )
    }
    return null
  }

  return (
    <Group style={transparentThemeBackgroundOverrides}>
      <StepReviewersList
        summaryData={summaryData}
        isCalibrationStep={isCalibrationStep}
        reviewsPublishingDay={currentCycleReviewsPublishingDay}
        secondaryActionButton={
          <PerformanceActionButton
            category={event.category}
            employee={employee}
            selectedPeriod={selectedPeriod}
          />
        }
        reviews={visibleReviews}
        subText={subText}
        onClickAddReview={e => {
          e.stopPropagation()
          navigateTo(
            pathToUrl(reviewLink, {
              id: currentUserReview?.id,
              employeeId: employee.id,
            }),
          )
        }}
        buttonDisabled={buttonDisabled}
        icon={ButtonIcon[state]}
        title={ButtonTitles[state]}
      />
    </Group>
  )
}
