import {
  Attendee,
  MeetingsSummaryInterface,
  OneToOneEmployeeRelation,
  OneToOneMeeting,
} from '@src/interfaces/meetings'
import { EmployeeInterface } from '@src/interfaces/employees'

interface OneToOneModel {
  getDependantAttendee: (
    employee: EmployeeInterface,
    meeting: OneToOneMeeting,
  ) => Attendee
  isScheduled: (meeting: OneToOneMeeting) => boolean
  isMeetingWithReport: (meeting: OneToOneMeeting) => boolean
  isMeetingManagedBy: (employee: EmployeeInterface, meeting: OneToOneMeeting) => boolean
  isMeetingParticipant: (employee: EmployeeInterface, meeting: OneToOneMeeting) => boolean
  isEmptyMeetingsSummaryContent: (
    summary: MeetingsSummaryInterface,
    sections?: Array<
      keyof Pick<
        MeetingsSummaryInterface,
        'summary' | 'strengths' | 'area_for_development'
      >
    >,
  ) => boolean
}

const getDependantAttendee = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): Attendee => {
  if (employee.id === meeting.employee.id) {
    return meeting.manager
  }

  return meeting.employee
}

const isMeetingManagedBy = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): boolean => meeting.manager.id === employee.id

const isScheduled = (meeting: OneToOneMeeting): boolean => meeting.meeting_series !== null

const isMeetingWithReport = (meeting: OneToOneMeeting): boolean =>
  meeting.employee_relation_type === OneToOneEmployeeRelation.Report

const isMeetingParticipant = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): boolean => meeting.manager.id === employee.id || meeting.employee.id === employee.id

const isEmptyMeetingsSummaryContent = (
  summary: MeetingsSummaryInterface,
  sections?: Array<
    keyof Pick<MeetingsSummaryInterface, 'summary' | 'strengths' | 'area_for_development'>
  >,
): boolean => {
  if (!sections || !sections.length) {
    return !summary.summary && !summary.area_for_development && !summary.strengths
  }

  return sections.every(key => !summary[key])
}

const createOneToOneModel = (): OneToOneModel => {
  return {
    getDependantAttendee,
    isScheduled,
    isMeetingWithReport,
    isMeetingManagedBy,
    isMeetingParticipant,
    isEmptyMeetingsSummaryContent,
  }
}

export const oneToOneModel = createOneToOneModel()
