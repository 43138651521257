import React from 'react'
import { HiringProcessInterface, HiringStageType } from '@src/interfaces/hiringProccess'
import {
  Group,
  HStack,
  Header,
  Link as UIKitLink,
  ActionButton,
  Bar,
  VStack,
  Icon,
  Text,
  Tooltip,
  useTooltip,
  Token,
  Box,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import SideBar from '@components/SideBar/SideBar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { Pencil } from '@revolut/icons'
import HiringStageDeleteAction from '@src/features/HiringStageDeleteAction/HiringStageDeleteAction'
import OnlineTestPreviewLink from '@src/features/HiringStageSidebarPreview/OnlineTestPreviewLink'
import OnlineTestPreviewDuration from '@src/features/HiringStageSidebarPreview/OnlineTestPreviewDuration'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface Props {
  onClose: () => void
  type: HiringStageType
  onSuccessDelete?: () => void
}

const HiringStageSidebarPreview = ({ onClose, onSuccessDelete, type }: Props) => {
  const { values, loading } = useLapeContext<HiringProcessInterface>()
  const tooltip = useTooltip()
  const isDisabled =
    values?.is_company_standard_round ||
    (type === 'specialisation' && values?.limit_editing_in_specialisation)
  const isOpen = true

  const getEditLink = () => {
    if (loading) {
      return ''
    }
    switch (type) {
      case 'role':
        return pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE, { id: values?.id })
      case 'specialisation':
        return pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION, { id: values?.id })
      case 'general':
        return pathToUrl(ROUTES.FORMS.HIRING_STAGES.GENERAL, { id: values?.id })
      case 'default':
        return pathToUrl(ROUTES.FORMS.HIRING_STAGES.DEFAULT, { id: values?.id })
      default:
        return ''
    }
  }

  const isOnlineTest = values.stage_type?.id === 'online_test'

  return (
    <>
      <SideBar
        isOpen={isOpen}
        onClose={onClose}
        loading={loading}
        title={values?.title || 'New stage'}
        variant="wide"
        headerContent={
          <Header.Bar>
            <VStack>
              <Bar>
                {!isDisabled && (
                  <>
                    {!values.is_reference_to_parent_stage && (
                      <ActionButton
                        useIcon={Pencil}
                        use={InternalLink}
                        to={getEditLink()}
                      >
                        Edit
                      </ActionButton>
                    )}
                    <HiringStageDeleteAction
                      type={type}
                      onSuccess={onSuccessDelete}
                      label={
                        type === 'general'
                          ? 'Archive'
                          : values.is_reference_to_parent_stage
                          ? 'Remove'
                          : 'Delete'
                      }
                    />
                  </>
                )}
              </Bar>
            </VStack>
          </Header.Bar>
        }
      >
        <VStack gap="s-16">
          <FormPreview data={loading ? undefined : values}>
            <Group>
              <FormPreview.Item title="Round name" field="title" />
              <FormPreview.Item title="Stage type" field="stage_type.name" />
              {isOnlineTest ? (
                <>
                  <FormPreview.Item<HiringProcessInterface>
                    title="Test platform"
                    field="test_platform.name"
                  />
                  <FormPreview.Item<HiringProcessInterface>
                    title="Test name"
                    field="online_test.name"
                  />
                </>
              ) : (
                <FormPreview.Item<HiringProcessInterface>
                  title="Skills"
                  field="skills"
                  type="options"
                />
              )}

              <FormPreview.Item<HiringProcessInterface>
                title="Playbook link"
                insert={data =>
                  data.playbook_link ? (
                    <UIKitLink
                      use="a"
                      href={data.playbook_link}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      View
                    </UIKitLink>
                  ) : (
                    '-'
                  )
                }
              />
            </Group>
          </FormPreview>
          {isOnlineTest ? (
            <FormPreview data={loading ? undefined : values}>
              <Group>
                <OnlineTestPreviewLink<HiringProcessInterface> />
                <OnlineTestPreviewDuration<HiringProcessInterface> />
              </Group>
            </FormPreview>
          ) : (
            <>
              <FormPreview data={loading ? undefined : values}>
                <Group>
                  <FormPreview.Item<HiringProcessInterface>
                    title="Scorecard"
                    field="scorecard_template.name"
                    to={data =>
                      pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
                        id: data.scorecard_template?.id,
                      })
                    }
                  />
                  <FormPreview.Item<HiringProcessInterface>
                    title="Duration"
                    insert={data =>
                      data.duration && data.duration_unit
                        ? `${data.duration} ${data.duration_unit.name}`
                        : '-'
                    }
                  />
                </Group>
              </FormPreview>
              <FormPreview data={loading ? undefined : values}>
                <Group>
                  <FormPreview.Item
                    title="Interviewer type"
                    field="interviewer_type.name"
                  />
                  {values?.interviewer_type?.id === 'groups' && (
                    <FormPreview.Item<HiringProcessInterface>
                      title="Interviewer groups"
                      field="interviewers_groups"
                      type="options"
                    />
                  )}

                  {values?.interviewer_type?.id === 'employees' && (
                    <FormPreview.Item<HiringProcessInterface>
                      title="Interviewers"
                      insert={data => (
                        <HStack space="s-4">
                          {data.interviewers?.map(item => (
                            <UserWithAvatar key={item.id} {...item} compact />
                          ))}
                        </HStack>
                      )}
                    />
                  )}
                </Group>
              </FormPreview>
            </>
          )}
          <FormPreview data={loading ? undefined : values} title="Additional settings">
            <Group>
              <FormPreview.Item<HiringProcessInterface>
                title={
                  <HStack gap="s-8" align="center">
                    <Text>Automation</Text>
                    <Box {...tooltip.getAnchorProps()}>
                      <Icon name="InfoOutline" size={16} />
                      <Tooltip {...tooltip.getTargetProps()}>
                        Automation settings allow to move candidates to the next stages
                        and automatically schedule interview
                      </Tooltip>
                    </Box>
                  </HStack>
                }
                field="schedule_interview_automatically"
                insert={({ schedule_interview_automatically }) => (
                  <Text
                    color={
                      schedule_interview_automatically
                        ? Token.color.orange
                        : Token.color.greyTone50
                    }
                  >
                    {schedule_interview_automatically ? 'Enabled' : 'Disabled'}
                  </Text>
                )}
              />
              <FormPreview.Item
                title="Company standard round"
                field="is_company_standard_round"
                type="boolean"
              />
              <FormPreview.Item
                title="Limited by seniority"
                field="is_limited_by_seniorities"
                type="boolean"
              />
              {values?.is_limited_by_seniorities &&
                values.seniority_min &&
                values.seniority_max && (
                  <FormPreview.Item<HiringProcessInterface>
                    title="Seniorities"
                    insert={data =>
                      `${data.seniority_min?.name} - ${data.seniority_max?.name}`
                    }
                  />
                )}
              <FormPreview.Item
                title="Limit editing in specialisation"
                field="limit_editing_in_specialisation"
                type="boolean"
              />
            </Group>
          </FormPreview>
        </VStack>
      </SideBar>
    </>
  )
}

export default HiringStageSidebarPreview
