import { Statuses } from '@src/interfaces'
import {
  KpiInterface,
  KpiReviewCycle,
  KpiTargetEpics,
  KpiTargets,
  UpdateTypes,
} from '@src/interfaces/kpis'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import forEach from 'lodash/forEach'
import { useGetKPITargetApprovals, useGetKPITargets } from '@src/api/kpis'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { useIsApprovalsEnabled } from './common'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'

export interface TargetsApprovalsValue {
  status: Statuses | null
  approvalFlow: React.ReactNode | null
  refresh: (newTargets: KpiTargets[] | KpiTargetEpics[]) => void
  reviewCycle?: KpiReviewCycle
}

export interface TargetsApprovalsProps {
  children: React.ReactNode
}

export const TargetsApprovalsContext = React.createContext<TargetsApprovalsValue>(
  {} as any,
)

const TargetsApprovals = ({ children }: TargetsApprovalsProps) => {
  const { values, initialValues } = useLapeContext<KpiInterface>()
  const [status, setStatus] = useState<Statuses | null>(null)
  const [target, setTarget] = useState<KpiTargets | KpiTargetEpics | null>()
  const [shouldUpdateTargets, setShouldUpdateTargets] = useState<boolean>(false)
  const { data: fetchedTargets } = useGetKPITargets(
    shouldUpdateTargets ? values.id : null,
  )
  const approvalsEnabled = useIsApprovalsEnabled()
  const approvalStatuses = [Statuses.active, Statuses.pending, Statuses.requires_changes]

  const {
    data: approvalSteps,
    isLoading,
    refetch,
  } = useGetKPITargetApprovals(approvalsEnabled && target?.id ? target.id : null)

  useEffect(() => {
    const targets =
      values.update_type === UpdateTypes.roadmap ? values.target_epics : values.targets
    refresh(targets)
  }, [values.targets?.length, values.target_epics?.length, initialValues.status])

  useEffect(() => {
    if (target?.id && approvalsEnabled) {
      refetch()
    }
  }, [target, approvalsEnabled])

  useEffect(() => {
    if (fetchedTargets?.results) {
      setShouldUpdateTargets(false)
      refresh(fetchedTargets.results as KpiTargets[])

      if (values.update_type === UpdateTypes.roadmap) {
        values.target_epics = fetchedTargets.results as KpiTargetEpics[]
      } else {
        values.targets = fetchedTargets.results as KpiTargets[]
      }
    }
  }, [fetchedTargets])

  const refresh = useCallback(
    (targets: KpiTargets[] | KpiTargetEpics[]) => {
      if (!initialValues.status || !approvalStatuses.includes(initialValues.status)) {
        setStatus(initialValues.status || values.status)
        setTarget(null)
        return
      }
      if (!targets?.length) {
        setStatus(null)
        setTarget(null)
        return
      }
      let newStatus: Statuses | undefined
      let newTarget: KpiTargets | KpiTargetEpics | undefined
      let hasFutureTargets = false

      // eslint-disable-next-line consistent-return
      forEach<KpiTargets | KpiTargetEpics>(targets, t => {
        if (
          t.review_cycle?.status === ReviewCycleStatus.planned &&
          newTarget?.status === Statuses.pending
        ) {
          return false // break
        }
        if (
          t.status &&
          [Statuses.pending, Statuses.requires_changes, Statuses.rejected].includes(
            t.status,
          )
        ) {
          newStatus = t.status
          newTarget = t
        }
        if (t.status === Statuses.completed) {
          newStatus = t.status
        }
        if (t.status === Statuses.approved) {
          newStatus = Statuses.approved
          newTarget = t
        } else if (t.status === Statuses.future) {
          hasFutureTargets = true
        }
      })

      if (!newStatus && hasFutureTargets) {
        newStatus = Statuses.future
      }

      setStatus(newStatus || null)
      setTarget(newTarget)
    },
    [initialValues.status],
  )

  const approvalFlow = useMemo(() => {
    if (!approvalStatuses.includes(values.status) || !approvalsEnabled || !target) {
      return null
    }
    return <ApprovalFlow isLoading={isLoading} steps={approvalSteps || null} />
  }, [isLoading, approvalSteps, values.status, approvalsEnabled, target])

  const value: TargetsApprovalsValue = useMemo(
    () => ({
      status,
      approvalFlow,
      refresh,
      reviewCycle: target?.review_cycle,
    }),
    [status, approvalFlow, refresh, target?.review_cycle],
  )

  return (
    <TargetsApprovalsContext.Provider value={value}>
      {children}
    </TargetsApprovalsContext.Provider>
  )
}

export default TargetsApprovals
