import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export enum MetricSources {
  Jira = 'jira',
  Bitbucket = 'bitbucket',
}

interface IndividualSourceMetricsInterface<T extends MetricSources> {
  id: number
  source: T
  cycle: IdAndName | null
  employee: EmployeeOptionInterface
}

export interface IndividualJiraMetricsInterface
  extends IndividualSourceMetricsInterface<MetricSources.Jira> {
  tickets_closed: number | null // int
  bugs_closed: number | null // int
  story_points: number | null // int
  cycle_participation: number | null // float
  tickets_closed_link: string | null
  bugs_closed_link: string | null
}

export interface OrgUnitJiraMetricsInterface {
  tickets_closed: number | null // int
  bugs_closed: number | null // int
  story_points: number | null // int
  cycle_participation: number | null // float
}

export interface IndividualBitbucketMetricsInterface
  extends IndividualSourceMetricsInterface<MetricSources.Bitbucket> {
  added_lines: number | null // int
  removed_lines: number | null // int
  merged_prs: number | null // int
  reviewed_prs: number | null // int
  comments_added: number | null // int
  average_pr_merge_time_seconds: number | null // int
}

export interface OrgUnitBitbucketMetricsInterface {
  added_lines: number | null // float
  removed_lines: number | null // float
  merged_prs: number | null // float
  reviewed_prs: number | null // float
  comments_added: number | null // float
  average_pr_merge_time_seconds: number | null // float
}
