import React, { useEffect } from 'react'
import { connect, useLape } from 'lape'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CompanyGoalInterface,
  CompanyGoalsWeightsInterface,
} from '@src/interfaces/companyGoals'
import styled from 'styled-components'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import { getCompanyGoals } from '@src/api/companyGoals'
import { selectorKeys } from '@src/constants/api'
import Loader from '@components/CommonSC/Loader'
import { formatNumber } from '@src/utils/format'
import { companyGoalsSubmitRequest } from '@src/api/companyGoalsWeights'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Flex, InputGroup } from '@revolut/ui-kit'
import { useLocation } from 'react-router-dom'
import { canChangeCompanyGoal } from '@src/store/auth/selectors'
import FormLocalstorageLape from '@src/features/Form/FormLocalstorageLape'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import * as yup from 'yup'
import { ReviewCyclesSelectorInterface } from '@src/interfaces/reviewCycles'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const Message = styled(WarningMessage)`
  width: 100%;
  padding-bottom: 22px;
`

const calculateTotal = (companyGoals: CompanyGoalInterface[]): number => {
  return (
    companyGoals.reduce((prev, curr) => {
      return prev + Number(curr.weight)
    }, 0) || 0
  )
}

const validator = {
  company_goals: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        weight: yup.number().required(),
      }),
    )
    .required(),
}

const General = connect(() => {
  const form = useLapeContext<CompanyGoalsWeightsInterface>()
  const { values } = form

  const state = useLape({
    cycleOffset: values.review_cycle?.offset,
    isLoading: !values.company_goals?.length,
    total: 0,
  })

  useEffect(() => {
    values.company_goals = values.company_goals || null
  }, [])

  useEffect(() => {
    if (!values.review_cycle) {
      return
    }
    const currentOffset = values.review_cycle?.offset || state.cycleOffset

    if (!values.company_goals || state.cycleOffset !== currentOffset) {
      state.isLoading = true

      getCompanyGoals(currentOffset)
        .then(response => {
          values.company_goals = response.data.results.filter(({ id }) => id > 0)
          state.isLoading = false
          state.cycleOffset = currentOffset
          state.total = calculateTotal(values.company_goals)
        })
        .catch(() => {
          state.isLoading = false
        })
    }
  }, [values.review_cycle])

  const invalidTotal = state.total !== 100 && state.total !== 0

  return (
    <PageWrapper>
      <PageHeader
        title="Edit Company Goals Weights"
        backUrl={ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL}
      />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            <LapeRadioSelectInput<ReviewCyclesSelectorInterface>
              label="Cycle"
              name="review_cycle"
              selector={selectorKeys.review_cycles}
              clearable={false}
              selectDefaultOption={opts => {
                const found = opts.find(opt => opt.value.offset === '0')

                if (found) {
                  return {
                    ...found,
                    value: {
                      ...found.value,
                      name: `Current cycle (${found.label})`,
                    },
                  }
                }
                return undefined
              }}
              width="calc(70% - 8px)"
            />
            {state.isLoading ? (
              <Flex justifyContent="center" alignItems="center">
                <Loader />
              </Flex>
            ) : (
              <>
                {values.company_goals?.map(({ id, name }, index) => (
                  <InputGroup variant="horizontal" key={`row_${id}`}>
                    <LapeRadioSelectInput
                      name="company_goal"
                      label="Company goal"
                      value={{ id, name }}
                      disabled
                    />
                    <LapeNewInput
                      name={`company_goals[${index}].weight`}
                      label="Weight"
                      hasError={invalidTotal}
                      message=""
                      required
                      type="number"
                      renderAction={() => '%'}
                      onAfterChange={() => {
                        state.total = calculateTotal(values.company_goals!)
                      }}
                      width="30%"
                    />
                  </InputGroup>
                ))}
                {invalidTotal && (
                  <Message>
                    The sum of all weights should be 100%, currently{' '}
                    {formatNumber(state.total)}%
                  </Message>
                )}
              </>
            )}
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL}
        />
      </PageActions>
    </PageWrapper>
  )
})

export default () => {
  const location = useLocation<{ initialValues: any; returnAs: string }>()
  const initialValues = location?.state?.initialValues || {}

  if (!canChangeCompanyGoal) {
    goBack(ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL)
    return null
  }
  return (
    <LapeForm
      validation={validator}
      initialValues={initialValues}
      onSubmit={companyGoalsSubmitRequest}
    >
      <FormLocalstorageLape
        isExistingData={!!initialValues}
        url={ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL}
      />
      <General />
    </LapeForm>
  )
}
