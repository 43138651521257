import { ColumnInterface, CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import {
  getGradeColor,
  PerformanceReviewInterface,
  ReviewTypeToShortTitle,
} from '@src/interfaces/performance'
import Table from '@src/components/TableV2/Table'
import { Tag, Token, Text, Box } from '@revolut/ui-kit'
import { capitalize } from 'lodash'
import TableCellLink from '@src/components/TableCellLink/TableCellLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'
import { Statuses } from '@src/interfaces'

const getTagStatusColor = (status: Statuses): { color: string; bg: string } => {
  switch (status) {
    case Statuses.pending:
      return { color: Token.color.terracotta, bg: Token.color.terracotta_20 }
    default:
      return { color: Token.color.greyTone50, bg: Token.color.greyTone10 }
  }
}

export const performanceReviewEmployeeColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'reviewed_employee.id',
    dataPoint: 'reviewed_employee.full_name',
    sortKey: 'reviewed_employee__full_name',
    filterKey: 'reviewed_employee__id',
    selectorsKey: selectorKeys.employee,
    insert: ({ data }) => (
      <Table.EmployeeCell employee={data.reviewed_employee} size={40} disableLink />
    ),
    title: 'Employee',
  }

export const performanceReviewGradeColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'reviewed_employee.grade',
  dataPoint: 'reviewed_employee.grade',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  masked: true,
  maskTooltip: {
    isShown: (
      <Box p="s-4">
        <Text whiteSpace="nowrap">Click to hide latest grade</Text>
      </Box>
    ),
    isHidden: (
      <Box p="s-4">
        <Text whiteSpace="nowrap">Click to show latest grade</Text>
      </Box>
    ),
  },
  colors: data => {
    if (!data.grade?.id) {
      return Token.color.greyTone50
    }
    return getGradeColor(data.grade.id)
  },
  insert: ({ data }) => <Table.ItemCell>{data.grade?.label || 'n/a'}</Table.ItemCell>,
  title: 'Grade',
}

export const performanceReviewReviewerColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'reviewer.id',
    dataPoint: 'reviewer.full_name',
    sortKey: 'reviewer__full_name',
    filterKey: 'reviewer__id',
    selectorsKey: selectorKeys.employee,
    insert: ({ data }) =>
      data.reviewer?.full_name ? <Table.EmployeeCell employee={data.reviewer} /> : '-',
    title: 'Reviewer',
  }

export const performanceReviewSeniorityColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'eligibility.seniority.id',
    dataPoint: 'eligibility.seniority.name',
    sortKey: 'eligibility__seniority__name',
    filterKey: 'eligibility__seniority__id',
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
    insert: ({ data }) => (
      <Table.SeniorityCell
        seniority={data.eligibility?.seniority?.name || data.seniority.name}
      />
    ),
  }

export const performanceReviewTeamColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'eligibility.team.id',
  dataPoint: 'eligibility.team.name',
  sortKey: 'eligibility__team__name',
  filterKey: 'eligibility__team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) =>
    data.eligibility?.team || data.team ? (
      <TableCellLink
        to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
          id: data.eligibility?.team?.id || data.team.id,
        })}
      >
        {data.eligibility?.team?.name || data.team.name}
      </TableCellLink>
    ) : (
      'n/a'
    ),
}

export const performanceReviewRoleColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'eligibility.role.id',
  dataPoint: 'eligibility.role.name',
  sortKey: 'eligibility__role__name',
  filterKey: 'eligibility__role__id',
  selectorsKey: selectorKeys.role,
  title: 'Role',
  insert: ({ data }) => data.eligibility?.role?.name || data.role?.name || '-',
}

export const performanceReviewStatusColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.scorecards_status,
    title: 'Status',
    insert: ({ data }) => {
      return (
        <Tag variant="faded" {...getTagStatusColor(data.status)}>
          {capitalize(data.status)}
        </Tag>
      )
    },
  }

export const performanceReviewTypeColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'review_type',
  dataPoint: 'review_type',
  sortKey: 'review_type',
  filterKey: 'review_type',
  selectorsKey: selectorKeys.scorecards_review_type,
  insert: ({ data }) =>
    data.review_type ? `${ReviewTypeToShortTitle[data.review_type]} review` : '-',
  title: 'Type',
}
