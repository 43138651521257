import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { StructuresTeamsGeneral } from './TeamsGeneral'
import { StructuresTeamsSettings } from './TeamsSettings'
import { StructuresDepartmentsSettings } from './DepartmentSettings'
import { StructuresTeamGeneralSettings } from './TeamGeneralSettings'

export const StructuresTeams = () => {
  return (
    <Switch>
      <Route path={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL} exact>
        <StructuresTeamsGeneral />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.TEAMS.TEAMS}>
        <StructuresTeamsSettings />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.TEAMS.DEPARTMENTS}>
        <StructuresDepartmentsSettings />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.TEAMS.GENERAL}>
        <StructuresTeamGeneralSettings />
      </Route>
    </Switch>
  )
}
