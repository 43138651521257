import React, { useMemo } from 'react'
import { Flex, Icon, HStack, Text, Token, Widget } from '@revolut/ui-kit'
import { EmployeeTotalCompensationTimeSeriesInterface } from '@src/interfaces/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { BarChart } from '../Charts/BarChart'

export const ON_TARGET_BONUS_LABEL = 'On target bonus*'
export const VESTED_EQUITY_LABEL = 'Vested equity'

type Props = {
  data?: EmployeeTotalCompensationTimeSeriesInterface[]
  isLoading: boolean
  height: string
  minHeight: number
}

const DATA_KEYS = [
  {
    id: 0,
    label: 'Base salary',
    value: 'base_salary',
    color: Token.colorChannel.blue,
  },
  {
    id: 1,
    label: VESTED_EQUITY_LABEL,
    value: 'vested_equity_value',
    color: Token.colorChannel.cyan,
  },
  {
    id: 2,
    label: 'Cash bonus',
    value: 'cash_bonus',
    color: Token.colorChannel.pink,
  },
  {
    id: 3,
    label: 'Sold equity',
    value: 'sold_equity_value',
    color: Token.colorChannel.lime,
  },
  {
    id: 4,
    label: ON_TARGET_BONUS_LABEL,
    value: 'on_target_bonus_value',
    color: Token.colorChannel.orange,
    isProjected: true,
  },
] as const

export const CompensationOverTime = ({ data, isLoading, height, minHeight }: Props) => {
  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(value => {
      return {
        label: '',
        tooltip: '',
        on_target_bonus_value: value.on_target_bonus.value,
        sold_equity_value: value.sold_equity.value,
        vested_equity_value: value.vested_equity.value,
        ...value,
      }
    })
  }, [data])

  const dataKeys = useMemo(() => {
    if (!chartData) {
      return []
    }

    return DATA_KEYS.filter(
      key => chartData.filter(timeSeries => Number(timeSeries[key.value])).length > 0,
    )
  }, [chartData])

  if (!isLoading && !data?.length) {
    return null
  }

  return (
    <Widget
      height={height}
      minHeight={minHeight}
      p="s-16"
      data-testid="compensation_over_time_section"
    >
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Compensation over time</Text>
          <Tooltip
            placement="bottom"
            text="Review your past earnings and expected compensation for future years, including upcoming vested equity, base salary (based on your current contractual details) and on target bonus based on your current role and seniority (1x multiplier, no performance adjustments)."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <BarChart data={chartData} dataKeys={dataKeys} isLoading={isLoading} />
      </Flex>
    </Widget>
  )
}
