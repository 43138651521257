import { TableFilter } from '@src/pages/Forms/CustomFieldsForm/FilterTable/types'
import { FieldOptions, Id, IdAndName, Statuses } from '.'
import { ApprovalStatuses } from './approvalFlow'
import { AvatarType, EmployeeOptionInterface } from './employees'
import { FileInterface } from './files'
import { ImportInterface } from '@src/interfaces/bulkDataImport'

export interface PublicHolidayInterface {
  id: number
  name: string
  country: IdAndName & {
    country_code: string
  }
  region: IdAndName | null
  from_date: string
  to_date: string
  status: IdAndName<'active' | 'archived'>
  creation_date_time: string
  field_options: FieldOptions
}

export interface RegionInterface {
  id: number
  name: string
  country: IdAndName | null
}

export interface RegimeInterface {
  id: number
  name: string
  default_approver: IdAndName<'line_manager' | 'dynamic_group'>
  groups: IdAndName[]
  member_count: number
  public_holidays: IdAndName
  working_days_start: IdAndName
  working_days_end: IdAndName
  default_approver_group: IdAndName | null
  field_options: FieldOptions
}

export interface RegimeMembershipsInterface {
  id: number
  employee: EmployeeOptionInterface
  regime: IdAndName
  creation_date_time: string
}

export interface EmployeeTimeOffPolicyAssignmentInterface {
  id: number
  created_by: EmployeeOptionInterface | null
  assigned_by: EmployeeOptionInterface | null
  creation_date_time: string
  employee: EmployeeOptionInterface
  start_date: string
  end_date: string | null
  group: IdAndName | null
  policy: IdAndName & {
    category: IdAndName
    details_url: string | null
    unit: TimeOffUnit
  }
  status: IdAndName<Statuses>
  first_accrual_amount?: number | null
  last_accrual_amount?: string | null
  unassigned_on: string | null
  unassigned_by: EmployeeOptionInterface | null
  field_options?: FieldOptions
  source: IdAndName<'automatic' | 'manual'>
}

export interface EmployeeTimeOffPolicyAssignmentFirstAccrual {
  amount: number
}

export interface EmployeeTimeOffStats {
  default_approver: EmployeeOptionInterface
  employee: EmployeeOptionInterface
  id: number
  pending_request_count: number
}

export interface TimeOffPolicyTableInterface {
  id: number
  assignment_count: number
  balance_type: IdAndName<'limited' | 'unlimited'>
  category: TimeOffCategoryInterface
  creation_date_time: string
  group: IdAndName | null
  name: string
  paid: IdAndName
  status: IdAndName<Statuses.active | Statuses.archived | Statuses.draft>
  unit: IdAndName
  details_url: string | null
  accrual_amount: number | null
}

export interface TimeOffPoliciesStatsInterface {
  total: number
  active: number
  archived: number
  draft: number
}

export interface CreateTempPolicyAssignmentInterface {
  policy: IdAndName
  groups: IdAndName[]
}

export interface TempPolicyAssignmentInterface {
  id: number
  employee: EmployeeOptionInterface & { email: string }
  policy: IdAndName
  created_by: EmployeeOptionInterface
}

export interface TempPolicyAssignmentsStatusInterface {
  error_message: string | null
  progress: number | null
  total: number | null
  state: 'in_progress' | 'completed' | 'failed'
}

export interface TempPolicyAssignmentsPolicyInterface {
  id: number
  policy: TimeOffPolicyTableInterface
  projected_balance_amount: number | null
}

export interface TempPolicyAssignmentsEmployeeInterface {
  id: number
  avatar?: AvatarType | null
  name?: string
  display_name?: string
  full_name?: string
  location?: IdAndName
  children?: TempPolicyAssignmentsPolicyInterface[]
}

export interface NormalizedTempPolicyAssignmentsEmployeeInterface
  extends Omit<TempPolicyAssignmentsEmployeeInterface, 'children'> {
  children: ImportInterface<TempPolicyAssignmentsPolicyInterface>[]
}

export type ChildTempPolicyAssignmentsEmployeeInterface =
  ImportInterface<TempPolicyAssignmentsPolicyInterface>

export type OnboardingTempPolicyAssignmentsEmployeeInterface =
  | NormalizedTempPolicyAssignmentsEmployeeInterface
  | ChildTempPolicyAssignmentsEmployeeInterface

export interface EmployeePolicyAssignmentsInterface {
  id: number
  avatar: AvatarType | null
  name: string
  display_name: string
  full_name: string
  location?: IdAndName
  open_balances: number
  children: number[]
}

export interface EmployeePolicyAssignmentsPolicyInterface {
  id: number
  assignment_id: number
  available_balance: number | null
  policy: Pick<
    TimeOffPolicyTableInterface,
    'id' | 'balance_type' | 'category' | 'name' | 'unit'
  >
}

export type TimeOffEmployeePolicyAssignmentsInterface =
  | EmployeePolicyAssignmentsInterface
  | EmployeePolicyAssignmentsPolicyInterface

export interface AccrualLevel {
  group: IdAndName | null
  amount: string
}

export interface RequestType {
  id?: number
  name: string
  amount: string
}

export type ApproverId =
  | 'default_approver'
  | 'dynamic_group'
  | 'group'
  | 'employee'
  | 'relationship'
export type ApproverRelationshipType =
  | 'functional_manager'
  | 'line_manager'
  | 'team_department_owner'

export type PolicyApprover = {
  id: number
  approver_type: IdAndName<ApproverId>
  sort_order: number
  groups: IdAndName<string>[]
  employee: IdAndName | null
  relationship: IdAndName<ApproverRelationshipType> | null
}

export type AccrualPeriodType = IdAndName<
  'daily' | 'weekly' | 'monthly' | 'yearly' | 'anniversary'
>

export type CarryOverLimitType = IdAndName<
  | 'no_carryover'
  | 'unlimited_carryover'
  | 'limited_number_of_days'
  | 'limited_number_of_hours'
>

export interface TimeOffPolicyInterface {
  id: number
  name: string
  owner: EmployeeOptionInterface
  category: TimeOffCategoryInterface
  accrual_levels: AccrualLevel[] | null
  request_types: RequestType[] | null
  balance_closing_period: IdAndName<'anniversary' | 'yearly'>
  balance_closing_period_month: IdAndName | null
  balance_closing_period_day_of_month: number | null
  is_approval_required: boolean
  is_attachment_required: boolean
  is_comment_required: boolean
  paid: IdAndName
  group: IdAndName | null
  unit: TimeOffUnit
  prorate_requests_with_fte: boolean
  request_instructions: string
  carryover_limit: CarryOverLimitType | null
  carryover_amount: number | null
  carryover_no_expiry: Id<string> | null
  carryover_expiry_month: IdAndName | null
  carryover_expiry_day_of_month: string | null
  accrual_period: AccrualPeriodType | null
  accrual_period_day_of_month: number | null
  accrual_period_day_of_week: IdAndName | null
  accrual_period_month: IdAndName | null
  accrual_period_monthly: IdAndName<
    'start_of_the_month' | 'end_of_the_month' | 'specific_date'
  >
  balance_type: IdAndName<'limited' | 'unlimited'>
  is_negative_balance_allowed: boolean | null
  can_use_leave_not_accrued: boolean | null
  balance_rounding_precision: IdAndName<
    'integer' | 'half' | 'one_decimal' | 'two_decimal'
  > | null
  balance_rounding_logic: IdAndName<'floor' | 'round' | 'ceil'> | null
  maximum_balance: number | null
  accrual_amount: number | null
  first_accrual: IdAndName | null
  prorate_accruals_with_fte: boolean | null
  status: IdAndName<
    Statuses.active | Statuses.archived | Statuses.pending | Statuses.draft
  >
  field_options?: FieldOptions
  policy_approvers: PolicyApprover[]
  half_days: IdAndName<'yes' | 'no'>
  eligibility_group_type: IdAndName<'eligibility_group' | 'custom_choices'> | null
  auto_assign_group: boolean
  apply_from_employee_start_date: boolean
  custom_filters_group: IdAndName | null
  max_request_duration: number
  attachment_required_threshold: number | null
}

export interface TimeOffPolicyDraftInterface {
  id: number
  draft_has_active_policy: boolean | null
  field_options: FieldOptions
}

export type TimeOffPolicyBasicsStep = TimeOffPolicyDraftInterface &
  Pick<TimeOffPolicyInterface, 'name' | 'category' | 'paid' | 'unit' | 'half_days'>

export type TimeOffPolicyDetailsStep = TimeOffPolicyDraftInterface &
  Pick<
    TimeOffPolicyInterface,
    | 'id'
    | 'accrual_amount'
    | 'accrual_levels'
    | 'accrual_period'
    | 'accrual_period_month'
    | 'accrual_period_day_of_month'
    | 'accrual_period_day_of_week'
    | 'balance_closing_period'
    | 'balance_closing_period_day_of_month'
    | 'balance_closing_period_month'
    | 'balance_rounding_logic'
    | 'balance_rounding_precision'
    | 'can_use_leave_not_accrued'
    | 'carryover_amount'
    | 'carryover_expiry_day_of_month'
    | 'carryover_expiry_month'
    | 'carryover_limit'
    | 'carryover_no_expiry'
    | 'first_accrual'
    | 'is_negative_balance_allowed'
    | 'prorate_accruals_with_fte'
    | 'maximum_balance'
    | 'max_request_duration'
    | 'accrual_period_monthly'
    | 'balance_type'
  >

export type TimeOffPolicyEligibilityStep = TimeOffPolicyDraftInterface &
  Pick<
    TimeOffPolicyInterface,
    | 'eligibility_group_type'
    | 'group'
    | 'auto_assign_group'
    | 'apply_from_employee_start_date'
    | 'custom_filters_group'
  >

export type TimeOffPolicyRequestsStep = TimeOffPolicyDraftInterface &
  Pick<
    TimeOffPolicyInterface,
    | 'request_instructions'
    | 'is_approval_required'
    | 'is_attachment_required'
    | 'is_comment_required'
    | 'attachment_required_threshold'
  >

export interface TimeOffPolicyReviewEligibleEmployeeInterface
  extends EmployeeOptionInterface {
  auto_assignments_details: {
    balance_details: {
      accrual_from_current_period_amount: number
      carried_over_from_previous_period_amount: number
      time_off_taken_amount: number
      current_period_unit: string
      previous_period_unit: string
    }
    current_balance: number | string
    new_balance: number | string
    employee_id: number
    errors?: string[]
    status: IdAndName<
      'policy_will_apply' | 'policy_will_not_apply' | 'policy_will_partially_apply'
    >
  }
}

export interface TimeOffTableFilterInterface {
  table_filters: TableFilter
}

export type TimeOffReviewStats = {
  total: number
  warning: number
  ok: number
}

export type TimeOffPolicyEditStepStatusesInterface = {
  draft_auto_assignments_step_done: boolean
  draft_basic_step_done: boolean
  draft_details_step_done: boolean
  draft_eligibility_step_done: boolean
  draft_requests_step_done: boolean
}

export type TimeOffProcessingStatus = {
  status: IdAndName<'idle' | 'creating_assignments'>
}

export interface EmployeeTimeOffBalancesInterface {
  id: number
  accrued: number | null
  accrued_rounded: number | null
  available_balance: number | null
  balance: number | null
  balance_rounded: number | null
  balance_end_of_cycle: number | null
  balance_end_of_cycle_rounded: number | null
  balance_type: IdAndName<'limited' | 'unlimited'>
  booked: number | null
  taken: number | null
  carried_over: number | null
  cycle_end: string
  cycle_start: string
  employee: EmployeeOptionInterface
  policy: IdAndName & { category: IdAndName; details_url: string | null }
  status: IdAndName<Statuses.open | Statuses.closed | Statuses.pending>
  unit: TimeOffUnit
  request_type_balances: RequestTypeBalance[]
  field_options?: FieldOptions
}

export interface EmployeeTimeOffBalanceTransaction {
  id: number
  balance: IdAndName & { policy: IdAndName & { category: IdAndName } }
  field: IdAndName
  amount: number
  created_by: EmployeeOptionInterface
  source: IdAndName
  source_id: number
  description: string
  creation_date_time: string
}

export type EmployeeTimeOffBalanceCycleStatusId = 'current' | 'future' | 'past'

export interface EmployeeTimeOffBalanceCycle {
  id: number
  end: string
  start: string
  status: IdAndName<EmployeeTimeOffBalanceCycleStatusId>
}

export interface EmployeeTimeOffBalanceCycleStats {
  available: number
  booked: number
  end: string
  id: number
  start: string
  status: IdAndName<EmployeeTimeOffBalanceCycleStatusId>
  taken: number
  to_be_earned: number
}

export type TimeOffUnit = IdAndName<'day' | 'hour'>
export type TimeOffPeriods = 'all_day' | 'morning' | 'afternoon'

export interface EmployeeTimeOffRequestInterface {
  id: number
  duration: number
  total_duration: number
  approval_date_time: string | null
  approval_status: IdAndName<ApprovalStatuses>
  approval_step: IdAndName
  approver: EmployeeOptionInterface
  attachment?: FileInterface | File | null
  balance: {
    id: number
    available_balance?: number | null
    name: string
    unit?: TimeOffUnit
    half_days?: IdAndName<'yes' | 'no'> | null
    policy: {
      id: number
      name: string
      is_approval_required: boolean
      is_attachment_required: boolean
      is_comment_required: boolean
      request_instructions: string | null
      details_url: string | null
      category?: TimeOffCategoryInterface
    } | null
  } | null
  employee: EmployeeOptionInterface
  request_type_balance?: RequestTypeBalance | null
  creation_date_time: string
  from_date_time?: string
  from_time_period: IdAndName<TimeOffPeriods> | null
  to_date_time?: string
  to_time_period: IdAndName<TimeOffPeriods> | null
  note: string
  status: IdAndName<ApprovalStatuses>
  unit: TimeOffUnit | null
  field_options?: FieldOptions
  category: TimeOffCategoryInterface | null
  policy: {
    category: TimeOffCategoryInterface
  }
}

export interface EmployeeTimeOffRequestSummaryInterface {
  id: number
  approval_status: IdAndName<ApprovalStatuses>
  category: IdAndName<string>
  from_date_time: string
  to_date_time: string
  field_options: FieldOptions
}

export interface TeamTimeOffRequestsInterface {
  id: number
  duration: number
  approval_date_time: string | null
  approval_status: IdAndName<ApprovalStatuses>
  approval_step: IdAndName
  approver: EmployeeOptionInterface
  attachment?: FileInterface | File | null
  policy: {
    category: TimeOffCategoryInterface
  }
  employee: EmployeeOptionInterface
  request_type_balance?: RequestTypeBalance | null
  creation_date_time: string
  from_date_time?: string
  from_time_period: IdAndName<TimeOffPeriods> | null
  to_date_time?: string
  to_time_period: IdAndName<TimeOffPeriods> | null
  note: string
  status: IdAndName<ApprovalStatuses>
  unit: TimeOffUnit
}

export interface NonWorkingDay {
  name: string
  day: string
  type: 'non_working_day' | 'public_holiday'
}

export interface TimeOffPolicyRequestSettingsInterface {
  id: number
  name: string
  unit: TimeOffUnit
  is_approval_required: boolean
  is_attachment_required: boolean
  is_comment_required: boolean
  request_instructions: string
  half_days: IdAndName<'yes' | 'no'> | null
  details_url: string | null
}

interface RequestTypeBalance {
  available: number
  id: number
  name: string
  request_type: IdAndName & { amount: number }
}

export interface EmployeeTimeOffBalanceSelectorOption {
  id: number
  name: string
  unit: TimeOffUnit
  balance_rounded: number
  policy: TimeOffPolicyRequestSettingsInterface
  request_type_balances: RequestTypeBalance[]
}

export interface AdjustmentFieldInterface extends IdAndName {
  available?: number | null
}

export interface EmployeeTimeOffAdjustBalanceInterface {
  id: number
  adjustment_type: { id: 'add' | 'subtract' }
  amount: string
  field?: AdjustmentFieldInterface
  balance: { id: number } | EmployeeTimeOffBalanceSelectorOption
  unit: TimeOffUnit
}

export enum RequestOfReportsStatuses {
  Completed = 'completed',
  InProgress = 'in_progress',
  Upcoming = 'upcoming',
}

export interface RequestOfReportsInterface {
  id: number
  employee: EmployeeOptionInterface
  team: IdAndName
  from_date_time: string
  to_date_time: string
  from_time_period?: IdAndName<TimeOffPeriods>
  to_time_period?: IdAndName<TimeOffPeriods>
  duration: number
  policy: IdAndName & {
    category: IdAndName
    paid: IdAndName<string>
  }
  requested_on: string
  approval_status: IdAndName<ApprovalStatuses>
  status: IdAndName<RequestOfReportsStatuses> | null
  unit: TimeOffUnit
}

export interface RequestOfReportsStatsInterface {
  pending_request_count: number
}

export interface BulkAssignInterface {
  policy: IdAndName
  group: IdAndName
  start_date: string
  end_date: string
  force_use_employee_join_date?: boolean
  id?: number
}

export type EmployeeTimeOffRequestCalendar = {
  id: number
  day: string
  category: IdAndName
} & Pick<
  RequestOfReportsInterface,
  | 'from_date_time'
  | 'from_time_period'
  | 'to_date_time'
  | 'to_time_period'
  | 'approval_status'
  | 'unit'
>

export type PublicHolidayCalendarItem = IdAndName & { day: string }

export interface EmployeeTimeOffRequestsCalendarInterface {
  id: number
  employee: EmployeeOptionInterface
  non_working_days: string[]
  public_holidays: PublicHolidayCalendarItem[]
  time_off_requests: EmployeeTimeOffRequestCalendar[]
}

export interface TimeOffCategoryInterface {
  id: number
  name: string
  description?: string
  is_archived?: boolean
  timeoff_includes_all_days?: boolean
}

export interface EmployeeTimeOffRequestPreviewInterface {
  attachment: {
    message: number | null
    required: boolean
  }
  duration: {
    duration: number | null
    error: string | null
    unit: TimeOffUnit
  }
}
