import React, { useMemo, useState } from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import {
  hiringProcessActionColumn,
  hiringProcessDurationColumn,
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSeniorityColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
  hiringProcessTypeColumn,
} from '@src/constants/columns/hiringProcess'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { RowInterface } from '@src/interfaces/data'
import HiringStagePreviewForm from '@src/pages/Settings/HiringProcess/HiringStagePreviewForm'
import { AddHiringStage } from '@src/pages/Settings/HiringProcess/AddHiringStage'
import { useLocation } from 'react-router-dom'
import { DefaultHiringProcessLocationState } from '@src/pages/Settings/HiringProcess/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessSettingsInterface } from '@src/interfaces/settings'
import { TextButton } from '@revolut/ui-kit'
import { useOrdering } from '@src/components/Table/hooks'

const getHiringProcessRow = (
  onDelete: (data: HiringProcessInterface) => void,
): RowInterface<HiringProcessInterface> => ({
  cells: [
    dragIconColumn,
    {
      ...hiringProcessTitleWithLockColumn,
      width: 185,
    },
    {
      ...hiringProcessTypeColumn,
      width: 125,
    },
    {
      ...hiringProcessDurationColumn,
      width: 100,
    },
    {
      ...hiringProcessSeniorityColumn,
      width: 106,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 200,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 90,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 139,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 240,
    },
    {
      ...hiringProcessActionColumn,
      width: 100,
      insert: ({ data }) => (
        <TextButton
          onClick={e => {
            e.stopPropagation()
            onDelete(data)
          }}
        >
          Remove
        </TextButton>
      ),
    },
  ],
})

export const DefaultHiringProcess = () => {
  const { values } = useLapeContext<HiringProcessSettingsInterface>()
  const location = useLocation<DefaultHiringProcessLocationState | undefined>()
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(
    location.state?.stageId,
  )

  const changeOrder = (rounds: HiringProcessInterface[]) => {
    values.hiring_process_rounds = rounds
  }

  const ordering = useOrdering(values.hiring_process_rounds || [], changeOrder)

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      setSelectedStageId(data.id)
    }
  }

  const hiringProcessRow = useMemo(() => {
    const handleRowDelete = (data: HiringProcessInterface) => {
      values.hiring_process_rounds = [...(values.hiring_process_rounds || [])].filter(
        ({ id }) => id !== data.id,
      )
    }
    return getHiringProcessRow(handleRowDelete)
  }, [])

  return (
    <>
      <HiringStagePreviewForm
        id={selectedStageId}
        onClose={() => setSelectedStageId(undefined)}
      />
      <Table.Widget>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <AddHiringStage />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<HiringProcessInterface>
            name={TableNames.DefaultHiringProcess}
            dataType="Hiring stage"
            useWindowScroll
            row={hiringProcessRow}
            data={values?.hiring_process_rounds || []}
            count={values?.hiring_process_rounds?.length || 0}
            orderingMode
            {...ordering}
            mainColumnIndex={1}
            disabledFiltering
            onRowClick={handleRowEdit}
            emptyState={<EmptyTableRaw title="Hiring stages will appear here" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}
