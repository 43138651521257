import { ROUTES } from '@src/constants/routes'
import { PermissionTypes, FeatureFlags, GlobalSettings } from '@src/store/auth/types'
import {
  HomeSectionId,
  TabBarWithPinningTabInterface,
  Subtab,
} from '@src/interfaces/tabPinning'
import TeamsTable from '@src/pages/Organisation/OrganisationSubTabs/Teams/TeamsTable'
import DepartmentsTable from '@src/pages/Organisation/OrganisationSubTabs/Departments/DepartmentsTable'
import RolesTable from '@src/pages/Organisation/OrganisationSubTabs/Roles/RolesTable'
import FunctionsTable from '@src/pages/Organisation/OrganisationSubTabs/Functions/FunctionsTable'
import SpecialisationsTable from '@src/pages/Organisation/OrganisationSubTabs/Specialisations/SpecialisationsTable'
import CompanyRoadmap from '@src/pages/Forms/Company/Roadmap/Roadmap'
import { CompanyAnalyticsDashboards } from '@src/pages/Forms/Company/AnalyticsDashboards/AnalyticsDashboards'
import { Goals } from '@src/pages/Organisation/OrganisationSubTabs/Goals/Goals'
import { Risk } from '@src/pages/Organisation/OrganisationSubTabs/Risk/Risk'
import { Engagement } from '@src/pages/Organisation/OrganisationSubTabs/Engagement/Engagement'
import Talent from '@src/pages/Organisation/OrganisationSubTabs/Talent/Talent'
import { Issues } from '@src/pages/Organisation/OrganisationSubTabs/Issues/Issues'

export const teamsTabs: Subtab[] = [
  {
    id: HomeSectionId.departments,
    title: 'Departments',
    path: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
    to: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
    permission: PermissionTypes.ViewDepartment,
    component: DepartmentsTable,
  },
  {
    id: HomeSectionId.teams,
    title: 'Teams',
    path: ROUTES.ORGANISATION.TEAMS.TEAMS,
    to: ROUTES.ORGANISATION.TEAMS.TEAMS,
    permission: PermissionTypes.ViewTeams,
    component: TeamsTable,
  },
]

const rolesTabs: Subtab[] = [
  {
    id: HomeSectionId.functions,
    title: 'Functions',
    path: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
    to: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
    permission: PermissionTypes.ViewFunction,
    component: FunctionsTable,
  },
  {
    id: HomeSectionId.roles,
    title: 'Roles',
    path: ROUTES.ORGANISATION.ROLES.ROLES,
    to: ROUTES.ORGANISATION.ROLES.ROLES,
    permission: PermissionTypes.ViewRoles,
    component: RolesTable,
  },
  {
    id: HomeSectionId.specialisations,
    title: 'Specialisations',
    path: ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
    to: ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
    permission: PermissionTypes.ViewRoles,
    component: SpecialisationsTable,
  },
]

export const companyTabs: Subtab[] = [
  {
    id: HomeSectionId.companyGoals,
    title: 'Goals',
    path: ROUTES.ORGANISATION.COMPANY.GOALS.ANY,
    to: ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL,
    component: Goals,
  },
  {
    id: HomeSectionId.companyRoadmap,
    title: 'Roadmap',
    path: ROUTES.ORGANISATION.COMPANY.ROADMAP,
    to: ROUTES.ORGANISATION.COMPANY.ROADMAP,
    globalSetting: GlobalSettings.RoadmapsEnabled,
    component: CompanyRoadmap,
  },
  {
    id: HomeSectionId.companyTalent,
    title: 'Talent',
    path: ROUTES.ORGANISATION.COMPANY.TALENT.ANY,
    to: ROUTES.ORGANISATION.COMPANY.TALENT.PERFORMANCE,
    permission: PermissionTypes.GlobalTalentViewerPermissions,
    component: Talent,
  },
  {
    id: HomeSectionId.companyAnalytics,
    title: 'Analytics',
    path: ROUTES.ORGANISATION.COMPANY.ANALYTICS,
    to: ROUTES.ORGANISATION.COMPANY.ANALYTICS,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    component: CompanyAnalyticsDashboards,
  },
  {
    id: HomeSectionId.companyIssues,
    title: 'Issues',
    path: ROUTES.ORGANISATION.COMPANY.ISSUES,
    to: ROUTES.ORGANISATION.COMPANY.ISSUES,
    featureFlag: FeatureFlags.Findings,
    component: Issues,
  },
  {
    id: HomeSectionId.companyRisk,
    title: 'Risk',
    path: ROUTES.ORGANISATION.COMPANY.RISK.ANY,
    to: ROUTES.ORGANISATION.COMPANY.RISK.SUMMARY,
    permission: PermissionTypes.RiskManagerPermissions,
    globalSetting: GlobalSettings.CommercialProductDisabled,
    component: Risk,
  },
  {
    id: HomeSectionId.companyEngagement,
    title: 'Engagement',
    path: ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.ANY,
    to: ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.CATEGORIES,
    globalSetting: GlobalSettings.EngagementEnabled,
    permission: PermissionTypes.ViewEngagementTabs,
    component: Engagement,
  },
]

export const organisationSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.teams,
    title: 'Teams',
    path: ROUTES.ORGANISATION.TEAMS.ANY,
    url: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
    icon: 'People',
    subtabs: teamsTabs,
    isNewNavigation: true,
    hideNavigation: true,
  },
  {
    id: HomeSectionId.roles,
    title: 'Roles',
    path: ROUTES.ORGANISATION.ROLES.ANY,
    url: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
    icon: 'RepairTool',
    subtabs: rolesTabs,
    isNewNavigation: true,
    hideNavigation: true,
  },
  {
    id: HomeSectionId.company,
    title: 'Company',
    path: ROUTES.ORGANISATION.COMPANY.ANY,
    url: ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL,
    icon: 'Bank',
    subtabs: companyTabs,
    isNewNavigation: true,
    hideNavigation: true,
  },
]
