import { useGetCareersFeatures } from '@src/api/recruitment/careers'
import { CareersFeature } from '@src/interfaces/careers'

export const useAiInterviewSummarisationCandidateEnabled = () => {
  const { data: features } = useGetCareersFeatures()

  return !!features?.feature_settings.includes(
    CareersFeature.ai_interview_summarisation_candidate,
  )
}
