import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { navigateTo } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetRequisitionRecruiter } from '@src/api/recruitmentGroups'
import { useState } from 'react'

export const getBackUrl = (
  params: JobPostingFlowParams,
  locationState?: JobPostingLocationState,
) => {
  if (params.id && locationState?.specialisationId) {
    return pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      id: params.id,
      specId: locationState.specialisationId,
    })
  }
  if (locationState?.requisitionId) {
    return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
      id: locationState.requisitionId,
    })
  }
  if (locationState?.specialisationId) {
    return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, {
      id: locationState.specialisationId,
    })
  }
  return pathToUrl(ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS)
}

export const getJobPostingFlowPath = (
  id?: string | number,
  route = ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS,
) => pathToUrl(route, { id, action: id ? 'edit' : 'create' })

export const navigateToJobPostingFlow = (
  { specialisationId, requisitionId, name }: JobPostingLocationState,
  id?: string | number,
  route?: string,
) => {
  const state = {
    requisitionId,
    specialisationId,
    name,
  }
  navigateTo(getJobPostingFlowPath(id, route), state)
}

export const useRecruiter = (specialisationId?: number | string, enabled = false) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [recruiterId, setRecruiterId] = useState<number>()
  const { data, isLoading } = useGetRequisitionRecruiter({
    specialisationId,
    enabled,
  })
  if (enabled && data && data?.recruiter?.id !== recruiterId) {
    setRecruiterId(data.recruiter.id)
    values.recruiter = data.recruiter
  }
  return {
    isLoading,
  }
}
