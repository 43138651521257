import React from 'react'
import {
  Ratings,
  ReviewDataInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { Group, VStack, Widget } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import get from 'lodash/get'
import {
  CommonSectionProps,
  getJustificationMessage,
  getRoundedRating,
  kpiRating,
  DeliverableGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { relationToString } from '@components/ScorecardGeneral/constants'
import BottomText from '@components/Inputs/partials/BottomText'
import { GradesMapInterface } from '@src/utils/grades'
import ExpandableText from '@components/ExpandableText/ExpandableText'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface KpiProps extends CommonSectionProps {
  kpiGrades: DeliverableGradeOption[]
  justificationOnly?: boolean
  showJustificationError?: boolean
  onSelectGrade?: (
    reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
    grade: DeliverableGradeOption,
  ) => void
  gradesMap: GradesMapInterface
}

export const KPI = connect(
  ({
    reviewData,
    isViewMode = false,
    kpiGrades,
    justificationOnly = false,
    showJustificationError,
    onSelectGrade,
    gradesMap,
  }: KpiProps) => {
    if (!reviewData) {
      return null
    }
    const getFieldDetails = (option: DeliverableGradeOption) => {
      const ratingValue = get(reviewData, 'kpis_section.recommended_rating')
      const isChecked =
        kpiRating.find(item => item.key === ratingValue)?.key === option.key

      return { isChecked, ratings: [] }
    }

    const getViewFieldDetails = (option: DeliverableGradeOption) => {
      const ratings =
        get(reviewData, 'kpis_section.recommended_ratings')?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const isChecked = !!ratings.length

      return { isChecked, ratings }
    }

    const selectedRating = reviewData.kpis_section?.recommended_rating
    const selectedGrade = kpiGrades.find(item => item.key === selectedRating)
    const justificationMessage = getJustificationMessage(false, selectedGrade)

    return (
      <VStack width="100%" space="s-16">
        {!justificationOnly && (
          <Widget>
            <Group style={transparentThemeBackgroundOverrides}>
              {kpiGrades.map((option, optionIdx) => {
                const { isChecked, ratings } = isViewMode
                  ? getViewFieldDetails(option)
                  : getFieldDetails(option)
                return (
                  <DeliverablesGrade
                    key={optionIdx}
                    isViewMode={isViewMode}
                    isChecked={isChecked}
                    ratings={ratings}
                    option={option}
                    onChange={() => onSelectGrade?.(reviewData, option)}
                    gradesMap={gradesMap}
                  />
                )
              })}
            </Group>
          </Widget>
        )}
        {isViewMode ? (
          <ExpandableText
            list={
              reviewData?.kpis_section?.comments?.map((comment, i) => ({
                title: comment.review.reviewer.full_name,
                additionalInfo: comment.review?.reviewer_relation
                  ? relationToString(comment.review?.reviewer_relation, true)
                  : undefined,
                items: [{ index: i, text: comment.value || '' }],
              })) || []
            }
          />
        ) : (
          <>
            <LapeNewTextArea
              rows={2}
              label={justificationMessage}
              name="review_data.kpis_section.comment"
              data-testid="comment"
              hideOptional
              hasError={showJustificationError}
            />
            {showJustificationError && <BottomText error="Please add justification" />}
          </>
        )}
      </VStack>
    )
  },
)
