import React from 'react'
import { createChain, Ellipsis, HStack, Icon, Text, Token } from '@revolut/ui-kit'
import { formatSnakeCase } from '@src/utils/string'
import { getIconProps, isNull } from './utils'

const MAX_PREVIEW_WIDTH_MOBILE = 150

interface Props {
  from: string | null
  isMobile: boolean
  to: string | null
}

export const ValuesDiff = ({ from, isMobile, to }: Props) => {
  const arrowChain = createChain(<Icon name="ArrowThinRight" size={12} />)
  const isNullFrom = isNull(from)
  const isNullTo = isNull(to)

  const textFrom = <Text variant="emphasis1">{formatSnakeCase(from)}</Text>
  const textTo = (
    <Text color={isNullTo ? Token.color.greyTone50 : undefined} variant="emphasis1">
      {isNullTo ? 'n/a' : formatSnakeCase(to)}
    </Text>
  )

  return (
    <HStack align="center" space="s-4">
      <Icon {...getIconProps(from)} size={15} />
      {arrowChain(
        isNullFrom ? undefined : isMobile ? (
          <Ellipsis maxWidth={MAX_PREVIEW_WIDTH_MOBILE} tooltip="auto">
            {textFrom}
          </Ellipsis>
        ) : (
          textFrom
        ),
        isMobile ? (
          <Ellipsis maxWidth={MAX_PREVIEW_WIDTH_MOBILE} tooltip="auto">
            {textTo}
          </Ellipsis>
        ) : (
          textTo
        ),
      )}
    </HStack>
  )
}
