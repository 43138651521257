import React from 'react'
import { Flex } from '@revolut/ui-kit'

import { Text } from '@src/pages/Landing/components/Text'
import { BookDemoButton } from '@src/pages/Landing/components/BookDemoButton'
import { TakeAProductTourButton } from '@src/pages/Landing/components/TakeAProductTourButton'

interface Props {
  tourUrl?: string
}

export const NextStep = ({ tourUrl }: Props) => {
  return (
    <Flex
      px="s-32"
      pb="s-64"
      pt={{ lg: 's-64', all: '0' }}
      flexDirection="column"
      gap="s-48"
      alignItems="center"
    >
      <Text variant="h1">Ready to take the next step?</Text>

      <Flex gap="s-32">
        <BookDemoButton />
        {tourUrl ? <TakeAProductTourButton url={tourUrl} /> : null}
      </Flex>
    </Flex>
  )
}
