import React, { useEffect, useState } from 'react'
import { TabBar, VStack } from '@revolut/ui-kit'
import CultureFitHelp from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CultureFitHelp'
import SkillsHelp from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/SkillsHelp'
import DeliverablesHelp from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/DeliverablesHelp'
import useIsCommercial from '@src/hooks/useIsCommercial'

export enum HelpTabs {
  Deliverables = 'Deliverables',
  Skills = 'Skills',
  Values = 'Values',
}

const renderTab = (tab?: HelpTabs, isCommercial?: boolean) => {
  switch (tab) {
    case HelpTabs.Deliverables:
      return <DeliverablesHelp isCommercial={isCommercial} />
    case HelpTabs.Skills:
      return <SkillsHelp isCommercial={isCommercial} />
    case HelpTabs.Values:
      return <CultureFitHelp isCommercial={isCommercial} />
    default:
      return null
  }
}

export const CombinedHelp = ({ initialTab }: { initialTab?: HelpTabs }) => {
  const [tab, setTab] = useState<HelpTabs | undefined>(initialTab)
  const isCommercial = useIsCommercial()

  useEffect(() => {
    if (tab !== initialTab) {
      setTab(initialTab)
    }
  }, [initialTab])

  return (
    <VStack space="s-16">
      <TabBar
        variant="segmented fit"
        value={tab}
        onChange={val => {
          if (val) {
            setTab(val as HelpTabs)
          }
        }}
      >
        <TabBar.Item to={HelpTabs.Deliverables}>Deliverables</TabBar.Item>
        <TabBar.Item to={HelpTabs.Skills}>Skills</TabBar.Item>
        <TabBar.Item to={HelpTabs.Values}>Values</TabBar.Item>
      </TabBar>
      {renderTab(tab, isCommercial)}
    </VStack>
  )
}
