import React from 'react'
import { VStack, Text, createChain, Box } from '@revolut/ui-kit'

interface Props {
  industry: string
  size: string
  headquarters: string
  bullets: string[]
}

export const FeatureBlockSummary = ({ industry, size, headquarters, bullets }: Props) => {
  return (
    <VStack space="s-16">
      <Box>
        {createChain(' | ')(
          <Text whiteSpace="pre">💼 Industry: {industry}</Text>,
          <Text whiteSpace="pre">📈 Size: {size}</Text>,
          <Text whiteSpace="pre">📍 Headquarters: {headquarters}</Text>,
        )}
      </Box>

      <VStack space="s-4">
        {bullets.map(bullet => (
          <Text key={bullet}>
            ✅{'  '}
            {bullet}
          </Text>
        ))}
      </VStack>
    </VStack>
  )
}
