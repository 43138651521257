import React from 'react'

import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import {
  DepartmentSettingsFields,
  DepartmentSettingsSaveButton,
} from '@src/pages/Settings/Organisation/Departments/common'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { organisationSettings } from '@src/api/settings'

const StructuresDepartmentsSettingsForm = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Departments settings"
        subtitle="Manage department functionality and approval settings"
        backUrl={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL}
      />

      <PageBody>
        <DepartmentSettingsFields />
      </PageBody>

      <PageActions>
        <DepartmentSettingsSaveButton />
      </PageActions>
    </PageWrapper>
  )
}

export const StructuresDepartmentsSettings = connect(() => {
  return (
    <Form api={organisationSettings} forceParams={{ id: '1' }}>
      <StructuresDepartmentsSettingsForm />
    </Form>
  )
})
