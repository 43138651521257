import {
  HStack,
  Text,
  VStack,
  Token,
  DataPoint,
  Icon,
  RadioGroup,
  Radio,
  Flex,
  Spacer,
  Box,
} from '@revolut/ui-kit'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { PercentageWrapper } from '@src/components/CommonSC/TableStatistics'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiRatingColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { TableNames } from '@src/constants/table'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { KpiInterface } from '@src/interfaces/kpis'
import { PerformanceRating } from '@src/interfaces/performance'
import { ratingToColor } from '@src/utils/performance'
import { AxiosPromise } from 'axios'
import React from 'react'
import { chartData, deliverables } from './example'
import { CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import SectionTitle from '../../components/SectionTitle'
import styled from 'styled-components'
import Table from '@src/components/TableV2/Table'

const Bold = styled('strong')`
  font-weight: 600;
`

export const Deliverables = () => {
  return (
    <Box>
      <SectionTitle
        title={
          <HStack>
            KPIs
            <Spacer width="s-8" />
            <InfoIconWithTooltip
              tooltipProps={{ width: 250 }}
              color={Token.color.greyTone50}
              content="KPIs that are assigned at employee level get calculated into the scorecard. This section will be hidden if no KPIs are assigned."
            />
          </HStack>
        }
      />
      <Table.Widget>
        <Table.Widget.Info>
          <DataPoint>
            <DataPoint.Value use="h5">
              <HStack space="s-8">
                <GraphIconChart
                  id={1}
                  fetchData={() =>
                    Promise.resolve({
                      data: chartData,
                    }) as unknown as AxiosPromise<PerformanceChartCycles>
                  }
                >
                  <PercentageWrapper>
                    <Text color={Token.color.lightGreen} variant="h5">
                      100%
                    </Text>
                    <Icon name="BarChart" color={Token.color.greyTone50} />
                  </PercentageWrapper>
                </GraphIconChart>
              </HStack>
            </DataPoint.Value>
            <DataPoint.Label>Overall progress</DataPoint.Label>
          </DataPoint>
          <DataPoint>
            <DataPoint.Value use="h5">Intermediate +</DataPoint.Value>
            <DataPoint.Label>Overall rating</DataPoint.Label>
          </DataPoint>
        </Table.Widget.Info>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.KPIs}
            count={4}
            data={
              [
                {
                  name: 'Sales examples',
                  weight: 30,
                  id: 1,
                  performance: 1.2,
                  rating: PerformanceRating.basic,
                },
                {
                  name: 'Operational examples',
                  weight: 20,
                  id: 2,
                  performance: 0.9,
                  rating: PerformanceRating.intermediate,
                },
                {
                  name: 'SLA Authorisation Rate',
                  weight: 25,
                  id: 3,
                  performance: 1,
                  rating: PerformanceRating.advanced,
                },
                {
                  name: 'Trust KPI Q4 2020',
                  weight: 25,
                  id: 4,
                  performance: 1,
                  rating: PerformanceRating.expert,
                },
              ] as unknown as KpiInterface[]
            }
            row={{
              cells: [
                {
                  ...kpiGenericNameColumn,
                  title: 'KPI',
                  type: CellTypes.text,
                  selectorsKey: selectorKeys.none,
                  width: 200,
                  dynamicHyperlinks: undefined,
                },
                {
                  ...kpiWeightColumn,
                  width: 50,
                },
                {
                  ...kpiPerformanceColumn,
                  width: 100,
                  insert: ({ data }) => {
                    return (
                      <GraphIconChart
                        id={data.id}
                        fetchData={() =>
                          Promise.resolve({
                            data: chartData,
                          }) as unknown as AxiosPromise<PerformanceChartCycles>
                        }
                      >
                        <Flex width={75} justifyContent="space-between">
                          <PercentageWrapper>
                            <ColoredPercent percent={data.performance * 100} />
                            <Icon name="BarChart" size={16} />
                          </PercentageWrapper>
                        </Flex>
                      </GraphIconChart>
                    )
                  },
                },
                {
                  ...kpiRatingColumn,
                  width: 150,
                  background: data =>
                    ratingToColor(data.rating) || Token.color.background,
                },
              ],
            }}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <SectionTitle
        title={
          <HStack>
            Deliverables
            <Spacer width="s-8" />
            <InfoIconWithTooltip
              tooltipProps={{ width: 250 }}
              color={Token.color.greyTone50}
              content="Employees without KPIs on their profile will be assessed based on the complexity, speed, and quality of deliverables."
            />
          </HStack>
        }
      />
      <VStack space="s-16">
        {deliverables.map((card, idx) => (
          <VStack key={card.title} space="s-16" pl="s-16" pr="s-16">
            <Text fontSize="18px" fontWeight={600}>
              {idx + 1}. {card.title}
            </Text>
            <RadioGroup defaultValue={card.default}>
              {group => (
                <VStack space="s-16">
                  {card.options.map(({ rating, description }) => (
                    <Radio {...group.getInputProps({ value: rating })} key={rating}>
                      <Text variant="secondary">
                        <Bold>{rating}</Bold> - {description}
                      </Text>
                    </Radio>
                  ))}
                </VStack>
              )}
            </RadioGroup>
          </VStack>
        ))}
      </VStack>
    </Box>
  )
}
