import React, { useEffect, useState } from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import { Flex, InputGroup, Input } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { IdAndName } from '@src/interfaces'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import AutoStepper from '@components/Stepper/AutoStepper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import EmployeeEmailForm from '@src/features/EmployeeEmailForm/EmployeeEmailForm'
import { selectUser } from '@src/store/auth/selectors'
import { SendEmployeeEmailCommonProps } from './SendEmployeeEmailCommon'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  EmployeeEmailTemplateCategories,
  EmployeeWhenToSend,
  SendEmployeeEmailInterface,
} from '@src/interfaces/employeeEmails'
import { isBeforeCurrent } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { getDate, getTime, getCurrentTimezone } from '@src/utils/timezones'

interface TemplateOption {
  id: string
  category: { id: EmployeeEmailTemplateCategories }
}

interface Props
  extends Pick<
    SendEmployeeEmailCommonProps,
    'children' | 'noEditor' | 'bottom' | 'templateFilter' | 'useOnboardingSettings'
  > {}

const SendEmployeeEmailCommonContent = ({
  children,
  noEditor,
  bottom,
  templateFilter,
  useOnboardingSettings,
}: Props) => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<SendEmployeeEmailInterface>()

  const [filterKey, setFilterKey] = useState<IdAndName<EmployeeEmailTemplateCategories>>()
  const [date, setDate] = useState(getDate(new Date()))
  const [time, setTime] = useState(
    getTime(
      values.custom_sending_datetime
        ? new Date(values.custom_sending_datetime)
        : new Date(),
    ),
  )

  const timezoneId = getCurrentTimezone()
  const onCustomSendingDateTimeChange = (newDate: string, newTime: string) => {
    const utc = zonedTimeToUtc(`${newDate} ${newTime}`, timezoneId)
    values.custom_sending_datetime = utc.toISOString()
  }

  const { options: templateCategoriesOptions } = useFetchOptions<{
    id: EmployeeEmailTemplateCategories
    name: string
  }>(selectorKeys.employee_email_template_categories)

  useEffect(() => {
    if (templateFilter && !values.category) {
      values.category =
        templateCategoriesOptions.find(({ value }) =>
          templateFilter({
            id: value.id,
            category: {
              id: value.id,
            },
          }),
        )?.value || null
    }
  }, [templateCategoriesOptions, templateFilter])

  useEffect(() => {
    if (
      values.when_to_send?.id === EmployeeWhenToSend.CUSTOM &&
      !values.custom_sending_datetime
    ) {
      onCustomSendingDateTimeChange(date, time)
    }
  }, [values.when_to_send])

  const emailTemplateFilter = (optionValue: TemplateOption) => {
    if (templateFilter) {
      return templateFilter(optionValue)
    }
    if (filterKey) {
      return optionValue.category.id === filterKey.id
    }
    return true
  }

  const timeError = isBeforeCurrent({ day: date, time }, timezoneId)
    ? 'The time is in the past'
    : ''

  return (
    <AutoStepper>
      {children}

      {!noEditor && (
        <>
          <NewStepperTitle title="Email details" type={NewStepperTitleType.compact} />
          <InputGroup>
            {templateFilter ? null : (
              <RadioSelectInput<IdAndName<EmployeeEmailTemplateCategories>>
                label="Category"
                onChange={option => {
                  if (option) {
                    values.email_template = null
                    values.category = option
                    setFilterKey(option)
                  }
                }}
                value={filterKey || null}
                options={templateCategoriesOptions}
              />
            )}
            {filterKey?.id === null ? null : (
              <LapeRadioSelectInput<TemplateOption>
                name="email_template"
                label="Email template"
                selector={selectorKeys.employee_email_templates_active}
                filter={emailTemplateFilter}
              />
            )}
            <LapeRadioSelectInput
              name="when_to_send"
              label="When to send"
              selector={selectorKeys.employee_email_when_to_send}
            />
            {values.when_to_send?.id === EmployeeWhenToSend.CUSTOM && (
              <InputGroup variant="horizontal">
                <Flex flex="1">
                  <LapeDatePickerInput
                    clearable={false}
                    disabledDays={{ before: new Date() }}
                    displayInUtc={false}
                    label="Schedule date"
                    name="custom_sending_datetime"
                    onChange={e => {
                      if (e) {
                        const value = getDate(e)
                        setDate(value)
                        onCustomSendingDateTimeChange(value, time)
                      }
                    }}
                    required
                  />
                </Flex>
                <Flex flex="1">
                  <Input
                    errorMessage={timeError}
                    invalid={!!timeError}
                    label="Schedule time"
                    onChange={e => {
                      const value = e.currentTarget.value
                      setTime(value)
                      onCustomSendingDateTimeChange(date, value)
                    }}
                    required
                    type="time"
                    value={time}
                  />
                </Flex>
              </InputGroup>
            )}
            <EmployeeEmailForm
              userEmail={user.email}
              noInvalidPlaceholders
              useOnboardingSettings={useOnboardingSettings}
            />
          </InputGroup>
        </>
      )}

      {bottom}
    </AutoStepper>
  )
}

export default SendEmployeeEmailCommonContent
