import React from 'react'
import { Group, Box } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import {
  AllowRoleAndSpecialisationOwnersToViewSalariesField,
  EnableDataExportForTalentAndRequisitionSectionsField,
  EnableMultipleLevelsPerSeniorityField,
  EnableSpecialisationSettingsField,
  FunctionSettingsFields,
  FunctionSettingsSaveButton,
  RoleApprovalStepSettingsField,
  SpecialisationApprovalStepSettingsField,
} from './common'

export const Function = () => {
  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply to functions, roles and specialisations"
          />
          <Box mt="s-24">
            <Group>
              <EnableSpecialisationSettingsField />
              <AllowRoleAndSpecialisationOwnersToViewSalariesField />
              <EnableMultipleLevelsPerSeniorityField />
              <FunctionSettingsFields />
              <EnableDataExportForTalentAndRequisitionSectionsField />
            </Group>
          </Box>

          <NewStepperTitle title="Roles" subtitle="Settings that apply to all roles" />
          <Box mt="s-24">
            <RoleApprovalStepSettingsField />
          </Box>

          <NewStepperTitle
            title="Specialisation"
            subtitle="Settings that apply to all specialisations"
          />
          <Box mt="s-24">
            <SpecialisationApprovalStepSettingsField />
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <FunctionSettingsSaveButton />
      </PageActions>
    </>
  )
}
