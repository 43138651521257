import React from 'react'
import { connect } from 'lape'
import { Group, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { enableScorecardsSwitcherProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/constants'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { ReviewTypes } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/ReviewTypes'
import { GeneralSettings } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/GeneralSettings'

export const EditFormContent = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const isManual = cycleModel.isManual(values)

  return (
    <VStack space="s-16">
      <GeneralSettings />
      <ReviewTypes />
      {!isManual && (
        <Group>
          <LapeNewSwitch
            name="enabled_scorecards_generations"
            itemTypeProps={enableScorecardsSwitcherProps}
            disabled={isManual}
          />
        </Group>
      )}
    </VStack>
  )
})
