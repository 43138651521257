import React, { useEffect, useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { useSyncMeetings } from '@src/api/meetings'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'
import { oneToOneModel } from '../../models/oneToOneModel'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { OneToOneMeeting } from '@src/interfaces/meetings'

import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { ScheduleMeetingSeriesAction } from './ScheduleMeetingSeriesAction'

const SYNC_ENABLE_TIMEOUT = 1000 * 60 * 10 // 10 minutes

interface Props {
  meeting?: OneToOneMeeting
}

export const HeaderActions = ({ meeting }: Props) => {
  const openNewTab = useOpenNewTab()
  const user = useSelector(selectUser)
  const { mutateAsync: syncMeetings, isLoading } = useSyncMeetings()
  const canChangeMeeting = useHasGlobalPermission(PermissionTypes.ChangeManagerMeeting)
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>()

  // aware that user can refresh the page and this will reset. But that's fine
  const [isSyncDisabled, setSyncDisabled] = useState(false)

  const showStatusPopup = useShowStatusPopup()

  const disableSync = () => {
    setSyncDisabled(true)

    setTimer(
      setTimeout(() => {
        setSyncDisabled(false)
      }, SYNC_ENABLE_TIMEOUT),
    )
  }

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  if (!meeting) {
    return null
  }

  const isManager = oneToOneModel.isMeetingManagedBy(user, meeting)

  const changeScheduleLink = meeting?.next_meeting_calendar_link

  return (
    <>
      <MoreBar maxCount={2}>
        {changeScheduleLink && (canChangeMeeting || isManager) && (
          <MoreBar.Action
            onClick={() => {
              openNewTab(changeScheduleLink)
            }}
            useIcon="Calendar"
          >
            Change Schedule
          </MoreBar.Action>
        )}
        <ScheduleMeetingSeriesAction meeting={meeting} />
        <MoreBar.Action
          pending={isLoading}
          onClick={async () => {
            await syncMeetings()
            disableSync()
            showStatusPopup({
              title: 'Meetings are syncing',
              description: 'It can take up to 10 minutes for the meetings to sync',
            })
          }}
          disabled={isSyncDisabled}
          useIcon="ArrowExchange"
        >
          Sync latest meetings
        </MoreBar.Action>
      </MoreBar>
    </>
  )
}
