import React from 'react'
import { MoreBar, chain, Text, Tooltip, Group, useTooltip } from '@revolut/ui-kit'
import { Cross } from '@revolut/icons'

import { employeePolicyAssignmentRequest } from '@src/api/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import { navigateTo } from '@src/actions/RouterActions'
import { Statuses } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { getStatusColor } from '@src/components/CommonSC/General'
import {
  getExitUrl,
  HeaderSubtitle,
  PreviewItems,
  usePolicyAssignmentParams,
} from './common'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'

export const Preview = () => {
  const params = usePolicyAssignmentParams()
  const tooltip = useTooltip()
  const { values, initialValues } =
    useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()

  const isAutomaticallyAssigned = values.source.id === 'automatic'
  const isUnassigned = values.status?.id === Statuses.unassigned

  const canUnassign =
    values.status?.id === Statuses.active &&
    values.field_options?.permissions?.includes(
      PermissionTypes.UnassignTimeOffPolicyAssignments,
    )
  const canDelete =
    isUnassigned &&
    values.field_options?.permissions?.includes(
      PermissionTypes.DeleteTimeOffPolicyAssignments,
    )
  const canEdit =
    !isUnassigned &&
    values.field_options?.permissions?.includes(
      PermissionTypes.ChangeTimeOffPolicyAssignment,
    )

  const exitUrl = getExitUrl(params.employeeId)

  return (
    <>
      <PageHeader
        title={chain(
          initialValues.policy?.name,
          <Text color={getStatusColor(initialValues.status?.id)}>
            {initialValues.status?.name}
          </Text>,
        )}
        backUrl={exitUrl}
        subtitle={<HeaderSubtitle />}
      />

      <SettingsButtons mb="s-24">
        <EditButton
          route={pathToUrl(
            ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.GENERAL,
            params,
          )}
          isVisible={canEdit}
        />
        {canUnassign && (
          <MoreBar.Action
            aria-disabled={isAutomaticallyAssigned}
            onClick={() => {
              if (!isAutomaticallyAssigned) {
                navigateTo(
                  pathToUrl(
                    ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_UNASSIGNMENT.GENERAL,
                    params,
                  ),
                )
              }
            }}
            useIcon={Cross}
            variant="negative"
            {...tooltip.getAnchorProps()}
          >
            Unassign
            {isAutomaticallyAssigned ? (
              <Tooltip maxWidth={300} placement="bottom" {...tooltip.getTargetProps()}>
                This policy was automatically assigned, to remove it you should alter the
                policy’s eligibility
              </Tooltip>
            ) : null}
          </MoreBar.Action>
        )}
        {canDelete && (
          <DeleteButton
            title="Policy assignment"
            deleteApi={employeePolicyAssignmentRequest.delete!}
            backUrl={exitUrl}
            dialogProps={{
              label:
                'You are deleting the policy from the employee time-off policies list',
              body: `Balance linked to this policy will be closed instantly. If you want to unassign the policy or make it expire at a future date, click 'Unassign' button.`,
              yesMessage: 'Confirm',
              noMessage: 'Cancel',
            }}
          />
        )}
      </SettingsButtons>

      {isUnassigned ? (
        <PageBody>
          <FormPreview data={initialValues}>
            <Group>
              <PreviewItems />
              <FormPreview.Divider />
              <FormPreview.Item field="unassigned_on" title="Unassigned on" type="date" />
              <FormPreview.Item<EmployeeTimeOffPolicyAssignmentInterface>
                field="unassigned_by.name"
                title="Unassigned by"
                to={policy =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: policy.unassigned_by?.id,
                  })
                }
              />
            </Group>
          </FormPreview>
        </PageBody>
      ) : (
        <PageBody>
          <FormPreview data={initialValues}>
            <Group>
              <PreviewItems />
            </Group>
          </FormPreview>
        </PageBody>
      )}
    </>
  )
}
