import { Avatar, Group, Icon, Item, ItemSkeleton, Text, Token } from '@revolut/ui-kit'
import React, { useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import {
  GoogleCalendarEventAttendee,
  MeetingEvent,
  MeetingEventStatus,
  OneToOneMeeting,
} from '@src/interfaces/meetings'
import { useGetEmployeeMeetingEvents } from '@src/api/meetings'
import { meetingDuration } from './utils/meetingDuration'
import { EmptyTableRaw as EmptyState } from '@src/components/Table/EmptyTableRaw'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { ScheduleMeetingSeriesAction } from './components/ScheduleMeetingSeriesAction'

interface Props {
  selectedEvent: MeetingEvent<GoogleCalendarEventAttendee> | undefined
  setSelectedEvent: (
    target: MeetingEvent<GoogleCalendarEventAttendee> | undefined,
  ) => void
  isLoading: boolean
  managerId: number
  employeeId: number
  startDate: string
  endDate: string
  setMeetingList: (list: MeetingEvent<GoogleCalendarEventAttendee>[]) => void
  hideEmpty?: boolean
  preselect?: boolean
  meeting?: OneToOneMeeting
}

const Skeleton = () => {
  return (
    <Group>
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
  )
}

export const MeetingsList = ({
  selectedEvent,
  setSelectedEvent,
  setMeetingList,
  isLoading,
  managerId,
  employeeId,
  startDate,
  endDate,
  hideEmpty,
  preselect,
  meeting,
}: Props) => {
  const user = useSelector(selectUser)
  const { data, isLoading: isMeetingEventsLoading } = useGetEmployeeMeetingEvents({
    employee_id: user.id,
    attendees: [employeeId, managerId].join(','),
    is_series: false,
    start: `${startDate},${endDate}`,
  })
  const { query } = useQuery<{ noteId?: string }>()

  const events = useMemo(() => {
    const completedEvents: MeetingEvent<GoogleCalendarEventAttendee>[] = []
    let lastScheduledEvent: MeetingEvent<GoogleCalendarEventAttendee> | undefined

    data?.results.forEach(event => {
      if (event.status === MeetingEventStatus.Completed) {
        completedEvents.push(event)
      }
      if (event.status === MeetingEventStatus.Scheduled) {
        lastScheduledEvent = event
      }
    })

    return lastScheduledEvent ? [lastScheduledEvent, ...completedEvents] : completedEvents
  }, [data?.results])

  useEffect(() => {
    if (events.length && !!preselect) {
      setSelectedEvent(
        query.noteId && !isNaN(Number(query.noteId))
          ? events.find(event => event.id === Number(query.noteId))
          : events[0],
      )

      setMeetingList(events)
    } else {
      setMeetingList([])
    }
  }, [events, preselect])

  if (isLoading || isMeetingEventsLoading) {
    return <Skeleton />
  }

  const isEventsAvailable = Boolean(events?.length && events.length > 0)

  if (!isEventsAvailable && hideEmpty) {
    return null
  }

  return (
    <Group>
      {!isEventsAvailable && (
        <EmptyState
          title="You don't have any meeting for this cycle"
          action={<ScheduleMeetingSeriesAction meeting={meeting} />}
        />
      )}
      {isEventsAvailable &&
        events?.map(event => (
          <Item
            key={event.id}
            use="button"
            variant="compact"
            aria-pressed={selectedEvent?.id === event.id}
            onClick={() => setSelectedEvent(event)}
          >
            <Item.Avatar>
              <Avatar
                color={Token.color.foreground}
                useIcon="Calendar"
                textStyle="emphasis1"
              >
                {selectedEvent?.id === event.id && (
                  <Avatar.Badge
                    bg={Token.color.foreground}
                    position="bottom-right"
                    useIcon={<Icon name="Check" size={12} />}
                  />
                )}
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{format(new Date(event.start), 'MMM d')}</Item.Title>
              <Item.Description
                color={
                  event.status === MeetingEventStatus.Completed
                    ? Token.color.greyTone50
                    : Token.color.deepGrey
                }
              >
                <Text variant="body2">{event.status}</Text>
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Item.Value>
                <Text variant="body1" color={Token.color.greyTone50}>
                  {meetingDuration(event.start, event.end)}
                </Text>
              </Item.Value>
            </Item.Side>
          </Item>
        ))}
    </Group>
  )
}
