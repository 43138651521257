import React from 'react'
import { Token, Widget, Caption, Spacer, VStack, Text } from '@revolut/ui-kit'
import { EpicOption } from '@src/interfaces/kpis'
import { LinearSelectOption } from './LinearSelectOption'
import { LinearTaskType } from '@src/interfaces/roadmaps'

interface Props {
  tasks: Pick<EpicOption, 'key' | 'url' | 'name' | 'issue_type'>[]
  error?: string
  onDelete: (key: string) => void
}

function isLinearTask(value?: string): value is LinearTaskType {
  return Object.values(LinearTaskType).includes(value as LinearTaskType)
}

export const LinearSelectedTaskWidget = ({ tasks, onDelete, error }: Props) => {
  if (!tasks.length && !error) {
    return null
  }
  return (
    <Widget p="s-12" style={{ border: `1px solid ${Token.color.greyTone10}` }}>
      <Caption color={Token.color.greyTone50}>Linear items</Caption>
      <Spacer height="s-12" />
      <VStack space="s-12">
        {tasks.map(task => (
          <LinearSelectOption
            key={task.key}
            url={task.url}
            taskId={task.key || undefined}
            type={isLinearTask(task.issue_type) ? task.issue_type : undefined}
            onDelete={() => {
              if (task.key) {
                onDelete(task.key)
              }
            }}
            label={task.name}
          />
        ))}
        {error && (
          <Text variant="body3" color={Token.color.danger}>
            {error}
          </Text>
        )}
      </VStack>
    </Widget>
  )
}
