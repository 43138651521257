import React, { useRef } from 'react'
import { ActionButton, Dropdown, Text, useToggle } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'

export enum ChartTypes {
  GradesDistribution = 'GradesDistribution',
  Nips = 'Nips',
}

export interface ChartTypeOption extends BaseOption<ChartTypes> {}

interface Props {
  value: ChartTypeOption
  onSelect: (value: ChartTypeOption) => void
}

export const ChartTypeSelector = ({ value, onSelect }: Props) => {
  const dropdownRef = useRef(null)
  const [isDropdownOpen, dropdownToggler] = useToggle()

  return (
    <>
      <ActionButton
        useIcon="BarChart"
        useEndIcon={isDropdownOpen ? 'ChevronUp' : 'ChevronDown'}
        ref={dropdownRef}
        onClick={dropdownToggler.switch}
      >
        <Text>{value.name}</Text>
      </ActionButton>
      <Dropdown
        open={isDropdownOpen}
        anchorRef={dropdownRef}
        onClose={dropdownToggler.off}
        autoClose
        data-testid="performance_analytics_history.chart.type.dropdown"
      >
        {chartTypeOptions.map(option => (
          <Dropdown.Item key={option.id} use="button" onClick={() => onSelect(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

export const performanceChartTypeToMetricLabelMap: Record<ChartTypes, string> = {
  [ChartTypes.GradesDistribution]: 'Performance',
  [ChartTypes.Nips]: 'NIPS',
}

export const performanceChartTypeOption: ChartTypeOption = {
  id: ChartTypes.GradesDistribution,
  name: performanceChartTypeToMetricLabelMap[ChartTypes.GradesDistribution],
}

const nipsChartTypeOption: ChartTypeOption = {
  id: ChartTypes.Nips,
  name: performanceChartTypeToMetricLabelMap[ChartTypes.Nips],
}

export const chartTypeOptions: ChartTypeOption[] = [
  performanceChartTypeOption,
  nipsChartTypeOption,
]
