import React from 'react'
import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { navigateTo } from '@src/actions/RouterActions'
import {
  TEMPLATE_TYPE_QUERY_KEY,
  UPLOAD_TYPE_QUERY_KEY,
} from '@src/features/BulkDataImportV2/constants'
import { pathToUrl } from '@src/utils/router'

export const BulkImportType = () => {
  const isOnboarding = isOnboardingPath()

  const navigate = (uploadType: 'create' | 'update') => {
    navigateTo(
      pathToUrl(
        isOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.IMPORT
          : ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT,
        undefined,
        {
          [TEMPLATE_TYPE_QUERY_KEY]: 'dynamic',
          [UPLOAD_TYPE_QUERY_KEY]: uploadType,
        },
      ),
    )
  }
  return (
    <PageWrapper>
      <PageHeader
        backUrl={
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL
            : ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL
        }
        title="Import data"
        subtitle="Upload a .xlsx or .csv file to import your goals"
      />
      <PageBody>
        <Group>
          <NewSelectionCard
            icon="Plus"
            title="Create multiple goals"
            subtitle="Use this to bulk create new goals"
            onClick={() => navigate('create')}
          />
          <NewSelectionCard
            icon="ArrowExchange"
            title="Update existing goals’ information"
            subtitle="Use this to update many fields for many goals, these changes are done right away"
            onClick={() => navigate('update')}
          />
        </Group>
      </PageBody>
    </PageWrapper>
  )
}
