import React, { PropsWithChildren } from 'react'
import { Box, Text, VStack, Widget } from '@revolut/ui-kit'
import { DeliverableJustificationPreview } from '@src/interfaces/performance'
import ExpandableText from '@components/ExpandableText/ExpandableText'
import { relationToString } from '@components/ScorecardGeneral/constants'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import get from 'lodash/get'

const Wrapper = ({
  children,
  isWidget,
}: PropsWithChildren<{
  isWidget: boolean
}>) => {
  return isWidget ? <Widget p="s-16">{children}</Widget> : <Box>{children}</Box>
}

export const DeliverablesJustificationsView = ({
  viewJustifications,
  isWidget = true,
}: {
  viewJustifications?: DeliverableJustificationPreview[]
  isWidget?: boolean
}) => {
  const user = useSelector(selectUser)
  if (!viewJustifications?.length) {
    return null
  }
  const justificationsGroups: { [key: string]: DeliverableJustificationPreview[] } = {}
  viewJustifications.forEach(item => {
    if (!justificationsGroups[item.review.reviewer.id]) {
      justificationsGroups[item.review.reviewer.id] = [item]
    } else {
      justificationsGroups[item.review.reviewer.id].push(item)
    }
  })

  return (
    <Wrapper isWidget={isWidget}>
      {isWidget && (
        <Box mb="s-16">
          <Text fontWeight={500} fontSize="h5">
            Deliverables and justification
          </Text>
        </Box>
      )}
      <VStack space="s-16">
        {Object.keys(justificationsGroups).map(reviewerId => {
          const reviews = justificationsGroups[reviewerId]
          const filteredReviews = reviews.filter(
            review => !!(review.value?.comment || get(review, 'value.0.comment')),
          )
          if (!filteredReviews.length) {
            return null
          }

          return (
            <ExpandableText
              key={reviewerId}
              breakText
              list={filteredReviews.map((review, ind) => {
                const reviewer = review.review?.reviewer
                const relation = review.review?.reviewer_relation || ''
                const userName = user.id === reviewer?.id ? 'You' : reviewer.full_name
                const icon = <UserWithAvatar {...review.review?.reviewer} compact />
                const comment =
                  review.value?.comment || get(review, 'value.0.comment', '')
                return {
                  title: ind === 0 && userName ? userName : '',
                  additionalInfo: relation ? relationToString(relation, true) : undefined,
                  icon: ind === 0 ? icon : undefined,
                  items: [
                    {
                      index: ind,
                      text: comment,
                      link: review.value?.reference_url
                        ? {
                            title: `Link ${ind + 1}`,
                            url: review.value?.reference_url,
                          }
                        : undefined,
                    },
                  ],
                }
              })}
            />
          )
        })}
      </VStack>
    </Wrapper>
  )
}
