import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@revolut/ui-kit'

import {
  getHeatmapExports,
  downloadHeatmapExportFile,
  createHeatmapExport,
} from '@src/api/engagement'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

export const useExportHeatmapData = (
  surveyId: number | undefined,
  dateFrom: string | undefined,
  dateTo: string | undefined,
) => {
  const [initExportsCount, setInitExportsCount] = useState<number>(0)

  let pollIntervalRef = useRef<NodeJS.Timeout>()

  const stopPolling = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current)
    }
  }

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [])

  const statusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()

  return {
    onInitExport: async () => {
      if (!surveyId) {
        return
      }
      let allExportsResponse = await getHeatmapExports(surveyId)
      if (allExportsResponse.data?.count) {
        setInitExportsCount(allExportsResponse.data.count)
      }
      try {
        await createHeatmapExport(surveyId, dateFrom, dateTo)
      } catch (error) {
        errorPopup.show({ error, fallbackTitle: 'Could not export heatmap data' })
      }
      pollIntervalRef.current = setInterval(async () => {
        try {
          allExportsResponse = await getHeatmapExports(surveyId, { ordering: '-id' })
        } catch (error) {
          errorPopup.show({ error, fallbackTitle: 'Could not export heatmap data' })
        }
        if (
          !allExportsResponse.data?.count ||
          allExportsResponse.data.count <= initExportsCount
        ) {
          return
        }
        const newExport = allExportsResponse.data.results[0]

        if (newExport.status.id === 'success') {
          statusPopup({
            title: 'Data export successful',
            status: 'success-result',
            actions: (
              <>
                <Button
                  elevated
                  onClick={() => {
                    downloadHeatmapExportFile(surveyId, newExport.id)
                    statusPopup.hide()
                  }}
                >
                  Download file
                </Button>
                <Button variant="secondary" onClick={statusPopup.hide}>
                  Cancel
                </Button>
              </>
            ),
          })
          stopPolling()
        } else if (newExport.status.id === 'error') {
          statusPopup({
            title: 'Could not export heatmap data',
            status: 'error',
            description: newExport.error,
            actions: (
              <Button elevated onClick={statusPopup.hide}>
                Close
              </Button>
            ),
          })
          stopPolling()
        }
      }, 1000)

      statusPopup({
        title: 'Exporting heatmap data',
        status: 'loading',
      })
    },
  }
}
