import React, { useState } from 'react'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import {
  getLocationPathnameWithoutWorkspace,
  navigateTo,
} from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Cell, Flex, HStack, MoreBar, Switch, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { Statuses } from '@src/interfaces'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { PromotionNominationInterface } from '@src/interfaces/promotions'
import {
  promotionEmployeeColumn,
  promotionNominatedByColumn,
  promotionProgressionTrackColumn,
  promotionRevieversColumn,
  promotionStageColumn,
  promotionStatusColumn,
  promotionTargetSeniorityColumn,
  promotionTargetSpecialisationColumn,
  promotionUpdateTimeColumn,
} from '@src/constants/columns/promotion/promotionNomintations'
import {
  enablePromotionFeature,
  exportPromotionNominees,
  promotionNominationRequests,
} from '@src/api/promotions'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { ExportTypes } from '@src/constants/export'
import { selectorKeys } from '@src/constants/api'
import ButtonFilters, {
  ButtonFilterConfig,
} from '@components/ButtonFilters/ButtonFilters'
import { FeatureFlags } from '@src/store/auth/types'
import { successNotification } from '@src/store/notifications/actions'
import { TableNames } from '@src/constants/table'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import Loader from '@components/CommonSC/Loader'
import TalentCycleSettingsBanner from '@src/pages/Forms/CommonTalentTab/TalentCycleSettingsBanner'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useIsPromotionNominationWindowOpen } from '@src/features/Promotions/hooks/usePromotionNominationAvailability'

const row: RowInterface<PromotionNominationInterface> = {
  disabled: data => data.status === Statuses.archived,
  linkToForm: ({ id, employee }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.PREVIEW, {
        id,
        employeeId: employee.id,
      }),
      { backUrl: `${getLocationPathnameWithoutWorkspace()}${window.location.search}` },
    ),
  cells: [
    {
      ...promotionEmployeeColumn,
      width: 150,
    },
    {
      ...promotionTargetSpecialisationColumn,
      width: 300,
    },
    {
      ...promotionTargetSeniorityColumn,
      width: 150,
    },
    {
      ...promotionProgressionTrackColumn,
      width: 100,
    },
    {
      ...promotionNominatedByColumn,
      width: 150,
    },
    {
      ...promotionStageColumn,
      width: 200,
    },
    {
      ...promotionRevieversColumn,
      width: 140,
    },
    {
      ...promotionStatusColumn,
      width: 100,
    },
    {
      ...promotionUpdateTimeColumn,
      width: 100,
    },
  ],
}
const getInitialFilters = (initialCycleId?: string | number): FilterByInterface[] => [
  {
    filters: [{ name: String(initialCycleId || 0), id: initialCycleId || 0 }],
    columnName: 'review_cycle__id',
    nonResettable: true,
  },
]
const sortBy: SortByInterface[] = [
  { sortBy: 'update_date_time', direction: SORT_DIRECTION.ASC },
]

const exportTypes: ExportTypes[] = [ExportTypes.csv]

const extraFilters: ButtonFilterConfig = {
  employee__team__department__id: {
    selector: selectorKeys.department,
    type: 'MultiSelect',
    title: 'Department',
  },
  employee__specialisation__role__function__id: {
    selector: selectorKeys.functions,
    type: 'MultiSelect',
    title: 'Function',
  },
}

const PromotionNomineesTableContent = ({
  initialCycleId,
}: {
  initialCycleId?: number | string
}) => {
  const isPromotionNominationWindowOpen = useIsPromotionNominationWindowOpen()
  // featureFlags are not updated before the page is refreshed
  const [isPromotionEnabled, setIsPromotionEnabled] = useState<boolean>(
    isPromotionNominationWindowOpen,
  )

  const table = useTable<PromotionNominationInterface>(
    promotionNominationRequests,
    getInitialFilters(initialCycleId),
    sortBy,
  )

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
          />
          <Stat label="Employees nominated" val={table?.count} ml="s-32" />
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <HStack space="s-16" align="center" flex="1 0 auto">
            <Switch
              checked={isPromotionEnabled}
              onChange={e => {
                const fallbackValue = isPromotionEnabled
                setIsPromotionEnabled(!isPromotionEnabled)
                enablePromotionFeature(e.currentTarget.checked)
                  .then(() => {
                    // feature settings are cashed on the BE for 5 minutes, this ensures that the feature will be
                    // visible right after it's turned on by the user
                    if (fallbackValue) {
                      workspaceLocalStorage.setItem(
                        FeatureFlags.PromotionNomination,
                        'false',
                      )
                    } else {
                      workspaceLocalStorage.setItem(
                        FeatureFlags.PromotionNomination,
                        'true',
                      )
                    }
                    successNotification(
                      `Promotion feature has been ${fallbackValue ? 'dis' : 'en'}abled`,
                    )
                  })
                  .catch(() => setIsPromotionEnabled(fallbackValue))
              }}
            >
              <Text>Enable promotion nominations</Text>
            </Switch>
            <MoreBar>
              <ExportMenu
                fileName="Promotion Nominees"
                supportedTypes={exportTypes}
                request={exportPromotionNominees}
              />
            </MoreBar>
          </HStack>
          <ButtonFilters
            filtersConfig={extraFilters}
            filters={table.filterBy}
            onChange={table.onFilterChange}
          />
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<PromotionNominationInterface>
            name={TableNames.PromotionNominees}
            useWindowScroll
            dataType="Promotion nominee"
            row={row}
            {...table}
            emptyState={<EmptyTableRaw title="No promotion nominees" />}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

const PromotionNomineesTable = () => {
  const { cycles, loading } = useSelectedPerformanceCycle()

  if (loading) {
    return <Loader />
  }
  const initialCycle = cycles.find(cycle => cycle.performance_reviews_selected_cycle)

  if (!initialCycle) {
    return <TalentCycleSettingsBanner />
  }

  return <PromotionNomineesTableContent initialCycleId={initialCycle.cycle_id} />
}

export default PromotionNomineesTable
