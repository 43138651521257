import { useEffect, useState } from 'react'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { BaseOptionId } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { timelineModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/TimelineModel'

export const useReviewCycleData = ({
  activeCycleId,
  employeeId,
  managerId,
}: {
  activeCycleId?: BaseOptionId
  employeeId: number
  managerId: number
}) => {
  const { query, changeQueryParam } = useQuery<{ cycle_id?: string }>()
  const [selectedCycle, setSelectedCycle] = useState<ReviewCyclesInterface>()
  const { data: reviewCycles, isLoading: isLoadingReviewCycles } = useGetReviewCycles({
    employeeIds: [employeeId, managerId],
    sortBy: [{ sortBy: 'start_date_time', direction: SORT_DIRECTION.ASC }],
  })

  useEffect(() => {
    if (!isLoadingReviewCycles && reviewCycles?.results) {
      const cycle =
        // select cycle based on passed param id if present
        reviewCycles.results.find(item => item.id === Number(activeCycleId)) ||
        // else select the first cycle that has start date in past and end date in future
        // technically we cannot identify the "ongoing" cycle precisely
        // because it could be > 1 manual/adhoc/test review cycles "ongoing" at the same time
        reviewCycles.results.find(timelineModel.isOngoingCycleByDates) ||
        reviewCycles.results[0]
      if (!query.cycle_id) {
        changeQueryParam('cycle_id', String(cycle.id))
      }
      setSelectedCycle(cycle)
    }
  }, [reviewCycles?.results, isLoadingReviewCycles, activeCycleId])

  const reviewCycleOptions = {
    options:
      reviewCycles?.results?.map(cycle => {
        return {
          id: cycle.id,
          name: cycle.name,
          offset: cycle.offset,
          is_test: cycle.is_test,
          is_adhoc: cycle.is_adhoc,
          is_manual_stage_switch_enabled: cycle.is_manual_stage_switch_enabled,
        }
      }) || [],
  }

  const dateRanges = {
    startDate: selectedCycle?.start_date_time,
    endDate: selectedCycle?.end_date_time,
  }
  return {
    selectedCycle,
    reviewCycleOptions,
    isLoading: isLoadingReviewCycles,
    dateRanges,
  }
}
