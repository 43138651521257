import React from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'
import { tableauDashboardRequests } from '@src/api/analyticsDashboards'
import { ROUTES } from '@src/constants/routes'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { PageWrapper } from '@components/Page/Page'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { OrgEntityProvider } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { DashboardForm } from './DashboardForm'
import { PreviewForm } from './PreviewForm'
import { NextButton } from './components/NextButton'
import { SubmitButton } from './components/SubmitButton'
import { Token } from '@revolut/ui-kit'

const Dashboard = connect(() => {
  const { values } = useLapeContext<AnalyticsDashboardInterface>()
  const params = useParams<{ id?: string }>()

  const tabs = [
    {
      title: 'Dashboard details',
      path: ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.DETAILS,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.DETAILS, params),
      component: DashboardForm,
    },
    {
      title: 'Preview & Approve',
      path: ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.PREVIEW, params),
      component: PreviewForm,
    },
  ]

  const isPreviewPath = useRouteMatch({
    path: ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.PREVIEW,
    exact: true,
  })

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.ORGANISATION.COMPANY.ANALYTICS}
        title={values.name || 'Set up Dashboard'}
      >
        <TabBarNavigation mb="s-16" tabs={tabs} />
      </PageHeader>
      <Switch>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.DETAILS}>
          <DashboardForm variant="tableau" />
        </Route>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.PREVIEW}>
          <PreviewForm variant="tableau" />
        </Route>
      </Switch>
      <PageActions maxWidth={isPreviewPath ? Token.breakpoint.xxl : Token.breakpoint.md}>
        <NextButton tabs={tabs} />
        <SubmitButton tabs={tabs} />
      </PageActions>
    </PageWrapper>
  )
})

const TableauDashboardEditForm = () => {
  return (
    <OrgEntityProvider>
      <Form
        api={tableauDashboardRequests}
        localStorageProps={{
          followUrlTransitions: true,
          removeDataAfterTransitionFromUrls: [
            pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.DETAILS),
            pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.PREVIEW),
          ],
        }}
      >
        <Dashboard />
      </Form>
    </OrgEntityProvider>
  )
}

export default connect(TableauDashboardEditForm)
