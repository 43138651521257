import React, { useMemo, useState } from 'react'
import { FilterButton, MoreBar } from '@revolut/ui-kit'

import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTableReturnType } from '@components/TableV2/hooks'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import {
  riskAssigneeColumn,
  riskDueDateColumn,
  riskJiraColumn,
  riskLastBreachColumn,
  riskLevelIndicatorsColumn,
  riskLimit1Column,
  riskLimit2Column,
  riskLimit3Column,
  riskQualityColumn,
  riskReasonIndicatorColumn,
  riskSummaryColumn,
  riskTypeColumn,
  riskValueColumn,
} from '@src/constants/columns/risk'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { RiskIndicatorInterface, RiskStatsInterface } from '@src/interfaces/risk'

interface Props {
  table: useTableReturnType<RiskIndicatorInterface, RiskStatsInterface>
  entity: 'employee' | 'team' | 'department' | 'company'
  useWrapper?: boolean
}

const ROW = (isEmployee: boolean): RowInterface<RiskIndicatorInterface> => ({
  cells: [
    {
      ...riskSummaryColumn,
      width: 260,
    },
    {
      ...riskLevelIndicatorsColumn,
      width: 130,
    },
    {
      ...riskTypeColumn,
      width: 90,
    },
    {
      ...riskValueColumn,
      width: 130,
    },
    {
      ...riskLimit1Column,
      width: 90,
    },
    {
      ...riskLimit2Column,
      width: 90,
    },
    {
      ...riskLimit3Column,
      width: 90,
    },
    {
      ...riskQualityColumn,
      width: 100,
    },
    {
      ...riskReasonIndicatorColumn,
      width: 100,
    },
    {
      ...riskLastBreachColumn,
      width: 100,
    },
    {
      ...(isEmployee ? teamNameColumn : riskAssigneeColumn),
      width: 90,
    },
    {
      ...riskDueDateColumn,
      width: 90,
    },
    {
      ...riskJiraColumn,
      width: 80,
    },
  ],
})

const Indicators = ({ table, entity }: Props) => {
  const [showBCI, setShowBCI] = useState(true)

  const isEmployee = entity === 'employee'
  const isCompany = entity === 'company'

  const row = useMemo(() => ROW(isEmployee), [isEmployee])
  const handleNewRow = () => {
    window.open('https://backoffice.revolut.com/risks/risk-indicators', '_blank')
  }

  const onChangeShowClosed = () => {
    if (showBCI) {
      table.onFilterChange({
        filters: [
          { id: 'RAS', name: 'RAS' },
          { id: 'KRI', name: 'KRI' },
        ],
        columnName: 'type',
      })
    } else {
      table.onFilterChange({
        filters: [],
        columnName: 'type',
      })
    }
    setShowBCI(!showBCI)
  }

  return (
    <>
      {isCompany ? null : (
        <Table.Widget.Filters>
          <FilterButton active={showBCI} onClick={onChangeShowClosed}>
            Show BCIs
          </FilterButton>
        </Table.Widget.Filters>
      )}

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action useIcon="Plus" onClick={handleNewRow}>
            Add Risk Indicator
          </MoreBar.Action>
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1478372102/Risk+Indicators+KRIs+and+BCIs"
            use="a"
            useIcon="InfoOutline"
          >
            How to manage risk indicators?
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RiskIndicatorInterface, RiskStatsInterface>
          name={TableNames.Indicators}
          useWindowScroll
          dataType="Risk Indicator"
          row={row}
          {...table}
          emptyState={
            <EmptyTableRaw title={`This ${entity} has no risk indicators data`} />
          }
          hideCount
        />
      </Table.Widget.Table>
    </>
  )
}

export default Indicators
