import React, { useMemo } from 'react'
import { Box, VStack, Widget } from '@revolut/ui-kit'
import { CycleOption, GradeOption } from '@src/interfaces/selectors'
import { FinalGrade } from '@src/interfaces/performance'
import { FilterByInterface } from '@src/interfaces/data'
import { useGradeMap } from '@src/hooks/useGradeMap'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { CYCLE_ID_QUERY_PARAM_KEY } from '@src/features/ReviewCycle/Analytics/PerformanceReviewsAnalytics'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { PerformanceScore } from '@src/features/ReviewCycle/Analytics/Overview/PerformanceScore'
import { ReviewStats } from '@src/features/ReviewCycle/Analytics/Overview/ReviewStats'
import { GradesDistributionBySeniority } from '@src/features/ReviewCycle/Analytics/Overview/GradesDistributionBySeniority'
import { GradesDistribution } from '@src/features/ReviewCycle/Analytics/Overview/GradesDistribution/GradesDistribution'
import { useGetReviewCycle } from '@src/api/reviewCycles'

interface Props {
  cycleId: string | number | undefined
  cycleOptions: CycleOption[]
  handleFilterCycle: (filters: FilterByInterface) => void
}

const LEFT_COLUMN_MIN_WIDTH = 220
const LEFT_COLUMN_MAX_WIDTH = 340

export const AnalyticsOverview = ({
  cycleId,
  cycleOptions,
  handleFilterCycle,
}: Props) => {
  const { data: reviewCycle, isLoading } = useGetReviewCycle(
    cycleId ? String(cycleId) : undefined,
  )
  const selectedCycleOption = useMemo<CycleOption | undefined>(
    () => cycleOptions.find(({ id }) => id === Number(cycleId)),
    [cycleId],
  )

  const { gradeOptions: allGradesOptions } = useGradeMap(selectedCycleOption)
  const gradesOptions = useMemo<GradeOption[]>(
    () =>
      allGradesOptions.filter(
        ({ id }) => ![FinalGrade.Empty, FinalGrade.None].includes(id),
      ),
    [allGradesOptions],
  )

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <CycleFilterButton
          selector={() => Promise.resolve({ options: cycleOptions })}
          onFilterChange={handleFilterCycle}
          columnName={CYCLE_ID_QUERY_PARAM_KEY}
          filter={[
            {
              columnName: CYCLE_ID_QUERY_PARAM_KEY,
              filters: cycleId ? [{ id: cycleId, name: `${cycleId}` }] : [],
            },
          ]}
        />
        {cycleId && (
          <>
            <TwoColumnsLayout
              left={
                <VStack space="s-16">
                  <PerformanceScore
                    cycleId={cycleId}
                    gradesOptions={gradesOptions}
                    height={380}
                  />
                  <ReviewStats cycleId={cycleId} />
                </VStack>
              }
              right={
                <Box width="100%" height="100%">
                  <GradesDistributionBySeniority
                    reviewCycle={reviewCycle ?? null}
                    gradesOptions={gradesOptions}
                    isLoading={isLoading}
                  />
                </Box>
              }
              leftFlex={1}
              rightFlex={1}
              leftMinWidth={LEFT_COLUMN_MIN_WIDTH}
              leftMaxWidth={{
                md: '100%',
                lg: LEFT_COLUMN_MAX_WIDTH,
              }}
              overflow="auto"
            />
            <GradesDistribution
              cycleId={cycleId}
              gradesOptions={gradesOptions}
              isLoading={isLoading}
            />
          </>
        )}
      </VStack>
    </Widget>
  )
}
