import { useMemo } from 'react'
import { Token } from '@revolut/ui-kit'
import {
  FinalGrade,
  PerformanceReviewAnalyticsGroupTypes,
} from '@src/interfaces/performance'
import { GradeOption } from '@src/interfaces/selectors'
import { SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  PerformanceReviewAnalyticsFilterKeys,
  PerformanceReviewAnalyticsParams,
  PerformanceReviewAnalyticsSortKeys,
  useGetPerformanceReviewAnalytics,
} from '@src/api/performance'
import { useGetSelectors } from '@src/api/selectors'
import { BaseColor } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { getColorByIndex } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import {
  GradeDistributionHistoryDataKey,
  GradeDistributionHistoryItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/GradeDistributionBarChart/ChartData'
import {
  NipsHistoryDataKey,
  NipsHistoryItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/NipsBarChart/ChartData'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

export const HISTORY_RECORDS_COUNT = 8

export enum YAxisUnits {
  Percentage = 'Percentage',
  Count = 'Count',
}

const GRADE_DISTRIBUTION_COLORS: BaseColor[] = [
  'orange',
  'deepGrey',
  'blue',
  'teal',
  'lightGreen',
]

export const NIPS_COLOR = Token.colorChannel.blue

export interface AnalyticsOptions {
  departmentsIds?: number[]
  functionsIds?: number[]
}

interface Props {
  gradeDistributionData: GradeDistributionHistoryItemData[]
  gradeDistributionDataKeys: GradeDistributionHistoryDataKey[]
  nipsData: NipsHistoryItemData[]
  nipsDataKey: NipsHistoryDataKey
  isLoading: boolean
}

const sortOptions: SortByInterface[] = [
  {
    sortBy: PerformanceReviewAnalyticsSortKeys.CycleStartDate,
    direction: SORT_DIRECTION.DESC,
  },
]

export const useChartData = (
  yAxisUnit: YAxisUnits,
  options?: AnalyticsOptions,
): Props => {
  const { show: showError } = useErrorPopup()
  const { data: gradesOptions = [], isLoading: isGradesLoading } =
    useGetSelectors<GradeOption>(selectorKeys.grades)
  const params = useMemo<PerformanceReviewAnalyticsParams>(() => {
    if (!options) {
      return {}
    }

    return {
      [PerformanceReviewAnalyticsFilterKeys.DepartmentId]: options.departmentsIds,
      [PerformanceReviewAnalyticsFilterKeys.FunctionId]: options.functionsIds,
    }
  }, [options])
  const { data: analyticsData, isLoading: isAnalyticsDataLoading } =
    useGetPerformanceReviewAnalytics(
      PerformanceReviewAnalyticsGroupTypes.Cycle,
      params,
      sortOptions,
      {
        onError: error =>
          showError({ error, fallbackTitle: 'Failed to load chart data' }),
      },
    )

  const gradeDistributionDataKeys = useMemo<GradeDistributionHistoryDataKey[]>(() => {
    if (!analyticsData || isGradesLoading) {
      return []
    }

    return gradesOptions.map(({ id, name }, index) => ({
      id: index,
      label: name,
      value: id,
      color: Token.colorChannel[getColorByIndex(index, GRADE_DISTRIBUTION_COLORS)],
    }))
  }, [analyticsData, isGradesLoading, gradesOptions])

  const gradeDistributionData = useMemo<GradeDistributionHistoryItemData[]>(() => {
    if (!analyticsData) {
      return []
    }

    return analyticsData
      .map(({ group, grades }) => ({
        ...grades.reduce<Record<Partial<FinalGrade>, number>>(
          (gradesMap, { id, count, percent }) => ({
            ...gradesMap,
            [id]: yAxisUnit === YAxisUnits.Percentage ? percent * 100 : count,
          }),
          {} as Record<Partial<FinalGrade>, number>,
        ),
        rawData: grades,
        label: group.name,
        tooltip: '',
      }))
      .slice(-HISTORY_RECORDS_COUNT, analyticsData.length)
  }, [analyticsData, yAxisUnit])

  const nipsDataKey = useMemo<NipsHistoryDataKey>(
    () => ({
      id: 0,
      label: 'NIPS',
      value: 'nips',
      color: NIPS_COLOR,
    }),
    [],
  )

  const nipsData = useMemo<NipsHistoryItemData[]>(() => {
    if (!analyticsData) {
      return []
    }

    return analyticsData
      .map(({ group, nips }) => ({
        nips: nips * 100,
        rawData: { nips },
        label: group.name,
        tooltip: '',
      }))
      .slice(-HISTORY_RECORDS_COUNT, analyticsData.length)
  }, [analyticsData])

  return {
    gradeDistributionData,
    gradeDistributionDataKeys,
    nipsDataKey,
    nipsData,
    isLoading: isAnalyticsDataLoading || isGradesLoading,
  }
}
