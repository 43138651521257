import React from 'react'
import { Skeleton, VStack, Widget } from '@revolut/ui-kit'
import {
  AvailableInterviewerSlotRequestArguments,
  useFetchDynamicSlots,
} from '@src/api/recruitment/interviewerScheduling'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import DayTimeAvatar from '@src/pages/Forms/Candidate/ScheduleSidebar/DayTimeAvatar'

interface Props
  extends Pick<
    AvailableInterviewerSlotRequestArguments,
    | 'roundId'
    | 'stageId'
    | 'interviewId'
    | 'interviewers'
    | 'interviewerGroups'
    | 'duration'
    | 'durationUnit'
    | 'isAdhoc'
    | 'daysCount'
    | 'dynamicSlotsDateRangeStart'
    | 'dynamicSlotsDateRangeEnd'
  > {
  timeZoneId: string
}

export const PreviewDynamicSlots = ({
  stageId,
  roundId,
  interviewId,
  daysCount,
  timeZoneId,
  interviewers,
  interviewerGroups,
  duration,
  durationUnit,
  isAdhoc,
  dynamicSlotsDateRangeStart,
  dynamicSlotsDateRangeEnd,
}: Props) => {
  const { data, isLoading } = useFetchDynamicSlots({
    stageId,
    roundId,
    interviewers,
    interviewerGroups,
    duration,
    durationUnit,
    isAdhoc,
    daysCount,
    dynamicSlotsDateRangeStart,
    dynamicSlotsDateRangeEnd,
    interviewId,
  })

  const groupedSlots = useGroupByDaySlots(timeZoneId, data || [])

  if (isLoading) {
    return (
      <Widget p="s-16" mt="s-16">
        <VStack gap="s-24">
          <Skeleton width={140} />
          <Skeleton width={240} />
          <Skeleton width={240} />
          <Skeleton width={140} />
          <Skeleton width={240} />
        </VStack>
      </Widget>
    )
  }

  return (
    <Widget p="s-16" mt="s-16">
      <VStack gap="s-24">
        {groupedSlots.map(group => (
          <DayTimeAvatar
            key={group.groupDate}
            day={group.label}
            items={group.items}
            timeZone={timeZoneId}
          />
        ))}
      </VStack>
    </Widget>
  )
}
