import React from 'react'
import { FinalGrade } from '@src/interfaces/performance'
import { TextProps, Text, Flex, Badge, Token, TooltipState } from '@revolut/ui-kit'
import { GradesMapInterface } from '@src/utils/grades'
import { RecommendedGradesInterface } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { HowGradeIsCalculatedTooltip } from './HowGradeIsCalculatedTooltip'

interface Props extends TextProps {
  value: FinalGrade
  gradesMap: GradesMapInterface
  recommendedGrades: RecommendedGradesInterface
  tooltip: TooltipState<Element>
}

export const RecommendationGrade = ({
  value,
  gradesMap,
  recommendedGrades,
  tooltip,
  ...rest
}: Props) => {
  return (
    <Flex gap="s-8">
      <Text use="div" {...rest}>
        {gradesMap[value]}
      </Text>
      {value === recommendedGrades.recommendedGrade && (
        <>
          <Badge size={20} bg={Token.color.blue_10} color={Token.color.blue} px="s-8">
            Recommended
          </Badge>
          <HowGradeIsCalculatedTooltip
            tooltip={tooltip}
            recommendedGrades={recommendedGrades}
          />
        </>
      )}
    </Flex>
  )
}
