import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

export const hasNoSchedulingPreferences = (values?: Partial<HiringProcessInterface>) => {
  if (values?.stage_type?.id) {
    return ['cv_screening', 'home_assessment', 'online_test', 'final_round'].includes(
      values.stage_type.id,
    )
  }
  return false
}
