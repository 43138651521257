import React, { useState } from 'react'
import { Action, Avatar, Group, Item, StatusPopup, Switch } from '@revolut/ui-kit'
import { Cleaning } from '@revolut/icons'
import { useSelector } from 'react-redux'

import {
  uploadLogo,
  useGetCompanyLogo,
  useGetCompanyPreferences,
  useUpdateCompanyPreferences,
} from '@src/api/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PreferencesLogoUpload } from './PreferencesLogoUpload'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const CompanyDesign = () => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)

  const { data: preferences, isLoading } = useGetCompanyPreferences()
  const { refetch, data: companyLogoData } = useGetCompanyLogo()
  const { mutate } = useUpdateCompanyPreferences()

  const isCommercial = useIsCommercial()
  const permissions = useSelector(selectPermissions)

  const canEditPreferences = permissions.includes(
    PermissionTypes.ChangeCompanyPreferences,
  )
  const canEditLogo = permissions.includes(PermissionTypes.CanManageCompanyLogo)

  const handleBrandingSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    mutate({ enable_branding: event.target.checked })
  }

  const closePopup = () => {
    setOpenUpload(false)
  }

  const handlePopupUpdate = () => {
    refetch()
    closePopup()
    setShowSuccessPopup(true)
  }

  return (
    <>
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar image={companyLogoData?.url} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Add your company icon</Item.Title>
            <Item.Description>Upload a logo of your company</Item.Description>
          </Item.Content>
          <Item.Side>
            <Action onClick={() => setOpenUpload(true)} disabled={!canEditLogo}>
              Update logo
            </Action>
          </Item.Side>
        </Item>
        {!isCommercial || (isCommercial && !companyLogoData?.url) ? null : (
          <Item use="label">
            <Item.Avatar>
              <Avatar useIcon={Cleaning} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Use company branding</Item.Title>
              <Item.Description>Show logo and company name in sidebar</Item.Description>
            </Item.Content>
            <Item.Side>
              <Switch
                onChange={handleBrandingSwitch}
                checked={!!preferences?.enable_branding}
                disabled={isLoading || !canEditPreferences}
              />
            </Item.Side>
          </Item>
        )}
      </Group>

      {openUpload ? (
        <PreferencesLogoUpload
          open
          onClose={closePopup}
          onUpload={uploadLogo}
          onSuccess={handlePopupUpdate}
        />
      ) : null}

      <StatusPopup
        variant="success"
        open={showSuccessPopup}
        onClose={() => setShowSuccessPopup(false)}
      >
        <StatusPopup.Title>Company preferences updated</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
