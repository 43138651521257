import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TextWidget, VStack } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'

export const PerformanceInsightsGroupsPage = () => (
  <PageWrapper>
    <PageHeader
      title="Insights"
      backUrl={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.EVALUATION_CRITERIA}
    />
    <PageBody>
      <VStack space="s-16">
        <TextWidget>
          <TextWidget.Title>
            <FormattedMessage
              id="performance.ai.insights.explanation.title"
              defaultMessage="Insights explained"
            />
          </TextWidget.Title>
          <TextWidget.Content>
            <FormattedMessage
              id="performance.ai.insights.explanation.description"
              values={{ b: chunks => <strong>{chunks}</strong> }}
              defaultMessage="Insights provides an <b>AI-generated summary</b> of employee performance by analysing key quantitative data, achievements, and goals. It helps managers evaluate individual contributions, compare performance across teams, and make informed, data-driven decisions during reviews."
            />
          </TextWidget.Content>
        </TextWidget>
      </VStack>
    </PageBody>
  </PageWrapper>
)
