import React from 'react'
import { ErrorMessage } from '@src/features/Errors/components/ErrorMessage'
import { ParsableError } from '@src/features/Errors/parseError'
import { getRequestId } from '@src/features/Errors/getters'

interface Props {
  error?: ParsableError
  message: string
}

export const TechnicalErrorMessage = ({ error, message }: Props) => {
  return (
    <ErrorMessage
      displayMessage={message}
      copyMessage={{
        status: error?.response?.status,
        message: error?.message,
        data: error?.response?.data,
        method: error?.config?.method?.toUpperCase(),
        url: error?.config.url,
        requestId: getRequestId(error),
      }}
    />
  )
}
