import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { Cell, Text, ActionButton } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'
import { PROMOTION_JUSTIFICATION_EXAMPLES } from '@src/constants/externalLinks'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const JustificationSidebar = ({ isOpen, onClose }: Props) => {
  return (
    <SideBar title="Justification example" isOpen={isOpen} onClose={onClose}>
      <Grid flow="row" gap={16}>
        <ActionButton
          use="a"
          useIcon="LinkExternal"
          href={PROMOTION_JUSTIFICATION_EXAMPLES}
        >
          Open more examples
        </ActionButton>
        <Cell display="block">
          <Text variant="primary" use="p">
            Format:
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            1. Use the STAR method (situation, task, actions, results) for your example.
            <br />
            2. Show how our company culture values are met. <br />
            3. Think about how this demonstrates readiness for promotion now.
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" use="p">
            Good example:
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            <b>Situation:</b> Due to the number of increased requisitions, my team started
            having more encounters with HoX.
            <br />
            <br />
            <b>Task/Action:</b> Acting as my right hand and my future successor, John has
            been present in all HoX meetings and to address the needs and any HoX
            concerns. Throughout the year, he clearly understood customer requirements,
            negotiated when needed, consistently provided feedback to the respective HoX
            on hiring, onboarding, and training of the new joiners.
            <br />
            <br />
            <b>Result:</b> All internal customers John has worked with submitted positive
            feedback about his outputs, work ethic, and business acumen. Hiring managers
            submit his name as the preferred recruiter in requisitions even in
            requisitions he won’t be working on!
            <br />
            <br />
            <b>Value Demonstrated:</b> Deliver Wow and Think Deeper
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" use="p">
            Bad example:
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            No consistent track record of high performance
            <br />
            <br /> Elizabeth as our strongest Ops Manager on board has started managing
            these teams along with the respective team leaders to support KPI A, KPI B,
            KPI C and KPI D. She also has more than 40 reports.
          </Text>
        </Cell>
      </Grid>
    </SideBar>
  )
}

export default JustificationSidebar
