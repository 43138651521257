import React from 'react'
import { useGetEmployeeDynamicGroups } from '@src/api/dynamicGroups'
import { DataHandlerInterface } from '@components/FormPreview/FormPreview'
import { EmployeeInterface } from '@src/interfaces/employees'
import { Contracts } from '@src/pages/EmployeeProfile/Layout/Summary/Contracts'
import { WorkDetails } from '@src/pages/EmployeeProfile/Layout/Summary/WorkDetails'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { useEmployeeRecordToPrimaryContractEnabled } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { OfferWidget } from './OfferWidget'

type Props = {
  data: EmployeeInterface
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  sectionsData: ProfileSectionsData
}

export const EmployeeInformation = ({
  data,
  primaryDataHandler,
  sectionsData,
}: Props) => {
  const employeeRecordToPrimaryContractEnabled =
    useEmployeeRecordToPrimaryContractEnabled()
  const { data: dynamicGroupsData } = useGetEmployeeDynamicGroups(data.id)

  return (
    <>
      {!!data.candidate_id && <OfferWidget candidateId={data.candidate_id} />}
      <WorkDetails
        data={data}
        dynamicGroups={dynamicGroupsData?.results || []}
        primaryDataHandler={primaryDataHandler}
        sectionsData={sectionsData}
      />
      {!employeeRecordToPrimaryContractEnabled && <Contracts data={data} />}
    </>
  )
}
