import React from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { formatTimeDiff } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/formatters/schedulingEvent'
import { AxiosError } from 'axios'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { TimeSlot } from '@src/interfaces/meetings'

import {
  AvailabilityStatus,
  useExtensionAvailability,
} from '../../hooks/useExtensionAvailability'
import { useExtensionData } from './useExtensionData'

export const useScheduleStatusPopup = (
  handleConfirm: VoidFunction,
  handleCancel: VoidFunction,
) => {
  const showStatusPopup = useShowStatusPopup()

  const showSuccessPopup = (event: TimeSlot) => {
    showStatusPopup({
      status: 'success-result',
      title: '1:1 meeting created',
      description: `You'll meet ${formatTimeDiff(event, true)}`,
      actions: statusPopup => (
        <StatusPopupActions
          handleConfirm={() => {
            handleConfirm()
            statusPopup.hide()
          }}
          handleCancel={() => {
            handleCancel()
            statusPopup.hide()
          }}
        />
      ),
    })
  }

  const showErrorPopup = (err: AxiosError) => {
    showStatusPopup({
      status: 'error',
      title: 'Failed to schedule event',
      description: getMessageFromApiError(err),
    })
  }

  return {
    showSuccessPopup,
    showErrorPopup,
  }
}

interface Props {
  handleConfirm: VoidFunction
  handleCancel: VoidFunction
}

export const StatusPopupActions = ({ handleConfirm, handleCancel }: Props) => {
  const { isChecking, status } = useExtensionAvailability()
  const { extensionLink } = useExtensionData()

  return (
    <StatusPopup.Actions>
      {isChecking || status === AvailabilityStatus.Installed ? (
        <Button onClick={handleConfirm}>Continue</Button>
      ) : (
        <>
          <Button
            useIcon="LinkExternalSimple"
            onClick={handleConfirm}
            use="a"
            target="_blank"
            rel="noreferrer noopener"
            href={extensionLink}
          >
            Get Revolut Meet Extension
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            Do it later
          </Button>
        </>
      )}
    </StatusPopup.Actions>
  )
}
