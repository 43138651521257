import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSummary/Actions/CommonActions'
import { useReopenReviewCycle } from '@src/api/reviewCycles'
import { MoreBar } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { DeleteCycleAction } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSummary/Actions/DeleteCycleAction'

interface Props {
  cycle: ReviewCyclesInterface
}

export const ClosedCycleActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { mutateAsync: reopenCycle, isLoading } = useReopenReviewCycle(id)
  const permissions = useSelector(selectPermissions)
  const showStatusPopup = useShowStatusPopup()

  const canDelete =
    permissions.includes(PermissionTypes.DeleteReviewCycles) &&
    cycleModel.isDeletable(cycle)

  const handleReopenCycle = async () => {
    await reopenCycle()
    showStatusPopup({ title: `${cycle.name} cycle is successfully re-opened` })
  }

  return (
    <MoreBar maxCount={2} aria-label="action panel">
      <CommonActions cycle={cycle} />
      <MoreBar.Action useIcon="Play" pending={isLoading} onClick={handleReopenCycle}>
        Re-open Cycle
      </MoreBar.Action>
      {canDelete && <DeleteCycleAction cycle={cycle} />}
    </MoreBar>
  )
}
