import { RowInterface, SORT_DIRECTION, FilterOption } from '@src/interfaces/data'
import {
  IssueInterface,
  IssuesCounts,
  IssuesCategoryCounts,
  IssueSeverity,
  IssueType,
  IssueTypes,
} from '@src/interfaces/issues'
import {
  issueLinkColumn,
  issueSummaryColumn,
  issueAssigneeColumn,
  issueOpenDateColumn,
  issueDueDateColumn,
  issueSeverityColumn,
  issueDaysLeftColumn,
  issueTeamColumn,
  issueTypeColumn,
  issueStatusColumn,
  issueDepartmentColumn,
  issueDailyChatsColumn,
  issueDailyComplaintsColumn,
} from '@src/constants/columns/issues'
import { Token, FilterButton, chain, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import React, { PropsWithChildren, useMemo, useEffect } from 'react'
import { useTableReturnType } from '@components/TableV2/hooks'
import { AuditStatuses } from '@src/interfaces/audit'
import Table from '@src/components/TableV2/Table'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { toIdAndName } from '@src/utils/toIdAndName'
import { matchPath, useLocation } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { CXLABS_BASE_URL } from '@src/constants/externalLinks'

interface IssuesTableProps {
  table: useTableReturnType<IssueInterface>
  name: TableNames
  counts?: IssuesCounts
  issuesCategoryCounts?: IssuesCategoryCounts
  showDepartments?: boolean
  showTeams?: boolean
  showIssueType?: boolean
  showIssueStatus?: boolean
  showFiltersAsStats?: boolean
  departmentId?: number
  teamId?: number
}

const row: RowInterface<IssueInterface> = {
  cells: [
    {
      ...issueLinkColumn,
      width: 110,
    },
    {
      ...issueSummaryColumn,
      width: 300,
    },
    {
      ...issueDepartmentColumn,
      width: 180,
    },
    {
      ...issueTypeColumn,
      width: 90,
    },
    {
      ...issueTeamColumn,
      width: 210,
    },
    {
      ...issueAssigneeColumn,
      width: 150,
    },
    {
      ...issueSeverityColumn,
      width: 90,
    },

    {
      ...issueOpenDateColumn,
      width: 100,
    },

    {
      ...issueDailyChatsColumn,
      width: 120,
    },
    {
      ...issueDailyComplaintsColumn,
      width: 120,
    },
    {
      ...issueDaysLeftColumn,
      title: 'Days left',
      colors: () => Token.color.greyTone50,
      width: 80,
    },
    {
      ...issueDueDateColumn,
      width: 100,
    },
    {
      ...issueStatusColumn,
      width: 100,
    },
  ],
}

const countIssues = (item?: Record<IssueSeverity, number>) => {
  if (!item) {
    return 0
  }
  const values = Object.values(item)

  return values.reduce((accumulator, value) => {
    return accumulator + value
  }, 0)
}

const issuesMap: Record<IssueType, string> = {
  user_issue: 'Findings',
  technical_bug: 'Technical Bugs',
  user: 'User Problems',
  product_audit: 'Product Audits',
}

const TypeFilterButton = ({
  type,
  issuesCategoryCounts,
  table,
  children,
}: PropsWithChildren<{
  type: IssueType
  table: useTableReturnType<IssueInterface>
  issuesCategoryCounts?: IssuesCategoryCounts
}>) => {
  const isActiveFilter = !!table.filterBy
    .find(f => f.columnName === 'category')
    ?.filters.find(f => f.id === type)

  const onToggleTypeFilter = () => {
    table.onFilterChange({
      filters: !isActiveFilter ? [{ name: type, id: type }] : [],
      columnName: 'category',
      nonResettable: true,
    })
  }

  return (
    <FilterButton onClick={onToggleTypeFilter} active={isActiveFilter}>
      {chain(
        children,
        issuesCategoryCounts ? countIssues(issuesCategoryCounts[type]) : 0,
      )}
    </FilterButton>
  )
}

const IssuesStatFilters = ({
  issuesCategoryCounts,
  table,
}: {
  issuesCategoryCounts?: IssuesCategoryCounts
  table: useTableReturnType<IssueInterface>
}) => {
  const selectedFilter = table.filterBy.find(
    ({ columnName }) => columnName === 'category',
  )?.filters?.[0]?.id

  const onToggleTypeFilter = (type: string) => {
    table.onFilterChange({
      filters: type && type !== 'total' ? [{ name: type, id: type }] : [],
      columnName: 'category',
      nonResettable: true,
    })
  }

  const total = useMemo(
    () =>
      issuesCategoryCounts
        ? IssueTypes.reduce((acc, val) => acc + countIssues(issuesCategoryCounts[val]), 0)
        : 0,
    [issuesCategoryCounts],
  )

  const filters = useMemo(
    () => [
      {
        title: 'Total',
        id: 'total',
        value: total,
      },
      ...IssueTypes.map(issueType => {
        return {
          id: issueType,
          title: issuesMap[issueType],
          value: issuesCategoryCounts ? countIssues(issuesCategoryCounts[issueType]) : 0,
        }
      }),
    ],
    [total, issuesCategoryCounts],
  )

  return (
    <StatFilters
      selectedFilter={selectedFilter ? String(selectedFilter) : 'total'}
      filters={filters}
      onClick={onToggleTypeFilter}
    />
  )
}

export const IssuesTable = ({
  table,
  name,
  issuesCategoryCounts,
  showDepartments = true,
  showTeams = true,
  showFiltersAsStats = false,
  departmentId,
  teamId,
}: IssuesTableProps) => {
  const defaultFilters: FilterOption[] = [
    toIdAndName(AuditStatuses.PENDING_FILING),
    toIdAndName(AuditStatuses.QUALITY_REVIEW),
    toIdAndName(AuditStatuses.IN_PROGRESS),
    toIdAndName(AuditStatuses.TO_DO),
  ]

  const currentCategory = table.filterBy.find(f => f.columnName === 'category')
  const showsUserIssuesTab = currentCategory?.filters.some(f => f.id === 'user')

  const location = useLocation()
  const routeMappings = [
    {
      path: ROUTES.FORMS.TEAM.ISSUES.ANY,
      url: `${CXLABS_BASE_URL}/${departmentId}/${teamId}?timeFrame=MONTH&source=chats`,
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.ANY,
      url: `${CXLABS_BASE_URL}/${departmentId}?timeFrame=MONTH&source=chats`,
    },
    {
      path: ROUTES.ORGANISATION.COMPANY.ISSUES,
      url: CXLABS_BASE_URL,
    },
  ]
  const getButtonLink = () => {
    const match = routeMappings.find(({ path }) =>
      matchPath(location.pathname, { path, exact: true }),
    )
    return match?.url
  }

  useEffect(() => {
    if (showsUserIssuesTab) {
      table.onFilterChange([
        {
          filters: [
            ...defaultFilters,
            toIdAndName(AuditStatuses.NOT_APPLICABLE),
            toIdAndName(AuditStatuses.DONE),
            toIdAndName(AuditStatuses.INITIATIVE_FILING),
            toIdAndName(AuditStatuses.INITIATIVE_IMPLEMENTATION),
            toIdAndName(AuditStatuses.FILING_REVIEW),
            toIdAndName(AuditStatuses.SOLUTION_REVIEW),
          ],
          columnName: 'status',
          nonResettable: true,
        },
      ])
      table.onSortChange({
        sortBy: 'chats_current',
        direction: SORT_DIRECTION.ASC,
      })
    } else {
      table.onFilterChange([
        {
          filters: defaultFilters,
          columnName: 'status',
          nonResettable: true,
        },
      ])
    }
  }, [showsUserIssuesTab])
  return (
    <>
      {!showFiltersAsStats ? (
        <Table.Widget.Filters>
          {IssueTypes.map(issueType => (
            <TypeFilterButton
              type={issueType}
              table={table}
              issuesCategoryCounts={issuesCategoryCounts}
              key={issueType}
            >
              {issuesMap[issueType]}
            </TypeFilterButton>
          ))}
        </Table.Widget.Filters>
      ) : null}
      {showFiltersAsStats ? (
        <Table.Widget.Filters>
          <IssuesStatFilters table={table} issuesCategoryCounts={issuesCategoryCounts} />
        </Table.Widget.Filters>
      ) : null}
      <Table.Widget.Actions>
        {showsUserIssuesTab && (
          <Table.Widget.MoreBar>
            <Table.ColumnsSettingsButton />
            {!!table.data.length && (
              <MoreBar.Action
                target="_blank"
                rel="noreferrer noopener"
                href={getButtonLink()}
                use="a"
                useIcon="LinkExternalSimple"
              >
                View in CX Labs
              </MoreBar.Action>
            )}
          </Table.Widget.MoreBar>
        )}
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<IssueInterface>
          dataType="Issue"
          name={name}
          row={row}
          useWindowScroll
          hiddenCells={{
            [issueTeamColumn.idPoint]: !showTeams,
            [issueDepartmentColumn.idPoint]: !showDepartments,
            [issueDailyChatsColumn.idPoint]: !showsUserIssuesTab,
            [issueDailyComplaintsColumn.idPoint]: !showsUserIssuesTab,
            [issueTypeColumn.idPoint]: showsUserIssuesTab,
            [issueDaysLeftColumn.idPoint]: showsUserIssuesTab,
            [issueDueDateColumn.idPoint]: !showsUserIssuesTab,
          }}
          {...table}
          hideCount
          tableSettings={{
            visible: [],
            hidden: [issueOpenDateColumn.title],
          }}
        />
      </Table.Widget.Table>
    </>
  )
}
