import React from 'react'
import { Box, Flex, Text, TextProps, Token, VStack } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { formatIfNumber } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'

export interface DistributionTooltipData
  extends Omit<DataKey<Record<string, number>>, 'color'> {
  percentage: number
  count: number | undefined
  color: string
}

interface Props {
  data: DistributionTooltipData[]
  title: string
}

export const DistributionTooltip = ({ data, title }: Props) => (
  <VStack space="s-4">
    <Text variant="h6">{title}</Text>
    {data.map(({ id, label, count, percentage, color }) => (
      <Flex justifyContent="flex-start" key={id} alignItems="center" gap="s-6">
        <Box
          bg={color}
          width="s-12"
          height="s-12"
          border={`1px solid ${Token.color.greyTone50}`}
          borderRadius={Token.radius.r2}
        />
        <Text fontSize="small" color={Token.color.background}>
          {label}:
        </Text>
        {count !== undefined ? (
          <>
            <Text fontSize="small" color={Token.color.background} ml="auto">
              {formatIfNumber(count)}
            </Text>
            <Percentage
              value={percentage}
              insertBefore="("
              insertAfter=")"
              fontSize="small"
              color={Token.color.background}
            />
          </>
        ) : (
          <Percentage
            value={percentage}
            fontSize="small"
            color={Token.color.background}
            ml="auto"
          />
        )}
      </Flex>
    ))}
  </VStack>
)

interface PercentageProps extends TextProps {
  value: number
  insertAfter?: React.ReactNode
  insertBefore?: React.ReactNode
}

const Percentage = ({
  value,
  insertAfter,
  insertBefore,
  ...textProps
}: PercentageProps) => {
  if (!Number.isFinite(value) || value === 0) {
    return null
  }

  const label = Math.abs(value) > 0.01 ? formatPercentage(value) : '<1%'

  return (
    <Text {...textProps}>
      {insertBefore}
      {label}
      {insertAfter}
    </Text>
  )
}
