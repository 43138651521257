import React from 'react'
import {
  Box,
  pathChain,
  ProgressWidget,
  ProgressWidgetSkeleton,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { ReferCandidateCapStats } from '@src/interfaces/referCandidate'

interface ReferralCapProps {
  loading: boolean
  referCandidateCap?: ReferCandidateCapStats
}

export const ReferralCap = ({ loading, referCandidateCap }: ReferralCapProps) => {
  if (loading) {
    return (
      <Box mb="s-16">
        <ProgressWidgetSkeleton aria-label="Referral Cap loading">
          <ProgressWidgetSkeleton.Title />
          <ProgressWidgetSkeleton.Description />
          <ProgressWidget.Progress />
        </ProgressWidgetSkeleton>
      </Box>
    )
  }

  if (!referCandidateCap) {
    return null
  }

  const { referrals_submitted_this_month, referral_cap } = referCandidateCap

  const overLimit = referrals_submitted_this_month >= referral_cap

  const description = [
    `You can refer up to ${referral_cap} candidates per month.`,
    overLimit ? 'Referral limit wil be renewed on the first of next month.' : '',
  ].join(' ')

  const color = overLimit
    ? Token.color.danger
    : referrals_submitted_this_month
    ? Token.color.blue
    : undefined

  return (
    <Box mb="s-16">
      <ProgressWidget aria-label="Referral Cap">
        <ProgressWidget.Title>
          <VStack gap="s-4">
            <Text>Candidates referred</Text>
            <Text variant="body2" color={Token.color.greyTone50}>
              {description}
            </Text>
          </VStack>
        </ProgressWidget.Title>
        <ProgressWidget.Description>
          <Text variant="heading3">
            {pathChain(referrals_submitted_this_month, referral_cap)}
          </Text>
        </ProgressWidget.Description>
        <ProgressWidget.Progress
          value={referrals_submitted_this_month / referral_cap}
          color={color}
        />
      </ProgressWidget>
    </Box>
  )
}
