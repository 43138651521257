import React from 'react'
import { ActionButton, Subheader } from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { SubheaderWithDescription } from '@src/components/SubheaderWithDescription'
import { StructureLevels } from './components/StructureLevels'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { isGeneralOrganisationSettingsHidden } from '@src/pages/Settings/Organisation/config'
import { useGetOrganisationSettings } from '@src/api/settings'

export const StructuresTeamsGeneral = () => {
  const { data } = useGetOrganisationSettings()
  const generalSettingsHidden = isGeneralOrganisationSettingsHidden(data)

  return (
    <PageWrapper>
      <PageHeader
        title="Teams structure"
        subtitle="Define areas of responsibility for deliverables in your business and use them to manage goals, talent, and performance reviews."
        backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
      />

      <PageBody>
        {generalSettingsHidden ? null : (
          <ActionButton
            to={ROUTES.SETTINGS.STRUCTURES.TEAMS.GENERAL}
            use={InternalLink}
            useIcon="Gear"
            mb="s-16"
          >
            Settings
          </ActionButton>
        )}

        <SubheaderWithDescription description="Change department and team settings">
          <Subheader.Title>Manage structure</Subheader.Title>
        </SubheaderWithDescription>

        <StructureLevels
          levels={[
            {
              id: 'departments',
              url: ROUTES.SETTINGS.STRUCTURES.TEAMS.DEPARTMENTS,
              icon: 'Bank',
              title: 'Departments',
              description:
                'Larger groups of employees that share broader activities and complex objectives.',
            },
            {
              id: 'teams',
              url: ROUTES.SETTINGS.STRUCTURES.TEAMS.TEAMS,
              icon: 'People',
              title: 'Teams',
              description:
                'Groups of employees who work collaboratively toward shared goals.',
            },
          ]}
        />
      </PageBody>
    </PageWrapper>
  )
}
