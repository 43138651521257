import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Label } from '@components/Label/Label'

type Props = {
  title: string
  data: DepartmentInterface
}
export const DepartmentPageHeader = ({ title, data }: Props) => {
  return (
    <PageHeader
      title={title}
      subtitle={
        <Label
          name={data.name}
          icon="Profile"
          url={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, {
            id: data.id,
          })}
        />
      }
      backUrl={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id: String(data.id) })}
    />
  )
}
