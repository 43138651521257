import React, { useState } from 'react'
import {
  Carousel,
  Flex,
  Grid,
  Box,
  VStack,
  Image,
  Token,
  AspectRatio,
  useMatchMedia,
  TextButton,
  Icon,
  HStack,
} from '@revolut/ui-kit'

import { Text } from '@src/pages/Landing/components/Text'
import { PageIndicator } from '@src/pages/Landing/components/PageIndicator'
import { CustomerStoryInterface } from '@src/pages/Landing/components/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const CustomerStory = ({ story }: { story: CustomerStoryInterface }) => {
  return (
    <Grid
      columns={{ all: 1, md: 2 }}
      gap="s-32"
      use={story.url ? InternalLink : undefined}
      to={story.url}
    >
      <Box alignSelf="center" order={{ all: 2, md: 1 }}>
        <AspectRatio ratio={484 / 420} maxWidth={484} maxHeight={420} margin="0 auto">
          <Image src={story.image} alt={story.company} radius="r24" scale="contain" />
        </AspectRatio>
      </Box>
      <VStack gap="s-16" alignSelf="center" order={{ all: 1, md: 2 }}>
        <Image src={story.logo} size={64} alt={story.company} />
        <Text variant="h6" color={Token.color.greyTone50}>
          {story.label || 'Customer testimony'}
        </Text>
        <Text variant="h5">{story.quote}</Text>
        <Text variant="primary">{story.quoteBy}</Text>
        {story.url ? (
          <TextButton>
            <HStack align="center" space="s-8">
              <Text fontWeight={Token.fontWeight.body2}>Read more</Text>
              <Icon name="ArrowThinRight" size={16} />
            </HStack>
          </TextButton>
        ) : null}
      </VStack>
    </Grid>
  )
}

interface Props {
  stories: CustomerStoryInterface[]
}

export const CustomerStories = ({ stories }: Props) => {
  const isTablet = useMatchMedia(Token.media.md)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  if (isTablet) {
    return (
      <>
        <Carousel
          aria-label="Slide change handler"
          index={currentSlideIndex}
          onIndexChange={setCurrentSlideIndex}
        >
          {stories.map(story => (
            <Carousel.Item key={story.company} width={1}>
              <CustomerStory story={story} />
            </Carousel.Item>
          ))}
        </Carousel>
        {stories.length > 1 ? (
          <Flex mt="s-16" justifyContent="center">
            <PageIndicator
              mt={{ all: 's-16', md: 's-24', xxl: 's-32' }}
              current={currentSlideIndex}
              total={stories.length}
              onClick={setCurrentSlideIndex}
            />
          </Flex>
        ) : null}
      </>
    )
  }

  return <CustomerStory story={stories[0]} />
}
