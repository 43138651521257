import React, { forwardRef, MouseEvent } from 'react'
import { Ellipsis, IconName, Tag, TagProps, Token } from '@revolut/ui-kit'
import { notReachable } from '@src/utils/notReachable'
import { TagVariant } from '@revolut/ui-kit/types/dist/components/Tag/variants'

export type ColorTagVariant =
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral'
  | 'default'
  | 'outlined'

interface Props extends Omit<TagProps, 'variant'> {
  icon?: IconName
  variant?: ColorTagVariant
  children: React.ReactNode
  onClick?: (e: MouseEvent) => void
}

export const ColorTag = forwardRef<HTMLElement, Props>(
  ({ icon, variant = 'default', children, onClick, ...props }, ref) => {
    let color
    let bg
    let tagVariant: TagVariant = 'faded'

    switch (variant) {
      case 'success': {
        color = Token.color.success
        bg = Token.color.teal_20
        break
      }

      case 'warning': {
        color = Token.color.warning
        bg = Token.color.orange_10
        break
      }

      case 'danger': {
        color = Token.color.danger
        bg = Token.color.inputError
        break
      }

      case 'neutral': {
        color = Token.color.greyTone50
        bg = Token.color.greyTone10
        break
      }

      case 'outlined': {
        color = undefined
        bg = undefined
        tagVariant = 'outlined'
        break
      }

      case 'default': {
        color = undefined
        bg = undefined
        break
      }

      default:
        notReachable(variant)
    }

    return (
      <Tag
        variant={tagVariant}
        color={color}
        bg={bg}
        style={{ maxWidth: '100%', cursor: onClick ? 'pointer' : undefined }}
        useIcon={icon}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <Ellipsis>{children}</Ellipsis>
      </Tag>
    )
  },
)
