import React from 'react'
import { Grid, Avatar, HStack, Text, Icon, TextButton, Token } from '@revolut/ui-kit'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'

export const ClickUpSelectOption = ({
  label,
  taskId,
  url,
  onDelete,
}: {
  label: React.ReactNode
  url?: string
  taskId?: string
  onDelete?: () => void
}) => {
  return (
    <Grid
      columns="max-content max-content 1fr max-content"
      gap="s-8"
      placeContent="center"
    >
      <Avatar
        size={20}
        image={{
          default: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
          '2x': 'https://assets.revolut.com/assets/apps/ClickUp@2x.webp',
          '3x': 'https://assets.revolut.com/assets/apps/ClickUp@3x.webp',
        }}
      />
      <Text color={Token.color.greyTone50}>{taskId}:</Text>
      <Text>{label}</Text>
      <HStack space="s-16">
        {url && <OpenInNewTab label="" link={url} onClick={e => e.stopPropagation()} />}
        {!!onDelete && (
          <TextButton onClick={onDelete}>
            <Icon size={18} name="Delete" color={Token.color.greyTone20} />
          </TextButton>
        )}
      </HStack>
    </Grid>
  )
}
