import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  HStack,
  Icon,
  Subheader,
  TextButton,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { PerformanceOutput } from '@src/features/AiInsights/Performance/components/PerformanceOutput/PerformanceOutput'

interface Props {
  employeeId: number
  cycleId: number
}

export const DeliverablesInsights = (props: Props) => {
  const [isDetailsSidebarOpen, detailsSidebarToggler] = useToggle()

  return (
    <>
      <Widget p="s-16" pt="s-4" data-testid="performance_scorecard_ai_deliverables">
        <VStack>
          <Subheader>
            <Subheader.Title>
              <HStack space="s-4" align="center">
                <AiIcon />
                <FormattedMessage
                  id="scorecard.deliverables.performance.ai.insights.subheader.title"
                  defaultMessage="Insights"
                />
              </HStack>
            </Subheader.Title>
          </Subheader>
          <TextButton onClick={detailsSidebarToggler.on}>
            <HStack space="s-8" align="center">
              <Icon name="LinkExternal" size={16} />
              <FormattedMessage
                id="scorecard.deliverables.performance.ai.insights.details.cta.label"
                defaultMessage="Full details"
              />
            </HStack>
          </TextButton>
        </VStack>
      </Widget>
      <SideBar
        isOpen={isDetailsSidebarOpen}
        title={
          <FormattedMessage
            id="scorecard.deliverables.performance.ai.insights.details.sidebar.title"
            defaultMessage="Performance output"
          />
        }
        onClose={detailsSidebarToggler.off}
      >
        <Box mt="-s-12">
          <PerformanceOutput {...props} />
        </Box>
      </SideBar>
    </>
  )
}
