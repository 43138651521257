import React, { useEffect, useRef, useState } from 'react'
import { Box, Color, Flex, Icon, Text, Token, Ellipsis } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { connect } from 'lape'
import { cellWrapperRole } from '@components/TableV2/Cell'
import TableCellInput, {
  TableCellInputProps,
} from '@components/Inputs/TableCellInput/TableCellInput'
import { LockedSyncIcon } from '@src/features/LockedSyncIcon/LockedSyncIcon'

export const stateAttr = 'editable_cell_state'

export enum CellState {
  inactive = 'inactive',
  active = 'active',
  hovered = 'hovered',
}

export const WrapperCss = css`
  &[data-${stateAttr}=${CellState.active}],
  &[data-${stateAttr}=${CellState.hovered}] {
    background-color: ${Token.color.blue_5};
    box-shadow: inset 0 0 0 1px ${Token.color.accent};
  }
`

export interface EditableCellProps extends TableCellInputProps {
  suffix?: string
  testid?: string
  color?: Color
  inactiveEditableInsert?: () => React.ReactNode | undefined
  hidePencil?: boolean
  onClick?: VoidFunction
  onCancel?: VoidFunction
  readonly?: boolean
  synchronised?: boolean
}

const EditableCell = ({
  suffix = '',
  testid = 'editable-cell',
  color,
  inactiveEditableInsert,
  hidePencil,
  onClick,
  ...props
}: EditableCellProps) => {
  const [cellState, setCellState] = useState<CellState>(CellState.inactive)
  const cellWrapper = useRef<HTMLElement | null | undefined>(null)

  const onStateUpdate = (nextState: CellState) => {
    setCellState(nextState)
    if (cellWrapper?.current) {
      cellWrapper.current.dataset[stateAttr] = nextState
    }
  }

  const assignCellWrapperRef = (ref: HTMLDivElement | null) => {
    const wrapper = ref?.closest<HTMLElement>(`[role=${cellWrapperRole}]`)

    if (wrapper) {
      cellWrapper.current = wrapper
    }
  }

  useEffect(() => {
    const onWrapperClick = (e: MouseEvent) => {
      e.stopPropagation()

      if (props.synchronised || props.disabled) {
        return
      }
      onStateUpdate(CellState.active)
    }

    const onWrapperMouseEnter = (e: MouseEvent) => {
      e.stopPropagation()

      if (!props.synchronised && cellState !== CellState.active) {
        onStateUpdate(CellState.hovered)
      }
    }

    const onWrapperMouseLeave = (e: MouseEvent) => {
      e.stopPropagation()

      if (cellState !== CellState.active) {
        onStateUpdate(CellState.inactive)
      }
    }

    cellWrapper?.current?.addEventListener('click', onWrapperClick)
    cellWrapper?.current?.addEventListener('mouseenter', onWrapperMouseEnter)
    cellWrapper?.current?.addEventListener('mouseleave', onWrapperMouseLeave)

    return () => {
      cellWrapper?.current?.removeEventListener('click', onWrapperClick)
      cellWrapper?.current?.removeEventListener('mouseenter', onWrapperMouseEnter)
      cellWrapper?.current?.removeEventListener('mouseleave', onWrapperMouseLeave)
    }
  }, [props.synchronised, props.disabled, cellState])

  if (cellState === CellState.active && !(props.disabled || props.synchronised)) {
    return (
      <Box
        data-testid="editable-cell"
        ref={assignCellWrapperRef}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            onStateUpdate(CellState.inactive)
            props.onCancel?.()
          }
        }}
      >
        <TableCellInput
          {...props}
          onBlur={val => {
            onStateUpdate(CellState.inactive)
            props.onBlur?.(val)
          }}
        />
      </Box>
    )
  }

  const inactiveEditableInserted = inactiveEditableInsert?.()

  return (
    <Flex
      data-testid={testid}
      ref={assignCellWrapperRef}
      alignItems="center"
      gap="s-4"
      style={{ pointerEvents: 'none' }}
    >
      {cellState !== CellState.active && inactiveEditableInserted ? (
        inactiveEditableInserted
      ) : (
        <Text
          color={props.synchronised ? Token.color.greyTone20 : color}
          style={{ flexGrow: 1 }}
        >
          <Ellipsis style={{ minHeight: 16 }}>
            {props.value}
            {suffix}
          </Ellipsis>
        </Text>
      )}

      {props.synchronised ? <LockedSyncIcon /> : null}

      {/* pencil icon */}
      {props.disabled || props.synchronised || hidePencil ? null : (
        <Flex justifyContent="flex-end" height="100%" alignItems="center">
          <Icon
            name="Pencil"
            size={16}
            color={cellState === CellState.hovered ? 'primary' : Token.color.greyTone50}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default connect(EditableCell)
