import { useGlobalSettings } from '@src/api/settings'
import { FieldOptions } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const useCanViewEngagement = <
  T extends { id: number; field_options: FieldOptions },
>({
  entity,
  checkLocalPermission,
}: {
  entity: T | undefined
  checkLocalPermission?: boolean
}) => {
  const { settings, isLoading } = useGlobalSettings()
  const hasGlobalPermission = useHasGlobalPermission(PermissionTypes.ViewEngagementTabs)
  const hasLocalPermission = checkLocalPermission
    ? entity?.field_options.permissions?.includes(PermissionTypes.ViewEngagementTabs)
    : true

  return {
    canView: Boolean(
      entity?.id &&
        settings.engagement_enabled &&
        hasGlobalPermission &&
        hasLocalPermission,
    ),
    isLoading,
  }
}

export const useIsEngagementManager = () => {
  const isEngagementManager = useHasGlobalPermission(
    PermissionTypes.EngagementManagerPermissions,
  )
  return isEngagementManager
}
