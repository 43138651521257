import React from 'react'
import { Token } from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { fieldChangelogKPIRequests } from '@src/api/changelog'
import {
  changelogChangedBy,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { KpiInterface } from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'

const FieldsChangelogKPIRow: RowInterface<ChangelogInterface<KpiInterface>> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields,
      selectorsKey: selectorKeys.kpi_changelog_fields,
      width: 210,
    },
    {
      ...changelogFieldsNewValue,
      selectorsKey: selectorKeys.kpi_changelog_values,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
}

const Changelog = () => {
  const { values } = useLapeContext<KpiInterface>()
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: values.id, name: values.full_name }],
    },
  ]

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const changelogFieldsTable = useTable<ChangelogInterface<KpiInterface>>(
    fieldChangelogKPIRequests,
    initialFilter,
    initialSort,
  )

  const handleRowClick = (change: ChangelogInterface<KpiInterface>) => {
    navigateReplace(
      `${pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: values.id })}?change=${change.id}`,
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<ChangelogInterface<KpiInterface>>
          name={TableNames.KPIChangelog}
          useWindowScroll
          dataType="Data Change"
          row={FieldsChangelogKPIRow}
          {...changelogFieldsTable}
          onRowClick={handleRowClick}
          emptyState={<EmptyTableRaw title="There are no changes to display" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Changelog
