import React, { useState } from 'react'
import { Avatar, Group, MoreBar, Subheader } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import SideBar from '@components/SideBar/SideBar'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { employeeSettingsRequests } from '@src/api/employees'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { EmployeeSettingsInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

const InterviewerProfileForm = () => {
  const showStatusPopup = useShowStatusPopup()
  const { submit, reset, initialValues } = useLapeContext<EmployeeSettingsInterface>()

  const onSubmit = async () => {
    try {
      await submit()
    } catch (error) {
      showStatusPopup({
        title: 'Cannot save changes',
        description: getMessageFromApiError(error),
        status: 'error',
      })

      // as initialValues is Partial, but reset takes not Partial, prefill all optional fields
      reset({
        email_integration_enabled: false,
        calendar_integration_enabled: false,
        field_options: {
          no_access: [],
          read_only: [],
        },
        office_suite_provider: null,
        consent_to_interview_ai_summarisation: null,
        ...initialValues,
      })
    }
  }

  return (
    <Group>
      <Subheader>Interview preferences</Subheader>
      <LapeNewSwitch
        name="consent_to_interview_ai_summarisation"
        itemTypeProps={{
          title: 'Enable AI',
          description:
            'When enabled, meeting transcription and interview summary will be provided in the interview scorecard and interview analytics will be provided in your interviewer profile.',
          avatar: (
            <Avatar variant="brand" size={40}>
              <AiIcon />
            </Avatar>
          ),
        }}
        onAfterChange={onSubmit}
      />
    </Group>
  )
}

export const InterviewerProfileAction = () => {
  const user = useSelector(selectUser)
  const [open, setOpen] = useState(false)

  return (
    <>
      <MoreBar.Action useIcon="Profile" onClick={() => setOpen(true)}>
        <FormattedMessage
          id="recruitment.interviewAvailability.actions.interviewerProfile"
          defaultMessage="My interviewer profile"
        />
      </MoreBar.Action>
      <SideBar
        isOpen={open}
        useLayout
        title="My interviewer profile"
        onClose={() => setOpen(false)}
      >
        <Form
          api={employeeSettingsRequests}
          forceParams={{ id: String(user.id) }}
          isExistingForm
        >
          <InterviewerProfileForm />
        </Form>
      </SideBar>
    </>
  )
}
