import { KpiGoals, KpiInterface, KpiReviewCycle, KpiTargets } from '@src/interfaces/kpis'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

import { probationCycleCategory } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'

import pick from 'lodash/pick'

type TargetInitialValuesParams = {
  kpi: KpiInterface
  defaultCycle?: ReviewCyclesInterface | KpiReviewCycle
  probationId?: string
  isTemplate?: boolean
}

// TODO: Remove this when BE is fixed
const transformCycle = (
  reviewCycle: ReviewCyclesInterface | KpiReviewCycle,
): KpiReviewCycle => {
  return pick(reviewCycle, ['id', 'name', 'offset', 'category', 'status'])
}

export const getInitialValuesValueTarget = ({
  kpi,
  defaultCycle,
  probationId,
  isTemplate,
}: TargetInitialValuesParams): KpiTargets => {
  const numTargets = kpi.targets.length
  const lastTarget = numTargets ? kpi.targets[numTargets - 1] : null

  const canBeTopdown = !!kpi.team || kpi.department

  return {
    kpi: { id: kpi.id },
    initial_value: lastTarget?.target || null,
    target: null,
    parent_kpi: null,
    owner: canBeTopdown ? kpi.owner : undefined,
    is_top_down: false,
    kpi_goal: lastTarget?.kpi_goal || KpiGoals.increase,
    review_cycle: probationId || !defaultCycle ? undefined : transformCycle(defaultCycle),
    is_probation: isTemplate && defaultCycle?.category === probationCycleCategory,
    employee_cycle: probationId
      ? {
          id: probationId,
          name: 'Probation',
          category: ReviewCycleCategory.Probation,
        }
      : undefined,
  }
}
