import { api, apiWithoutHandling, normalizeCaptchaError } from '@src/api'
import {
  CAREERS_TEAM_OTHER,
  CareersApplicationInterface,
  CareersApplicationSettingsInterface,
  CareersFeaturesInterface,
  CareersFunctionGroupInterface,
  CareersPosition,
  CareersPositionLocation,
  CareersPositionResponse,
  CareersSections,
  CareersSettingsInterface,
} from '@src/interfaces/careers'
import findKey from 'lodash/findKey'
import { useFetch, useFetchV2 } from '@src/utils/reactQuery'
import { IdAndName, RequestInterfaceNew } from '@src/interfaces'
import { Country } from '@src/interfaces/selectors'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import { useMemo } from 'react'
import { API, COOKIE } from '@src/constants/api'
import { getFormData } from '@src/utils/getApplicationFormData'
import { useGetWhoAmI } from '@src/api/login'
import { useCookie } from '@src/hooks/useCookie'
import { LocalStorageKeys, PermissionTypes } from '@src/store/auth/types'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const normalizeCareersGroups = (
  groups?: CareersFunctionGroupInterface[],
): Record<string, string[]> =>
  groups?.reduce((acc, item) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    acc[item.name] = item.functions.map(func => func.name)
    return acc
  }, {}) || {}

const responseTransformer = <T extends CareersPositionResponse>(
  response: T,
  groups?: CareersFunctionGroupInterface[],
): CareersPosition => {
  const team =
    findKey(
      normalizeCareersGroups(groups),
      functions => response.function?.name && functions.includes(response.function.name),
    ) || CAREERS_TEAM_OTHER

  const locations: CareersPositionLocation[] = response.locations.map(location => ({
    name: location.name,
    type: location.type || 'office',
    country: location.country?.name || null,
  }))

  let status
  let internalId

  if ('internal_id' in response && typeof response.internal_id === 'number') {
    internalId = response.internal_id
  }

  if ('status' in response && typeof response.status === 'string') {
    status = response.status as PublishingStatuses
  }

  return {
    id: response.id,
    internalId,
    text: response.title,
    status,
    locations,
    description: response.description || '',
    creation_date_time: response.creation_date_time,
    presentation_video_url: response.presentation_video_url,
    team,
  }
}

export const useFetchCareersList = () => {
  const { data: settings } = useCareersSettings()
  const context = useFetch<CareersPositionResponse[]>(
    '/postings',
    'v2',
    undefined,
    undefined,
    undefined,
    true,
  )
  const data = useMemo(
    () =>
      context.data?.map((p: CareersPositionResponse) =>
        responseTransformer(p, settings?.function_groups),
      ),
    [context.data, settings?.function_groups],
  )

  return {
    ...context,
    data,
  }
}

export const useCanEditJobPostings = () => {
  const [isAuthenticatedCookie] = useCookie(COOKIE.AUTHENTICATED)
  const isAuthenticatedLocalStorage = workspaceLocalStorage.getItem(
    LocalStorageKeys.AUTHENTICATED,
  )

  const isAuthenticated = isAuthenticatedCookie || isAuthenticatedLocalStorage
  const { data: user } = useGetWhoAmI(!!isAuthenticated)

  if (!user) {
    return false
  }

  const permissions = user.permissions || []
  return permissions.includes(PermissionTypes.AddJobDescriptions)
}

export const useIsCareersPreview = () => {
  const canEditJobPostings = useCanEditJobPostings()
  const isCommercial = useIsCommercial()

  return canEditJobPostings && isCommercial
}

export const useFetchPosition = <T extends CareersPositionResponse>(
  id?: string | null,
) => {
  const isPreview = useIsCareersPreview()
  const isExternal = !isPreview

  let url = null
  if (id) {
    url = isPreview ? `/postingsPreview/${id}` : `/postings/${id}`
  }
  const context = useFetch<T>(url, 'v2', undefined, true, undefined, isExternal)
  const data = useMemo(
    () => (context.data ? responseTransformer<T>(context.data) : undefined),
    [context.data],
  )

  return {
    ...context,
    data,
  }
}

export const useFetchCountries = () => {
  const context = useFetch<{ options: Country[] }>(
    `${API.INTERVIEWS}/applications/applicantCountries`,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchAdditionalQuestions = (id?: string) =>
  useFetch<CareersSections | null>(
    id ? `${API.INTERVIEWS}/applications/generateAdditionalQuestions` : null,
    undefined,
    { params: { posting_id: id } },
    undefined,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    true,
  )
export const useFetchGenderChoices = () => {
  const context = useFetch<{ options: IdAndName<string, string>[] }>(
    API.APPLICANT_GENDERS,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchDiversityEthnicities = () => {
  const context = useFetch<{ options: IdAndName<string, string>[] }>(
    API.APPLICANT_ETHNICITIES,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchUniversities = () => {
  const context = useFetch<{ options: IdAndName[] }>(
    API.APPLICANT_UNIVERSITIES,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchEducationMajors = () => {
  const context = useFetch<{ options: IdAndName[] }>(
    API.APPLICANT_EDUCATION_MAJORS,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchDegrees = () => {
  const context = useFetch<{ options: IdAndName<string>[] }>(
    API.APPLICANT_DEGREES,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchWorkExperienceCompanies = () => {
  const context = useFetch<{ options: IdAndName[] }>(
    API.APPLICANT_WORK_EXPERIENCE_COMPANIES,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchWorkExperiencePositions = () => {
  const context = useFetch<{ options: IdAndName[] }>(
    API.APPLICANT_WORK_EXPERIENCE_POSITIONS,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

  return { ...context, data: context.data?.options }
}

export const careersApplicationRequest: RequestInterfaceNew<CareersApplicationInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.INTERVIEWS}/applications/${id}`, undefined, undefined, true),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.INTERVIEWS}/applications/${id}`,
        data,
        undefined,
        undefined,
        true,
      ),
    submit: async (data, { id }) =>
      apiWithoutHandling
        .post(
          `${API.INTERVIEWS}/applications`,
          getFormData(data, getIdFromPositionSlug(id!)),
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          undefined,
          true,
        )
        .catch(normalizeCaptchaError),
    delete: async ({ id }) =>
      api.delete(`${API.INTERVIEWS}/applications/${id}`, undefined, undefined, true),
  }

export const useCareersSettings = () =>
  useFetchV2<CareersSettingsInterface>({
    url: API.POSTING_CAREER_PAGE_SETTINGS,
    version: 'v2',
    queryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    isExternal: true,
  })

export const useCareersApplicationSettings = () =>
  useFetch<CareersApplicationSettingsInterface>(
    '/postings/applicationFormSettings',
    'v2',
    undefined,
    undefined,
    undefined,
    true,
  )

export const useIsDiversityCollected = (countryId?: number) =>
  useFetch<{ is_diversity_collected: boolean }>(
    countryId ? `${API.INTERVIEWS}/applications/isDiversityCollected` : null,
    undefined,
    { params: { country_id: countryId } },
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const useGetCareersFeatures = () =>
  useFetchV2<CareersFeaturesInterface>({
    url: '/postings/featureSettings',
    isExternal: true,
    version: 'v2',
  })
