import React from 'react'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { Text, Token, VStack, VStackProps } from '@revolut/ui-kit'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface Props extends VStackProps {
  checked: boolean
  onCheck: (checked: boolean) => void
}

export const InterviewTranscriptConsent = ({ checked, onCheck, ...props }: Props) => {
  return (
    <VStack gap="s-16" {...props}>
      <NewCheckbox
        checked={checked}
        onChange={() => onCheck(!checked)}
        label="I consent"
      />
      <Text variant="body3" color={Token.color.greyTone50} display="block">
        Tick "I consent" above if you agree to us using AI to record a written transcript
        and summarise your interviews (video or audio will not be recorded). The
        transcripts and summaries will be used to help our interviewers fill in their
        interview scorecards, which means we will be able to assess you more fairly and
        provide you with more timely and accurate feedback. <br />
        <br />
        Consent is entirely optional and will not impact your employment prospects. You
        can withdraw your consent at any time{' '}
        <HideIfCommercial>by emailing us at dpo@revolut.com or</HideIfCommercial> by
        notifying your interviewer during any interview.
      </Text>
    </VStack>
  )
}
