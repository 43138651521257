import React, { useState } from 'react'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { CalendarCheck, Info, Warning } from '@revolut/icons'
import { Button, Item, MoreBar, Portal, Side, Tooltip, useTooltip } from '@revolut/ui-kit'
import { CandidatesPipelineViewType, useGetStages } from '@src/api/recruitment/candidates'
import { bulkMoveToStage } from '@src/api/recruitment/interviews'
import SideBar from '@src/components/SideBar/SideBar'
import StageCounts from '@src/features/CommonCandidatesTable/StageCounts'
import { BulkMoveToStageInterface } from '@src/interfaces/interviewTool'
import pluralize from 'pluralize'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

type MoveToStageActionProps = {
  disabled: boolean
  onClick: () => void
}

export const MoveToStageAction = ({ disabled, onClick }: MoveToStageActionProps) => {
  const tooltip = useTooltip()
  return (
    <>
      <MoreBar.Action
        {...tooltip.getAnchorProps()}
        useIcon={CalendarCheck}
        aria-disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
      >
        Change stage
      </MoreBar.Action>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>
          You can change stage in bulk only for candidate(s) from the same process
        </Tooltip>
      )}
    </>
  )
}

type MoveToStageSidebarProps = {
  candidateIds: number[]
  specialisationIds: number[]
  open: boolean
  onClose: () => void
}

const MoveToStageSidebar = ({
  candidateIds,
  specialisationIds,
  open,
  onClose,
}: MoveToStageSidebarProps) => {
  const [stage, setStage] = useState<CandidatesPipelineViewType>()
  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)

  const { data, isLoading, isError, error } = useGetStages(
    open ? candidateIds : undefined,
    specialisationIds,
  )
  const stages = data?.filter(({ stage_type }) => stage_type !== 'Total') ?? []
  const activeStages = stages.filter(({ status }) => status !== 'archived')

  const handleClose = () => {
    setSuccess(null)
    onClose()
  }

  const handleSubmit = async () => {
    if (!stage) {
      return
    }
    setPending(true)
    try {
      const payload: BulkMoveToStageInterface = {
        candidate_ids: candidateIds,
      }
      if (stage.stage_type === 'hiring_panel' || stage.stage_type === 'offer') {
        payload.stage_hiring_stage_type = stage.stage_type
      } else {
        payload.stage_hiring_stage_id = stage.filters?.[0]
      }
      await bulkMoveToStage(payload)
      setSuccess(true)
      setStage(undefined)
    } catch (e) {
      setSuccess(false)
    } finally {
      setPending(false)
    }
  }

  return (
    <>
      <SideBar
        isOpen={open}
        onClose={handleClose}
        title={`Change stage for ${pluralize('candidates', candidateIds?.length, true)}`}
      >
        {specialisationIds.length > 1 && (
          <Item useIcon={Info}>
            <Item.Content>
              You can change stage in bulk only for candidate(s) from the same process
            </Item.Content>
          </Item>
        )}
        {error && (
          <Item useIcon={Warning}>
            <Item.Content>
              <Item.Title>Something went wrong</Item.Title>
              <Item.Description>
                {getMessageFromApiError(error) ||
                  'Try to reduce amount of selected candidates'}
              </Item.Description>
            </Item.Content>
          </Item>
        )}
        {!isError && specialisationIds.length === 1 && (
          <>
            <StageCounts stages={stages} />
            <RadioSelectInput<CandidatesPipelineViewType>
              label="Stage to move to"
              loading={isLoading}
              options={activeStages.map(({ label, ...stageValue }) => ({
                label,
                value: {
                  name: label,
                  label,
                  ...stageValue,
                },
              }))}
              onChange={newStage => setStage(newStage ?? undefined)}
              value={stage}
            />

            <Side.Actions horizontal>
              <Button variant="secondary" onClick={handleClose} autoFocus>
                Cancel
              </Button>
              <Button
                elevated
                disabled={!stage || pending}
                onClick={handleSubmit}
                pending={pending}
              >
                Submit
              </Button>
            </Side.Actions>
          </>
        )}
      </SideBar>
      <Portal>
        <BulkStatusPopup isSuccess={success} onClose={handleClose} />
      </Portal>
    </>
  )
}

export default MoveToStageSidebar
