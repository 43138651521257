import { useMemo } from 'react'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { Statuses } from '@src/interfaces'
import { EntityTypes } from '@src/constants/api'
import { PerformanceSelector } from '@src/interfaces/performance'
import { NameIdInterface } from '@src/interfaces/requisitions'
import { EmployeeInterface } from '@src/interfaces/employees'

export const useGetGoalsFilters = (
  selectedPeriod: PerformanceSelector,
  employee: EmployeeInterface,
  team?: NameIdInterface,
) => {
  const { filter: employeeContentTypeFilter } = useGetGoalContentTypeFilter(
    EntityTypes.employee,
  )
  const { filter: teamContentTypeFilter } = useGetGoalContentTypeFilter(EntityTypes.team)

  return useMemo(() => {
    const employeeGoalsFilters = [
      {
        columnName: 'cycle__id',
        filters: [{ id: selectedPeriod.id, name: selectedPeriod.name }],
        nonResettable: true,
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      {
        columnName: 'object_id',
        filters: [{ id: employee.id, name: employee.full_name }],
        nonResettable: true,
        nonInheritable: true,
      },
      ...(employeeContentTypeFilter ? [employeeContentTypeFilter] : []),
    ]

    const teamGoalsFilters = [
      {
        columnName: 'cycle__id',
        filters: [{ id: selectedPeriod.id, name: selectedPeriod.name }],
        nonResettable: true,
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      ...(team
        ? [
            {
              columnName: 'object_id',
              filters: [{ id: team.id, name: team.name || '' }],
              nonResettable: true,
              nonInheritable: true,
            },
          ]
        : []),
      ...(teamContentTypeFilter ? [teamContentTypeFilter] : []),
    ]

    return { employeeGoalsFilters, teamGoalsFilters }
  }, [selectedPeriod, employee, team, employeeContentTypeFilter, teamContentTypeFilter])
}
