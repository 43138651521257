import React from 'react'
import { Color, Text } from '@revolut/ui-kit'

import {
  Ratings,
  ReviewSummaryOverallFeedbackInterface,
} from '@src/interfaces/performance'
import OverallFeedback from '@src/pages/Forms/EmployeePerformanceLayout/components/OverallFeedback'
import { getReviewerTitleAndRating } from '../Common/utils'

interface Props {
  feedbacks?: ReviewSummaryOverallFeedbackInterface[]
  ratings?: Ratings[]
  title?: string
  reviewUrl?: string
  renderTitle?: boolean
}

const SidebarFeedbacks = ({
  feedbacks,
  ratings,
  title = 'Feedback',
  reviewUrl,
  renderTitle = true,
}: Props) => {
  if (!feedbacks?.length) {
    return null
  }

  return (
    <>
      {renderTitle && (
        <Text color={Color.GREY_TONE_50} fontWeight={500} fontSize="caption">
          {title}
        </Text>
      )}
      {feedbacks.map((feedback, i) => {
        const { title: reviewerTitle } = getReviewerTitleAndRating(
          feedback.review,
          ratings,
        )

        return (
          <React.Fragment key={i}>
            <OverallFeedback
              avatar={feedback.review.reviewer.avatar}
              title={reviewerTitle}
              name={feedback.review.reviewer.display_name}
              reviewerId={feedback.review.reviewer.id}
              pros={feedback.value?.pros}
              cons={feedback.value?.cons}
              isSidebar
              disabled
              updatedDate={feedback.review.updated_date}
              reviewUrl={reviewUrl}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default SidebarFeedbacks
