import {
  EligibilityCheckItem,
  EligibilityCheckOptionsInterface,
  ProgressionTrack,
} from '@src/interfaces/promotions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useEffect, useState } from 'react'
import {
  checkPromotionEligibility,
  useGetPromotionProgressionTrack,
} from '@src/api/promotions'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'

interface Props {
  data: EligibilityCheckItem[]
  isLoading: boolean
}

export const usePromotionEligibilityCriteria = (
  employee: EmployeeInterface,
  growthPlan: GrowthPlanInterface,
): Props => {
  const [isLoading, setIsLoading] = useState(true)
  const { data: progressionTrackData, isLoading: isProgressionTrackLoading } =
    useGetPromotionProgressionTrack(employee.id)
  const [criteria, setCriteria] = useState<EligibilityCheckItem[]>([])

  useEffect(() => {
    if (isProgressionTrackLoading) {
      return
    }

    const options: EligibilityCheckOptionsInterface = {
      growth_plan_id: growthPlan.id,
      seniority: growthPlan.target_seniority,
      specialisation: growthPlan.target_specialisation,
      progression_track:
        progressionTrackData?.progression_track ?? ProgressionTrack.Normal,
    }

    checkPromotionEligibility(employee.id, options)
      .then(({ data: { eligibility_criteria_results: eligibilityCriteria } }) => {
        setCriteria(
          [...eligibilityCriteria].sort(
            (lhs, rhs) => Number(rhs.passed) - Number(lhs.passed),
          ),
        )
      })
      .catch(() => {
        setCriteria([])
      })
      .finally(() => setIsLoading(false))
  }, [
    isProgressionTrackLoading,
    progressionTrackData?.progression_track,
    employee.id,
    growthPlan.id,
    growthPlan.target_specialisation.id,
    growthPlan.target_seniority.id,
  ])

  return {
    isLoading,
    data: criteria,
  }
}
