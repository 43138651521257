import React from 'react'
import { connect } from 'lape'

import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { organisationSettings } from '@src/api/settings'
import {
  FunctionSettingsSaveButton,
  SpecialisationApprovalStepSettingsField,
} from '@src/pages/Settings/Function/common'

const StructureSpecialisationsSettingsForm = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Specialisation settings"
        subtitle="Change specialisation privacy and approval settings"
        backUrl={ROUTES.SETTINGS.STRUCTURES.ROLES.ALL}
      />

      <PageBody>
        <SpecialisationApprovalStepSettingsField />
      </PageBody>

      <PageActions>
        <FunctionSettingsSaveButton />
      </PageActions>
    </PageWrapper>
  )
}

export const StructuresSpecialisationsSettings = connect(() => {
  return (
    <Form api={organisationSettings} forceParams={{ id: '1' }}>
      <StructureSpecialisationsSettingsForm />
    </Form>
  )
})
