import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, Flex, HStack, Spacer, Tag, Text, Token, VStack } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import {
  IndividualBitbucketMetricsInterface,
  OrgUnitBitbucketMetricsInterface,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { MetricHighlight } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/MetricHighlight'
import { MetricsItemSkeleton } from '@src/features/AiInsights/Performance/components/Metrics/External/common/MetricsItemSkeleton'
import { MetricDetailsVariants } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'

interface Props {
  orgUnit: BaseOption<string>
  employeeMetrics: IndividualBitbucketMetricsInterface | null
  orgUnitMetrics: OrgUnitBitbucketMetricsInterface | null
  isLoading: boolean
}

export const BitbucketMetrics = ({
  orgUnit,
  employeeMetrics,
  orgUnitMetrics,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <MetricsItemSkeleton />
  }

  if (!employeeMetrics || !orgUnitMetrics) {
    return null
  }

  const {
    employee,
    merged_prs: individualPRsMerged,
    reviewed_prs: individualPRsReviewed,
    average_pr_merge_time_seconds: individualTimeToMerge,
    added_lines: individualLinesAdded,
    removed_lines: individualLinesRemoved,
    comments_added: individualCommentsAdded,
  } = employeeMetrics

  const {
    merged_prs: averagePRsMerged,
    reviewed_prs: averagePRsReviewed,
    average_pr_merge_time_seconds: averageTimeToMerge,
    added_lines: averageLinesAdded,
    removed_lines: averageLinesRemoved,
    comments_added: averageCommentsAdded,
  } = orgUnitMetrics

  // TODO: use correct Bitbucket avatar image once it's requested and added to ui-kit
  //  https://www.figma.com/design/AdGBSfYelfKyw5GLdE1QSe/Skills-%26-Values?node-id=631-2350376&t=higmR3nmdAIgbODJ-0
  return (
    <VStack>
      <HStack space="s-16" align="center" p="s-16">
        <Avatar useIcon="Jira|image" />
        <Text fontWeight={500}>
          <FormattedMessage
            id="integrations.bitbucket.label"
            defaultMessage="Bitbucket"
          />
        </Text>
        <Spacer />
        <Tag variant="outlined" color={Token.color.deepGrey} useIcon="Link">
          <FormattedMessage
            id="integrations.common.connected.label"
            defaultMessage="Connected"
          />
        </Tag>
      </HStack>
      <VStack space="s-8" px="s-16">
        <Flex gap="s-8" flexWrap="wrap">
          <MetricHighlight
            value={individualPRsMerged ?? 0}
            average={averagePRsMerged ?? 0}
            metricName="pr"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.bitbucket.prs.merged.label"
                defaultMessage="PRs merged"
              />
            }
            flex={1}
          />
          <MetricHighlight
            value={individualPRsReviewed ?? 0}
            average={averagePRsReviewed ?? 0}
            metricName="pr"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.bitbucket.prs.reviewed.label"
                defaultMessage="PRs reviewed"
              />
            }
            flex={1}
          />
          <MetricHighlight
            value={individualTimeToMerge ?? 0}
            average={averageTimeToMerge ?? 0}
            metricName="second"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            variant={MetricDetailsVariants.Seconds}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.bitbucket.avg.merge.time.label"
                defaultMessage="Avg time to merge"
              />
            }
            flex={1}
          />
        </Flex>
        <Flex gap="s-8" flexWrap="wrap">
          <MetricHighlight
            value={individualLinesAdded ?? 0}
            average={averageLinesAdded ?? 0}
            metricName="line"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.bitbucket.lines.added.label"
                defaultMessage="Lines added"
              />
            }
            flex={1}
          />
          <MetricHighlight
            value={individualLinesRemoved ?? 0}
            average={averageLinesRemoved ?? 0}
            metricName="line"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.bitbucket.lines.removed.label"
                defaultMessage="Lines removed"
              />
            }
            flex={1}
          />
          <MetricHighlight
            value={individualCommentsAdded ?? 0}
            average={averageCommentsAdded ?? 0}
            metricName="comment"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.bitbucket.comments.added.label"
                defaultMessage="Comments added"
              />
            }
            flex={1}
          />
        </Flex>
      </VStack>
    </VStack>
  )
}
