import React, { useMemo } from 'react'

import { navigateTo } from '@src/actions/RouterActions'
import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { useGetSelectorsWithFilters } from '@src/api/selectors'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2CommentsNumColumn,
  engagementResultsV2DistributionColumn,
  getEngagementResultsV2CategoryNameColumn,
} from '@src/constants/columns/engagementResultsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useIsEngagementManager } from '@src/features/Engagement/hooks/permissions'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'

import { getDetailsPageQueryParams } from '../Details/common'
import { ItemsToAnalyse, ResultsInterface } from './common'
import {
  getDateRangeFilterParams,
  getSurveyRoundFilterParams,
  useApplyNonTableFilters,
} from './hooks/useApplyNonTableFilters'

const getRow = (
  surveyId: number,
  canViewDetails: boolean,
  queryParams: Record<string, string>,
  categoriesOptions: IdAndName[] = [],
): RowInterface<EngagementResultV2Interface> => ({
  linkToForm: canViewDetails
    ? ({ id: categoryId }) =>
        navigateTo(
          pathToUrl(
            ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.CATEGORY,
            { id: surveyId, itemId: categoryId },
            queryParams,
          ),
        )
    : undefined,
  cells: [
    { ...getEngagementResultsV2CategoryNameColumn(categoriesOptions), width: 250 },
    { ...engagementResultsV2AverageScoreColumn, width: 120 },
    { ...engagementResultsV2DistributionColumn, width: 300 },
    { ...engagementResultsV2CommentsNumColumn, width: 100 },
  ],
})

export const ResultCategoriesTable = ({
  viewMode,
  surveyId,
  timelineFilter,
  scopeFilters = [],
}: ResultsInterface) => {
  const isEngagementManager = useIsEngagementManager()

  const itemsToAnalyse: ItemsToAnalyse = 'categories'
  const disableTableRequests = viewMode !== 'table'

  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, itemsToAnalyse),
    [
      ...getSurveyRoundFilterParams(timelineFilter),
      ...getDateRangeFilterParams(
        timelineFilter.dateFrom,
        timelineFilter.dateTo,
        itemsToAnalyse,
      ),
      ...scopeFilters,
    ],
    undefined,
    {
      disable: disableTableRequests,
      disableQuery: true,
    },
  )

  useApplyNonTableFilters({
    disable: disableTableRequests || table.loading,
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'categories',
  })

  const { data: categoriesOptions = [] } = useGetSelectorsWithFilters<IdAndName>({
    type: selectorKeys.survey_drivers,
    filters: [{ columnName: 'survey_id', filters: [toIdAndName(String(surveyId))] }],
  })
  const detailsPageQueryParams = getDetailsPageQueryParams(timelineFilter, scopeFilters)
  const row = useMemo(
    () =>
      getRow(surveyId, isEngagementManager, detailsPageQueryParams, categoriesOptions),
    [surveyId, isEngagementManager, detailsPageQueryParams, categoriesOptions],
  )

  return (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultCategories}
      row={row}
      hideCount
      emptyState={<EmptyTableRaw title="Results for the categories were not found" />}
      useWindowScroll
      {...table}
    />
  )
}
