import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Portal,
  chain,
  Color,
  Text,
  Flex,
  Popup,
  Header,
  VStack,
  TextArea,
  Box,
  TextButton,
  HStack,
  Icon as UIKitIcon,
  Token,
} from '@revolut/ui-kit'
import {
  ArrowBulk,
  ArrowRates,
  Info,
  LogoutDoor,
  TriangleDown,
  Credit,
  Rocket,
  ArrowUpgrade,
  Reverted,
} from '@revolut/icons'

import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { EditableColumnInterface } from '@src/components/Table/EditableTable/EditableTable'
import {
  DepartmentCompensationReviewInterface,
  EmployeeCompensationChanges,
  EmployeeCompensationEditInterface,
} from '@src/interfaces/compensation'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import {
  getDepartmentCompensationReviewsCommentsAPI,
  getEmployeeCompensationReviewsCommentsAPI,
  getNewSalaryHistory,
} from '@src/api/compensation'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import EmployeePerformanceChart from '@src/components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { getEmployeePerformanceGraph } from '@src/api/employees'
import {
  Colored,
  ColoredPercent,
} from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import Icon from '@src/components/Icon/Icon'
import HistoryTooltip, { HistoryTypes } from '@src/components/Stepper/HistoryTooltip'
import {
  getHistoryRequest,
  optimizedSeniorityChangelogRequests,
  optimizedSpecialisationHistoryRequests,
  salaryChangelogRequests,
  changelogEmployeeRequests,
} from '@src/api/changelog'
import SalaryHistoryTooltip from '@src/pages/Forms/DepartmentForm/Budget/SalaryHistoryTooltip'
import CXCircles from '@src/components/ColumnInserts/CXCircles/CXCircles'
import CultureCircles from '@src/components/ColumnInserts/CultureCircles/CultureCircles'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  rowHasScheduledTermination,
  rowIneligibleForBonus,
  rowIneligibleForCompensation,
} from '@src/features/BudgetDistribution/BudgetDistributionTable'
import { formatMoneyWithCode } from '@src/utils/format'
import { TalentPerformanceObsoleteGradeColumn } from '@components/TalentPerformanceGradeColumn/TalentPerformanceObsoleteGradeColumn'
import { useGetOrganisationSettings } from '@src/api/settings'

export const departmentCompensationPoolColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'budget_pool',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Budget pool',
    insert: ({ data }) => data.department?.name || data.budget_pool_config?.name || '-',
  }

export const departmentCompensationTypeColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_type',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
    insert: ({ data }) => (data.department != null ? 'Department' : 'Custom'),
  }

export const departmentCompensationHeadcountColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.text,
    idPoint: 'headcount',
    dataPoint: 'headcount',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Headcount',
  }

export const departmentCompensationOwnerColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.employee,
    title: 'Owner',
    insert: ({ data }) => <UserWithAvatar {...data.owner} />,
  }

export const departmentCompensationPerformanceColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'performance',
    dataPoint: 'performance',
    sortKey: 'performance',
    filterKey: 'performance',
    filterType: FilterType.percentRange,
    selectorsKey: selectorKeys.performance,
    title: 'Performance',
    insert: ({ data }) =>
      data.department != null ? <ColoredPercent percent={data.performance * 100} /> : '-',
  }

export const departmentCompensationKPIColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'kpi_performance_percent',
    dataPoint: 'kpi_performance_percent',
    sortKey: 'kpi_performance_percent',
    filterKey: 'kpi_performance_percent',
    filterType: FilterType.percentRange,
    selectorsKey: selectorKeys.kpi_performance_percent,
    title: 'KPI',
    insert: ({ data }) =>
      data.department != null ? (
        <ColoredPercent percent={data.kpi_performance_percent * 100} />
      ) : (
        '-'
      ),
  }

export const departmentCompensationRoadmapColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'roadmap_progress_percent',
    dataPoint: 'roadmap_progress_percent',
    sortKey: 'roadmap_progress_percent',
    filterKey: 'roadmap_progress_percent',
    filterType: FilterType.percentRange,
    selectorsKey: selectorKeys.roadmap_progress_percent,
    title: 'Roadmap',
    insert: ({ data }) =>
      data.department != null ? (
        <ColoredPercent percent={data.roadmap_progress_percent * 100} />
      ) : (
        '-'
      ),
  }

export const departmentCompensationCXColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'cx_complaints_last_30_days',
    dataPoint: 'cx_complaints_last_30_days',
    sortKey: 'cx_complaints_last_30_days',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'CX',
    insert: ({ data }) => (data.department != null ? <CXCircles data={data} /> : '-'),
  }

export const departmentCompensationCultureColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'culture_enps',
    dataPoint: 'culture_enps',
    sortKey: 'percentile_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Culture',
    insert: ({ data }) =>
      data.department != null ? <CultureCircles data={data} /> : '-',
  }

export const departmentCompensationBudgetColumn = (
  currency: string,
): ColumnInterface<DepartmentCompensationReviewInterface> => ({
  type: CellTypes.insert,
  idPoint: 'new_spend',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'New spend',
  insert: ({ data }) => formatMoneyWithCode(data.salary, currency),
})

export const departmentCompensationEditableBudgetColumn = (
  currency?: string,
): EditableColumnInterface<DepartmentCompensationReviewInterface> => ({
  inputType: TableCellInputType.int,
  idPoint: 'salary',
  dataPoint: 'salary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'New spend',
  inactiveEditableInsert: data => formatMoneyWithCode(data.salary, currency),
})

export const departmentCompensationBonusColumn = (
  currency?: string,
): ColumnInterface<DepartmentCompensationReviewInterface> => ({
  type: CellTypes.insert,
  idPoint: 'bonus_grant',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus grant',
  insert: ({ data }) => formatMoneyWithCode(data.bonus, currency),
})

export const departmentCompensationEditableBonusColumn = (
  currency?: string,
): EditableColumnInterface<DepartmentCompensationReviewInterface> => ({
  inputType: TableCellInputType.int,
  idPoint: 'bonus',
  dataPoint: 'bonus',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus grant',
  inactiveEditableInsert: data => formatMoneyWithCode(data.bonus, currency),
})

export const departmentCompensationActionColumn = (
  onClick: (data: DepartmentCompensationReviewInterface) => void,
): ColumnInterface<DepartmentCompensationReviewInterface> => ({
  type: CellTypes.insert,
  idPoint: 'compensation_action',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <TextButton onClick={() => onClick(data)} height="auto">
      Reset
    </TextButton>
  ),
})

interface CommentsCellProps {
  data: DepartmentCompensationReviewInterface | EmployeeCompensationEditInterface
  mode: 'department' | 'employee'
}

const CommentsCell = ({ data, mode }: CommentsCellProps) => {
  const [commentsOpen, setCommentsOpen] = useState(false)

  const api = {
    department: getDepartmentCompensationReviewsCommentsAPI,
    employee: getEmployeeCompensationReviewsCommentsAPI,
  } as const

  const getApi = useMemo(() => api[mode], [mode])

  const commentsApi = getApi(data.id)
  const getComments = commentsApi.useGetComments(false, !commentsOpen)

  return (
    <>
      <TextButton
        onClick={e => {
          e.stopPropagation()
          setCommentsOpen(true)
        }}
        height="auto"
        p="0"
      >
        {data.comment_count > 0 ? chain('View', data.comment_count) : 'Add'}
      </TextButton>

      <Portal target="#comment-portal-target">
        {/* Because the containing row has an on click handler to open details */}
        <Box onClick={e => e.stopPropagation()}>
          <ChatSidebar
            type={ChatMessageType.Comment}
            isOpen={commentsOpen}
            onClose={() => {
              if (getComments.data?.count) {
                data.comment_count = getComments.data.count
              }
              setCommentsOpen(false)
            }}
            data={getComments.data?.results || []}
            isLoading={getComments.isLoading}
            refetch={getComments.refetch}
            onAddMessage={commentsApi.addComment}
            onEdit={commentsApi.editComment}
            onArchive={commentsApi.archiveComment}
            onResolve={commentsApi.resolveComment}
            disableTodolistFeature
          />
        </Box>
      </Portal>
    </>
  )
}

export const departmentCompensationCommentsColumn: ColumnInterface<DepartmentCompensationReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_comments',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Comments',
    insert: ({ data }) => <CommentsCell data={data} mode="department" />,
  }

const getCompensationColumnWithIneligibleColor = (
  data: EmployeeCompensationEditInterface,
) => (cellIsIneligible(data) ? Token.color.greyTone50 : '')

export const employeeCompensationEmployeeColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
    colors: getCompensationColumnWithIneligibleColor,
  }

interface SeniorityCellProps {
  employeeId: number
  seniorityName?: string
  specialisationSeniorityName?: string | null
}

export const SeniorityCell = ({
  employeeId,
  seniorityName,
  specialisationSeniorityName,
}: SeniorityCellProps) => {
  const { data: orgSettings } = useGetOrganisationSettings()
  return (
    <Flex>
      <Flex gap="s-8">
        <Text>
          {seniorityName || '-'}
          {!!orgSettings?.enable_multiple_levels_per_seniority &&
          specialisationSeniorityName
            ? ` (${specialisationSeniorityName})`
            : null}
        </Text>

        <HistoryTooltip
          isNew
          color={Token.color.greyTone50}
          request={getHistoryRequest(
            { id: employeeId },
            'seniority',
            {
              form: changelogEmployeeRequests(employeeId),
              field: optimizedSeniorityChangelogRequests(employeeId),
            },
            true,
          )}
          iconSize="tiny"
        />
      </Flex>
    </Flex>
  )
}

export const employeeCompensationSeniorityColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'seniority.id',
    dataPoint: 'seniority.name',
    sortKey: 'seniority__name',
    filterKey: 'seniority__id',
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
    colors: getCompensationColumnWithIneligibleColor,
    insert: ({ data }) => {
      return (
        <SeniorityCell
          employeeId={data.employee.id}
          seniorityName={data.seniority?.name}
          specialisationSeniorityName={data.specialisation_seniority_sublevel?.name}
        />
      )
    },
  }

interface SpecialisationCellProps {
  employeeId: number
  specialisationName?: string | null
}

export const SpecialisationCell = ({
  specialisationName,
  employeeId,
}: SpecialisationCellProps) => {
  return (
    <Flex>
      <Flex gap="s-8">
        <Text>{specialisationName || '-'}</Text>
        <HistoryTooltip
          isNew
          color={Token.color.greyTone50}
          request={getHistoryRequest(
            { id: employeeId },
            'specialisation',
            {
              form: changelogEmployeeRequests(employeeId),
              field: optimizedSpecialisationHistoryRequests(employeeId),
            },
            true,
          )}
          iconSize="tiny"
        />
      </Flex>
    </Flex>
  )
}

interface NewSalaryCellProps {
  newSalary?: string | null
  employeeId: number
  reviewId: number
}

export const NewSalaryCell = ({
  newSalary,
  employeeId,
  reviewId,
}: NewSalaryCellProps) => {
  const formattedRequest = async () => {
    const response = await getNewSalaryHistory(reviewId, {
      target__id: employeeId,
      field_name: 'salary_amount_new',
      ordering: '-effective_date_time',
    })

    const responseFormatted = response.data.results.map((item, index) => ({
      type: index === 0 ? HistoryTypes.Current : HistoryTypes.Past,
      name: `${item.created_by.full_name}: ${item.salary_amount_new_before} ${item.salary_currency.iso_code} → ${item.salary_amount_new_after} ${item.salary_currency.iso_code}`,
    }))

    return responseFormatted
  }

  return (
    <Flex>
      <Flex gap="s-8">
        <Text>{newSalary || '-'}</Text>
        <HistoryTooltip
          isNew
          color={Token.color.greyTone50}
          request={formattedRequest}
          iconSize="tiny"
        />
      </Flex>
    </Flex>
  )
}

export const employeeCompensationSpecialisationColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: 'specialisation__name',
    filterKey: 'specialisation__id',
    selectorsKey: selectorKeys.specialisations,
    title: 'Role (Specialisation)',
    colors: getCompensationColumnWithIneligibleColor,
    insert: ({ data }) => {
      return (
        <SpecialisationCell
          employeeId={data.employee.id}
          specialisationName={data.specialisation?.name}
        />
      )
    },
  }

export const employeeCompensationLocationColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.text,
    idPoint: 'location.id',
    dataPoint: 'location.name',
    sortKey: 'location__name',
    filterKey: 'location__id',
    selectorsKey: selectorKeys.location,
    title: 'Location',
    colors: getCompensationColumnWithIneligibleColor,
  }

interface CurrentSalaryCellProps {
  employeeId?: number
  salaryAmount?: number | null
  currencyCode?: string
}

export const CurrentSalaryCell = ({
  employeeId,
  salaryAmount,
  currencyCode,
}: CurrentSalaryCellProps) => {
  if (!employeeId || !currencyCode) {
    return <>-</>
  }

  return (
    <Flex>
      <Flex gap="s-8">
        <Text>{formatMoneyWithCode(salaryAmount, currencyCode)}</Text>
        <SalaryHistoryTooltip employeeId={employeeId} targetCurrency={currencyCode} />
      </Flex>
    </Flex>
  )
}

export const employeeCompensationCurrentSalaryColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    filterType: FilterType.range,
    idPoint: 'salary_amount',
    dataPoint: '',
    sortKey: 'salary_amount_usd',
    filterKey: 'salary_amount',
    selectorsKey: selectorKeys.none,
    title: 'Current salary',
    colors: getCompensationColumnWithIneligibleColor,
    insert: ({ data }) => {
      return (
        <CurrentSalaryCell
          employeeId={data.employee.id}
          salaryAmount={data.salary_amount}
          currencyCode={data.salary_currency?.iso_code}
        />
      )
    },
  }

export const employeeCompensationLatestGradeColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'latest_grade',
    dataPoint: '',
    sortKey: 'latest_score',
    filterKey: 'latest_grade',
    selectorsKey: selectorKeys.performance_grades,
    title: 'Latest grade',
    colors: getCompensationColumnWithIneligibleColor,
    insert: ({ data }) => {
      return (
        <EmployeePerformanceChart
          id={data.employee.id}
          fetchKpi={getEmployeePerformanceGraph}
          vertical="left"
        >
          <Flex justifyContent="space-between" width={96}>
            <TalentPerformanceObsoleteGradeColumn
              id={data.employee.id}
              name="grade"
              grade={data.latest_grade}
            />
            <Colored color={Token.color.greyTone20}>
              <Icon type="Graph" size="tiny" />
            </Colored>
          </Flex>
        </EmployeePerformanceChart>
      )
    },
  }

interface ScheduledForTerminationOrIneligibleCellProps {
  type: 'salary' | 'bonus'
}

const ScheduledForTerminationCell = ({
  type,
}: ScheduledForTerminationOrIneligibleCellProps) => {
  const typeToText = {
    salary:
      'Salary does not contribute to the total allowance for the budget, as employee is scheduled for termination.',
    bonus:
      'Bonus does not contribute to the total allowance for the budget, as employee is scheduled for termination.',
  }

  return (
    <Flex>
      <Flex gap="s-8">
        <Text color={Color.GREY_TONE_50}>N/A</Text>
        <Tooltip placement="top" text={typeToText[type]}>
          <Info size={16} color={Color.GREY_TONE_50} />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

const IneligibleForCompensationCell = ({
  type,
}: ScheduledForTerminationOrIneligibleCellProps) => {
  const typeToText = {
    salary:
      'Salary does not contribute to the total allowance for the budget, as employee is ineligible for compensation review.',
    bonus:
      'Bonus does not contribute to the total allowance for the budget, as employee is ineligible for bonus.',
  }

  return (
    <Flex>
      <Flex gap="s-8">
        <Text color={Color.GREY_TONE_50}>N/A</Text>
        <Tooltip placement="top" text={typeToText[type]}>
          <Info size={16} color={Color.GREY_TONE_50} />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export const cellIsIneligible = (data: EmployeeCompensationEditInterface) =>
  rowHasScheduledTermination(data) || rowIneligibleForCompensation(data)

const IneligibleCell = (props: {
  data: EmployeeCompensationEditInterface
  type: 'salary' | 'bonus'
}) => {
  if (
    props.type === 'bonus' &&
    rowIneligibleForBonus(props.data) &&
    !rowIneligibleForCompensation(props.data)
  ) {
    return <IneligibleForCompensationCell type={props.type} />
  }

  if (rowHasScheduledTermination(props.data)) {
    return <ScheduledForTerminationCell type={props.type} />
  }
  if (rowIneligibleForCompensation(props.data)) {
    return <IneligibleForCompensationCell type={props.type} />
  }

  return null
}

export const employeeCompensationNewSalaryColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    filterType: FilterType.range,
    idPoint: 'compensation_salary_amount_new',
    dataPoint: '',
    sortKey: 'salary_amount_new_usd',
    filterKey: 'salary_amount_new',
    selectorsKey: selectorKeys.none,
    title: 'New salary',
    insert: ({ data }) => {
      return cellIsIneligible(data) ? (
        <IneligibleCell data={data} type="salary" />
      ) : (
        <NewSalaryCell
          newSalary={formatMoneyWithCode(
            data.salary_amount_new,
            data.salary_currency.iso_code,
          )}
          employeeId={data.employee.id}
          reviewId={data.id}
        />
      )
    },
  }

const getSign = (value: number) => (value > 0 ? '+' : '')

export const getChangeColor = (value?: number | null) => {
  if (value == null) {
    return Color.GREY_TONE_50
  }
  if (value > 0) {
    return Color.LIGHT_GREEN
  }
  if (value < 0) {
    return Color.RED
  }
  return Color.GREY_TONE_50
}

interface SalaryChangeCellProps {
  salaryChange: number | null
  currencyCode?: string
}

export const SalaryChangeCell = ({
  salaryChange,
  currencyCode,
}: SalaryChangeCellProps) => {
  if (salaryChange == null) {
    return <>-</>
  }

  const sign = getSign(salaryChange)
  return (
    <>
      {sign}
      {formatMoneyWithCode(salaryChange, currencyCode)}
    </>
  )
}

export const employeeCompensationSalaryChangeColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_salary_change',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary change',
    colors: data => {
      const terminationColor = getCompensationColumnWithIneligibleColor(data)
      return terminationColor || getChangeColor(data.salary_change)
    },
    insert: ({ data }) => {
      const sign = getSign(data.salary_change)
      return `${sign}${formatMoneyWithCode(
        data.salary_change,
        data.salary_currency.iso_code,
      )}`
    },
  }

export const employeeCompensationSalaryChangeRecommendationColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_salary_recommendation_change',
    dataPoint: '',
    sortKey: 'salary_recommendation',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary Recommendation',
    insert: ({ data }) => {
      const salaryDeviationPercentage = Math.round(
        (data.salary_amount_new / data.salary_recommendation - 1) * 100,
      )

      return cellIsIneligible(data) ? (
        <IneligibleCell data={data} type="salary" />
      ) : (
        <Text>
          {formatMoneyWithCode(data.salary_recommendation, data.salary_currency.iso_code)}{' '}
          (deviation: {salaryDeviationPercentage || 0}%)
        </Text>
      )
    },
  }

export const employeeCompensationBonusGrantRecommendationColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_bonus_recommendation_change',
    dataPoint: '',
    sortKey: 'bonus_recommendation',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Bonus Recommendation',
    insert: ({ data }) => {
      const bonusDeviationPercentage = Math.round(
        (data.bonus_amount_new / data.bonus_recommendation - 1) * 100,
      )

      return cellIsIneligible(data) ? (
        <IneligibleCell data={data} type="bonus" />
      ) : (
        <Text>
          {formatMoneyWithCode(data.bonus_recommendation, data.bonus_currency.iso_code)}{' '}
          (deviation: {bonusDeviationPercentage || 0}%)
        </Text>
      )
    },
  }

export const employeeCompensationSalaryChangeEditableColumn: EditableColumnInterface<EmployeeCompensationEditInterface> =
  {
    inputType: TableCellInputType.positiveInt,
    idPoint: 'salary_change',
    dataPoint: 'salary_change',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary change',
    isEditable: data => !cellIsIneligible(data),
    notEditableInsert: () => <Text color={Color.GREY_TONE_50}>-</Text>,
    inactiveEditableInsert: data => {
      const sign = getSign(data.salary_change)
      return (
        <Text color={getChangeColor(data.salary_change)}>
          {sign}
          {formatMoneyWithCode(data.salary_change, data.salary_currency.iso_code)}
        </Text>
      )
    },
  }

export const getSalaryChangePercentValue = (
  salaryAmount: number | null,
  changePercent: number | null,
) => {
  if (!salaryAmount || changePercent == null) {
    return '-'
  }
  const sign = getSign(changePercent)
  return `${sign}${changePercent}%`
}

export const employeeCompensationSalaryChangePercentColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_salary_change_percent',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary change (in %)',
    colors: data => {
      const terminationColor = getCompensationColumnWithIneligibleColor(data)
      return terminationColor || getChangeColor(data.salary_change_percent)
    },
    insert: ({ data }) =>
      getSalaryChangePercentValue(data.salary_amount, data.salary_change_percent),
  }

export const employeeCompensationSalaryChangePercentEditableColumn: EditableColumnInterface<EmployeeCompensationEditInterface> =
  {
    inputType: TableCellInputType.positiveFloat,
    idPoint: 'salary_change_percent',
    dataPoint: 'salary_change_percent',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary change (in %)',
    isEditable: data => !!data.salary_amount && !cellIsIneligible(data),
    notEditableInsert: () => <Text color={Color.GREY_TONE_50}>-</Text>,
    inactiveEditableInsert: data => {
      const sign = getSign(data.salary_change_percent)
      return (
        <Text color={getChangeColor(data.salary_change_percent)}>
          {sign}
          {data.salary_change_percent}%
        </Text>
      )
    },
  }

interface SalaryBandCellProps {
  salaryAmount?: number | null
  upperBand?: number
  lowerBand?: number
  currencyCode?: string
}

export const SalaryBandCell = ({
  salaryAmount,
  upperBand,
  lowerBand,
  currencyCode,
}: SalaryBandCellProps) => {
  if (upperBand == null || lowerBand == null || salaryAmount == null) {
    return <>-</>
  }
  const isAboveBand = salaryAmount > upperBand
  const isBelowBand = salaryAmount < lowerBand

  return (
    <Flex>
      <Flex gap="s-8">
        {isAboveBand && (
          <TriangleDown color={Color.RED} style={{ transform: 'rotate(180deg)' }} />
        )}
        {isBelowBand && <TriangleDown color={Color.BLUE} />}
        <Text>
          {lowerBand.toLocaleString()}-{upperBand.toLocaleString()} {currencyCode}
        </Text>
      </Flex>
    </Flex>
  )
}

export const employeeCompensationSalaryBandColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_salary_band',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary band',
    colors: getCompensationColumnWithIneligibleColor,
    insert: ({ data }) => {
      return (
        <SalaryBandCell
          salaryAmount={data.salary_amount}
          upperBand={data.salary_benchmark?.upper_band}
          lowerBand={data.salary_benchmark?.lower_band}
          currencyCode={data.salary_benchmark?.currency.iso_code}
        />
      )
    },
  }

export const employeeCompensationBonusGrantColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    filterType: FilterType.range,
    idPoint: 'compensation_bonus_amount',
    dataPoint: '',
    sortKey: 'bonus_amount',
    filterKey: 'bonus_amount',
    selectorsKey: selectorKeys.none,
    title: 'Bonus grant',
    insert: ({ data }) =>
      cellIsIneligible(data) ? (
        <IneligibleCell data={data} type="bonus" />
      ) : (
        formatMoneyWithCode(data.bonus_amount_new, data.bonus_currency.iso_code)
      ),
  }

interface BonusChangeCellProps {
  bonusChange?: number | null
  currencyCode?: string
  employeeId?: number
  reviewId?: number
}

export const BonusChangeCell = ({
  bonusChange,
  currencyCode,
  employeeId,
  reviewId,
}: BonusChangeCellProps) => {
  if (bonusChange == null) {
    return <>-</>
  }
  if (!employeeId || !reviewId) {
    return <>-</>
  }
  const sign = getSign(bonusChange)
  return (
    <Flex gap="s-8">
      <Text color={getChangeColor(bonusChange)}>
        {sign}
        {formatMoneyWithCode(bonusChange, currencyCode)}
      </Text>
      <HistoryTooltip
        isNew
        color={Token.color.greyTone50}
        request={getHistoryRequest(
          { id: employeeId },
          'salary_amount_new',
          {
            form: changelogEmployeeRequests(employeeId),
            field: salaryChangelogRequests(employeeId, reviewId),
          },
          true,
        )}
        iconSize="tiny"
      />
    </Flex>
  )
}

export const employeeCompensationActionColumn = (
  onClick: (data: EmployeeCompensationEditInterface) => void,
): ColumnInterface<EmployeeCompensationEditInterface> => ({
  type: CellTypes.insert,
  idPoint: 'compensation_action_reset',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) =>
    cellIsIneligible(data) ? (
      ''
    ) : (
      <TextButton onClick={() => onClick(data)} height="auto" p="0">
        Reset
      </TextButton>
    ),
})

export const employeeCompensationCommentsColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_comments_insert',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Comments',
    insert: ({ data }) => <CommentsCell data={data} mode="employee" />,
  }

interface JustificationCellProps {
  data: EmployeeCompensationEditInterface
  changes: EmployeeCompensationChanges[]
  onChangeRowJustification: (
    row: EmployeeCompensationEditInterface,
    justification: string,
  ) => void
}

const JustificationCell = ({
  data,
  changes,
  onChangeRowJustification,
}: JustificationCellProps) => {
  const [justificationOpen, setJustificationOpen] = useState(false)
  const [justification, setJustification] = useState('')

  const thisRowChange = changes.find(row => row.id === data.id)

  useEffect(() => {
    setJustification(
      justificationOpen && thisRowChange?.justification
        ? thisRowChange.justification
        : '',
    )
  }, [justificationOpen, thisRowChange?.justification])

  if (!thisRowChange) {
    return null
  }

  const isEditing = !!thisRowChange.justification

  return (
    <>
      <TextButton onClick={() => setJustificationOpen(true)} height="auto" p="0">
        <HStack space="s-8" align="center">
          {isEditing ? undefined : <UIKitIcon name="Info" size={16} />}
          <Text>{isEditing ? 'Edit' : 'Add'}</Text>
        </HStack>
      </TextButton>

      <Popup
        open={justificationOpen}
        variant="bottom-sheet"
        onClose={() => setJustificationOpen(false)}
      >
        <Header variant="bottom-sheet">
          <Header.Title>Please justify your changes</Header.Title>
        </Header>

        <VStack space="s-16">
          <Text variant="caption" color={Color.GREY_TONE_50}>
            You must provide an acceptable rationale for editing the salary change amount
            and/or bonus grant amount recommended for this employee. This will be reviewed
            by the compensation and audit teams.
          </Text>

          <TextArea
            label="Justification"
            value={justification}
            onChange={e => setJustification(e.currentTarget.value)}
          />
        </VStack>

        <Popup.Actions horizontal>
          <Button onClick={() => setJustificationOpen(false)} variant="secondary">
            Go back
          </Button>
          <Button
            onClick={() => {
              onChangeRowJustification(data, justification)
              setJustificationOpen(false)
            }}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const employeeCompensationJustificationColumn = (
  changes: EmployeeCompensationChanges[],
  onChangeRowJustification: (
    row: EmployeeCompensationEditInterface,
    justification: string,
  ) => void,
): ColumnInterface<EmployeeCompensationEditInterface> => ({
  type: CellTypes.insert,
  idPoint: 'compensation_employee_justification',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Justification',
  insert: ({ data }) => (
    <JustificationCell
      data={data}
      changes={changes}
      onChangeRowJustification={onChangeRowJustification}
    />
  ),
})

export const employeeCompensationFlagsColumn: ColumnInterface<EmployeeCompensationEditInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_flags',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Flags',
    insert: ({ data }) => {
      const iconSize = 18
      const flags = [
        {
          tooltip: `Internal move ongoing`,
          component: <ArrowBulk color={Color.BLUE} size={iconSize} />,
          visible: !!data.is_internal_move,
        },
        {
          tooltip: `PIP ongoing`,
          component: <ArrowRates color={Color.RED} size={iconSize} />,
          visible: !!data.pip_cycle,
        },
        {
          tooltip: `Employee scheduled for termination.`,
          component: <LogoutDoor color={Color.GREY_TONE_50} size={iconSize} />,
          visible: !!data.termination,
        },
        {
          tooltip: 'Employee is ineligible for compensation review',
          component: <Reverted color={Color.GREY_TONE_50} size={iconSize} />,
          visible: !!rowIneligibleForCompensation(data),
        },
        {
          tooltip: `Material Risk Taker`,
          component: <Credit color={Color.ORANGE} size={iconSize} />,
          visible: !!data.is_mrt,
        },
        {
          tooltip: `Promotion ongoing`,
          component: <Rocket color={Color.BLUE} size={iconSize} />,
          visible: !!data.promotion,
        },
        {
          tooltip: `Reaching next sub-seniority`,
          component: <ArrowUpgrade color={Color.BLUE} size={iconSize} />,
          visible: !!data.is_sub_seniority_level_up,
        },
      ].filter(flag => flag.visible)

      if (flags.length === 0) {
        return <Text color={Color.GREY_TONE_50}>-</Text>
      }

      return (
        <Flex gap="s-4">
          {flags.map(flag => (
            <Tooltip text={flag.tooltip} placement="top" key={flag.tooltip}>
              {flag.component}
            </Tooltip>
          ))}
        </Flex>
      )
    },
  }
