import React from 'react'
import { Flex, FlexProps } from '@revolut/ui-kit'

const COL_WIDTH = {
  all: 320,
  sm: 400,
}

interface Props extends FlexProps {
  left: React.ReactNode
  right: React.ReactNode
  leftFlex?: number
  rightFlex?: number
  leftMinWidth?: FlexProps['minWidth']
  leftMaxWidth?: FlexProps['maxWidth']
  rightMinWidth?: FlexProps['minWidth']
  rightMaxWidth?: FlexProps['maxWidth']
}
export const TwoColumnsLayout = ({
  left,
  right,
  leftMinWidth,
  leftMaxWidth,
  rightMinWidth,
  rightMaxWidth,
  leftFlex = 1,
  rightFlex = 1,
  ...flexProps
}: Props) => {
  return (
    <Flex gap="s-16" flexWrap="wrap" {...flexProps}>
      <Flex
        flex={leftFlex}
        minWidth={leftMinWidth || COL_WIDTH}
        flexDirection="column"
        gap="s-16"
        maxWidth={leftMaxWidth}
      >
        {left}
      </Flex>
      <Flex
        flex={rightFlex}
        minWidth={rightMinWidth || COL_WIDTH}
        flexDirection="column"
        gap="s-16"
        maxWidth={rightMaxWidth}
      >
        {right}
      </Flex>
    </Flex>
  )
}
