import React, { MouseEvent, useState } from 'react'
import {
  MoreBar,
  useTooltip,
  Tooltip as UIKitTooltip,
  Popup,
  Header,
  Button,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { Pencil, Plus } from '@revolut/icons'
import { Statuses } from '@src/interfaces'
import SettingsButtons from '@src/features/SettingsButtons'
import { successNotification } from '@src/store/notifications/actions'
import { ArrowRepeat, Cross, Delete } from '@revolut/icons'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { ActionButtonVariant } from '@revolut/ui-kit/types/dist/components/ActionButton/ActionButton'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: Plus,
  [ButtonStates.Edit]: Pencil,
  [ButtonStates.Continue]: Pencil,
}

const ButtonVariant: Record<ButtonStates, ActionButtonVariant> = {
  [ButtonStates.Add]: 'accent',
  [ButtonStates.Edit]: 'primary',
  [ButtonStates.Continue]: 'primary',
}

type Props = {
  submitReviewDisabledReason: string | null
  onClickAddReview: (e: MouseEvent<HTMLButtonElement>) => void
  onClickViewReview: () => void
  onClickRequestReview: () => void
  onClickCancelPIP?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  onClickRegenerateScorecards?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  onClickCloseProbation?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  onClickDeleteProbation?: () => Promise<void>
  userReviewStatus?: Statuses | null
  disabled?: boolean
  canAddReview: boolean
  canRequestFeedback?: boolean
  canRejectReview?: boolean
  onClickRejectReview?: () => void
  canCancelPIP?: boolean
  canOpenPIPSettings?: boolean
  onClickOpenPIPSettings?: () => void
  canRegenerateScorecards?: boolean
  canDeleteProbationCycle?: boolean
  canChangeCycleSettings?: boolean
  handleOpenCycleSettings?: () => void
  isProbation?: boolean
  isProbationPeriod?: boolean
  isPerformanceReview?: boolean
}

const PerformanceActions = ({
  canAddReview,
  submitReviewDisabledReason,
  onClickAddReview,
  onClickRejectReview,
  onClickCancelPIP,
  onClickRegenerateScorecards,
  onClickCloseProbation,
  onClickDeleteProbation,
  userReviewStatus,
  onClickViewReview,
  onClickRequestReview,
  disabled,
  canRequestFeedback,
  canRejectReview,
  canCancelPIP,
  canOpenPIPSettings,
  onClickOpenPIPSettings,
  canRegenerateScorecards,
  canDeleteProbationCycle,
  canChangeCycleSettings,
  handleOpenCycleSettings,
  isProbation,
  isProbationPeriod,
  isPerformanceReview = false,
}: Props) => {
  const { id } = useParams<{ id: string }>()
  const tooltip = useTooltip()
  const [cancelPIPPending, setCancelPIPPending] = useState(false)
  const [regenerateScorecardsPending, setRegenerateScorecardsPending] = useState(false)
  const [closeProbationPending, setCloseProbationPending] = useState(false)
  const [popupOpened, setPopupOpened] = useState(false)
  const statusPopup = useStatusPopup()
  let state = ButtonStates.Add

  const { data: performanceSettings } = useGetPerformanceSettings()
  const probationEnabled = performanceSettings?.enable_probation

  if (userReviewStatus === Statuses.completed) {
    state = ButtonStates.Edit
  }

  if (userReviewStatus === Statuses.draft) {
    state = ButtonStates.Continue
  }

  const showSuccess = () => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={() => {
          navigateTo(
            pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
              id,
            }),
          )
        }}
      >
        <StatusPopup.Title>Cycle was successfully deleted</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const showError = () =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Error deleting probation</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )

  const onDeleteProbation = async () => {
    setCloseProbationPending(true)
    try {
      await onClickDeleteProbation?.()
      setPopupOpened(false)
      showSuccess()
    } catch {
      showError()
    } finally {
      setCloseProbationPending(false)
    }
  }

  return (
    <SettingsButtons my="s-16" maxWidth={470}>
      {canAddReview && (
        <MoreBar.Action
          useIcon={ButtonIcon[state]}
          variant={ButtonVariant[state]}
          aria-disabled={!!submitReviewDisabledReason}
          onClick={e => {
            if (!submitReviewDisabledReason) {
              onClickAddReview(e)
            }
          }}
          {...tooltip.getAnchorProps()}
        >
          {ButtonTitles[state]}
          {submitReviewDisabledReason && (
            <UIKitTooltip {...tooltip.getTargetProps()} width={200}>
              {submitReviewDisabledReason}
            </UIKitTooltip>
          )}
        </MoreBar.Action>
      )}

      {canRejectReview && onClickRejectReview && (
        <MoreBar.Action variant="negative" onClick={onClickRejectReview}>
          Reject review request
        </MoreBar.Action>
      )}

      <MoreBar.Action onClick={onClickViewReview} disabled={disabled}>
        View Reviews
      </MoreBar.Action>
      {canRequestFeedback && (
        <MoreBar.Action onClick={onClickRequestReview} disabled={disabled}>
          Request Review
        </MoreBar.Action>
      )}

      {canChangeCycleSettings && !isPerformanceReview && (
        <MoreBar.Action onClick={handleOpenCycleSettings}>Cycle settings</MoreBar.Action>
      )}

      {canOpenPIPSettings && (
        <MoreBar.Action onClick={onClickOpenPIPSettings}>Setup</MoreBar.Action>
      )}

      {canCancelPIP && (
        <MoreBar.Action
          variant="negative"
          pending={cancelPIPPending}
          onClick={async e => {
            setCancelPIPPending(true)
            try {
              await onClickCancelPIP?.(e)
              successNotification('PIP canceled')
            } finally {
              setCancelPIPPending(false)
            }
          }}
        >
          Cancel PIP
        </MoreBar.Action>
      )}
      {canRegenerateScorecards && isProbation && isProbationPeriod && (
        <>
          <MoreBar.Action
            useIcon={ArrowRepeat}
            pending={regenerateScorecardsPending}
            onClick={async e => {
              setRegenerateScorecardsPending(true)
              try {
                await onClickRegenerateScorecards?.(e)
                successNotification('Scorecards regenerated')
              } finally {
                setRegenerateScorecardsPending(false)
              }
            }}
          >
            Regenerate scorecards
          </MoreBar.Action>
          {probationEnabled && (
            <>
              <MoreBar.Action
                useIcon={Cross}
                variant="negative"
                pending={closeProbationPending}
                onClick={async e => {
                  setCloseProbationPending(true)
                  try {
                    await onClickCloseProbation?.(e)
                    successNotification('Probation cycle closed')
                  } finally {
                    setCloseProbationPending(false)
                  }
                }}
              >
                Close probation cycle
              </MoreBar.Action>
            </>
          )}
        </>
      )}
      {isProbation && canDeleteProbationCycle && (
        <>
          <MoreBar.Action
            useIcon={Delete}
            variant="negative"
            onClick={() => setPopupOpened(true)}
          >
            Delete probation cycle
          </MoreBar.Action>
          <Popup
            open={popupOpened}
            onClose={() => setPopupOpened(false)}
            variant="bottom-sheet"
          >
            <Header variant="bottom-sheet">
              <Header.Title>Are you sure you want to delete this cycle?</Header.Title>
            </Header>
            <Popup.Actions horizontal>
              <Button variant="secondary" onClick={() => setPopupOpened(false)}>
                Cancel
              </Button>
              <Button
                elevated
                onClick={onDeleteProbation}
                pending={closeProbationPending}
              >
                Delete
              </Button>
            </Popup.Actions>
          </Popup>
        </>
      )}
    </SettingsButtons>
  )
}

export default PerformanceActions
