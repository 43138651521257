import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { TextSkeleton, useToggle } from '@revolut/ui-kit'
import {
  cancelEmployeeFieldRequest,
  cancelEmployeeFieldsRequest,
  cancelEmployeePayrollDetailsTableRequests,
  cancelEmployeePersonalInfoChangeRequest,
  cancelEmployeeWorkAndCompensationChangeRequest,
  changelogEmployeeInfoTableRequests,
  changelogEmployeePayrollDetailsTableRequests,
  changelogEmployeePersonalInfoTableRequests,
  changelogEmployeeWorkAndCompensationTableRequests,
  changelogFieldsEmployeeInfoTableRequests,
  changelogFieldsEmployeePayrollDetailsTableRequests,
  changelogFieldsEmployeePersonalInfoTableRequests,
  changelogFieldsEmployeeWorkAndCompensationTableRequests,
} from '@src/api/changelog'
import { SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { TableNames } from '@src/constants/table'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { ChangeRequestActions } from './EmploeeInfo/ChangeRequestActions'
import { RequestChangeButton } from './EmploeeInfo/RequestChangeButton'
import { ChangelogContent } from './ChangelogContent'
import { CommonTable } from './CommonTable'

interface Props {
  employeeId: number
  employeeRecordToPrimaryContractEnabled: boolean
  payrollDetailsId: number
  personalInfoId: number
  workAndOrgDetailsId?: number
}

const initialSortBy: SortByInterface[] = [
  {
    sortBy: 'effective_date_time',
    direction: SORT_DIRECTION.ASC,
  },
]

export const ChangelogPage = ({
  employeeId,
  employeeRecordToPrimaryContractEnabled,
  payrollDetailsId,
  personalInfoId,
  workAndOrgDetailsId,
}: Props) => {
  const params = useParams()
  const [showIndividual, showIndividualToggler] = useToggle({ defaultState: true })

  const changelogEmployeeInfoTableApi = useMemo(() => {
    const getItems = showIndividual
      ? changelogFieldsEmployeeInfoTableRequests(employeeId)
      : changelogEmployeeInfoTableRequests(employeeId)

    return { getItems }
  }, [employeeId, showIndividual])
  const changelogPersonalInfoTableApi = useMemo(() => {
    const getItems = showIndividual
      ? changelogFieldsEmployeePersonalInfoTableRequests(personalInfoId)
      : changelogEmployeePersonalInfoTableRequests(personalInfoId)

    return { getItems }
  }, [personalInfoId, showIndividual])
  const changelogPayrollInfoTableApi = useMemo(() => {
    const getItems = showIndividual
      ? changelogFieldsEmployeePayrollDetailsTableRequests(payrollDetailsId)
      : changelogEmployeePayrollDetailsTableRequests(payrollDetailsId)

    return { getItems }
  }, [payrollDetailsId, showIndividual])
  const changelogWorkAndCompensationTableApi = useMemo(() => {
    const getItems = showIndividual
      ? changelogFieldsEmployeeWorkAndCompensationTableRequests(workAndOrgDetailsId!)
      : changelogEmployeeWorkAndCompensationTableRequests(workAndOrgDetailsId!)

    return { getItems }
  }, [showIndividual, workAndOrgDetailsId])

  const changelogEmployeeInfoTable = useTable(
    changelogEmployeeInfoTableApi,
    undefined,
    initialSortBy,
    { refetchOnApiChange: true },
  )
  const changelogPersonalInfoTable = useTable(
    changelogPersonalInfoTableApi,
    undefined,
    initialSortBy,
    { refetchOnApiChange: true },
  )
  const changelogPayrollInfoTable = useTable(
    changelogPayrollInfoTableApi,
    undefined,
    initialSortBy,
    { refetchOnApiChange: true },
  )
  const changelogWorkAndCompensationTable = useTable(
    changelogWorkAndCompensationTableApi,
    undefined,
    initialSortBy,
    {
      disable: !employeeRecordToPrimaryContractEnabled || !workAndOrgDetailsId,
      refetchOnApiChange: true,
    },
  )

  const tabs = [
    {
      title: 'Employee Info',
      path: ROUTES.FORMS.EMPLOYEE.CHANGELOG.EMPLOYEE_INFO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG.EMPLOYEE_INFO, params),
      component: (
        <CommonTable
          cancelField={(id, fieldName, entityId) =>
            cancelEmployeeFieldRequest(id, fieldName, entityId)
          }
          cancelFields={(id, entityId) => cancelEmployeeFieldsRequest(id, entityId)}
          changelogTable={changelogEmployeeInfoTable}
          changeRequestActions={(id, request) => (
            <ChangeRequestActions
              employeeId={id}
              onSuccess={changelogEmployeeInfoTable.refresh}
              request={request}
            />
          )}
          entityId={employeeId}
          requestChangeButton={id => <RequestChangeButton employeeId={id} />}
          selectorChangedFields={selectorKeys.employee_changelog_fields}
          selectorNewValues={selectorKeys.employee_changelog_fields}
          showIndividual={showIndividual}
          showIndividualToggler={showIndividualToggler}
          tableName={
            showIndividual
              ? TableNames.EmployeeFieldsChangelog
              : TableNames.EmployeeChangelog
          }
        />
      ),
      quickSummary: changelogEmployeeInfoTable.loading ? (
        <TextSkeleton size={1} />
      ) : (
        changelogEmployeeInfoTable.count
      ),
    },
    {
      title: 'Personal Info',
      path: ROUTES.FORMS.EMPLOYEE.CHANGELOG.PERSONAL_INFO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG.PERSONAL_INFO, params),
      component: (
        <CommonTable
          cancelFields={(id, entityId) =>
            cancelEmployeePersonalInfoChangeRequest(id, entityId)
          }
          changelogTable={changelogPersonalInfoTable}
          entityId={personalInfoId}
          selectorChangedFields={selectorKeys.employee_personal_changelog_fields}
          selectorNewValues={selectorKeys.employee_personal_changelog_values}
          showIndividual={showIndividual}
          showIndividualToggler={showIndividualToggler}
          tableName={
            showIndividual
              ? TableNames.EmployeePersonalInfoChangelogFields
              : TableNames.EmployeePersonalInfoChangelog
          }
        />
      ),
      quickSummary: changelogPersonalInfoTable.loading ? (
        <TextSkeleton size={1} />
      ) : (
        changelogPersonalInfoTable.count
      ),
    },
    {
      title: 'Payroll Info',
      path: ROUTES.FORMS.EMPLOYEE.CHANGELOG.PAYROLL_INFO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG.PAYROLL_INFO, params),
      component: (
        <CommonTable
          cancelFields={(id, entityId) =>
            cancelEmployeePayrollDetailsTableRequests(id, entityId)
          }
          changelogTable={changelogPayrollInfoTable}
          entityId={payrollDetailsId}
          selectorChangedFields={selectorKeys.employee_payroll_changelog_fields}
          selectorNewValues={selectorKeys.employee_payroll_changelog_values}
          showIndividual={showIndividual}
          showIndividualToggler={showIndividualToggler}
          tableName={
            showIndividual
              ? TableNames.EmployeePayrollDetailsChangelogFields
              : TableNames.EmployeePayrollDetailsChangelog
          }
        />
      ),
      quickSummary: changelogPayrollInfoTable.loading ? (
        <TextSkeleton size={1} />
      ) : (
        changelogPayrollInfoTable.count
      ),
    },
    {
      title: 'Compensation Info',
      path: ROUTES.FORMS.EMPLOYEE.CHANGELOG.COMPENSATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG.COMPENSATION, params),
      component: (
        <CommonTable
          cancelFields={(id, entityId) =>
            cancelEmployeeWorkAndCompensationChangeRequest(id, entityId)
          }
          changelogTable={changelogWorkAndCompensationTable}
          entityId={workAndOrgDetailsId!}
          selectorChangedFields={
            selectorKeys.employee_work_and_compensation_changelog_fields
          }
          selectorNewValues={selectorKeys.employee_work_and_compensation_changelog_values}
          showIndividual={showIndividual}
          showIndividualToggler={showIndividualToggler}
          tableName={
            showIndividual
              ? TableNames.EmployeeWorkAndCompensationChangelogFields
              : TableNames.EmployeeWorkAndCompensationChangelog
          }
        />
      ),
      quickSummary: changelogWorkAndCompensationTable.loading ? (
        <TextSkeleton size={1} />
      ) : (
        changelogWorkAndCompensationTable.count
      ),
      canView: employeeRecordToPrimaryContractEnabled,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView !== false)

  return <ChangelogContent tabs={filteredTabs} />
}
