import React from 'react'
import { Box, InputGroup, Side } from '@revolut/ui-kit'
import { useGetContractsSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import {
  EmployeeWorkAndOrgDetailsInterface,
  InternalOrExternalEmployee,
} from '@src/interfaces/employees'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import EmployeeDocSectionLape from '@src/features/FormTabs/Employee/EmployeeDocSectionLape'
import { UpdateButton } from './UpdateButton'
import { useEmployeeDetailsOnAfterSubmit } from '../hooks'

export interface Props {
  data: InternalOrExternalEmployee
  isSidebarContent?: boolean
  refreshData?: VoidFunction
}

export const WorkAndOrgDetailsFormContent = ({
  data,
  isSidebarContent,
  refreshData,
}: Props) => {
  const form = useLapeContext<EmployeeWorkAndOrgDetailsInterface>()
  const { values } = form
  const isInternal = data.employee_type === 'internal'

  const { data: contractsSettings } = useGetContractsSettings()
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)

  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <NewStepperTitle title="Salary" />
        <InputGroup>
          <InputGroup variant="horizontal">
            <LapeNewInput
              label="Base salary amount"
              name="salary_amount"
              required
              type="money"
              width="50%"
            />
            <LapeRadioSelectInput
              label="Currency"
              name="salary_currency"
              required
              selector={selectorKeys.currencies}
            />
          </InputGroup>
          <InputGroup variant="horizontal">
            <LapeRadioSelectInput
              label="Payment frequency"
              name="salary_payment_frequency"
              selector={selectorKeys.employee_contract_payment_frequency}
            />
            <LapeRadioSelectInput
              label="Time unit"
              name="salary_time_unit"
              selector={selectorKeys.employee_contract_time_unit}
            />
          </InputGroup>
        </InputGroup>
        <NewStepperTitle title="Joining bonus" />
        <InputGroup>
          <LapeRadioSelectInput
            label="Bonus type"
            name="sign_on_bonus_type"
            selector={selectorKeys.contract_sign_on_bonus_types}
          />
          {values.sign_on_bonus_type == null ||
          values.sign_on_bonus_type.id === 'no_bonus' ? null : (
            <InputGroup variant="horizontal">
              <LapeNewInput
                label="Bonus amount"
                name="sign_on_bonus_amount"
                required
                type="money"
                width="50%"
              />
              <LapeRadioSelectInput
                label="Currency"
                name="sign_on_bonus_currency"
                selector={selectorKeys.currencies}
              />
            </InputGroup>
          )}
        </InputGroup>
        <NewStepperTitle title="Employment" />
        <InputGroup>
          <InputGroup variant="horizontal">
            <LapeNewInput
              description="Contractual hours worked per week, as per employment contract"
              label="Weekly working hours"
              name="weekly_working_hours"
              required
              type="number"
              width="50%"
            />
            <LapeNewInput
              description="Ratio from 0-1 based on number of hours worked per week compared to full time"
              label="Full time equivalent"
              name="full_time_equivalent"
              required
              type="number"
            />
          </InputGroup>
        </InputGroup>
        <NewStepperTitle title="Notice period" />
        <InputGroup>
          <InputGroup variant="horizontal">
            <LapeNewInput
              description="Notice period as per the employment contract during probation period"
              label="During probation"
              name="notice_period_during_probation"
              required
              type="number"
              width="50%"
            />
            <LapeRadioSelectInput
              label="Time unit"
              name="notice_period_during_probation_unit"
              selector={selectorKeys.employee_contract_probation_unit}
            />
          </InputGroup>
          <InputGroup variant="horizontal">
            <LapeNewInput
              description="Notice period as per the employment contract after probation period "
              label="After probation"
              name="notice_period_after_probation"
              required
              type="number"
              width="50%"
            />
            <LapeRadioSelectInput
              label="Time unit"
              name="notice_period_after_probation_unit"
              selector={selectorKeys.employee_contract_probation_unit}
            />
          </InputGroup>
        </InputGroup>
        {contractsSettings?.documents_enabled && isInternal ? (
          <Box mb="s-24">
            <NewStepperTitle title="Document" />
            <EmployeeDocSectionLape
              initialValues={{
                confidentiality: { id: 'hr_manager', name: 'HR Manager' },
                category: { id: 1, name: 'Employment contract - Full time' },
              }}
              employee={data}
            />
          </Box>
        ) : null}
      </PageBody>
      <ActionsWrapper>
        <UpdateButton
          datePickerProps={{
            disabledDays: { before: new Date() },
          }}
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}
