import React from 'react'
import { Box, VStack } from '@revolut/ui-kit'

import {
  FeatureBlock,
  FeatureBlockVariant,
} from '@src/pages/Landing/components/FeatureBlock'
import { FeatureBlockSummary } from './components/FeatureBlockSummary'
import {
  TECHSCALE_1,
  TECHSCALE_2,
  TECHSCALE_3,
  TECHSCALE_HERO,
  TECHSCALE_STORY_1,
} from './constants'
import { LandingHeader } from '@src/pages/Landing/components/Header'
import { CompanyDescription } from './components/CompanyDescription'
import { CustomerStory } from './components/CustomerStory'
import { Footer } from '../Landing/components/Footer'
import { ToolsUsed } from './components/ToolsUsed'
import { NextStep } from './components/NextStep'
import { DEMO_RECRUITMENT_URL } from '../Landing/constants'

export const Techscale = () => {
  return (
    <Box>
      <LandingHeader bg="rgb(48,47,111)" pb="s-16" />

      <FeatureBlock
        title="Techscale simplifies HR and attracts record applicants"
        description={
          <FeatureBlockSummary
            industry="Software development"
            size="60+ employees"
            headquarters="Copenhagen"
            bullets={[
              'HR team saved 7 hours per week',
              'Average number of applications grew by 180%',
            ]}
          />
        }
        caption="Case study"
        image={TECHSCALE_HERO}
        withPadding
        variant={FeatureBlockVariant.Right}
        url={DEMO_RECRUITMENT_URL}
        baseProps={{
          backgroundColor: 'rgb(103, 99, 216)',
          pb: `0 !important`,
        }}
        imageContainerProps={{ marginTop: 'auto' }}
        imageProps={{
          height: 672,
          marginTop: 100,
        }}
        contentProps={{
          paddingTop: { lg: 's-32', all: 's-64' },
          paddingBottom: { lg: 's-32', all: '0' },
        }}
      />

      <CompanyDescription>
        Techscale — A product development agency based in Copenhagen, Denmark specializing
        in apps, software & web.
      </CompanyDescription>

      <CustomerStory
        story={{
          ...TECHSCALE_STORY_1,
          quoteBy: undefined,
          label: TECHSCALE_STORY_1.quoteBy,
          url: undefined,
        }}
      />

      <ToolsUsed
        label="The tools Techscale used"
        tools={[
          'Automated scheduling',
          'Integrations with LinkedIn & Microsoft',
          'Bulk CV screening',
          'Automated communication templates',
        ]}
      />

      <VStack space={{ lg: 's-64', all: 's-16' }} my="s-64">
        <FeatureBlock
          title="Lacking the foundations to grow"
          description={
            <>
              TechScale experienced rapid growth, with a 22% headcount increase between
              2023 and 2024. This growth exposed inefficiencies in their people processes,
              particularly in recruitment and onboarding. Techscale was not set up to
              scale, wasting resources on day-to-day operations and lacking insights from
              employee interactions.{'\n\n'}The company's HR manager was spending over 7
              hours per week on tasks that could be automated, whilst candidates faced
              delayed responses.
            </>
          }
          caption="Challenge"
          image={TECHSCALE_1}
          variant={FeatureBlockVariant.Right}
          showBookDemoButton={false}
          imageProps={{ height: 600 }}
          baseProps={{ maxWidth: 1000 }}
        />

        <FeatureBlock
          title="Automations for hiring at scale"
          description={
            <>
              TechScale was already using Revolut in other aspects of their business. The
              team was impressed with how many pain points the platform aimed to solve
              with its key features such as automated communication tools, one click
              Microsoft integration for interview scheduling and an ability to create a
              talent bank.
              {'\n\n'}
              The system supported the cross-country operations. These features aligned
              perfectly with TechScale’s goals.{'\n\n'}The onboarding and implementation
              was easy. The Revolut Team helped us roll out in 3 phases to secure a smooth
              onboarding: Pilot phase, Management phase, Employee Phase. We were
              successfully onboarded within weeks and after 1 month of using the software,
              all employees were comfortable on the platform
            </>
          }
          caption="Solution"
          image={TECHSCALE_2}
          showBookDemoButton={false}
          imageProps={{ height: 600 }}
          baseProps={{ maxWidth: 1000 }}
        />

        <FeatureBlock
          title="a wider talent pool to raise the bar"
          description={
            <>
              Revolut People exceeded expectations. After a brief introduction to the
              tool, the HR team began saving 7 hours a week as they eliminated manual
              tasks. The platform’s LinkedIn job posting integration increased the average
              number of applications by 180%, and over 200 collective hours were saved in
              preparation for their most recent performance review cycles.{'\n\n'}
              The need for an additional hire in People Operations was removed, enabling
              TechScale to streamline their operations while maintaining a lean team.
            </>
          }
          caption="Results"
          image={TECHSCALE_3}
          variant={FeatureBlockVariant.Right}
          showBookDemoButton={false}
          imageProps={{ height: 600 }}
          baseProps={{ maxWidth: 1000 }}
        />
      </VStack>

      <NextStep tourUrl={DEMO_RECRUITMENT_URL} />

      <Footer />
    </Box>
  )
}
