import React, { useState } from 'react'
import {
  Avatar,
  Box,
  chain,
  Circle,
  HStack,
  Icon,
  Item,
  Tag,
  Text,
  TextButton,
  Token,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import { engagementAnswersTableRequests } from '@src/api/engagement'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { useTableReturnType } from '@src/components/TableV2/hooks'
import { EngagementAnswerInterface } from '@src/interfaces/engagement'
import { formatDateTime } from '@src/utils/format'
import SideBar from '@src/components/SideBar/SideBar'
import { AcknowledgementSidebar } from '../ResultsTab/AnswersTable/AcknowledgementSidebar'

const mapScoreToColor = (score: number) => {
  if (score <= 3) {
    return Token.color.danger
  }
  if (score > 3 && score <= 7) {
    return Token.color.yellow
  }
  if (score > 7) {
    return Token.color.success
  }
  return undefined
}

const mapScoreToBackgroundColor = (score: number) => {
  if (score <= 3) {
    return Token.color.red_20
  }
  if (score > 3 && score <= 7) {
    return Token.color.yellow_20
  }
  if (score > 7) {
    return Token.color.green_20
  }
  return undefined
}

interface Props {
  table: useTableReturnType<EngagementAnswerInterface>
}
export const Comments = ({ table }: Props) => {
  const [selectedAnswer, setSelectedAnswer] = useState<EngagementAnswerInterface>()
  const [sidebarOpen, toggleSidebarOpen] = useToggle()

  return (
    <>
      <Widget p="s-12">
        <TablePreview<EngagementAnswerInterface>
          title={
            <Box ml="s-12">
              <Text color={Token.color.foreground} variant="h4">
                {chain('Comments', table.count)}
              </Text>
            </Box>
          }
          table={table}
          api={engagementAnswersTableRequests}
          emptyState={{
            title: 'No comments found',
            icon: 'Chat',
          }}
          row={answer => {
            return (
              <Item my="s-12">
                <Item.Avatar>
                  {answer.answer_score ? (
                    <Circle
                      size={40}
                      variant="filled"
                      color={mapScoreToColor(answer.answer_score)}
                      backgroundColor={mapScoreToBackgroundColor(answer.answer_score)}
                    >
                      {answer.answer_score}
                    </Circle>
                  ) : (
                    <Avatar
                      image={{
                        default:
                          'https://assets.revolut.com/assets/3d-images-v2/3D278.png',
                        '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D278@2x.png',
                        '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D278@3x.png',
                      }}
                    />
                  )}
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{answer.answer_text}</Item.Title>
                  <Item.Description>
                    <VStack mt="s-8" space="s-8">
                      <Text>{formatDateTime(answer.creation_date_time)}</Text>
                      <HStack space="s-8" align="center">
                        {answer.acknowledged ? (
                          <Tag
                            bg="accent"
                            useIcon="16/CheckCircle"
                            variant="status"
                            color={Token.color.white}
                          >
                            Acknowledged
                          </Tag>
                        ) : null}
                        <HStack space="s-4" align="center">
                          <Icon
                            size={15}
                            color={Token.color.blue}
                            name={answer.acknowledged ? '16/CommentAdd' : 'Check'}
                          />
                          <TextButton
                            variant="accent"
                            onClick={() => {
                              setSelectedAnswer(answer)
                              toggleSidebarOpen()
                            }}
                          >
                            {answer.acknowledged ? 'Replies' : 'Acknowledge'}
                          </TextButton>
                        </HStack>
                      </HStack>
                    </VStack>
                  </Item.Description>
                </Item.Content>
              </Item>
            )
          }}
        />
      </Widget>
      {selectedAnswer && (
        <SideBar
          variant="medium"
          isOpen={sidebarOpen}
          onClose={toggleSidebarOpen.off}
          title={selectedAnswer?.answer_text}
        >
          <AcknowledgementSidebar
            answerId={selectedAnswer.id}
            onAcknowledge={() => table.refresh()}
          />
        </SideBar>
      )}
    </>
  )
}
