import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Tooltip, TooltipProps } from '@revolut/ui-kit'
import { GradeOption } from '@src/interfaces/selectors'
import { FinalGrade } from '@src/interfaces/performance'

interface Props extends TooltipProps {
  grades: GradeOption[]
}

export const NipsCalculationTooltip = ({ grades, ...tooltipProps }: Props) => {
  const nipsCalculationFormula = `${getGradeOptionExpression(
    grades,
    FinalGrade.Exceptional,
  )}${getGradeOptionExpression(grades, FinalGrade.Strong, '+')}${getGradeOptionExpression(
    grades,
    FinalGrade.AveragePlus,
    '+',
  )}${getGradeOptionExpression(
    grades,
    FinalGrade.AverageMinus,
    '-',
  )}${getGradeOptionExpression(grades, FinalGrade.Poor, '-')}`

  return (
    <Tooltip {...tooltipProps}>
      <FormattedMessage
        id="performance.nips.calculation.label"
        values={{ formula: nipsCalculationFormula }}
        defaultMessage="Net Individual Performance Score (NIPS) = {formula}. These stats are calculated using the selected or most recent performance review cycle."
      />
    </Tooltip>
  )
}

const getGradeOptionExpression = (
  grades: GradeOption[],
  gradeId: FinalGrade,
  operator?: string,
  prefix = '%',
): string => {
  const grade = grades.find(({ id }) => id === gradeId)
  if (!grade) {
    return ''
  }

  return `${operator ? `${operator} ` : ''}${prefix}${grade.name} `
}
