import { IconName } from '@revolut/ui-kit'
import {
  navigateTo,
  navigateReplace,
  getLocationDescriptor,
} from '@src/actions/RouterActions'
import { getCompany } from '@src/api/company'
import { departmentRequestsNew } from '@src/api/department'
import { employeesRequestsNew } from '@src/api/employees'
import { teamsRequestsNew } from '@src/api/teams'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'
import { CompanyInterface } from '@src/interfaces/company'
import { FilterByInterface } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FunctionInterface } from '@src/interfaces/functions'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { TeamInterface } from '@src/interfaces/teams'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { pathToUrl } from '@src/utils/router'
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'

const orgEntityFilterGetter =
  (entity: OrgEntityInterface | null) => (): FilterByInterface[] => {
    switch (entity?.type) {
      case EntityTypes.department:
        return [
          {
            columnName: 'department__id',
            filters: [{ id: entity.data.id, name: entity.data.name }],
          },
        ]
      case EntityTypes.team:
      case EntityTypes.teams:
        return [
          {
            columnName: 'team__id',
            filters: [{ id: entity.data.id, name: entity.data.name }],
          },
        ]
      case EntityTypes.employee:
      case EntityTypes.employees:
        return [
          {
            columnName: 'employee__id',
            filters: [{ id: entity.data.id, name: entity.data.full_name }],
          },
        ]
      case EntityTypes.companyV2:
      case EntityTypes.company:
        return [
          {
            columnName: 'company__id',
            filters: [{ id: entity.data.id, name: entity.data.name }],
          },
        ]
      default:
        return []
    }
  }

const orgEntityPropsGetter = (entity: OrgEntityInterface | null) => {
  const pickIdAndName = (data: {
    id: number
    name?: string
    display_name?: string
  }): IdAndName => ({
    id: data.id,
    name: data.name || data.display_name || String(data.id),
  })

  return () => {
    if (!entity) {
      return {}
    }
    switch (entity.type) {
      case EntityTypes.company:
      case EntityTypes.companyV2:
        return {
          is_company: true,
          company: pickIdAndName(entity.data),
        }
      case EntityTypes.department:
        return {
          department: pickIdAndName(entity.data),
        }
      case EntityTypes.employees:
        return {
          is_employee: true,
          employee: pickIdAndName(entity.data),
        }
      case EntityTypes.function:
        return {
          function: pickIdAndName(entity.data),
        }
      case EntityTypes.role:
        return {
          role: pickIdAndName(entity.data),
        }
      case EntityTypes.teams:
        return {
          team: pickIdAndName(entity.data),
        }
      case EntityTypes.specialisation:
        return {
          specialisation: pickIdAndName(entity.data),
        }
      default:
        return {}
    }
  }
}

interface OrgEntityContextInterface {
  entity: OrgEntityInterface | null
  getEntityProps: ReturnType<typeof orgEntityPropsGetter>
  getEntityFilters: ReturnType<typeof orgEntityFilterGetter>
  navigateWithEntity: typeof navigateTo
  navigateReplaceWithEntity: typeof navigateReplace
  getHomeRoute: ReturnType<typeof getHomeRoute>
  getEntityIcon: () => IconName
}

const fetchEntity = async (
  type?: EntityTypes,
  id?: string,
  isCompany?: boolean,
): Promise<OrgEntityInterface | null> => {
  if (!type && !isCompany) {
    return Promise.resolve(null)
  }

  if (isCompany) {
    const { data } = await getCompany()
    return {
      type: EntityTypes.company,
      data,
    }
  }

  switch (type) {
    case EntityTypes.employee:
    case EntityTypes.employees: {
      const { data } = await employeesRequestsNew.get({ id })
      return {
        type: EntityTypes.employees,
        data,
      }
    }
    case EntityTypes.team:
    case EntityTypes.teams: {
      const { data } = await teamsRequestsNew.get({ id })
      return {
        type: EntityTypes.teams,
        data,
      }
    }
    case EntityTypes.department: {
      const { data } = await departmentRequestsNew.get({ id })
      return {
        type: EntityTypes.department,
        data,
      }
    }
    default:
      return Promise.resolve(null)
  }
}

export type OrgEntityInterface =
  | { type: EntityTypes.department; data: DepartmentInterface }
  | { type: EntityTypes.teams | EntityTypes.team; data: TeamInterface }
  | {
      type: EntityTypes.employees | EntityTypes.employee
      data: EmployeeInterface & { name?: string }
    }
  | { type: EntityTypes.role; data: RoleInterface }
  | { type: EntityTypes.company; data: CompanyInterface }
  | { type: EntityTypes.companyV2; data: CompanyInterface }
  | { type: EntityTypes.function; data: FunctionInterface }
  | { type: EntityTypes.specialisation; data: SpecialisationInterface }

interface OrgEntityContextProviderProps {
  entity?: OrgEntityInterface
  entityType?: EntityTypes
  entityId?: string
  isCompany?: boolean
}

export const getEntityIcon = (entityType?: EntityTypes) => {
  switch (entityType) {
    case EntityTypes.employee:
    case EntityTypes.employees:
      return 'Profile'
    case EntityTypes.team:
    case EntityTypes.teams:
      return 'People'
    case EntityTypes.department:
      return 'Bank'
    // no specific requirements for the below
    case EntityTypes.function:
    case EntityTypes.specialisation:
    case EntityTypes.role:
      return 'RepairTool'
    case EntityTypes.company:
    case EntityTypes.companyV2:
      return 'Concierge'
    default:
      return 'Services'
  }
}

export const getEntityLink = (
  entityType: EntityTypes | undefined,
  id: string | number | undefined,
) => {
  switch (entityType) {
    case EntityTypes.company:
    case EntityTypes.companyV2:
      return getLocationDescriptor(pathToUrl(ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL))
    case EntityTypes.team:
    case EntityTypes.teams:
      return getLocationDescriptor(pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id }))
    case EntityTypes.department:
      return getLocationDescriptor(pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id }))
    case EntityTypes.employee:
    case EntityTypes.employees:
      return getLocationDescriptor(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }))
    default:
      return undefined
  }
}

const getHomeRoute = (entity?: OrgEntityInterface | null) => () => {
  if (isOnboardingPath()) {
    return ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL
  }

  switch (entity?.type) {
    case EntityTypes.employee:
    case EntityTypes.employees:
      return ROUTES.FORMS.EMPLOYEE.PROFILE

    case EntityTypes.team:
    case EntityTypes.teams:
      return ROUTES.FORMS.TEAM.GOALS.GENERAL

    case EntityTypes.department:
      return ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL

    case EntityTypes.specialisation:
      return ROUTES.FORMS.SPECIALISATIONS.PREVIEW

    case EntityTypes.role:
      return ROUTES.FORMS.ROLE.PREVIEW

    case EntityTypes.function:
      return ROUTES.FORMS.FUNCTION.SUMMARY

    case EntityTypes.company:
      return ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL

    case EntityTypes.companyV2:
      return ROUTES.PERFORMANCE.GOALS.GENERAL

    default:
      return null
  }
}

const OrgEntityContext = createContext<OrgEntityContextInterface>({
  entity: null,
  getEntityProps: orgEntityPropsGetter(null),
  getEntityFilters: orgEntityFilterGetter(null),
  navigateWithEntity: navigateTo,
  navigateReplaceWithEntity: navigateReplace,
  getHomeRoute: getHomeRoute(),
  getEntityIcon: () => 'Services',
})

export const useOrgEntity = () => {
  const context = useContext(OrgEntityContext)

  return context
}

export type WithEntity<T extends {}> = T & { entity: OrgEntityInterface }

export const OrgEntityProvider = ({
  children,
  entity,
  entityType,
  entityId,
  isCompany,
}: PropsWithChildren<OrgEntityContextProviderProps>) => {
  const location = useLocation<{
    entity?: OrgEntityInterface
    state?: { entity?: OrgEntityInterface }
  }>()
  const [fetchedEntity, setFetchedEntity] = useState<OrgEntityInterface>()

  const getProvidedEntity = () =>
    entity || location.state?.entity || location.state?.state?.entity

  useEffect(() => {
    let mounted = true

    if (getProvidedEntity()) {
      return () => {}
    }

    if ((entityId && entityType) || isCompany) {
      fetchEntity(entityType, entityId, isCompany).then(
        fetched => fetched && mounted && setFetchedEntity(fetched),
      )
    }

    return () => {
      mounted = false
    }
  }, [entityId, entityType, entity, location, isCompany])

  const value = useMemo<OrgEntityContextInterface>(() => {
    // state.state is required to support 2 steps forms
    const currentEntity = getProvidedEntity() || fetchedEntity || null

    const tryToNavigate =
      (action: typeof navigateTo | typeof navigateReplace) =>
      (url: string, state: unknown = {}, withSearch?: boolean) => {
        try {
          return action(
            url,
            {
              ...(state as {}),
              entity: currentEntity,
            },
            withSearch,
          )
        } catch (err) {
          console.error(
            'Failed to navigate with entity because of the following error',
            err,
          )
          // Location state is unknown and can be any primitive so spreading state may fail
          // in that case we can't enhance provided state with entity property, so navigating as is
          return action(url, state, withSearch)
        }
      }

    return {
      entity: currentEntity,
      getEntityProps: orgEntityPropsGetter(currentEntity),
      getEntityFilters: orgEntityFilterGetter(currentEntity),
      navigateWithEntity: tryToNavigate(navigateTo),
      navigateReplaceWithEntity: tryToNavigate(navigateReplace),
      getHomeRoute: getHomeRoute(currentEntity),
      getEntityIcon: () => getEntityIcon(currentEntity?.type),
    }
  }, [entity, location.state, fetchedEntity])

  return <OrgEntityContext.Provider value={value}>{children}</OrgEntityContext.Provider>
}
