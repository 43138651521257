import React from 'react'
import { connect } from 'lape'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { organisationSettings } from '@src/api/settings'
import { OrganisationGeneralSettingsForm } from '../Organisation/General/General'
import { ROUTES } from '@src/constants/routes'

const StructuresTeamGeneralSettingsForm = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="General settings"
        subtitle="Settings that apply to teams, departments and company."
        backUrl={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL}
      />

      <OrganisationGeneralSettingsForm />
    </PageWrapper>
  )
}

export const StructuresTeamGeneralSettings = connect(() => {
  return (
    <Form api={organisationSettings} forceParams={{ id: '1' }}>
      <StructuresTeamGeneralSettingsForm />
    </Form>
  )
})
