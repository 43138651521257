import React from 'react'
import { UseQueryResult } from 'react-query'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EntityTypes } from '@src/constants/api'

interface Props {
  id: string | number
  useGetEntity: (id: number | string) => UseQueryResult<{ name: string }>
  entityType: EntityTypes
}

const Header = ({ useGetEntity, entityType, id }: Props) => {
  const { data } = useGetEntity(id)
  return (
    <PageHeader title={`Approve ${entityType} kpis`} backUrl="/" subtitle={data?.name} />
  )
}

export default Header
