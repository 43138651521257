import React from 'react'

import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend as RechartsLegend,
  ReferenceLine,
} from 'recharts'
import { Token, Relative } from '@revolut/ui-kit'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ChartEmptyResults } from '@src/pages/Forms/QueryForm/components/Charts/ChartEmptyResults'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import { ChartTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/ChartTooltip'
import { formatPercentage } from '@src/utils/format'
import {
  GradeDistributionDataKey,
  GradeDistributionItemData,
} from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/ChartData'
import { XAxisTick } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/XAxisTick'
import { Legend } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/Legend'
import { Tooltip } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/Tooltip'
import { ReferenceLineLabel } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/ReferenceLineLabel'
import { renderStackedRectBar } from '@src/features/ReviewCycle/Analytics/Charts/components/StackedRectBar'

const CHART_HEIGHT = 300
const CHART_MARGIN = { top: 16, right: 40, bottom: 5, left: 50 }
const DEFAULT_TICK_COUNT = 5
const X_AXIS_TICK_MARGIN = 8
const Y_AXIS_TICK_MARGIN = 65
const X_AXIS_REFERENCE_ID = 'reference_axis'

interface Props {
  dataKeys: GradeDistributionDataKey[]
  data: GradeDistributionItemData[]
  isLoading: boolean
  height?: number
}

export const GradesDistributionBarChart = ({
  height = CHART_HEIGHT,
  data,
  dataKeys,
  isLoading,
}: Props) => {
  const yAxisMax = Math.max(
    ...data.reduce<number[]>(
      (yAxisValues, { targetDistribution, gradeCount, totalGradesCount }) => [
        ...yAxisValues,
        targetDistribution,
        Math.ceil((gradeCount / totalGradesCount) * 100),
      ],
      [],
    ),
  )

  if (isLoading) {
    return <ChartSkeleton height={height} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={height} />
  }

  return (
    <Relative width="100%" height="100%">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data} layout="horizontal" margin={CHART_MARGIN}>
          <XAxis
            type="number"
            domain={[0, 100]}
            xAxisId={X_AXIS_REFERENCE_ID}
            axisLine={false}
            tickLine={false}
            scale="linear"
            hide
          />
          <XAxis
            dataKey="label"
            tick={props => <XAxisTick {...props} data={data} />}
            tickMargin={X_AXIS_TICK_MARGIN}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="number"
            domain={[0, yAxisMax]}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickCount={DEFAULT_TICK_COUNT}
            tick={{ color: Token.color.greyTone50, fontSize: 12 }}
            orientation="right"
            tickFormatter={value => formatPercentage(value / 100)}
            tickMargin={Y_AXIS_TICK_MARGIN}
          />
          {dataKeys.map(renderStackedRectBar)}
          {data.map((datum, index) => (
            <ReferenceLine
              label={props => (
                <ReferenceLineLabel {...props} value={datum.targetDistribution} />
              )}
              key={datum.label}
              segment={[
                { x: index * 20, y: datum.targetDistribution },
                { x: (index + 1) * 20, y: datum.targetDistribution },
              ]}
              stroke={Token.color.greyTone50}
              strokeDasharray="3 3"
              strokeOpacity={0.6}
              xAxisId={X_AXIS_REFERENCE_ID}
            />
          ))}
          <RechartsTooltip
            cursor={false}
            content={<ChartTooltip tooltip={Tooltip} dataKeys={dataKeys} />}
          />
          <RechartsLegend
            content={props => <Legend props={props} dataKeys={dataKeys} />}
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: '32px',
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Relative>
  )
}
