import React from 'react'

import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { Button } from './Button'

interface Props {
  url: string
}

export const TakeAProductTourButton = ({ url }: Props) => {
  const { sendAnalyticsEvent } = useAnalytics()

  return (
    <Button
      // @ts-ignore TODO: REVPI-28 Migrate LandingPage to standard UI Kit
      variant="secondary"
      use="a"
      href={url}
      display={{ all: 'none', md: 'flex' }}
      style={{ backgroundImage: 'unset' }}
      target="_blank"
      onClick={() => {
        sendAnalyticsEvent(AnalyticsEvents.click_take_product_tour)
      }}
    >
      Take a product tour
    </Button>
  )
}
