import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'

import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { CreateRoadmapLocationState } from './common/AddRoadmapMoreBarAction'
import { JiraRoadmapPriorities } from '@src/interfaces/roadmaps'
import { navigateReplace } from '@src/actions/RouterActions'
import { useLocation } from 'react-router-dom'
import { useGetReviewCycle } from '@src/api/reviewCycles'
import { NewSectionCardSkeleton } from '@src/components/NewSelectionCard/NewSectionCardSkeleton'
import { capitalize } from 'lodash'

const Title = () => (
  <FormattedMessage id="roadmapCreate.page.title" defaultMessage="Add new roadmap" />
)

const Description = () => (
  <FormattedMessage
    id="roadmapCreate.page.description"
    defaultMessage="Select the roadmap level"
  />
)

const getEntityType = (entity: EntityTypes) =>
  entity === EntityTypes.companyV2
    ? 'company'
    : entity === EntityTypes.department
    ? 'department'
    : 'team'

export const CreateRoadmapLevelSelect = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const location = useLocation<{ roadmapSelectCycleID: string; isOnboarding: boolean }>()
  const { data: reviewCycle, isLoading } = useGetReviewCycle(
    location.state.roadmapSelectCycleID,
  )
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const backUrl = location.state?.isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.ROADMAPS
    : ROUTES.PERFORMANCE.GOALS.ROADMAPS

  const user = useSelector(selectUser)

  const getState = (entity: EntityTypes) => {
    const type: CreateRoadmapLocationState['genericRoadmapCreationType'] =
      getEntityType(entity)
    return {
      backUrl,
      name: capitalize(`${type} roadmap`),
      genericRoadmapCreationType: type,
      initialValues: {
        // we need to set null values to clean up previous state
        // these null values are removed from the API payload before sending
        team: null,
        employee: null,
        department: null,
        name: null,
        review_cycle: reviewCycle,
        start_date: reviewCycle?.start_date_time,
        due_date: reviewCycle?.end_date_time,
        is_company: entity === EntityTypes.companyV2,
        owner: { id: user.id, name: user.display_name },
        priority: { id: JiraRoadmapPriorities.MAJOR, name: JiraRoadmapPriorities.MAJOR },
      },
    }
  }

  const handleNew = async (entity: EntityTypes) => {
    const state = getState(entity)
    navigateReplace(ROUTES.FORMS.ROADMAP.CREATE_NEW_FORM, state)
  }

  return (
    <PageWrapper>
      <PageHeader
        title={<Title />}
        subtitle={<Description />}
        backUrl={pathToUrl(backUrl)}
      />

      <PageBody>
        <Group>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {permissions.includes(PermissionTypes.CanAddCompanyRoadmap) && (
                <NewSelectionCard
                  icon={isCommercial ? 'CompanyFilled' : 'LogoRevolut'}
                  title="Company roadmap"
                  data-testid="company-goal-select"
                  onClick={() => handleNew(EntityTypes.companyV2)}
                />
              )}
              {permissions.includes(PermissionTypes.CanAddDepartmentRoadmap) && (
                <NewSelectionCard
                  icon="Bank"
                  title="Department roadmap"
                  data-testid="department-goal-select"
                  onClick={() => handleNew(EntityTypes.department)}
                />
              )}
              {permissions.includes(PermissionTypes.CanAddDepartmentRoadmap) && (
                <NewSelectionCard
                  icon="People"
                  title="Team roadmap"
                  data-testid="team-goal-select"
                  onClick={() => handleNew(EntityTypes.teams)}
                />
              )}
            </>
          )}
        </Group>
      </PageBody>
    </PageWrapper>
  )
}

const Skeleton = () => {
  return (
    <>
      <NewSectionCardSkeleton />
      <NewSectionCardSkeleton />
      <NewSectionCardSkeleton />
    </>
  )
}
