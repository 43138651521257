import React from 'react'
import SectionLayout from '@src/pages/Careers/CareersApplication/components/Section'
import { Box } from '@revolut/ui-kit'
import { useAiInterviewSummarisationCandidateEnabled } from '@src/pages/Careers/hooks/useAiInterviewSummarisationCandidateEnabled'
import { InterviewTranscriptConsent } from '@src/features/InterviewTranscriptConsent/InterviewTranscriptConsent'

interface Props {
  checked: boolean
  onCheck: (checked: boolean) => void
}

export const CareersInterviewTranscriptConsent = ({ checked, onCheck }: Props) => {
  const enabled = useAiInterviewSummarisationCandidateEnabled()

  if (!enabled) {
    return null
  }

  return (
    <Box mt="s-32">
      <SectionLayout
        title="Interview transcripts"
        body={<InterviewTranscriptConsent checked={checked} onCheck={onCheck} />}
      />
    </Box>
  )
}
