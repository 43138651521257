import {
  EngagementResultsAverageScoreInterface,
  EngagementResultsOpenEndedDistribution,
  EngagementResultsScoresInterface,
} from '@src/interfaces/engagement'

export const getTotalAnswers = ({
  scores,
  unanswered = 0,
}: {
  scores: EngagementResultsScoresInterface
  unanswered: number
}) => Object.values(scores || {}).reduce((acc, val) => acc + val, 0) + unanswered

export const getTotalAnswersFromAverage = (
  averageScore: EngagementResultsAverageScoreInterface,
) => Object.values(averageScore || {}).reduce((acc, val) => acc + val || 0, 0)

export const getTotalAnswersFromOpenEnded = (
  openEndedDistribution: EngagementResultsOpenEndedDistribution,
) => Object.values(openEndedDistribution || {}).reduce((acc, val) => acc + val, 0)
