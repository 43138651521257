import React, { useState } from 'react'
import {
  ActionButton,
  Box,
  Subheader,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const Description = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  const isCommercial = useIsCommercial()
  return (
    <>
      {openJobPostings && (
        <JobPostingPopup
          confirmLabel="Reuse posting"
          specialisationId={values?.specialisation?.id!}
          onClose={() => {
            setOpenJobPostings(false)
          }}
          onConfirm={jobPosting => {
            values.sections = jobPosting.sections
          }}
        />
      )}
      <Box>
        <Subheader>
          <Subheader.Title>Job Description</Subheader.Title>
          <Subheader.Side>
            <ActionButton
              useIcon="Search"
              onClick={() => {
                setOpenJobPostings(true)
              }}
            >
              Copy from existing job
            </ActionButton>
          </Subheader.Side>
        </Subheader>
        <VStack gap="s-16">
          {values.sections?.map((section, idx) => (
            <Widget key={section.title} p="s-16">
              <VStack gap="s-12">
                <Text variant="emphasis1">{section.title}</Text>
                <LapeHTMLEditor
                  name={`sections.${idx}.content`}
                  placeholder={section.title}
                  height={350}
                  required
                  addMarginToParagraphs
                />
              </VStack>
            </Widget>
          ))}
          <Widget p="s-16">
            <VStack gap="s-12">
              <Text variant="emphasis1">Video</Text>
              <Text color={Token.color.greyTone50} variant="body2">
                Add a video to the job posting to tell candidates more about your company
                and the role they’re applying to. The video will be shown below the main
                job posting description
              </Text>
              <LapeNewInput
                name="presentation_video_url"
                label={
                  isCommercial
                    ? 'YouTube video URL'
                    : 'YouTube or assets.revolut.com video URL'
                }
                message={
                  isCommercial
                    ? 'Only YouTube videos will be displayed'
                    : 'Only videos from YouTube or assets.revolut.com will be displayed'
                }
                onAfterChange={val => {
                  if (!val) {
                    delete values.presentation_video_url
                  }
                }}
              />
            </VStack>
          </Widget>
        </VStack>
      </Box>
    </>
  )
}
