import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'
import {
  Avatar,
  Box,
  Group,
  IconName,
  Item,
  HStack,
  MoreBar,
  Subheader,
  Text,
  Token,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  useEmployeeTimeOffRequests,
  getTimeOffBalanceTransactions,
  useTimeOffBalanceCycles,
  useTimeOffBalanceCycleStats,
  useTimeOffBalanceDetails,
} from '@src/api/timeOff'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import {
  timeOffBalanceTransactionAmountColumn,
  timeOffBalanceTransactionCreateDateColumn,
  timeOffBalanceTransactionCreatedByColumn,
  timeOffBalanceTransactionDescriptionColumn,
} from '@src/constants/columns/timeOff'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import Loader from '@src/components/CommonSC/Loader'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@src/components/TableV2/hooks'
import { AdjustBalanceSidebar } from '@src/components/TimeOff/AdjustBalanceSidebar'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import {
  approvalStatusIdToIconColor,
  policyCategoryToIcon,
} from '@src/features/TimeOffCalendarTable/helpers'
import { PermissionTypes } from '@src/store/auth/types'
import { formatPeriod } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { BalanceCycleSelector, CycleOption } from './CycleSelector'
import { CycleStatsChart } from './CycleStatsChart/CycleStatsChart'

const row = {
  cells: [
    {
      ...timeOffBalanceTransactionCreateDateColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionCreatedByColumn,
      width: 150,
    },
    {
      ...timeOffBalanceTransactionDescriptionColumn,
      width: 200,
    },
    {
      ...timeOffBalanceTransactionAmountColumn,
      width: 100,
    },
  ],
}

export const approvalStatusIdToIcon = (status: ApprovalStatuses): IconName => {
  switch (status) {
    case ApprovalStatuses.Pending:
      return 'Bell'
    case ApprovalStatuses.Canceled:
    case ApprovalStatuses.Rejected:
      return 'Cross'
    default:
      return 'Check'
  }
}

export const TimeOffBalances = () => {
  const { id } = useParams<{ id: string }>()
  const containerRef = useRef<HTMLDivElement>(null)
  const { width: containerWidth } = useResizeObserver({ ref: containerRef })

  const [adjustBalanceSidebarOpen, setAdjustBalanceSidebarOpen] = useState(false)
  const [selectedCycle, setSelectedCycle] = useState<CycleOption>()

  const { isLoading, data, refetch } = useTimeOffBalanceDetails(id)
  const { data: cyclesOptions, isLoading: isLoadingCyclesOptions } =
    useTimeOffBalanceCycles(id)
  const {
    data: cycleStats,
    isLoading: isLoadingCycleStats,
    refetch: refetchCycleStats,
  } = useTimeOffBalanceCycleStats(id, selectedCycle?.id)
  const table = useTable(
    { getItems: getTimeOffBalanceTransactions(id) },
    [
      {
        filters: [{ name: 'balance', id: 'balance' }],
        columnName: 'field__id',
        nonResettable: true,
      },
    ],
    undefined,
    { disableQuery: true },
  )
  const timeOffRequests = useEmployeeTimeOffRequests(
    true,
    {
      filters: [
        {
          filters: [{ id: 'upcoming', name: 'Upcoming' }],
          columnName: 'status',
        },
        {
          filters: selectedCycle
            ? [{ id: selectedCycle.id, name: selectedCycle.id.toString() }]
            : [],
          columnName: 'bookings__cycle_id',
        },
        {
          filters: data ? [{ id, name: id.toString() }] : [],
          columnName: 'balance_id',
        },
      ],
    },
    data?.employee.id,
  )

  const options = useMemo(() => {
    return (
      cyclesOptions?.results.map(option => ({
        ...option,
        startToEnd: formatPeriod(new Date(option.start), new Date(option.end)),
      })) || []
    )
  }, [cyclesOptions?.results])

  useEffect(() => {
    if (selectedCycle) {
      return
    }
    const currentCycle = options.find(cycle => cycle.status?.id === 'current')

    if (currentCycle) {
      setSelectedCycle(currentCycle)
      table.onFilterChange({
        filters: [{ id: currentCycle.id, name: currentCycle.id.toString() }],
        columnName: 'cycle_id',
        nonResettable: true,
      })
    }
  }, [options, selectedCycle])

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (!data) {
    return null
  }

  const canRequestTimeOff = data.field_options?.permissions?.includes(
    PermissionTypes.RequestTimeOff,
  )
  const canAdjustBalance = data.field_options?.permissions?.includes(
    PermissionTypes.AddAdjustments,
  )

  const isMobile = containerWidth
    ? containerWidth <= parseInt(Token.breakpoint.sm, 10)
    : true

  return (
    <>
      <PageWrapper>
        <PageHeader
          backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.BALANCES, {
            id: data?.employee.id,
          })}
          title="Balance details"
          subtitle={
            <HStack>
              {chain(<UserWithAvatar {...data.employee} />, data.policy.category.name)}
            </HStack>
          }
        />
        <PageBody>
          <VStack space="s-24" ref={containerRef}>
            <MoreBar>
              {canRequestTimeOff ? (
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                        employeeId: data.employee?.id,
                      }),
                      { balanceId: data.id },
                    )
                  }
                  useIcon="Plus"
                >
                  Request time off
                </MoreBar.Action>
              ) : null}
              <BalanceCycleSelector
                isLoading={isLoadingCyclesOptions}
                onChange={cycle => {
                  if (cycle) {
                    setSelectedCycle(cycle)
                    table.onFilterChange({
                      filters: [{ id: cycle.id, name: cycle.id.toString() }],
                      columnName: 'cycle_id',
                      nonResettable: true,
                    })
                  }
                }}
                options={options}
                value={selectedCycle}
              />
              {canAdjustBalance ? (
                <MoreBar.Action
                  onClick={() => setAdjustBalanceSidebarOpen(true)}
                  useIcon="Pencil"
                >
                  Adjust balance
                </MoreBar.Action>
              ) : null}
              <MoreBar.Action
                to={pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, {
                  id: data.policy.id,
                })}
                use={InternalLink}
                useIcon="Form"
              >
                View policy
              </MoreBar.Action>
            </MoreBar>
            <CycleStatsChart
              data={cycleStats}
              isLoading={isLoadingCycleStats}
              isUnlimited={data.balance_type.id === 'unlimited'}
              isMobile={isMobile}
              unit={data.unit.id}
            />
            {!!timeOffRequests?.length && (
              <Box>
                <Subheader variant="nested">
                  <Subheader.Title>Upcoming time off</Subheader.Title>
                </Subheader>
                <Group>
                  <VStack>
                    {timeOffRequests.map(request => {
                      const { from_date_time, to_date_time, policy } = request
                      const period =
                        from_date_time && to_date_time
                          ? formatPeriod(
                              new Date(from_date_time),
                              new Date(to_date_time),
                              { showOneDateIfStartAndEndDatesAreSame: true },
                            )
                          : undefined

                      return (
                        <Item
                          key={request.id}
                          to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                            id: request.id,
                            employeeId: data?.employee.id.toString(),
                          })}
                          use={InternalLink}
                        >
                          <Item.Avatar>
                            <Avatar useIcon={policyCategoryToIcon(policy.category.id)}>
                              <Avatar.Badge
                                bg={approvalStatusIdToIconColor(
                                  request.approval_status.id,
                                )}
                                useIcon={approvalStatusIdToIcon(
                                  request.approval_status.id,
                                )}
                              />
                            </Avatar>
                          </Item.Avatar>
                          <Item.Content>
                            <Item.Title>{policy.category.name}</Item.Title>
                            <Item.Description>
                              <Text
                                color={approvalStatusIdToIconColor(
                                  request.approval_status.id,
                                )}
                              >
                                {request.approval_status.name}
                              </Text>
                            </Item.Description>
                          </Item.Content>
                          <Item.Side>{period}</Item.Side>
                        </Item>
                      )
                    })}
                  </VStack>
                </Group>
              </Box>
            )}
            <Box>
              <Subheader variant="nested">
                <Subheader.Title>History</Subheader.Title>
              </Subheader>
              <Table.Widget>
                <Table.Widget.Table>
                  <AdjustableTable
                    name={TableNames.TimeOffBalances}
                    row={row}
                    useWindowScroll
                    {...table}
                  />
                </Table.Widget.Table>
              </Table.Widget>
            </Box>
          </VStack>
        </PageBody>
      </PageWrapper>

      <AdjustBalanceSidebar
        balance={data}
        employeeId={data.employee.id}
        isOpen={adjustBalanceSidebarOpen}
        onAfterSubmit={() => {
          setAdjustBalanceSidebarOpen(false)
          table.refresh()
          refetch()
          refetchCycleStats()
        }}
        onClose={() => setAdjustBalanceSidebarOpen(false)}
      />
    </>
  )
}
