import React from 'react'
import { Image, ImageProps } from '@revolut/ui-kit'

import type { FeatureBlockAlign } from './types'

interface FeatureBlockImageProps extends ImageProps {
  image: string
  align: FeatureBlockAlign
}

export const FeatureBlockImage = ({ image, align, ...props }: FeatureBlockImageProps) => {
  return (
    <Image alt="" src={image} sizes="100vw" style={{ objectFit: align }} {...props} />
  )
}
