import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import TextAreaField from '@components/Inputs/TextAreaField/TextAreaField'
import BottomText from '@components/Inputs/partials/BottomText'
import { Text, Flex, Box, Token, TextButton, opacity, chain } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { ListType } from '@src/utils'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { formatDate } from '@src/utils/format'
import { Queries } from '@src/constants/api'
import { AvatarType } from '@src/interfaces/employees'
import useShowMoreDescription from '@components/AnytimeFeedback/useShowMoreDescription'

const Container = styled(Box)<{
  isSidebar?: boolean
  noMargin?: boolean
  disabled?: boolean
}>`
  background-color: ${props =>
    props.disabled ? Token.color.widgetBackground : Token.color.searchBackground};
  border-radius: 12px;
  padding: 16px 16px 12px;
  box-sizing: border-box;
  margin: ${props => (props.noMargin ? 0 : '16px 0 0')};
  border: ${props =>
    props.isSidebar ? `1px solid ${opacity(Token.colorChannel.deepGrey, 0.2)}` : 'none'};
`

const TextArea = styled(TextAreaField)`
  border: 0;
  margin-left: -8px;
  background-color: transparent;
  :disabled {
    background-color: transparent;
  }
`

interface OverallFeedbackProps {
  pros?: string[]
  cons?: string[]
  onChangePros?: (val: string) => void
  onChangeCons?: (val: string) => void
  disabled?: boolean
  title?: string | ReactNode
  subtitle?: string | ReactNode
  onGetProsElement?: (elem: HTMLTextAreaElement | null) => void
  onGetConsElement?: (elem: HTMLTextAreaElement | null) => void
  avatar?: AvatarType
  name?: string
  isSidebar?: boolean
  reviewerId?: number
  updatedDate?: string
  reviewUrl?: string
  noMargin?: boolean
}

const OverallFeedback = ({
  pros,
  cons,
  onChangePros,
  onChangeCons,
  disabled,
  title,
  subtitle,
  onGetProsElement,
  onGetConsElement,
  avatar,
  isSidebar,
  name,
  reviewerId,
  updatedDate,
  reviewUrl,
  noMargin = false,
}: OverallFeedbackProps) => {
  const { submitFailed } = useLapeContext()
  const [touched, setTouched] = useState(false)

  const prosValue = pros?.join('\n')
  const consValue = cons?.join('\n')

  const prosDescription = useShowMoreDescription(prosValue, 'overallFeedback-strengths')
  const consDescription = useShowMoreDescription(consValue, 'overallFeedback-areas')

  return (
    <Container
      isSidebar={isSidebar}
      data-testid={`overall-feedback-${name}`}
      noMargin={noMargin}
      disabled={disabled}
    >
      <Flex gap="s-8" alignItems="center" mb="s-12">
        {name && (
          <UserWithAvatar
            size={40}
            avatar={avatar}
            name={name}
            id={reviewerId}
            compact
            width={40}
          />
        )}
        {title && (
          <Text>
            {chain(
              title,
              subtitle,
              updatedDate ? (
                <Text color={Token.color.greyTone50} fontSize="caption">
                  {formatDate(updatedDate)}
                </Text>
              ) : null,
            )}
          </Text>
        )}
      </Flex>

      <Box mb="s-8">
        <Text use="div" fontWeight={500} color={Token.color.greyTone50}>
          Strengths:
        </Text>
        {disabled ? (
          prosDescription
        ) : (
          <TextArea
            value={prosValue}
            onChange={value => {
              onChangePros && onChangePros(value)
              setTouched(true)
            }}
            listType={ListType.dots}
            minHeight={12}
            defaultHeight={12}
            onBlur={() => {
              onChangePros && pros && onChangePros(pros.filter(item => item).join('\n'))
            }}
            onGetElement={onGetProsElement}
            data-testid="overallFeedback-strengths"
          />
        )}
      </Box>

      <Box mb="s-8">
        <Text use="div" fontWeight={500} color={Token.color.greyTone50}>
          Areas of development:
        </Text>
        {disabled ? (
          consDescription
        ) : (
          <TextArea
            value={consValue}
            onChange={value => {
              onChangeCons && onChangeCons(value)
              setTouched(true)
            }}
            listType={ListType.dots}
            minHeight={12}
            defaultHeight={12}
            onBlur={() => {
              onChangeCons && cons && onChangeCons(cons.filter(item => item).join('\n'))
            }}
            onGetElement={onGetConsElement}
            data-testid="overallFeedback-areas"
          />
        )}
      </Box>

      <BottomText
        error={
          !disabled &&
          (touched || submitFailed) &&
          !prosValue?.trim() &&
          !consValue?.trim()
            ? 'Overall feedback is obligatory. You cannot submit review without it.'
            : undefined
        }
      />

      {reviewUrl && (
        <TextButton
          use="a"
          href={`${reviewUrl}?${Queries.ReviewerId}=${reviewerId}`}
          target="_blank"
          pl={0}
        >
          Open review
        </TextButton>
      )}
    </Container>
  )
}

export default connect(OverallFeedback)
