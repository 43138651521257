import React from 'react'
import { useGlobalSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { getSettingsLinkConfig } from '@src/pages/Settings/common/config'
import { jobsSettingsLinkConfig } from '@src/pages/Settings/Jobs/config'
import { Group } from '@revolut/ui-kit'

export const HiringProcessList = () => {
  const { settings } = useGlobalSettings()

  const permissions = useSelector(selectPermissions)

  const config = getSettingsLinkConfig({
    config: jobsSettingsLinkConfig,
    permissions,
    globalSettings: settings,
    type: 'settings',
  })

  return <Group mb="s-24">{config.jobsHiringProcessSettings?.component}</Group>
}
