import React, { useEffect, useState, useMemo } from 'react'
import { Action, FilterButton } from '@revolut/ui-kit'
import {
  updateDashboardsOrder,
  getAnalyticsDashboards,
  getAnalyticsDashboardsOrdered,
} from '@src/api/analyticsDashboards'
import { navigateTo } from '@src/actions/RouterActions'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import {
  analyticsDashboardDetailsColumn,
  analyticsDashboardRelatedKpisColumn,
} from '@src/constants/columns/analyticsDashboard'
import { TableNames } from '@src/constants/table'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { FilterByInterface, RowInterface, SortByInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useOrdering } from '@components/TableV2/hooks'
import { pathToUrl } from '@src/utils/router'
import { DetailsSideBar } from './DetailsSideBar'
import OrderingTableControls from '@src/features/OrderingTableControls/OrderingTableControls'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { IdAndName, FieldOptions } from '@src/interfaces'
import { PermissionTypes, FeatureFlags } from '@src/store/auth/types'
import Stat from '@components/Stat/Stat'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { dashboardTypeRouteMap } from '@src/pages/Forms/DataAnalyticsDashboardForm/constants'
import { useQuery } from '@src/utils/queryParamsHooks'

interface AnalyticsDashboardsTabProps {
  data?: IdAndName & { field_options: FieldOptions }
  entity: EntityTypes
  initialFilter?: FilterByInterface[]
  initialSort?: SortByInterface[]
  row: RowInterface<GenericAnalyticsDashboardInterface>
  navigation?: React.ReactNode
}

export const AnalyticsDashboardsTab = ({
  data,
  entity,
  initialFilter,
  initialSort,
  row,
  navigation,
}: AnalyticsDashboardsTabProps) => {
  const NO_SETTINGS_PAGES = [
    EntityTypes.role,
    EntityTypes.roles,
    EntityTypes.function,
    EntityTypes.functions,
    EntityTypes.specialisation,
    EntityTypes.specialisations,
  ]

  const featureFlags = useSelector(selectFeatureFlags)
  const [sideBarData, setSideBarData] = useState<GenericAnalyticsDashboardInterface>()
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [showAll, setShowAll] = useState<boolean>()
  const [orderingMode, setOrderingMode] = useState(false)

  const table = useTable<GenericAnalyticsDashboardInterface>(
    {
      getItems:
        entity === EntityTypes.company
          ? getAnalyticsDashboards(entity, data?.id)
          : getAnalyticsDashboardsOrdered(),
    },
    initialFilter,
    initialSort,
  )
  const { query, changeQueryParam } = useQuery()

  useEffect(() => {
    if (!Object.hasOwn(query, 'company_related') && entity === EntityTypes.company) {
      changeQueryParam('company_related', 'true')
    } else {
      setShowAll(true)
    }
  }, [])

  const onAfterChange = async (requestData: UpdateOrderingInterface) => {
    try {
      await updateDashboardsOrder(requestData, initialFilter || [])
    } catch (e) {
      // if something went wrong - cancel optimistic changes
      table.refresh()
    }
  }

  const {
    onChangeOrder,
    moveToTop,
    moveToBottom,
    selectedOrderingIds,
    setSelectedOrderingIds,
  } = useOrdering(table.data, table.setData, table.count, table.refresh, onAfterChange)

  const tableRow: RowInterface<GenericAnalyticsDashboardInterface> = useMemo(
    () => ({
      ...row,
      cells: [
        { ...dragIconColumn, width: 25 },
        ...row.cells,
        {
          ...analyticsDashboardDetailsColumn,
          width: 40,
          insert: ({ data: dashboardData }) => (
            <Action
              onClick={e => {
                e.stopPropagation()

                setSideBarOpen(!sideBarOpen)
                setSideBarData(dashboardData)
              }}
            >
              Details
            </Action>
          ),
        },
      ],
    }),
    [sideBarOpen],
  )

  const canAdd = data?.field_options.permissions?.includes(
    PermissionTypes.ManageDashboards,
  )
  const canEditSettings =
    featureFlags?.includes(FeatureFlags.ReportingConnectionsManagement) &&
    !NO_SETTINGS_PAGES.includes(entity)

  const onChangeOrderingMode = (isOrdering: boolean) => {
    if (isOrdering) {
      table.resetFiltersAndSorting([
        {
          filters: [{ name: 'False', id: 'False' }],
          columnName: 'include_non_prioritised_items',
          nonResettable: true,
        },
      ])
    } else {
      table.resetFiltersAndSorting(initialFilter)
    }
    setOrderingMode(isOrdering)
  }

  const onSwitch = () => {
    table.onFilterChange([
      {
        columnName: 'company_related',
        filters: showAll
          ? [
              {
                id: 'true',
                name: 'true',
              },
            ]
          : [],
      },
    ])

    setShowAll(!showAll)
  }

  const handleRowClick = (r: GenericAnalyticsDashboardInterface) => {
    navigateTo(
      pathToUrl(dashboardTypeRouteMap[r.dashboard_type], {
        id: r.id,
      }),
    )
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          {navigation || (
            <Stat label="Total" val={table?.loading ? undefined : table?.count} />
          )}
        </Table.Widget.Info>
        <Table.Widget.Search>
          <Table.Search
            placeholder="Search by name or description"
            onFilter={table.onFilterChange}
          />
        </Table.Widget.Search>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {canAdd && (
              <PrimaryAction
                onClick={() => {
                  const locationState = {
                    entityType: entity,
                    ...(data && { entityId: data.id, entityName: data.name }),
                  }

                  navigateTo(
                    pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD_CREATE, {}),
                    locationState,
                  )
                }}
                useIcon="Plus"
              >
                Add new dashboard
              </PrimaryAction>
            )}
            {canAdd && entity !== EntityTypes.company ? (
              <OrderingTableControls
                disabled={false}
                orderingMode={orderingMode}
                onChangeMode={onChangeOrderingMode}
                moveToTop={moveToTop}
                moveToBottom={moveToBottom}
                disabledMoveButtons
                showMoveButtons={false}
                buttonLabel="Reorder"
              />
            ) : null}
            {canEditSettings && entity === EntityTypes.company ? (
              <SettingsButton
                path={ROUTES.SETTINGS.ANALYTICS}
                canView={[PermissionTypes.UseReportingApp]}
              />
            ) : null}
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>

        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by name or description"
            onFilter={searchQuery => table.onFilterChange(searchQuery, false)}
            variant="compact"
          />
          {entity === EntityTypes.company && (
            <FilterButton active={!showAll} onClick={onSwitch}>
              Company dashboards
            </FilterButton>
          )}
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable<GenericAnalyticsDashboardInterface>
            dataType="Dashboards"
            name={TableNames.AnalyticsDashboards}
            row={tableRow}
            {...table}
            onRowClick={orderingMode ? undefined : handleRowClick}
            useWindowScroll
            orderingMode={orderingMode}
            disabledFiltering={orderingMode}
            setSelectedOrderingIds={setSelectedOrderingIds}
            selectedOrderingIds={selectedOrderingIds}
            onChangeOrder={onChangeOrder}
            hideCount
            hiddenCells={{
              [dragIconColumn.idPoint]: !orderingMode,
              [analyticsDashboardDetailsColumn.idPoint]: true,
              [analyticsDashboardRelatedKpisColumn.idPoint]: true,
            }}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <DetailsSideBar
        dashboard={sideBarData}
        isOpen={sideBarOpen}
        setIsOpen={setSideBarOpen}
      />
    </>
  )
}
