import React from 'react'
import { IntegrationSettings } from '@src/pages/Forms/IntegrationSettings/IntegrationSettings'
import { useStatusPopup, StatusPopup, Button } from '@revolut/ui-kit'
import { createEmployeeUpload } from '@src/api/bulkDataImport'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { MergeConnectionInterface } from '@src/interfaces/integrations'

export const SourceSettings = () => {
  const statusPopup = useStatusPopup()

  const handleSuccess = (connection: MergeConnectionInterface) => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Importing data</StatusPopup.Title>
      </StatusPopup>,
    )

    createEmployeeUpload(connection.integration_type)
      .then(response => {
        statusPopup.hide()
        navigateReplace(
          pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
            id: response.data.id,
          }),
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to import data</StatusPopup.Title>
            <StatusPopup.Description>
              {getMessageFromApiError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary" elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }
  return <IntegrationSettings onSuccess={handleSuccess} useNextButton />
}
