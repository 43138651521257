import React from 'react'
import { Group, Box } from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { APPROVAL_PROCESS_GROUPS_API, organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationViewPermissions } from '../../SettingsLandingPage/constants'

export const OrganisationGeneralSettingsForm = () => {
  const queryClient = useQueryClient()

  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
  const findingsHidden = !values.findings?.visible
  const riskHidden = !values.risk?.visible
  const bugsTrackingHidden = !values.bugs_tracking?.visible
  const cxHidden = !values.customer_experience?.visible
  const dataAccessRequestHidden = !values.data_access_requests?.visible

  const generalSettingsHidden =
    cxHidden &&
    bugsTrackingHidden &&
    findingsHidden &&
    riskHidden &&
    dataAccessRequestHidden

  return (
    <>
      <PageBody>
        <Box mt="s-24">
          <Group>
            {generalSettingsHidden ? null : (
              <>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Customer Experience functionality',
                    description:
                      'Enables tracking metrics related to customer experience and displaying them in the team/department profiles and tables',
                  }}
                  name="customer_experience.enabled"
                  disabled={disableEdit}
                  hidden={cxHidden}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Bugs tracking functionality',
                    description:
                      'Enables tracking product bugs per team and displaying them in the team/department profiles and tables',
                  }}
                  name="bugs_tracking.enabled"
                  disabled={disableEdit}
                  hidden={bugsTrackingHidden}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Findings functionality',
                    description:
                      'Enables tracking findings related to the team/department, this can be security, audit, technical or other sort of findings and issues related to that particular unit.',
                  }}
                  name="findings.enabled"
                  disabled={disableEdit}
                  hidden={findingsHidden}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Risks functionality',
                    description:
                      'Allows displaying risks related to the team/department in the system and the controls to mitigate them',
                  }}
                  name="risk.enabled"
                  disabled={disableEdit}
                  hidden={riskHidden}
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable Data Access functionality',
                    description:
                      'Allows team/department owners to configure automatic access to data for certain groups of employees part of the team.',
                  }}
                  name="data_access_requests.enabled"
                  disabled={disableEdit}
                  hidden={dataAccessRequestHidden}
                />
              </>
            )}
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable download actions for talent and requisition tables',
                description:
                  'Allows the download of company, department, and team data as csv or xlsx files',
              }}
              name="enable_download_actions_for_department_data"
              disabled={disableEdit}
            />
          </Group>
        </Box>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ORGANISATION.GENERAL,
    url: ROUTES.SETTINGS.ORGANISATION.GENERAL,
    canView: organisationViewPermissions,
    component: OrganisationGeneralSettingsForm,
  },
]

export const General = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="General settings"
      subtitle="Settings that apply to teams, departments and company"
    />
  )
}
