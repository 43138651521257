import React from 'react'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import { relationToString } from '@components/ScorecardGeneral/constants'
import ProbationResult from '@components/ProbationResult/ProbationResult'
import { ProbationRecommendationInterface } from '@src/interfaces/probationReview'
import { Box, Flex } from '@revolut/ui-kit'
import { getUpdatedTimeReview } from '@src/utils/performance'

type Props = {
  recommendations: ProbationRecommendationInterface[]
}

const RecommendationReviewers = ({ recommendations }: Props) => {
  return (
    <Box>
      {recommendations.map(recommendation => (
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          mt="18px"
          mb="18px"
          key={recommendation.id}
        >
          <AvatarSnippet
            firstName={recommendation.reviewer.first_name}
            lastName={recommendation.reviewer.last_name}
            fullName={recommendation.reviewer.full_name}
            label={`(${relationToString(recommendation.reviewer_relation, true)})`}
            text={getUpdatedTimeReview(
              recommendation.updated_date_time,
              recommendation.status,
            )}
            status={recommendation.status}
            avatar={recommendation.reviewer.avatar}
          />
          <ProbationResult
            result={recommendation.result}
            status={recommendation.status}
          />
        </Flex>
      ))}
    </Box>
  )
}

export default RecommendationReviewers
