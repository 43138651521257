import React from 'react'
import { Text, Token } from '@revolut/ui-kit'

export const GoalName = ({ name }: { name?: string }) => {
  return name ? (
    <Text>{name}</Text>
  ) : (
    <Text color={Token.color.greyTone20}>
      <i>[Unnamed goal]</i>
    </Text>
  )
}
