import React from 'react'
import { connect } from 'lape'
import {
  SearchInput,
  SearchInputProps,
  OnFilterHandler as OnFilterHandlerType,
} from '@src/components/TableV2/SearchTable/SearchInput'

export type OnFilterHandler = OnFilterHandlerType

const SearchTableInput = ({
  onFilter,
  placeholder = 'Search',
  columnName = 'search',
  variant = 'default',
  disabled = false,
}: SearchInputProps) => {
  const defaultVariant = variant === 'default'
  if (defaultVariant) {
    return null
  }

  return (
    <SearchInput
      width={defaultVariant ? '100%' : undefined}
      minWidth={defaultVariant ? 200 : undefined}
      maxWidth={defaultVariant ? 443 : undefined}
      columnName={columnName}
      disabled={disabled}
      placeholder={placeholder}
      variant={variant}
      onFilter={onFilter}
    />
  )
}

export default connect(SearchTableInput)
