import React from 'react'
import { Placeholder, Spinner } from '@revolut/ui-kit'

import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { ResultsTab } from '../../v2/ResultsTab'
import { useSurveySelector } from './SurverySelector'
import { useTimelineFilter } from './TimelineFilter/useTimelineFilter'
import { useHeatmapFilters } from './hooks/useHeatmapFilters'

interface Props {
  scopeFilters: FilterByInterface[] | undefined
  invalidParamsWarning?: string
  isCompany?: boolean
}
export const SurveryResultsTabPublished = ({
  scopeFilters,
  invalidParamsWarning,
  isCompany,
}: Props) => {
  const { data: surveyOptions, isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted)

  const surveySelector = useSurveySelector()
  const timelineFilter = useTimelineFilter(surveySelector.value?.id)
  const heatmapFilters = useHeatmapFilters(surveySelector.value?.id, scopeFilters)

  if (isLoadingSurveyOptions) {
    return <Spinner />
  }
  if (!isLoadingSurveyOptions && !surveyOptions?.length) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not find latest survey</Placeholder.Title>
      </Placeholder>
    )
  }
  if (invalidParamsWarning) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not get surveys data</Placeholder.Title>
        <Placeholder.Description>{invalidParamsWarning}</Placeholder.Description>
      </Placeholder>
    )
  }
  return (
    <ResultsTab
      survey={surveySelector.surveyData.value}
      timelineFilter={timelineFilter}
      heatmapFilters={heatmapFilters}
      scopeFilters={scopeFilters}
      surveySelector={surveySelector}
      isCompany={isCompany}
    />
  )
}
