import { kpisRequestsNew } from '@src/api/kpis'
import SideBar from '@src/components/SideBar/SideBar'
import React from 'react'
import Form from '@src/features/Form/Form'
import { Flex, InputGroup, Side } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import { GoalKpiDetails } from '@src/interfaces/goals'
import { TargetFieldsByType } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/TargetFieldsByType'

export const GoalTargetSidebarForm = ({
  target,
  onClose,
  onAfterSubmit,
}: {
  target?: GoalKpiDetails
  onClose: () => void
  onAfterSubmit: (kpi: GoalKpiDetails) => void
}) => {
  const loadingState = (
    <InputGroup>
      <InputsSkeleton />
      <InputsSkeleton />
    </InputGroup>
  )
  return (
    <SideBar
      useLayout
      variant="wide"
      isOpen={!!target}
      onClose={onClose}
      title={target?.name}
      subtitle="Update metric"
    >
      {!!target && (
        <Form
          api={kpisRequestsNew}
          forceParams={{ id: String(target.id) }}
          loadingState={loadingState}
        >
          <Flex
            aria-label="Add a target"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            gap="s-24"
          >
            <TargetFieldsByType type={target.update_type} variant="side-edit" />
            <Side.Actions horizontal gradient="full">
              <Flex justifyContent="center" alignItems="center">
                <NewSaveButtonWithPopup<GoalKpiDetails>
                  width="350px"
                  useValidator
                  onAfterSubmit={onAfterSubmit}
                >
                  Save target
                </NewSaveButtonWithPopup>
              </Flex>
            </Side.Actions>
          </Flex>
        </Form>
      )}
    </SideBar>
  )
}
