import React, { useState } from 'react'
import {
  Tile,
  Icon,
  Image,
  Avatar,
  Token,
  Tag,
  Absolute,
  Item,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import pluralize from 'pluralize'
import { createSurveyFromTemplate } from '@src/api/engagement'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import Loader from '@src/components/CommonSC/Loader'

interface Props {
  template: EngagementSurveyInterface
}

const mapTemplateNameToImage = (name: string) => {
  // it doesn't matter for now really but we need a way of better handling this when we have multiple templates available
  switch (name) {
    case 'Remote Work':
      return 'https://assets.revolut.com/assets/3d-images-v2/3D028.png'
    case 'Teamwork':
      return 'https://assets.revolut.com/assets/3d-images-v2/3D183.png'
    case 'Manager Effectiveness':
      return 'https://assets.revolut.com/assets/3d-images-v2/3D033.png'
    case 'Employee Engagement':
    default:
      return 'https://assets.revolut.com/assets/3d-images-v2/3D332.png'
  }
}

export const SelectTemplateTile = ({ template }: Props) => {
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()

  const handleCreateFromTemplate = async () => {
    setIsPending(true)
    try {
      const { data } = await createSurveyFromTemplate(template.id)
      navigateTo(
        pathToUrl(ROUTES.FORMS.ENGAGEMENT.EDIT, {
          step: 'review',
          id: data.id,
        }),
      )
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't select template</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }
  return (
    <Tile
      disabled={isPending}
      onClick={handleCreateFromTemplate}
      aspectRatio={22 / 9}
      variant="voucher"
      backgroundColor={Token.color.actionBackground}
    >
      <Tag useIcon="Questionnaire" variant="status">
        {pluralize('question', template.num_questions, true)}
      </Tag>
      <Tile.Action>
        {template.is_recommended && <Icon name="Cleaning" color={Token.color.accent} />}
      </Tile.Action>
      <Tile.Content>
        {isPending ? (
          <Loader />
        ) : (
          <Absolute top="s-16" right="s-16" bottom="0px">
            <Image
              style={{ maxHeight: '100%' }}
              image={mapTemplateNameToImage(template.title)}
            />
          </Absolute>
        )}
      </Tile.Content>
      <Tile.Footer>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Document" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{template.title}</Item.Title>
            <Item.Description>{template.subtitle}</Item.Description>
          </Item.Content>
        </Item>
      </Tile.Footer>
    </Tile>
  )
}
