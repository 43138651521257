import React, { useState } from 'react'
import { HStack, TextButton, TextSkeleton } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { approveChangeRequest, rejectChangeRequest } from '@src/api/employeeChangeRequest'
import { ROUTES } from '@src/constants/routes'
import { ChangelogChangeRequestInterface } from '@src/interfaces/changelog'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface ChangeRequestActionsProps {
  employeeId: number
  onSuccess: () => void
  request?: ChangelogChangeRequestInterface | null
}

export const ChangeRequestActions = ({
  employeeId,
  onSuccess,
  request,
}: ChangeRequestActionsProps) => {
  const showErrorPopup = useErrorPopup()
  const showStatusPopup = useShowStatusPopup()
  const [approvePending, setApprovePending] = useState(false)
  const [rejectPending, setRejectPending] = useState(false)

  if (request == null) {
    return null
  }

  const onApproveOrReject = async (operation: 'approve' | 'reject') => {
    const buttonStateSetter =
      operation === 'approve' ? setApprovePending : setRejectPending
    const approveRejectApi =
      operation === 'approve' ? approveChangeRequest : rejectChangeRequest

    buttonStateSetter(true)

    try {
      await approveRejectApi(employeeId, request.id)
      onSuccess()
      showStatusPopup({
        title: `Change was successfully ${
          operation === 'approve' ? 'approved' : 'rejected'
        }`,
      })
    } catch (error) {
      showErrorPopup.show({
        error,
        fallbackTitle: `Failed to ${operation} change`,
      })
    } finally {
      buttonStateSetter(false)
    }
  }

  if (request.can_approve) {
    return (
      <HStack align="center" space="s-8">
        {approvePending ? (
          <TextSkeleton width="s-64" />
        ) : (
          <TextButton
            disabled={rejectPending}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onApproveOrReject('approve')
            }}
          >
            Approve
          </TextButton>
        )}
        {rejectPending ? (
          <TextSkeleton width="s-64" />
        ) : (
          <TextButton
            disabled={approvePending}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onApproveOrReject('reject')
            }}
            variant="danger"
          >
            Reject
          </TextButton>
        )}
      </HStack>
    )
  }

  return (
    <TextButton
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.DETAILS, {
            employeeId,
            id: request.change_request_set.id,
          }),
        )
      }}
    >
      View request
    </TextButton>
  )
}
