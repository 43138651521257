import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AppError } from '../parseError'

interface ActionErrorTitleProps {
  error: AppError
  fallbackTitle: string
}

export const GetErrorTitle = ({ error, fallbackTitle }: ActionErrorTitleProps) => {
  switch (error.type) {
    case 'forbidden':
      return (
        <FormattedMessage
          id="error.get.noPermission"
          defaultMessage="You do not have permission to view this"
        />
      )
    case 'bad_request': {
      return (
        <FormattedMessage
          id="error.get.badRequest.generic"
          defaultMessage="Could not handle request data"
        />
      )
    }
    case 'resource_not_found':
      return (
        <FormattedMessage
          id="error.get.resourceNotFound"
          defaultMessage="Resource not found"
        />
      )
    default:
      return <>{fallbackTitle}</>
  }
}
