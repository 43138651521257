import React from 'react'
import { useParams } from 'react-router-dom'
import { StatusWidget } from '@revolut/ui-kit'
import { getEmployeeContractChangelogRequests } from '@src/api/employees'
import { RowInterface } from '@src/interfaces/data'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { PageBody } from '@components/Page/PageBody'
import {
  changelogChangedByWithAvatar,
  changelogChangedOn,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogFieldsOldValue,
} from '@src/constants/columns/changelog'
import { TableNames } from '@src/constants/table'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getPermissions } from '../Preview/getPermissions'

const row: RowInterface<ChangelogInterface<EmployeeContractsInterface>> = {
  cells: [
    {
      ...changelogFieldsChangedFields,
      filterKey: null,
      width: 220,
    },
    {
      ...changelogFieldsOldValue,
      sortKey: 'initial_field_display_value',
      width: 200,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 230,
    },
    { ...changelogChangedOn, width: 200 },
  ],
}

export const ContractChangelog = () => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const { values } = useLapeContext<EmployeeContractsInterface>()

  const table = useTable<ChangelogInterface<EmployeeContractsInterface>>({
    getItems: getEmployeeContractChangelogRequests({ contractId: id, employeeId }),
  })

  const permissions = getPermissions(values.field_options?.permissions)

  if (!permissions.canView) {
    return (
      <PageBody>
        <StatusWidget>
          <StatusWidget.Image
            src="https://assets.revolut.com/assets/3d-images/3D020.png"
            srcSet="https://assets.revolut.com/assets/3d-images/3D020@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D020@3x.png 3x"
          />
          <StatusWidget.Title>
            You are not allowed to view changelog of this contract
          </StatusWidget.Title>
          <StatusWidget.Description>
            Contact support for additional information
          </StatusWidget.Description>
          <StatusWidget.Action onClick={() => goBack(ROUTES.APPS.CONTRACTS.ALL)}>
            Go Back
          </StatusWidget.Action>
        </StatusWidget>
      </PageBody>
    )
  }
  return (
    <PageBody>
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.ContractChangelog}
            useWindowScroll
            row={row}
            {...table}
            noDataMessage="Contract changelog will appear here"
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
}
