import React, { useState } from 'react'
import {
  BottomSheet,
  Header,
  Button,
  Icon,
  Token,
  VStack,
  Text,
  Checkbox,
  HStack,
  Grid,
  Cell,
  Avatar,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { LatencyDaysSelect } from '@src/pages/Forms/GoalForm/Form/Widgets/Targets/LatencyDaysSelect'

export interface AdvancedSettingsPayload {
  latencyDays: number | null
}

interface Props {
  open: boolean
  latencyDays: number | null
  onConfirm: (payload: AdvancedSettingsPayload) => void
  onCancel: () => void
}

export const AdvancedSettingsPopup = ({
  open,
  onCancel,
  onConfirm,
  latencyDays,
}: Props) => {
  const tooltip = useTooltip()
  const [allowLateUpdates, setAllowLateUpdates] = useState(!!latencyDays)
  const [latencyDaysUpdate, setLatencyDaysUpdate] = useState(latencyDays)

  return (
    <BottomSheet size="lg" open={open} onClose={onCancel}>
      <Header>
        <Header.Title>Advanced metric settings</Header.Title>
        <Header.Actions>
          <Avatar useIcon="Gear" />
        </Header.Actions>
      </Header>
      <BottomSheet.Description>
        <VStack gap="s-16">
          <VStack gap="s-12">
            <HStack gap="s-4">
              <Text variant="heading3">Late update</Text>
              <Icon {...tooltip.getAnchorProps()} name="InfoOutline" size={24} />
              <Tooltip {...tooltip.getTargetProps()} placement="top" width={300}>
                <Text>
                  When enabled, goal owners can update results for a specified number of
                  days after the quarter ends. Useful for goals that require additional
                  time to finalise results
                </Text>
              </Tooltip>
            </HStack>

            <Grid columns="2fr 1fr" columnGap="s-16">
              <Cell>
                <HStack gap="s-16" align="center">
                  <Checkbox
                    data-testid="latency-goal"
                    checked={allowLateUpdates}
                    onChange={event => {
                      setAllowLateUpdates(event.target.checked)
                      if (!event.target.checked) {
                        setLatencyDaysUpdate(null)
                      }
                    }}
                  />
                  <Text variant="emphasis1" color={Token.color.foreground}>
                    Allow late updates
                  </Text>
                </HStack>
              </Cell>
              <LatencyDaysSelect
                disabled={!allowLateUpdates}
                value={latencyDaysUpdate}
                onChange={value => setLatencyDaysUpdate(value)}
              />
            </Grid>
          </VStack>
        </VStack>
      </BottomSheet.Description>
      <BottomSheet.Actions horizontal>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm({ latencyDays: latencyDaysUpdate })} elevated>
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
