import { PUBLIC_ROUTES } from '@src/constants/routes'
import {
  LOGO_BLUELABS_IMG,
  LOGO_CARDINO_IMG,
  LOGO_TECHSCALE_IMG,
  STORY_TECHSCALE_IMG,
} from '../Landing/constants'

export const TECHSCALE_HERO =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/techscale-hero.png'

export const TECHSCALE_1 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/techscale-1.png'

export const TECHSCALE_2 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/techscale-2.png'

export const TECHSCALE_3 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/techscale-3.png'

export const TECHSCALE_4 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/techscale-4.png'

export const CARDINO_HERO =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/cardino-hero.png'

export const CARDINO_1 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/cardino-1.png'

export const CARDINO_2 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/cardino-2.png'

export const CARDINO_3 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/cardino-3.png'

export const CARDINO_4 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/cardino-4.png'

export const BLUELABS_1 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-stories/bluelabs-1.png'

export const TECHSCALE_STORY_1 = {
  company: 'TechScale',
  logo: LOGO_TECHSCALE_IMG,
  image: STORY_TECHSCALE_IMG,
  quote:
    '“Despite being a relatively small business, we felt really well taken care of. The team helped work around our challenges and we’ve already seen the average number of our applicants grow by 180% through Revolut People’s LinkedIn job posting integration.”',
  quoteBy: 'Nikolaj Gaba, CMO & Partner · TechScale',
  url: PUBLIC_ROUTES.CUSTOMER_STORIES.TECHSCALE,
  label: 'Customer success story',
}

export const CARDINO_STORY_1 = {
  company: 'Cardino',
  logo: LOGO_CARDINO_IMG,
  image: CARDINO_4,
  quote: `"Revolut People has brought transparency, organisation, and structure to Cardino's HR processes, which is essential for our high-growth environment. It has given us a platform to better manage performance, support clear career paths, and cultivate a thriving workplace culture."`,
  quoteBy: 'Lukasz Pajak, Founder · Cardino',
  url: PUBLIC_ROUTES.CUSTOMER_STORIES.CARDINO,
  label: 'Customer success story',
}

export const BLUELABS_STORY_1 = {
  company: 'BlueLabs',
  logo: LOGO_BLUELABS_IMG,
  image: BLUELABS_1,
  quote: `"Revolut People has streamlined our processes. Now all information is easily shared with the right people and stored in one place, making check-ins and goal tracking simple and efficient. We can make meaningful growth decisions."`,
  quoteBy: 'Emilia Gontarz, Head of People & Culture · BlueLabs',
  label: 'Customer testimony',
}
