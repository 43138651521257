import React, { useEffect, useMemo, useState } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Button,
  Group,
  Header,
  Item,
  Popup,
  Skeleton,
  Tag,
  Token,
  VStack,
} from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { addHiringStagesToSpecialisationHiringProcess } from '@src/api/hiringProcess'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { CompetencyMatrixInterface } from '@src/interfaces/roles'
import { DefineCompetencyMatrixPopup } from '@src/features/JobPostingFlow/components/DefineCompetencyMatrixPopup'
import {
  updateSpecialisationCompetencyMatrix,
  useGetSpecialisation,
} from '@src/api/specialisations'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

interface Props {
  onClose: VoidFunction
  onSuccess: VoidFunction
  jobPostingHiringProcess: HiringProcessInterface[]
  specialisationId: number
}

export const SetDefaultHiringProcessPopup = ({
  onClose,
  jobPostingHiringProcess,
  specialisationId,
  onSuccess,
}: Props) => {
  const showStatusPopup = useShowStatusPopup()
  const { data: specialisation, isLoading } = useGetSpecialisation(specialisationId)
  const [loading, setLoading] = useState(false)
  const [defaultProcessChecked, setDefaultProcessChecked] = useState(true)
  const [addSkillsChecked, setAddSkillsChecked] = useState(true)
  const [competencyMatrixOpen, setCompetencyMatrixOpen] = useState(false)
  const [competencyMatrix, setCompetencyMatrix] = useState<CompetencyMatrixInterface[]>(
    [],
  )

  const skills = useMemo(
    () => jobPostingHiringProcess.map(item => item.skills).flat(),
    [jobPostingHiringProcess],
  )

  useEffect(() => {
    if (!skills.length) {
      setAddSkillsChecked(false)
    }
  }, [skills])

  useEffect(() => {
    const competencyMatrixWithSkills: CompetencyMatrixInterface[] = []

    if (specialisation?.functional_competency_matrix) {
      competencyMatrixWithSkills.push(...specialisation.functional_competency_matrix)
    }

    skills.forEach(skill => {
      if (!competencyMatrixWithSkills.find(item => item.skill?.id === skill.id)) {
        competencyMatrixWithSkills.push({
          skill: {
            id: Number(skill.id),
            name: skill.name,
          },
          competencies: [],
        })
      }
    })

    setCompetencyMatrix(competencyMatrixWithSkills)
  }, [specialisation?.functional_competency_matrix, skills])

  const updateCompetencyMatrix = async () => {
    try {
      return updateSpecialisationCompetencyMatrix(specialisationId, competencyMatrix)
    } catch (error) {
      showStatusPopup({
        title: 'Cannot update competency matrix',
        description: getMessageFromApiError(error),
      })

      throw error
    }
  }

  const updateHiringProcess = async () => {
    const promises = jobPostingHiringProcess.map(process =>
      addHiringStagesToSpecialisationHiringProcess({
        ...process,
        id: undefined,
        specialisation: { id: specialisation!.id },
      }),
    )

    try {
      return Promise.all(promises)
    } catch (error) {
      showStatusPopup({
        title: 'Cannot set default hiring process',
        description: getMessageFromApiError(error),
      })

      throw error
    }
  }

  const onConfirm = async () => {
    try {
      setLoading(true)

      if (defaultProcessChecked) {
        await updateHiringProcess()
      }

      if (addSkillsChecked) {
        await updateCompetencyMatrix()
      }

      onSuccess()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Popup open onClose={onClose} preventUserClose={loading}>
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Set as specialisation default?</Header.Title>
        <Header.Description>
          This is the first time a hiring process has been defined for this
          specialisation.
        </Header.Description>
      </Header>
      {isLoading ? (
        <Skeleton />
      ) : specialisation ? (
        <>
          <VStack gap="s-16">
            <Group p="s-16">
              <VStack gap="s-16">
                <NewCheckbox
                  checked={defaultProcessChecked}
                  onClick={() => setDefaultProcessChecked(prev => !prev)}
                  label="Set as default hiring process"
                  description={
                    <>
                      Use this hiring process as the default hiring process for this
                      specialisation.{' '}
                      <Box mt="s-8">
                        <Tag color={Token.color.groupedBackground}>Recommended</Tag>
                      </Box>
                    </>
                  }
                />
                {!!skills.length && (
                  <NewCheckbox
                    checked={addSkillsChecked}
                    onClick={() => setAddSkillsChecked(prev => !prev)}
                    label="Add skills to specialisation"
                    description={
                      <>
                        Skills added to Interview evaluation will be added to{' '}
                        {specialisation.name} competency matrix.
                        <Box mt="s-8">
                          <Tag color={Token.color.groupedBackground}>Recommended</Tag>
                        </Box>
                      </>
                    }
                  />
                )}
              </VStack>
            </Group>
            {addSkillsChecked && (
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="AvatarGrid" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Competency matrix</Item.Title>
                  <Item.Description>
                    View and edit the expected skill level for each seniority.
                  </Item.Description>
                </Item.Content>
                <Item.Side>
                  <ActionButton
                    onClick={() => {
                      setCompetencyMatrixOpen(true)
                    }}
                  >
                    Review
                  </ActionButton>
                </Item.Side>
              </Item>
            )}
          </VStack>
          <Popup.Actions horizontal>
            <Button variant="secondary" onClick={onClose} disabled={loading}>
              Back
            </Button>
            <Button elevated onClick={onConfirm} pending={loading} disabled={loading}>
              Confirm
            </Button>
          </Popup.Actions>
          {competencyMatrixOpen && (
            <DefineCompetencyMatrixPopup
              onClose={() => {
                setCompetencyMatrixOpen(false)
              }}
              specialisation={specialisation}
              competencyMatrix={competencyMatrix}
              onChange={val => {
                setCompetencyMatrix([...val])
              }}
            />
          )}
        </>
      ) : (
        <PageNotFoundWidget />
      )}
    </Popup>
  )
}
