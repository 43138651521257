import React from 'react'
import { OneToOneStatus } from '../constants/meeting'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { OneToOneMeetingsQueryKeys } from '@src/api/meetings'
import { oneToOneModel } from '../models/oneToOneModel'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'
import Table from '@src/components/TableV2/Table'
import { Token } from '@revolut/ui-kit'
import { formatRRule } from '@src/utils/format'
import { selectorKeys } from '@src/constants/api'
import { formatRecurrentTimeDiff } from '../formatters/meetingSeries'
import { employeeRelationToLabelMap } from '../EmployeeMeetings/constants'

export const statusColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.NoMeeting,
  dataPoint: OneToOneMeetingsQueryKeys.NoMeeting,
  sortKey: OneToOneMeetingsQueryKeys.NoMeeting,
  filterKey: OneToOneMeetingsQueryKeys.NoMeeting,
  insert: ({ data: meeting }) => {
    const isScheduled = oneToOneModel.isScheduled(meeting)

    const status = isScheduled ? OneToOneStatus.Scheduled : OneToOneStatus.Missing
    const variant: ColorTagVariant = isScheduled ? 'neutral' : 'danger'

    return <Table.StatusCell status={status} variant={variant} />
  },
  selectorsKey: async () => ({
    options: [
      { id: 'true', name: OneToOneStatus.Missing },
      { id: 'false', name: OneToOneStatus.Scheduled },
    ],
  }),
  title: 'Status',
}

export const recurrenceColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: 'recurrence',
  dataPoint: 'recurrence',
  sortKey: null,
  filterKey: null,
  colors: meeting =>
    !oneToOneModel.isScheduled(meeting) ? Token.color.greyTone50 : 'inherit',
  insert: ({ data: meeting }) =>
    oneToOneModel.isScheduled(meeting)
      ? formatRRule(meeting.meeting_series?.recurrence)
      : 'N/A',
  selectorsKey: selectorKeys.none,
  title: 'Recurring',
}

export const timeColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: 'time',
  dataPoint: 'time',
  sortKey: null,
  filterKey: null,
  colors: () => Token.color.greyTone50,
  insert: ({ data: meeting }) => formatRecurrentTimeDiff(meeting),
  selectorsKey: selectorKeys.none,
  title: 'Time',
}

export const meetingOwnerColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.ManagerId,
  dataPoint: 'manager.full_name',
  sortKey: OneToOneMeetingsQueryKeys.ManagerFullName,
  filterKey: OneToOneMeetingsQueryKeys.ManagerId,
  selectorsKey: selectorKeys.all_employees,
  title: 'Meeting owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.manager} />,
}

export const meetingDepartmentnColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.text,
  idPoint: 'manager.department.id',
  dataPoint: 'manager.department.name',
  sortKey: OneToOneMeetingsQueryKeys.ManagerTeamDepartmentName,
  filterKey: OneToOneMeetingsQueryKeys.ManagerTeamDepartmentId,
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const meetingRelationColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.RelationType,
  dataPoint: OneToOneMeetingsQueryKeys.RelationType,
  sortKey: OneToOneMeetingsQueryKeys.RelationType,
  filterKey: OneToOneMeetingsQueryKeys.RelationType,
  selectorsKey: selectorKeys.meeting_relation_types,
  title: 'Relation',
  colors: () => Token.color.greyTone50,
  insert: ({ data }) => employeeRelationToLabelMap[data.relation_type],
}

export const employeeColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.EmployeeId,
  dataPoint: 'employee.full_name',
  sortKey: OneToOneMeetingsQueryKeys.EmployeeFullName,
  filterKey: OneToOneMeetingsQueryKeys.EmployeeId,
  selectorsKey: selectorKeys.all_employees,
  title: 'Report',
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
}
