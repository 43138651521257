import React from 'react'
import {
  AvatarSkeleton,
  Flex,
  HStack,
  Spacer,
  TextSkeleton,
  VStack,
} from '@revolut/ui-kit'
import { MetricHighlightSkeleton } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/MetricHighlight'

export const MetricsItemSkeleton = () => (
  <VStack>
    <HStack space="s-16" px="s-16" pt="s-16" align="center">
      <AvatarSkeleton />
      <TextSkeleton width={100} />
      <Spacer />
      <TextSkeleton width={100} />
    </HStack>
    <VStack space="s-8" p="s-16">
      <Flex gap="s-8" flexWrap="wrap">
        <MetricHighlightSkeleton flex={1} />
        <MetricHighlightSkeleton flex={1} />
      </Flex>
      <Flex gap="s-8" flexWrap="wrap">
        <MetricHighlightSkeleton flex={1} />
        <MetricHighlightSkeleton flex={1} />
      </Flex>
    </VStack>
  </VStack>
)
