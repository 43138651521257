import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { connect } from 'lape'

import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import {
  TeamSettingsFields,
  TeamSettingsSaveButton,
} from '@src/pages/Settings/Organisation/Teams/common'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { organisationSettings } from '@src/api/settings'

const StructuresTeamsSettingsForm = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Teams settings"
        subtitle="Change team privacy and approval settings"
        backUrl={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL}
      />

      <PageBody>
        <VStack space="s-16">
          <TeamSettingsFields />
        </VStack>
      </PageBody>

      <PageActions>
        <TeamSettingsSaveButton />
      </PageActions>
    </PageWrapper>
  )
}

export const StructuresTeamsSettings = connect(() => {
  return (
    <Form api={organisationSettings} forceParams={{ id: '1' }}>
      <StructuresTeamsSettingsForm />
    </Form>
  )
})
