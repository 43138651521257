import { useIsPerformanceAiInsightsEnabled } from '@src/features/AiInsights/Performance/hooks/useAiInsightsAvailability'
import {
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'

export const useCanAccessAiInsights = (review: ReviewScorecardInterface) => {
  const isPerformanceAiInsightsEnabled = useIsPerformanceAiInsightsEnabled()

  return (
    isPerformanceAiInsightsEnabled &&
    !!review.reviewer_relation &&
    [ReviewerRelation.LineManager, ReviewerRelation.FunctionalManager].includes(
      review.reviewer_relation,
    ) &&
    review.category === ReviewCategory.Performance
  )
}
