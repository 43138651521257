import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { Avatar, Banner, Tag, VStack, Text, Token } from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import { GoalsTableFormWidget } from './GoalsTableWidget'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { FilterByInterface } from '@src/interfaces/data'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import pluralize from 'pluralize'
import isWithinInterval from 'date-fns/isWithinInterval'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useLocation } from 'react-router'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { EmployeeTargets } from './Targets/EmployeeTargets'
import { TeamTargets } from './Targets/TeamTargets'
import { DepartmentTargets } from './Targets/DepartmentTargets'
import { CompanyTargets } from './Targets/CompanyTargets'
import { useGetGoalContentTypeFilter } from './useGoalFilters'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useCycleGoalsCalibrationState } from './useCycleGoalsCalibrationState'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Statuses } from '@src/interfaces'
import { useRouteMatch } from 'react-router-dom'
import { PerformanceRoadmaps } from './Roadmaps/PerformanceRoadmaps'
import { useGetEmployeeCyclesSelector } from './common/useGetEmployeeCyclesSelector'
import { BaseOption } from '@src/interfaces/selectors'
import { useGetRoadmapSettings } from '@src/api/settings'
import { OrganisationalGoalsWidget } from './components/OrganisationalGoalsWidget'

export const useInitialFilters = (
  currentCycle?: BaseOption,
  cycleFilterKey: string = 'cycle__id',
) => {
  const { entity } = useOrgEntity()
  const { filter: contentTypeFilter } = useGetGoalContentTypeFilter(entity?.type)
  return useMemo(() => {
    const initialFilters: FilterByInterface[] = [
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
    ]

    if (currentCycle) {
      initialFilters.push({
        columnName: cycleFilterKey,
        filters: [{ id: currentCycle.id, name: currentCycle.name }],
        nonResettable: true,
      })
    }
    if (contentTypeFilter) {
      initialFilters.push(contentTypeFilter)
    }

    if (
      initialFilters &&
      entity &&
      entity.type !== EntityTypes.company &&
      entity.type !== EntityTypes.companyV2
    ) {
      initialFilters.push({
        columnName: 'object_id',
        filters: [{ id: entity.data.id, name: entity.data.name || '' }],
        nonResettable: true,
        nonInheritable: true,
      })
    }

    return contentTypeFilter ? initialFilters : null
  }, [currentCycle, entity, contentTypeFilter, cycleFilterKey])
}

const useCurrentTimeline = (currentCycle?: ReviewCyclesInterface) => {
  const { entity } = useOrgEntity()
  const { query } = useQuery()

  const calibrationState = useCycleGoalsCalibrationState(
    query.cycle__id ?? currentCycle?.id,
  )
  const today = new Date()

  const isBetween = (start?: string | null, end?: string | null) => {
    return start && end
      ? isWithinInterval(today, { start: new Date(start), end: new Date(end) })
      : false
  }

  const isKpiPeriodFor = (cycle?: ReviewCyclesInterface) => {
    switch (entity?.type) {
      case EntityTypes.employee:
      case EntityTypes.employees: {
        return {
          isActive: isBetween(
            cycle?.individual_kpi_period_start_day,
            cycle?.individual_kpi_period_end_day,
          ),
          timeLeft: cycle?.individual_kpi_period_end_day
            ? formatDistanceToNowStrict(new Date(cycle.individual_kpi_period_end_day))
            : null,
        }
      }

      case EntityTypes.team:
      case EntityTypes.teams:
        return {
          isActive: isBetween(
            cycle?.team_kpi_period_start_day,
            cycle?.team_kpi_period_end_day,
          ),
          timeLeft: cycle?.team_kpi_period_end_day
            ? formatDistanceToNowStrict(new Date(cycle.team_kpi_period_end_day))
            : null,
        }

      case EntityTypes.department:
        return {
          isActive: isBetween(
            cycle?.department_kpi_period_start_day,
            cycle?.department_kpi_period_end_day,
          ),
          timeLeft: cycle?.department_kpi_period_end_day
            ? formatDistanceToNowStrict(new Date(cycle.department_kpi_period_end_day))
            : null,
        }

      default:
        return {
          isActive: false,
          timeLeft: null,
        }
    }
  }

  return {
    kpi: isKpiPeriodFor(currentCycle),
    calibration: calibrationState,
  }
}

export const useBanner = (defaultCycle?: ReviewCyclesInterface) => {
  const { entity } = useOrgEntity()

  const { kpi, calibration } = useCurrentTimeline(defaultCycle)

  if (
    !entity ||
    entity.type === EntityTypes.company ||
    entity.type === EntityTypes.companyV2
  ) {
    return null
  }

  if (!kpi.isActive && !calibration.isActive) {
    return null
  }

  const renderBanner = (
    title: string,
    description: string,
    timeLeft: string | number | null,
    image: string,
    imageVersion?: string,
  ) => {
    const assetsBase = `https://assets.revolut.com/assets/${
      imageVersion ? `3d-images-${imageVersion}` : '3d-images'
    }`
    return (
      <Banner aria-label="period banner">
        <Banner.Avatar>
          <Avatar
            variant="app"
            image={{
              default: `${assetsBase}/${image}.png`,
              '2x': `${assetsBase}/${image}@2x.png`,
              '3x': `${assetsBase}/${image}@3x.png`,
            }}
            size={56}
          />
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>{title}</Banner.Title>
          <Banner.Description>
            <VStack gap="s-12">
              <Text>{description}</Text>
              {timeLeft ? (
                <Tag
                  useIcon="Time"
                  color={Token.color.foreground}
                  variant="faded"
                  aria-label="period time left"
                >
                  <Text variant="small-tile">{timeLeft} left</Text>
                </Tag>
              ) : null}
            </VStack>
          </Banner.Description>
        </Banner.Content>
      </Banner>
    )
  }

  if (kpi.isActive) {
    return renderBanner(
      'Goal setting and approval',
      `Please add and review the ${pluralize(
        entity.type,
        1,
      )} goals for the upcoming quarter. Goals should include a clear definition of done and a target for the success criteria which tracks progress towards the outcome of the goal. Goals should be set to impact the outcome of our company goals.`,
      kpi.timeLeft,
      '3D196',
    )
  }

  if (calibration.isActive) {
    return renderBanner(
      'Time for Goal Progress Calibration!',
      `It's essential to ensure our goals are advancing as planned. Dive into this calibration process to assess the progress and adjust any discrepancies. This step ensures our efforts remain aligned with the desired outcomes and helps in identifying areas that might need more focus. Your timely attention ensures our team's continued success`,
      calibration.timeLeft,
      '3D221',
      'v2',
    )
  }

  return null
}

const getRoutes = (entityType?: EntityTypes) => {
  switch (entityType) {
    case EntityTypes.companyV2:
      return ROUTES.PERFORMANCE.GOALS
    case EntityTypes.company:
      return ROUTES.ORGANISATION.COMPANY.GOALS
    case EntityTypes.department:
      return ROUTES.FORMS.DEPARTMENT.GOALS
    case EntityTypes.team:
    case EntityTypes.teams:
      return ROUTES.FORMS.TEAM.GOALS
    case EntityTypes.employee:
    case EntityTypes.employees:
      return ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS
    default:
      return null
  }
}

export const RoadmapsTableWidget = ({ cycle }: { cycle?: ReviewCyclesInterface }) => {
  return <PerformanceRoadmaps initialCycle={cycle} />
}

const OrganisationalGoalsTableWidget = ({
  cycle,
  initialFilters,
}: {
  cycle?: ReviewCyclesInterface
  initialFilters: FilterByInterface[]
}) => {
  return (
    <OrganisationalGoalsWidget initialCycle={cycle} initialFilters={initialFilters} />
  )
}

export const TargetsTableWidget = ({ cycle }: { cycle?: ReviewCyclesInterface }) => {
  const { entity } = useOrgEntity()

  switch (entity?.type) {
    case EntityTypes.employee:
    case EntityTypes.employees:
      return <EmployeeTargets data={entity.data} />
    case EntityTypes.team:
    case EntityTypes.teams:
      return <TeamTargets data={entity.data} cycle={cycle} />
    case EntityTypes.department:
      return <DepartmentTargets data={entity.data} cycle={cycle} />
    case EntityTypes.company:
    case EntityTypes.companyV2:
      return <CompanyTargets initialCycle={cycle} />
    default:
      return null
  }
}

const GoalsTabComponent = ({
  entityType,
  entityId,
  initialFilters,
  cycle,
  onManageModeChange,
}: {
  entityType: EntityTypes
  entityId: number
  initialFilters: FilterByInterface[]
  cycle?: ReviewCyclesInterface
  onManageModeChange?: (open: boolean) => void
}) => {
  const { data: roadmapsSettings } = useGetRoadmapSettings()
  const banner = useBanner(cycle)
  const query = useLocationQuery()
  const { data: performanceSettings, isLoading } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle
  const isOnboarding = isOnboardingPath()
  const isPerformanceRoute = useRouteMatch(ROUTES.PERFORMANCE.ANY)

  const routes = isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP
    : getRoutes(entityType)

  const currentReviewCycleId = query.get('cycle__id') || query.get('review_cycle__id')
  const cycleIdQuery = currentReviewCycleId
    ? new URLSearchParams({ cycle__id: currentReviewCycleId })
    : undefined

  const tabs = useMemo(() => {
    if (!routes || isLoading) {
      return []
    }

    return [
      {
        path: [
          ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL,
          ROUTES.PERFORMANCE.GOALS.GENERAL,
          ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL,
          ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,
          ROUTES.FORMS.TEAM.GOALS.GENERAL,
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
        ],
        component: GoalsTableFormWidget,
        title: 'Goals',
      },
      ...([EntityTypes.employee, EntityTypes.employees].includes(entityType)
        ? [
            {
              path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ORGANISATIONAL,
              component: OrganisationalGoalsTableWidget,
              title: 'Organisational Goals',
            },
          ]
        : [
            {
              isHidden: !isMultipleGoalsTargetsEnabled,
              path: [
                ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.TARGETS,
                ROUTES.PERFORMANCE.GOALS.TARGETS,
                ROUTES.ORGANISATION.COMPANY.GOALS.TARGETS,
                ROUTES.FORMS.DEPARTMENT.GOALS.TARGETS,
                ROUTES.FORMS.TEAM.GOALS.TARGETS,
              ],
              component: TargetsTableWidget,
              title: 'Targets',
            },
            {
              isHidden:
                !(isPerformanceRoute || isOnboarding) || !roadmapsSettings?.enabled,
              path: [
                ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.ROADMAPS,
                ROUTES.PERFORMANCE.GOALS.ROADMAPS,
                ROUTES.ORGANISATION.COMPANY.GOALS.ROADMAPS,
                ROUTES.FORMS.DEPARTMENT.GOALS.ROADMAPS,
                ROUTES.FORMS.TEAM.GOALS.ROADMAPS,
              ],
              component: RoadmapsTableWidget,
              title: 'Roadmaps',
            },
          ]),
    ].filter(tab => tab && !tab.isHidden)
  }, [routes, currentReviewCycleId, isLoading, isMultipleGoalsTargetsEnabled])

  return (
    <VStack space="s-24">
      {banner}
      {!!tabs.length && (
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.title}>
              <tab.component
                initialFilters={initialFilters}
                cycle={cycle}
                onManageModeChange={onManageModeChange}
              />
            </Route>
          ))}
          <InternalRedirect
            to={
              routes
                ? {
                    pathname: pathToUrl(routes.GENERAL, { id: entityId }),
                    search: cycleIdQuery?.toString(),
                  }
                : {}
            }
          />
        </Switch>
      )}
    </VStack>
  )
}

interface GoalsTabProps {
  onManageModeChange?: (enabled: boolean) => void
}

export const GoalsTab = ({ onManageModeChange }: GoalsTabProps) => {
  const { data: currentCycle, isLoading: cycleLoading } = useGetReviewCycleByOffset(0)

  const { entity } = useOrgEntity()
  const isEmployee =
    entity?.type && [EntityTypes.employee, EntityTypes.employees].includes(entity.type)

  const { initialCycle, isLoading } = useGetEmployeeCyclesSelector(
    isEmployee ? entity?.data.id : undefined,
    true,
  )
  // if it's employee page use employee performance as initial cycle, as they may not have current cycle
  // eg when on probation
  const initialFilters = useInitialFilters(isEmployee ? initialCycle : currentCycle)

  return !cycleLoading && initialFilters && entity && !isLoading ? (
    <GoalsTabComponent
      cycle={currentCycle}
      initialFilters={initialFilters}
      entityId={entity.data.id}
      entityType={entity.type}
      onManageModeChange={onManageModeChange}
    />
  ) : (
    <PageLoading />
  )
}

function useLocationQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
