import React, { useMemo } from 'react'
import { Box, HStack, Subheader } from '@revolut/ui-kit'
import { ScheduleInterviewInterface } from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import SlotInterviewerFilter from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotInterviewerFilter'
import { SlotInterviewerGroupFilter } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotInterviewerGroupFilter'
import { AvailableSlotsBanner } from '@src/pages/Forms/Candidate/ScheduleSidebar/AvailableSlotsBanner'
import { useSetInitialTimezone } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks/useSetInitialTimezone'
import { getTimeZoneId } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import { RangeValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'
import { DynamicSlotsDateFilter } from '@src/pages/Forms/Candidate/ScheduleSidebar/DynamicSlotsDateFilter'

interface Props {
  roundId: number
  dateFilter?: OptionInterface
  interviewersFilter?: OptionInterface[]
  interviewerGroupsFilter?: IdAndName[]
  setInterviewersFilter: (options?: OptionInterface[]) => void
  setInterviewerGroupsFilter: (options?: IdAndName[]) => void
  setDateFilter: (options?: OptionInterface) => void
  isPrepCall: boolean
  onChangeDateRange: (dateRange: RangeValue) => void
  dateRange: RangeValue | null
}

export const DynamicSlotsMode = ({
  setInterviewersFilter,
  interviewersFilter,
  interviewerGroupsFilter,
  setInterviewerGroupsFilter,
  roundId,
  dateFilter,
  setDateFilter,
  isPrepCall,
  onChangeDateRange,
  dateRange,
}: Props) => {
  const { values } = useLapeContext<ScheduleInterviewInterface>()
  useSetInitialTimezone()

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)

  const selectedWeekOption = useMemo(
    () => (dateFilter ? { id: Number(dateFilter.id), name: dateFilter.name } : undefined),
    [dateFilter],
  )

  return (
    <ErrorBoundary>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Select preferred weeks and interviewers</Subheader.Title>
        </Subheader>
        <HStack gap="s-8" mb="s-16">
          <DynamicSlotsDateFilter
            selectedWeekOption={selectedWeekOption}
            customDateRange={dateRange}
            onChangeCustomDateRange={onChangeDateRange}
            onChangeWeekOption={setDateFilter}
            timeZoneId={timeZoneId}
          />
          <SlotInterviewerFilter
            onChange={newInterviewersFilter => {
              setInterviewersFilter(newInterviewersFilter)
            }}
            value={interviewersFilter}
            isPrepCall={isPrepCall}
            stageId={values.interview_stage?.id}
            roundId={roundId}
          />
          <SlotInterviewerGroupFilter
            onChange={newInterviewerGroupsFilter => {
              setInterviewerGroupsFilter(newInterviewerGroupsFilter)
            }}
            value={interviewerGroupsFilter}
            isPrepCall={isPrepCall}
            stageId={values.interview_stage?.id}
            roundId={roundId}
          />
        </HStack>
        <Box mb="s-16">
          <TimezoneSelect />
        </Box>
        <AvailableSlotsBanner
          stageId={values.interview_stage.id}
          roundId={roundId}
          interviewers={interviewersFilter}
          interviewerGroups={interviewerGroupsFilter}
          duration={values.duration}
          durationUnit={values.duration_unit?.id}
          isAdhoc={isPrepCall}
          daysCount={values.days_to_suggest_slots_for}
          dynamicSlotsDateRangeStart={values.slots_datetime_start}
          dynamicSlotsDateRangeEnd={values.slots_datetime_end}
          timeZoneId={timeZoneId}
        />
      </Box>
    </ErrorBoundary>
  )
}
