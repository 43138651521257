import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import React, { useEffect, useState } from 'react'
import { ProbationGoalInterface } from '@src/interfaces/probationReview'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { useGetGoals } from '@src/api/performance'
import { getProbationGoals } from '@src/api/probationReview'
import { getPipGoals } from '@src/api/pip'
import { Box, Icon, Token } from '@revolut/ui-kit'
import { TicketsView } from '@src/pages/Forms/ProbationOverview/ProbationGoals/TicketsView'
import { ProbationLayoutGoalsJiraDisabled } from '@src/pages/EmployeeProfile/Preview/Performance/Goals/ProbationLayoutGoalsJiraDisabled'
import { useGetPeriodTypes } from '@src/utils/performance'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { selectUser } from '@src/store/auth/selectors'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/CollapsableTableWrapper'
import { CalculatedDeliverablesRating } from './useCalculatedDeliverablesRating'

export const SummaryGoalsCollapsable = ({
  data,
  selectedPeriod,
  lineManagerId,
  calculatedDeliverablesRating,
  isSummaryReview,
}: {
  data: Pick<EmployeeInterface, 'id'>
  isSummaryReview?: boolean
  selectedPeriod: Pick<
    PerformanceSelector,
    | 'category'
    | 'end_date_time'
    | 'probation_reviews_deliverables_type'
    | 'id'
    | 'probation_version'
    | 'version'
  >
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
  lineManagerId?: number
}) => {
  const user = useSelector(selectUser)
  const isLineManager = user.id === lineManagerId
  const [tickets, setTickets] = useState<ProbationGoalInterface[]>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const pipJiraDisabled = featureFlags.includes(
    FeatureFlags.PipGoalsJiraIntegrationDisabled,
  )

  const { isNewProbation, isPIPv2 } = useGetPeriodTypes(selectedPeriod)

  const cycleId = selectedPeriod?.id

  const filters = [
    {
      filters: [{ name: `${data.id}`, id: `${data.id}` }],
      columnName: 'employee__id',
    },
    selectedPeriod?.category !== ReviewCycleCategory.Probation
      ? {
          filters: [{ name: `${cycleId}`, id: `${cycleId}` }],
          columnName: 'review_cycle__id',
        }
      : {
          filters: [{ name: selectedPeriod?.category, id: selectedPeriod?.category }],
          columnName: 'category',
        },
  ]

  const { data: jiraDisabledGoals = [] } = useGetGoals(
    selectedPeriod?.category && data.id && selectedPeriod?.end_date_time ? data.id : null,
    filters,
  )

  const jiraIntegrationEnabled =
    performanceSettings?.enable_probation_and_pip_goals_via_jira &&
    (!isPIPv2 || !pipJiraDisabled)

  const probationDeliverablesType = selectedPeriod?.probation_reviews_deliverables_type

  const onAddGoalRedirect = () => {
    const route =
      selectedPeriod.category === ReviewCycleCategory.Probation
        ? ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS
        : ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS

    navigateTo(
      `${pathToUrl(route, {
        employeeId: data?.id,
        cycleId: selectedPeriod.id,
      })}`,
    )
  }

  useEffect(() => {
    if (!isNewProbation || cycleId === undefined) {
      return
    }

    const fetchTickets = async () => {
      const result = await getProbationGoals(data.id, String(cycleId))
      const results = [...result.data.results]

      setTickets(results)
    }

    fetchTickets()
  }, [cycleId, isNewProbation, probationDeliverablesType])

  useEffect(() => {
    if (!isPIPv2 || cycleId === undefined) {
      return
    }

    const fetchTickets = async () => {
      const result = await getPipGoals(data.id, String(cycleId))

      setTickets(result.data.results)
    }

    fetchTickets()
  }, [cycleId, isPIPv2])

  const showProbationGoals =
    isNewProbation && cycleId !== undefined && probationDeliverablesType === 'jira'

  const showPipGoals = isPIPv2 && cycleId !== undefined
  const content = (
    <Box mt="s-16">
      <CollapsableTableWrapper
        mapperFunc={calculatedDeliverablesRating?.mapper}
        gradesMap={calculatedDeliverablesRating?.gradesMap}
        completedReviews={isSummaryReview}
        icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
        headerTitle="Goals"
        headerRating={calculatedDeliverablesRating?.rating}
        count={
          probationDeliverablesType === 'jira' && jiraIntegrationEnabled
            ? (tickets || []).length
            : jiraDisabledGoals.length
        }
        ratings={calculatedDeliverablesRating?.ratings}
      >
        {probationDeliverablesType === 'jira' && jiraIntegrationEnabled ? (
          <TicketsView
            type={selectedPeriod.category}
            onAddGoalRedirect={onAddGoalRedirect}
            tickets={tickets || []}
            isLineManager={isLineManager}
          />
        ) : (
          <ProbationLayoutGoalsJiraDisabled
            type={selectedPeriod.category}
            goals={jiraDisabledGoals}
            onAddGoalRedirect={onAddGoalRedirect}
            isLineManager={isLineManager}
          />
        )}
      </CollapsableTableWrapper>
    </Box>
  )

  return <>{(showProbationGoals || showPipGoals) && content}</>
}
