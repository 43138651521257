import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { WhitelistRecordInterface } from '@src/interfaces/supportTool'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { InputGroup, Widget } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@components/Page/PageBody'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const General = () => {
  const { values } = useLapeContext<WhitelistRecordInterface>()

  const backUrl = pathToUrl(ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS)

  let title
  if (values.id) {
    title = 'Whitelisted reviewers'
  } else {
    title = 'New whitelist'
  }

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />
      <PageBody>
        <Widget p="s-16">
          <InputGroup>
            <LapeNewMultiSelect
              label="Name"
              name="whitelisted_reviewers"
              selector={selectorKeys.employee}
              placeholder="Select reviewers"
              required
              variant="grey"
            />
            <LapeRadioSelectInput
              name="cycle"
              label="Performance cycle"
              selector={selectorKeys.all_review_cycles}
            />
            <InputGroup variant="horizontal">
              <LapeNewDatePicker label="Select start date" name="start_date" required />
              <LapeNewDatePicker label="Select end date" name="end_date" required />
            </InputGroup>
            <LapeNewTextArea
              name="reason"
              label="Add reason why this is needed"
              required
              rows={8}
            />
          </InputGroup>
        </Widget>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Whitelist record successfully saved."
          afterSubmitUrl={backUrl}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default General
