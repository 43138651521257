import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import {
  DaySlotGroupInterface,
  DaySlotGroupItemInterface,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/DaySlotsGroup/DaySlotsGroup'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export const useGroupByDaySlotsAndInterviewers = <
  T extends { event_start_datetime: string; employee: EmployeeOptionInterface },
>(
  timeZoneId: string,
  slots: T[],
) => {
  const result: DaySlotGroupInterface<DaySlotGroupItemInterface>[] = []
  const groupedSlots = useGroupByDaySlots(timeZoneId, slots)

  groupedSlots.forEach(group => {
    let items: DaySlotGroupItemInterface[] = []

    group.items.forEach(item => {
      const foundSlot = items.find(
        i => i.event_start_datetime === item.event_start_datetime,
      )

      if (foundSlot) {
        foundSlot.interviewers.push(item.employee)
      } else {
        items.push({
          event_start_datetime: item.event_start_datetime,
          interviewers: [item.employee],
        })
      }
    })

    result.push({
      groupDate: group.groupDate,
      label: group.label,
      items,
    })
  })

  return result
}
