import styled, { css } from 'styled-components'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { Link } from 'react-router-dom'

const linkStyle = css`
  color: inherit;
  text-decoration: none;
  justify-self: start;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledLink = styled(Link)`
  ${linkStyle}
`

export const StyledInternalLink = styled(InternalLink)`
  ${linkStyle}
`
