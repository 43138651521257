import React, { useEffect } from 'react'

import { ROUTES } from '@src/constants/routes'
import { Function } from '@src/pages/Settings/Function/Function'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { useCompanyStructuresSettingsEnabled } from '@src/pages/Settings/Structures/components/useCompanyStructuresSettingsEnabled'
import { navigateReplace } from '@src/actions/RouterActions'
import { organisationViewPermissions } from '@src/pages/Settings/SettingsLandingPage/constants'

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ROLES.GENERAL,
    url: ROUTES.SETTINGS.ROLES.GENERAL,
    canView: organisationViewPermissions,
    component: Function,
  },
]

export const FunctionSettings = () => {
  const companyStructuresEnabled = useCompanyStructuresSettingsEnabled()

  useEffect(() => {
    if (companyStructuresEnabled) {
      navigateReplace(ROUTES.SETTINGS.STRUCTURES.ROLES.ALL)
    }
  }, [companyStructuresEnabled])

  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Roles app settings"
      subtitle="All settings for functions, roles and specialisations"
    />
  )
}
