import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import CandidateSchedulingRouter from '@src/pages/CandidateScheduling/Router'
import DiversitySurvey from '@src/pages/DiversitySurvey/DiversitySurvey'
import CareersRouter from '@src/pages/Careers/Router'
import BookingLink from '@src/features/BookingLinks/Candidate/BookingLink'
import Welcome from '@src/pages/Onboarding/Welcome/Welcome'
import Page404 from '@src/pages/Page404/Page404'
import { SubdomainRedirect } from '@src/pages/SubdomainRedirect/SubdomainRedirect'
import EngagementConsent from '@src/pages/EngagementConsent/EngagementConsent'
import { connect } from 'lape'
import { TermsAndConditions } from '@src/pages/Legal/TermsAndConditions'
import { PrivacyNotice } from '@src/pages/Legal/PrivacyNotice'
import { Complaints } from '@src/pages/Legal/Complaints'
import { ComingSoon } from '@src/pages/Public/ComingSoon/ComingSoon'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { CustomerStoriesRouter } from '@src/pages/CustomerStories/CustomerStoriesRouter'

const PublicRouter = () => {
  return (
    <Switch>
      <Route
        path={PUBLIC_ROUTES.CANDIDATE_SCHEDULING.ANY}
        component={CandidateSchedulingRouter}
      />
      <Route path={PUBLIC_ROUTES.WELCOME} exact>
        <Welcome headerHeight={200} />
      </Route>
      <Route path={PUBLIC_ROUTES.CAREERS.ANY} component={CareersRouter} />
      <Route exact path={PUBLIC_ROUTES.BOOKING_LINK.ANY} component={BookingLink} />
      <Route path={PUBLIC_ROUTES.DIVERSITY_SURVEY} component={DiversitySurvey} />
      <Route exact path={PUBLIC_ROUTES.PAGE_404}>
        <Page404 noLink />
      </Route>
      <Route
        exact
        path={PUBLIC_ROUTES.SUBDOMAIN_PERMISSIONS_REDIRECT}
        component={() => <SubdomainRedirect route={ROUTES.GRANT_PERMISSIONS_REDIRECT} />}
      />
      <Route
        exact
        path={PUBLIC_ROUTES.INDEED_INTEGRATION_REDIRECT}
        component={() => <SubdomainRedirect route={ROUTES.FEATURES.INTEGRATION.INDEED} />}
      />
      <Route
        exact
        path={PUBLIC_ROUTES.ENGAGEMENT_CONSENT}
        component={EngagementConsent}
      />
      <Route
        exact
        path={PUBLIC_ROUTES.TERMS_AND_CONDITIONS}
        component={TermsAndConditions}
      />
      <Route exact path={PUBLIC_ROUTES.COMPLAINTS} component={Complaints} />
      <Route exact path={PUBLIC_ROUTES.COMING_SOON} component={ComingSoon} />
      <Route exact path={PUBLIC_ROUTES.PRIVACY_NOTICE} component={PrivacyNotice} />
      <Route path={PUBLIC_ROUTES.CUSTOMER_STORIES.ANY}>
        <CustomerStoriesRouter />
      </Route>
      <Route>
        <PageNotFoundWidget noLink />
      </Route>
    </Switch>
  )
}

export default connect(PublicRouter)
