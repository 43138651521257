import React from 'react'
import { Tag, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetJobPostingSettings } from '@src/api/settings'

const getStatusColor = (status: PublishingStatuses) => {
  switch (status) {
    case PublishingStatuses.closed:
    case PublishingStatuses.unpublished:
      return Token.color.red
    case PublishingStatuses.published_internally:
    case PublishingStatuses.fully_published:
      return Token.color.green
    default:
      return Token.color.greyTone50
  }
}

const StatusTag = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const tooltip = useTooltip()

  const {
    status,
    approval_status,
    requested_publishing_status,
    requested_publishing_status_display,
  } = values

  const notFinalStatus = status !== requested_publishing_status
  const notApproved =
    jobPostingSettings?.enable_approvals && approval_status !== 'approved'

  if (!status) {
    return null
  }

  return (
    <Tag variant="outlined" color={getStatusColor(status)} {...tooltip.getAnchorProps()}>
      {upperFirst(removeUnderscore(status))}
      {notApproved && notFinalStatus && (
        <Tooltip {...tooltip.getTargetProps()}>
          Job posting will be {requested_publishing_status_display} after approval
        </Tooltip>
      )}
    </Tag>
  )
}

export default StatusTag
