import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { AxiosError } from 'axios'
import omit from 'lodash/omit'
import flatten from 'flat'

import { arrayErrorsToFormError } from '@src/utils/form'
import { FormError, useLapeContext } from './LapeForm'
import isEmpty from 'lodash/isEmpty'
import { parseError } from '../Errors/parseError'
import { Box } from '@revolut/ui-kit'
import { captureError } from '../Errors/captureError'
import { useErrorPopup } from '../Errors/useErrorPopup'

const errorKeysToIgnore = ['non_field_errors']

export type FormValidatorContextType = {
  validated: boolean
  validate: (
    callback: () => Promise<any>,
    customErrorHandler?: (error: AxiosError) => void,
  ) => () => void
  forceErrors: (errors: FormError<{}>) => void
} | null

const FormValidatorContext = createContext<FormValidatorContextType>(null)

// Use this when consumer may not be wrapped in FormValidatorProvider, otherwise use useSafeFormValidator
export const useFormValidator = () => {
  return useContext(FormValidatorContext)
}

// Use this when consumer should be wrapped in FormValidatorProvider, otherwise use useFormValidator
export const useSafeFormValidator = () => {
  const context = useContext(FormValidatorContext)
  if (context == null) {
    throw new Error(`useSafeFormValidator must be used within a FormValidatorProvider`)
  }
  return context
}

export const FormValidatorProvider: React.FC = ({ children }) => {
  const [validated, setValidated] = useState(false)
  const form = useLapeContext()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const statusPopup = useStatusPopup()
  const errorPopup = useErrorPopup()

  const showFallbackErrorPopup = (error: AxiosError) => {
    captureError(error, {
      tags: { component: 'FormValidatorProvider' },
      severity: 'fatal',
    })

    errorPopup.show({
      fallbackTitle: 'Failed to submit',
      error,
    })
  }

  const scrollToField = (
    inputNames: string[],
    error?: AxiosError,
    customErrorHandler?: (error: AxiosError) => void,
  ) => {
    const inputs = inputNames.map(name => {
      // wrapperRef is needed in case we have multiple forms with the same input titles, but the problem is
      // wrapperRef.current could be empty if Popup is inside, as it floats to the top
      const element = (
        wrapperRef.current?.innerHTML ? wrapperRef.current : document
      ).querySelector(`[data-name~="${name}"]`)

      return {
        element,
        top: element?.getBoundingClientRect().top,
      }
    })

    const topmostElement = inputs.reduce<{
      element: Element | null
      top: number | undefined
    } | null>((topmost, element) => {
      if (element.top != null && (topmost?.top == null || element.top < topmost.top)) {
        return element
      }
      return topmost
    }, null)

    // Noticed an issue of `scrollIntoView` getting cancelled by other events (like modal closing), scheduling fixes this
    setTimeout(() => {
      topmostElement?.element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    })

    if (inputs.some(input => input.element == null) && error) {
      if (customErrorHandler) {
        customErrorHandler(error)
      } else {
        showFallbackErrorPopup(error)
      }
    }
  }

  const validate = useCallback(
    (callback: () => Promise<any>, customErrorHandler?: (error: AxiosError) => void) =>
      // eslint-disable-next-line consistent-return
      async () => {
        setValidated(true)

        if (form.valid) {
          return callback().catch(error => {
            form.apiErrors = arrayErrorsToFormError(error?.response?.data)
            const fieldsWithApiError = omit(form.apiErrors, errorKeysToIgnore)

            const parsedError = parseError(error)

            if (
              parsedError.type === 'bad_request' &&
              Object.keys(fieldsWithApiError).length > 0
            ) {
              const flatErrors = flatten<Partial<FormError<{}>>, { [key: string]: any }>(
                fieldsWithApiError,
              )
              Object.keys(flatErrors).forEach(key => {
                if (isEmpty(flatErrors[key])) {
                  delete flatErrors[key]
                }
              })
              const inputErrors = Object.keys(flatErrors)
              scrollToField(inputErrors, error, customErrorHandler)
            } else if (parsedError.type === 'content_too_large') {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>
                    File is too large, please upload a file smaller than 25MB.
                  </StatusPopup.Title>
                  <StatusPopup.Actions>
                    <Button onClick={statusPopup.hide} variant="secondary">
                      Close
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            } else if (error) {
              if (customErrorHandler) {
                customErrorHandler(error)
              } else {
                showFallbackErrorPopup(error)
              }
            }
          })
        }
        scrollToField(Object.keys(flatten(form.errors)))
      },
    [form],
  )

  /** When you need to generate input errors from the front-end */
  const forceErrors = useCallback(
    (errors: FormError<{}>) => {
      setValidated(true)
      form.errors = errors
      const flatErrors = flatten<Partial<FormError<{}>>, { [key: string]: any }>(errors)
      scrollToField(Object.keys(flatErrors))
    },
    [form],
  )

  const contextValue = useMemo(
    () => ({
      validated,
      validate,
      forceErrors,
    }),
    [validated, validate, forceErrors],
  )

  return (
    <FormValidatorContext.Provider value={contextValue}>
      <Box display="contents" ref={wrapperRef}>
        {children}
      </Box>
    </FormValidatorContext.Provider>
  )
}
