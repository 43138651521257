import React, { useMemo } from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetGoals } from '@src/api/performance'
import { ProbationLayoutGoalsJiraDisabled } from '@src/pages/EmployeeProfile/Preview/Performance/Goals/ProbationLayoutGoalsJiraDisabled'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector
}

export const SmartGoals = ({ employee, reviewCycle }: Props) => {
  const filters = useMemo(
    () => [
      {
        filters: [{ name: String(employee.id), id: employee.id }],
        columnName: 'employee__id',
      },
      {
        filters: [{ name: reviewCycle.category, id: reviewCycle.category }],
        columnName: 'category',
      },
    ],
    [employee.id, reviewCycle.id],
  )

  const { data: smartGoals = [], isLoading } = useGetGoals(employee.id, filters)

  const user = useSelector(selectUser)
  const isLineManager = user.id === employee.line_manager.id

  const onAddGoalRedirect = () => {
    navigateTo(
      `${pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
        employeeId: employee.id,
        cycleId: reviewCycle.id,
      })}`,
    )
  }

  return (
    <ProbationLayoutGoalsJiraDisabled
      type={reviewCycle.category}
      goals={smartGoals}
      isLoading={isLoading}
      onAddGoalRedirect={onAddGoalRedirect}
      isLineManager={isLineManager}
    />
  )
}
