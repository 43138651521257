import React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import HiringStageComponent from '@src/pages/Forms/HiringStage/HiringStage'
import {
  formHiringProcessStagesRequest,
  formHiringProcessStagesRoleRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface, HiringStageType } from '@src/interfaces/hiringProccess'

type Props = {
  type: HiringStageType
}

const HiringStageForm = ({ type }: Props) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const backUrl = ROUTES.FORMS.STAGES_BANK

  return (
    <PageWrapper>
      <PageHeader
        title={values?.id ? values.title : 'Create new stage'}
        backUrl={backUrl}
      />
      <HiringStageComponent type={type} />
    </PageWrapper>
  )
}

export const HiringStage = ({ type }: Props) => {
  let api

  switch (type) {
    case 'general':
    case 'default': {
      api = formHiringProcessStagesRequest
      break
    }

    case 'role': {
      api = formHiringProcessStagesRoleRequest
      break
    }

    case 'specialisation': {
      api = formHiringProcessStagesSpecialisationRequest
      break
    }
  }
  return (
    <Form api={api}>
      <HiringStageForm type={type} />
    </Form>
  )
}

export default connect(() => {
  const { tab } = useParams<{ tab: HiringStageType }>()
  return <HiringStage type={tab} />
})
