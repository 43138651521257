import React from 'react'
import { Box, Token } from '@revolut/ui-kit'

import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { CustomerStories } from '@src/pages/Landing/components/CustomerStories'
import { CustomerStoryInterface } from '@src/pages/Landing/components/types'

interface Props {
  story: CustomerStoryInterface
}

export const CustomerStory = ({ story }: Props) => {
  return (
    <Box backgroundColor={Token.color.groupedBackground} py="s-48">
      <ContentBlockLayout>
        <CustomerStories stories={[story]} />
      </ContentBlockLayout>
    </Box>
  )
}
