import React from 'react'
import { Item, Image, Token } from '@revolut/ui-kit'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { GetErrorTitle } from './GetErrorTitle'
import { ParsableError, parseError } from '../parseError'

interface ErrorItemProps {
  error: ParsableError
  /** Title to show instead of parsing it from the error object */
  forcedTitle?: React.ReactNode
  /** Fallback title in case of an unknown unhandled error. Do not use `Something went wrong`, use user friendly message instead, e.g. `Could not archive team` */
  fallbackTitle: string
  fallbackDescription?: string
}

export const ErrorItem = ({
  error,
  forcedTitle,
  fallbackTitle,
  fallbackDescription,
}: ErrorItemProps) => {
  return (
    <Item>
      <Item.Avatar>
        <Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title color={Token.color.greyTone50}>
          {forcedTitle || (
            <GetErrorTitle error={parseError(error)} fallbackTitle={fallbackTitle} />
          )}
        </Item.Title>
        <Item.Description>
          {getMessageFromApiError(error) || fallbackDescription}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
