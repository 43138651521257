import React from 'react'
import { ActionButton, Box, Link } from '@revolut/ui-kit'

import { relationToString } from '@components/ScorecardGeneral/constants'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import {
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
} from '@src/interfaces/performance'
import { getUpdatedTimeReview } from '@src/utils/performance'
import { getReviewer } from './utils'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

type Props = {
  request: PerfReviewRequestFeedbackInterface | RequestFeedbackInterface
  isNewFlow: boolean
  canDelete?: boolean
  onDelete?: () => void
  pendingDeletion?: boolean
}

const Reviewer = ({
  request,
  isNewFlow,
  canDelete,
  onDelete,
  pendingDeletion,
}: Props) => {
  const reviewer = getReviewer(request, isNewFlow)
  const relation = isNewFlow
    ? relationToString(
        (request as PerfReviewRequestFeedbackInterface).reviewer_relation,
        true,
      )
    : undefined

  if (!reviewer) {
    return null
  }

  const renderAvatarSnippet = () => (
    <Link
      href={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: reviewer.id })}
      color="inherit"
    >
      <AvatarSnippet
        firstName={reviewer.first_name}
        lastName={reviewer.last_name}
        fullName={reviewer.full_name}
        text={getUpdatedTimeReview(request.updated_date, request.status)}
        label={relation && `(${relation})`}
        status={request.status}
        avatar={reviewer.avatar}
      />
    </Link>
  )

  if (canDelete) {
    return (
      <Box>
        {renderAvatarSnippet()}
        <ActionButton onClick={onDelete} pending={pendingDeletion} ml="s-56" mt="s-8">
          Delete request
        </ActionButton>
      </Box>
    )
  }

  return renderAvatarSnippet()
}

export default Reviewer
