import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import PublicPageLayout from '@components/CommonSC/PublicPageLayout'
import CareersJobList from '@src/pages/Careers/CareersJobList'
import Header, { PageType } from '@src/pages/Careers/Header'
import Footer from '@src/pages/Careers/Footer'
import { Flex } from '@revolut/ui-kit'
import { CareersPosition } from '@src/pages/Careers/CareersPosition/CareersPosition'
import { useCareersSettings } from '@src/api/recruitment/careers'
import PageLoading from '@components/PageLoading/PageLoading'
import { connect } from 'lape'
import { useCareersAnalytics } from '@src/pages/Careers/hooks/useCareersAnalytics'
import CareersApplication from '@src/pages/Careers/CareersApplication'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'

type LayoutProps = {
  type: PageType
  children: React.ReactNode
}

const Layout = ({ type, children }: LayoutProps) => {
  return (
    <>
      <Header type={type} />
      <PublicPageLayout
        noPadding
        maxWidth="1000px"
        m="auto"
        px="s-8"
        style={{ flexGrow: 1 }}
      >
        {children}
      </PublicPageLayout>
    </>
  )
}

const Router = () => {
  useCareersAnalytics()
  const { data: settings, isLoading, isError } = useCareersSettings()

  if (isLoading) {
    return <PageLoading />
  }

  if (isError || (settings && !settings.publish_career_website)) {
    return <PageNotFoundWidget />
  }

  return (
    <Flex flexDirection="column" minHeight="100%">
      <Switch>
        <Route exact path={PUBLIC_ROUTES.CAREERS.JOB_LIST}>
          <Layout type={PageType.jobList}>
            <CareersJobList />
          </Layout>
        </Route>
        <Route exact path={PUBLIC_ROUTES.CAREERS.APPLICATION}>
          <Layout type={PageType.application}>
            <CareersApplication />
          </Layout>
        </Route>
        <Route exact path={PUBLIC_ROUTES.CAREERS.POSITION}>
          <Layout type={PageType.position}>
            <CareersPosition />
          </Layout>
        </Route>
        <Redirect
          from={PUBLIC_ROUTES.CAREERS.JOB_LIST_DEPRECATED}
          to={PUBLIC_ROUTES.CAREERS.JOB_LIST}
        />
      </Switch>
      <Footer />
    </Flex>
  )
}

export default connect(Router)
