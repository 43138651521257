import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import {
  KPIRoadmapTargetLocationState,
  KPIValueTargetLocationState,
} from '@src/features/FormTabs/Kpi/KPITargets/common/types'
import { pathToUrl } from '@src/utils/router'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { PageNoAccessWidget } from '@src/features/Errors/components/Page/PageNoAccessWidget'

const KPITarget = () => {
  const { kpiId } = useParams<{ kpiId: string }>()
  const { state } = useLocation<
    KPIValueTargetLocationState | KPIRoadmapTargetLocationState
  >()

  if (!state?.kpi) {
    return <InternalRedirect to={pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: kpiId })} />
  }

  return <PageNoAccessWidget />
}

export default KPITarget
