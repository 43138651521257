import React from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import {
  AllowRoleAndSpecialisationOwnersToViewSalariesField,
  EnableDataExportForTalentAndRequisitionSectionsField,
  EnableMultipleLevelsPerSeniorityField,
  EnableSpecialisationSettingsField,
  FunctionSettingsSaveButton,
} from '@src/pages/Settings/Function/common'
import { PageActions } from '@src/components/Page/PageActions'

const StructuresRolesGeneralSettingsForm = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="General settings"
        subtitle="Settings that apply to functions, roles and specialisations."
        backUrl={ROUTES.SETTINGS.STRUCTURES.ROLES.ALL}
      />

      <PageBody>
        <Group>
          <EnableMultipleLevelsPerSeniorityField />
          <EnableDataExportForTalentAndRequisitionSectionsField />
          <EnableSpecialisationSettingsField />
          <AllowRoleAndSpecialisationOwnersToViewSalariesField />
        </Group>
      </PageBody>

      <PageActions>
        <FunctionSettingsSaveButton />
      </PageActions>
    </PageWrapper>
  )
}

export const StructuresRolesGeneralSettings = connect(() => {
  return (
    <Form api={organisationSettings} forceParams={{ id: '1' }}>
      <StructuresRolesGeneralSettingsForm />
    </Form>
  )
})
