import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  chain,
  Flex,
  HStack,
  Separator,
  Spacer,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { connect } from 'lape'

import { useOrdering, useTable } from '@components/Table/hooks'
import { FilterByInterface } from '@src/interfaces/data'
import {
  delegateCalibration,
  getTalentPerformanceItemsWithSeparators,
  talentPerformanceRequests,
  updateCalibratedGrade,
} from '@src/api/talent'
import {
  finalGradeColumn,
  fmGradeColumn,
  fmGradePlainColumn,
  functionalManagerColumn,
  hofGradeColumn,
  hofRatingColumn,
  systemRecommendedGradeColumn,
} from '@src/constants/columns/talent/performance'
import {
  TalentPerformanceBulkGradeUpdateResponse,
  TalentPerformanceStatsInterface,
} from '@src/interfaces/talent/performance'
import { getTalentTypes, TalentType } from '@src/interfaces/talent/talent'
import Loader from '@components/CommonSC/Loader'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import {
  FinalGrade,
  PerformanceRating,
  ReviewCategory,
} from '@src/interfaces/performance'
import { PermissionTypes } from '@src/store/auth/types'
import { FunctionInterface } from '@src/interfaces/functions'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import produce from 'immer'
import SummarySidebar from './SummarySidebar/SummarySidebar'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { CompanyInterface } from '@src/interfaces/company'
import { EmployeeInterface } from '@src/interfaces/employees'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  isActivePerformanceCycle,
  isDepartmentType,
  isFunctionType,
  useGetCycleSelector,
  useShowRanking,
  isCompanyType,
  getCalibrationFlagOptions,
} from '@src/pages/Forms/CommonTalentTab/utils'
import FilterDropdownButtons from '@components/FilterDropdownButtons/FilterDropdownButtons'
import TalentHeaderNIPS from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderNIPS'
import { formatNumber } from '@src/utils/format'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import RequestFeedback, {
  RequestFeedbackSelector,
} from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/RequestFeedback'
import {
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { TableNames } from '@src/constants/table'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { CycleOption, GradeOption, OptionInterface } from '@src/interfaces/selectors'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { BulkGradeAssign } from './BulkGradeAssign'
import TalentCycleSettingsBanner from '@src/pages/Forms/CommonTalentTab/TalentCycleSettingsBanner'
import {
  DelegateCalibrationMoreAction,
  DelegateCalibrationProps,
} from './DelegateCalibration'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { TalentHelpBanner } from './TalentHelpBanner'
import { useQuery } from '@src/utils/queryParamsHooks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { ShowGradeExplainAction } from './GradesExplanationSidebar/GradesExplanationSidebar'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { getCalibrationFilters } from '@src/features/Calibration/getCalibrationFilters'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import isNumber from 'lodash/isNumber'
import {
  AboveLimitsIconWithTooltip,
  TalentHeaderGrades,
} from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderGrades'
import get from 'lodash/get'
import TalentHeaderStats from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderStats'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useGradeMap } from '@src/hooks/useGradeMap'
import { useCalibrationInitialOptions } from './hooks/useCalibrationInitialOptions'
import { getTalentPerfomranceTableRow } from './TalentPerformanceTableRows'
import { getCalibrationPermissions } from './getCalibrationPermissions'
import { CALIBRATION_MORE_INFO } from '@src/constants/externalLinks'
import { TalentUIInterface } from './types'
import { useGetSelectors } from '@src/api/selectors'

interface TalentProps {
  data:
    | DepartmentInterface
    | TeamInterface
    | RoleInterface
    | SpecialisationInterface
    | FunctionInterface
    | CompanyInterface
    | EmployeeInterface
  talentType: TalentType
  navigation?: React.ReactElement
}

interface TalentContentProps extends TalentProps {
  initialCycleId: number
  cycles: CycleOption[]
}

const TalentPerformanceContent = ({
  data,
  talentType = TalentType.Function,
  cycles,
  initialCycleId,
  navigation,
}: TalentContentProps) => {
  const { entity } = useOrgEntity()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: grades = [] } = useGetSelectors<GradeOption>(selectorKeys.grades)
  const [cycleEditable, setCycleEditable] = useState(
    isActivePerformanceCycle(initialCycleId, cycles),
  )
  const [openSidebar, setOpenSidebar] = useState<{
    opened: boolean
    withScroll?: boolean
  }>({ opened: false })
  const [openGradeExplain, setOpenGradeExplain] = useState(false)
  const [openReviewPopup, setOpenReviewPopup] = useState(false)
  const [selectedTalent, setSelectedTalent] =
    useState<{ index: number; talent: TalentUIInterface }>()
  const [tableDataWithoutSeparators, setTableDataWithoutSeparators] = useState<
    TalentUIInterface[]
  >([])
  const [viewRatings, setViewRatings] = useState(false)
  const [viewHistory, setViewHistory] = useState(false)
  const [selectedRows, setSelectedRows] = useState<TalentUIInterface[]>([])
  const [selectControls, setSelectControls] =
    useState<SelectionControls<TalentUIInterface>>()
  const [selectionState, setSelectionState] =
    useState<SelectTableWrapperOnChangeData<TalentUIInterface>>()

  const isEmployeeTalent =
    talentType === TalentType.FunctionalReport || talentType === TalentType.DirectReport
  const isCompanyTalent = isCompanyType(talentType)
  const isOrganisationTalent = isDepartmentType(talentType)
  const isFunctionalTalent = isFunctionType(talentType)

  const showRanking = useShowRanking(talentType)
  const { canDelegate, canChangeGradeSuggestion, canChangeTalentRank } =
    getCalibrationPermissions((data.field_options.permissions || []) as PermissionTypes[])

  const canChangeHoDGrades =
    cycleEditable &&
    [TalentType.Team, TalentType.Department].includes(talentType) &&
    canChangeGradeSuggestion

  const canChangeHoFGrades =
    cycleEditable &&
    [TalentType.Function, TalentType.Role, TalentType.Specialisation].includes(
      talentType,
    ) &&
    canChangeTalentRank

  const disableRowSelection = (row: TalentUIInterface) => {
    if (enabledBulkDelegate) {
      return false
    }
    if (isDepartmentType(talentType)) {
      return row.field_options.read_only.includes(
        PermissionTypes.DepartmentOwnerGradeSuggestion,
      )
    }
    if (showRanking) {
      const hodGrade = row.department_owner_grade_suggestion?.id
      return (
        hodGrade === FinalGrade.Poor ||
        row.field_options.read_only.includes(PermissionTypes.FunctionOwnerGradeSuggestion)
      )
    }
    return row.field_options.read_only.includes(
      PermissionTypes.FunctionOwnerGradeSuggestion,
    )
  }

  const handleSelect = (
    tableWrapperState: SelectTableWrapperOnChangeData<TalentUIInterface>,
  ) => {
    setSelectionState(tableWrapperState)
    if (tableWrapperState && tableWrapperState.selectedRowsData.length) {
      setSelectedRows(tableWrapperState.selectedRowsData)
    } else if (tableWrapperState.isAllSelected) {
      const selected = table.data.filter(
        row =>
          !disableRowSelection(row) &&
          !tableWrapperState.excludeListIds.has(String(row.id)),
      )
      setSelectedRows(selected)
    } else {
      setSelectedRows([])
    }
  }

  const feedbackRequestsContext = useFetcherPerformanceRequests({
    category: ReviewCategory.Performance,
    isNewFlow: true,
    id: selectedTalent ? selectedTalent.talent.employee.id : undefined,
    performanceCycle:
      openReviewPopup && selectedTalent
        ? {
            id: selectedTalent.talent.cycle?.id,
            category: ReviewCycleCategory.Performance,
          }
        : undefined,
  })
  const cycleSelector = useGetCycleSelector(cycles)

  const TalentTypes = getTalentTypes(data.id, true)

  const [enabledBulkAssign, setEnabledBulkAssign] = useState(false)
  const [enabledBulkDelegate, setEnabledBulkDelegate] = useState(false)
  const [showRankingColumn, setShowRankingColumn] = useState(false)
  const [orderingMode, setOrderingMode] = useState<boolean>(
    showRanking && !!canChangeHoFGrades,
  )

  const { filter, nipsGraphRequest } = TalentTypes[talentType]

  // TODO: this is temporary, get rid of it once BE implements consistent filter
  if (filter && talentType === TalentType.FunctionalReport) {
    filter.columnName = 'functional_manager__id'
  }

  const { initialFilters, initialSortBy } = useCalibrationInitialOptions({
    talentType,
    talentId: data.id,
    cycleId: initialCycleId,
  })
  const table = useTable<TalentUIInterface, TalentPerformanceStatsInterface>(
    {
      ...talentPerformanceRequests,
      getItems: showRanking
        ? getTalentPerformanceItemsWithSeparators
        : talentPerformanceRequests.getItems,
    },
    initialFilters,
    initialSortBy,
  )

  useEffect(() => {
    setTableDataWithoutSeparators(
      table.data.filter(row => (isNumber(row.id) ? row.id > 0 : true)),
    )
  }, [table.data])

  const [queuedSidebarTalent, setQueuedSidebarTalent] = useState<number | null>(null)

  const seniorityOptions = useFetchOptions<OptionInterface>(
    selectorKeys.seniority,
  ).options.map(({ value }) => value)

  useEffect(() => {
    if (queuedSidebarTalent && tableDataWithoutSeparators[queuedSidebarTalent]) {
      setSelectedTalent({
        talent: tableDataWithoutSeparators[queuedSidebarTalent],
        index: queuedSidebarTalent,
      })
    }
  }, [tableDataWithoutSeparators])

  const getCanBulkCalibrate = (
    type: TalentType,
    hasHodPermissions: boolean,
    hasHofPermissions: boolean,
  ) => {
    const isBulkEligibleType = [
      TalentType.Department,
      TalentType.Function,
      TalentType.Specialisation,
    ].includes(type)

    const hasBulkCalibrationPermissions = isDepartmentType(type)
      ? hasHodPermissions
      : hasHofPermissions

    return isBulkEligibleType && hasBulkCalibrationPermissions
  }

  const allowBulkCalibration =
    getCanBulkCalibrate(talentType, !!canChangeHoDGrades, !!canChangeHoFGrades) &&
    (!showRanking || enabledBulkAssign)

  const showBulkAssign = showRanking && canChangeHoFGrades

  const onAfterChange = async (
    requestData: UpdateOrderingInterface,
    originalData: TalentUIInterface[],
    updatedData: TalentUIInterface[],
  ) => {
    const id = requestData.item_object_ids[0]
    const afterRowIdx = originalData.findIndex(
      item => item.id === requestData.target_position_object_id,
    )
    let beforeRowItem = originalData[afterRowIdx - 1]

    if (afterRowIdx === -1) {
      beforeRowItem = originalData[originalData.length - 1]
    }

    const requestedGrade = beforeRowItem.function_owner_grade_suggestion?.id
    const currentGrade = updatedData.find(item => item.id === id)
      ?.function_owner_grade_suggestion?.id

    if (id && requestedGrade && requestedGrade !== currentGrade) {
      await changeHoFGrade(Number(id), requestedGrade)
    }
  }

  const ignoreChangeOrder = (
    sourceIds: (number | string)[],
    activeIndex: number | null,
    targetIndex: number,
  ) => {
    // if moving separator string
    if (isNumber(sourceIds[0]) && sourceIds[0] < 0) {
      return true
    }

    if (!activeIndex) {
      return false
    }

    const movedItem = table.data[activeIndex]
    const currentGrade = movedItem.function_owner_grade_suggestion?.id
    const selectedItemIndex = targetIndex > activeIndex ? targetIndex : targetIndex - 1
    const selectedGrade =
      table.data[selectedItemIndex].function_owner_grade_suggestion?.id
    const changedGrade = currentGrade !== selectedGrade

    const hodGrade = movedItem.department_owner_grade_suggestion?.id
    const hodSelectedPoor = hodGrade === FinalGrade.Poor
    const movingOutsidePoor = hodSelectedPoor && selectedGrade !== FinalGrade.Poor
    const movingToPoor =
      !!hodGrade && hodGrade !== FinalGrade.Poor && selectedGrade === FinalGrade.Poor

    // !changedGrade - if user moved line within the same grade group, this means that grade didn't change, so we should ignore further calculation
    // movingOutsidePoor - if HoD calibrated with Unsatisfactory, HoF shouldn't be able to calibrate with anything except Unsatisfactory
    // movingToPoor - if HoD calibrated with grade other than Unsatisfactory, HoF shouldn't be able to calibrate with Unsatisfactory
    return !changedGrade || movingOutsidePoor || movingToPoor
  }

  const { selectedOrderingIds, onChangeOrder } = useOrdering(
    table.data,
    table.setData,
    table.count,
    table.refresh,
    onAfterChange,
    ignoreChangeOrder,
  )

  const updateAfterChangingGrade = (id: number, responseData: TalentUIInterface) => {
    if (responseData) {
      table.updateRows(
        row => row.id === id,
        () => ({ ...responseData, isPendingGrade: false }),
      )

      if (selectedTalent && selectedTalent.talent.id === id) {
        setSelectedTalent({
          talent: responseData,
          index: selectedTalent.index,
        })
      }
    }
  }

  const changeHoFRating = async (id: number, rating: PerformanceRating) => {
    table.updateRows(
      row => row.id === id,
      row => ({ ...row, function_owner_rating_label: rating }),
    )

    try {
      const response = await updateCalibratedGrade(
        id,
        {
          function_owner_rating_label: rating,
        },
        getCalibrationFilters(entity),
      )
      updateAfterChangingGrade(id, response.data)
    } catch (e) {
      table.refresh()
    }
  }

  const getChangeGradeHandler =
    (field: 'department_owner_grade_suggestion' | 'function_owner_grade_suggestion') =>
    async (id: number, grade: FinalGrade, justification?: string) => {
      table.updateRows(
        row => row.id === id,
        row => ({
          ...row,
          [field]: grade,
          ...(field === 'department_owner_grade_suggestion'
            ? { department_grade_justification: justification }
            : { function_grade_justification: justification }),
          isPendingGrade: true,
        }),
      )

      try {
        const response = await updateCalibratedGrade(
          id,
          {
            [field]: grade,
            ...(field === 'department_owner_grade_suggestion'
              ? { department_grade_justification: justification }
              : { function_grade_justification: justification }),
          },
          getCalibrationFilters(entity),
        )
        updateAfterChangingGrade(id, response.data)
        table.refreshStats()
      } catch (e) {
        table.refresh()
      } finally {
        table.updateRows(
          row => row.id === id,
          row => ({ ...row, isPendingGrade: false }),
        )
      }
    }

  const changeHoDGrade = getChangeGradeHandler('department_owner_grade_suggestion')

  const changeHoFGrade = getChangeGradeHandler('function_owner_grade_suggestion')

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const NIPS = table.stats?.nips ? +formatNumber(table.stats?.nips * 100, 1) : 0

  const onBulkGradeAssigned = (results: TalentPerformanceBulkGradeUpdateResponse[]) => {
    if (showRanking) {
      table.refresh()
    } else {
      const updatedData = produce(table.data, draft => {
        results.forEach(updated => {
          const tableRow = draft.find(row => row.id === updated.id)
          if (tableRow) {
            tableRow.department_owner_grade_suggestion =
              updated.department_owner_grade_suggestion
            tableRow.function_owner_grade_suggestion =
              updated.function_owner_grade_suggestion
            tableRow.display_grade = updated.display_grade
          }
        })
      })

      const rowsToKeepSelected =
        results.length === selectedRows.length
          ? []
          : selectedRows.filter(
              selRow => !results.find(resRow => resRow.id === selRow.id),
            )

      selectControls && selectControls.setSelection(rowsToKeepSelected)
      table.setData(updatedData)
    }
  }

  const onDelegate: DelegateCalibrationProps['delegate'] = async ({
    calibratorId,
    talents,
  }) => {
    const calibratorKey = isDepartmentType(talentType)
      ? 'department_grade_calibrator'
      : 'function_grade_calibrator'

    const {
      data: { results },
    } = await delegateCalibration(
      {
        grades: talents && talents.length ? talents.map(talent => talent.id) : null,
        include_all: !!selectionState?.isAllSelected,
        exclude_ids: selectionState?.isAllSelected
          ? Array.from(selectionState?.excludeListIds).map(Number)
          : null,
        [calibratorKey]: { id: calibratorId },
      },
      [...table.filterBy, ...getCalibrationFilters(entity)],
    )

    const updatedData = produce(table.data, draft => {
      results.forEach(updated => {
        const tableRow = draft.find(row => row.id === updated.id)
        if (tableRow) {
          tableRow.department_grade_calibrator = updated.department_grade_calibrator
          tableRow.function_grade_calibrator = updated.function_grade_calibrator
        }
      })
    })
    table.setData(updatedData)
    selectControls?.setSelection([])
  }

  const handleCycleChange = (selectedCycle: OptionInterface) => {
    setOpenSidebar({ opened: false, withScroll: false })
    setSelectedTalent(undefined)
    setCycleEditable(isActivePerformanceCycle(selectedCycle.id, cycles))
  }

  const onRowClick = (talent: TalentUIInterface, scrollSidebar: boolean = false) => {
    table.setData(
      produce(table.data, draft => {
        const found = draft.find(item => item.id === talent.id)
        if (found) {
          found.isSelected = true
        }
      }),
    )
    const index = tableDataWithoutSeparators.findIndex(
      rowData => rowData.id === talent.id,
    )
    setSelectedTalent({ talent, index })
    setOpenGradeExplain(false)
    setOpenSidebar({ opened: true, withScroll: scrollSidebar })
  }

  const filteredFilters = useMemo(() => {
    return table.filterBy
      ?.find((filterBy: FilterByInterface) => filterBy.columnName === 'cycle__id')
      ?.filters.filter(option => option.id !== '' && option.name !== '')
  }, [table.filterBy])

  const selectedCycle = useMemo(() => {
    return cycles.find(cycle => cycle.cycle_id === Number(filteredFilters?.[0].id))
  }, [filteredFilters, cycles])

  const { gradesMap, gradeOptions } = useGradeMap(selectedCycle)
  const calibrationSelector =
    selectedCycle?.grade_labels_version === 1
      ? selectorKeys.performance_grades
      : selectorKeys.calibration_grades

  const row = useMemo(() => {
    return getTalentPerfomranceTableRow({
      viewHistory,
      talentType,
      changeHoDGrade,
      changeHoFGrade,
      changeHoFRating,
      canChangeHoFGrades,
      orderingMode,
      viewRatings,
      performanceSettings,
      disableRowSelection,
      allowSelect: allowBulkCalibration || enabledBulkDelegate,
      gradeOptions,
      showRankingColumn,
      showRanking,
      statsWeights: table.stats?.weights || null,
      calibrationSelector,
      gradesMap,
      selectedCycle,
      onBarRaiserClick: onRowClick,
    })
  }, [
    performanceSettings,
    viewHistory,
    talentType,
    canChangeHoFGrades,
    orderingMode,
    viewRatings,
    allowBulkCalibration,
    enabledBulkDelegate,
    gradeOptions,
    table.stats,
    gradesMap,
    selectedCycle,
  ])

  const renderSeparatorContent = !showRanking
    ? undefined
    : (rowData: TalentUIInterface, index: number) => {
        const prevRowData = table.data[index - 1]
        if (
          isOrganisationTalent &&
          rowData.department_owner_grade_suggestion?.id ===
            prevRowData?.department_owner_grade_suggestion?.id
        ) {
          return null
        }
        if (
          isFunctionalTalent &&
          rowData.function_owner_grade_suggestion?.id ===
            prevRowData?.function_owner_grade_suggestion?.id
        ) {
          return null
        }

        const gradeFieldName = isFunctionalTalent
          ? 'function_owner_grade_suggestion'
          : 'department_owner_grade_suggestion'

        const statsGradeId = get(rowData, gradeFieldName)?.id || ''
        const count = get(table.stats, statsGradeId) || table.stats?.unassigned
        const gradeId = isFunctionalTalent
          ? rowData.function_owner_grade_suggestion?.id
          : rowData.department_owner_grade_suggestion?.id
        const aboveLimit = !!get(table.stats?.above_limits, statsGradeId)

        return (
          <Flex
            pl="s-16"
            alignItems="center"
            color={Token.color.greyTone50}
            borderLeft={`2px solid ${Token.color.grey50}`}
            style={{ fontWeight: 500 }}
            gap="s-8"
          >
            {chain(gradeId ? gradesMap[gradeId] : 'Unassigned', count)}
            {aboveLimit && <AboveLimitsIconWithTooltip size={15} />}
          </Flex>
        )
      }

  useEffect(() => {
    table.data.forEach(t => {
      const selectedTalentId = selectedTalent?.talent.id
      t.isSelected = selectedTalentId === t.id
    })
  }, [selectedTalent, table.data])
  return (
    <VStack data-testid="performance-talent-content">
      <Table.Widget>
        <Table.Widget.Info>
          {talentType === TalentType.Company && (
            <>
              {navigation}
              <Box height="s-48">
                <Separator orientation="vertical" />
              </Box>
            </>
          )}

          <HStack gap="s-16" style={{ flexGrow: 1 }}>
            {nipsGraphRequest && (
              <TalentHeaderNIPS
                id={data.id}
                nips={NIPS}
                gradesOptions={grades}
                rightMargin={false}
                nipsGraphRequest={nipsGraphRequest}
              />
            )}
            <TalentHeaderStats
              isLoading={table.loading}
              lmCompleted={table.stats?.line_manager_completed_review_ratio}
              fmCompleted={table.stats?.functional_manager_completed_review_ratio}
            />
            <Spacer style={{ flexGrow: 1 }} />
            <TalentHeaderGrades gradesMap={gradesMap} stats={table.stats} />
          </HStack>
        </Table.Widget.Info>
        <Table.Widget.Status>
          {!(isEmployeeTalent || isCompanyTalent) && !isOrganisationTalent && (
            <TalentHelpBanner type={talentType} />
          )}
        </Table.Widget.Status>
        <Table.Widget.Filters>
          {!isCompanyTalent && navigation}
          <CycleFilter
            columnName="cycle__id"
            type={CycleFilterType.Filter}
            filter={table.filterBy}
            filterInputType={FilterSelectType.SingleSelect}
            onFilterChange={table.onFilterChange}
            onChange={handleCycleChange}
            selector={cycleSelector}
          />
          <FilterButtonCheckboxSelect
            label="Seniority"
            searchable
            width={300}
            options={seniorityOptions || []}
            value={
              table.filterBy.find(item => item.columnName === 'seniority__id')?.filters ||
              []
            }
            onChange={selection => {
              table.onFilterChange({
                filters: selection || [],
                columnName: 'seniority__id',
              })
            }}
          />
          <FilterDropdownButtons
            placement="bottom-start"
            items={getCalibrationFlagOptions(table.stats)}
            filters={table.filterBy}
            disabled={table.loading}
            onChange={filters => {
              table.onFilterChange(filters)
            }}
            label="Calibration flags"
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar maxCount={2}>
            <Table.ColumnsSettingsButton />
            {canDelegate && !showBulkAssign && (
              <SwitchButton
                checked={enabledBulkDelegate}
                onClick={() => {
                  setOrderingMode(!orderingMode)
                  setEnabledBulkDelegate(!enabledBulkDelegate)
                }}
              >
                Bulk delegate
              </SwitchButton>
            )}
            <MoreInfoButton href={CALIBRATION_MORE_INFO} />
            <HideIfCommercial>
              <ShowGradeExplainAction
                open={openGradeExplain}
                onOpen={() => {
                  setOpenGradeExplain(true)
                  setSelectedTalent(undefined)
                  setOpenSidebar({ opened: false, withScroll: false })
                }}
                onClose={() => setOpenGradeExplain(false)}
              />
            </HideIfCommercial>
            {showBulkAssign && (
              <SwitchButton
                checked={enabledBulkAssign}
                onClick={() => {
                  setOrderingMode(!orderingMode)
                  setEnabledBulkAssign(!enabledBulkAssign)
                }}
              >
                Bulk assign
              </SwitchButton>
            )}

            {allowBulkCalibration && (
              <BulkGradeAssign
                talents={selectedRows}
                talentType={talentType}
                onSuccess={onBulkGradeAssigned}
                selector={calibrationSelector}
              />
            )}
            {canDelegate && (allowBulkCalibration || enabledBulkDelegate) && (
              <DelegateCalibrationMoreAction
                disabled={!selectionState?.isAllSelected && !selectedRows.length}
                talents={selectionState?.isAllSelected ? undefined : selectedRows}
                delegate={onDelegate}
              />
            )}
            {showRanking && (
              <SwitchButton
                checked={showRankingColumn}
                onClick={() => {
                  setShowRankingColumn(!showRankingColumn)
                }}
              >
                Show ranking
              </SwitchButton>
            )}
            {!showRanking && (
              <>
                <SwitchButton
                  checked={viewRatings}
                  onClick={() => {
                    if (orderingMode) {
                      setOrderingMode(false)
                    }
                    setViewRatings(!viewRatings)
                  }}
                >
                  View ratings
                </SwitchButton>
                <SwitchButton
                  tooltipContent={
                    viewHistory
                      ? 'Hide grade history charts'
                      : 'View grade history charts'
                  }
                  checked={viewHistory}
                  onClick={() => {
                    setViewHistory(!viewHistory)
                  }}
                >
                  View history
                </SwitchButton>
              </>
            )}
            <AllowedExportMenu
              request={talentPerformanceRequests.getExport}
              filterQuery={filterQuery}
              fileName="Talent performance"
              type={talentType}
            />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled={allowBulkCalibration || enabledBulkDelegate}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            tableData={table.data}
            onChange={handleSelect}
            onControlsLoaded={setSelectControls}
          >
            <AdjustableTable<TalentUIInterface, TalentPerformanceStatsInterface>
              name={TableNames.TalentPerformance}
              useWindowScroll
              hideCount
              dataType="Employee"
              hiddenCells={{
                [functionalManagerColumn.idPoint]:
                  !performanceSettings?.enable_functional_management,
                [hofRatingColumn.idPoint]:
                  !performanceSettings?.enable_functional_management,
                [hofGradeColumn.idPoint]:
                  !performanceSettings?.enable_functional_management,
                [fmGradeColumn.idPoint]:
                  !performanceSettings?.enable_functional_management,
                [fmGradePlainColumn.idPoint]:
                  !performanceSettings?.enable_functional_management,
                [systemRecommendedGradeColumn.idPoint]: showRanking,
                [finalGradeColumn.idPoint]: showRanking,
                'department_grade_calibrator.id': !isDepartmentType(talentType),
              }}
              row={row}
              {...table}
              tableSettings={{
                hidden: [
                  'Department',
                  'Delivery (LM)',
                  'Skills (FM)',
                  'Bar raiser',
                  'System grade',
                ],
                visible: [],
              }}
              emptyState={<EmptyTableRaw title="No employees" />}
              orderingMode={orderingMode}
              disabledSorting={showRanking}
              renderSeparatorContent={renderSeparatorContent}
              selectedOrderingIds={selectedOrderingIds}
              onChangeOrder={onChangeOrder}
              onRowClick={talent => onRowClick(talent)}
              selectedCount={
                selectionState?.isAllSelected
                  ? table.count - selectionState.excludeListIds.size
                  : selectedRows.length
              }
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>

      {openSidebar.opened && !!selectedTalent ? (
        <SummarySidebar
          isOpen
          talentType={talentType}
          canChangeGrade={
            isDepartmentType(talentType) ? !!canChangeHoDGrades : !!canChangeHoFGrades
          }
          canDelegate={!!canDelegate}
          selector={calibrationSelector}
          onClose={() => {
            setOpenSidebar({ opened: false, withScroll: false })
            setSelectedTalent(undefined)
          }}
          talent={selectedTalent.talent}
          currentIndex={selectedTalent.index + 1}
          total={tableDataWithoutSeparators.length}
          onReviewRequested={
            cycleEditable
              ? () => {
                  setOpenReviewPopup(true)
                }
              : undefined
          }
          onGradeSelected={async option => {
            const grade = option?.id === FinalGrade.Empty ? FinalGrade.None : option?.id

            if (grade !== undefined && isDepartmentType(talentType)) {
              await changeHoDGrade(selectedTalent.talent.id, grade)
            } else if (grade !== undefined && isFunctionType(talentType)) {
              await changeHoFGrade(selectedTalent.talent.id, grade)
            }
          }}
          onPageChanaged={page => {
            setOpenSidebar({ opened: openSidebar.opened, withScroll: false })
            const index = page - 1
            const needToLoadNextPage =
              tableDataWithoutSeparators.length < table.count &&
              tableDataWithoutSeparators.length - index <= 10 &&
              !table.nextPageLoading

            if (needToLoadNextPage) {
              table.fetchNextPage()
            }

            if (tableDataWithoutSeparators[index]) {
              setSelectedTalent({
                talent: tableDataWithoutSeparators[index],
                index,
              })
            } else {
              setQueuedSidebarTalent(index)
            }
          }}
          onDelegate={cycleEditable ? onDelegate : undefined}
          scrollToBarRaiser={openSidebar.withScroll}
          gradesMap={gradesMap}
        />
      ) : null}
      {openReviewPopup && selectedTalent && (
        <RequestFeedback
          type="popup"
          canRequest
          performanceCycle={selectedTalent.talent.cycle as RequestFeedbackSelector}
          isNewFlow
          category={ReviewCategory.Performance}
          onClose={() => setOpenReviewPopup(false)}
          id={selectedTalent.talent.employee?.id}
          onAddRequest={() => {
            feedbackRequestsContext.refetch()
          }}
          requests={(feedbackRequestsContext?.data as NewFlowRequestsResponse)?.results}
        />
      )}
    </VStack>
  )
}

const LapeTalentPerformanceContent = connect(TalentPerformanceContent)

const TalentPerformance = (props: TalentProps) => {
  const { cycles, loading } = useSelectedPerformanceCycle()
  const { query } = useQuery()
  const selectedCycleId = query.cycle__id

  if (loading) {
    return <Loader />
  }
  const initialCycle = cycles.find(cycle =>
    selectedCycleId
      ? String(cycle.cycle_id) === selectedCycleId
      : cycle.performance_reviews_selected_cycle,
  )

  if (!initialCycle?.cycle_id) {
    return <TalentCycleSettingsBanner />
  }

  return (
    <LapeTalentPerformanceContent
      {...props}
      cycles={cycles}
      initialCycleId={initialCycle.cycle_id}
    />
  )
}

export default TalentPerformance
