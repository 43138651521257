import React, { ComponentProps } from 'react'
import { DataPoint, Highlights, TextSkeleton } from '@revolut/ui-kit'
import { OverallProgress } from './OverallProgress'
import { useParams, useRouteMatch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useGetOrganisationalUnitStats } from '@src/api/goals'
import { useQuery } from '@src/utils/queryParamsHooks'
import pluralize from 'pluralize'
import { isNumber } from 'lodash'

interface Props extends ComponentProps<typeof OverallProgress> {}

export const EmployeeProgressNavigation = ({ ...overallProgressProps }: Props) => {
  const { id } = useParams<{ id: string }>()
  const { query } = useQuery<{ cycle__id: string }>()
  const { data, isLoading } = useGetOrganisationalUnitStats({
    employeeId: id,
    cycleId: query.cycle__id,
  })
  return (
    <Highlights variant="tabs">
      <OverallProgress
        selected={Boolean(
          useRouteMatch({
            path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
            exact: true,
          }),
        )}
        navigateTo={pathToUrl(
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
          {
            id,
          },
        )}
        label="Performance goals"
        {...overallProgressProps}
      />
      <DataPoint
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ORGANISATIONAL, {
          id,
        })}
        aria-selected={Boolean(
          useRouteMatch({
            path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.ORGANISATIONAL,
            exact: true,
          }),
        )}
      >
        <DataPoint.Value>
          {isLoading ? (
            <TextSkeleton />
          ) : isNumber(data?.total_goals) ? (
            pluralize('goal', data?.total_goals, true)
          ) : (
            'n/a'
          )}
        </DataPoint.Value>
        <DataPoint.Label>Organisational goals</DataPoint.Label>
      </DataPoint>
    </Highlights>
  )
}
