import React, { useState } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import {
  Subheader,
  Status,
  Sticky,
  ProgressSteps,
  ProgressStep,
  Widget,
  Box,
  Tag,
  VStack,
  HStack,
  Text,
  Token,
  useStatusPopup,
  StatusPopup,
  Avatar,
} from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { useGetTicket, getTicketsCommentsAPI, uploadTicketFiles } from '@src/api/tickets'
import { useParams } from 'react-router'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import styled from 'styled-components'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { TicketFullInterface, ticketStatuses } from '@src/interfaces/tickets'
import { formatDateTime } from '@src/utils/format'
import { statusColorMap } from '@src/pages/Tickets/common'
import { TicketFeedback } from '@src/pages/Tickets/Ticket/TicketFeedback'
import { TicketFeedbackRatePopup } from '@src/pages/Tickets/Ticket/TicketFeedbackRatePopup'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { TicketFile } from '@src/pages/Tickets/Ticket/TicketFile'
import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import { isArray } from 'lodash'
import { formatSnakeCase } from '@src/utils/string'

const StyledSticky = styled(Sticky)`
  max-height: calc(100vh - 80px);
  overflow: scroll;
  scrollbar-width: none;
`

export const Ticket = () => {
  const { id } = useParams<{ id: string }>()
  const { data: ticket, refetch } = useGetTicket(id)
  const [importPending, setImportPending] = useState(false)
  const [ratePopupOpen, setRatePopupOpen] = useState(false)
  const statusPopup = useStatusPopup()

  const commentsApi = getTicketsCommentsAPI(Number(id))

  if (!ticket) {
    return null
  }

  const handleUpload = async (files: File[]) => {
    setImportPending(true)
    const filesResults = await uploadTicketFiles(id, files)
    if (filesResults.some(file => file.status === 'rejected')) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to upload file</StatusPopup.Title>
        </StatusPopup>,
      )
    }
    await refetch()
    setImportPending(false)
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={ticket.title}
          subtitle={
            <Status
              useIcon="16/StatusActive"
              iconColor={statusColorMap[ticket.current_status.status]}
              color={statusColorMap[ticket.current_status.status]}
            >
              {formatSnakeCase(ticket.current_status.status)}
            </Status>
          }
          backUrl={ROUTES.TICKETS.ALL}
        />

        <TwoColumnsLayout
          left={
            <>
              <TicketFeedback
                ticket={ticket}
                refetch={refetch}
                openRatePopup={() => setRatePopupOpen(true)}
              />
              <Widget>
                <FormPreview<TicketFullInterface>
                  data={ticket}
                  customHeader={() => (
                    <Box px="s-16">
                      <Subheader variant="nested">
                        <Subheader.Title>Details</Subheader.Title>
                      </Subheader>
                    </Box>
                  )}
                >
                  <FormPreview.Item field="url" title="URL" />
                  <FormPreview.Details field="text" title="Description" />
                  <FormPreview.Details
                    field="steps_to_reproduce"
                    title="Steps to reproduce"
                  />
                  <FormPreview.Item field="product.name" title="Topic" />

                  {ticket.files && ticket.files.length > 0 ? (
                    <FormPreview.Details<TicketFullInterface>
                      title="Attachments"
                      field="files"
                      insert={({ files }) =>
                        files.map(file => (
                          <TicketFile
                            ticket={ticket}
                            file={file}
                            key={file.id}
                            onDelete={refetch}
                          />
                        ))
                      }
                    />
                  ) : null}

                  {ticket.employee_first_name || ticket.employee_last_name ? (
                    <FormPreview.Item<TicketFullInterface>
                      field="employee_first_name"
                      title="Employee"
                      insert={data => (
                        <HStack gap="s-8">
                          {data.employee_avatar ? (
                            <Avatar size={24} image={data.employee_avatar} />
                          ) : null}
                          <Text>
                            {data.employee_first_name} {data.employee_last_name}
                          </Text>
                        </HStack>
                      )}
                    />
                  ) : null}
                  {ticket.employee_uuid ? (
                    <FormPreview.Item field="employee_uuid" title="Employee UUID" />
                  ) : null}
                  {ticket.tenant_company_name ? (
                    <FormPreview.Item
                      field="tenant_company_name"
                      title="Tenant company name"
                    />
                  ) : null}
                  {ticket.tenant_uuid ? (
                    <FormPreview.Item field="tenant_uuid" title="Tenant UUID" />
                  ) : null}

                  {!!ticket.jira_url && !!ticket.jira_id ? (
                    <FormPreview.Item
                      title="Jira ticket link"
                      insert={() => (
                        <>
                          <Tag
                            use="a"
                            target="_blank"
                            useIcon="Jira|image"
                            variant="faded"
                            href={ticket.jira_url!}
                          >
                            {ticket.jira_id}
                          </Tag>
                        </>
                      )}
                    />
                  ) : null}
                </FormPreview>
              </Widget>

              <FileUploader
                attachButtonText="Click to upload files"
                loading={importPending}
                onChange={files => {
                  if (isArray(files)) {
                    handleUpload(files)
                  }
                }}
                accept={
                  'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv, application/csv, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/quicktime, video/mp4'
                }
                uploadInstructions={
                  <VStack align="center" mt="s-4">
                    <Text variant="small" color={Token.color.greyTone50}>
                      Allowed file-types: .pdf, .jpeg, .jpg, .png, .xlsx, .csv, .docx,
                      .mov, .mp4
                    </Text>
                    <Text variant="small" color={Token.color.greyTone50}>
                      Max size 20MB
                    </Text>
                  </VStack>
                }
                multiple
              />

              <CommentsSection api={commentsApi} disableTodolistFeature />
            </>
          }
          right={
            <StyledSticky top={70}>
              <Widget>
                <Box px="s-16">
                  <Subheader variant="nested">
                    <Subheader.Title>Report status</Subheader.Title>
                  </Subheader>
                </Box>

                <ProgressSteps variant="vertical-compact">
                  {ticketStatuses.map(status => {
                    const ticketStatus = ticket.status_list.find(s => s.status === status)
                    return (
                      <ProgressStep
                        state={
                          ticket?.current_status.status === ticketStatus?.status &&
                          ticketStatus?.status !== 'closed'
                            ? 'pending'
                            : ticketStatus
                            ? 'done'
                            : undefined
                        }
                        key={status}
                        indicatorColor={
                          ticket?.current_status.status === ticketStatus?.status &&
                          ticketStatus?.sub_status
                            ? 'orange'
                            : ticketStatus
                            ? 'blue'
                            : undefined
                        }
                      >
                        <ProgressStep.Title>{formatSnakeCase(status)}</ProgressStep.Title>
                        <ProgressStep.Description>
                          {ticketStatus
                            ? formatDateTime(ticketStatus.creation_date_time)
                            : '-'}
                          {ticketStatus && ticketStatus?.sub_status ? (
                            <Text color="orange" use="div">
                              {formatSnakeCase(ticketStatus.sub_status)}
                            </Text>
                          ) : null}
                        </ProgressStep.Description>
                      </ProgressStep>
                    )
                  })}
                </ProgressSteps>
              </Widget>
            </StyledSticky>
          }
          leftMaxWidth={Token.breakpoint.md}
          rightMaxWidth={350}
        />
      </PageWrapper>

      <TicketFeedbackRatePopup
        ticketId={ticket.id}
        onClose={() => setRatePopupOpen(false)}
        isOpen={ratePopupOpen}
      />
    </>
  )
}
