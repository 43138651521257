import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { Group, InputGroup, ActionWidget, Link, List } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JiraPreferencesInterface } from '@src/interfaces/integrations'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { InfoOutline, Dot } from '@revolut/icons'
import { JIRA_HOW_TO, JIRA_TOKEN } from '@src/constants/externalLinks'

export const JiraForm = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { values } = useLapeContext<JiraPreferencesInterface>()

  const disableEdit = !permissions.includes(PermissionTypes.ChangeJiraIntegration)

  return (
    <PageWrapper>
      <PageHeader
        title="Jira integration settings"
        subtitle="Setup Jira"
        backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
      />
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Jira integration',
                description: 'Enable integration with Jira for all features.',
              }}
              name="enabled"
              disabled={disableEdit}
            />
          </Group>
          {values.enabled ? (
            <>
              <NewStepperTitle title="Settings" />
              <InputGroup>
                <ActionWidget>
                  <ActionWidget.Title>How to setup</ActionWidget.Title>
                  <ActionWidget.Avatar>
                    <InfoOutline color="orange" />
                  </ActionWidget.Avatar>
                  <ActionWidget.Content>
                    <List variant="compact">
                      <List.Item useIcon={Dot} color="grey-tone-50">
                        Log in to{' '}
                        <Link target="_blank" href={JIRA_TOKEN}>
                          {JIRA_TOKEN}
                        </Link>
                      </List.Item>
                      <List.Item useIcon={Dot} color="grey-tone-50">
                        Click <b>Create API token</b>.
                      </List.Item>
                      <List.Item useIcon={Dot} color="grey-tone-50">
                        From the dialog that appears, enter a <b>Label</b> for your token
                        and click <b>Create</b>
                      </List.Item>
                      <List.Item useIcon={Dot} color="grey-tone-50">
                        Click <b>Copy</b>, then paste the token to <b>API key</b> field
                      </List.Item>
                    </List>
                    <br />
                    More information can be found{' '}
                    <Link target="_blank" href={JIRA_HOW_TO}>
                      here
                    </Link>
                  </ActionWidget.Content>
                </ActionWidget>
                <LapeNewInput
                  required
                  name="server"
                  label="Server"
                  disabled={disableEdit}
                  message={'URL to Jira, e.g. https://mysite.atlassian.net'}
                />
                <LapeNewInput
                  name="username"
                  type="password"
                  label="Username"
                  required
                  disabled={disableEdit}
                  message={'Jira username of the user who created API key'}
                />
                <LapeNewInput
                  name="api_key"
                  type="password"
                  label="API key"
                  required
                  disabled={disableEdit}
                />
              </InputGroup>
              <HideIfCommercial>
                <NewStepperTitle title="HR tickets integration" />
                <InputGroup>
                  <LapeNewSwitch
                    itemTypeProps={{
                      title: 'Enable HR tickets integration',
                      description: 'Add ability to create HR tickets.',
                    }}
                    name="create_hr_tickets_enabled"
                    disabled={disableEdit}
                  />
                  {values.create_hr_tickets_enabled ? (
                    <>
                      <LapeNewInput
                        required
                        name="hr_project_key"
                        label="HR project key"
                        message="Used to create tickets in HR Jira board"
                        disabled={disableEdit}
                      />
                      <LapeNewInput
                        required
                        name="hrsd_project_key"
                        label="HR service desk project key"
                        message="Used to create service desk tickets for HR"
                        disabled={disableEdit}
                      />
                    </>
                  ) : null}
                </InputGroup>
              </HideIfCommercial>
            </>
          ) : null}
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.JIRA_INTEGRATION_PREFERENCES)
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}
