import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { DataPoint, Flex, Highlights } from '@revolut/ui-kit'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { DataPointLink } from '@components/StatNavigation/StatNavigation'
import Table from '@src/components/TableV2/Table'

interface ChangelogContentProps {
  tabs: TabBarTableNavigationInterface[]
}

export const ChangelogContent = ({ tabs }: ChangelogContentProps) => {
  return (
    <Flex flexDirection="column" minHeight={0}>
      <Switch>
        <Table.Widget>
          <Table.Widget.Info>
            <Highlights variant="tabs">
              {tabs.map(tab => (
                <DataPointLink
                  key={String(tab.path)}
                  path={String(tab.path)}
                  to={String(tab.to)}
                >
                  <DataPoint.Value>{tab.quickSummary}</DataPoint.Value>
                  <DataPoint.Label>{tab.title}</DataPoint.Label>
                </DataPointLink>
              ))}
            </Highlights>
          </Table.Widget.Info>

          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.url}>
              {tab.component}
            </Route>
          ))}
        </Table.Widget>
      </Switch>
    </Flex>
  )
}
