import React, { useEffect, useState } from 'react'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { Grid } from '@components/CommonSC/Grid'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { connect } from 'lape'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Link as A, Token } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import Loader from '@components/CommonSC/Loader'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequests } from '@src/api/employees'
import { PageBody } from '@src/components/Page/PageBody'
import { getLocationDescriptor } from '@src/actions/RouterActions'

const UserRequestRouter = () => {
  const [loading, setLoading] = useState(true)
  const [employee, setEmployee] = useState<EmployeeInterface>()
  const params = useParams<{ employeeId: string }>()
  const location = useLocation<{ initialValues: any; returnAs: string }>()
  const initialValues = location?.state?.initialValues || {}
  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS, {
    id: params.employeeId,
  })

  useEffect(() => {
    fetchEmployee()
  }, [])

  const fetchEmployee = async () => {
    const employeeId = params.employeeId

    try {
      const result = await employeesRequests.getItem(employeeId)
      if (result.data) {
        setEmployee(result.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const canAddLookerModel = employee?.field_options?.permissions?.includes(
    PermissionTypes.AddLookerModelUserAccessRequest,
  )
  const canAddLookerFolder = employee?.field_options?.permissions?.includes(
    PermissionTypes.AddLookerFolderUserAccessRequest,
  )

  if (loading) {
    return <Loader />
  }

  const lookerDisabledTooltip = (
    <>
      Please log in to Looker first via Okta. Also, only selected roles and departments
      can request access currently. For more info, please see{` `}
      <A
        color={Token.color.background}
        textDecoration="underline"
        href="https://revolut.atlassian.net/wiki/x/RoRYe"
        target="_blank"
        rel="noreferrer noopener"
      >
        Confluence
      </A>{' '}
    </>
  )

  return (
    <PageWrapper>
      <PageHeader title="Choose Request Type" backUrl={backUrl} />
      <PageBody>
        <Grid mt="s-32" flow="row" gap={24}>
          <NewSelectionCard
            use={props => <Link {...props} replace />}
            disabled={!canAddLookerModel}
            disabledTooltip={lookerDisabledTooltip}
            // @ts-expect-error object works fine here, but UI kit expects string
            to={{
              ...getLocationDescriptor(
                pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL, {
                  employeeId: params.employeeId,
                }),
              ),
              state: {
                initialValues,
              },
            }}
            icon="LockOpened"
            title="Looker Model"
            subtitle="Request access to Looker Models"
            transparent={false}
          />
          <NewSelectionCard
            use={props => <Link {...props} replace />}
            disabled={!canAddLookerFolder}
            disabledTooltip={lookerDisabledTooltip}
            // @ts-expect-error object works fine here, but UI kit expects string
            to={{
              ...getLocationDescriptor(
                pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_FOLDER, {
                  employeeId: params.employeeId,
                }),
              ),
              state: {
                initialValues,
              },
            }}
            icon="LockOpened"
            title="Looker Folder"
            subtitle="Request access to Looker Folders"
            transparent={false}
          />
        </Grid>
      </PageBody>
    </PageWrapper>
  )
}

export default connect(UserRequestRouter)
