import React, { useEffect, useRef } from 'react'
import { Flex, FlexProps } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { useLocation } from 'react-router-dom'

export interface Props extends FlexProps {
  children: React.ReactNode | React.ReactNode[]
}

const PerformancePage = ({ children }: Props) => {
  const location = useLocation()
  const scrollRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    }
  }, [location.pathname])

  return (
    <PageWrapper>
      <Flex flexDirection="column" width="100%" flex={1} minHeight={0} ref={scrollRef}>
        {children}
      </Flex>
    </PageWrapper>
  )
}

export default PerformancePage
