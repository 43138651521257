import React from 'react'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import {
  ActionButton,
  Details,
  DetailsCellSkeleton,
  DetailsSkeleton,
  ItemSkeleton,
  Widget,
} from '@revolut/ui-kit'
import { meetingFrequencyToLabelMap } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/constants/meeting'
import { PreviewSectionHeader } from './PreviewSectionHeader'
import { MeetingTemplateEditStep } from '../interfaces'

interface Props {
  data?: MeetingTemplateInterface
  isLoading: boolean
  onEdit: (step: MeetingTemplateEditStep) => void
}

const Skeleton = () => {
  return (
    <Widget p="s-16">
      <ItemSkeleton />

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>
    </Widget>
  )
}

export const PreviewGeneralSection = ({ data, isLoading, onEdit }: Props) => {
  if (isLoading) {
    return <Skeleton />
  }

  if (!data) {
    return null
  }

  return (
    <Widget p="s-16">
      <PreviewSectionHeader
        title="General Info"
        subtitle="How often the meetings should occur"
        iconName="Info"
        actionElement={
          <ActionButton onClick={() => onEdit('general')}>Edit</ActionButton>
        }
      />

      <Details>
        <Details.Title alignSelf="center">Template title</Details.Title>
        <Details.Content>{data.name}</Details.Content>
      </Details>

      <Details>
        <Details.Title alignSelf="center">Description</Details.Title>
        <Details.Content>{data.description}</Details.Content>
      </Details>

      {data.frequency && (
        <Details>
          <Details.Title alignSelf="center">Default frequency</Details.Title>
          <Details.Content>{meetingFrequencyToLabelMap[data.frequency]}</Details.Content>
        </Details>
      )}

      <Details>
        <Details.Title alignSelf="center">Default duration</Details.Title>
        <Details.Content>{data.duration}min</Details.Content>
      </Details>
    </Widget>
  )
}
