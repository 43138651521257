import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import {
  ROW as personalKPIRow,
  usePersonalKPITable,
} from '@src/pages/EmployeeProfile/Preview/KPI/PersonalKPI'
import React, { useEffect } from 'react'
import { Icon, Token, VStack } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { summaryTableProps } from '@src/features/Summary/common'
import { withoutFilterAndSort } from '@src/utils/table'
import {
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/CollapsableTableWrapper'
import { CalculatedDeliverablesRating } from '../Summary/useCalculatedDeliverablesRating'

const kpiRow = {
  ...personalKPIRow,
  cells: withoutFilterAndSort([
    {
      ...kpiGenericNameColumn,
      title: 'Name',
      width: 300,
    },
    {
      ...kpiWeightColumn,
      width: 100,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
  ]),
}

export const NoReviewsDeliverablesCollapsable = ({
  data,
  stats,
  selectedPeriod,
  category,
  calculatedDeliverablesRating,
}: {
  data: Pick<EmployeeInterface, 'id'>
  stats?: Pick<EmployeeStats, 'kpi_performance_percent'>
  selectedPeriod: Pick<PerformanceSelector, 'id'>
  category?: ReviewCycleCategory
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
}) => {
  const kpiTable = usePersonalKPITable(data, { refetchOnApiChange: true }, [
    {
      filters: [{ name: `${selectedPeriod.id}`, id: selectedPeriod.id }],
      columnName: 'cycle__id',
      nonResettable: true,
    },
  ])

  useEffect(() => {
    const cycleFilterId = kpiTable.filterBy.find(
      filter => filter.columnName === 'cycle__id',
    )?.filters[0]?.id
    if (selectedPeriod.id !== cycleFilterId) {
      kpiTable.onFilterChange([
        {
          filters: [{ name: `${selectedPeriod.id}`, id: selectedPeriod.id }],
          columnName: 'cycle__id',
          nonResettable: true,
        },
      ])
    }
  }, [selectedPeriod])

  return (
    <VStack space="s-16">
      {category === ReviewCycleCategory.Performance && (
        <CollapsableTableWrapper
          mapperFunc={calculatedDeliverablesRating?.mapper}
          ratings={calculatedDeliverablesRating?.ratings}
          gradesMap={calculatedDeliverablesRating?.gradesMap}
          icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
          headerTitle="Deliverables"
          headerStat={(stats?.kpi_performance_percent || 0) * 100}
          headerRating={calculatedDeliverablesRating?.rating}
          count={kpiTable.data.length}
        >
          <AdjustableTable<KpiInterface>
            name={TableNames.EmployeeKPIs}
            dataType="KPI"
            row={kpiRow}
            {...kpiTable}
            noDataMessage="All your goals for this cycle will appear here"
            onRowClick={onKPITableRowClick}
            {...summaryTableProps}
            hideCountAndButtonSection
          />
        </CollapsableTableWrapper>
      )}
    </VStack>
  )
}
