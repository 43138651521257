import React from 'react'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { ParsableError, parseError } from './parseError'
import { ActionErrorTitle } from './components/ActionErrorTitle'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { getTechnicalErrorMessage } from '@src/features/Errors/getters'
import { TechnicalErrorMessage } from '@src/features/Errors/components/TechnicalErrorMessage'

interface UseErrorPopupShowParams {
  error: ParsableError
  /** Fallback title in case of an unknown unhandled error. Do not use `Something went wrong`, use user friendly message instead, e.g. `Could not archive team` */
  fallbackTitle: string
  fallbackDescription?: string
}

export const useErrorPopup = () => {
  const statusPopup = useStatusPopup()

  return {
    show: ({ error, fallbackTitle, fallbackDescription }: UseErrorPopupShowParams) => {
      const parsedError = parseError(error)
      const apiDescription = getMessageFromApiError(error)
      let description = apiDescription
      let technicalMessage: null | string = null

      if (parsedError.subType === 'be_error' || (!description && error?.response?.data)) {
        technicalMessage = getTechnicalErrorMessage(error)
        description = null
      }
      if (!technicalMessage && fallbackDescription) {
        description = fallbackDescription
      }

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            <ActionErrorTitle error={parsedError} fallbackTitle={fallbackTitle} />
          </StatusPopup.Title>
          {description ? (
            <StatusPopup.Description>{description}</StatusPopup.Description>
          ) : technicalMessage ? (
            <StatusPopup.Description>
              <TechnicalErrorMessage error={error} message={technicalMessage} />
            </StatusPopup.Description>
          ) : null}
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    },
    hide: statusPopup.hide,
  }
}
