import React, { useEffect, useRef } from 'react'
import { VStack, Widget, Text, Color, Box } from '@revolut/ui-kit'

import { OptionInterface } from '@src/interfaces/selectors'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import ReviewersFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/ReviewersFilter'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/SummaryReviewTables'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/BarRaiserSummary'

export interface ReviewsProps {
  data: ReviewSummaryInterface
  onFilterChanged: (option?: OptionInterface[]) => void
  scrollToBarRaiser?: boolean
}

export const ReviewsSection = ({
  data,
  onFilterChanged,
  scrollToBarRaiser,
}: ReviewsProps) => {
  const { summary, reviews } = data
  const barRaiserRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToBarRaiser) {
      setTimeout(() => {
        barRaiserRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 600)
    }
  }, [])

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <ReviewersFilter data={reviews} onFilterChange={onFilterChanged} showFilters />
        {data.summary && (
          <VStack>
            <SummaryReviewTables
              reviewData={data.summary}
              reviewValues={data}
              hideExpectations
            />
            <Box pt="s-24" ref={barRaiserRef}>
              <BarRaiserSummary data={data.summary} hideBanner />
            </Box>
          </VStack>
        )}
        {reviews.length ? (
          <div style={{ marginTop: '16px' }}>
            <Text mb={0} color={Color.GREY_TONE_50} variant="caption" fontWeight={500}>
              Feedback
            </Text>
            <SidebarFeedbacks
              renderTitle={false}
              feedbacks={summary?.overall_feedbacks}
              ratings={summary?.ratings}
            />
          </div>
        ) : (
          <NoReviewsWidget />
        )}
      </VStack>
    </Widget>
  )
}
