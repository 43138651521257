import React, { useState } from 'react'
import {
  Bar,
  BottomSheet,
  Button,
  Calendar,
  Header,
  ToggleButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { RangeValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'
import { IdAndName } from '@src/interfaces'
import {
  DateFilterOptions,
  DEFAULT_WEEKS_COUNT,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { utcToZonedTime } from 'date-fns-tz'

interface Props {
  open: boolean
  onClose: VoidFunction
  title: string
  onChangeCustomDateRange: (value: RangeValue) => void
  onChangeWeekOption: (value: IdAndName) => void
  selectedWeekOption?: IdAndName
  customDateRange?: RangeValue
  timeZoneId: string
}

const CUSTOM_OPTION_ID = -1

const options = [
  ...DateFilterOptions,
  {
    id: CUSTOM_OPTION_ID,
    name: 'Custom',
  },
]

export const DynamicSlotsDateFilterPopup = ({
  title,
  open,
  onClose,
  selectedWeekOption: initialWeekOption,
  customDateRange: initialCustomDateRange,
  onChangeCustomDateRange,
  onChangeWeekOption,
  timeZoneId,
}: Props) => {
  const defaultOption = options.find(opt => opt.id === DEFAULT_WEEKS_COUNT) || options[0]
  const customOption = options.find(opt => opt.id === CUSTOM_OPTION_ID)

  const [customDateRange, setCustomDateRange] = useState<RangeValue | undefined>(
    initialCustomDateRange,
  )
  const [selectedWeekOption, setSelectedWeekOption] = useState<IdAndName>(
    initialCustomDateRange && customOption
      ? customOption
      : initialWeekOption || defaultOption,
  )

  const onSubmit = () => {
    if (selectedWeekOption.id === CUSTOM_OPTION_ID) {
      if (customDateRange) {
        onChangeCustomDateRange(customDateRange)
      } else {
        onChangeWeekOption(defaultOption)
      }
    } else {
      onChangeWeekOption(selectedWeekOption)
    }

    onClose()
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>{title}</Header.Title>
      </Header>
      <VStack gap="s-16">
        <Bar>
          {options.map(option => (
            <ToggleButton
              key={option.name}
              checked={selectedWeekOption.id === option.id}
              onToggle={() => {
                setSelectedWeekOption(option)
              }}
            >
              {option.name}
            </ToggleButton>
          ))}
        </Bar>

        {selectedWeekOption.id === CUSTOM_OPTION_ID ? (
          <Calendar
            variant="range"
            labelButtonClear="Clear"
            labelEmptyValue="Select range"
            labelPrev="Previous"
            labelNext="Next"
            value={customDateRange}
            onChange={setCustomDateRange}
            transparentBg
            disabledDays={{
              before: utcToZonedTime(new Date(), timeZoneId),
            }}
          />
        ) : (
          <ActionWidget
            title={`Rolling period of ${selectedWeekOption.name}`}
            avatarColor={Token.color.blue}
          />
        )}
      </VStack>
      <BottomSheet.Actions horizontal>
        <Button elevated variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Submit</Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
