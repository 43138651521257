import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { hiringProcessSettings } from '@src/api/settings'
import { Avatar, Group, Token } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { DefaultHiringProcess } from '@src/pages/Settings/HiringProcess/DefaultHiringProcess'

export const HiringProcessGeneral = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeHiringProcessPreferences,
  )
  return (
    <>
      <PageBody maxWidth="100%" mb="s-64">
        <AutoStepper>
          <NewStepperTitle noAutoStep title="General settings" />
          <Group maxWidth={Token.breakpoint.md}>
            <LapeNewSwitch
              itemTypeProps={{
                avatar: <Avatar useIcon="LockOpened" />,
                title:
                  'Allow specialisation owners to lock hiring process on job postings',
                description:
                  'When enabled, the hiring process for each specialisation can be locked by the specialisation owner. Recruitment or hiring managers will be able to add stages, or questions within a stage, but will not be able to remove, replace or skip stages in the hiring process.',
              }}
              name="enable_specialisation_hiring_process_lock"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                avatar: <Avatar useIcon="ChangePasscode" />,
                title: 'Lock all specialisation hiring processes on job postings',
                description:
                  'When enabled, the hiring process for each specialisation will be locked automatically. Recruitment or hiring managers will be able to add stages, or questions within a stage, but will not be able to remove, replace or skip stages in the hiring process.',
              }}
              name="enable_global_hiring_process_lock"
              disabled={disableEdit}
            />
          </Group>
          <NewStepperTitle
            noAutoStep
            title="Default hiring process template"
            subtitle="This hiring process will be the default for all job postings (unless defined by the specialisation)."
          />
          <DefaultHiringProcess />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.HIRING_PROCESS_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Hiring process settings',
    description:
      'Setup hiring process templates and control hiring process flexibility in specialisation and job posting level',
    path: ROUTES.SETTINGS.JOB_POSTING.HIRING_PROCESS,
    url: ROUTES.SETTINGS.JOB_POSTING.HIRING_PROCESS,
    canView: [PermissionTypes.ViewHiringProcessPreferences],
    component: HiringProcessGeneral,
  },
]

export const General = () => {
  return <SettingsForm routes={routes} api={hiringProcessSettings} />
}
