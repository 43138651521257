import { Cell, Flex, MoreBar, VStack, Token, Box, MoreBarSkeleton } from '@revolut/ui-kit'
import { kpisRequests, kpisRequestsNew, linkExampleKPIs } from '@src/api/kpis'
import { useTable } from '@src/components/Table/hooks'
import { Statuses } from '@src/interfaces'
import { KpiInterface } from '@src/interfaces/kpis'
import React, { useState } from 'react'
import Message from '../../components/Message'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  kpiCurrentValueColumn,
  kpiInitialValueColumn,
  kpiNameColumn,
  kpiPerformanceColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
} from '@src/constants/columns/kpi'
import TargetsApprovals from '@src/pages/Forms/KpiForm/common/TargetsApprovals'
import Form from '@src/features/Form/Form'
import { SidePanel } from './Side'
import { KpiForm } from './Form'
import { useFindTeamByName } from '@src/api/teams'
import Table from '@src/components/TableV2/Table'

export const Preview = () => {
  const [selected, setSelected] = useState<KpiInterface>()
  const [pendingGenerate, setPendingGenerate] = useState(false)
  const { data: defaultTeam, isLoading } = useFindTeamByName('Product · Core · IT')
  const [formShown, setFormShown] = useState(false)
  const table = useTable<KpiInterface>(kpisRequests, [
    {
      filters: [{ name: Statuses.active, id: Statuses.active }],
      nonResettable: true,
      columnName: 'status',
    },
    {
      filters: [{ name: '0', id: '0' }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ])

  const examplesAlreadyGenerated = !!table.data.filter(kpi =>
    ['[Example] Company KPI', '[Example] Department KPI'].includes(kpi.name),
  ).length

  const handleGenerateExamples = async () => {
    setPendingGenerate(true)
    try {
      await linkExampleKPIs()
      table.refresh()
    } finally {
      setPendingGenerate(false)
    }
  }

  return (
    <>
      <Flex gap="s-24">
        <VStack space="s-16" width="60%">
          {examplesAlreadyGenerated ? (
            <Message
              title="We have created some example KPIs for you"
              description="Feel free to take a look and try creating one yourself"
              storageKey="kpi_onboarding_preview"
            />
          ) : null}
          <Table.Widget>
            <Table.Widget.Actions>
              {table.loading || isLoading ? (
                <MoreBarSkeleton />
              ) : (
                <Table.Widget.MoreBar>
                  <MoreBar.Action useIcon="Plus" onClick={() => setFormShown(true)}>
                    Add new KPI
                  </MoreBar.Action>
                  {!examplesAlreadyGenerated && !!defaultTeam && (
                    <MoreBar.Action
                      useIcon="Plus"
                      onClick={handleGenerateExamples}
                      pending={pendingGenerate}
                    >
                      Generate example KPIs
                    </MoreBar.Action>
                  )}
                </Table.Widget.MoreBar>
              )}
            </Table.Widget.Actions>
            <Table.Widget.Table>
              <AdjustableTable
                name={TableNames.KPIs}
                onRowClick={setSelected}
                dataType="KPI"
                row={{
                  highlight: kpi =>
                    kpi.id === selected?.id ? Token.color.actionBackground : '',
                  isNotNested: true,
                  cells: [
                    { ...kpiNameColumn, width: 300 },
                    { ...kpiPerformanceColumn, width: 150 },
                    { ...kpiInitialValueColumn, width: 100 },
                    { ...kpiCurrentValueColumn, width: 100 },
                    { ...kpiTargetColumn, width: 100 },
                    { ...kpiUnitColumn, width: 100 },
                    { ...kpiStrategyColumn, width: 100 },
                  ],
                }}
                {...table}
              />
            </Table.Widget.Table>
          </Table.Widget>
        </VStack>
        <Box width="40%">
          {selected && (
            <Cell>
              <Form<KpiInterface>
                forceParams={{ id: String(selected.id) }}
                api={kpisRequestsNew}
                fieldsToExclude={['targets', 'target_epics']}
                useLocalStorageCaching={false}
              >
                <TargetsApprovals>
                  <SidePanel />
                </TargetsApprovals>
              </Form>
            </Cell>
          )}
        </Box>
      </Flex>
      <Form forceParams={{ id: undefined }} api={kpisRequestsNew}>
        <KpiForm
          defaultTeam={defaultTeam || undefined}
          open={formShown}
          onClose={() => setFormShown(false)}
          onAfterSubmit={() => table.refresh()}
        />
      </Form>
    </>
  )
}
