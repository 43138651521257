import React from 'react'
import { useSelector } from 'react-redux'

import { getScopeFilters } from '@src/features/Engagement/helpers'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { RoleInterface } from '@src/interfaces/roles'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { SurveryResultsTabPublished as SurveyResultsPublishedV2 } from '@src/pages/Performance/Engagement/components/v2/ResultsTab/Published'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const Engagement = ({ data }: { data: RoleInterface }) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  if (isEngagementV2) {
    return <SurveyResultsPublishedV2 scopeFilters={getScopeFilters(data, 'role')} />
  }
  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Roles,
        id: data.id,
        permissions: data.field_options.permissions,
      }}
    />
  )
}
