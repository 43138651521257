import { API } from '@src/constants/api'
import {
  MergeDepartmentsPayloadInterface,
  MergeDepartmentsResponseInterface,
  MergeEntitiesPayloadInterface,
  MergeEntitiesResponseInterface,
  MergeRolesPayloadInterface,
  MergeRolesResponseInterface,
  MergeSpecialisationPayloadInterface,
  MergeTeamsPayloadInterface,
  MergeTeamsResponseInterface,
} from '@src/interfaces/updateOrganisationStructure'
import { apiWithoutHandling } from '.'
import { useFetchV2, usePostV2 } from '@src/utils/reactQuery'

export const mergeDepartments = (data: MergeDepartmentsPayloadInterface) =>
  apiWithoutHandling.post<MergeDepartmentsResponseInterface>(
    `${API.DEPARTMENT}/merge`,
    data,
  )

export const mergeEntities = (data: MergeEntitiesPayloadInterface) =>
  apiWithoutHandling.post<MergeEntitiesResponseInterface>(`${API.ENTITIES}/merge`, data)

export const mergeTeams = (data: MergeTeamsPayloadInterface) =>
  apiWithoutHandling.post<MergeTeamsResponseInterface>(`${API.TEAMS}/merge`, data)

export const useMergeTeams = () =>
  usePostV2<
    MergeTeamsResponseInterface,
    MergeTeamsResponseInterface,
    MergeTeamsPayloadInterface
  >({
    url: `${API.TEAMS}/merge`,
  })

export const useGetMergeTeamsStatus = (taskId?: string) =>
  useFetchV2<MergeTeamsResponseInterface>({
    url: `${API.TEAMS}/merge/status`,
    params: {
      params: {
        task_id: taskId,
      },
    },
    queryOptions: {
      enabled: !!taskId,
      refetchOnWindowFocus: false,
      refetchInterval: data =>
        data?.status && ['PENDING', 'RECEIVED', 'STARTED', 'RETRY'].includes(data.status)
          ? 1500
          : false,
    },
  })

export const mergeSpecialisations = (data: MergeSpecialisationPayloadInterface) =>
  apiWithoutHandling.post(`${API.SPECIALISATIONS}/merge`, data)

export const mergeRoles = (data: MergeRolesPayloadInterface) =>
  apiWithoutHandling.post<MergeRolesResponseInterface>(`${API.ROLES}/merge`, data)

export const deleteDepartment = (id: number) =>
  apiWithoutHandling.delete(`${API.DEPARTMENT}/${id}`)

export const deleteEntity = (id: number) =>
  apiWithoutHandling.delete(`${API.ENTITIES}/${id}`)

export const deleteTeam = (id: number) => apiWithoutHandling.delete(`${API.TEAMS}/${id}`)

export const deleteRole = (id: number) => apiWithoutHandling.delete(`${API.ROLES}/${id}`)
