import React from 'react'
import { Text, HStack, Avatar } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { RecommendedBadge } from './RecommendedBadge'

interface Props {
  disabled?: boolean
  tooltip?: React.ReactNode
}

export const FunctionalManagerReviewsSwitch = ({ disabled, tooltip }: Props) => {
  return (
    <LapeNewSwitch
      itemTypeProps={{
        avatar: (
          <Avatar
            variant="brand"
            bg="transparent"
            size={40}
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D261.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D261@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D261@3x.png',
            }}
          />
        ),
        title: (
          <HStack gap="s-8" align="center">
            <Text>Functional manager reviews</Text>
            <RecommendedBadge />
          </HStack>
        ),
        description:
          'A functional manager(FM) is a person with the same skills but a higher seniority compared to the person who is being mentored. When enabled the FM will have to review the mentee during all types of performance cycles.',
        tooltip,
      }}
      name="enable_functional_manager_reviews"
      disabled={disabled}
    />
  )
}
