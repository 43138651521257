import React from 'react'
import { Group, Subheader } from '@revolut/ui-kit'
import { useAiInterviewSummarisationCandidateEnabled } from '@src/pages/Careers/hooks/useAiInterviewSummarisationCandidateEnabled'
import { InterviewTranscriptConsent } from '@src/features/InterviewTranscriptConsent/InterviewTranscriptConsent'

interface Props {
  checked: boolean
  onCheck: (checked: boolean) => void
}

export const CandidateSchedulingInterviewTranscriptConsent = ({
  checked,
  onCheck,
}: Props) => {
  const enabled = useAiInterviewSummarisationCandidateEnabled()

  if (!enabled) {
    return null
  }

  return (
    <Group>
      <Subheader>Interview transcripts</Subheader>
      <InterviewTranscriptConsent checked={checked} onCheck={onCheck} p="s-16" />
    </Group>
  )
}
