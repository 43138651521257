import React from 'react'
import { Token } from '@revolut/ui-kit'

import { Text } from '@src/pages/Landing/components/Text'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'

interface Props {
  children: React.ReactNode
}

export const CompanyDescription = ({ children }: Props) => {
  return (
    <ContentBlockLayout>
      <Text use="h4" variant="h4" textAlign="center" color={Token.color.greyTone50}>
        {children}
      </Text>
    </ContentBlockLayout>
  )
}
