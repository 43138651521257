import React from 'react'
import useResizeObserver from 'use-resize-observer'
import { Box, Token } from '@revolut/ui-kit'
import { GenericTimeSeriesInterface } from '@src/interfaces/totalCompensation'
import {
  BarChart as HorizontalBarChart,
  HorizontalBarChartProps,
} from '@src/pages/Forms/QueryForm/components/Charts/BarChart'
import { formatMoneyMillions } from '@src/utils/format'
import { BarChartTooltip } from '../Charts/BarChartTooltip'
import { valueAccessor } from './helpers'

type ChartData = {
  label: string
  tooltip: string
} & GenericTimeSeriesInterface

type Props<T, K> = Pick<
  HorizontalBarChartProps<T, K>,
  'data' | 'dataKeys' | 'isLoading' | 'projectedDataIdx'
>

const BAR_RADIUS = 6
const MAX_BAR_SIZE = 120
const LINE_STROKE_WIDTH = 2.5

export const BarChart = <T extends ChartData, K>({
  data,
  dataKeys,
  isLoading,
  projectedDataIdx,
}: Props<T, K>) => {
  const { height, ref } = useResizeObserver<HTMLDivElement>()

  return (
    <Box height="100%" ref={ref}>
      <HorizontalBarChart<T, K>
        barOptions={{
          className: 'horizontalBarChartDashed',
          maxBarSize: MAX_BAR_SIZE,
          radius: BAR_RADIUS,
          style: { stroke: Token.color.widgetBackground, strokeWidth: LINE_STROKE_WIDTH },
        }}
        customValueAccessor={valueAccessor}
        data={data}
        dataKeys={dataKeys}
        isLoading={isLoading}
        isStacked
        height={height}
        projectedDataIdx={projectedDataIdx}
        tooltip={BarChartTooltip}
        xAxis={{ axisLine: false, dataKey: 'year' }}
        yAxis={{
          orientation: 'right',
          tickFormatter: value => formatMoneyMillions(value, data?.[0].currency.iso_code),
          ticks: undefined, // do not set the values of axis ticks manually
        }}
        withProjection
      />
    </Box>
  )
}
