import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { Goals } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Goals/Goals'
import { JiraGoals } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/JiraGoals/JiraGoals'
import { SmartGoals } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/SmartGoals/SmartGoals'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector
  performanceSummary?: ReviewSummaryInterface
  performanceSettings?: PerformanceSettingsInterface
}

export const Deliverables = ({
  employee,
  reviewCycle,
  performanceSummary,
  performanceSettings,
}: Props) => {
  const hasJiraGoals = reviewCycle.probation_reviews_deliverables_type === 'jira'
  const hasSmartGoals =
    hasJiraGoals && !performanceSettings?.enable_probation_and_pip_goals_via_jira

  if (hasSmartGoals) {
    return <SmartGoals employee={employee} reviewCycle={reviewCycle} />
  }

  if (hasJiraGoals) {
    return <JiraGoals employee={employee} reviewCycle={reviewCycle} />
  }

  return (
    <Goals
      employee={employee}
      reviewCycle={reviewCycle}
      performanceSummary={performanceSummary}
    />
  )
}
