import React from 'react'
import { Flex, Text, useTooltip } from '@revolut/ui-kit'
import { getFunctionNIPSColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatNumber } from '@src/utils/format'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { BarChart } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PerformanceTimeRange } from '@src/constants/api'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { AxiosPromise } from 'axios'
import { GradeOption } from '@src/interfaces/selectors'
import { NipsCalculationTooltip } from '@src/features/ReviewCycle/components/Nips/NipsCalculationTooltip'

type Props = {
  id?: number
  nips: number
  gradesOptions: GradeOption[]
  nipsGraphRequest?: (
    id: number | string,
    range?: PerformanceTimeRange,
    cycleOffset?: string,
  ) => AxiosPromise<PerformanceChartCycles>
  label?: string
  rightMargin?: boolean
}

const TalentHeaderNIPS = ({
  id,
  nipsGraphRequest,
  nips,
  gradesOptions,
  label = 'NIPS',
  rightMargin = true,
}: Props) => {
  const tooltip = useTooltip()

  return (
    <Stat
      label={label}
      val={
        <Flex alignItems="center">
          <Text
            use="div"
            mr="5px"
            color={getFunctionNIPSColor(nips || 0)}
            {...tooltip.getAnchorProps<HTMLDivElement>()}
          >
            {`${formatNumber(nips, 1)}%`}
          </Text>
          <NipsCalculationTooltip
            grades={gradesOptions}
            placement="right"
            maxWidth={300}
            {...tooltip.getTargetProps<HTMLDivElement>()}
          />
          {nipsGraphRequest && id && (
            <GraphIconChart id={id} vertical="right" fetchData={nipsGraphRequest}>
              <Flex pr={rightMargin ? 's-12' : 0}>
                <BarChart cursor="pointer" size={16} color="grey-tone-20" />
              </Flex>
            </GraphIconChart>
          )}
        </Flex>
      }
      mr={rightMargin ? 's-32' : 0}
    />
  )
}

export default TalentHeaderNIPS
