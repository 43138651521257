import React from 'react'
import { Side, VStack } from '@revolut/ui-kit'

import { useGetEngagementAnswerDetails } from '@src/api/engagement'
import { AnswerWidget } from '../../common/AnswerWidget'
import { RepliesWidget } from '../../common/RepliesWidget'

interface Props {
  answerId: number
  onAcknowledge: VoidFunction
}
export const AcknowledgementSidebar = ({ answerId, onAcknowledge }: Props) => {
  const {
    data: answerData,
    refetch: refetchAnswer,
    isLoading: isLoadingAnswer,
  } = useGetEngagementAnswerDetails(answerId)

  return (
    <VStack space="s-16">
      <AnswerWidget
        canAcknowledge
        answer={answerData}
        isLoading={isLoadingAnswer}
        onAcknowledge={() => {
          refetchAnswer()
          onAcknowledge()
        }}
      />
      <RepliesWidget
        answerId={answerId}
        renderInputWrapper={input => <Side.Actions>{input}</Side.Actions>}
      />
    </VStack>
  )
}
