import { PerformanceSettingsInterface } from '@src/interfaces/settings'

interface SettingsModel {
  hasFunctionalManagement: (settings: PerformanceSettingsInterface) => boolean
  hasFunctionalManagerReviews: (settings: PerformanceSettingsInterface) => boolean
}

const hasFunctionalManagement = (settings: PerformanceSettingsInterface) =>
  settings.enable_functional_management
const hasFunctionalManagerReviews = (settings: PerformanceSettingsInterface) =>
  hasFunctionalManagement(settings) && settings.enable_functional_manager_reviews

const createSettingsModel = (): SettingsModel => {
  return {
    hasFunctionalManagement,
    hasFunctionalManagerReviews,
  }
}

export const settingsModel = createSettingsModel()
