import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { OrganisationStructure } from './types'
import { apiV2 } from '@src/api'
import { useFetchV2, useUpdateV2 } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'

export const structureRequests: RequestInterfaceNew<OrganisationStructure> = {
  get: async ({ id }) => apiV2.get(`${API.ORGANISATION_STRUCTURES}/${id}`),
  update: async (data, { id }) =>
    apiV2.patch(`${API.ORGANISATION_STRUCTURES}/${id}`, data),
  submit: async data => apiV2.post(`${API.ORGANISATION_STRUCTURES}`, data),
  delete: async ({ id }) => apiV2.delete(`${API.ORGANISATION_STRUCTURES}/${id}`),
}

export const deleteStructure = (id: number) =>
  apiV2.delete(`${API.ORGANISATION_STRUCTURES}/${id}`)

export const useUpdateStructure = () =>
  useUpdateV2<OrganisationStructure, OrganisationStructure>({
    url: API.ORGANISATION_STRUCTURES,
    updater: (_oldData, newData) => {
      return newData
    },
  })

export const useOrganisationStructures = () =>
  useFetchV2<GetRequestInterface<OrganisationStructure>>({
    url: API.ORGANISATION_STRUCTURES,
  })
