import React, { ReactChild, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AxiosPromise } from 'axios'
import { MoreBar, TextButton, Token, ToggleFn } from '@revolut/ui-kit'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { useTableReturnType } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import {
  changelogAction,
  changelogChangedBy,
  changelogChangedFields,
  changelogChangedOn,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import {
  ChangelogInterface,
  ChangelogChangeRequestInterface,
} from '@src/interfaces/changelog'
import { RowInterface } from '@src/interfaces/data'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props<T> {
  cancelField?: (id: number, fieldName: string, employeeId: number) => AxiosPromise
  cancelFields: (id: number, employeeId: number) => AxiosPromise
  changelogTable: useTableReturnType<ChangelogInterface<T>>
  changeRequestActions?: (
    id: number,
    request?: ChangelogChangeRequestInterface | null,
  ) => ReactChild
  entityId: number
  requestChangeButton?: (id: number) => React.ReactNode
  selectorChangedFields: selectorKeys
  selectorNewValues: selectorKeys
  showIndividualToggler: ToggleFn
  showIndividual: boolean
  tableName: TableNames
}

const getRows = <T,>(
  selectorChangedFields: selectorKeys,
  selectorNewValues: selectorKeys,
  showIndividual: boolean,
): RowInterface<ChangelogInterface<T>> => {
  return {
    highlight: data => {
      if (data.status?.id === Statuses.pending) {
        return Token.color.orange_5
      }
      return ''
    },
    cells: [
      {
        ...(showIndividual ? changelogFieldsChangedFields : changelogChangedFields),
        selectorsKey: selectorChangedFields,
        width: 240,
      },
      showIndividual
        ? {
            ...changelogFieldsNewValue,
            selectorsKey: selectorNewValues,
            width: 240,
          }
        : null,
      {
        ...changelogEffectiveDate,
        width: 200,
      },
      {
        ...changelogChangedBy,
        width: 240,
      },
      {
        ...changelogChangedOn,
        width: 200,
      },
      {
        ...changelogStatus,
        width: 240,
      },
    ].filter(Boolean),
  }
}

export const CommonTable = <T,>({
  cancelField,
  cancelFields,
  changelogTable,
  changeRequestActions,
  entityId,
  requestChangeButton,
  selectorChangedFields,
  selectorNewValues,
  showIndividual,
  showIndividualToggler,
  tableName,
}: Props<T>) => {
  const permissions = useSelector(selectPermissions)
  const canCancel = permissions.includes(PermissionTypes.ChangeEmployeechangelogitem)

  const confirmationPopup = useConfirmationDialog()
  const showErrorPopup = useErrorPopup()
  const showStatusPopup = useShowStatusPopup()

  const rows = useMemo(() => {
    const changelogRows = getRows<T>(
      selectorChangedFields,
      selectorNewValues,
      showIndividual,
    )

    changelogRows.cells[changelogRows.cells.length] = {
      ...changelogAction,
      width: 200,
      insert: ({ data: cellData }) => {
        if (
          canCancel &&
          cellData.status?.id === Statuses.pending &&
          cellData.change_request == null &&
          (!showIndividual || cancelField)
        ) {
          return (
            <TextButton
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                confirmationPopup.show({
                  body: 'Cancelling the future update can not be undone. Continue only if you are 100% sure of this.',
                  label: 'Cancel this future update?',
                  noMessage: 'Go back',
                  yesMessage: 'Confirm',
                  onConfirm: () =>
                    showIndividual
                      ? handleCancelField(
                          cellData.changelog_item_id!,
                          cellData.field_name,
                        )
                      : handleCancelFields(cellData.id),
                })
              }}
            >
              Cancel
            </TextButton>
          )
        }

        if (changeRequestActions) {
          return changeRequestActions(entityId, cellData.change_request)
        }

        return null
      },
    }

    return changelogRows
  }, [cancelField, showIndividual])

  const handleCancel = async <U extends (...args: Parameters<U>) => AxiosPromise>(
    cancelFunction: U,
    ...args: Parameters<U>
  ): Promise<void> => {
    try {
      await cancelFunction(...args)
      showStatusPopup({
        title: 'Change was successfully cancelled.',
      })
      changelogTable.refresh()
    } catch (error) {
      showErrorPopup.show({
        error,
        fallbackTitle: 'Failed to cancel change',
      })
    }
  }

  const handleCancelField = async (id: number, fieldId: string) => {
    if (cancelField) {
      await handleCancel(cancelField, id, fieldId, entityId)
    }
  }

  const handleCancelFields = async (id: number) => {
    await handleCancel(cancelFields, id, entityId)
  }

  return (
    <>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {requestChangeButton?.(entityId)}
          <MoreBar.Action
            onClick={showIndividualToggler.switch}
            useIcon={showIndividual ? 'SwitchOn' : 'SwitchOff'}
          >
            Show individual fields
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<ChangelogInterface<T>>
          dataType="Change"
          emptyState={<EmptyTableRaw title="No changes found" />}
          name={tableName}
          row={rows}
          useWindowScroll
          {...changelogTable}
        />
      </Table.Widget.Table>
    </>
  )
}
