import React from 'react'
import { Flex, Text, Avatar, FlexProps, chain, TextButton } from '@revolut/ui-kit'
import { getInitials } from '@src/utils/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { StyledLink } from '@components/Atoms/StyledLink'
import { getLocationDescriptor } from '@src/actions/RouterActions'

interface Props extends Omit<FlexProps, 'id' | 'location'> {
  id?: number
  full_name?: string
  avatar?: string | null
  isSkipped: boolean
  caption?: string
  rejectionComment?: string | null
  onViewRejectionReasonClick?: (comment?: string | null) => void
}

const Approver = ({
  id,
  avatar,
  full_name,
  isSkipped,
  caption,
  rejectionComment,
  onViewRejectionReasonClick,
  ...rest
}: Props) => {
  const initials = full_name && getInitials(full_name)

  return (
    <Flex {...rest} alignItems="center">
      <Avatar image={avatar || undefined} size={24} opacity={isSkipped ? 0.5 : 1}>
        <Text fontSize="tiny">{!avatar && initials}</Text>
      </Avatar>
      <Flex ml="0.7rem" alignItems="center">
        {chain(
          <>
            <Text textDecoration={isSkipped ? 'line-through' : undefined}>
              {id && full_name ? (
                <StyledLink
                  to={getLocationDescriptor(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
                  )}
                >
                  {full_name}
                </StyledLink>
              ) : (
                'Not assigned'
              )}
              {caption && <Text pl="0.25rem">{caption}</Text>}
              {isSkipped ? (
                <Text pl="0.25rem" color="grey-tone-50">
                  (skipped)
                </Text>
              ) : null}
            </Text>
          </>,
          onViewRejectionReasonClick ? (
            <TextButton
              onClick={() => onViewRejectionReasonClick(rejectionComment)}
              p={0}
            >
              View reason
            </TextButton>
          ) : null,
        )}
      </Flex>
    </Flex>
  )
}

export default Approver
