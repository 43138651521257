import React from 'react'
import { ReviewerRelation, ReviewSummaryInterface } from '@src/interfaces/performance'
import styled from 'styled-components'
import { relationToString } from '@components/ScorecardGeneral/constants'
import { Box, Text, Token } from '@revolut/ui-kit'
import OverallFeedback from '@src/pages/Forms/EmployeePerformanceLayout/components/OverallFeedback'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { AdditionalScorecards } from '@src/pages/Forms/EmployeePerformanceLayout/components/AdditionalScorecards'

const FeedbackText = styled.div<{ margin?: string }>`
  margin: ${props => props.margin};
  width: 100%;
`

const Muted = styled.span`
  padding-left: 6px;
  color: ${Token.color.greyTone50};
`

type EmployeePerformanceViewSummaryContentProps = {
  values: ReviewSummaryInterface
  width: string | number
  showStepperTitle?: boolean
  withBorder?: boolean
  hideAdditional?: boolean
  margin?: string
}
export const EmployeePerformanceViewSummaryContent = ({
  values,
  width,
  showStepperTitle = true,
  withBorder = false,
  hideAdditional = false,
  margin,
}: EmployeePerformanceViewSummaryContentProps) => {
  const additionalScorecards = values.summary?.cycle_sections
  const scorecardAnswers = additionalScorecards?.[0]?.questions[0]?.answers
  const scorecardsRelations = scorecardAnswers
    ? [...(Object.keys(scorecardAnswers) as ReviewerRelation[])]
    : []

  return (
    <AutoStepper width={width}>
      {showStepperTitle && <AutoStepperTitle title="Overall feedback" variant="letter" />}
      {values.summary?.overall_feedbacks.map((feedback, i) => {
        const title = (
          <Text use="div" variant="primary" mb="s-12">
            {feedback.review.reviewer.display_name}
            {feedback.review.probation_checkpoint && (
              <Muted>CP{feedback.review.probation_checkpoint.number}</Muted>
            )}
            {feedback.review.reviewer_relation && (
              <Muted>({relationToString(feedback.review.reviewer_relation, true)})</Muted>
            )}
          </Text>
        )

        return (
          <FeedbackText key={i} margin={margin}>
            <OverallFeedback
              title={title}
              pros={feedback.value?.pros}
              cons={feedback.value?.cons}
              avatar={feedback.review.reviewer.avatar}
              name={feedback.review.reviewer.full_name}
              reviewerId={feedback.review.reviewer.id}
              disabled
              isSidebar={withBorder}
            />
          </FeedbackText>
        )
      })}
      {!!additionalScorecards?.length && !hideAdditional && (
        <>
          <AutoStepperTitle title="Additional questions" variant="letter" />
          {scorecardsRelations.map((relation, index) => (
            <Box key={index}>
              {additionalScorecards.map((scorecard, ind) => (
                <Box mb="s-16" key={scorecard.id}>
                  <AdditionalScorecards
                    data={scorecard}
                    scorecardIdx={ind}
                    relation={relation}
                    viewMode
                  />
                </Box>
              ))}
            </Box>
          ))}
        </>
      )}
    </AutoStepper>
  )
}
