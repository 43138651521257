import React from 'react'
import { createChain, Text, TabBar, TabBarProps, Token } from '@revolut/ui-kit'
import { matchPath, useLocation } from 'react-router-dom'
import { getPrevLocationDescriptor } from '@src/actions/RouterActions'
import { TabBarWithPinningTabConfig } from '@src/interfaces/tabPinning'
import TabBarLink from '@components/TabBarLink/TabBarLink'

interface TabBarWithPinningProps extends TabBarProps {
  tabs: TabBarWithPinningTabConfig[]
}

export const TabBarSimple = ({ tabs, ...props }: TabBarWithPinningProps) => {
  const location = useLocation()

  return (
    <TabBar
      behaviour="scroll"
      maxWidth="min-content"
      data-testid="tab-bar-simple"
      {...props}
    >
      {tabs.map(tab => {
        const match = tab.path
          ? !!matchPath(location.pathname, { path: tab.path, exact: true })
          : undefined

        return (
          <TabBarLink
            aria-selected={match === undefined ? undefined : match}
            exact
            to={tab.urlDescriptor || getPrevLocationDescriptor(tab.url)}
            useIcon={tab.icon}
            key={tab.title}
          >
            {createChain(
              <Text color={Token.color.greyTone20} px="s-4">
                ·
              </Text>,
            )(tab.title, tab.quickSummary)}
          </TabBarLink>
        )
      })}
    </TabBar>
  )
}
