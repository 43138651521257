import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@revolut/ui-kit'

import { Techscale } from './Techscale'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { GlobalStyle } from '../Landing/styles'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { Cardino } from './Cardino'
import { useScrollToTop } from '@src/hooks/useScrollToTop'

export const CustomerStoriesRouter = () => {
  useScrollToTop()

  return (
    <>
      <GlobalStyle />
      <ThemeProvider mode="dark">
        <Switch>
          <Route path={PUBLIC_ROUTES.CUSTOMER_STORIES.TECHSCALE}>
            <Techscale />
          </Route>
          <Route path={PUBLIC_ROUTES.CUSTOMER_STORIES.CARDINO}>
            <Cardino />
          </Route>
          <Route>
            <PageNotFoundWidget />
          </Route>
        </Switch>
      </ThemeProvider>
    </>
  )
}
