import React, { useMemo, useRef } from 'react'
import { Dropdown, HStack, Icon, Text, TextButton, useToggle } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'

interface Props {
  value: BaseOption<string>
  options: BaseOption<string>[]
  onSelect: (option: BaseOption<string>) => void
}

export const OrgUnitSelector = ({ value, options, onSelect }: Props) => {
  const dropdownRef = useRef(null)
  const [isDropdownOpen, dropdownToggler] = useToggle()

  const visibleOptions = useMemo(
    () => options.filter(({ id }) => id !== value.id),
    [value.id],
  )

  return (
    <>
      <TextButton ref={dropdownRef} onClick={dropdownToggler.switch}>
        <HStack space="s-4" align="center">
          <Text variant="emphasis1">{value.name}</Text>
          <Icon size={16} name={isDropdownOpen ? 'ChevronUp' : 'ChevronDown'} />
        </HStack>
      </TextButton>
      <Dropdown
        open={isDropdownOpen}
        anchorRef={dropdownRef}
        onClose={dropdownToggler.off}
        autoClose
      >
        {visibleOptions.map(option => (
          <Dropdown.Item key={option.id} use="button" onClick={() => onSelect(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}
