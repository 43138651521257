import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/HelpSections'
import { chain, Link, Text, VStack } from '@revolut/ui-kit'
import {
  HELP_DELIVERABLES_LINK,
  HELP_PERF_REVIEW_PLAYBOOK,
} from '@src/constants/externalLinks'
import { useGetGrades } from '@src/api/performanceSettings'

const DeliverablesHelp = ({ isCommercial }: { isCommercial?: boolean }) => {
  const { data: { results: gradesSettings = [] } = {} } = useGetGrades()
  const sections = [
    'Performance for goals is based on the overall calibrated progress percentage of goals the employee owned for this cycle.',
    <VStack key={1}>
      <Text>
        Goals are assigned a recommended grade based on the calibrated progress.
      </Text>
      <ul style={{ paddingInlineStart: 16, margin: 0 }}>
        {gradesSettings.map((grade, i) => (
          <li key={grade.id}>
            {chain(
              i === 0
                ? `Below ${grade.goal_performance_percent}%`
                : i === gradesSettings.length - 1
                ? `Above ${gradesSettings.at(i - 1)?.goal_performance_percent}%`
                : `${gradesSettings.at(i - 1)?.goal_performance_percent}% - ${
                    grade.goal_performance_percent
                  }%`,
              grade.label,
            )}
          </li>
        ))}
      </ul>
    </VStack>,
    'If an employee has not been assigned any goals, they should be reviewed based on the contribution and impact they have made to their team achieving its goals.',
    <VStack key={3}>
      <Text>When assessing an employee, a reviewer should consider:</Text>
      <ul style={{ paddingInlineStart: 16, margin: 0 }}>
        <li>How much was contributed this cycle given the size of the goals?</li>
        <li>Was the employee a critical driver for success?</li>
        <li>
          What has been the impact on business goals as a result of their contribution?
        </li>
        <li>Have they met the expectations for their seniority and role?</li>
        <li>Are they helping to raise the talent bar of those around them?</li>
      </ul>
    </VStack>,
    'If a selected rating is different from the expectations or recommendation for an employee, you will need to add a comment to justify your review.',
  ]

  if (!isCommercial) {
    sections.push(
      <Text>
        Please review the{' '}
        <Link target="_blank" href={HELP_PERF_REVIEW_PLAYBOOK} textDecoration="underline">
          Performance Assessment playbook
        </Link>{' '}
        for further guidance. You can read about how to review goals{' '}
        <Link target="_blank" href={HELP_DELIVERABLES_LINK} textDecoration="underline">
          here
        </Link>
        .
      </Text>,
    )
  }

  return <HelpSections sections={sections} />
}

export default DeliverablesHelp
