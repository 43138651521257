import React from 'react'
import { chain, Color, Flex, Text } from '@revolut/ui-kit'

import { useTable } from '@src/components/TableV2/hooks'
import { OffboardingScreenProps, taskStatusToColor, useOffboardingParams } from './common'
import {
  OffboardingTicketsInterface,
  OffboardingTicketsStatsInterface,
} from '@src/interfaces/offboarding'
import { RowInterface } from '@src/interfaces/data'
import { getOffboardingTickets, getOffboardingTicketsStats } from '@src/api/offboarding'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatWithoutTimezone } from '@src/utils/format'
import Stat from '@src/components/Stat/Stat'
import {
  offboardingTicketsDescriptionColumn,
  offboardingTicketsIssueColumn,
  offboardingTicketsOwnerColumn,
  offboardingTicketsStatusColumn,
  offboardingTicketsTitleColumn,
} from '@src/constants/columns/offboarding'
import { TableNames } from '@src/constants/table'
import { getEmployeePosition } from './helpers'

const row: RowInterface<OffboardingTicketsInterface> = {
  cells: [
    {
      ...offboardingTicketsIssueColumn,
      width: 150,
    },
    {
      ...offboardingTicketsTitleColumn,
      width: 200,
    },
    {
      ...offboardingTicketsDescriptionColumn,
      width: 200,
    },
    {
      ...offboardingTicketsOwnerColumn,
      width: 200,
    },
    {
      ...offboardingTicketsStatusColumn,
      width: 200,
    },
  ],
}

export const Tickets = ({ data }: OffboardingScreenProps) => {
  const task = data.offboarding_tasks.find(t => t.name.id === 'jira_tasks_progress')
  const params = useOffboardingParams()

  const table = useTable<OffboardingTicketsInterface, OffboardingTicketsStatsInterface>({
    getItems: getOffboardingTickets(params.employeeId),
    getStats: getOffboardingTicketsStats(params.employeeId),
  })

  if (!task) {
    return null
  }

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          'Offboarding tickets',
          <Text color={taskStatusToColor[task.status.id]}>{task.status.name}</Text>,
        )}
        subtitle={
          <Flex flexDirection="column" gap="s-4" mt="s-4">
            <Flex alignItems="center">
              {chain(
                <UserWithAvatar {...data.employee} asText />,
                <Text fontSize="16px" color={Color.FOREGROUND}>
                  {getEmployeePosition(data.employee)} at {data.employee.team.name},{' '}
                  {data.employee.location.name}
                </Text>,
              )}
            </Flex>
            <Text color={Color.GREY_TONE_50} variant="caption">
              {chain(
                `Last day in office: ${formatWithoutTimezone(
                  data.termination_date_time,
                )}`,
                `Offboarding manager: ${data.hr_manager?.full_name || '-'}`,
              )}
            </Text>
          </Flex>
        }
        backUrl={pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, params)}
      />
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="All tickets" val={table.stats?.total} />
          <Stat label="Progress" val={table.stats?.in_progress} color={Color.ORANGE} />
          <Stat label="Completed" val={table.stats?.done} color={Color.GREEN} />
        </Table.Widget.Info>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.OffboardingTickets}
            useWindowScroll
            dataType="Ticket"
            row={row}
            onRowClick={rowData =>
              window.open(rowData.jira_url, '_blank', 'noopener noreferrer')
            }
            {...table}
            noDataMessage="Tickets will appear here"
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageWrapper>
  )
}
