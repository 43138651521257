import React, { useState, PropsWithChildren } from 'react'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import { deleteEligibleGroup, useGetEligibleGroupsInfo } from '@src/api/reviewCycles'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  Text,
  Action,
  HStack,
  VStack,
  Box,
  DetailsCellSkeleton,
  ButtonSkeleton,
  Token,
  BoxProps,
  ItemSkeleton,
  ActionButton,
  ActionButtonSkeleton,
  Item,
  Widget,
} from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import {
  eligibleEmployeeContractColumn,
  eligibleEmployeeLocationColumn,
  eligibleEmployeeStartedAtColumn,
  eligibleEmployeeTypeColumn,
  employeeNameColumn,
  employeeStatusColumn,
} from '@src/constants/columns/employee'
import { EmployeeInterface } from '@src/interfaces/employees'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import NoDataWidget from '@src/pages/Forms/ReviewCycle/components/NoDataWidget'
import { EligibleGroupsFiltersSidebar } from './components/EligibleGroupsFiltersSidebar'
import styled from 'styled-components'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import pluralize from 'pluralize'
import { useGetGlobalEligibleGroupsInfo } from '@src/api/performanceSettings'
import { RequestInterfaceNew } from '@src/interfaces'

const ShadowGradient = styled.div`
  position: absolute;
  top: 169px;
  left: 1px;
  right: 1px;
  height: 70px;
  background: linear-gradient(0deg, #ffffff 18.23%, rgba(255, 255, 255, 0) 100%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

const ROW: RowInterface<EmployeeInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id })),
  cells: [
    {
      ...employeeNameColumn,
      width: 250,
    },
    {
      ...eligibleEmployeeTypeColumn,
      width: 90,
    },
    {
      ...eligibleEmployeeContractColumn,
      width: 100,
    },
    {
      ...eligibleEmployeeLocationColumn,
      width: 90,
    },
    {
      ...eligibleEmployeeStartedAtColumn,
      width: 110,
    },
    {
      ...employeeStatusColumn,
      width: 90,
    },
  ],
}

interface FormMutator {
  submit: (
    group: EligibleGroupInterface | Omit<EligibleGroupInterface, 'id'>,
  ) => Promise<void>
  delete: (groupId: number) => Promise<void>
}

interface EligibleEmployeesWidgetProps {
  cycleId?: string
  api?: RequestInterfaceNew<EligibleGroupInterface>
  configureFormMutator?: (groups: EligibleGroupInterface[]) => FormMutator
  disabled?: boolean
  allowDeleteAll?: boolean
  variant?: 'item' | 'table'
  useSidebarLayout: boolean
}

const WidgetWrapper = ({
  variant,
  children,
  ...boxProps
}: PropsWithChildren<BoxProps & Pick<EligibleEmployeesWidgetProps, 'variant'>>) => {
  return variant === 'table' ? (
    <Box {...boxProps}>{children}</Box>
  ) : (
    <Box
      border={`1px solid ${Token.color.greyTone10}`}
      borderRadius={Token.radius.r16}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export const EligibleEmployeesWidget = ({
  cycleId,
  api,
  configureFormMutator,
  disabled = false,
  allowDeleteAll = false,
  variant = 'table',
  useSidebarLayout,
}: EligibleEmployeesWidgetProps) => {
  const useGetGroups = cycleId
    ? useGetEligibleGroupsInfo.bind(null, cycleId)
    : useGetGlobalEligibleGroupsInfo
  const { data: groups, isLoading, refetch, isRefetching } = useGetGroups()
  const [openSidebar, setOpenSidebar] = useState(false)
  const [currentGroup, setCurrentGroup] = useState<EligibleGroupInterface>()
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [deletePending, setDeletePending] = useState(false)
  const formApi =
    configureFormMutator && groups ? configureFormMutator(groups.results) : undefined

  const handleDeleteGroup = async () => {
    try {
      setDeletePending(true)
      if (currentGroup?.id) {
        const deleteCb = formApi ? formApi.delete : deleteEligibleGroup
        await deleteCb(currentGroup.id)
        pushNotification({
          value: 'Group was deleted',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        setCurrentGroup(undefined)
        refetch()
      }
    } finally {
      setDeletePending(false)
      setShowDeletePopup(false)
    }
  }

  const pendingUI =
    variant === 'table' ? (
      <VStack space="s-16">
        <DetailsCellSkeleton height={370} />
        <ButtonSkeleton width={190} />
      </VStack>
    ) : (
      <VStack space="s-16" px="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ActionButtonSkeleton width={190} />
      </VStack>
    )

  const loadedUi = (
    <VStack space="s-16" pl="s-16" pr="s-16">
      {groups?.results.length ? (
        groups?.results.map(group => (
          <WidgetWrapper variant={variant} key={group.id}>
            <GroupTableWidget
              data={group}
              hideRows={variant === 'item'}
              onEdit={
                disabled
                  ? undefined
                  : item => {
                      setCurrentGroup(item)
                      setOpenSidebar(true)
                    }
              }
              onDelete={
                disabled || (!allowDeleteAll && groups?.results.length === 1)
                  ? undefined
                  : item => {
                      setCurrentGroup(item)
                      setShowDeletePopup(true)
                    }
              }
            />
          </WidgetWrapper>
        ))
      ) : variant === 'table' ? (
        <Box mb="s-16">
          <NoDataWidget text="No employees selected as eligible" />
        </Box>
      ) : null}
      {!disabled && (
        <ActionButton useIcon="Plus" onClick={() => setOpenSidebar(true)}>
          Add employee group
        </ActionButton>
      )}
    </VStack>
  )

  return (
    <>
      {isLoading || isRefetching ? pendingUI : loadedUi}

      <ConfirmationDialog
        open={showDeletePopup}
        onClose={() => {
          setCurrentGroup(undefined)
          setShowDeletePopup(false)
        }}
        onConfirm={handleDeleteGroup}
        onReject={() => {
          setCurrentGroup(undefined)
          setShowDeletePopup(false)
        }}
        label="Delete this group?"
        body="Deleting a group will permanently remove it"
        loading={deletePending}
        submitDisabled={deletePending}
        yesMessage="Delete"
        noMessage="Cancel"
      />

      <EligibleGroupsFiltersSidebar
        isOpen={openSidebar}
        useLayout={useSidebarLayout}
        handleClose={() => {
          setOpenSidebar(false)
          setCurrentGroup(undefined)
        }}
        groupData={currentGroup}
        refetchGroups={refetch}
        api={api}
        submit={formApi ? formApi.submit : undefined}
      />
    </>
  )
}

const GroupTableWidget = ({
  data,
  onEdit,
  onDelete,
  hideRows = false,
}: {
  data: EligibleGroupInterface
  onEdit?: (group: EligibleGroupInterface) => void
  onDelete?: (group: EligibleGroupInterface) => void
  hideRows?: boolean
}) => {
  return (
    <Widget>
      <Item>
        <Item.Content>
          <Item.Title>{data.name}</Item.Title>
          <Item.Description>
            <VStack gap="s-4">
              <Text variant="caption">
                {pluralize('filter', Object.keys(data.filters).length, true)} selected
              </Text>
              <Text variant="caption">
                {data.sign === 'exclude'
                  ? 'Set as ineligible'
                  : `Scorecards: ${
                      data.should_create_scorecards ? 'enabled' : 'disabled'
                    }`}
              </Text>
            </VStack>
          </Item.Description>
        </Item.Content>
        <Item.Side>
          {onEdit || onDelete ? (
            <HStack space="s-8">
              {!!onEdit && (
                <Action
                  onClick={() => {
                    onEdit(data)
                  }}
                >
                  Edit
                </Action>
              )}
              {!!onDelete && (
                <Action
                  onClick={() => {
                    onDelete(data)
                  }}
                >
                  Delete
                </Action>
              )}
            </HStack>
          ) : null}
        </Item.Side>
      </Item>
      {hideRows ? null : (
        <>
          <AdjustableTable<EmployeeInterface>
            name={TableNames.EligibleGroups}
            row={ROW}
            hideCountAndButtonSection
            rowHeight="medium"
            data={data.preview?.results || []}
            count={data.preview?.count || 0}
            noDataMessage="No eligible employees found"
            disabledFiltering
          />
          {data.preview?.count !== undefined && data.preview?.count > 5 && (
            <ShadowGradient />
          )}
        </>
      )}
    </Widget>
  )
}
