import React, { MouseEvent } from 'react'
import { Flex, Link } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'

export const extractHostname = (url: string) => {
  try {
    return new URL(url).hostname
  } catch (e) {
    return url
  }
}

type Props = {
  label?: string
  link: string
  noIcon?: boolean
  onClick?: (event: MouseEvent) => void
}

const OpenInNewTab = ({ label = 'Open in new tab', link, noIcon, onClick }: Props) => {
  return (
    <Link href={link} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <Flex alignItems="center">
        {!noIcon && (
          <>
            <LinkExternal size={14} />
            &nbsp;
          </>
        )}
        {label}
      </Flex>
    </Link>
  )
}

export default OpenInNewTab
