import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { EmployeeButtonProps } from '@src/pages/EmployeeProfile/Preview/components/Buttons/types'
import { canViewChangelog } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const Changelog = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const canView = canViewChangelog(data)

  if (!canView) {
    return null
  }

  const actionProps = {
    children: 'Changelog',
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG.EMPLOYEE_INFO, { id: String(data.id) }),
    use: InternalLink,
    useIcon: <Icon name="CalendarWeek" size={16} />,
  }

  return isDropdownItem ? (
    <Dropdown.Item {...actionProps} />
  ) : (
    <MoreBar.Action {...actionProps} />
  )
}
