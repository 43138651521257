import {
  EpicOption,
  KpiInterface,
  KpiReviewCycle,
  KpiTargetEpics,
} from '@src/interfaces/kpis'
import { FilterByInterface } from '@src/interfaces/data'
import { JiraRoadmapPriorities, RoadmapInterface } from '@src/interfaces/roadmaps'
import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { NameIdInterface } from '@src/interfaces/employees'
import pick from 'lodash/pick'

const getTypeSpecificFilters = (kpi: KpiInterface): FilterByInterface[] => {
  if (kpi.is_company) {
    return [
      {
        filters: [
          {
            name: JiraRoadmapPriorities.COMPANY,
            id: JiraRoadmapPriorities.COMPANY as any,
          },
          {
            name: JiraRoadmapPriorities.TRANSFORMATION,
            id: JiraRoadmapPriorities.TRANSFORMATION as any,
          },
        ],
        columnName: 'priority',
      },
    ]
  }
  if (kpi.team) {
    return [
      {
        filters: [{ name: `${kpi.team.id}`, id: kpi.team.id }],
        columnName: 'team_id',
      },
    ]
  }
  if (kpi.is_employee) {
    return [
      {
        filters: [{ name: `${kpi.owner?.id}`, id: kpi.owner?.id }],
        columnName: 'employee_id',
      },
    ]
  }
  if (kpi.department) {
    return [
      {
        filters: [{ name: `${kpi.department?.id}`, id: kpi.department?.id }],
        columnName: 'team_department_or_department',
      },
    ]
  }
  if (kpi.function) {
    return [
      {
        filters: [{ name: `${kpi.function.id}`, id: kpi.function.id }],
        columnName: 'function__id',
      },
    ]
  }
  if (kpi.role) {
    return [
      {
        filters: [{ name: `${kpi.role.id}`, id: kpi.role.id }],
        columnName: 'role__id',
      },
    ]
  }
  return []
}

export const getFilters = (kpi: KpiInterface, search: string): FilterByInterface[] => {
  return [
    ...getTypeSpecificFilters(kpi),
    ...(search
      ? [
          {
            filters: [{ name: search, id: search }],
            columnName: 'search',
          },
        ]
      : []),
    {
      filters: [
        { name: ReviewCycleStatus.ongoing, id: ReviewCycleStatus.ongoing },
        { name: ReviewCycleStatus.planned, id: ReviewCycleStatus.planned },
      ],
      columnName: 'review_cycle__status',
    },
  ]
}

// TODO: Remove this when BE is fixed
export const transformCycle = (
  reviewCycle: ReviewCyclesInterface | KpiReviewCycle,
): KpiReviewCycle => {
  return pick(reviewCycle, ['id', 'name', 'offset', 'category', 'status'])
}

export const parseRoadmapToEpic = (roadmap: RoadmapInterface): EpicOption =>
  ({
    id: roadmap.id,
    key: roadmap.key || null,
    name: roadmap.name,
    url: roadmap.epic_url,
    owner: roadmap.owner as NameIdInterface,
    due_date: roadmap.due_date,
    progress: roadmap.progress,
    done: roadmap.done,
    todo: roadmap.todo,
    unitName: roadmap.organisational_unit?.name,
    type: roadmap.type,
  } as EpicOption)

export const getInitialValuesRoadmapTarget = (
  kpi: KpiInterface,
  defaultCycle?: KpiReviewCycle,
): Partial<KpiTargetEpics> => {
  const canBeTopDown = !!kpi.team || !!kpi.department
  return {
    kpi: { id: kpi.id },
    epics: [],
    parent_kpi: null,
    is_top_down: false,
    review_cycle: defaultCycle ? transformCycle(defaultCycle) : undefined,
    owner: canBeTopDown ? kpi.owner : undefined,
  }
}
