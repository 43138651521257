import React from 'react'
import { VStack, type BoxProps, Box, Flex, Token, ImageProps } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import * as SS from 'styled-system'

import { FeatureBlockImage } from './FeatureBlockImage'
import { Text } from '../components/Text'
import { FeatureBlockVideo } from '@src/pages/Landing/components/FeatureBlockVideo'
import { BookDemoButton } from '@src/pages/Landing/components/BookDemoButton'
import { SignUpNowButton } from './SignupNowButton'
import { TakeAProductTourButton } from './TakeAProductTourButton'

export enum FeatureBlockVariant {
  Left = 'left',
  Right = 'right',
}

export enum FeatureBlockAlign {
  Cover = 'cover',
  Contain = 'contain',
}

const FeatureBlockLayout = (props: BoxProps) => (
  <Box
    marginX="auto"
    paddingY={{ all: 's-24', lg: '40px', xxl: '80px' }}
    paddingX={{ all: 's-20', lg: 0 }}
    use="section"
    width="100%"
    {...props}
  />
)

const FeatureBlockBase = styled(FeatureBlockLayout)<{ variant: FeatureBlockVariant }>(
  css({
    alignItems: 'center',
    display: 'grid',
    gap: ['s-24', null, null, null, null, 's-40'],
  }),
  SS.variant({
    variants: {
      [FeatureBlockVariant.Left]: {
        gridTemplateColumns: ['auto', null, null, '50%'],
        gridTemplateAreas: [`'content' 'image'`, null, null, `'image content'`],
      },
      [FeatureBlockVariant.Right]: {
        gridTemplateColumns: ['auto', null, null, '50%'],
        gridTemplateAreas: [`'content' 'image'`, null, null, `'content image'`],
      },
    },
  }),
)

export interface FeatureBlockContent {
  description?: string | React.ReactNode
  image?: string
  video?: string
  title?: string | React.ReactNode
  caption?: string
  variant?: FeatureBlockVariant
  withPadding?: boolean
  url?: string
  showBookDemoButton?: boolean
  showSignUpButton?: boolean
  baseProps?: BoxProps
  imageContainerProps?: BoxProps
  imageProps?: ImageProps
  contentProps?: BoxProps
}

export const FeatureBlock = ({
  image,
  video,
  title,
  description,
  caption,
  url,
  variant = FeatureBlockVariant.Left,
  withPadding = false,
  showBookDemoButton = true,
  showSignUpButton = false,
  baseProps,
  imageContainerProps,
  imageProps,
  contentProps,
}: FeatureBlockContent) => {
  return (
    <FeatureBlockBase {...baseProps} variant={variant}>
      {!video && image && (
        <Box
          {...imageContainerProps}
          pl={
            withPadding && variant === FeatureBlockVariant.Left
              ? { all: 0, lg: 's-32' }
              : 0
          }
          pr={
            withPadding && variant === FeatureBlockVariant.Right
              ? { all: 0, lg: 's-32' }
              : 0
          }
        >
          <FeatureBlockImage
            {...imageProps}
            image={image}
            align={FeatureBlockAlign.Contain}
          />
        </Box>
      )}
      {video && <FeatureBlockVideo video={video} poster={image} />}
      <VStack
        align={{ all: 'center', lg: 'start' }}
        gridArea="content"
        space={{ all: 's-16', md: 's-24', xxl: 's-32' }}
        maxWidth={488}
        margin={{
          all: '0 auto',
          lg: variant === FeatureBlockVariant.Right ? '0 0 0 auto' : 0,
        }}
        {...contentProps}
      >
        {caption && (
          <Text
            variant="h6"
            color={Token.color.greyTone50}
            textAlign={{ all: 'center', lg: 'start' }}
          >
            {caption}
          </Text>
        )}
        {title && (
          <Text
            use="h2"
            variant="h2"
            whiteSpace="pre-wrap"
            textAlign={{ all: 'center', lg: 'start' }}
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
            variant="subtitle1"
            whiteSpace="pre-wrap"
            use="div"
            textAlign={{ all: 'center', lg: 'start' }}
          >
            {description}
          </Text>
        )}
        <Flex gap="s-24" justifyContent={{ all: 'start', md: 'center' }}>
          {showSignUpButton ? <SignUpNowButton /> : null}
          {showBookDemoButton ? (
            <BookDemoButton variant={showSignUpButton ? 'black' : 'primary'} />
          ) : null}
          {url ? <TakeAProductTourButton url={url} /> : null}
        </Flex>
      </VStack>
    </FeatureBlockBase>
  )
}
