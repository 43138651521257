import { IdAndName } from '.'
import { ImportSessionState } from '@src/interfaces/bulkDataImport'

interface IntegrationPreferencesBase {
  id: number
  enabled: boolean
}

export interface SlackPreferencesInterface extends IntegrationPreferencesBase {}

export interface SlackBotInterface {
  id: number
  name: string
  description: string
  scopes: string
  access_token: string
}

export interface ClickupIntegrationInterface extends IntegrationPreferencesBase {
  is_external_app: boolean
  client_id: string
  redirect_uri: string
  server: string
  client_secret?: string
}

export interface OktaIntegrationInterface extends IntegrationPreferencesBase {
  metadata_url: string
}

export interface TableauIntegrationInterface extends IntegrationPreferencesBase {
  creation_date_time: string
  server: string
  update_date_time: string
  owner: { id: number }
  client_id?: string
  server_id?: string
  secret_value: string
}

export interface SalesforceIntergrationInterface extends IntegrationPreferencesBase {
  server: string
  is_dedicated_connected_app?: boolean
}

export interface LinearIntegrationInterface extends IntegrationPreferencesBase {
  client_id?: string
  creation_date_time: string
  update_date_time: string
  redirect_uri: string
}

export interface LinearIntegrationInterfacePayload extends IntegrationPreferencesBase {
  client_id: string
  client_secret: string
}

export interface JiraPreferencesInterface extends IntegrationPreferencesBase {
  create_hr_tickets_enabled: boolean
  server: string
  username: string
  api_key: string
  hr_project_key: string
  hrsd_project_key: string
  connection_type?: IdAndName<'jira'> // used on FE only so far
  issue_key?: string // used on FE only so far
}

export interface LookerIntegrationInterface extends IntegrationPreferencesBase {
  creation_date_time: string
  server: string
  update_date_time: string
  method: IdAndName<'basic' | 'oauth'>
  client_guid?: string
}

export interface JiraConnectionTestInterface {
  issue_key: string
  api_key?: string
  server?: string
  username?: string
}

export interface JiraConnectionTestResult {
  success: boolean
  detail: string
}

export interface GoogleCalendarPreferencesInterface extends IntegrationPreferencesBase {
  credentials: string
}

export interface DocusignPreferencesInterface extends IntegrationPreferencesBase {
  base_path: string
  oauth_host_name: string
  client_id: string
  user_id: string
  account_id: string
  private_key: string
  connect_enabled: boolean
  connect_username: string
  connect_password: string
}

export interface DocusignConsentInterface {
  url: string | null
}

export interface DocusignUsersInterface {
  id: number
  creation_date_time: string
  email: string
  permission_profile_id: string
  status: IdAndName<'active' | 'closed'>
  user_id: string
  update_date_time: string
}

export const MergeIntegrations = ['bamboohr', 'hibob', 'personio'] as const
export type MergeIntegration = typeof MergeIntegrations[number]

export interface MergeConnectionInterface {
  id: string
  integration_type: MergeIntegration
  sync_all_statuses: boolean | null
  state: 'active' | 'initiated' | 'cancelled' | 'deleted'
  last_sync: {
    state: ImportSessionState
    update_date_time: string
    id: number
    auto_apply: boolean
  } | null
  available_fields: {
    id: string
    name: string
    required: boolean
  }[]
  selected_fields: IdAndName<string>[]
}

export interface MergeConnectionRequest {
  integration_type: MergeIntegration
  sync_all_statuses?: boolean
}

export interface MergeConnectionResponse {
  id: string
  token: string
}
