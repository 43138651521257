import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'

type OrganisationSettingsItemId = 'organisationTeams' | 'organisationDepartments'

export const organisationSettingsLinkConfig: SettingsLinkItemConfig<OrganisationSettingsItemId>[] =
  [
    {
      id: 'organisationTeams',
      section: 'Organisation settings',
      icon: 'Bank',
      title: 'Teams',
      to: ROUTES.SETTINGS.ORGANISATION.TEAMS,
      subtitle: (
        <FormattedMessage
          id="settings.organisation.teams"
          defaultMessage="Settings that apply to all teams"
        />
      ),
      permissions: [PermissionTypes.ViewOrganisationPreferences],
    },
    {
      id: 'organisationDepartments',
      section: 'Organisation settings',
      icon: 'Services',
      title: 'Departments',
      to: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
      subtitle: (
        <FormattedMessage
          id="settings.organisation.departments"
          defaultMessage="Settings that apply to all departments"
        />
      ),
      permissions: [PermissionTypes.ViewOrganisationPreferences],
    },
  ]

export const isGeneralOrganisationSettingsHidden = (
  settings?: OrganisationSettingsInterface,
) => {
  const findingsHidden = !settings?.findings?.visible
  const riskHidden = !settings?.risk?.visible
  const bugsTrackingHidden = !settings?.bugs_tracking?.visible
  const cxHidden = !settings?.customer_experience?.visible
  const dataAccessRequestHidden = !settings?.data_access_requests?.visible

  return (
    cxHidden &&
    bugsTrackingHidden &&
    findingsHidden &&
    riskHidden &&
    dataAccessRequestHidden
  )
}
