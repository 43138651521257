import React from 'react'
import { Absolute, BoxProps, Flex, Token } from '@revolut/ui-kit'

import { Text } from './Text'
import { ROUTES, WORKSPACES } from '@src/constants/routes'

import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import { pathToUrl } from '@src/utils/router'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { BookDemoButton } from '@src/pages/Landing/components/BookDemoButton'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { navigateTo } from '@src/actions/RouterActions'
import { isDevWorkspacesEnabled } from '@src/utils'

export const LandingHeader = (props: BoxProps) => {
  const workspaceContext = useWorkspaceContext()

  const { sendAnalyticsEvent } = useAnalytics()

  return (
    <Absolute top={0} width="100%" {...props}>
      <Flex
        maxWidth={{ all: '1000px', xxl: '1432px' }}
        width="100%"
        margin="auto"
        justifyContent="space-between"
        px="s-16"
        mt="s-16"
        alignItems="center"
      >
        <InternalLink to={pathToUrl(ROUTES.MAIN)}>
          <LogoAlt width="200" />
        </InternalLink>

        <>
          <Flex gap="s-24" alignItems="center" display={{ all: 'none', md: 'flex' }}>
            {isDevWorkspacesEnabled() ? (
              <Button
                // @ts-ignore
                variant="text"
                onClick={() => {
                  const lastWorkspace = workspaceContext?.workspaces[0]

                  if (lastWorkspace) {
                    window.location.href = `/${lastWorkspace.name}`
                  } else {
                    navigateTo(WORKSPACES.MAIN)
                  }
                }}
                style={{ color: Token.color.foreground }}
              >
                Log in
              </Button>
            ) : null}

            <InternalLink
              to={pathToUrl(ROUTES.SIGNUP.MAIN)}
              onClick={() => {
                sendAnalyticsEvent(AnalyticsEvents.click_signup)
              }}
            >
              <Text variant="primary" fontWeight={500}>
                Sign up
              </Text>
            </InternalLink>

            <BookDemoButton />
          </Flex>

          <Button
            // @ts-ignore
            variant="secondary"
            use={InternalLink}
            to={pathToUrl(ROUTES.SIGNUP.MAIN)}
            display={{ all: 'flex', md: 'none' }}
          >
            Sign up
          </Button>
        </>
      </Flex>
    </Absolute>
  )
}
