import React, { PropsWithChildren } from 'react'
import {
  Action,
  ActionButton,
  DragAndDrop,
  Flex,
  HStack,
  Token,
  Widget,
} from '@revolut/ui-kit'

interface EvaluationSectionProps {
  disableActions: boolean
  isEditing?: boolean
  isSubmitting?: boolean
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDelete?: VoidFunction
  onEdit?: VoidFunction
}

export const EvaluationSection = ({
  children,
  disableActions,
  isEditing,
  isSubmitting,
  sortable,
  onDelete,
  onEdit,
}: PropsWithChildren<EvaluationSectionProps>) => {
  return (
    <Widget
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Flex justifyContent="space-between" px="s-16" pt="s-16">
        <Action
          {...sortable?.attributes}
          {...sortable?.listeners}
          color={Token.color.greyTone50}
          iconOnly
          ref={sortable?.setNodeRef}
          useIcon="Drag"
          disabled={disableActions || isEditing}
        />
        <HStack gap="s-16">
          <ActionButton
            aria-label="Edit"
            disabled={disableActions}
            iconOnly
            size="xs"
            useIcon={isEditing ? 'Check' : 'Pencil'}
            pending={isEditing && isSubmitting}
            onClick={onEdit}
          />
          <ActionButton
            disabled={disableActions}
            aria-label="Delete"
            iconOnly
            size="xs"
            useIcon="Delete"
            onClick={onDelete}
          />
        </HStack>
      </Flex>
      {children}
    </Widget>
  )
}
