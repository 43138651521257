import {
  MeetingTemplateFrequency,
  MeetingTemplateInterface,
  MeetingTemplateStatus,
  TemplateItemContentType,
  TemplateItemInterface,
  TemplateItemRole,
} from '@src/interfaces/meetings'
import { MeetingTemplateEditStep } from './interfaces'
import { IconName } from '@revolut/ui-kit'
import {
  AudienceIdType,
  AudienceInterface,
} from '@src/components/AddAudienceSidebar/interfaces'

export const isStepDone = (
  step: MeetingTemplateEditStep,
  values?: Partial<MeetingTemplateInterface>,
) => {
  switch (step) {
    case 'audience':
      return !!values?.audiences?.length
    case 'general':
      return !!(values?.name && values?.duration)
    case 'fields':
      return values?.items?.length || values?.enable_ai
    default:
      return false
  }
}

export const getTemplateStatus = (
  audiences: AudienceInterface[],
  templateItems: TemplateItemInterface[],
  name: string,
  frequency: MeetingTemplateFrequency | null,
  duration: number | null,
): MeetingTemplateStatus => {
  const hasContentItems = Boolean(templateItems.length > 0)
  const isActiveTemplate =
    audiences?.length > 0 && name && frequency && duration && hasContentItems
  return isActiveTemplate ? MeetingTemplateStatus.Active : MeetingTemplateStatus.Draft
}

export const mapAudienceIcon = (id: AudienceIdType): IconName => {
  switch (id) {
    case 'department':
      return 'Bank'
    case 'seniority':
      return 'Trophy'
    case 'role':
      return 'Profile'
    case 'specialisation':
      return 'IndustrialGear'
    case 'function':
      return 'RepairTool'
    case 'team':
    case 'dynamic_group':
    default:
      return 'People'
  }
}

export const itemFieldsSetupEntitiesRecord = {
  [TemplateItemRole.TalkingPoints]: {
    title: 'Talking points',
    content_type: TemplateItemContentType.TextList,
    content: [],
    role: TemplateItemRole.TalkingPoints,
    is_private: false,
    is_available_before_start: true,
  },
  [TemplateItemRole.ActionItems]: {
    title: 'Action items',
    content_type: TemplateItemContentType.TextCheckbox,
    content: [],
    role: TemplateItemRole.ActionItems,
    is_private: false,
    is_available_before_start: false,
  },
  [TemplateItemRole.PerformanceRating]: {
    title: 'Performance rating',
    content_type: TemplateItemContentType.DropdownSingleChoice,
    content: [
      { value: 'unsatisfactory', name: 'unsatisfactory' },
      { value: 'average_minus', name: 'average minus' },
      { value: 'average_plus', name: 'average plus' },
      { value: 'strong', name: 'strong' },
      { value: 'exceptional', name: 'exceptional' },
    ],
    role: TemplateItemRole.PerformanceRating,
    is_private: true,
    is_available_before_start: false,
  },
  [TemplateItemRole.PersonalNotes]: {
    title: 'Personal notes',
    content_type: TemplateItemContentType.Text,
    content: '',
    role: TemplateItemRole.PersonalNotes,
    is_private: true,
    is_available_before_start: false,
  },
}
