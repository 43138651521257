import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { LinkedInIntegrationItem } from '@src/pages/Hub/Integrations/LinkedIn/LinkedInIntegrationItem'

export const LinkedInIntegrationPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="LinkedIn"
        subtitle="Job boards"
        backUrl={ROUTES.SETTINGS.INTEGRATIONS}
      />
      <PageBody>
        <LinkedInIntegrationItem />
      </PageBody>
    </PageWrapper>
  )
}
