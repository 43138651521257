import React, { useState } from 'react'
import {
  copyToClipboard,
  Button,
  StatusPopup,
  TextButton,
  useStatusPopup,
} from '@revolut/ui-kit'
import { getBookingLinkByDefinitionId } from '@src/api/bookingLinks'
import { Copy } from '@revolut/icons'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import {
  getMessageFromApiError,
  pushNotification,
} from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { useExtensionApiContext } from '@src/utils/extension'

type GenerateLinkPropTypes = {
  bookingLinkId: number
}

const CopyLink = ({ url }: { url: string }) => {
  const [isCopied, setIsCopied] = useState(false)
  return (
    <Button
      variant="secondary"
      useIcon={Copy}
      onClick={() => {
        copyToClipboard(url).then(() => {
          setIsCopied(true)
          pushNotification({
            value: 'Booking link copied to clipboard',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        })
      }}
    >
      {isCopied ? 'Copied link' : 'Copy link'}
    </Button>
  )
}

const GenerateBookingLink = ({ bookingLinkId }: GenerateLinkPropTypes) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const apiHandler = useExtensionApiContext()
  const permissions = useSelector(selectPermissions)
  const canGenerateBookingLink = permissions.includes(PermissionTypes.AddBookingLink)
  const statusPopup = useStatusPopup()

  const showLoading = () =>
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Generating link</StatusPopup.Title>
      </StatusPopup>,
    )

  const showSuccess = (token: string) => {
    const url = pathToFullUrlWithActiveWorkspace(PUBLIC_ROUTES.BOOKING_LINK.GENERAL, {
      token,
    })
    statusPopup.show(
      <StatusPopup variant="success-result">
        <StatusPopup.Title>Generated link</StatusPopup.Title>
        <StatusPopup.Description>{url}</StatusPopup.Description>
        <StatusPopup.Actions>
          <CopyLink url={url} />
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const showError = (message: string | null) =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Error generating link</StatusPopup.Title>
        {message && <StatusPopup.Description>{message}</StatusPopup.Description>}
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )

  const handleGenerateLink = async () => {
    showLoading()
    try {
      const { data } = await getBookingLinkByDefinitionId(bookingLinkId, apiHandler)
      const { token } = data
      sendAnalyticsEvent(AnalyticsEvents.booking_link_generated)
      showSuccess(token)
    } catch (e) {
      showError(getMessageFromApiError(e))
    }
  }

  return (
    <TextButton
      fontWeight={500}
      onClick={handleGenerateLink}
      disabled={!canGenerateBookingLink}
    >
      Generate link
    </TextButton>
  )
}

export default GenerateBookingLink
