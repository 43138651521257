import React from 'react'
import { Token } from '@revolut/ui-kit'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { PerformanceRating, Ratings } from '@src/interfaces/performance'
import styled from 'styled-components'
import RatingTooltip from '@src/pages/EmployeeProfile/Preview/Performance/Common/RatingTooltip'
import { placement as TooltipPlacement } from '@components/Tooltip/Tooltip'

const Tooltip = styled(RatingTooltip)`
  color: ${Token.color.teal};
`

interface SummaryRatingProps {
  rating?: PerformanceRating
  ratings?: Ratings[]
  tooltipPlacement?: TooltipPlacement
}

export const SummaryRating = ({
  rating,
  ratings,
  tooltipPlacement = 'left',
}: SummaryRatingProps) => {
  if (rating && ratings) {
    return (
      <Tooltip ratings={ratings} totalRating={rating} placement={tooltipPlacement}>
        <PerformanceRatingLabelTag rating={rating} fontSize="caption" />
      </Tooltip>
    )
  }
  if (rating) {
    return <PerformanceRatingLabelTag rating={rating} fontSize="caption" />
  }
  return null
}
