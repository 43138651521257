import { IconName } from '@revolut/ui-kit'

export const UNITED_KINDGOM_COUNTRY_CODE = 'GB'

export const FUNCTION_DEFAULT_ICON: IconName = 'RepairTool'
export const SKILLS_DEFAULT_ICON: IconName = 'Palette'
export const SURVEYS_DEFAULT_ICON: IconName = 'Message'
export const DEPARTMENT_DEFAULT_ICON: IconName = 'Bank'
export const TEAM_DEFAULT_ICON: IconName = 'People'
export const DASHBOARD_DEFAULT_ICON: IconName = 'BarChart'
export const QUERY_DEFAULT_ICON: IconName = '16/Code'
export const DOCUMENT_DEFAULT_ICON: IconName = 'DocumentsPair'
export const GOAL_DEFAULT_ICON: IconName = 'Target'
export const COMPANY_DEFAULT_ICON: IconName = 'Bank'
export const ORGANISATION_STRUCTURE_DEFAULT_ICON: IconName = 'Bank'
export const EMPLOYEE_DEFAULT_ICON: IconName = 'Profile'
