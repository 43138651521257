import React from 'react'
import { Bar, BarProps } from 'recharts'
import { Token } from '@revolut/ui-kit'
import {
  getActiveShapeColor,
  getColorByIndex,
  getMainShapeColor,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { StackedRectBarShape } from '@src/features/ReviewCycle/Analytics/Charts/components/StackedRectBarShape'

// recharts does not support wrapping Bar component in custom wrappers, having <StackedRectBar /> is not possible
// https://github.com/recharts/recharts/issues/2788
export const renderStackedRectBar = <T extends DataKey<Record<string, number>>>(
  dataKey: T,
  index: number,
) => (
  <Bar
    key={dataKey.id}
    dataKey={String(dataKey.value)}
    fill={getMainShapeColor(dataKey.color || Token.colorChannel[getColorByIndex(index)])}
    shape={(props: BarProps) => (
      <StackedRectBarShape
        {...props}
        fill={getMainShapeColor(
          dataKey.color || Token.colorChannel[getColorByIndex(index)],
        )}
      />
    )}
    activeBar={(props: BarProps) => (
      <StackedRectBarShape
        {...props}
        fill={getActiveShapeColor(
          dataKey.color || Token.colorChannel[getColorByIndex(index)],
        )}
      />
    )}
    stackId="stack"
  />
)
