import React from 'react'
import { Avatar, Group, Item, ItemSkeleton, Token } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { usePromotionEligibilityCriteria } from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/usePromotionEligibility'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { EligibilityCheckItem } from '@src/interfaces/promotions'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
}

export const PromotionEligibility = ({ employee, growthPlan }: Props) => {
  const { isLoading, data } = usePromotionEligibilityCriteria(employee, growthPlan)

  if (isLoading) {
    return <Skeleton />
  }

  if (data.length === 0) {
    return null
  }

  return (
    <Item>
      <Item.Content>
        <Item.Title>Eligibility for Promotion</Item.Title>
        <Item.Description>
          The following criteria will need to be met for the successful promotion
        </Item.Description>
        <Group style={transparentThemeBackgroundOverrides} ml="-s-16">
          {data.map(criterion => (
            <EligibilityCriterion
              key={criterion.eligibility_criteria.id}
              {...criterion}
            />
          ))}
        </Group>
      </Item.Content>
    </Item>
  )
}

interface EligibilityCriterionProps extends EligibilityCheckItem {}

const EligibilityCriterion = ({
  passed,
  eligibility_criteria: { name },
}: EligibilityCriterionProps) => (
  <Item>
    <Item.Avatar>
      <Avatar
        useIcon={passed ? 'Check' : 'Cross'}
        color={passed ? Token.color.success : Token.color.danger}
      />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{name}</Item.Title>
    </Item.Content>
  </Item>
)

const Skeleton = () => (
  <ItemSkeleton>
    <ItemSkeleton.Content>
      <ItemSkeleton.Title />
      <ItemSkeleton.Description />
      <Group style={transparentThemeBackgroundOverrides} ml="-s-16">
        <ItemSkeleton>
          <ItemSkeleton.Avatar />
          <ItemSkeleton.Content>
            <ItemSkeleton.Title />
            <ItemSkeleton.Description />
          </ItemSkeleton.Content>
        </ItemSkeleton>
        <ItemSkeleton>
          <ItemSkeleton.Avatar />
          <ItemSkeleton.Content>
            <ItemSkeleton.Title />
            <ItemSkeleton.Description />
          </ItemSkeleton.Content>
        </ItemSkeleton>
      </Group>
    </ItemSkeleton.Content>
  </ItemSkeleton>
)
