import React, { useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Banner, Avatar, VStack, HStack, Token, H5, FilterButton } from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import {
  activateTimeOffPolicy,
  getTimeOffPoliciesEligibleEmployeesTableRequests,
  useTimeOffReviewStats,
} from '@src/api/timeOff'
import { TimeOffPolicyReviewEligibleEmployeeInterface } from '@src/interfaces/timeOff'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import Stat from '@src/components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import {
  timeOffPolicyReviewCurrentBalanceColumn,
  timeOffPolicyReviewEmployeeColumn,
  timeOffPolicyReviewNewBalanceColumn,
  timeOffPolicyReviewStatusColumn,
} from '@src/constants/columns/timeOffPolicyReview'
import { PolicyEditTabCommonProps } from './common'
import { NextTabButton } from './NextTabButton'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

const ROW: RowInterface<TimeOffPolicyReviewEligibleEmployeeInterface> = {
  cells: [
    {
      ...timeOffPolicyReviewEmployeeColumn,
      width: 250,
    },
    {
      ...timeOffPolicyReviewCurrentBalanceColumn,
      width: 200,
    },
    {
      ...timeOffPolicyReviewNewBalanceColumn,
      width: 200,
    },
    {
      ...timeOffPolicyReviewStatusColumn,
      width: 200,
    },
  ],
}

export const PolicyEditReviewTab = ({
  basicStepInfo,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const { id } = useParams<{ id: string }>()

  const { data: stats } = useTimeOffReviewStats(id)
  const table = useTable(getTimeOffPoliciesEligibleEmployeesTableRequests(id))

  const filtersByStatus =
    table.filterBy.find(
      filterBy => filterBy.columnName === 'auto_assignments_details__status__id',
    )?.filters || []

  const isFilteredByWarnings =
    filtersByStatus.some(filter => filter.id === 'policy_will_not_apply') &&
    filtersByStatus.some(filter => filter.id === 'policy_will_partially_apply')

  const toggleFilterByWarnings = () => {
    table.onFilterChange(
      isFilteredByWarnings
        ? {
            columnName: 'auto_assignments_details__status__id',
            filters: [],
          }
        : {
            columnName: 'auto_assignments_details__status__id',
            filters: [
              { id: 'policy_will_not_apply', name: 'policy_will_not_apply' },
              { id: 'policy_will_partially_apply', name: 'policy_will_partially_apply' },
            ],
          },
    )
  }
  const isCustomerOnboarding = useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.ANY)

  const showStatusPopup = useShowStatusPopup()

  const [isRecalculateBalances, setIsRecalculateBalances] = useState(false)

  return (
    <>
      <VStack gap="s-16">
        <Banner>
          <Banner.Avatar>
            <Avatar variant="brand" useIcon="Lightbulb" size={56} />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>
              Let’s review the policy assignment before applying it
            </Banner.Title>
            <Banner.Description>
              Please check any warnings so that employees don’t have wrong policies
              applied
            </Banner.Description>
          </Banner.Content>
        </Banner>
        <ItemSwitch
          checked={isRecalculateBalances}
          description="If turned on, we will recalculate all balances for all employees."
          onChange={event => setIsRecalculateBalances(event.target.checked)}
          title="Re-calculate balance amount"
        />
        <Table.Widget>
          <Table.Widget.Info>
            <VStack gap="s-16">
              <H5>Review and confirm these employees affected by your new policy</H5>
              <HStack gap="s-32">
                <Stat label="Total" mb="s-12" val={stats?.total} />
                <Stat
                  label="Warning"
                  mb="s-12"
                  val={stats?.warning}
                  color={Token.color.orange}
                />
                <Stat label="OK" mb="s-12" val={stats?.ok} color={Token.color.green} />
              </HStack>
            </VStack>
          </Table.Widget.Info>
          <Table.Widget.Filters>
            <FilterButton
              active={isFilteredByWarnings}
              onClick={toggleFilterByWarnings}
              pending={table.loading}
            >
              Show warnings
            </FilterButton>
          </Table.Widget.Filters>
          <Table.Widget.Table>
            <AdjustableTable
              {...table}
              dataType="Employees"
              name={TableNames.TimeOffPoliciesReview}
              noDataMessage="No employees will be affected as auto-assign is disabled."
              row={ROW}
              useWindowScroll
            />
          </Table.Widget.Table>
        </Table.Widget>
      </VStack>
      <NextTabButton
        disabled={!basicStepInfo?.id}
        onAfterSubmit={async () => {
          let activatePolicyResponse
          try {
            activatePolicyResponse = await activateTimeOffPolicy({
              id: basicStepInfo!.id,
              recalculateBalances: isRecalculateBalances,
              updatePolicyAssignments: !isCustomerOnboarding,
            })
          } catch (err) {
            Sentry.captureException(err)
            showStatusPopup({
              title: 'Failed to activate the policy',
              description: 'Something went wrong. Please, try again later.',
              status: 'error',
            })
            return Promise.reject()
          }
          if (isCustomerOnboarding) {
            goBack()
          } else if (activatePolicyResponse) {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, {
                id: activatePolicyResponse.data.id,
              }),
            )
          }
          return Promise.resolve()
        }}
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
