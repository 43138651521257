import React from 'react'
import { Token } from '@revolut/ui-kit'
import { GradeDistributionHistoryItemData } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/GradeDistributionBarChart/ChartData'
import {
  DistributionTooltip,
  DistributionTooltipData,
} from '@src/features/ReviewCycle/Analytics/Charts/components/DistributionTooltip'
import { GradeDistributionDataKey } from '@src/features/ReviewCycle/Analytics/Charts/GradesDistributionBySeniorityBarChart/ChartData'

export const Tooltip = (
  props: GradeDistributionHistoryItemData,
  dataKeys?: GradeDistributionDataKey[],
  colors?: string[],
) => {
  const data = (dataKeys ?? []).map<DistributionTooltipData>((dataKey, index) => {
    const grade = props.rawData.find(({ id }) => id === dataKey.value)

    return {
      count: grade?.count ?? 0,
      percentage: grade?.percent ?? 0,
      ...dataKey,
      color: colors ? colors[index] : Token.color.greyTone50,
    }
  })

  return <DistributionTooltip title={props.label} data={data} />
}
