import React from 'react'
import {
  ActionButton,
  Item,
  Placeholder,
  VStack,
  Text,
  ItemSkeleton,
  Avatar,
  Token,
} from '@revolut/ui-kit'

import { EngagementAnswerInterface } from '@src/interfaces/engagement'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { formatDateTime } from '@src/utils/format'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { acknowledgeEngagementAnswer } from '@src/api/engagement'

interface Props {
  answer: EngagementAnswerInterface | undefined
  isLoading?: boolean
  canAcknowledge?: boolean
  onAcknowledge?: VoidFunction
}
export const AnswerWidget = ({
  isLoading,
  answer,
  canAcknowledge,
  onAcknowledge,
}: Props) => {
  const avatarImageId = answer?.answer_text ? '3D278' : '3D066'
  const isAcknowledged = !!answer?.acknowledged
  const showStatusPopup = useShowStatusPopup()

  return (
    <>
      {isLoading ? (
        <ItemSkeleton />
      ) : answer ? (
        <Item>
          <Item.Avatar>
            <Avatar
              image={{
                default: `https://assets.revolut.com/assets/3d-images-business/${avatarImageId}.png`,
                '2x': `https://assets.revolut.com/assets/3d-images-business/${avatarImageId}@2x.png`,
                '3x': `https://assets.revolut.com/assets/3d-images-business/${avatarImageId}@3x.png`,
              }}
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              {answer.answer_text || (
                <Text color={Token.color.greyTone50}>
                  This answer does not have text comment
                </Text>
              )}
            </Item.Title>
            <Item.Description>
              <VStack pt="s-4" space="s-8">
                {formatDateTime(answer.creation_date_time)}
                {canAcknowledge && (
                  <ActionButton
                    useIcon={isAcknowledged ? '16/CheckCircle' : undefined}
                    variant={isAcknowledged ? 'accent' : undefined}
                    size="xs"
                    onClick={async () => {
                      try {
                        await acknowledgeEngagementAnswer(answer.id)
                        onAcknowledge?.()

                        showStatusPopup({
                          title: 'Answer was acknowledged',
                        })
                      } catch (e) {
                        showStatusPopup({
                          status: 'error',
                          title: 'Could not update acknowledgement status',
                          description: getMessageFromApiError(e),
                        })
                      }
                    }}
                  >
                    {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
                  </ActionButton>
                )}
              </VStack>
            </Item.Description>
          </Item.Content>
        </Item>
      ) : (
        <Placeholder mt="s-64">
          <Placeholder.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D028.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@3x.png',
            }}
          />
          <Placeholder.Title>Answer was not found</Placeholder.Title>
        </Placeholder>
      )}
    </>
  )
}
