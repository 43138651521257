import React, { useMemo, useRef } from 'react'
import {
  Dropdown,
  HStack,
  Icon,
  Spacer,
  Text,
  TextButton,
  useToggle,
} from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { FormattedMessage } from 'react-intl'
import { PerformanceReviewAnalyticsSortKeys } from '@src/api/performance'

interface Props {
  value: BaseOption<PerformanceReviewAnalyticsSortKeys>
  sortKeys: PerformanceReviewAnalyticsSortKeys[]
  onSelect: (id: PerformanceReviewAnalyticsSortKeys) => void
  isAscending: boolean
  onToggleSortDirection: VoidFunction
}

export const SortSelector = ({
  value,
  sortKeys,
  onSelect,
  isAscending,
  onToggleSortDirection,
}: Props) => {
  const dropdownRef = useRef(null)
  const [isDropdownOpen, dropdownToggler] = useToggle()

  const allowedSortOptions = useMemo(
    () => sortOptions.filter(({ id }) => sortKeys.includes(id)),
    [sortKeys],
  )

  return (
    <>
      <TextButton ref={dropdownRef} onClick={dropdownToggler.switch}>
        <HStack space="s-4" align="center">
          <Text variant="emphasis1">{value.name}</Text>
          <Icon size={16} name={isDropdownOpen ? 'ChevronUp' : 'ChevronDown'} />
        </HStack>
      </TextButton>
      <Dropdown
        open={isDropdownOpen}
        anchorRef={dropdownRef}
        onClose={dropdownToggler.off}
        autoClose
        data-testid="performance_analytics_grades_distribution.sort.dropdown"
      >
        {allowedSortOptions.map(({ id, name }) => (
          <Dropdown.Item key={id} use="button" onClick={() => onSelect(id)}>
            <HStack align="center">
              {value && <Text>{name}</Text>}
              <Spacer width="s-64" />
              {value.id === id && (
                <TextButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onToggleSortDirection()
                  }}
                  use="span"
                >
                  <HStack gap="s-4" align="center">
                    <Text>
                      {isAscending
                        ? sortKeysToSortAscDirectionLabelMap[value.id]
                        : sortKeysToSortDescDirectionLabelMap[value.id]}
                    </Text>
                    <Icon name={isAscending ? 'ArrowUp' : 'ArrowDown'} size={16} />
                  </HStack>
                </TextButton>
              )}
            </HStack>
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

export const sortOptions: BaseOption<PerformanceReviewAnalyticsSortKeys>[] = [
  {
    id: PerformanceReviewAnalyticsSortKeys.EmployeeCount,
    name: 'No. of employees',
  },
  {
    id: PerformanceReviewAnalyticsSortKeys.Name,
    name: 'Alphabetically',
  },
  {
    id: PerformanceReviewAnalyticsSortKeys.Seniority,
    name: 'Rank',
  },
]

const sortKeysToSortAscDirectionLabelMap: Record<
  PerformanceReviewAnalyticsSortKeys,
  React.ReactNode
> = {
  [PerformanceReviewAnalyticsSortKeys.Name]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.alphabetical.asc"
      defaultMessage="A-Z"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.Performance]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.asc"
      defaultMessage="High to low"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.Seniority]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.asc"
      defaultMessage="High to low"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.EmployeeCount]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.asc"
      defaultMessage="High to low"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.CycleStartDate]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.asc"
      defaultMessage="High to low"
    />
  ),
}

const sortKeysToSortDescDirectionLabelMap: Record<
  PerformanceReviewAnalyticsSortKeys,
  React.ReactNode
> = {
  [PerformanceReviewAnalyticsSortKeys.Name]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.alphabetical.desc"
      defaultMessage="Z-A"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.Performance]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.desc"
      defaultMessage="Low to high"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.Seniority]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.desc"
      defaultMessage="Low to high"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.EmployeeCount]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.desc"
      defaultMessage="Low to high"
    />
  ),
  [PerformanceReviewAnalyticsSortKeys.CycleStartDate]: (
    <FormattedMessage
      id="performance.analytics.sort.direction.numeric.desc"
      defaultMessage="Low to high"
    />
  ),
}
