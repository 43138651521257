import React, { useMemo, useState } from 'react'
import { useGetCareerSettings } from '@src/api/settings'
import { ActionButton, Avatar, Box, Item, VStack, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import uniqBy from 'lodash/uniqBy'
import SideBar from '@src/components/SideBar/SideBar'
import { ApplicationFormPreview } from '@src/features/JobPostingFlow/Publish/ApplicationFormPreview'
import { CareersPositionHeader } from '@src/pages/Careers/CareersPosition/CareersPositionHeader'
import { useCareerPositionLocations } from '@src/pages/Careers/CareersPosition/useCareerPositionLocations'
import { CareersPositionBody } from '@src/pages/Careers/CareersPosition/CareersPositionBody'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'

interface CareersWebsiteProps {
  requisitions: RequisitionInterface[]
}

const useLocations = (requisitions: RequisitionInterface[]) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const currentLocations = useMemo(() => {
    return (
      (values.location_source === LocationSource.requisition
        ? uniqBy(
            requisitions.flatMap(requisition => requisition.locations).filter(Boolean),
            'id',
          )
        : values.locations) || []
    )
  }, [requisitions])
  const locations = useCareerPositionLocations(currentLocations)
  return locations
}

export const CareersWebsite = ({ requisitions }: CareersWebsiteProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const locations = useLocations(requisitions)
  const [showPreview, setShowPreview] = useState(false)
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: ['Job posting', 'Application form'],
    highlightSelected: false,
  })
  const { data: careersPageSettings } = useGetCareerSettings({
    refetchOnWindowFocus: true,
  })
  const careersPageEnabled = careersPageSettings?.publish_career_website
  const description = useMemo(
    () =>
      values.sections
        ?.filter(section => section.content)
        ?.map(section => `<b>${section.title}</b><br />${section.content}`)
        ?.join('<br />') || '',
    [values.sections],
  )
  if (!careersPageEnabled) {
    return null
  }
  return (
    <>
      <VStack gap="s-16">
        <Item>
          <Item.Avatar>
            <Avatar useIcon="InfoOutline" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Preview Job posting</Item.Title>
            <Item.Description>
              Review job posting content and application form
            </Item.Description>
            <Item.Actions>
              <ActionButton
                useIcon="EyeShow"
                onClick={() => {
                  setShowPreview(!showPreview)
                }}
              >
                Preview
              </ActionButton>
            </Item.Actions>
          </Item.Content>
        </Item>
      </VStack>
      {showPreview && (
        <SideBar
          isOpen
          onClose={() => setShowPreview(false)}
          title="Job posting preview"
          sideProps={{ resizable: true }}
        >
          <Widget py="s-16" px="s-32">
            <Box pb="s-40">{tabBar}</Box>
            <CareersPositionHeader
              text={values.name || ''}
              locationProps={{ locations }}
            />
            {currentTab === 'Job posting' && (
              <CareersPositionBody
                id=""
                description={description}
                disabledApplyButton
                text={values?.name || ''}
                video={values.presentation_video_url}
              />
            )}
            {currentTab === 'Application form' && <ApplicationFormPreview />}
          </Widget>
        </SideBar>
      )}
    </>
  )
}
