import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import {
  DocumentsRuleInterface,
  SendRuleDocuments,
  SentRequestsInterface,
} from '@src/interfaces/documentsRule'
import {
  getSendDocuments,
  getSentRequests,
  ruleDocumentsNameSelector,
} from '@src/api/documentsRules'
import {
  documentRequestsCreationDate,
  documentRequestsName,
  documentRequestsRecipient,
  documentRequestsStatus,
  documentsRulesSentDate,
  documentsRulesSentName,
  documentsRulesSentRecipient,
  documentsRulesSentStatus,
} from '@src/constants/columns/documentsRules'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@components/TableV2/hooks'

interface Props {
  data: DocumentsRuleInterface
}

const sentDocumentsRow: RowInterface<SendRuleDocuments> = {
  cells: [
    {
      ...documentsRulesSentName,
      width: 390,
    },
    {
      ...documentsRulesSentRecipient,
      width: 750,
    },
    {
      ...documentsRulesSentDate,
      width: 150,
    },
    {
      ...documentsRulesSentStatus,
      width: 150,
    },
  ],
}

const sentRequestsRow: RowInterface<SentRequestsInterface> = {
  cells: [
    {
      ...documentRequestsName,
      width: 390,
    },
    {
      ...documentRequestsRecipient,
      width: 300,
    },
    {
      ...documentRequestsCreationDate,
      width: 200,
    },
    {
      ...documentRequestsStatus,
      width: 150,
    },
  ],
}

const Documents = ({ data }: Props) => {
  return (
    <Table.Widget>
      <Table.Widget.Table>
        {data.template_type.id === 'generate' ? (
          <SentDocumentsTable id={data.id} />
        ) : null}
        {data.template_type.id === 'request' ? <SentRequestsTable id={data.id} /> : null}
      </Table.Widget.Table>
    </Table.Widget>
  )
}

interface CommonTableProps {
  id: number
}

const SentDocumentsTable = ({ id }: CommonTableProps) => {
  const table = useTable({ getItems: getSendDocuments(id) })

  sentDocumentsRow.cells[0].selectorsKey = async () => {
    const result = await ruleDocumentsNameSelector(id)
    return result?.data
  }

  return (
    <AdjustableTable
      name={TableNames.SentDocuments}
      row={sentDocumentsRow}
      {...table}
      noDataMessage="All sent documents will appear here"
    />
  )
}

const SentRequestsTable = ({ id }: CommonTableProps) => {
  const table = useTable({ getItems: getSentRequests(id) })

  return (
    <AdjustableTable
      name={TableNames.SentRequests}
      row={sentRequestsRow}
      {...table}
      noDataMessage="All sent requests will appear here"
    />
  )
}

export default Documents
