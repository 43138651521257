import React, { useRef } from 'react'
import { Dropdown, HStack, Icon, Text, TextButton, useToggle } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { YAxisUnits } from '@src/features/ReviewCycle/Analytics/Charts/HistoryBarCharts/hooks/useChartData'

export interface YAxisUnitOption extends BaseOption<YAxisUnits> {}

interface Props {
  value: BaseOption<YAxisUnits>
  disabled?: boolean
  onSelect: (value: YAxisUnitOption) => void
}

export const YAxisUnitsSelector = ({ value, disabled, onSelect }: Props) => {
  const dropdownRef = useRef(null)
  const [isDropdownOpen, dropdownToggler] = useToggle()

  return (
    <>
      <TextButton ref={dropdownRef} onClick={dropdownToggler.switch} disabled={disabled}>
        <HStack space="s-4" align="center">
          <Text variant="emphasis1">{value.name}</Text>
          <Icon size={16} name={isDropdownOpen ? 'ChevronUp' : 'ChevronDown'} />
        </HStack>
      </TextButton>
      <Dropdown
        open={isDropdownOpen}
        anchorRef={dropdownRef}
        onClose={dropdownToggler.off}
        autoClose
        data-testid="performance_analytics_history.y.axis.units.dropdown"
      >
        {unitsOptions.map(option => (
          <Dropdown.Item key={option.id} use="button" onClick={() => onSelect(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

export const percentageOption: YAxisUnitOption = {
  id: YAxisUnits.Percentage,
  name: 'Percentage',
}

const totalCountOption: YAxisUnitOption = {
  id: YAxisUnits.Count,
  name: 'Total number',
}

export const unitsOptions: YAxisUnitOption[] = [percentageOption, totalCountOption]
