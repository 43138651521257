import { ROUTES } from '@src/constants/routes'
import { ReviewCategory } from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { Location } from 'history'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export interface OptionInterface {
  id: number
  name: string
  relation?: string
}

export const getReviewEditUrl = (
  category?: ReviewCategory | ReviewCycleCategory,
  employeeId?: number,
  fallbackEmployeeId?: number | string,
  reviewId?: number,
) => {
  switch (category) {
    case ReviewCategory.Upwards:
      return pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW_LAYOUT, {
        employeeId,
        id: reviewId,
      })
    case ReviewCategory.Performance:
      return pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT, {
        employeeId,
        id: reviewId,
      })
    case ReviewCategory.Probation:
      return pathToUrl(ROUTES.FORMS.PROBATION_REVIEW_LAYOUT, {
        employeeId,
        id: reviewId,
      })
    default:
      return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
        id: fallbackEmployeeId,
      })
  }
}

export const getReviewsUrl = (
  category?: ReviewCategory | ReviewCycleCategory,
  cycleId?: string,
  employeeId?: number,
  location?: Location<unknown>,
) => {
  let route = ''
  switch (category) {
    case ReviewCategory.Upwards:
      route = ROUTES.FORMS.UPWARDS_REVIEW_VIEW_LAYOUT
      break
    case ReviewCategory.Performance:
      route = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW_LAYOUT
      break
    case ReviewCategory.Probation:
      route = ROUTES.FORMS.PROBATION_REVIEW_VIEW_LAYOUT
      break
    case ReviewCategory.PIP:
    case ReviewCategory.PIP_V2:
      route = ROUTES.FORMS.PIP_REVIEW_VIEW_LAYOUT
      break
  }

  return (
    pathToUrl(route, {
      id: cycleId,
      employeeId,
    }) + (location?.search || '')
  )
}
