import { toIdAndName } from '@src/utils/toIdAndName'
import { ScopeFilterKey } from './types'

export const getScopeFilters = <T extends { id: number }>(
  data: T | undefined,
  scope:
    | 'employee'
    | 'team'
    | 'department'
    | 'role'
    | 'specialisation'
    | 'function'
    | 'company',
) => {
  if (!data || scope === 'company') {
    return []
  }
  let columnName: ScopeFilterKey = scope === 'employee' ? 'line_manager' : scope
  return [
    {
      columnName,
      filters: [toIdAndName(String(data.id))],
      nonResettable: true,
    },
  ]
}
