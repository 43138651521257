import { useSelector } from 'react-redux'

import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

/**
 * TODO: https://revolut.atlassian.net/browse/REVCOR-3825 remove backwards compatible code when possible
 * @deprecated If you need to check one permission, just pass it as argument, not in an array
 */
export function useHasGlobalPermission(permissions: [PermissionTypes]): boolean
export function useHasGlobalPermission(permissions: PermissionTypes): boolean
export function useHasGlobalPermission<T extends PermissionTypes[]>(
  permissions: [...T],
): {
  [I in keyof T]: boolean
}
export function useHasGlobalPermission(permissions: PermissionTypes | PermissionTypes[]) {
  const userPermissions = useSelector(selectPermissions)

  if (Array.isArray(permissions)) {
    // TODO: https://revolut.atlassian.net/browse/REVCOR-3825 remove backwards compatible code when possible
    /** To keep it backwards compatible - initial implementation accepted array and returned single boolean value */
    if (permissions.length === 1) {
      return userPermissions?.includes(permissions[0])
    }
    return permissions.map(permission => userPermissions?.includes(permission))
  }
  return userPermissions?.includes(permissions)
}

export function useHasSomeGlobalPermission(permissions: PermissionTypes[]) {
  const userPermissions = useSelector(selectPermissions)
  return permissions.some(permission => userPermissions.includes(permission))
}
