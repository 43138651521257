import React, { useRef, useState } from 'react'
import { Dropdown, MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

interface Props {
  employeeId: number
}

export const RequestChangeButton = ({ employeeId }: Props) => {
  const requestChangeButtonRef = useRef(null)
  const [isChangeRequestDropdownOpen, setIsChangeRequestDropdownOpen] = useState(false)

  return (
    <>
      <MoreBar.Action
        ref={requestChangeButtonRef}
        onClick={() => setIsChangeRequestDropdownOpen(!isChangeRequestDropdownOpen)}
        useIcon="Plus"
      >
        Request a change
      </MoreBar.Action>
      <Dropdown
        anchorRef={requestChangeButtonRef}
        onClose={() => setIsChangeRequestDropdownOpen(false)}
        open={isChangeRequestDropdownOpen}
      >
        <Dropdown.Item
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
            employeeId: `${employeeId}`,
            type: 'name',
          })}
          use={InternalLink}
        >
          Name
        </Dropdown.Item>
        <Dropdown.Item
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
            employeeId: `${employeeId}`,
            type: 'organisation',
          })}
          use={InternalLink}
        >
          Organisation
        </Dropdown.Item>
      </Dropdown>
    </>
  )
}
