import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { teamsRequests } from '@src/api/teams'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  circlesKriColumn,
  teamStatusColumn,
  teamNameIconColumn,
} from '@src/constants/columns/team'
import { teamOwnerColumn } from '@src/constants/columns/employee'
import {
  departmentBudgetColumn,
  departmentHeadcountColumn,
  departmentRequisitionsColumn,
  departmentRoadmapColumn,
  nipsColumn,
  departmentAuditColumn,
  departmentCommunicationColumn,
  departmentCultureColumn,
  departmentKPIColumn,
} from '@src/constants/columns/department'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { Statuses } from '@src/interfaces'
import { FilterButton } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import {
  useGetRoadmapSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useGetSlackPreferences } from '@src/api/integrations'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

interface StructureProps {
  data: DepartmentInterface
}

const ROW: RowInterface<TeamInterface> = {
  cells: [
    {
      ...teamNameIconColumn,
      width: 220,
    },
    {
      ...teamOwnerColumn,
      width: 160,
    },
    {
      ...departmentHeadcountColumn,
      width: 110,
    },
    {
      ...departmentRequisitionsColumn,
      width: 80,
    },
    {
      ...departmentKPIColumn,
      width: 80,
    },
    {
      ...departmentRoadmapColumn,
      width: 80,
    },
    {
      ...nipsColumn,
      width: 80,
    },
    {
      ...departmentAuditColumn,
      width: 80,
    },
    {
      ...circlesKriColumn,
      width: 80,
    },
    {
      ...departmentBudgetColumn,
      width: 100,
    },
    {
      ...departmentCommunicationColumn,
      width: 100,
    },
    {
      ...teamStatusColumn,
      width: 100,
    },
  ],
}

const Teams = ({ data }: StructureProps) => {
  const { data: settings } = useGetOrganisationSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()
  const { settings: globalSettings } = useGlobalSettings()
  const [showArchivedTeams, setShowArchivedTeams] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_TEAMS,
    false,
  )

  const permissions = useSelector(selectPermissions)
  const canAdd =
    permissions.includes(PermissionTypes.AddTeams) ||
    data.field_options.permissions?.includes(PermissionTypes.AddTeams)

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter = [
      {
        filters: [{ name: data.name, id: data.id }],
        columnName: 'department__id',
        nonResettable: true,
      },
      {
        filters: [{ name: Statuses.active, id: Statuses.active }],
        columnName: 'status',
        nonResettable: true,
      },
    ]

    filter.push(getFilterByStatus(showArchivedTeams))

    return filter
  }

  const initialSortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<TeamInterface>(teamsRequests, getInitialFilter(), initialSortBy)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {}), {
      initialValues: { department: { name: data.name, id: data.id } },
    })
  }

  const handleRowEdit = (row: { id: number }) => {
    navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: row.id }))
  }

  const onToggleArchivedTeams = () => {
    setShowArchivedTeams(!showArchivedTeams)
    table.onFilterChange(getFilterByStatus(!showArchivedTeams))
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const roadmapEnabled = !!roadmapSettings?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const approvalsEnabled = !!settings?.enable_teams_approvals
  const budgetEnabled = !!settings?.enable_budget_management
  const slackEnabled = !!slackSettings?.enabled
  const engagementEnabled = !!globalSettings?.engagement_enabled

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Teams" val={table?.count} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd ? (
            <PrimaryAction onClick={handleNewRow} useIcon="Plus">
              Add Team
            </PrimaryAction>
          ) : null}
          <AllowedExportMenu
            request={teamsRequests.getExport}
            filterQuery={filterQuery}
            fileName="Teams"
            type={TalentType.Team}
          />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleArchivedTeams} active={showArchivedTeams}>
          Archived teams
        </FilterButton>
      </Table.Widget.Filters>

      <Table.Widget.Table>
        <AdjustableTable<TeamInterface>
          name={TableNames.DepartmentTeams}
          useWindowScroll
          dataType="Team"
          row={ROW}
          {...table}
          onRowClick={handleRowEdit}
          noDataMessage="All teams will appear here"
          hiddenCells={{
            [departmentRoadmapColumn.idPoint]: !roadmapEnabled,
            [circlesKriColumn.idPoint]: !riskEnabled,
            [departmentCultureColumn.idPoint]: !engagementEnabled,
            [departmentAuditColumn.idPoint]: !findingsEnabled,
            [departmentBudgetColumn.idPoint]: !budgetEnabled,
            [departmentCommunicationColumn.idPoint]: !slackEnabled,
            [teamStatusColumn.idPoint]: !approvalsEnabled,
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Teams
