import React from 'react'
import { HStack, Text, VStack, Widget } from '@revolut/ui-kit'

import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import UserName from '@src/components/UserWithAvatar/UserName'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'

interface Props {
  answerText: string
  employee?: EmployeeOptionInterface
}
export const ReplyWidget = ({ answerText, employee }: Props) => {
  return (
    <Widget p="s-16">
      <VStack space="s-16">
        {employee ? (
          <EmployeeUserWithAvatar {...employee} />
        ) : (
          <HStack align="center" space="s-8">
            <UserAvatar avatar={null} name="Anonymous Participant" />
            <UserName name="Anonymous Participant" />
          </HStack>
        )}
        <Text>{answerText}</Text>
      </VStack>
    </Widget>
  )
}
