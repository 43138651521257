import React from 'react'

import {
  Box,
  Copyable,
  IconButton,
  Item,
  Link,
  Spacer,
  Tag,
  Text,
  Token,
} from '@revolut/ui-kit'
import {
  ConnectionStatuses,
  ConnectionType,
  ExternalChannelItemInterface,
} from '@src/interfaces/deparment'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

interface Props {
  channel: ExternalChannelItemInterface
  onChannelEdit: VoidFunction
  canEdit: boolean
}
export const ExternalChannelItem = ({ channel, onChannelEdit, canEdit }: Props) => {
  const getIcon = () => {
    if (channel.type === ConnectionType.Channel && channel.is_primary_group) {
      return (
        <IconButton
          use="a"
          target="_blank"
          rel="noopener norefferer"
          href={channel.href}
          useIcon="LinkExternal"
        />
      )
    }
    if (channel.type === ConnectionType.Group && canEdit) {
      return <IconButton useIcon="Pencil" onClick={onChannelEdit} aria-label="Edit" />
    }
    return <Spacer width="s-16" />
  }

  return (
    <Item>
      <Item.Content>
        <Item.Title>
          {channel.href ? (
            <Link target="_blank" href={channel.href} variant="body2">
              #{channel.handle}
            </Link>
          ) : (
            <Text
              variant="body2"
              color={
                channel.status === ConnectionStatuses.Disabled
                  ? Token.color.greyTone50
                  : Token.color.foreground
              }
            >
              {channel.type === ConnectionType.Channel && `'#${channel.handle}`}
              {channel.type === ConnectionType.Group && (
                <Box width="fit-content">
                  <Copyable
                    justifyContent="left"
                    value={`@${channel.handle}`}
                    labelButtonCopy="Copy"
                    onCopy={() => {
                      pushNotification({
                        value: 'Group handle copied to clipboard',
                        duration: SUCCESS_DEFAULT_DURATION,
                        type: NotificationTypes.success,
                      })
                    }}
                  >
                    @{channel.handle}
                  </Copyable>
                </Box>
              )}
            </Text>
          )}
        </Item.Title>
        <Item.Description>
          <Text variant="body2">{channel.name}</Text>
          {channel.status === ConnectionStatuses.LinkError && canEdit && (
            <Box mt="s-6">
              <Tag useIcon="ExclamationTriangle" color="warning">
                Name conflict
              </Tag>
            </Box>
          )}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>{getIcon()}</Item.Value>
      </Item.Side>
    </Item>
  )
}
