import { Color, IconName, Token } from '@revolut/ui-kit'
import {
  FinalGrade,
  PerformanceReviewAnalyticsGroupTypes,
} from '@src/interfaces/performance'

export const gradeToColorMap: Record<FinalGrade, Color | undefined> = {
  [FinalGrade.Empty]: Token.color.greyTone50,
  [FinalGrade.None]: Token.color.greyTone50,
  [FinalGrade.Poor]: Token.color.orange,
  [FinalGrade.AverageMinus]: Token.color.deepGrey,
  [FinalGrade.AveragePlus]: Token.color.blue,
  [FinalGrade.Strong]: Token.color.teal,
  [FinalGrade.Exceptional]: Token.color.lightGreen,
}

export const groupTypeToIconNameMap: Record<
  PerformanceReviewAnalyticsGroupTypes,
  IconName
> = {
  [PerformanceReviewAnalyticsGroupTypes.Country]: 'Flag',
  [PerformanceReviewAnalyticsGroupTypes.Seniority]: 'Profile',
  [PerformanceReviewAnalyticsGroupTypes.Department]: 'Bank',
  [PerformanceReviewAnalyticsGroupTypes.Function]: 'RepairTool',
  [PerformanceReviewAnalyticsGroupTypes.Role]: 'Profile',
  [PerformanceReviewAnalyticsGroupTypes.Specialisation]: 'Profile',
  [PerformanceReviewAnalyticsGroupTypes.Cycle]: 'DocumentsPair',
}
