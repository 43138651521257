import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from '@revolut-internal/event-tracker/react'

import './styles/index.css'
import './styles/normalize.css'
import App from './pages/App/App'
import { store } from './utils/store'
import { env, Environments } from './constants/api'
import { history } from './utils/router'
import { Flex } from '@revolut/ui-kit'
import { setAutoFreeze } from 'immer'
import { lapeTrackUseState } from 'lape'
import { QueryClient, QueryClientProvider } from 'react-query'
import ErrorBoundary, { DefaultFallBack } from '@src/features/ErrorBoundary/ErrorBoundary'
import { AnalyticsContextProvider, GoogleAnalyticsWrapper } from '@src/utils/analytics'
import { UIKitWithThemeProvider } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { CookieConsentWrapper } from '@src/pages/Landing/components/CookieConsentWrapper'
import { WorkspaceContextProvider } from '@src/features/Workspaces/WorkspaceContext'
import { ConfirmationDialogProvider } from '@src/features/Popups/ConfirmationDialogProvider'
import { GlobalStyle } from './GlobalStyles'
import { parseError } from '@src/features/Errors/parseError'
import {
  INVALID_IAP_CREDENTIALS,
  INVALID_AUTH_COOKIE,
} from '@src/constants/notifications'
import { dispatch } from '@src/utils/store'
import { logOutAction } from '@src/store/auth/actions'
import { captureError } from './features/Errors/captureError'
import { PUBLIC_PREFIX, SIGNUP } from './constants/routes'
import { SessionExpiredPopup } from './components/SessionExpiredPopup/SessionExpiredPopup'

if (env === Environments.staging || env === Environments.production) {
  axios.interceptors.request.use(
    config => {
      config.headers = { ...config.headers, 'X-Requested-With': 'XMLHttpRequest' }
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )
}

axios.interceptors.response.use(
  response => response,
  error => {
    if (env === Environments.staging || env === Environments.production) {
      if (
        (error.response?.status === 401 &&
          error.response?.data?.startsWith(INVALID_IAP_CREDENTIALS)) ||
        (error.response?.status === 302 &&
          error.response?.data?.startsWith(INVALID_AUTH_COOKIE))
      ) {
        window.location.href = `${
          document.location.origin
        }/iap?redirect_url=${encodeURIComponent(
          `${window.location.pathname}${window.location.search}`,
        )}`

        return new Promise(() => {})
      }
    }

    const parsedError = parseError(error)

    if (parsedError.type === 'unauthorized' || parsedError.type === 'session_expired') {
      if (
        [PUBLIC_PREFIX, SIGNUP].some(route =>
          history?.location?.pathname?.startsWith?.(route),
        )
      ) {
        captureError(error, {
          tags: { component: 'axiosInterceptor' },
          severity: 'fatal',
        })
      } else {
        dispatch(logOutAction(true))
      }
    }

    return Promise.reject(error)
  },
)

const EventTracker = React.lazy(() =>
  import(
    /* webpackChunkName: 'event-tracker' */ '@src/features/EventTracker/EventTracker'
  )
    .then(module => ({
      default: module.EventTracker,
    }))
    .catch(error => {
      Sentry.captureException(error)
      console.error('event-tracker failed:', error)
      return { default: () => <></> }
    }),
)

if (
  env === Environments.production ||
  env === Environments.productionCommercial ||
  env === Environments.productionCommercialRoot
) {
  Sentry.init({
    dsn:
      env === Environments.production
        ? 'https://8a8797e8f3f34eaf8a1ef1e9dcef2748@sentry.io/1542966'
        : 'https://b1026b46e947a4142288b6aded8809b8@o104379.ingest.sentry.io/4505793610776576',
    environment: 'production', // environment is used by Tower and it expect either 'production' or 'prod' values
    ignoreErrors: [
      // TODO: this is a final form error, try to remove this ignore after legacy forms are migrated to lape
      "Cannot read property 'active' of undefined",
      'Request failed with status code 502',
      'Request failed with status code 503',
      'Request failed with status code 504',
      // NB: can be ignored safely: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      // NB: comes from our dependencies, not sure we have control on it
      // About this issue: https://github.com/juggle/resize-observer/issues/103
      'ResizeObserver loop completed with undelivered notifications.',
      'Error: Network Error',
      'TypeError: Failed to fetch',
      'timeout exceeded',
      'Request aborted',
      `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
    ],
    beforeSend(event, hint) {
      if (axios.isAxiosError(hint?.originalException) && event.level !== 'fatal') {
        const parsedError = parseError(hint.originalException)
        if (
          parsedError.type === 'unauthorized' ||
          parsedError.type === 'session_expired' ||
          parsedError.type === 'forbidden'
        ) {
          return null
        }
      }

      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        _metricOptions: {
          _reportAllChanges: true,
        },
      }),
    ],
    tracesSampleRate: 0.01,
  })
}

setAutoFreeze(false)
lapeTrackUseState()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: env !== Environments.local,
    },
  },
})

const renderApp = () => {
  ReactDOM.render(
    <IntlProvider locale="en">
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <WorkspaceContextProvider>
            <UIKitWithThemeProvider>
              <ConfirmationDialogProvider>
                <CookieConsentWrapper>
                  <GoogleAnalyticsWrapper>
                    <AnalyticsContextProvider>
                      <ErrorBoundary
                        fallback={
                          <Flex
                            width="100vw"
                            height="100vh"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <DefaultFallBack />
                          </Flex>
                        }
                      >
                        <GlobalStyle />
                        <App />
                        {env === Environments.local ||
                        env === Environments.production ||
                        env === Environments.developmentCommercial ||
                        env === Environments.developmentCommercialRoot ||
                        env === Environments.productionCommercial ||
                        env === Environments.productionCommercialRoot ? (
                          <Suspense fallback={null}>
                            <EventTracker />
                          </Suspense>
                        ) : null}
                        <SessionExpiredPopup />
                      </ErrorBoundary>
                    </AnalyticsContextProvider>
                  </GoogleAnalyticsWrapper>
                </CookieConsentWrapper>
              </ConfirmationDialogProvider>
            </UIKitWithThemeProvider>
          </WorkspaceContextProvider>
        </QueryClientProvider>
      </Provider>
    </IntlProvider>,
    document.getElementById('mount'),
  )
}

if (
  env !== Environments.production &&
  env !== Environments.productionCommercial &&
  env !== Environments.productionCommercialRoot &&
  localStorage.getItem('enable_msw') === 'true'
) {
  const startWorker = async () => {
    const { worker } = await import('@src/api/mocks/browser')
    worker.start({ onUnhandledRequest: 'bypass' })
  }
  startWorker().then(() => renderApp())
} else {
  renderApp()
}
