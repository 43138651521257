import { Avatar, ExpandableItem, Group, Item, Spacer, Text } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { CyclesNavigationSkeleton } from '@src/pages/Forms/DepartmentForm/Performance/CyclesNavigation/CyclesNavigationSkeleton'
import { Cycle, CycleType } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { formatPercentage } from '@src/utils/format'
import {
  getOverallProgressGrade,
  gradeSettings,
} from '@src/pages/Forms/DepartmentForm/Performance/utils'
import { chain } from '@revolut/ui-kit'

interface PerformanceCyclesNavigationProps {
  cyclesList: Cycle[] | undefined
  setSelectedCycle: (cycle: Cycle) => void
  selectedCycle: Cycle | undefined
}

export const PerformanceCyclesNavigationCollapsable = ({
  cyclesList,
  selectedCycle,
  setSelectedCycle,
}: PerformanceCyclesNavigationProps) => {
  const [expandedItems, setExpandedItems] = useState<number[]>([])

  const groupedCyclesList = (() => {
    if (!cyclesList || !cyclesList.length) {
      return []
    }
    const years = cyclesList.filter(item => item.type === CycleType.Year)
    const months = cyclesList.filter(item => item.type === CycleType.ReviewCycle)

    return years.map(year => {
      const relatedMonths = months.filter(month =>
        month.review_cycle_ids.some(id => year.review_cycle_ids.includes(id)),
      )
      return { ...year, months: relatedMonths }
    })
  })()

  // set initial expanded item if there is a selected cycle in page url param
  useEffect(() => {
    if (selectedCycle && !expandedItems.length) {
      setExpandedItems([...selectedCycle.review_cycle_ids])
    }
  }, [selectedCycle])

  const updateExpandedItems = (cycle: Cycle) => {
    // if already expanded
    if (expandedItems.filter(value => cycle.review_cycle_ids.includes(value)).length) {
      cycle.review_cycle_ids.forEach(id => {
        setExpandedItems(prevState => prevState.filter(item => item !== id))
      })
    } else {
      setExpandedItems(prevState => [...prevState, ...cycle.review_cycle_ids])
      setSelectedCycle(cycle)
    }
  }
  return (
    <Group
      height={{ all: '40vh', lg: 'fit-content' }}
      overflow={{ all: 'scroll', lg: 'unset' }}
    >
      {!groupedCyclesList?.length && <CyclesNavigationSkeleton />}
      {groupedCyclesList?.map(item => (
        <ExpandableItem
          key={item.id + item.type}
          use="button"
          aria-pressed={selectedCycle?.review_cycle_ids === item.review_cycle_ids}
          // check if there is intersection between expanded items and review_cycle_ids to expand correctly if a quarter item was preset from page url param
          expanded={
            selectedCycle &&
            !!expandedItems.filter(value => item.review_cycle_ids.includes(value)).length
          }
          onToggle={() => {
            updateExpandedItems(item)
          }}
          py="s-16"
          variant="compact"
        >
          <ExpandableItem.Avatar>
            <Avatar useIcon="Calendar" />
          </ExpandableItem.Avatar>
          <ExpandableItem.Content>
            <ExpandableItem.Title>
              {chain(
                item.name,
                <Text
                  variant="body1"
                  whiteSpace="nowrap"
                  color={
                    gradeSettings?.[
                      getOverallProgressGrade(
                        item.overall_progress
                          ? item.overall_progress.percent * 100
                          : undefined,
                      )
                    ].color
                  }
                >
                  {formatPercentage(item.overall_progress?.percent ?? null)}
                </Text>,
              )}
            </ExpandableItem.Title>
          </ExpandableItem.Content>

          <ExpandableItem.NestedContent>
            {item.months.map(month => (
              <Item
                use="button"
                key={month.id + month.type}
                onClick={() => {
                  setSelectedCycle(month)
                }}
                aria-pressed={selectedCycle?.review_cycle_ids === month.review_cycle_ids}
                pl="s-12"
              >
                <Spacer width="s-56" />
                <Item.Content>
                  <Item.Title>
                    <Text>
                      {chain(
                        month.name,
                        <Text
                          variant="body1"
                          whiteSpace="nowrap"
                          color={
                            gradeSettings?.[
                              getOverallProgressGrade(
                                month.overall_progress
                                  ? month.overall_progress.percent * 100
                                  : undefined,
                              )
                            ].color
                          }
                        >
                          {formatPercentage(month.overall_progress?.percent ?? null)}
                        </Text>,
                      )}
                    </Text>
                  </Item.Title>
                </Item.Content>
              </Item>
            ))}
          </ExpandableItem.NestedContent>
        </ExpandableItem>
      ))}
    </Group>
  )
}
