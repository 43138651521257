import React from 'react'
import { Box, Flex, Text, Token } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { FinalGrade } from '@src/interfaces/performance'
import { gradeToColorMap } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/constants'

interface Props {
  items: BaseOption<FinalGrade>[]
}

export const Legend = ({ items }: Props) => (
  <Flex columnGap="s-12" rowGap="s-2" justifyContent="center" flexWrap="wrap">
    {items.map(({ id, name }) => (
      <Flex key={id} alignItems="center" gap="s-4">
        <Box
          bg={gradeToColorMap[id]}
          width="s-12"
          height="s-12"
          border={`1px solid ${Token.color.greyTone10}`}
          borderRadius={Token.radius.r2}
        />
        <Text fontSize="small" color={Token.color.greyTone50} use="div">
          {name}
        </Text>
      </Flex>
    ))}
  </Flex>
)
