import { useEffect, useMemo, useState } from 'react'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { PerformanceSelector } from '@src/interfaces/performance'
import { useGetEmployeeLatestPublishedReviewCycle } from '@src/api/reviewCycles'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'

type CycleProps = {
  cycleId: string
  cycleCategory: ReviewCycleCategory
  lastPublishedCycle: PerformanceSelector | null
}

type ContextProps = {
  isLoaded: boolean
  hasSummaryInTalentCycle: boolean
}

type Props = ReturnType<typeof useFetchPerformanceSummary> & CycleProps & ContextProps

export const useGetPerformanceSummary = (talent: TalentPerformanceInterface): Props => {
  const [cycleId, setCycleId] = useState(String(talent.cycle.id))
  const [employeeId, setEmployeeId] = useState(talent.employee.id)
  const [cycleCategory, setCycleCategory] = useState(ReviewCycleCategory.Performance)

  const context = useFetchPerformanceSummary(cycleId, employeeId, cycleCategory)

  const hasMissingSummary =
    talent.employee.id === employeeId && !context.isLoading && !context.data?.summary

  const { data: lastPublishedReviewCycle, isLoading: isLastPublishedReviewCycleLoading } =
    useGetEmployeeLatestPublishedReviewCycle(talent.employee.id, hasMissingSummary)

  const isLoaded = !context.isLoading && !isLastPublishedReviewCycleLoading

  useEffect(() => {
    setEmployeeId(talent.employee.id)
    setCycleId(String(talent.cycle.id))
    setCycleCategory(ReviewCycleCategory.Performance)
  }, [talent.employee.id, talent.cycle.id])

  useEffect(() => {
    if (!hasMissingSummary || !lastPublishedReviewCycle) {
      return
    }

    setCycleId(String(lastPublishedReviewCycle.id))
    setCycleCategory(lastPublishedReviewCycle.category)
  }, [hasMissingSummary, lastPublishedReviewCycle])

  return useMemo<Props>(() => {
    const hasSummaryInTalentCycle =
      isLoaded && !hasMissingSummary && cycleId === String(talent.cycle.id)
    const lastPublishedCycle =
      !hasSummaryInTalentCycle && !!lastPublishedReviewCycle
        ? lastPublishedReviewCycle
        : null

    return {
      ...context,
      cycleId,
      cycleCategory,
      lastPublishedCycle,
      isLoaded,
      hasSummaryInTalentCycle,
    }
  }, [
    context,
    cycleId,
    talent.cycle.id,
    cycleCategory,
    isLoaded,
    hasMissingSummary,
    lastPublishedReviewCycle,
  ])
}
