import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useGlobalSettings } from '@src/api/settings'
import { EmployeeInterface } from '@src/interfaces/employees'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const useIsPromotionsEnabled = () => {
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()

  return promotions_enabled
}

export const useIsPromotionNominationWindowOpen = () => {
  const isPromotionsEnabled = useIsPromotionsEnabled()
  const featureFlags = useSelector(selectFeatureFlags)

  if (!isPromotionsEnabled) {
    return false
  }

  /**
   * This feature is enabled by switching FF,
   * after switching its cached on BE for 5 minutes,
   * to get valid state on FE it's being cached in LS as well
   * @see {/src/pages/Performance/PromotionNominees/PromotionNominees.tsx}
   */
  const cashedFlag = workspaceLocalStorage.getItem(FeatureFlags.PromotionNomination)

  if (cashedFlag) {
    return cashedFlag === 'true'
  }

  return featureFlags.includes(FeatureFlags.PromotionNomination)
}

export const useCanNominateAnyTime = () => {
  const isPromotionsEnabled = useIsPromotionsEnabled()
  const permissions = useSelector(selectPermissions)

  return (
    isPromotionsEnabled &&
    permissions.includes(PermissionTypes.PromotionsAnyTimeNomination)
  )
}

export const useCanNominate = () => {
  const isPromotionsEnabled = useIsPromotionsEnabled()
  const canNominateAnyTime = useCanNominateAnyTime()
  const isPromotionNominationWindowOpen = useIsPromotionNominationWindowOpen()

  return isPromotionsEnabled && (canNominateAnyTime || isPromotionNominationWindowOpen)
}

export const useCanNominateEmployee = (employee: EmployeeInterface) => {
  const isPromotionsEnabled = useIsPromotionsEnabled()

  return (
    isPromotionsEnabled &&
    employee.field_options?.permissions?.includes(PermissionTypes.AddPromotionNomination)
  )
}
