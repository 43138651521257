import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'

export const PerformancePageHeader = () => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()

  const isSummary = (
    review: ReviewScorecardInterface | ReviewSummaryInterface,
  ): review is ReviewSummaryInterface =>
    (review as ReviewSummaryInterface).summary !== undefined

  const data = isSummary(values) ? values.reviews[0] : values

  const getBackUrl = () => {
    return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
      id: data?.reviewed_employee?.id,
    })
  }

  const backUrl = getBackUrl()

  return <PageHeader title={null} backUrl={backUrl} />
}
