import { Token } from '@revolut/ui-kit'

export enum PerformanceScoreItemDataKeys {
  NIPS = 'NIPS',
  ALL = 'ALL',
}

export interface PerformanceScoreItemData {
  name: PerformanceScoreItemDataKeys
  value: number
  color: typeof Token.colorChannel[keyof typeof Token.colorChannel]
}
