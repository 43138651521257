import Form from '@src/features/Form/Form'
import { salesforceIntegrationPreferences } from '@src/api/integrations'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import {
  Text,
  Avatar,
  Spacer,
  Token,
  HStack,
  VStack,
  Box,
  Item,
  Icon,
  Flex,
  ActionButton,
  Bullet,
  IconButton,
} from '@revolut/ui-kit'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SalesforceIntergrationInterface } from '@src/interfaces/integrations'

import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { IntegrationEnablePopup } from '../components/IntegrationEnablePopup'
import { UpdateTypes } from '@src/interfaces/kpis'
import { serverNameValidators } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { getServerLabel } from '../../utils'

export const SalesforceIntegration = () => {
  return (
    <Form
      api={salesforceIntegrationPreferences}
      forceParams={{ id: '1' }}
      validator={serverNameValidators[UpdateTypes.salesforce]}
    >
      <SalesforceForm />
    </Form>
  )
}

const SalesforceForm = () => {
  const { values, reset, submit, isSubmitting, loading } =
    useLapeContext<SalesforceIntergrationInterface>()
  const confirmationPopup = useConfirmationDialog()
  const [enableIntegrationPopupOpen, setEnableIntegrationPopupOpen] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  useEffect(() => {
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Salesforce"
        backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                {values.enabled && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  Collaboration
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image={{
                  default: 'https://assets.revolut.com/assets/apps/Salesforce.webp',
                  '2x': 'https://assets.revolut.com/assets/apps/Salesforce@2x.webp',
                  '3x': 'https://assets.revolut.com/assets/apps/Salesforce@3x.webp',
                }}
                size={56}
              >
                {values.enabled ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
      />

      {!values.enabled && (
        <Box pt="s-16">
          <ActionButton
            useIcon="Link"
            pending={loading}
            disabled={loading || isSubmitting}
            variant="accent"
            onClick={evnt => {
              evnt.preventDefault()
              setEnableIntegrationPopupOpen(true)
            }}
          >
            Enable
          </ActionButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        {values.enabled && (
          <Item>
            <Item.Avatar>
              <Avatar label={getServerLabel(values.server)} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{values.server}</Item.Title>
              <Item.Description>Connected server</Item.Description>
            </Item.Content>
            <Item.Side>
              <IconButton
                useIcon="Pencil"
                color={Token.color.accent}
                onClick={evnt => {
                  evnt.preventDefault()
                  setEnableIntegrationPopupOpen(true)
                }}
              />
            </Item.Side>
          </Item>
        )}
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.salesforce.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.salesforce.intro.text"
                defaultMessage="This integration allows users to connect their metrics to Salesforce and sync them automatically"
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.clickup.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.salesforce.intro.tips.account.title"
                  defaultMessage="Connect your Salesforce account"
                />
              ),
              icon: <Bullet variant="filled">1</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.account.text"
                  defaultMessage="You will be asked to specify your Salesforce login information in this step. Please note, you have to be Salesforce admin in order to connect to Revolut People"
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.metrics.title"
                  defaultMessage="Connect your metrics to the Salesforce"
                />
              ),
              icon: <Bullet variant="filled">2</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.jira.intro.tips.metrics.text"
                  defaultMessage="When crating new metric within a goal, select metric type: Salesforce, then specify Report and value that should be in sync with Revolut People. Every time Salesforce updates, your metrics in Revolut People will be updated as well"
                />
              ),
            },
          ]}
        />
        {values.enabled && (
          <Item
            color={Token.color.danger}
            use="button"
            onClick={async evnt => {
              evnt.preventDefault()
              confirmationPopup.show({
                label: 'Are you sure?',
                body: `After disabling this integration you won't be able to use Salesforce as a tracking tool`,
                onConfirm: async () => {
                  values.enabled = false
                  try {
                    const response = await submit()
                    showStatusPopup({ title: 'Integration disabled' })
                    reset(response)
                  } catch (error) {
                    showStatusPopup({
                      title: 'Cannot disable integration',
                      description: getStringMessageFromError(error),
                      status: 'error',
                    })
                    values.enabled = true
                  }
                },
              })
            }}
          >
            <Item.Content>
              <Flex gap="s-16">
                <Icon name="Unlink" />
                <Item.Title>Disconnect Salesforce</Item.Title>
              </Flex>
            </Item.Content>
          </Item>
        )}
      </PageBody>
      <IntegrationEnablePopup
        isOpen={enableIntegrationPopupOpen}
        type="salesforce"
        onClose={() => {
          setEnableIntegrationPopupOpen(false)
        }}
      />
    </PageWrapper>
  )
}
