import React, { useState } from 'react'
import { ActionButton, Item, ItemSkeleton, Text, chain, Token } from '@revolut/ui-kit'

import {
  changeMerchantApiPaymentMethod,
  getMerchantApiConfig,
  useMerchantApiPaymentMethod,
  useSubsciptionInfo,
} from '@src/api/plans'
import { OrderAndConfig } from './SubscriptionPlanProvider'
import {
  CardItemAvatar,
  FAILED_PAYMENT_MESSAGE,
  NO_PAYMENT_METHOD_MESSAGE,
} from './common'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface PreviewPaymentDetailsProps {
  onSetOrderAndConfig: (data: OrderAndConfig) => void
}

export const PaymentDetails = ({ onSetOrderAndConfig }: PreviewPaymentDetailsProps) => {
  const { info } = useSubsciptionInfo()
  const { data: paymentMethodData } = useMerchantApiPaymentMethod(
    info?.revolut_merchant_api_payment_method?.id,
  )
  const errorPopup = useErrorPopup()
  const [createChangeOrderPending, setCreateChangeOrderPending] = useState(false)

  const cardNumber = paymentMethodData?.last4 ? (
    <Text color={Token.color.foreground}>•••• •••• •••• {paymentMethodData.last4}</Text>
  ) : null
  const expiryMonth =
    paymentMethodData?.expiry_month &&
    String(paymentMethodData.expiry_month).padStart(2, '0')
  const expiryYear =
    paymentMethodData?.expiry_year && String(paymentMethodData.expiry_year).slice(-2)
  const cardExpiry = expiryMonth && expiryYear ? `${expiryMonth}/${expiryYear}` : null
  const isRevolutPayMethod = paymentMethodData?.type.id === 'REVOLUT_PAY'
  const isLastPaymentFailed = info?.last_payment_status?.id === 'failure'
  const isOnCustomPlan = !!info?.subscription_plan?.manual_payment

  const onUpdate = async () => {
    if (!createChangeOrderPending) {
      setCreateChangeOrderPending(true)

      try {
        const [configResponse, orderResponse] = await Promise.all([
          getMerchantApiConfig(),
          changeMerchantApiPaymentMethod(),
        ])

        onSetOrderAndConfig({
          order: orderResponse.data,
          config: configResponse.data,
          type: 'change-payment-method',
        })
      } catch (error) {
        errorPopup.show({
          error,
          fallbackTitle: 'Failed to change payment method',
        })
      } finally {
        setCreateChangeOrderPending(false)
      }
    }
  }

  if (!info || (info.revolut_merchant_api_payment_method && !paymentMethodData)) {
    return <ItemSkeleton />
  }

  if (isOnCustomPlan) {
    return (
      <Item aria-label="payment method">
        <Item.Avatar>
          <CardItemAvatar variant="default" type="customPlan" />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>Invoice and bank transfer</Item.Title>
        </Item.Content>
      </Item>
    )
  }

  if (!info.revolut_merchant_api_payment_method) {
    return (
      <Item aria-label="payment method">
        <Item.Avatar>
          <CardItemAvatar variant="error" type="card" />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>{NO_PAYMENT_METHOD_MESSAGE}</Item.Title>
        </Item.Content>

        <Item.Side>
          <ActionButton onClick={onUpdate} pending={createChangeOrderPending}>
            Update
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  if (isRevolutPayMethod) {
    return (
      <Item aria-label="payment method">
        <Item.Avatar>
          <CardItemAvatar
            type="revolut-pay"
            variant={isLastPaymentFailed ? 'error' : 'default'}
          />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>Revolut Pay</Item.Title>
          {isLastPaymentFailed ? (
            <Item.Description color={Token.color.red}>
              {FAILED_PAYMENT_MESSAGE}
            </Item.Description>
          ) : null}
        </Item.Content>

        <Item.Side>
          <ActionButton onClick={onUpdate} pending={createChangeOrderPending}>
            Update
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  return (
    <Item aria-label="payment method">
      <Item.Avatar>
        <CardItemAvatar type="card" variant={isLastPaymentFailed ? 'error' : 'default'} />
      </Item.Avatar>

      <Item.Content>
        <Item.Title>
          <Text color={Token.color.greyTone50}>{chain(cardNumber, cardExpiry)}</Text>
        </Item.Title>
        {isLastPaymentFailed ? (
          <Item.Description color={Token.color.red}>
            {FAILED_PAYMENT_MESSAGE}
          </Item.Description>
        ) : null}
      </Item.Content>

      <Item.Side>
        <ActionButton onClick={onUpdate} pending={createChangeOrderPending}>
          Update
        </ActionButton>
      </Item.Side>
    </Item>
  )
}
