import { Flex, Side, VStack, chain } from '@revolut/ui-kit'
import { EntityTypes } from '@src/constants/api'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { RoadmapInterface, UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import pluralize from 'pluralize'
import React, { useMemo, useState } from 'react'
import { SideAction } from './SidebarAction'
import { GoalTargetCycleSelector } from '../../common/GoalTargetCycleSelector'

import { pick } from 'lodash'
import { ClickupTasks } from '@src/features/Roadmaps/Clickup/ClickupTasks'

export const LinkClickupTasksForm = ({
  submit,
  initialCycle,
  ownerId,
  pending,
  selected = [],
}: {
  submit: (payload: {
    cycle: ReviewCyclesInterface
    epics: UnassignedRoadmapInterface[]
  }) => void
  initialCycle?: ReviewCyclesInterface
  ownerId: number
  pending: boolean
  selected?: RoadmapInterface[]
}) => {
  const { entity } = useOrgEntity()
  const [cycle, setCycle] = useState<ReviewCyclesInterface | null>(initialCycle || null)
  const [roadmaps, setRoadmaps] = useState<UnassignedRoadmapInterface[]>(
    selected?.map(item => ({
      ...pick(item, 'epic_url', 'id', 'key', 'owner', 'issue_type'),
      display_name: item.name,
      epic_name: item.name,
      priority: item.priority,
      due_date: item.due_date!,
    })),
  )

  const selectedTasks = useMemo(() => {
    return roadmaps?.map(epic => ({
      key: epic.key,
      name: epic.epic_name,
      url: epic.epic_url,
    }))
  }, [roadmaps])

  const handleAdd = () => {
    if (cycle && roadmaps) {
      submit({ cycle, epics: roadmaps })
    }
  }

  return (
    <VStack space="s-16">
      <GoalTargetCycleSelector
        onChange={val => setCycle('start_date_time' in val ? val : null)}
        initialCycle={initialCycle}
        ownerId={ownerId}
        isEmployee={
          entity?.type === EntityTypes.employees || entity?.type === EntityTypes.employee
        }
      />
      <ClickupTasks
        onDelete={key => setRoadmaps(prev => prev?.filter(item => item.key !== key))}
        onSelectionChange={selectedOptions => {
          const mappedOptions: UnassignedRoadmapInterface[] = selectedOptions.map(
            option =>
              ({
                display_name: option.name,
                key: option.key,
                epic_name: option.name,
                epic_url: option.url,
                owner: { id: ownerId, name: ownerId },
              } as unknown as UnassignedRoadmapInterface),
          )
          setRoadmaps(mappedOptions)
        }}
        addedTasks={selectedTasks}
      />
      <Side.Actions>
        <Flex width="100%" justifyContent="center">
          <SideAction
            pending={pending}
            onClick={handleAdd}
            disabled={roadmaps?.length === 0}
          >
            Add {chain(pluralize('roadmap', roadmaps?.length), roadmaps?.length || 0)}
          </SideAction>
        </Flex>
      </Side.Actions>
    </VStack>
  )
}
