import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '@src/api/index'
import { getCommentsAPI } from '@src/api/comments'
import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  CommitteeResultInterface,
  ProbationCheckpoints,
  ProbationGoalInterface,
  ProbationManagersGrade,
} from '@src/interfaces/probationReview'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import {
  CycleDetailInterface,
  EmployeePipInterface,
  PipFormInterface,
} from '@src/pages/Forms/PipV2/interfaces'
import { useFetch } from '@src/utils/reactQuery'
import { PipCycleSettingsInterface } from '@src/interfaces/reviewCycles'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { ProbationInterface, ProbationStatisticsDTO } from '@src/interfaces/probation'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'

export const useGetEmployee = (employeeId: number | string) => {
  return useFetch<EmployeePipInterface>(`${API.PIP}/employee/${employeeId}`, 'v2')
}

export const pipRequestsNew: RequestInterfaceNew<PipFormInterface> = {
  get: async ({ id }) =>
    api.get(`${API.PIP}/cycleDetail/${getReviewCycleIdWithoutPrefix(id)}`, {}, 'v2'),
  update: async (_, { employeeId, id }, data) =>
    apiWithoutHandling.put(
      `${API.PIP}/${employeeId}/cycle/${getReviewCycleIdWithoutPrefix(id)}`,
      data,
      {},
      'v2',
    ),
  submit: async (data, { employeeId }) =>
    apiWithoutHandling.post(`${API.PIP}/${employeeId}/cycle`, data, {}, 'v2'),
}

export const pipCycleRequestsNew: RequestInterfaceNew<CycleDetailInterface> = {
  get: async ({ id }) =>
    api.get(`${API.PIP}/cycleDetail/${getReviewCycleIdWithoutPrefix(id)}`, {}, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.put(
      `${API.PIP}/cycleGoals/${getReviewCycleIdWithoutPrefix(id)}`,
      data,
      {},
      'v2',
    ),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(
      `${API.PIP}/cycleGoals/${getReviewCycleIdWithoutPrefix(id)}`,
      data,
      {},
      'v2',
    ),
}

export const useGetPipCheckpoints = (
  employeeId: number | string | null,
  cycleId: number | string | null,
) => {
  return useFetch<ProbationCheckpoints>(
    employeeId && cycleId
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints`
      : null,
    'v2',
  )
}

export const getPipGoals = (employeeId: number | string, cycleId: number | string) =>
  api.get<GetRequestInterface<ProbationGoalInterface>>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipGoals`,
    {},
    'v2',
  )

export const addPipGoals = (
  employeeId: number | string,
  data: ProbationGoalInterface,
  cycleId: number | string,
) =>
  api.post<ProbationGoalInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipGoals`,
    data,
    {},
    'v2',
  )

export const deletePipGoal = (
  employeeId: number | string,
  goalId: number,
  cycleId: number | string,
) =>
  api.delete(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipGoals/${goalId}`,
    {},
    'v2',
  )

export const approvePipGoals = (
  employeeId: number,
  checkpointId: number,
  cycleId: string,
) =>
  api.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints/${checkpointId}/approve`,
    {},
    undefined,
    'v2',
  )

export const requestEditPipGoals = (
  employeeId: number,
  checkpointId: number,
  cycleId: string,
) =>
  api.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints/${checkpointId}/request_goals_edits`,
    {},
    undefined,
    'v2',
  )

export const pipCommentsApi = (cycleId?: number | string) =>
  getCommentsAPI({
    baseUrl: `${API.PIP}/${getReviewCycleIdWithoutPrefix(cycleId)}/comments`,
    apiVersion: 'v2',
  })

export const pipReviewLayoutRequests: RequestInterfaceNew<ReviewScorecardInterface> = {
  get: async ({ id, employeeId }) => {
    return api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
      {
        params: {
          category: ReviewCategory.PIP_V2,
        },
      },
      'v2',
    )
  },
  update: async (data, { id }) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
        API.PERFORMANCE
      }/performanceReviews/${id}`,
      data,
      {},
      'v2',
    ),
  submit: async data =>
    apiWithoutHandling.post(
      `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
      {
        category: ReviewCategory.PIP_V2,
      },
      {},
      'v2',
    ),
}

export const committeeResultRequests: RequestInterfaceNew<CommitteeResultInterface> = {
  get: async ({ cycleId, employeeId, id }) =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions/${id}`,
      {},
      'v2',
    ),
  update: async (_, { employeeId, id, cycleId }, data) =>
    api.put(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions/${id}`,
      data,
      {},
      'v2',
    ),
  submit: async (data, { employeeId, cycleId }) =>
    api.post(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions`,
      data,
      {},
      'v2',
    ),
}

export const requestEditScorecard = (
  employeeId: number | string,
  cycleId: number | string,
  scorecardId: number | string,
  commentValue: string,
) => {
  return api.put(
    `${API.PIP}/${employeeId}/cycles/${cycleId}/scorecards/${scorecardId}/request_edits`,
    { comment: commentValue },
    undefined,
    'v2',
  )
}

export const approveScorecard = (
  employeeId: number | string,
  cycleId: number | string,
  scorecardId: number | string,
) => {
  return api.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints/${scorecardId}/approve_managers_feedback`,
    undefined,
    undefined,
    'v2',
  )
}
export const getPipManagersGrade = (
  employeeId: number,
  cycleId: string,
): AxiosPromise<ProbationManagersGrade> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions/calculatedManagersGrade`,
    {},
    'v2',
  )

export const pipCycleSettingsRequests: RequestInterfaceNew<PipCycleSettingsInterface> = {
  get: async ({ id }) => {
    return api.get(`${API.PIP}/cycleDetail/${id}`, {}, 'v2')
  },
  update: async (data, { employeeId, id }) => {
    return api.put(`${API.PIP}/${employeeId}/cycle/${id}`, data, {}, 'v2')
  },
  submit: async (data, { employeeId, id }) => {
    return api.post(`${API.PIP}/${employeeId}/cycle/${id}`, data, {}, 'v2')
  },
}

export const getPipItems = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ProbationInterface>> =>
  api.get(
    API.PIP_ITEMS,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getPipStatistics = async () =>
  api.get<ProbationStatisticsDTO>(`${API.PIP_STATS}`, undefined, 'v2')
