import { Box, Copyable, HStack, Spacer, Text, Token } from '@revolut/ui-kit'
import NewResponsiveCodeEditor from '@components/Inputs/NewCodeEditor/NewResponsiveCodeEditor'
import React from 'react'

interface ErrorMessageInterface {
  /** Message shown to the user in the popup */
  displayMessage: string
  /** Object with more details that will be copied to user clipboard */
  copyMessage: {
    status?: number
    message?: string
    data?: unknown
    method?: string
    url?: string
    requestId?: string
  }
}

export const ErrorMessage = ({ displayMessage, copyMessage }: ErrorMessageInterface) => {
  return (
    <Box
      backgroundColor={Token.color.groupedBackground}
      radius={Token.radius.widget}
      p="s-16"
    >
      <HStack mb="s-8">
        <Text>Error message</Text>
        <Spacer />
        <Copyable
          value={`${copyMessage.method} ${copyMessage.url}\n${copyMessage.status} ${
            copyMessage.message
          }\nRequest ID: ${copyMessage.requestId || '-'}\n${JSON.stringify(
            copyMessage.data,
            null,
            2,
          )}`}
          labelButtonCopy="Copy"
          style={{ width: 'auto' }}
        />
      </HStack>
      <Text textAlign="left">
        <NewResponsiveCodeEditor
          readonly
          language="json"
          /** Code editor and popup doesn't support dynamic heights. But expected `displayMessage` is stringified JSON, so we can count the number of lines */
          height={Math.min((displayMessage?.split('\n').length || 1) * 18, 270)}
          value={displayMessage}
          transparent
          customOptions={{
            lineNumbers: 'off',
            scrollbar: { horizontal: 'hidden', vertical: 'hidden' },
            folding: false,
          }}
        />
      </Text>
    </Box>
  )
}
