import React from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { Box, Ellipsis, useTooltip, Tooltip, Token, HStack, Icon } from '@revolut/ui-kit'

interface HiringProcessTitleProps {
  data: HiringProcessInterface
}

export const HiringProcessTitle = ({ data }: HiringProcessTitleProps) => {
  const referenceTooltip = useTooltip()
  const automaticSchedulingTooltip = useTooltip()
  const stageIsInherited =
    (data?.is_company_standard_round && !!data.company_hiring_stage?.id) ||
    data.is_reference_to_parent_stage ||
    !!data.role_hiring_stage?.id

  const stageCannotBeEdited = stageIsInherited || data?.limit_editing_in_specialisation
  return (
    <HStack gap="s-8" align="center">
      <Ellipsis>{data.title}</Ellipsis>
      {data.schedule_interview_automatically && (
        <Box {...automaticSchedulingTooltip.getAnchorProps()}>
          <Icon name="20/AutoExchange" color={Token.color.greyTone20} size={14} />
          <Tooltip {...automaticSchedulingTooltip.getTargetProps()}>
            This hiring stage has automations. Check stage for more info
          </Tooltip>
        </Box>
      )}
      {stageCannotBeEdited && (
        <Box {...referenceTooltip.getAnchorProps()}>
          <Icon name="16/LockOutline" color={Token.color.greyTone20} size={14} />
          <Tooltip {...referenceTooltip.getTargetProps()}>
            This stage is defined on the{' '}
            {(data.company_hiring_stage?.id && 'company') ||
              (data.role_hiring_stage?.id && 'role')}{' '}
            level and can be edited only in the{' '}
            {(data.company_hiring_stage?.id && 'stages bank') ||
              (data.role_hiring_stage?.id && 'parent role hiring stages')}
          </Tooltip>
        </Box>
      )}
    </HStack>
  )
}
