import React, { useEffect, useState } from 'react'
import { AxiosError } from 'axios'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import {
  ActionWidget,
  BottomSheet,
  Box,
  Button,
  Header,
  InputGroup,
  Item,
  StatusPopup,
  TransitionCollapse,
  Widget,
  useStatusPopup,
} from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import { API, API_ENDPOINT } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocusignPreferencesInterface } from '@src/interfaces/integrations'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { InfoOutline } from '@revolut/icons'
import { getDocusignOAuthUrl } from '@src/api/integrations'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { UsersTable } from './UsersTable'

declare global {
  interface Window {
    submitDocusign?: () => void
  }
}

export const DocuSignForm = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { values, initialValues, submit, apiErrors } =
    useLapeContext<DocusignPreferencesInterface>()

  const formValidator = useSafeFormValidator()

  const [consentUrl, setConsentUrl] = useState<string>()

  const statusPopup = useStatusPopup()

  useEffect(() => {
    // After giving consent in DocuSign, user is redirected to DocuSignConfirmation component, where this callback is triggered
    window.submitDocusign = () => {
      setConsentUrl(undefined)
      statusPopup.show(<StatusPopup variant="loading" preventUserClose />)
      handleSubmit()
    }
    return () => {
      delete window.submitDocusign
    }
  }, [])

  const disableEdit = !permissions.includes(PermissionTypes.ChangeDocuSignIntegration)

  const handleSubmit = formValidator?.validate(() => {
    return submit()
      .then(response => {
        queryClient.invalidateQueries(API.DOCUSIGN_INTEGRATION_PREFERENCES)
        showSuccessPopup()
        return response
      })
      .catch(error => {
        statusPopup.hide()
        throw error
      })
  })

  const showErrorPopup = (error: AxiosError) =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        <StatusPopup.Description>
          {getStringMessageFromError(error)}
        </StatusPopup.Description>
      </StatusPopup>,
    )

  const showSuccessPopup = () => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        // @ts-ignore
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Settings saved successfully</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const onSubmit = () => {
    statusPopup.show(<StatusPopup variant="loading" preventUserClose />)

    if (values.enabled) {
      getDocusignOAuthUrl({ ...initialValues, ...values })
        .then(res => {
          if (res.data.url) {
            apiErrors.enabled = undefined
            setConsentUrl(res.data.url)
            statusPopup.hide()
          } else {
            handleSubmit()
          }
        })
        .catch(error => {
          showErrorPopup(error)
        })
    } else {
      handleSubmit()
    }
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="DocuSign integration settings"
          subtitle="Setup DocuSign"
          backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        />
        <PageBody mb="s-64">
          <AutoStepper>
            <NewStepperTitle title="DocuSign" />
            <Widget data-testid="docusign">
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable DocuSign integration',
                  description:
                    'This integration will allow generating documents and signing them.',
                }}
                name="enabled"
                disabled={disableEdit}
              />
              <TransitionCollapse in={values.enabled}>
                <Box p="s-16">
                  <InputGroup>
                    <ActionWidget>
                      <ActionWidget.Title>How to setup</ActionWidget.Title>
                      <ActionWidget.Avatar>
                        <InfoOutline color="orange" />
                      </ActionWidget.Avatar>
                      <ActionWidget.Content px="s-16">
                        <ol>
                          <li>
                            Ensure you have DocuSign admin access and you can see the
                            Settings tab from the DocuSign landing page.
                          </li>
                          <li>Navigate to Settings → Integrations → Apps and Keys.</li>
                          <li>
                            Copy the details in this page as follows: Account ID to
                            Account ID field, User ID to User ID field, Account Base URI
                            to Account Base URI field.
                          </li>
                          <li>
                            Create a new app and copy the content of the Private Key to
                            the Private Key field (Once saved the key will be securely be
                            stored).
                          </li>
                        </ol>
                      </ActionWidget.Content>
                    </ActionWidget>
                    <LapeNewInput
                      required
                      name="base_path"
                      label="Account Base URI"
                      disabled={disableEdit}
                    />
                    <LapeNewInput
                      required
                      name="oauth_host_name"
                      label="OAuth host name"
                      disabled={disableEdit}
                    />
                    <LapeNewInput
                      required
                      name="client_id"
                      label="Integration Key"
                      type="password"
                      disabled={disableEdit}
                    />
                    <LapeNewInput
                      required
                      name="user_id"
                      label="User ID"
                      type="password"
                      disabled={disableEdit}
                    />
                    <LapeNewInput
                      required
                      name="account_id"
                      label="Account ID"
                      type="password"
                      disabled={disableEdit}
                    />
                    <LapeNewTextArea
                      name="private_key"
                      label="Private Key"
                      required
                      disabled={disableEdit}
                    />
                  </InputGroup>
                </Box>
              </TransitionCollapse>
            </Widget>

            <NewStepperTitle title="DocuSign Connect" />
            <Widget>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable DocuSign Connect',
                  description:
                    'This integration will allow DocuSign documents to be updated in real time in the system. ',
                }}
                name="connect_enabled"
                disabled={disableEdit}
              />
              <TransitionCollapse in={values.connect_enabled}>
                <Box p="s-16">
                  <InputGroup>
                    <ActionWidget>
                      <ActionWidget.Title>How to setup</ActionWidget.Title>
                      <ActionWidget.Avatar>
                        <InfoOutline color="orange" />
                      </ActionWidget.Avatar>
                      <ActionWidget.Content px="s-16">
                        <ol>
                          <li>
                            Ensure you have DocuSign admin access and you can see the
                            Settings tab from the DocuSign landing page.
                          </li>
                          <li>Navigate to Settings → Integrations → Connect.</li>
                          <li>Add a new connect configuration.</li>
                          <li>
                            Fill in the value for the URL to Publish with this:{' '}
                            <b>{`${API_ENDPOINT()}v1/docusign/connect/recipient/signed`}</b>
                          </li>
                          <li>
                            Enable the <b>Basic Authentication Header</b>.
                          </li>
                          <li>
                            Create a Username and a Password and set the values on this
                            page and on the DocuSign page.
                          </li>
                        </ol>
                      </ActionWidget.Content>
                    </ActionWidget>
                    <LapeNewInput
                      name="connect_username"
                      type="password"
                      label="Username"
                      required
                      disabled={disableEdit}
                    />
                    <LapeNewInput
                      name="connect_password"
                      type="password"
                      label="Password"
                      required
                      disabled={disableEdit}
                    />
                  </InputGroup>
                </Box>
              </TransitionCollapse>
            </Widget>

            <TransitionCollapse in={values.enabled}>
              <NewStepperTitle title="DocuSign users" />
              <UsersTable />
            </TransitionCollapse>
          </AutoStepper>
        </PageBody>

        <PageActions>
          <Button onClick={onSubmit} elevated>
            Save Changes
          </Button>
        </PageActions>
      </PageWrapper>

      <BottomSheet
        open={!!consentUrl}
        onClose={() => setConsentUrl(undefined)}
        labelButtonClose="Close"
      >
        <Header>
          <Header.Title>DocuSign</Header.Title>
        </Header>

        <Item>
          <Item.Content>
            <Item.Title>We need your consent</Item.Title>
            <Item.Description mt="s-8">
              In order to integrate with DocuSign, we need consent of the user configured
              via the User ID field.
              <br />
              <br />
              We will use this consent to access DocuSign when a user is otherwise not
              available (importing DocuSign data to our system, opening DocuSign for
              document signatures).
              <br />
              <br />
              This consent will NOT allow us to send or sign any documents in your name.
            </Item.Description>
          </Item.Content>
        </Item>

        <BottomSheet.Actions horizontal>
          <Button onClick={() => setConsentUrl(undefined)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              window.open(consentUrl, '_blank')
            }}
            elevated
          >
            Allow
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}
