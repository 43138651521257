import React from 'react'
import { Box, Group, Subheader, Text, Token } from '@revolut/ui-kit'

import { SelfReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/SelfReviewsSwitch'
import { LineManagerReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/LineManagerReviewsSwitch'
import { FunctionalManagerReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/FunctionalManagerReviewsSwitch'
import { UpwardsReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/UpwardsReviewsSwitch'
import { PeerReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/PeerReviewsSwitch'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { settingsModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/SettingsModel'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { FormattedMessage } from 'react-intl'

export const ReviewTypes = () => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const { data: settings } = useGetPerformanceSettings()

  const hasFunctionalManagerReviews =
    !!settings && settingsModel.hasFunctionalManagerReviews(settings)
  const isFunctionalManagerReviewsDisabled =
    !hasFunctionalManagerReviews && !values.enable_functional_manager_reviews

  return (
    <>
      <Box>
        <Subheader>
          <Subheader.Title>Review types</Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          Based on your selection, we will create a separate Scorecard for each review
          type.
        </Text>
      </Box>
      <Group>
        <SelfReviewsSwitch />
        <LineManagerReviewsSwitch />
        <FunctionalManagerReviewsSwitch
          disabled={isFunctionalManagerReviewsDisabled}
          tooltip={
            isFunctionalManagerReviewsDisabled ? (
              <Box p="s-2" width={200}>
                <FormattedMessage
                  id="performance.reviews.cycles.global.fm.reviews.disabled.label"
                  defaultMessage="Functional management or Functional manager reviews are disabled at the general settings level"
                />
              </Box>
            ) : undefined
          }
        />
        <UpwardsReviewsSwitch />
        <PeerReviewsSwitch />
      </Group>
    </>
  )
}
