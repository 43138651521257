import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import {
  useCanApproveGrowthPlan,
  useCanChangeGrowthPlan,
  useCanDeleteGrowthPlan,
  useCanRejectGrowthPlan,
} from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { growthPlanModel } from '@src/features/GrowthPlans/GrowthPlan/models/growthPlanModel'
import { CompleteAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/CompleteAction'
import { MissAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/MissAction'
import { ApproveAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/ApproveAction'
import { EditAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/EditAction'
import { RejectAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/RejectAction'
import { ArchiveAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/ArchiveAction'
import { NominateAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/NominateAction'
import { RemindToNominateAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/RemindToNominateAction'
import { DeleteAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/DeleteAction'
import {
  useCanNominateAnyTime,
  useCanNominateEmployee,
  useIsPromotionNominationWindowOpen,
} from '@src/features/Promotions/hooks/usePromotionNominationAvailability'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  refetch: VoidFunction
  refetchApproval: () => Promise<void>
}

export const GrowthPlanActions = ({
  employee,
  growthPlan,
  refetch,
  refetchApproval,
}: Props) => {
  const canChangeGrowthPlan = useCanChangeGrowthPlan(growthPlan)
  const canApproveGrowthPlan = useCanApproveGrowthPlan(growthPlan)
  const canRejectGrowthPlan = useCanRejectGrowthPlan(growthPlan)
  const canDeleteGrowthPlan = useCanDeleteGrowthPlan(growthPlan)

  const isPromotionNominationWindowOpen = useIsPromotionNominationWindowOpen()
  const canNominateAnyTime = useCanNominateAnyTime()
  const canNominateEmployee = useCanNominateEmployee(employee)

  const canCompete =
    canChangeGrowthPlan && growthPlanModel.isReadyForCompletion(growthPlan)

  const canReject = canRejectGrowthPlan && growthPlanModel.isReadyForApproval(growthPlan)
  const canApprove =
    canApproveGrowthPlan && growthPlanModel.isReadyForApproval(growthPlan)

  const canArchive = canChangeGrowthPlan && !growthPlanModel.isDraft(growthPlan)
  const canEdit = canChangeGrowthPlan && growthPlanModel.isEditable(growthPlan)
  const canDelete = canDeleteGrowthPlan && growthPlanModel.isDraft(growthPlan)

  const canNominate =
    canNominateEmployee &&
    (isPromotionNominationWindowOpen || canNominateAnyTime) &&
    growthPlanModel.isApproved(growthPlan) &&
    growthPlanModel.isCompleted(growthPlan) &&
    !growthPlanModel.hasNominationAttached(growthPlan)
  const canRemindToNominate =
    canNominateEmployee &&
    !isPromotionNominationWindowOpen &&
    growthPlanModel.isApproved(growthPlan) &&
    growthPlanModel.isCompleted(growthPlan) &&
    !growthPlanModel.hasNominationAttached(growthPlan) &&
    !growthPlanModel.hasNominationReminder(growthPlan)

  const handleOnArchive = () => {
    refetch()
  }

  const handleOnDelete = () => {
    refetch()
  }

  const handleOnApproval = () => {
    refetchApproval()
  }

  const handleOnMiss = () => {
    refetch()
  }

  return (
    <>
      {canCompete && (
        <>
          <MissAction growthPlan={growthPlan} onComplete={handleOnMiss} />
          <CompleteAction growthPlan={growthPlan} />
        </>
      )}
      {canReject && (
        <RejectAction growthPlan={growthPlan} onComplete={handleOnApproval} />
      )}
      {canApprove && (
        <ApproveAction growthPlan={growthPlan} onComplete={handleOnApproval} />
      )}
      {canNominate && <NominateAction employee={employee} />}
      {canRemindToNominate && <RemindToNominateAction growthPlan={growthPlan} />}
      {canArchive && (
        <ArchiveAction growthPlan={growthPlan} onComplete={handleOnArchive} />
      )}
      {canEdit && <EditAction employee={employee} growthPlan={growthPlan} />}
      {canDelete && <DeleteAction growthPlan={growthPlan} onComplete={handleOnDelete} />}
    </>
  )
}
