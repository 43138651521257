import React from 'react'
import { Flex, Text, Token, VStack, Widget } from '@revolut/ui-kit'

import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { NoDataPlaceholder, withLoader } from '../common'
import { PieChart } from './PieChart'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'

interface Props {
  data: EngagementResultV2Interface | undefined
  isLoading: boolean
  containerHeight: number
  commentsCount: number
  isCommentsLoading: boolean
}
export const PieChartWidget = ({
  data,
  isLoading,
  containerHeight,
  commentsCount,
  isCommentsLoading,
}: Props) => {
  if (isLoading) {
    return <ChartSkeleton height={containerHeight} />
  }
  if (!data) {
    return (
      <Widget height="100%">
        <NoDataPlaceholder />
      </Widget>
    )
  }
  const contentPadding = 12

  return (
    <TwoColumnsLayout
      leftMinWidth={300}
      rightMinWidth={200}
      rightMaxWidth={300}
      left={
        <Widget height={containerHeight} p={contentPadding}>
          {withLoader(
            isLoading,
            <PieChart
              data={data}
              containerHeight={containerHeight - contentPadding * 2}
            />,
          )}
        </Widget>
      }
      right={
        <Flex flex="1" gap="s-16" flexDirection="column">
          <Widget height="50%">
            <Flex
              flex="1"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {withLoader(
                isLoading,
                <VStack space="s-4" align="center">
                  <Text variant="emphasis1" color={Token.color.greyTone50}>
                    Total answers
                  </Text>
                  <Text variant="h0">{data.respondents}</Text>
                </VStack>,
              )}
            </Flex>
          </Widget>
          <Widget height="50%" width="100%">
            <Flex
              flex="1"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {withLoader(
                isCommentsLoading,
                <VStack space="s-4" align="center">
                  <Text variant="emphasis1" color={Token.color.greyTone50}>
                    Total comments
                  </Text>
                  <Text variant="h0">{commentsCount}</Text>
                </VStack>,
              )}
            </Flex>
          </Widget>
        </Flex>
      }
    />
  )
}
