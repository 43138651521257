import React from 'react'
import {
  Avatar,
  AvatarSkeleton,
  Box,
  Ellipsis,
  Flex,
  Grid,
  HStack,
  Skeleton,
  Spacer,
  Text,
  TextSkeleton,
  Token,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { PerformanceReviewAnalyticsInterface } from '@src/interfaces/performance'
import { formatPercentage } from '@src/utils/format'
import {
  gradeToColorMap,
  groupTypeToIconNameMap,
} from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/constants'
import { Tooltip } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/Tooltip'
import { GradeOption } from '@src/interfaces/selectors'

const ROW_LABEL_MIN_WIDTH = 50
const ROW_LABEL_WIDTH = 200
const BAR_ITEM_HEIGHT = 52
const DEFAULT_ROW_OPACITY = 0.8
const ACTIVE_ROW_OPACITY = 1

interface Props {
  data: PerformanceReviewAnalyticsInterface
  grades: GradeOption[]
  isHighlighted: boolean
  onMouseEnter: VoidFunction
  onMouseLeave: VoidFunction
  labelImage?: string
}

export const Row = ({
  data,
  grades,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
  labelImage,
}: Props) => {
  const tooltip = useTooltip()

  const { group, grades: groupGrades } = data
  const gridColumns = groupGrades.reduce<string>(
    (columns, { percent }) =>
      percent > 0 ? `${columns} ${Number(percent.toFixed(2)) * 100}fr` : columns,
    '',
  )
  const visibleGrades = groupGrades.filter(({ percent }) => percent > 0)

  return (
    <Box {...tooltip.getAnchorProps<HTMLDivElement>()}>
      <Flex
        key={group.id}
        justifyContent="center"
        alignItems="center"
        gap="s-16"
        opacity={isHighlighted ? ACTIVE_ROW_OPACITY : DEFAULT_ROW_OPACITY}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <HStack
          width={ROW_LABEL_WIDTH}
          minWidth={ROW_LABEL_MIN_WIDTH}
          align="center"
          space="s-12"
        >
          <Avatar
            size={32}
            image={labelImage}
            useIcon={labelImage ? undefined : groupTypeToIconNameMap[group.type]}
          />
          <Ellipsis>{group.name}</Ellipsis>
        </HStack>
        <Spacer />
        <Grid columns={gridColumns} columnGap="s-16" width="100%" overflow="auto">
          {visibleGrades.map(({ id, percent }) => (
            <VStack
              key={id}
              height={BAR_ITEM_HEIGHT}
              p="s-4"
              backgroundColor={gradeToColorMap[id]}
              radius={Token.radius.r6}
            >
              <Spacer />
              <Text textAlign="center" fontSize={12} use="p">
                {percent > 0.01 ? formatPercentage(percent) : '<1'}
              </Text>
              <Spacer />
            </VStack>
          ))}
        </Grid>
      </Flex>
      <Tooltip data={data} grades={grades} {...tooltip.getTargetProps<HTMLDivElement>()}>
        Hello, world
      </Tooltip>
    </Box>
  )
}

export const RowSkeleton = () => (
  <Flex justifyContent="center" alignItems="center" gap="s-16">
    <HStack
      width={ROW_LABEL_WIDTH}
      minWidth={ROW_LABEL_MIN_WIDTH}
      space="s-12"
      align="center"
    >
      <AvatarSkeleton size={32} />
      <TextSkeleton width={100} />
    </HStack>
    <Spacer />
    <Grid columns="1fr 2fr 1fr 5fr 3fr" columnGap="s-16" width="100%" overflow="auto">
      <Skeleton height={BAR_ITEM_HEIGHT} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={BAR_ITEM_HEIGHT} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={BAR_ITEM_HEIGHT} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={BAR_ITEM_HEIGHT} p="s-4" radius={Token.radius.r6} />
      <Skeleton height={BAR_ITEM_HEIGHT} p="s-4" radius={Token.radius.r6} />
    </Grid>
  </Flex>
)
