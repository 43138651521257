import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'
import { Box, Ellipsis, Flex, Text, Token } from '@revolut/ui-kit'
import {
  PerformanceScoreItemData,
  PerformanceScoreItemDataKeys,
} from '@src/features/ReviewCycle/Analytics/Charts/PerformanceScorePieChart/Items'

interface LegendItemProps extends Pick<PerformanceScoreItemData, 'name' | 'color'> {
  description: React.ReactNode
}

export const PerformanceScoreLegendItem = ({
  color,
  name,
  description,
}: LegendItemProps) => (
  <Flex gap="s-4" alignItems="center" justifyContent="center" flexWrap="wrap">
    <Box
      border={`1px solid ${Token.color.greyTone10}`}
      borderRadius={Token.radius.r2}
      bg={color}
      height="s-8"
      width="s-8"
    />
    <Text color={Token.color.foreground} fontSize="small">
      <Ellipsis>{name}</Ellipsis>
    </Text>
    <Text color={Token.color.greyTone50} fontSize="small">
      <Ellipsis>{description}</Ellipsis>
    </Text>
  </Flex>
)

const performanceLegendItemsKeys: PerformanceScoreItemDataKeys[] = [
  PerformanceScoreItemDataKeys.NIPS,
]

const performanceItemDataKeyToLegendDescriptionMap: Record<
  PerformanceScoreItemDataKeys,
  React.ReactNode
> = {
  [PerformanceScoreItemDataKeys.NIPS]: (
    <FormattedMessage
      defaultMessage="(Net Individual Performance Score)"
      id="performance.analytics.nips.legend.label"
    />
  ),
  [PerformanceScoreItemDataKeys.ALL]: null,
}

export const PerformanceScoreLegend = ({ payload }: LegendProps) => {
  const legendPayload = useMemo(
    () =>
      (payload ?? []).filter(entry => performanceLegendItemsKeys.includes(entry.value)),
    [payload],
  )

  return (
    <Flex gap="s-16" justifyContent="center" pt="s-8" px="s-16">
      {legendPayload.map(entry => (
        <PerformanceScoreLegendItem
          key={String(entry.value)}
          color={
            entry.color as typeof Token.colorChannel[keyof typeof Token.colorChannel]
          }
          name={entry.value}
          description={
            performanceItemDataKeyToLegendDescriptionMap[
              entry.value as PerformanceScoreItemDataKeys
            ]
          }
        />
      ))}
    </Flex>
  )
}
