import React from 'react'
import { ColumnInterface, CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { IssueInterface } from '@src/interfaces/issues'
import { A } from '@components/CommonSC/General'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import {
  DetailsCell,
  Flex,
  Group,
  Icon,
  Link,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import Table from '@components/TableV2/Table'
import { ColorTagVariant, ColorTag } from '@components/ColorTag/ColorTag'
import { AuditStatuses } from '@src/interfaces/audit'
import styled from 'styled-components'
import { formatPercentage } from '@src/utils/format'

const TooltipContainer = styled.div`
  white-space: nowrap;
  padding: 12px;
`

export const getSeverityColor = (data: IssueInterface) => {
  switch (data.severity?.id) {
    case 'minor':
      return Token.color.greyTone50
    case 'medium':
      return Token.color.warning
    case 'major':
    case 'blocker':
    case 'critical':
      return Token.color.danger
    default:
      return Token.color.foreground
  }
}

const getStatusVariant = (status: AuditStatuses): ColorTagVariant => {
  switch (status) {
    case AuditStatuses.DONE:
      return 'success'

    case AuditStatuses.TO_DO:
    case AuditStatuses.PENDING_FILING:
    case AuditStatuses.QUALITY_REVIEW:
      return 'neutral'

    default:
      return 'default'
  }
}

export const issueLinkColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_link',
  dataPoint: 'issue_link',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => (
    <A href={data.issue_link} target="_blank">
      <ColorTag icon="Jira|image">{data.issue_id}</ColorTag>
    </A>
  ),
  selectorsKey: selectorKeys.none,
  title: 'Issue',
}

export const issueSummaryColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
  insert: ({ data }) =>
    data.summary_link ? (
      <Link use="a" rel="noreferrer noopener" target="_blank" href={data.summary_link}>
        {data.summary}
      </Link>
    ) : (
      data.summary
    ),
}

export const issueTypeColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'issue_type.id',
  dataPoint: 'issue_type.name',
  sortKey: 'issue_type',
  filterKey: 'issue_type',
  selectorsKey: selectorKeys.issue_type,
  title: 'Type',
}

export const issueTeamColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const issueDepartmentColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'department__name',
  filterKey: 'department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const issueSeverityColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'severity.id',
  dataPoint: 'severity.name',
  sortKey: 'severity',
  filterKey: 'severity',
  selectorsKey: selectorKeys.issue_severity,
  title: 'Severity',
  colors: getSeverityColor,
}

export const issueAssigneeColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'assignee.id',
  dataPoint: 'assignee.name',
  sortKey: 'assignee__full_name',
  filterKey: 'assignee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Assignee',
  insert: ({ data }) => <UserWithAvatar {...data.assignee} />,
}

export const issueOpenDateColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Open date',
}

export const issueDueDateColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.date,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: 'due_date',
  filterKey: 'due_date',
  selectorsKey: selectorKeys.none,
  title: 'Due date',
}

export const issueDaysLeftColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'days_left',
  dataPoint: 'days_left',
  sortKey: 'days_left',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Days left / Overdue',
  insert: ({ data }) => {
    if (!data.days_left) {
      return '-'
    }
    if (data.days_left < 0) {
      return <Text color={Token.color.danger}>Overdue</Text>
    }
    return pluralize('day', data.days_left, true)
  },
}

export const issueStatusColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.issue_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getStatusVariant(data.status.id)}
    />
  ),
}

export const issueDailyChatsColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'chats_current',
  dataPoint: 'chats_current',
  sortKey: 'chats_current',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Daily chats',
  headerTooltip: (
    <TooltipContainer>
      <Flex alignItems="center">
        <Text>Value 3 weeks ago</Text> <Icon name="ArrowThinRight" size="s-12" />
        <Text>Value 1 week ago (Saturday to Friday)</Text>
      </Flex>
    </TooltipContainer>
  ),
  insert: ({ data }) => (
    <ProgressColumn
      initial={data.chats_initial}
      current={data.chats_current}
      progress={data.chat_diff_percent}
    />
  ),
}

export const issueDailyComplaintsColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'complaints_current',
  dataPoint: 'complaints_current',
  sortKey: 'complaints_current',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Daily complaints',
  headerTooltip: (
    <TooltipContainer>
      <Flex alignItems="center">
        <Text>Value 3 weeks ago</Text> <Icon name="ArrowThinRight" size="s-12" />
        <Text>Value 1 week ago (Saturday to Friday)</Text>
      </Flex>
    </TooltipContainer>
  ),
  insert: ({ data }) => (
    <ProgressColumn
      initial={data.complaints_initial}
      current={data.complaints_current}
      progress={data.complaints_diff_percent}
    />
  ),
}

const getCurrentColor = (progress: number | null) => {
  if (progress === null) {
    return Token.color.greyTone20
  }
  if (progress < 0) {
    return Token.color.success
  }
  if (progress < 0.2) {
    return Token.color.greyTone20
  }
  if (progress < 0.5) {
    return Token.color.warning
  }
  return Token.color.danger
}

function ProgressColumn({
  initial,
  current,
  progress,
}: {
  initial: number | null
  current: number | null
  progress: number | null
}) {
  const tooltip = useTooltip()
  return (
    <>
      <Flex
        columnGap="s-4"
        alignItems="center"
        width="100%"
        {...tooltip.getAnchorProps()}
      >
        <Text
          variant="emphasis2"
          color={Token.color.greyTone20}
          style={{ justifySelf: 'flex-end' }}
        >
          {initial?.toFixed(1)}
        </Text>
        <Icon name="ArrowThinRight" color={Token.color.greyTone20} size="s-16" />
        <Text
          variant="emphasis2"
          color={getCurrentColor(progress)}
          style={{ justifySelf: 'flex-end' }}
        >
          {current?.toFixed(1)}
        </Text>
      </Flex>
      <Tooltip placement="top" {...tooltip.getTargetProps()}>
        <Group minWidth={200}>
          <DetailsCell py="s-4" px="s-4">
            <DetailsCell.Title>3 weeks ago</DetailsCell.Title>
            <DetailsCell.Content>{initial?.toFixed(1)}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell py="s-4" px="s-4">
            <DetailsCell.Title>1 week ago (Sat-Fri)</DetailsCell.Title>
            <DetailsCell.Content color={getCurrentColor(progress)}>
              {current?.toFixed(1)}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell py="s-4" px="s-4">
            <DetailsCell.Title>Change (%)</DetailsCell.Title>
            <DetailsCell.Content color={getCurrentColor(progress)}>
              {progress && progress > 0 && '+'}
              {formatPercentage(progress)}
            </DetailsCell.Content>
          </DetailsCell>
        </Group>
      </Tooltip>
    </>
  )
}
