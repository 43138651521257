import React from 'react'
import { isNumber, omit } from 'lodash'
import { matchPath, useParams } from 'react-router-dom'
import {
  Box,
  FilterButtonSkeleton,
  Flex,
  Highlights,
  HStack,
  MoreBar,
  Separator,
  Token,
} from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import { useGetSurveyAnalytics } from '@src/api/engagement'
import {
  normalizedScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import Stat from '@src/components/Stat/Stat'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import TableLoader from '@src/components/TableV2/TableLoader'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { EngagementSurveyInterface, GroupByOptionItem } from '@src/interfaces/engagement'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { formatPercentage } from '@src/utils/format'
import { useQuery } from '@src/utils/queryParamsHooks'
import { history, pathToUrl } from '@src/utils/router'
import { toIdAndName, toLabeledIdAndName } from '@src/utils/toIdAndName'
import { useSelector } from 'react-redux'
import { allSurveyResultsBaseRoutes, ItemsToAnalyse, ViewMode } from './common'
import { useExportHeatmapData } from './hooks/useExportHeatmapData'
import { UseHeatmapFiltersReturnType } from './hooks/useHeatmapFilters'
import { SurveySelector, UseSurveySelectorReturnType } from './SurverySelector'
import { TableContent } from './TableContent'
import { TimelineFilter } from './TimelineFilter'
import { UseTimelineFilterReturnType } from './TimelineFilter/useTimelineFilter'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'

const getAnalysedItemsBaseRoute = () => {
  return allSurveyResultsBaseRoutes.find(
    route => !!matchPath(history.location.pathname, route.ANY),
  )?.ANY
}

const validateTimelineFilterConfig = (timelineFilter: UseTimelineFilterReturnType) => {
  if (timelineFilter.mode.value === 'calendar') {
    return true
  }
  if (timelineFilter.mode.value === 'rounds') {
    return Boolean(
      timelineFilter.round.value && timelineFilter.dateFrom && timelineFilter.dateTo,
    )
  }
  return true
}

const getTimelineFilterConfigErrorDescription = (
  timelineFilter: UseTimelineFilterReturnType,
) => {
  const unknownError = {
    title: 'Could not get survey results',
    description: 'Please, contact your system administrator',
  }

  if (timelineFilter.mode.value !== 'rounds') {
    return unknownError
  }
  if (!timelineFilter.round.options.length) {
    return {
      title: 'This survey does not have any rounds',
      description: 'You should start the survey to get some results',
    }
  }
  if (timelineFilter.round.options.length && !timelineFilter.round.value) {
    return {
      title: 'Survey round is not selected',
      description: 'Please, select survey round to see the results',
    }
  }
  return unknownError
}

interface StatHighlightsProps {
  surveyId: number | undefined
  scopeFilters: Props['scopeFilters']
}
const StatHighlights = ({ surveyId, scopeFilters }: StatHighlightsProps) => {
  const { data: surveyAnalytics, isLoading: isLoadingSurveryAnalytics } =
    useGetSurveyAnalytics(surveyId, scopeFilters)

  return (
    <Highlights>
      <Stat
        color={normalizedScoreToColor(surveyAnalytics?.average_score)}
        val={
          isLoadingSurveryAnalytics ? undefined : surveyAnalytics?.average_score ?? 'N/A'
        }
        label="Average score"
      />
      <Stat
        color={
          surveyAnalytics?.audience_size ? Token.color.foreground : Token.color.greyTone50
        }
        val={
          isLoadingSurveryAnalytics ? undefined : surveyAnalytics?.audience_size ?? 'N/A'
        }
        label="Audience size"
        tooltip="This is the number of active employees who were requested to complete the survey"
      />
      <Stat
        val={
          isLoadingSurveryAnalytics
            ? undefined
            : formatPercentage(surveyAnalytics?.response_rate ?? null, 2)
        }
        label="Participation"
        tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
        color={
          !surveyAnalytics?.response_rate
            ? Token.color.greyTone50
            : participationRateToColor(
                Math.floor(
                  isNumber(surveyAnalytics?.response_rate)
                    ? surveyAnalytics.response_rate * 100
                    : 0,
                ),
              )
        }
      />
    </Highlights>
  )
}

interface FiltersDropdownsProps {
  itemsToAnalyse: ItemsToAnalyse
  heatmapFilters: UseHeatmapFiltersReturnType
  viewMode: ViewMode
  isScopedView: boolean
  survey: EngagementSurveyInterface | undefined
}
const FiltersDropdowns = ({
  itemsToAnalyse,
  heatmapFilters,
  viewMode,
  isScopedView,
  survey,
}: FiltersDropdownsProps) => {
  const params = useParams()
  const { query, changeQueryParam } = useQuery<{ viewMode?: ViewMode }>()

  return (
    <HStack align="center" space="s-8">
      {itemsToAnalyse !== 'answers' && (
        <>
          <RadioSelectInput<IdAndName<ViewMode>>
            inputProps={{ width: 140 }}
            label="View mode"
            searchable={false}
            value={toIdAndName(viewMode)}
            options={['table' as const, 'heatmap' as const].map(toLabeledIdAndName)}
            onChange={value => {
              if (value?.id) {
                changeQueryParam('viewMode', value.id)
              }
            }}
          />
          {viewMode === 'heatmap' && (
            <RadioSelectInput
              inputProps={{ width: 180 }}
              label="Group by"
              searchable={false}
              value={heatmapFilters.groupBy.value}
              selector={selectorKeys.engagement_analytics_heatmap_groups}
              onChange={value => {
                if (value?.id) {
                  heatmapFilters.groupBy.setValue(value)
                }
              }}
            />
          )}
        </>
      )}
      <RadioSelectInput
        inputProps={{ width: 180 }}
        label="Analyse"
        searchable={false}
        value={toIdAndName(itemsToAnalyse || 'questions')}
        options={['categories' as const, 'questions' as const, 'answers' as const].map(
          toLabeledIdAndName,
        )}
        onChange={value => {
          if (!value) {
            return
          }
          if (survey) {
            const path = getAnalysedItemsBaseRoute()

            if (path) {
              navigateReplace(
                pathToUrl(
                  path,
                  isScopedView
                    ? { ...params, subtab: value.id }
                    : { ...params, type: value.id },
                  value.id === 'answers' ? omit(query, 'viewMode') : query,
                ),
              )
            }
          }
        }}
      />
    </HStack>
  )
}

interface Props {
  survey: EngagementSurveyInterface | undefined
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  surveySelector?: UseSurveySelectorReturnType
  isCompany?: boolean
}
export const ResultsTab = ({
  survey,
  timelineFilter,
  heatmapFilters,
  scopeFilters,
  surveySelector,
  isCompany,
}: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isDevEngagementV2 = featureFlags.includes(FeatureFlags.DevEngagementV2)

  const params = useParams<{ type: ItemsToAnalyse; subtab: ItemsToAnalyse }>()
  const itemsToAnalyseFromPath = params.type || params.subtab

  const { query } = useQuery<{ viewMode?: ViewMode }>()
  const { viewMode = 'table' } = query

  const { onInitExport } = useExportHeatmapData(
    survey?.id,
    timelineFilter.dateFrom,
    timelineFilter.dateTo,
  )
  const surveyId = survey?.id || surveySelector?.value?.id
  const isScopedView = !!scopeFilters

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {isCompany ? (
          <Flex
            width="100%"
            gap="s-32"
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack align="center" space="s-24">
              <CompanyNavigation />
              <Box height="s-48">
                <Separator orientation="vertical" />
              </Box>
              <StatHighlights surveyId={surveyId} scopeFilters={scopeFilters} />
            </HStack>
            <FiltersDropdowns
              itemsToAnalyse={itemsToAnalyseFromPath}
              heatmapFilters={heatmapFilters}
              viewMode={viewMode}
              isScopedView={isScopedView}
              survey={survey}
            />
          </Flex>
        ) : (
          <Flex
            width="100%"
            justifyContent="space-between"
            ml={isCompany ? 's-16' : undefined}
          >
            <StatHighlights surveyId={surveyId} scopeFilters={scopeFilters} />
            <FiltersDropdowns
              itemsToAnalyse={itemsToAnalyseFromPath}
              heatmapFilters={heatmapFilters}
              viewMode={viewMode}
              isScopedView={isScopedView}
              survey={survey}
            />
          </Flex>
        )}
      </Table.Widget.Info>
      <Table.Widget.Filters ml={isCompany ? 's-8' : undefined}>
        {surveySelector && (
          <SurveySelector
            {...surveySelector}
            onChange={() => timelineFilter.clearAll()}
          />
        )}
        <TimelineFilter hideModeSwitcher={isScopedView} {...timelineFilter} />
        {viewMode === 'heatmap' && (
          <>
            {heatmapFilters.groupBy.isLoadingAvailableItems ? (
              <FilterButtonSkeleton width={150} />
            ) : (
              <FilterButtonCheckboxSelect<GroupByOptionItem>
                searchable
                label={heatmapFilters.groupBy.value.name}
                options={heatmapFilters.groupBy.availableItems}
                value={heatmapFilters.value}
                onChange={newValue => {
                  if (heatmapFilters.groupBy && newValue) {
                    heatmapFilters.setValue(newValue)
                  } else {
                    heatmapFilters.setValue([])
                  }
                }}
              />
            )}
          </>
        )}
      </Table.Widget.Filters>
      {isDevEngagementV2 && (
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {viewMode === 'heatmap' && (
              <MoreBar.Action useIcon="ShareIOs" onClick={onInitExport}>
                Export heatmap data
              </MoreBar.Action>
            )}
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
      )}
      {surveyId &&
      !timelineFilter.round.isLoadingOptions &&
      !heatmapFilters.isLoadingGroupByOptions ? (
        <Table.Widget.Table>
          {validateTimelineFilterConfig(timelineFilter) ? (
            <TableContent
              viewMode={viewMode}
              surveyId={surveyId}
              heatmapFilters={heatmapFilters}
              timelineFilter={timelineFilter}
              scopeFilters={scopeFilters}
              itemsToAnalyse={itemsToAnalyseFromPath}
            />
          ) : (
            <EmptyTableRaw
              title={getTimelineFilterConfigErrorDescription(timelineFilter).title}
              description={
                getTimelineFilterConfigErrorDescription(timelineFilter).description
              }
              imageId="3D018"
            />
          )}
        </Table.Widget.Table>
      ) : (
        <TableLoader rowHeight="large" />
      )}
    </Table.Widget>
  )
}
