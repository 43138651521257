import React, { useMemo } from 'react'

import { navigateTo } from '@src/actions/RouterActions'
import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { useGetSelectorsWithFilters } from '@src/api/selectors'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2CommentsNumColumn,
  engagementResultsV2DistributionColumn,
  engagementResultsV2QuestionCategoryNameColumn,
  engagementResultsV2QuestionTypeColumn,
  getEngagementResultsV2QuestionNameColumn,
} from '@src/constants/columns/engagementResultsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useIsEngagementManager } from '@src/features/Engagement/hooks/permissions'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'

import { getDetailsPageQueryParams } from '../Details/common'
import { ItemsToAnalyse, ResultsInterface } from './common'
import {
  getDateRangeFilterParams,
  getSurveyRoundFilterParams,
  useApplyNonTableFilters,
} from './hooks/useApplyNonTableFilters'

const getRow = (
  surveyId: number,
  canViewDetails: boolean,
  queryParams: Record<string, string>,
  questionsOptions: IdAndName[] = [],
): RowInterface<EngagementResultV2Interface> => ({
  linkToForm: canViewDetails
    ? ({ id: questionId }) =>
        navigateTo(
          pathToUrl(
            ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.QUESTION,
            { id: surveyId, itemId: questionId },
            queryParams,
          ),
        )
    : undefined,
  cells: [
    { ...getEngagementResultsV2QuestionNameColumn(questionsOptions), width: 350 },
    { ...engagementResultsV2AverageScoreColumn, width: 120 },
    { ...engagementResultsV2DistributionColumn, width: 250 },
    { ...engagementResultsV2QuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsV2QuestionTypeColumn, width: 120 },
    { ...engagementResultsV2CommentsNumColumn, width: 100 },
  ],
})

export const ResultQuestionsTable = ({
  viewMode,
  surveyId,
  timelineFilter,
  scopeFilters = [],
}: ResultsInterface) => {
  const isEngagementManager = useIsEngagementManager()

  const itemsToAnalyse: ItemsToAnalyse = 'questions'
  const disableTableRequests = viewMode !== 'table'

  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, itemsToAnalyse),
    [
      ...getSurveyRoundFilterParams(timelineFilter),
      ...getDateRangeFilterParams(
        timelineFilter.dateFrom,
        timelineFilter.dateTo,
        itemsToAnalyse,
      ),
      ...scopeFilters,
    ],
    undefined,
    {
      disable: disableTableRequests,
      disableQuery: true,
    },
  )

  useApplyNonTableFilters({
    disable: disableTableRequests || table.loading,
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'questions',
  })

  const { data: questionsOptions = [] } = useGetSelectorsWithFilters<IdAndName>({
    type: selectorKeys.survey_questions,
    filters: [{ columnName: 'survey_id', filters: [toIdAndName(String(surveyId))] }],
  })
  const detailsPageQueryParams = getDetailsPageQueryParams(timelineFilter, scopeFilters)
  const row = useMemo(
    () => getRow(surveyId, isEngagementManager, detailsPageQueryParams, questionsOptions),
    [surveyId, isEngagementManager, detailsPageQueryParams, questionsOptions],
  )

  return (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultQuestions}
        row={row}
        emptyState={<EmptyTableRaw title="Results for the questions were not found" />}
        hideCount
        useWindowScroll
        {...table}
      />
    </>
  )
}
