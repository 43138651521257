import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Details,
  DetailsSkeleton,
  HStack,
  Subheader,
  SubheaderSkeleton,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { MetricsItemSkeleton } from '@src/features/AiInsights/Performance/components/Metrics/External/common/MetricsItemSkeleton'
import { OrgUnitSelector } from '@src/features/AiInsights/Performance/components/PerformanceOutput/OrgUnitSelector'
import {
  MetricsParamsKeys,
  useGetEmployeeBitbucketMetrics,
  useGetEmployeeJiraMetrics,
  useGetOrgUnitBitbucketMetrics,
  useGetOrgUnitJiraMetrics,
} from '@src/features/AiInsights/Performance/api/metrics'
import { JiraMetrics } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/JiraMetrics'
import { EpicsContributionsTable } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/EpicsContributionsTable/EpicsContributionsTable'
import { BitbucketMetrics } from '@src/features/AiInsights/Performance/components/Metrics/External/Bitbucket/BitbucketMetrics'

interface Props {
  employeeId: number
  cycleId: number
}

export const PerformanceOutput = ({ employeeId, cycleId }: Props) => {
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<BaseOption<string> | null>(null)

  const { data: orgUnits = [], isLoading: isOrgUnitsLoading } = useGetSelectors<
    BaseOption<string>
  >(selectorKeys.performance_insights_org_units)

  // TODO: display external metrics based on configured sources when BE implements it https://revolut.atlassian.net/browse/REVC-7971
  const { data: employeeJiraMetricsResults, isLoading: isEmployeeJiraMetricsLoading } =
    useGetEmployeeJiraMetrics(employeeId, {
      [MetricsParamsKeys.CycleId]: cycleId,
    })
  const { data: orgUnitJiraMetrics, isLoading: isOrgUnitJiraMetricsLoading } =
    useGetOrgUnitJiraMetrics(employeeId, {
      [MetricsParamsKeys.OrgUnitId]: selectedOrgUnit?.id,
      [MetricsParamsKeys.CycleId]: cycleId,
    })
  const employeeJiraMetrics = employeeJiraMetricsResults?.results[0]

  const {
    data: employeeBitbucketMetricsResults,
    isLoading: isEmployeeBitbucketMetricsLoading,
  } = useGetEmployeeBitbucketMetrics(employeeId, {
    [MetricsParamsKeys.CycleId]: cycleId,
  })
  const { data: orgUnitBitbucketMetrics, isLoading: isOrgUnitBitbucketMetricsLoading } =
    useGetOrgUnitBitbucketMetrics(employeeId, {
      [MetricsParamsKeys.OrgUnitId]: selectedOrgUnit?.id,
      [MetricsParamsKeys.CycleId]: cycleId,
    })
  const employeeBitbucketMetrics = employeeBitbucketMetricsResults?.results[0]

  useEffect(() => {
    if (!selectedOrgUnit && orgUnits.length > 0) {
      setSelectedOrgUnit(orgUnits[0])
    }
  }, [orgUnits])

  if (isOrgUnitsLoading) {
    return <Skeleton />
  }

  return (
    <VStack space="s-4">
      <VStack>
        <Subheader>
          <Subheader.Title>
            <HStack space="s-4" align="center">
              <AiIcon />
              <FormattedMessage
                id="performance.ai.insights.metrics.subheader.title"
                defaultMessage="Insights"
              />
            </HStack>
          </Subheader.Title>
        </Subheader>
        <Widget px="s-16" py="s-4">
          <Details>
            <Details.Title>
              <FormattedMessage
                id="performance.metrics.output.title"
                defaultMessage="Performance output"
              />
            </Details.Title>
            {selectedOrgUnit && (
              <Details.Content>
                <OrgUnitSelector
                  value={selectedOrgUnit}
                  options={orgUnits}
                  onSelect={option => setSelectedOrgUnit(option)}
                />
              </Details.Content>
            )}
          </Details>
        </Widget>
      </VStack>
      <VStack>
        <Subheader>
          <Subheader.Title>
            <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
              <FormattedMessage
                id="performance.metrics.external.sources.title"
                defaultMessage="Sources"
              />
            </Text>
          </Subheader.Title>
        </Subheader>
        <VStack space="s-16">
          {selectedOrgUnit && (
            <Widget>
              <VStack
                space="s-16"
                pb="s-16"
                data-testid="ai_insights_performance_jira_metrics"
              >
                <JiraMetrics
                  orgUnit={selectedOrgUnit}
                  employeeMetrics={employeeJiraMetrics ?? null}
                  orgUnitMetrics={orgUnitJiraMetrics ?? null}
                  isLoading={isEmployeeJiraMetricsLoading || isOrgUnitJiraMetricsLoading}
                />
                <Box px="s-16" data-testid="ai_insights_performance_jira_epics">
                  <EpicsContributionsTable employeeId={employeeId} cycleId={cycleId} />
                </Box>
              </VStack>
            </Widget>
          )}
          {selectedOrgUnit && (
            <Widget pb="s-16">
              <Box pb="s-16" data-testid="ai_insights_performance_bitbucket_metrics">
                <BitbucketMetrics
                  orgUnit={selectedOrgUnit}
                  employeeMetrics={employeeBitbucketMetrics ?? null}
                  orgUnitMetrics={orgUnitBitbucketMetrics ?? null}
                  isLoading={
                    isEmployeeBitbucketMetricsLoading || isOrgUnitBitbucketMetricsLoading
                  }
                />
              </Box>
            </Widget>
          )}
        </VStack>
      </VStack>
    </VStack>
  )
}

const Skeleton = () => (
  <VStack space="s-16">
    <SubheaderSkeleton />
    <Widget p="s-16">
      <DetailsSkeleton />
    </Widget>
    <SubheaderSkeleton />
    <Widget>
      <MetricsItemSkeleton />
    </Widget>
    <SubheaderSkeleton />
    <Widget>
      <MetricsItemSkeleton />
    </Widget>
  </VStack>
)
