import React, { useEffect, useRef, useState } from 'react'
import { VStack, Flex, TabBar, Widget, Placeholder } from '@revolut/ui-kit'
import { PerformanceSummaryHeader } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceSummaryHeader'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { PerformanceCyclesNavigationCollapsable } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceCyclesNavigationCollapsable'
import { PerformanceSummary } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceSummary'
import { PerformanceAnalytics } from './PerformanceAnalytics'
import {
  Cycle,
  PerformanceHistoryContentType,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import {
  useGetAggregatedStats,
  useGetDepartmentPerformanceStats,
  useGetDepartmentReviewCycles,
} from '@src/api/performance'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import PageLoading from '@components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import { goalsListTableRequests } from '@src/api/goals'
import { roadmapsRequests } from '@src/api/roadmaps'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { getDepartmentKarma, getDepartmentKarmaStats } from '@src/api/department'
import { Statuses } from '@src/interfaces'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { PerformanceCyclesNavigation } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceCyclesNavigation'

enum Tabs {
  Cycles = 'cycles',
  Analytics = 'analytics',
}

interface PerformanceTabContentProps {
  entityId: number
  entityName: string | undefined
  cyclesList: Cycle[]
  contentType: PerformanceHistoryContentType
}

const SELECTED_REVIEW_CYCLE_IDS_PARAM = 'cycle__id'

const PerformanceTabContent = ({
  entityId,
  entityName,
  contentType,
  cyclesList,
}: PerformanceTabContentProps) => {
  const HEADER_OFFSET = 70

  const { query, changeQueryParam } = useQuery(true)

  const [navigationTabValue, setNavigationTabValue] = useState<Tabs | null>(Tabs.Cycles)
  const [selectedCycle, setSelectedCycle] = useState<Cycle | undefined>()
  const featureFlags = useSelector(selectFeatureFlags)
  const expandYearsEnabled = featureFlags?.includes(
    FeatureFlags.DepartmentPerformanceExpandYearsEnabled,
  )

  const onSetSelectedCycle = (cycle: Cycle) => {
    changeQueryParam(SELECTED_REVIEW_CYCLE_IDS_PARAM, cycle.review_cycle_ids.toString())
    setSelectedCycle(cycle)
  }

  useEffect(() => {
    if (!(query.cycle__id && cyclesList.length)) {
      onSetSelectedCycle(cyclesList[1])
    }
  }, [])

  useEffect(() => {
    if (query.cycle__id && cyclesList.length) {
      const target = cyclesList?.find(
        c => c.review_cycle_ids.toString() === query.cycle__id,
      )
      setSelectedCycle(target)
    }
  }, [cyclesList])

  const goalsSettings = {
    initialFilters: [
      {
        columnName: 'object_id',
        filters: [{ id: entityId, name: entityName || '' }],
        nonResettable: true,
        nonInheritable: true,
      },
      {
        columnName: 'content_type_id',
        filters: [{ id: contentType.id, name: contentType.model }],
        nonResettable: true,
        nonInheritable: true,
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
    ],
    initialSort: [{ sortBy: 'goal_cycle_weight', direction: SORT_DIRECTION.ASC }],
  }

  const roadmapsSettings = {
    initialFilters: [
      {
        columnName: 'department__id',
        filters: [{ id: entityId, name: entityName || '' }],
        nonResettable: true,
        nonInheritable: true,
      },
    ],
    initialSort: [
      {
        sortBy: 'team__name',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'priority',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'due_date',
        direction: SORT_DIRECTION.DESC,
      },
    ],
  }

  const karmaSettings = {
    initialFilters: [],

    initialSort: [
      {
        sortBy: 'issue_date_time',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  }

  const { data: cycleStats } = useGetDepartmentPerformanceStats(
    contentType.id,
    selectedCycle?.review_cycle_ids,
    entityId,
  )

  const { data: summaryStats } = useGetAggregatedStats({
    contentTypeId: contentType.id,
    departmentId: entityId,
    navigationType: selectedCycle?.type,
    reviewCycleIds: selectedCycle?.review_cycle_ids,
  })

  const scrollGoalsRef = useRef<HTMLDivElement>(null)
  const scrollRoadmapRef = useRef<HTMLDivElement>(null)
  const scrollKarmaRef = useRef<HTMLDivElement>(null)

  const handleStatClick = (statName: 'goal' | 'roadmap' | 'karma') => {
    setTimeout(() => {
      const refMapping: Record<string, React.RefObject<HTMLDivElement>> = {
        goal: scrollGoalsRef,
        roadmap: scrollRoadmapRef,
        karma: scrollKarmaRef,
      }

      const element = refMapping[statName]?.current

      if (element) {
        let elementPosition = element.getBoundingClientRect().top
        let offsetPosition = elementPosition - HEADER_OFFSET

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }, 300)
  }

  return (
    <VStack space="s-16" width="100%">
      <Widget>
        <VStack width="100%">
          <Flex flexDirection="row">
            <PerformanceSummaryHeader
              summaryStats={summaryStats}
              cycleType={selectedCycle?.type}
              selectedCycle={selectedCycle}
              handleStatClick={handleStatClick}
            />
          </Flex>
        </VStack>
      </Widget>
      <Flex alignItems="center" justifyContent="space-between">
        <TabBar
          minWidth={260}
          onChange={selected => setNavigationTabValue(selected)}
          value={navigationTabValue}
        >
          <TabBar.Item to={Tabs.Cycles}>Review cycles</TabBar.Item>
          <TabBar.Item to={Tabs.Analytics}>Analytics</TabBar.Item>
        </TabBar>
      </Flex>
      {navigationTabValue === Tabs.Cycles && (
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={{ md: 270, all: '100%' }}
          // todo: update tests to have only one navigation type and remove FF REVCOR-3694
          left={
            expandYearsEnabled ? (
              <PerformanceCyclesNavigationCollapsable
                cyclesList={cyclesList}
                setSelectedCycle={onSetSelectedCycle}
                selectedCycle={selectedCycle}
              />
            ) : (
              <PerformanceCyclesNavigation
                cyclesList={cyclesList}
                setSelectedCycle={onSetSelectedCycle}
                selectedCycle={selectedCycle}
              />
            )
          }
          right={
            <PerformanceSummary
              cycleStats={cycleStats}
              selectedCycle={selectedCycle}
              goalTableRequests={goalsListTableRequests}
              roadmapsTableRequests={roadmapsRequests}
              karmaTableRequests={{
                getItems: getDepartmentKarma(entityId),
                getStats: getDepartmentKarmaStats(entityId),
              }}
              goalsSettings={goalsSettings}
              roadmapsSettings={roadmapsSettings}
              karmaSettings={karmaSettings}
              summaryStats={summaryStats}
              scrollGoalsRef={scrollGoalsRef}
              scrollRoadmapRef={scrollRoadmapRef}
              scrollKarmaRef={scrollKarmaRef}
            />
          }
        />
      )}
      {navigationTabValue === Tabs.Analytics && (
        <PerformanceAnalytics
          cyclesList={cyclesList}
          setSelectedCycle={onSetSelectedCycle}
          entityId={entityId}
        />
      )}
    </VStack>
  )
}

export const PerformanceTab = () => {
  const { entity } = useOrgEntity()
  const { data: contentTypes } = useGetSelectors<PerformanceHistoryContentType>(
    selectorKeys.performance_history_content_types,
  )

  const contentType = contentTypes?.find(({ model }) => model === 'department')
  const { data: cyclesList } = useGetDepartmentReviewCycles(
    contentType?.id,
    entity?.data.id,
  )
  return entity && contentType && cyclesList?.length ? (
    <PerformanceTabContent
      entityId={entity.data.id}
      entityName={entity.data.name}
      contentType={contentType}
      cyclesList={cyclesList}
    />
  ) : cyclesList?.length === 0 ? (
    <VStack space="s-16" width="100%">
      <Widget>
        <Placeholder>
          <Placeholder.Image
            image={{
              default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
              '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
              '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
            }}
          />
          <Placeholder.Title>Department Performance will appear here</Placeholder.Title>
        </Placeholder>
      </Widget>
    </VStack>
  ) : (
    <PageLoading />
  )
}
