import React from 'react'
import { Button, StatusPopup, useToggle } from '@revolut/ui-kit'
import { EmployeeWorkAndOrgDetailsInterface } from '@src/interfaces/employees'
import { DatePickerInputProps } from '@components/Inputs/DatePickerInput/DatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EffectiveDatePopup } from './EffectiveDatePopup'

interface UpdateButtonProps {
  datePickerProps?: DatePickerInputProps
  onAfterSubmit?: VoidFunction
}

export const UpdateButton = ({
  onAfterSubmit,
  ...datePickerProps
}: UpdateButtonProps) => {
  const { dirty, disabled, submit, values } =
    useLapeContext<EmployeeWorkAndOrgDetailsInterface>()
  const [effectiveDatePopupOpen, effectiveDateToggler] = useToggle()
  const [successPopupOpen, successPopupToggler] = useToggle()

  const shouldHideButton = (!!values.id && !dirty) || disabled

  const onSaveChanges = async () => {
    try {
      await submit()
      successPopupToggler.on()
    } finally {
      effectiveDateToggler.off()
    }
  }

  return (
    <>
      {effectiveDatePopupOpen && (
        <EffectiveDatePopup
          onClose={effectiveDateToggler.off}
          onSaveChanges={onSaveChanges}
          {...datePickerProps}
        />
      )}
      <StatusPopup
        onClose={() => {
          successPopupToggler.off()
          onAfterSubmit?.()
        }}
        open={successPopupOpen}
        variant="success"
      >
        <StatusPopup.Title>Compensation details successfully updated</StatusPopup.Title>
      </StatusPopup>
      {shouldHideButton ? null : (
        <Button elevated onClick={effectiveDateToggler.on}>
          Save changes
        </Button>
      )}
    </>
  )
}
