import React from 'react'

import {
  Color,
  HStack,
  Icon,
  TextButton,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import {
  EngagementResultInterface,
  EngagementResultsAverageScoreInterface,
  EngagementResultsScoresInterface,
  EngagementResultV2Interface,
  EngagementV2ResultsAverageScoreInterface,
} from '@src/interfaces/engagement'
import { mapScoreToColor, NON_ANSWERED_KEY, SCORE_KEYS } from '../constants'
import { PreviewBar } from './PreviewBar'
import {
  getTotalAnswers,
  getTotalAnswersFromAverage,
} from '@src/apps/People/Engagement/Results/components/DistributionChart/helpers'
import { Summary as RatingSummary, SummaryV2 } from './RatingTooltipContent/Summary'
import { Distribution as RatingDistribution } from './RatingTooltipContent/Distribution'
import { Summary as OpenEndedSummary } from './OpenEndedTooltipContent/Summary'
import { Distribution as OpenEndedDistribution } from './OpenEndedTooltipContent/Distribution'

type Distribution = {
  color: Color
  width: number
}

const getDistribution = ({
  scores,
  unanswered,
}: {
  scores: EngagementResultsScoresInterface
  unanswered: number
}): Distribution[] => {
  const total = getTotalAnswers({ scores, unanswered })

  return total
    ? SCORE_KEYS.map(scoreKey => {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        const scoreValue = scoreKey === NON_ANSWERED_KEY ? unanswered : scores[scoreKey]
        return {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          color: mapScoreToColor[scoreKey],
          width: scoreValue / total,
        }
      })
    : []
}

const getAvgDistribution = ({
  averageScore,
}: {
  averageScore: EngagementResultsAverageScoreInterface
}): Distribution[] => {
  const total = getTotalAnswersFromAverage(averageScore)

  return total
    ? [
        {
          color: Token.color.danger,
          width: averageScore.detractors / total,
        },
        {
          color: Token.color.yellow,
          width: averageScore.passives / total,
        },
        {
          color: Token.color.success,
          width: averageScore.promoters / total,
        },
        {
          color: Token.color.grey20,
          width: averageScore.unanswered / total,
        },
      ]
    : []
}

type Props = {
  data: EngagementResultInterface
  isAverage?: boolean
}
export const DistributionChart = ({ data, isAverage }: Props) => {
  const tooltip = useTooltip()

  const scores = data.scores_distribution
  const averageScore = {
    detractors: data.detractors,
    passives: data.passives,
    promoters: data.promoters,
    unanswered: data.unanswered,
  }
  const isOpenEnded = data.question?.type.id === 'open_ended'

  const distribution = getDistribution({
    scores,
    unanswered: data.unanswered,
  })
  const avgDistribution = getAvgDistribution({ averageScore })

  if (isOpenEnded) {
    return (
      <>
        <HStack space="s-8" align="center" {...tooltip.getAnchorProps()}>
          <Icon name="BarChart" size={16} color={Token.color.blue} />
          <TextButton>Hover to see details</TextButton>
        </HStack>
        {isAverage ? (
          <Tooltip {...tooltip.getTargetProps()}>
            <OpenEndedSummary distribution={data.open_ended_distribution} />
          </Tooltip>
        ) : (
          <Tooltip
            {...tooltip.getTargetProps({ placement: 'bottom' })}
            p={0}
            radius="cell"
          >
            <OpenEndedDistribution distribution={data.open_ended_distribution} />
          </Tooltip>
        )}
      </>
    )
  }
  return distribution.length ? (
    <>
      <PreviewBar
        tooltip={tooltip}
        distribution={isAverage ? avgDistribution : distribution}
      />
      {isAverage ? (
        <Tooltip {...tooltip.getTargetProps()}>
          <RatingSummary averageScore={averageScore} />
        </Tooltip>
      ) : (
        <Tooltip {...tooltip.getTargetProps({ placement: 'bottom' })} p={0} radius="cell">
          <RatingDistribution scores={scores} unanswered={data.unanswered} />
        </Tooltip>
      )}
    </>
  ) : null
}

const getAvgDistributionV2 = ({
  averageScore,
}: {
  averageScore: EngagementV2ResultsAverageScoreInterface
}): Distribution[] => {
  const total = averageScore.respondents || 1

  return [
    {
      color: Token.color.danger,
      width: averageScore.detractors / total,
    },
    {
      color: Token.color.yellow,
      width: averageScore.passives / total,
    },
    {
      color: Token.color.success,
      width: averageScore.promoters / total,
    },
    {
      color: Token.color.greyTone20,
      width: averageScore.ambivalents / total,
    },
  ]
}

interface DistributionChartV2Props {
  data: EngagementResultV2Interface
}
export const DistributionChartV2 = ({ data }: DistributionChartV2Props) => {
  const tooltip = useTooltip()

  const averageScore = {
    ambivalents: data.ambivalents,
    detractors: data.detractors,
    passives: data.passives,
    promoters: data.promoters,
    respondents: data.respondents,
  }
  const avgDistribution = getAvgDistributionV2({ averageScore })

  if (data.type === 'Open ended') {
    return <>-</>
  }
  return (
    <>
      <PreviewBar tooltip={tooltip} distribution={avgDistribution} />
      <Tooltip {...tooltip.getTargetProps()}>
        <SummaryV2 averageScore={averageScore} />
      </Tooltip>
    </>
  )
}
