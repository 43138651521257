import React from 'react'
import { SchedulePopup } from '../../EmployeeMeetings/SchedulePopup/SchedulePopup'
import { MoreBar, useToggle } from '@revolut/ui-kit'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PermissionTypes } from '@src/store/auth/types'
import { oneToOneModel } from '../../models/oneToOneModel'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'

interface Props {
  meeting?: OneToOneMeeting
}

export const ScheduleMeetingSeriesAction = ({ meeting }: Props) => {
  const user = useSelector(selectUser)
  const [schedulePopup, toggleSchedulePopup] = useToggle()
  const canChangeMeeting = useHasGlobalPermission(PermissionTypes.ChangeManagerMeeting)
  const queryClient = useQueryClient()

  if (!meeting) {
    return null
  }

  const isManager = oneToOneModel.isMeetingManagedBy(user, meeting)
  const isScheduled = oneToOneModel.isScheduled(meeting)

  const handleCompleteScheduling = () => {
    queryClient.refetchQueries([API.MEETINGS_EVENTS])
  }

  if (isScheduled) {
    return null
  }

  return (
    <>
      {(canChangeMeeting || isManager) && (
        <MoreBar.Action
          onClick={toggleSchedulePopup.on}
          variant="accent"
          useIcon="Calendar"
        >
          Create schedule
        </MoreBar.Action>
      )}
      <SchedulePopup
        isOpen={schedulePopup}
        meeting={meeting}
        handleCompleteScheduling={handleCompleteScheduling}
        handleClose={toggleSchedulePopup.off}
      />
    </>
  )
}
