import { InputGroup, Subheader, VStack, Widget } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { cycleNameDescription } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/constants'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'

export const GeneralSettings = () => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const isManual = cycleModel.isManual(values)

  return (
    <>
      <Subheader>
        <Subheader.Title>General settings</Subheader.Title>
      </Subheader>
      <Widget p="s-16">
        <VStack space="s-16">
          <InputGroup>
            <LapeNewInput
              name="name"
              label="Cycle name"
              description={cycleNameDescription}
              required
            />
          </InputGroup>
          {!isManual && (
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                name="start_date_time"
                label="Cycle Start Date"
                required
              />
              <LapeDatePickerInput name="end_date_time" label="Cycle End Date" required />
            </InputGroup>
          )}
        </VStack>
      </Widget>
    </>
  )
}
