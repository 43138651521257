import React from 'react'
import { TextButton, Token, HStack, IconButton } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { deleteSlackBot } from '@src/api/integrations'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { FeatureFlags } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

interface SlackBotActionsProps {
  id: number
  onDelete: () => void
}

const SlackBotActions = ({ id, onDelete }: SlackBotActionsProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const slackAppIntegrationEnabled = featureFlags.includes(
    FeatureFlags.SlackAppIntegration,
  )
  const confirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const handleDelete = () => {
    confirmationDialog.show({
      label: 'Are you sure you want to unregister slack bot?',
      body: '',
      onConfirm: async () => {
        try {
          await deleteSlackBot(id)
          onDelete()
        } catch (err) {
          showStatusPopup({
            title: 'Failed to unregister slack bot',
            description: getStringMessageFromError(err),
            status: 'error',
          })
        } finally {
          confirmationDialog.hide()
        }
      },
    })
  }

  if (slackAppIntegrationEnabled) {
    return (
      <IconButton
        useIcon="Delete"
        onClick={async e => {
          e.stopPropagation()
          handleDelete()
        }}
        size={16}
        color={Token.color.greyTone50}
      />
    )
  }

  return (
    <HStack gap="s-8">
      <TextButton
        color={Token.color.accent}
        onClick={e => {
          e.stopPropagation()
          navigateTo(pathToUrl(ROUTES.FEATURES.INTEGRATION.SLACK.BOT, { id }))
        }}
      >
        Edit
      </TextButton>
      <TextButton
        color={Token.color.danger}
        onClick={async e => {
          e.stopPropagation()
          handleDelete()
        }}
      >
        Remove
      </TextButton>
    </HStack>
  )
}

export default SlackBotActions
