import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { MeetingsTable } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsTable/MeetingsTable'
import { OneToOneExtensionBanner } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/components/ExtensionBanner/OneToOneExtensionBanner'
import { Action, Placeholder, VStack, Widget } from '@revolut/ui-kit'

import useIsCommercial from '@src/hooks/useIsCommercial'
import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'
import { PageNoAccessWidget } from '@src/features/Errors/components/Page/PageNoAccessWidget'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetEmployeePersonalSettings } from '@src/api/employees'

interface Props {
  data: EmployeeInterface
}

export const LayoutTab = ({ data }: Props) => {
  const isCommercial = useIsCommercial()
  const user = useSelector(selectUser)
  const isSelfUser = user.id === data.id
  const { data: employeeSettings, isLoading } = useGetEmployeePersonalSettings({
    enabled: isSelfUser && isCommercial,
  })
  const canSeeMeetingTable =
    isCommercial && isSelfUser // check if you're on yourself
      ? employeeSettings?.calendar_integration_enabled
      : true
  return (
    <>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}>
        <VStack space="s-16">
          <OneToOneExtensionBanner />
          {isLoading ? (
            <TableBodySkeleton rowHeight="medium" />
          ) : canSeeMeetingTable ? (
            <MeetingsTable employee={data} />
          ) : !employeeSettings?.calendar_integration_enabled ? (
            <Widget>
              <Placeholder>
                <Placeholder.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D403.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D403@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D403@3x.png',
                  }}
                />
                <Placeholder.Title>Calendar not linked</Placeholder.Title>
                <Placeholder.Description>
                  Link your calendar in order to use 1:1 meetings feature
                </Placeholder.Description>
                <Placeholder.Actions>
                  <Action
                    use={InternalLink}
                    to={pathToUrl(ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS, { id: user.id })}
                  >
                    Link account
                  </Action>
                </Placeholder.Actions>
              </Placeholder>
            </Widget>
          ) : (
            <PageNoAccessWidget />
          )}
        </VStack>
      </Route>
    </>
  )
}
