import React from 'react'
import { API } from '@src/constants/api'
import {
  importBonusesBonusCategoryColumn,
  importBonusesBonusCurrencyISOCodeColumn,
  importBonusesBonusCycleColumn,
  importBonusesBonusDateColumn,
  importBonusesEmployeeEmailColumn,
  importBonusesTargetColumn,
  importBonusesTaxabilityColumn,
} from '@src/constants/columns/importBonuses'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportBonusesInterface } from '@src/interfaces/importBonuses'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportBonusesInterface>> => ({
  cells: [
    {
      ...importBonusesEmployeeEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesBonusCycleColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesBonusCategoryColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesTargetColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesBonusCurrencyISOCodeColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesBonusDateColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesTaxabilityColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportBonuses = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.EMPLOYEE_BONUSES_BULK_UPLOAD}
      apiVersion="v2"
      anyRoute={ROUTES.FORMS.IMPORT_DATA.BONUSES.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.BONUSES.REVIEW_DATA}
      category="cash_bonuses_bulk_upload"
      entity="bonus"
      name="bonus"
      row={row}
      tableName={TableNames.UploadCashBonuses}
      header={<PageHeader backUrl={ROUTES.MAIN} title="Import cash bonuses" />}
      hiddenColumns={{ select: true }}
    />
  )
}
