import React from 'react'
import { FormattedMessage } from 'react-intl'
import pluralize from 'pluralize'
import { HStack, Spacer, Text, Token, VStack } from '@revolut/ui-kit'
import {
  getComparableMetricProps,
  MetricDetailsVariants,
} from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'
import { Capitalize } from '@components/CommonSC/General'
import { formatNumericValue } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/formatters'

interface Props {
  value: number
  average: number
  metricName: string
  orgUnitName: string
  employeeName?: string
  title: React.ReactNode
  description?: React.ReactNode
  summary?: React.ReactNode
  variant?: MetricDetailsVariants
}

// TODO: populate metric details with text content and performance history charts
//  when it's defined by PO/UX https://revolut.atlassian.net/browse/REVC-8120
export const MetricDetails = ({
  value,
  average,
  employeeName,
  metricName,
  orgUnitName,
  title,
  description,
  summary,
  variant = MetricDetailsVariants.Default,
}: Props) => {
  const metricProps = getComparableMetricProps(value, average)

  return (
    <VStack space="s-8" p="s-8">
      <Text fontWeight={500}>{title}</Text>
      {description && <Text variant="body2">{description}</Text>}
      <VStack space="s-4">
        <HStack align="center">
          <Text color={Token.color.greyTone50}>
            <FormattedMessage
              id="performance.metrics.details.employee.name.title"
              values={{ employeeName: employeeName || 'Employee' }}
              defaultMessage="{employeeName}:"
            />
          </Text>
          <Spacer />
          <Text color={metricProps?.color}>
            <DetailsContent value={value} name={metricName} variant={variant} />
          </Text>
        </HStack>
        <HStack align="center">
          <Text color={Token.color.greyTone50}>
            <Capitalize>
              <FormattedMessage
                id="performance.metrics.details.org.unit.avg.title"
                values={{ orgUnitName }}
                defaultMessage="{orgUnitName} avg:"
              />
            </Capitalize>
          </Text>
          <Spacer />
          <DetailsContent value={average} name={metricName} variant={variant} />
        </HStack>
      </VStack>
      {summary && summary}
    </VStack>
  )
}

interface DetailsContentProps {
  value: number
  name: string
  variant: MetricDetailsVariants
}

const DetailsContent = ({ value, name, variant }: DetailsContentProps) => {
  const formattedValue = formatNumericValue(value, variant)

  if (variant !== MetricDetailsVariants.Default) {
    return <Text>{formattedValue}</Text>
  }

  return (
    <FormattedMessage
      id="performance.metrics.details.content"
      values={{ count: formattedValue, name: pluralize(name, value) }}
      defaultMessage="{count} {name}"
    />
  )
}
