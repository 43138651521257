import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Avatar, Fixed, Item, ItemSkeleton, Text, Token, VStack } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { useGetEngagementAnswerDetails } from '@src/api/engagement'

import { AnswerWidget } from '../common/AnswerWidget'
import { RepliesWidget } from '../common/RepliesWidget'

const BottomWrapper = styled(Fixed)`
  &::before {
    content: '';
    position: absolute;
    top: -36px;
    height: 36px;
    width: ${Token.breakpoint.md};
    background: linear-gradient(to top, ${Token.color.greyTone2}, rgba(255, 0, 0, 0));
    z-index: -1;
  }
`

export const EngagementAnswerReplyThread = () => {
  const { answerId } = useParams<{ questionId: string; answerId: string }>()

  const { data: answerData, isLoading: isLoadingAnswer } = useGetEngagementAnswerDetails(
    Number(answerId),
  )

  return (
    <PageWrapper>
      <PageHeader title="Survey question replies" backUrl={ROUTES.MAIN} />
      <PageBody>
        <VStack space="s-16">
          {isLoadingAnswer ? (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          ) : (
            <>
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="QuestionOutline" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    {answerData?.question.question_text ? (
                      <Text variant="h4">{answerData.question.question_text}</Text>
                    ) : (
                      <Text color={Token.color.greyTone50}>Question not found</Text>
                    )}
                  </Item.Title>
                </Item.Content>
              </Item>
              <AnswerWidget answer={answerData} />
            </>
          )}
          <RepliesWidget
            answerId={answerId}
            bottomGap={96}
            renderInputWrapper={input => (
              <BottomWrapper
                width={Token.breakpoint.md}
                pb="s-48"
                pt="s-12"
                bottom={0}
                backgroundColor={Token.color.greyTone2}
              >
                {input}
              </BottomWrapper>
            )}
          />
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
