import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { EnableButton } from './EnableButton'
import { useQuery } from '@src/utils/queryParamsHooks'
import { confirmDeelIntegration } from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const AUTH_STATE_QUERY_KEY = 'state'
const AUTH_CODE_QUERY_KEY = 'code'

export const DeelIntegration = () => {
  const { query, deleteQueryParam } = useQuery()
  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()

  useEffect(() => {
    const authState = query[AUTH_STATE_QUERY_KEY]
    const authCode = query[AUTH_CODE_QUERY_KEY]

    if (authState && authCode) {
      confirmationPopup.show({
        label: 'Enable Deel integration',
        body: 'Press Confirm button to finish integration process',
        yesMessage: 'Confirm',
        noMessage: 'Cancel',
        onConfirm: async () => {
          try {
            await confirmDeelIntegration(authState, authCode)
            showStatusPopup({
              title: 'Deel integration sucessfully enabled',
              status: 'success',
            })
            deleteQueryParam(AUTH_STATE_QUERY_KEY)
            deleteQueryParam(AUTH_CODE_QUERY_KEY)
          } catch (error) {
            showStatusPopup({
              title: 'Could not enable Deel integration',
              description: getStringMessageFromError(error),
              status: 'error',
            })
          }
        },
      })
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader title="Deel" subtitle="Payroll" backUrl={ROUTES.SETTINGS.INTEGRATIONS}>
        <EnableButton />
      </PageHeader>
      <PageBody>
        <OnboardingIntro videoPosterUrl="/integrations/Integration_Deel.png">
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.deel.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.deel.intro.description"
                defaultMessage="Use Revolut People as the source of truth for all your payroll needs, making sure all information is flowing to Deel and that no manual input is needed."
              />
            </p>
          </OnboardingIntroTextWidget>
          <OnboardingIntroTips
            title={
              <FormattedMessage
                id="integrations.deel.intro.tips.title"
                defaultMessage="How to use"
              />
            }
            items={[
              {
                title: (
                  <FormattedMessage
                    id="integrations.deel.intro.tips.sync.title"
                    defaultMessage="Automatically synchronise employee information with Deel"
                  />
                ),
                icon: 'ArrowExchange',
                text: (
                  <FormattedMessage
                    id="integrations.deel.intro.tips.sync.description"
                    defaultMessage="All changes will be sent to Deel when specified within a paygroup"
                  />
                ),
              },
            ]}
          />
        </OnboardingIntro>
      </PageBody>
    </PageWrapper>
  )
}
