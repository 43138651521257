import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { TabBar, VStack } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageWrapper } from '@components/Page/Page'
import { CycleOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import Loader from '@components/CommonSC/Loader'
import { AnalyticsOverview } from '@src/features/ReviewCycle/Analytics/Overview/AnalyticsOverview'
import { FilterByInterface } from '@src/interfaces/data'
import { isFutureScheduledCycleOption } from '@src/utils/reviewCycles'
import { AnalyticsHistory } from '@src/features/ReviewCycle/Analytics/History/AnalyticsHistory'

export enum PerformanceReviewAnalyticsSubTabs {
  Overview = 'overview',
  History = 'history',
}

interface Props {
  cycleId: number | string | undefined
  cycleOptions: CycleOption[]
}

export const CYCLE_ID_QUERY_PARAM_KEY = 'cycle__id'

export const PerformanceReviewAnalyticsPage = ({ cycleId, cycleOptions }: Props) => {
  const { subtab } = useParams<{ subtab: PerformanceReviewAnalyticsSubTabs }>()
  const [navigationTabValue, setNavigationTabValue] =
    useState<PerformanceReviewAnalyticsSubTabs | null>(subtab)

  useEffect(() => {
    setNavigationTabValue(subtab)
  }, [subtab])

  const handleSubTabNavigation = (subTab: PerformanceReviewAnalyticsSubTabs | null) => {
    navigateReplace(
      pathToUrl(
        ROUTES.PERFORMANCE.REVIEWS_ANALYTICS.ANY,
        {
          subtab: subTab,
        },
        cycleId ? { [CYCLE_ID_QUERY_PARAM_KEY]: String(cycleId) } : undefined,
      ),
    )
  }

  const handleFilterCycle = (filter: FilterByInterface) => {
    const [cycleFilterOption] = filter.filters

    navigateReplace(
      pathToUrl(
        ROUTES.PERFORMANCE.REVIEWS_ANALYTICS.ANY,
        {
          subtab,
        },
        { [CYCLE_ID_QUERY_PARAM_KEY]: String(cycleFilterOption.id) },
      ),
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title={<PageHeader.Title title="Analytics" useIcon="BarChart" />}
        backUrl={ROUTES.PERFORMANCE.REVIEWS.GENERAL}
      />
      <PageBody maxWidth="100%">
        <VStack space="s-16">
          <TabBar<'div', PerformanceReviewAnalyticsSubTabs>
            onChange={handleSubTabNavigation}
            value={navigationTabValue}
          >
            <TabBar.Item to={PerformanceReviewAnalyticsSubTabs.Overview}>
              <FormattedMessage defaultMessage="Overview" id="common.overview.label" />
            </TabBar.Item>
            <TabBar.Item to={PerformanceReviewAnalyticsSubTabs.History}>
              <FormattedMessage defaultMessage="History" id="common.history.label" />
            </TabBar.Item>
          </TabBar>
          {navigationTabValue === PerformanceReviewAnalyticsSubTabs.Overview && (
            <AnalyticsOverview
              cycleId={cycleId}
              cycleOptions={cycleOptions}
              handleFilterCycle={handleFilterCycle}
            />
          )}
          {navigationTabValue === PerformanceReviewAnalyticsSubTabs.History && (
            <AnalyticsHistory />
          )}
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

export const PerformanceReviewsAnalytics = () => {
  const { search } = useLocation()
  const cycleIdQueryParam = new URLSearchParams(search).get(CYCLE_ID_QUERY_PARAM_KEY)
  const {
    initialCycleOffset: cycleId,
    cycles: cycleOptions,
    loading,
  } = useSelectedPerformanceCycle(selectorKeys.all_review_cycles)
  const cycleOptionsWithoutFutureScheduled = cycleOptions.filter(
    cycleOption => !isFutureScheduledCycleOption(cycleOption) && !cycleOption.is_test,
  )

  if (loading) {
    return <Loader />
  }

  return (
    <PerformanceReviewAnalyticsPage
      cycleId={cycleIdQueryParam ?? cycleId}
      cycleOptions={cycleOptionsWithoutFutureScheduled}
    />
  )
}
