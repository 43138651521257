import {
  JobPostingInterface,
  JobPostingLocationInterface,
  PublishingStatuses,
} from '@src/interfaces/jobPosting'

export const getNormalizedLocations = (locations: JobPostingLocationInterface[]) =>
  locations.reduce<{ office: string[]; remote: string[] }>(
    (acc, item) => {
      switch (item.type) {
        case 'remote': {
          acc.remote.push(item.location_name)
          break
        }

        case 'office': {
          acc.office.push(item.location_name)
          break
        }

        default: {
          acc.remote.push(item.location_name)
        }
      }
      return acc
    },
    {
      office: [],
      remote: [],
    },
  )

export const isPublished = (jobPosting: JobPostingInterface) =>
  jobPosting.status !== PublishingStatuses.unpublished &&
  jobPosting.status !== PublishingStatuses.closed
