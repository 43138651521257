import React, { useState } from 'react'
import {
  Box,
  Subheader,
  InputGroup,
  TransitionCollapse,
  Avatar,
  BottomSheet,
  Header,
  Token,
  Button,
  Widget,
} from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'

import { companyBillingSettingsRequests } from '@src/api/plans'
import { CompanyBillingSettingsInterface } from '@src/interfaces/plans'
import { selectorKeys } from '@src/constants/api'
import Form from '@src/features/Form/Form'
import { SubheaderWithDescription } from '@src/components/SubheaderWithDescription'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PaymentDetails } from './PaymentDetails'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { OrderAndConfig } from '@src/pages/Forms/Plans/SubscriptionPlanProvider'
import { CheckoutWidget } from '@src/pages/Forms/Plans/CheckoutWidget'
import { API } from '@src/constants/api'
import { useRetryPaymentFlow } from '@src/pages/AccountRestricted/useRetryPaymentFlow'

export const PaymentDetailsPage = () => {
  const [orderAndConfig, setOrderAndConfig] = useState<OrderAndConfig>()

  const queryClient = useQueryClient()

  const { onRetryPayment } = useRetryPaymentFlow({
    onSuccessPopupClose: () => {
      resetState()
    },
  })

  const resetState = () => {
    queryClient.invalidateQueries(API.SUBSCRIPTIONS_INFO_API)
    setOrderAndConfig(undefined)
  }

  return (
    <>
      <Box maxWidth={Token.breakpoint.md}>
        <PaymentDetails onSetOrderAndConfig={setOrderAndConfig} />
      </Box>

      <Form api={companyBillingSettingsRequests} forceParams={{ id: '1' }}>
        <PaymentDetailsForm />
      </Form>

      <BottomSheet open={!!orderAndConfig} onClose={resetState}>
        <Header>
          <Header.Title>Update payment method</Header.Title>
        </Header>

        {orderAndConfig && (
          <CheckoutWidget
            orderAndConfig={orderAndConfig}
            onRetryPayment={onRetryPayment}
            onSuccessPopupClose={resetState}
          >
            <CheckoutWidget.Details />

            <BottomSheet.Actions horizontal>
              <Button variant="secondary" onClick={resetState}>
                Cancel
              </Button>
              <CheckoutWidget.Actions pendingState="button" />
            </BottomSheet.Actions>
          </CheckoutWidget>
        )}
      </BottomSheet>
    </>
  )
}

const PaymentDetailsForm = () => {
  const { values } = useLapeContext<CompanyBillingSettingsInterface>()

  return (
    <>
      <PageBody>
        <Box mt="s-16">
          <SubheaderWithDescription description="Edit your company name and address for invoices, and choose the email address where they should be sent.">
            <Subheader.Title>Invoice details</Subheader.Title>
          </SubheaderWithDescription>

          <InputGroup>
            <LapeNewInput
              name="company_name"
              label="Company name"
              description="Choose how your company name should appear on invoices"
              required
            />

            <LapeNewInput
              name="email"
              label="Billing email"
              description="Choose where to send invoices"
              required
            />

            <Widget>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Use different address on invoices',
                  description:
                    'Enable to manually provide the address to use on invoices',
                  avatar: <Avatar useIcon="LocationPin" />,
                }}
                name="has_billing_address"
              />
              <TransitionCollapse in={values.has_billing_address}>
                <Box p="s-16">
                  <InputGroup>
                    <LapeRadioSelectInput
                      name="country"
                      label="Country"
                      selector={selectorKeys.countries}
                    />
                    <LapeNewInput name="post_code" label="Postal code" required />
                    <InputGroup variant="horizontal">
                      <LapeNewInput
                        name="address_line_1"
                        label="Address line 1"
                        required
                      />
                      <LapeNewInput
                        name="address_line_2"
                        label="Address line 2"
                        required
                      />
                    </InputGroup>
                    <LapeNewInput name="city" label="City" required />
                  </InputGroup>
                </Box>
              </TransitionCollapse>
            </Widget>
          </InputGroup>
        </Box>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup useValidator>Save</NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
