import React from 'react'
import {
  VStack,
  Widget,
  Text,
  Icon,
  copyToClipboard,
  Box,
  Grid,
  Token,
  Subheader,
  TravelWidget,
  HStack,
  Spacer,
} from '@revolut/ui-kit'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LinearIntegrationInterface } from '@src/interfaces/integrations'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { LINEAR_SETTINGS_API } from '@src/constants/externalLinks'

interface Props {
  setLightBoxSrc: (src: { video: string; poster: string } | undefined) => void
}

export const LinearHelperContent = ({ setLightBoxSrc }: Props) => {
  const { values } = useLapeContext<LinearIntegrationInterface>()

  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Log in to your <Text fontWeight={700}>Linear account</Text>, go to
                Settings and click <Text fontWeight={700}>API</Text>
              </Text>
            }
            bulletVariant="filled"
            subtitle={
              <VStack pt="s-12">
                <OpenInNewTab link={LINEAR_SETTINGS_API} label="Take me there" />
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title="Click ”+” in OAuth Applications"
            subtitle="You need to be an admin or owner in order to do this"
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title="Enter your app's name and the following Callback URL:"
            subtitle={
              <VStack pt="s-12">
                {/* using custom component here as `Tag` is only one line, and redirect_uri can be longer */}
                <Box
                  use="button"
                  onClick={e => {
                    e.preventDefault()
                    values.redirect_uri &&
                      copyToClipboard(values.redirect_uri).then(() => {
                        pushNotification({
                          value: 'Callback URL copied to clipboard',
                          duration: SUCCESS_DEFAULT_DURATION,
                          type: NotificationTypes.success,
                        })
                      })
                  }}
                >
                  <Grid
                    columns="auto 1fr"
                    bg={Token.color.black}
                    color={Token.color.white}
                    borderRadius="r16"
                    placeItems="center"
                    gap="s-4"
                    px="s-8"
                    py="s-6"
                  >
                    <Icon name="Copy" size={12} />
                    <Text
                      variant="emphasis4"
                      textAlign="start"
                      style={{ overflowWrap: 'anywhere' }}
                    >
                      {values.redirect_uri}
                    </Text>
                  </Grid>
                </Box>
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Click <Text fontWeight={700}>Create</Text>
              </Text>
            }
            subtitle="On the next screen, you'll see a Client ID and Client Secret have been generated."
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Copy <Text fontWeight={700}>Client ID</Text> and{' '}
                <Text fontWeight={700}>Client Secret</Text>
              </Text>
            }
            subtitle="Use Client ID and Client Secret in Revolut People to connect Linear"
          />
        </AutoStepper>
      </Widget>
      <Subheader style={{ paddingBottom: 0 }}>
        Where to enter? <Icon name="InfoOutline" color={Token.color.greyTone50} />
      </Subheader>
      <TravelWidget
        use="div"
        variant="large"
        image={[
          'https://assets.revolut.com/revoluters/assets/integrations/linear_integration_help_preview.png',
        ]}
      >
        <TravelWidget.Details
          use="button"
          onClick={() =>
            setLightBoxSrc({
              video:
                'https://assets.revolut.com/revoluters/assets/integrations/linear_integration_help.mp4',
              poster:
                'https://assets.revolut.com/revoluters/assets/integrations/linear_integration_help_preview.png',
            })
          }
        >
          <HStack align="center">
            <Text>Watch video</Text>
            <Spacer />
            <Icon name="ChevronRight" />
          </HStack>
        </TravelWidget.Details>
      </TravelWidget>
    </VStack>
  )
}
