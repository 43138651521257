import { RowInterface } from '@src/interfaces/data'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getEmployeeColumn,
  recurrenceColumn,
  relationColumn,
  getStatusColumn,
  timeColumn,
} from './columns'
import { oneToOneModel } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/models/oneToOneModel'

export const getRow = (
  currentUser: EmployeeInterface,
  employee: EmployeeInterface,
  canViewMeetings: boolean,
  onUnscheduledClick: (meeting: OneToOneMeeting) => void,
): RowInterface<OneToOneMeeting> => ({
  cells: [
    { ...getEmployeeColumn(employee), width: 200 },
    { ...relationColumn, width: 200 },
    { ...timeColumn, width: 200 },
    { ...recurrenceColumn, width: 100 },
    { ...getStatusColumn(onUnscheduledClick), width: 100 },
  ],
  disabled: (meeting: OneToOneMeeting) => {
    if (oneToOneModel.isMeetingParticipant(currentUser, meeting)) {
      return false
    }
    return !canViewMeetings
  },
})
