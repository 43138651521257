import React from 'react'
import { LabelProps } from 'recharts'
import { Token } from '@revolut/ui-kit'
import { CartesianViewBox } from 'recharts/types/util/types'

const TARGET_ICON_SIZE = 16

interface Props extends LabelProps {
  value: number
}

export const ReferenceLineLabel = ({ value, ...labelProps }: Props) => {
  const viewBox = (labelProps.viewBox || {}) as CartesianViewBox
  const x = Number(viewBox.x) + Number(viewBox.width)
  const y = Number(viewBox.y)

  return (
    <>
      <svg
        fontSize={12}
        width={TARGET_ICON_SIZE}
        height={TARGET_ICON_SIZE}
        viewBox={`0 0 ${TARGET_ICON_SIZE} ${TARGET_ICON_SIZE}`}
        x={x - TARGET_ICON_SIZE - 2}
        y={y - TARGET_ICON_SIZE - 2}
      >
        <path
          d="M8 12.9641C5.34903 12.9641 3.2 10.815 3.2 8.16406C3.2 5.5131 5.34903 3.36406 8 3.36406C10.651 3.36406 12.8 5.5131 12.8 8.16406C12.8 10.815 10.651 12.9641 8 12.9641ZM8 14.1641C11.3137 14.1641 14 11.4778 14 8.16406C14 4.85035 11.3137 2.16406 8 2.16406C4.68629 2.16406 2 4.85035 2 8.16406C2 11.4778 4.68629 14.1641 8 14.1641ZM8 10.5641C6.67452 10.5641 5.6 9.48955 5.6 8.16406C5.6 6.83858 6.67452 5.76406 8 5.76406C9.32548 5.76406 10.4 6.83858 10.4 8.16406C10.4 9.48955 9.32548 10.5641 8 10.5641ZM8 11.7641C9.98822 11.7641 11.6 10.1523 11.6 8.16406C11.6 6.17584 9.98822 4.56406 8 4.56406C6.01178 4.56406 4.4 6.17584 4.4 8.16406C4.4 10.1523 6.01178 11.7641 8 11.7641ZM9.2 8.16406C9.2 8.8268 8.66274 9.36406 8 9.36406C7.33726 9.36406 6.8 8.8268 6.8 8.16406C6.8 7.50132 7.33726 6.96406 8 6.96406C8.66274 6.96406 9.2 7.50132 9.2 8.16406Z"
          fill={Token.color.greyTone50}
        />
      </svg>
      <svg>
        <text pointerEvents="none">
          <tspan x={x} y={y} dy={-5.5} fill={Token.color.greyTone50} fontSize={12}>
            {value}
          </tspan>
        </text>
      </svg>
    </>
  )
}
