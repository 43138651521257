import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Flex, Box, Token } from '@revolut/ui-kit'

import MainHeader from '@src/features/MainHeader/MainHeader'
import ImportantKpiBanner from '@src/features/Banners/ImportantKpiBanner'
import { ROUTES } from '@src/constants/routes'
import Dashboard from '@src/pages/Home/HomeSubTabs/Dashboard/Dashboard'
import { SystemStatusBanner } from '@src/features/Banners/SystemStatusBanner'

const homeTabs = [
  {
    id: 'dashboard' as const,
    title: 'Dashboard',
    path: ROUTES.MAIN,
    url: ROUTES.MAIN,
    component: Dashboard,
  },
]

interface HomeLandingPageProps {
  sectionName: string
}

const HomeLandingPage = ({ sectionName }: HomeLandingPageProps) => {
  return (
    <>
      <Flex flexDirection="column">
        <MainHeader title={sectionName}>
          <Box maxWidth={Token.breakpoint.xl}>
            <SystemStatusBanner mb="s-8" />
            <ImportantKpiBanner disabled mb="s-8" />
          </Box>
        </MainHeader>
        <Switch>
          {homeTabs.map(tab => {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.path}
                render={() => {
                  document.title = `${sectionName} - ${tab.title}`
                  return <tab.component />
                }}
              />
            )
          })}
        </Switch>
      </Flex>
    </>
  )
}

export default HomeLandingPage
