import React, { useEffect, useMemo, useState } from 'react'
import {
  Flex,
  HStack,
  InputGroup,
  Text,
  Box,
  FilterButton,
  Widget,
} from '@revolut/ui-kit'
import { LikeDislike, SocialDislike, SocialLike } from '@revolut/icons'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import {
  AnytimeFeedbackInterface,
  AnytimeFeedbackRating,
  AnytimeFeedbackVisibility,
  AnytimeFeedbackVisibilityTitles,
  FeedbackCategory,
} from '@src/interfaces/anytimeFeedback'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import {
  getAnytimeFeedbackRequests,
  getAnytimeFeedbackLabels,
} from '@src/api/anytimeFeedback'
import { useParams } from 'react-router-dom'
import NewRadioButtons from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import Form from '@src/features/Form/Form'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { goBack } from '@src/actions/RouterActions'

const visibilityOptionsAnytime = [
  {
    value: {
      name: AnytimeFeedbackVisibility.EmployeeAndManagers,
      id: AnytimeFeedbackVisibility.EmployeeAndManagers,
    },
    label: AnytimeFeedbackVisibilityTitles[AnytimeFeedbackVisibility.EmployeeAndManagers],
    tooltip:
      'This feedback will be visible to the employee, their line and functional managers, their head of department and function, and HR managers.',
  },
  {
    value: {
      name: AnytimeFeedbackVisibility.ManagersOnly,
      id: AnytimeFeedbackVisibility.ManagersOnly,
    },
    label: AnytimeFeedbackVisibilityTitles[AnytimeFeedbackVisibility.ManagersOnly],
    tooltip:
      'This feedback will not be visible to the employee. Only their line and functional managers, their head of department and function, and HR managers will see it.',
  },
]
const getVisibilityOptionsOneToOne = (isSelfNote: boolean) => {
  const privateId = isSelfNote
    ? AnytimeFeedbackVisibility.EmployeeOnly
    : AnytimeFeedbackVisibility.Private
  return [
    {
      value: {
        name: AnytimeFeedbackVisibility.EmployeeAndManagers,
        id: AnytimeFeedbackVisibility.EmployeeAndManagers,
      },
      label: 'Shared',
      tooltip:
        'This feedback will be visible to the employee, their line and functional managers, their head of department and function, and HR managers.',
    },
    {
      value: {
        name: privateId,
        id: privateId,
      },
      label: 'Private',
      tooltip: 'This feedback will only be visible to you.',
    },
  ]
}

const bodyPrefillAnytime = `Strengths / What went well:


Areas for development / What could be better:

`
const bodyPrefillOneToOne = `Feedback:


Actions:

`

const General = connect(({ category }: { category: FeedbackCategory }) => {
  const { values } = useLapeContext<AnytimeFeedbackInterface>()
  const params = useParams<{ userId: string }>()
  const user = useSelector(selectUser)
  const [labels, setLabels] = useState<{ label: string; value: { id: string } }[]>()
  const bodyPrefill =
    category === FeedbackCategory.Anytime ? bodyPrefillAnytime : bodyPrefillOneToOne
  const disabled = values?.body === bodyPrefill || values?.body?.length === 0
  const visibilityOptions =
    category === FeedbackCategory.Anytime
      ? visibilityOptionsAnytime
      : getVisibilityOptionsOneToOne(params.userId === String(user.id))

  const ratings = [
    {
      name: AnytimeFeedbackRating.Superpower,
      text: 'Positive feedback',
      icon: SocialLike,
    },
    {
      name: AnytimeFeedbackRating.Performing,
      text: 'Balanced comments',
      icon: LikeDislike,
    },
    {
      name: AnytimeFeedbackRating.ImprovementArea,
      text: 'Areas for development',
      icon: SocialDislike,
    },
  ]

  const getLabels = async () => {
    const { data } = await getAnytimeFeedbackLabels(params.userId)
    const foundLabels = data.map((label: { id: string; name: string }) => ({
      label: label.name,
      value: { id: label.id },
    }))
    setLabels(foundLabels)
  }

  useEffect(() => {
    getLabels()
    if (!values.rating) {
      values.rating = AnytimeFeedbackRating.Superpower
    }
    if (!values.visibility) {
      values.visibility = AnytimeFeedbackVisibility.EmployeeAndManagers
    }
    if (!values.body) {
      values.body = bodyPrefill
    }
  }, [])

  const visibilityValue = useMemo(() => {
    return visibilityOptions.find(item => item.value.id === values.visibility)?.value
  }, [values.visibility])

  return (
    <>
      <PageBody>
        <Widget p="s-16" mb="s-16">
          <Text use="p" color="grey-tone-50" mb="s-8">
            How would you rate your feedback?
          </Text>
          <HStack space="s-8" mb="s-24">
            {ratings.map(rating => (
              <FilterButton
                key={rating.name}
                useIcon={rating.icon}
                active={values.rating === rating.name}
                onClick={() => {
                  values.rating = rating.name
                }}
              >
                {rating.text}
              </FilterButton>
            ))}
          </HStack>
          <InputGroup>
            <LapeNewTextArea required name="body" label="Description" />
            <LapeNewMultiSelect<{ id: string }>
              name="labels"
              onChange={options => {
                values.labels = options.map(option => option.value.id)
              }}
              options={labels}
              placeholder="Feedback labels"
              value={
                values.labels?.map(label => ({
                  label,
                  value: { id: label },
                })) || []
              }
            />
          </InputGroup>
        </Widget>
        <Flex justifyContent="space-between" alignItems="center">
          <Text mr="s-24" color="grey-tone-50">
            Visibility:
          </Text>
          <Box width="90%" flex={1}>
            <NewRadioButtons
              options={visibilityOptions}
              onChange={option => {
                values.visibility = option.value.id
              }}
              variant="cell"
              value={visibilityValue}
              defaultOptionIndex={1}
            />
          </Box>
        </Flex>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          disabled={disabled}
          successText={
            values.id
              ? 'Feedback was successfully updated'
              : 'Feedback was successfully created'
          }
          onAfterSubmit={() => {
            goBack(
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
                id: params.userId,
              }),
            )
          }}
          tooltipText={
            disabled
              ? 'You cannot submit feedback without completing the description.'
              : undefined
          }
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
})

export default connect(({ category }: { category: FeedbackCategory }) => (
  <Form
    api={getAnytimeFeedbackRequests(category)}
    disableDataCleanup
    disableLocalStorageCaching
  >
    <General category={category} />
  </Form>
))
