import React from 'react'
import { Props } from 'recharts/types/component/DefaultLegendContent'
import { Box, Flex, Text, Tooltip, Token, useTooltip } from '@revolut/ui-kit'
import { formatMoney } from '@src/utils/format'

interface PieLegendProps extends Props {
  currency?: string
  legendTooltipData?: {
    key: string
    tooltip: JSX.Element
  }[]
}

interface LegendItemProps {
  color?: string
  currency?: string
  tooltip?: JSX.Element
  name: string
  value?: any
}

export const LegendItem = ({
  color,
  currency,
  tooltip,
  name,
  value,
}: LegendItemProps) => {
  const legendTooltip = useTooltip()

  return (
    <Flex flexDirection="column" gap="s-4" {...legendTooltip.getAnchorProps()}>
      <Box
        border={`1px solid ${Token.color.greyTone10}`}
        borderRadius={Token.radius.r2}
        bg={color}
        height="s-12"
        width="s-12"
      />
      <Text color={Token.color.greyTone50} fontSize="small">
        {name}
      </Text>
      <Text color={Token.color.greyTone50} fontSize="small">
        {formatMoney(value, currency)}
      </Text>
      {tooltip && (
        <Tooltip {...legendTooltip.getTargetProps()} maxWidth={350}>
          {tooltip}
        </Tooltip>
      )}
    </Flex>
  )
}

export const PieLegend = (props: PieLegendProps) => {
  const { currency, legendTooltipData, payload } = props

  return (
    <Flex gap="s-16" justifyContent="center" pt="s-8" px="s-16">
      {payload?.map(entry => {
        const tooltipData = legendTooltipData?.find(data => data.key === entry.value)

        return (
          <LegendItem
            key={String(entry.value)}
            color={entry.color}
            currency={currency}
            name={entry.value}
            tooltip={tooltipData?.tooltip}
            value={entry.payload?.value}
          />
        )
      })}
    </Flex>
  )
}
