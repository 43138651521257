import React, { useState } from 'react'
import {
  Header,
  Layout,
  Image,
  Flex,
  Button,
  Subheader,
  VStack,
  StatusWidget,
  Group,
  DetailsCell,
  DetailsSkeleton,
  ErrorWidget,
  TextButton,
  HStack,
  Text,
  TransitionCollapse,
  ActionButton,
  Grid,
  Icon,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { useQueryClient } from 'react-query'

import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { PaymentDetails } from '@src/pages/Forms/Plans/PaymentDetails'
import { useRetryPaymentFlow } from './useRetryPaymentFlow'
import { downloadInvoice, unrestrictSubscription, useLatestInvoice } from '@src/api/plans'
import { UseFetchResult } from '@src/interfaces'
import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import {
  InvoiceEmployeesPreview,
  InvoiceSummary,
  InvoiceTotal,
} from '@src/pages/Forms/Plans/common'
import { saveFile } from '@src/utils'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { OrderAndConfig } from '@src/pages/Forms/Plans/SubscriptionPlanProvider'
import { CheckoutWidget } from '@src/pages/Forms/Plans/CheckoutWidget'
import { API } from '@src/constants/api'

const UnrestrictAccountButton = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const [unrestrictAccountPending, setUnrestrictAccountPending] = useState(false)

  const subscriptionPlansDevtoolsEnabled = featureFlags.includes(
    FeatureFlags.SubscriptionPlansDevtools,
  )

  const onUnrestrictAccount = () => {
    setUnrestrictAccountPending(true)

    unrestrictSubscription()
      .then(() => {
        window.location.reload()
      })
      .finally(() => setUnrestrictAccountPending(false))
  }

  if (!subscriptionPlansDevtoolsEnabled) {
    return null
  }

  return (
    <ActionButton onClick={onUnrestrictAccount} pending={unrestrictAccountPending}>
      Unrestrict account
    </ActionButton>
  )
}

export const AccountRestricted = () => {
  const [paymentDetailsViewVisible, setPaymentDetailsViewVisible] = useState(false)
  const [orderAndConfig, setOrderAndConfig] = useState<OrderAndConfig>()

  const queryClient = useQueryClient()
  const { onRetryPayment } = useRetryPaymentFlow({
    onReviewPaymentDetails: () => setPaymentDetailsViewVisible(true),
    onSuccessPopupClose: () => {
      window.location.reload()
    },
  })
  const invoiceData = useLatestInvoice(paymentDetailsViewVisible)

  const permissions = useSelector(selectPermissions)

  const resetState = () => {
    queryClient.invalidateQueries(API.SUBSCRIPTIONS_INFO_API)
    setOrderAndConfig(undefined)
  }

  const canManageSubscriptionPlans = permissions.includes(
    PermissionTypes.ManageSubscriptionPlans,
  )
  const title = canManageSubscriptionPlans
    ? 'Your account is restricted'
    : 'Temporary account restriction'
  const description = canManageSubscriptionPlans
    ? `We couldn't process your last payment. Retry your payment or update payment details to unlock your account.`
    : `Your company's account has been temporary restricted. Please contact your system admin for more information`

  const isOpenInvoice = invoiceData.data?.status?.id === 'open'
  const showRetryPayment = !!invoiceData.data && isOpenInvoice

  return paymentDetailsViewVisible ? (
    <Layout>
      <Layout.Main>
        <Header variant="item">
          <Header.CloseButton
            onClick={() => setPaymentDetailsViewVisible(false)}
            aria-label="Close"
          />
          <Header.Title>Invoice Details</Header.Title>
        </Header>

        {orderAndConfig ? (
          <CheckoutWidget
            orderAndConfig={orderAndConfig}
            onRetryPayment={onRetryPayment}
            onSuccessPopupClose={resetState}
          >
            <PageBody>
              <CheckoutWidget.Details />
              <InvoiceDetails invoice={invoiceData} />
            </PageBody>

            <PageActions>
              <Grid columns={2} gap="s-8" maxWidth={375}>
                <Button variant="secondary" onClick={resetState}>
                  Cancel
                </Button>
                <CheckoutWidget.Actions />
              </Grid>
            </PageActions>
          </CheckoutWidget>
        ) : (
          <>
            <PageBody>
              <PaymentDetails onSetOrderAndConfig={setOrderAndConfig} />
              <InvoiceDetails invoice={invoiceData} />
            </PageBody>

            {showRetryPayment ? (
              <PageActions>
                <Button
                  onClick={() => {
                    if (invoiceData.data?.id) {
                      onRetryPayment(invoiceData.data.id)
                    }
                  }}
                  variant="secondary"
                >
                  Retry payment
                </Button>
              </PageActions>
            ) : null}
          </>
        )}
      </Layout.Main>
    </Layout>
  ) : (
    <Layout>
      <Layout.Main>
        <Header variant="item">
          <Header.Title>{title}</Header.Title>
          <Header.Description>{description}</Header.Description>
        </Header>

        <Flex justifyContent="center">
          <Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D020.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D020@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D020@3x.png',
            }}
            maxWidth={300}
          />
        </Flex>
      </Layout.Main>
      {canManageSubscriptionPlans ? (
        <Layout.Actions>
          <Button onClick={() => setPaymentDetailsViewVisible(true)} variant="secondary">
            View invoice
          </Button>
          <UnrestrictAccountButton />
        </Layout.Actions>
      ) : null}
    </Layout>
  )
}

interface InvoiceDetailsProps {
  invoice: UseFetchResult<SubscriptionInvoiceInterface | null, AxiosError>
}

const InvoiceDetails = ({ invoice }: InvoiceDetailsProps) => {
  const [invoiceDownloadPending, setInvoiceDownloadPending] = useState(false)
  const [showMoreOpen, setShowMoreOpen] = useState(false)

  const data = invoice.data
  const noInvoiceFound = data?.status?.id !== 'open' || data === null

  if (invoice.isLoading || !data) {
    return (
      <WithInvoiceSubheader>
        <Group>
          <DetailsCell>
            <DetailsCell.Title>Active employees</DetailsCell.Title>
            <DetailsSkeleton.Content />
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>New active employees</DetailsCell.Title>
            <DetailsSkeleton.Content />
          </DetailsCell>
        </Group>
      </WithInvoiceSubheader>
    )
  }

  if (noInvoiceFound) {
    return (
      <WithInvoiceSubheader>
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
              '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
            }}
          />
          <StatusWidget.Title>No open invoice found</StatusWidget.Title>
        </StatusWidget>
      </WithInvoiceSubheader>
    )
  }

  if (invoice.error) {
    return (
      <WithInvoiceSubheader>
        <ErrorWidget>
          <ErrorWidget.Image />
          <ErrorWidget.Title />
          <ErrorWidget.Description>
            {getStringMessageFromError(invoice.error)}
          </ErrorWidget.Description>
        </ErrorWidget>
      </WithInvoiceSubheader>
    )
  }

  return (
    <WithInvoiceSubheader
      downloadContents={
        <TextButton
          onClick={() => {
            setInvoiceDownloadPending(true)
            downloadInvoice(data.id)
              .then(response => {
                saveFile(response.file, response.fileName)
              })
              .finally(() => {
                setInvoiceDownloadPending(false)
              })
          }}
          disabled={invoiceDownloadPending}
        >
          Download invoice
        </TextButton>
      }
    >
      <InvoiceEmployeesPreview invoice={data} />
      <InvoiceTotal invoice={data} />

      <Flex justifyContent="center">
        <TextButton onClick={() => setShowMoreOpen(!showMoreOpen)}>
          <HStack align="center" space="s-8">
            {showMoreOpen ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
            <Text>Show {showMoreOpen ? 'less' : 'more'}</Text>
          </HStack>
        </TextButton>
      </Flex>

      <TransitionCollapse in={showMoreOpen}>
        <InvoiceSummary invoice={data} />
      </TransitionCollapse>
    </WithInvoiceSubheader>
  )
}

interface WithInvoiceSubheaderProps {
  downloadContents?: React.ReactNode
}

const WithInvoiceSubheader: React.FC<WithInvoiceSubheaderProps> = ({
  children,
  downloadContents,
}) => (
  <VStack space="s-16" mt="s-16">
    <Subheader>
      <Subheader.Title>Invoice</Subheader.Title>
      <Subheader.Side>{downloadContents}</Subheader.Side>
    </Subheader>

    {children}
  </VStack>
)
