import React, { useState } from 'react'
import { chain, FilterButton as FilterButtonComponent } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { RangeValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'
import { format, isSameDay } from 'date-fns'
import { DynamicSlotsDateFilterPopup } from '@src/pages/Forms/Candidate/ScheduleSidebar/DynamicSlotsDateFilterPopup'

interface Props {
  selectedWeekOption?: IdAndName
  customDateRange: RangeValue
  onChangeCustomDateRange: (dateRange: RangeValue) => void
  onChangeWeekOption: (selectedWeekOption: IdAndName) => void
  timeZoneId: string
}

export const DynamicSlotsDateFilter = ({
  selectedWeekOption,
  customDateRange,
  onChangeCustomDateRange,
  onChangeWeekOption,
  timeZoneId,
}: Props) => {
  const [open, setOpen] = useState(false)

  const getLabel = () => {
    if (selectedWeekOption?.name) {
      return selectedWeekOption.name
    }

    if (!customDateRange?.from || !customDateRange?.to) {
      return null
    }

    const startDate = new Date(customDateRange.from)
    const endDate = new Date(customDateRange.to)

    if (isSameDay(startDate, endDate)) {
      return format(startDate, 'd MMM')
    }

    return `${format(startDate, 'd MMM')} - ${format(endDate, 'd MMM')}`
  }

  return (
    <>
      <FilterButtonComponent
        useIcon="Filter"
        onClick={() => setOpen(true)}
        active={!!(selectedWeekOption || customDateRange)}
        aria-haspopup="listbox"
      >
        {chain('Dates', getLabel())}
      </FilterButtonComponent>

      <DynamicSlotsDateFilterPopup
        open={open}
        onClose={() => setOpen(false)}
        title="Specify date range"
        selectedWeekOption={selectedWeekOption}
        customDateRange={customDateRange}
        onChangeWeekOption={onChangeWeekOption}
        onChangeCustomDateRange={onChangeCustomDateRange}
        timeZoneId={timeZoneId}
      />
    </>
  )
}
