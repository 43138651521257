import React, { useCallback, useState } from 'react'
import {
  BottomSheet,
  Button,
  DragAndDrop,
  Flex,
  Group,
  Header,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Item,
  ItemSkeleton,
  Side,
  TextArea,
  Token,
} from '@revolut/ui-kit'

import SideBar from '@src/components/SideBar/SideBar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  OrganisationStructureTab,
  OrganisationStructure,
} from '@src/pages/Settings/Structures/types'
import { move } from '@src/utils/move'

interface StructureTabsSidebarProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const StructureTabsSidebar = ({ isOpen, onClose }: StructureTabsSidebarProps) => {
  const { values } = useLapeContext<OrganisationStructure>()

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title="Manage page tabs"
      subtitle="Define the default layout of pages of this structure"
    >
      {values.default_layout ? (
        <StructureTabsSidebarLoaded onClose={onClose} />
      ) : (
        <Group>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </Group>
      )}
    </SideBar>
  )
}

interface StructureTabsSidebarLoadedProps {
  onClose: VoidFunction
}

const StructureTabsSidebarLoaded = ({ onClose }: StructureTabsSidebarLoadedProps) => {
  const { values } = useLapeContext<OrganisationStructure>()

  const [activeId, setActiveId] = useState<number | null>(null)
  const [sidebarState, setSidebarState] = useState(() => {
    return {
      visible: values.default_layout.filter(tab => tab.state === 'enabled'),
      hidden: values.default_layout.filter(tab => tab.state === 'disabled'),
    }
  })
  const [editTab, setEditTab] = useState<number | null>(null)

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      setSidebarState(prev => ({
        ...prev,
        visible:
          startIndex === endIndex
            ? prev.visible
            : move(prev.visible, startIndex, endIndex),
      }))
    }

    setActiveId(null)
  }, [])

  const activeItem = activeId && sidebarState.visible.find(item => item.id === activeId)

  const onRemove = (tab: OrganisationStructureTab) => {
    setSidebarState(prev => ({
      visible: prev.visible.filter(t => t.id !== tab.id),
      hidden: [...prev.hidden, tab],
    }))
  }

  const onAdd = (tab: OrganisationStructureTab) => {
    setSidebarState(prev => ({
      visible: [...prev.visible, tab],
      hidden: prev.hidden.filter(t => t.id !== tab.id),
    }))
  }

  const onSave = () => {
    values.default_layout = [
      ...sidebarState.visible.map(tab => ({ ...tab, state: 'enabled' as const })),
      ...sidebarState.hidden.map(tab => ({ ...tab, state: 'disabled' as const })),
    ]
    onClose()
  }

  return (
    <>
      <Flex flexDirection="column" gap="s-16">
        <DragAndDrop.Provider
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <Group>
            <DragAndDrop.Sortable
              id="sortable-table-preferences"
              items={sidebarState.visible}
            >
              {sortable => {
                const tab = sidebarState.visible.find(item => item.id === sortable.id)
                return tab ? (
                  <Item
                    ref={sortable.setNodeRef}
                    style={{
                      transform: sortable.transform
                        ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                        : undefined,
                      transition: sortable.transition || 'none',
                      opacity: sortable.isDragging ? 0 : undefined,
                    }}
                  >
                    <Item.Prefix>
                      <IconButton
                        aria-label={`Remove ${tab.name} tab`}
                        useIcon="MinusCircle"
                        color={Token.color.red}
                        onClick={() => onRemove(tab)}
                        size={16}
                      />
                    </Item.Prefix>
                    <Item.Content>
                      <Item.Title>{tab.name}</Item.Title>
                      <Item.Description>{tab.description}</Item.Description>
                    </Item.Content>
                    <Item.Side>
                      <HStack space="s-16">
                        <IconButton
                          onClick={() => setEditTab(tab.id)}
                          useIcon="Pencil"
                          color={Token.color.blue}
                          size={16}
                          aria-label={`Edit ${tab.name} tab`}
                        />
                        <IconButton
                          aria-label="Drag"
                          useIcon="Drag"
                          color={Token.color.greyTone20}
                          {...sortable.attributes}
                          {...sortable.listeners}
                          size={16}
                        />
                      </HStack>
                    </Item.Side>
                  </Item>
                ) : null
              }}
            </DragAndDrop.Sortable>
          </Group>
          <DragAndDrop.DragOverlay>
            {activeItem && (
              <Item>
                <Item.Prefix>
                  <IconButton
                    useIcon="MinusCircle"
                    color={Token.color.red}
                    onClick={() => {}}
                    size={16}
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>{activeItem.name}</Item.Title>
                  <Item.Description>{activeItem.description}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <HStack space="s-16">
                    <IconButton
                      onClick={() => {}}
                      useIcon="Pencil"
                      color={Token.color.blue}
                      size={16}
                    />
                    <IconButton
                      useIcon="Drag"
                      color={Token.color.greyTone20}
                      onClick={() => {}}
                      size={16}
                    />
                  </HStack>
                </Item.Side>
              </Item>
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>

        <Group>
          {sidebarState.hidden.map(tab => {
            return (
              <Item key={tab.id}>
                <Item.Prefix>
                  <IconButton
                    aria-label={`Add ${tab.name} tab`}
                    useIcon="PlusCircle"
                    color={Token.color.blue}
                    onClick={() => onAdd(tab)}
                    size={16}
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>{tab.name}</Item.Title>
                  <Item.Description>{tab.description}</Item.Description>
                </Item.Content>
              </Item>
            )
          })}
        </Group>
      </Flex>

      <Side.Actions>
        <Button elevated onClick={onSave}>
          Confirm
        </Button>
      </Side.Actions>

      <EditTabPopup
        open={!!editTab}
        onClose={() => setEditTab(null)}
        values={sidebarState.visible.find(f => f.id === editTab)}
        onSave={tabState => {
          setSidebarState(prev => ({
            ...prev,
            visible: prev.visible.map(tab => (tab.id === tabState.id ? tabState : tab)),
          }))
          setEditTab(null)
        }}
        key={editTab}
      />
    </>
  )
}

interface EditTabPopupProps {
  open: boolean
  onClose: VoidFunction
  values?: OrganisationStructureTab
  onSave: (values: OrganisationStructureTab) => void
}

const EditTabPopup = ({ open, onClose, onSave, values }: EditTabPopupProps) => {
  const [state, setState] = useState(values)

  if (!state || !values) {
    return null
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Edit</Header.Title>
        <Header.Description>
          Updates the title of the tab being displayed on every page
        </Header.Description>
      </Header>

      <InputGroup>
        <Input
          label="Tab name"
          value={state.name}
          onChange={e => {
            if (state) {
              setState({ ...state, name: e.currentTarget.value })
            }
          }}
        />
        <TextArea
          label="Description"
          value={state.description}
          onChange={e => {
            if (state) {
              setState({ ...state, description: e.currentTarget.value })
            }
          }}
        />
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSave(state)}>Confirm</Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
