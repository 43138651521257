import React from 'react'
import {
  Absolute,
  Avatar,
  Box,
  Group,
  HStack,
  Icon,
  IconName,
  Item,
  Relative,
  Token,
} from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface StructureLevelsProps {
  levels: (SturctureLevelProps & { id: string })[]
}

export const StructureLevels = ({ levels }: StructureLevelsProps) => {
  return (
    <Group>
      {levels.map((level, index) => (
        <HStack key={level.id}>
          {index > 0 ? (
            <Relative width={40 * index}>
              <Absolute right={-6} top={22}>
                <Icon name="ArrowHookUp" color={Token.color.greyTone20} size={16} />
              </Absolute>
            </Relative>
          ) : null}
          <SturctureLevel {...level} />
        </HStack>
      ))}
    </Group>
  )
}

interface SturctureLevelProps {
  url: string
  icon: IconName
  title: React.ReactNode
  description: React.ReactNode
}

export const SturctureLevel = ({
  url,
  icon,
  title,
  description,
}: SturctureLevelProps) => {
  return (
    <Box width="100%">
      <Item use={InternalLink} to={url} variant="disclosure">
        <Item.Avatar>
          <Avatar useIcon={icon} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>{description}</Item.Description>
        </Item.Content>
      </Item>
    </Box>
  )
}
