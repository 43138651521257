import React, { useEffect, useState } from 'react'
import { GenericError } from '@src/features/Form/FormErrorGuard'
import { HiringStagesListSkeleton } from '@src/features/JobPostingFlow/HiringProcess/HiringStagesListSkeleton'
import { HiringStage } from '@src/features/JobPostingFlow/HiringProcess/HiringStage'
import { HiringStageSidebar } from '@src/features/JobPostingFlow/HiringProcess/HiringStageSidebar'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { ActionButton, DragAndDrop } from '@revolut/ui-kit'
import { HiringStageFormSidebar } from '@src/features/JobPostingFlow/HiringProcess/HiringStageFormSidebar'
import { useSidebar } from '@src/hooks/useSidebar'
import { HiringStagesListSidebars } from '@src/features/JobPostingFlow/types'
import {
  reorderJobPostingHiringProcess,
  useGetJobPostingHiringProcess,
} from '@src/api/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { move } from '@src/utils/move'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface HiringStagesListProps {
  isHiringProcessLocked: boolean
}

export const HiringStagesList = ({ isHiringProcessLocked }: HiringStagesListProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [activeStage, setActiveStage] = useState<HiringProcessInterface | null>(null)
  const { sidebar, closeSidebar, toggleSidebar } = useSidebar<HiringStagesListSidebars>()
  const [activeDragId, setActiveDragId] = useState<string | number | null>(null)
  const showStatusPopup = useShowStatusPopup()
  const { data, isLoading, refetch } = useGetJobPostingHiringProcess(values.id)
  const [hiringProcessStages, setHiringProcessStages] = useState<
    HiringProcessInterface[]
  >([])

  useEffect(() => {
    if (data?.results) {
      setHiringProcessStages(data.results)
    }
  }, [data])

  if (isLoading) {
    return <HiringStagesListSkeleton />
  }

  if (!data) {
    return <GenericError />
  }

  const activeDragStage =
    activeDragId && hiringProcessStages.find(({ id }) => String(id) === activeDragId)

  const handleOrderChange = async (startIndex: number, endIndex: number) => {
    const oldOrder = [...hiringProcessStages]
    const newOrder = move(hiringProcessStages, startIndex, endIndex)
    setHiringProcessStages(newOrder)
    try {
      showStatusPopup({
        preventUserClose: true,
        status: 'pending',
        title: 'Updating hiring stages order',
      })
      await reorderJobPostingHiringProcess(
        values.id,
        newOrder.map(({ id }) => ({
          id,
        })),
      )
      showStatusPopup({
        title: 'Updated hiring stages order',
      })
    } catch (e) {
      setHiringProcessStages(oldOrder)
      showStatusPopup({
        status: 'error',
        title: 'There was a error reordering hiring stages',
        description: getMessageFromApiError(e),
      })
    }
  }

  return (
    <>
      {activeStage && sidebar === 'stageDetails' && (
        <HiringStageSidebar stage={activeStage} onClose={() => toggleSidebar(sidebar)} />
      )}

      {sidebar === 'stageForm' && (
        <HiringStageFormSidebar
          stage={activeStage}
          onClose={() => toggleSidebar('stageForm')}
          onAfterSubmit={() => {
            setActiveStage(null)
            closeSidebar()
            refetch()
          }}
        />
      )}

      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveDragId(event.active.id)
        }}
        onDragEnd={event => {
          if (event.over) {
            handleOrderChange(
              event?.active?.data?.current?.sortable.index ?? 0,
              event?.over?.data?.current?.sortable.index ?? 0,
            )
          }
        }}
        onDragCancel={() => {
          setActiveDragId(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={hiringProcessStages.map(({ id }) => String(id))}
        >
          {sortable => {
            const stage = hiringProcessStages.find(({ id }) => String(id) === sortable.id)
            if (!stage) {
              return null
            }
            return (
              <HiringStage
                disabled={sidebar === 'stageForm'}
                isHiringProcessLocked={isHiringProcessLocked}
                sortable={sortable}
                stage={stage}
                onClick={() => {
                  if (stage.id === activeStage?.id) {
                    setActiveStage(null)
                    closeSidebar()
                  } else {
                    setActiveStage(stage)
                  }
                  if (sidebar !== 'stageDetails') {
                    toggleSidebar('stageDetails')
                  }
                }}
                onEdit={() => {
                  setActiveStage(stage)
                  toggleSidebar('stageForm')
                }}
                onAfterDelete={() => {
                  refetch()
                }}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeDragStage && (
            <HiringStage
              key={activeDragStage.id}
              isHiringProcessLocked={isHiringProcessLocked}
              stage={activeDragStage}
              onAfterDelete={() => {}}
              onClick={() => {}}
              onEdit={() => {}}
              disabled
            />
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>

      <ActionButton
        useIcon="Plus"
        disabled={sidebar === 'stageForm'}
        onClick={() => {
          setActiveStage(null)
          toggleSidebar('stageForm')
        }}
      >
        Add stage
      </ActionButton>
    </>
  )
}
