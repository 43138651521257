import React from 'react'
import Form from '@src/features/Form/Form'
import { formHiringProcessStagesRequest } from '@src/api/hiringProcess'
import { connect } from 'lape'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import HiringStageSidebarPreview from '@src/features/HiringStageSidebarPreview/HiringStageSidebarPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { updateStagesAfterDelete } from '@src/utils/hiring'
import { HiringProcessSettingsInterface } from '@src/interfaces/settings'

interface Props {
  id?: number
  onClose: VoidFunction
}

const HiringStagePreviewForm = ({ id, onClose }: Props) => {
  const context = useLapeContext<HiringProcessSettingsInterface>()

  if (!id) {
    return null
  }

  const onSuccessDelete = () => {
    updateStagesAfterDelete<HiringProcessSettingsInterface>(context, id)
    onClose()
  }

  return (
    <Form<HiringProcessInterface>
      api={formHiringProcessStagesRequest}
      forceParams={{ id: String(id) }}
      disableLoading
    >
      <HiringStageSidebarPreview
        type="default"
        onSuccessDelete={onSuccessDelete}
        onClose={onClose}
      />
    </Form>
  )
}

export default connect(HiringStagePreviewForm)
