import { useFetch, usePostV2 } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { AnnouncementInterface } from '@src/interfaces/announcements'
import { AxiosPromise } from 'axios'
import { api } from '@src/api/index'
import { useQueryClient } from 'react-query'

export const useGetUnreadAnnouncements = () => {
  const context = useFetch<{ results: AnnouncementInterface[] }>(
    API.ANNOUNCEMENTS,
    'v1',
    undefined,
    undefined,
  )
  return { ...context, data: context.data?.results }
}

export const markAnnouncementAsRead = (id: number): AxiosPromise<AnnouncementInterface> =>
  api.post(`${API.ANNOUNCEMENTS}/${id}/read`)

export const useDismissAnnouncement = (id: number) => {
  const queryClient = useQueryClient()

  return usePostV2<undefined, AnnouncementInterface, void>({
    url: `${API.ANNOUNCEMENTS}/${id}/dismiss`,
    handleSuccess: _data => {
      queryClient.setQueryData<{ results: AnnouncementInterface[] }>(
        [API.ANNOUNCEMENTS, 'v1', null],
        oldData => {
          const newResults = oldData?.results.filter(f => f.id !== id) || []
          return {
            ...oldData,
            results: newResults,
          }
        },
      )
    },
  })
}
