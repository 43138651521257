import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Banner, ProgressStep, ProgressSteps, Token } from '@revolut/ui-kit'
import {
  FinalGrade,
  FinalGradeInterface,
  PerformanceSelector,
  ReviewCategory,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getTimelineStepDescription,
  getTimelineStepState,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { useCanViewMeetingsTab } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'

import { PerfomanceStepDetails } from './PerfomanceStepDetails'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useFetchPerformanceSummary } from '../../Preview/Performance/Summary/hooks'
import { useGetGoalsWithContentTypeCheck } from '@src/api/goals'
import { useGetGoalsFilters } from './useGetGoalsFilters'
import { isAfter, isBefore, isPast } from 'date-fns'
import { PerformanceReviewers } from './components/PerformanceReviewers'
import { PerformanceActionButton } from './components/PerformanceActionButton'

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  finalGrade?: FinalGradeInterface
  performanceSelectorData?: PerformanceSelector[]
  setCycleTimelineEvents?: (events: SingleTimelineEventInterface[]) => void
  eventsData: SingleTimelineEventInterface[]
  isLoading?: boolean
}

const resultsTitle = {
  managers: 'Results published to managers',
  employees: 'Results published to employees',
} as const

export const PerformanceTimeline = ({
  data,
  selectedPeriod,
  finalGrade,
  performanceSelectorData,
  setCycleTimelineEvents,
  eventsData,
  isLoading,
}: Props) => {
  const currentCycleReviewsPublishingDay = performanceSelectorData?.find(
    ({ id }) => id === selectedPeriod.id,
  )?.reviews_publishing_day

  const { data: settings } = useGetPerformanceSettings()

  const [selectedStepId, setSelectedStepId] = useState<string>()

  const { data: summaryData, isLoading: summaryLoading } = useFetchPerformanceSummary(
    selectedPeriod?.id !== undefined ? String(selectedPeriod?.id) : undefined,
    data.id,
    ReviewCategory.Performance,
  )

  const { employeeGoalsFilters, teamGoalsFilters } = useGetGoalsFilters(
    selectedPeriod,
    data,
    summaryData?.team,
  )

  const { data: employeeGoals, isLoading: isEmployeeGoalsLoading } =
    useGetGoalsWithContentTypeCheck({
      filters: employeeGoalsFilters,
    })

  const { data: teamGoals, isLoading: isTeamGoalsLoading } =
    useGetGoalsWithContentTypeCheck({
      filters: teamGoalsFilters,
    })

  const goalsCount = (teamGoals?.count || 0) + (employeeGoals?.count || 0)

  const canViewMeetings = useCanViewMeetingsTab(data)
  const user = useSelector(selectUser)
  const isManager =
    user.id === data.quality_control?.id || user.id === data.line_manager?.id

  useEffect(() => {
    if (setCycleTimelineEvents) {
      setCycleTimelineEvents(eventsData || [])
    }
  }, [eventsData])

  const timelineEvents = Array.isArray(eventsData) ? eventsData : []

  const initialAccumulator = {
    timelineEventsWithoutResults: [] as SingleTimelineEventInterface[],
    lastStepData: [] as SingleTimelineEventInterface[],
    timeLineEventsMap: new Map() as Map<
      SingleTimelineEventInterface['title'],
      SingleTimelineEventInterface
    >,
  }

  const { timelineEventsWithoutResults, lastStepData, timeLineEventsMap } =
    useMemo(() => {
      if (!timelineEvents) {
        return initialAccumulator
      }

      return timelineEvents.reduce<typeof initialAccumulator>((acc, curr) => {
        acc.timeLineEventsMap.set(curr.title, curr)

        if (curr.category === 'review_results') {
          acc.lastStepData.push(curr)
        } else {
          acc.timelineEventsWithoutResults.push(curr)
        }
        return acc
      }, initialAccumulator)
    }, [timelineEvents])

  const getLastStepData = (): {
    state: 'pending' | 'done' | 'default'
    description: JSX.Element | null
  } => {
    const title = isManager ? resultsTitle.managers : resultsTitle.employees

    const publishedResults = timelineEvents.find(
      ({ title: eventTitle }) => eventTitle === title,
    )

    if (publishedResults) {
      return {
        state: getTimelineStepState(
          publishedResults.start_period,
          publishedResults.end_period,
          publishedResults.category,
        ),
        description: getTimelineStepDescription(publishedResults),
      }
    }

    return { state: 'default', description: null }
  }

  const canChangeGradePublishing = useMemo(() => {
    if (data?.line_manager?.id !== user.id) {
      // only LM can publish / unpublish grades prematurely
      return false
    }
    // they have the same category and type so we have to rely on title
    const managerPublishDate = lastStepData.find(
      ({ title }) => title === resultsTitle.managers,
    )?.start_period
    const employeePublishDate = lastStepData.find(
      ({ title }) => title === resultsTitle.employees,
    )?.start_period

    if (
      !managerPublishDate ||
      !employeePublishDate ||
      !finalGrade ||
      finalGrade.id === FinalGrade.Poor
    ) {
      return false
    }

    if (
      isAfter(new Date(), new Date(managerPublishDate)) &&
      isBefore(new Date(managerPublishDate), new Date(employeePublishDate))
    ) {
      return true
    }
    return false
  }, [lastStepData, user?.id, data?.line_manager?.id, finalGrade])

  const { state: lastStepState, description: lastStepDescription } = getLastStepData()

  const pendingTimeLineStepData = useMemo(() => {
    const targetEvent = timelineEvents?.filter(event => {
      const state = getTimelineStepState(
        event.start_period,
        event.end_period,
        event.category,
      )
      return state === 'pending'
    })[0]
    return { type: targetEvent?.category, event: targetEvent }
  }, [timelineEvents])

  useEffect(() => {
    setSelectedStepId(undefined)

    if (lastStepState === 'pending') {
      setSelectedStepId('Grade published')
    }

    if (pendingTimeLineStepData.event) {
      setSelectedStepId(pendingTimeLineStepData.event.title)
    }
  }, [pendingTimeLineStepData])

  const activeTimeLineStep = useMemo(() => {
    if (selectedStepId === 'Grade published') {
      return { type: lastStepData[0]?.category, event: undefined }
    }

    if (selectedStepId) {
      const event = timeLineEventsMap.get(selectedStepId)
      return { type: event?.category, event }
    }

    return pendingTimeLineStepData
  }, [selectedStepId, pendingTimeLineStepData])

  if (isLoading || summaryLoading || isEmployeeGoalsLoading || isTeamGoalsLoading) {
    return <SectionLoader />
  }

  if (!timelineEvents) {
    return null
  }

  const events = timelineEventsWithoutResults
  const isEmptyReviewers =
    !activeTimeLineStep.event?.reviews?.length && activeTimeLineStep.event?.category
      ? !['kpi', 'goals'].includes(activeTimeLineStep.event.category)
      : false

  const getIsClosedEvent = () => {
    if (selectedStepId === 'Grade published') {
      return currentCycleReviewsPublishingDay
        ? isPast(new Date(currentCycleReviewsPublishingDay))
        : undefined
    }
    return activeTimeLineStep?.event?.end_period
      ? isPast(new Date(activeTimeLineStep?.event?.end_period))
      : undefined
  }

  return (
    <>
      <ProgressSteps>
        {events.length > 0 &&
          events.map((event, i) => {
            if (event.category === 'meeting' && !canViewMeetings) {
              return null
            }

            const state = getTimelineStepState(
              event.start_period,
              event.end_period,
              event.category,
            )
            const description = getTimelineStepDescription(
              event,
              selectedPeriod.is_manual_stage_switch_enabled,
            )

            const shouldRenderStep =
              event.category === 'calibration' ? settings?.enable_calibration : true

            if (shouldRenderStep && event.category !== 'meeting') {
              return (
                <ProgressStep
                  indicatorColor={
                    state === 'pending' ? Token.color.accent : Token.color.teal
                  }
                  style={{ cursor: 'pointer' }}
                  data-testid={`${event.title}--${state}`}
                  key={i}
                  state={state}
                  onClick={() => setSelectedStepId(event.title)}
                  paddingTop="s-6"
                  paddingX="s-6"
                  paddingBottom="s-16"
                  borderRadius="r6"
                  bg={selectedStepId === event.title ? Token.color.grey50_10 : undefined}
                >
                  {event.title && (
                    <ProgressStep.Title>
                      {event.category === 'promotion'
                        ? 'Nomination for promotion'
                        : event.title}
                    </ProgressStep.Title>
                  )}
                  <ProgressStep.Description>{description}</ProgressStep.Description>
                </ProgressStep>
              )
            }
            return null
          })}

        <ProgressStep
          indicatorColor={
            lastStepState === 'pending' ? Token.color.accent : Token.color.teal
          }
          style={{ cursor: 'pointer' }}
          data-testid={`Grade published--${lastStepState}`}
          state={lastStepState}
          onClick={() => setSelectedStepId('Grade published')}
          paddingTop="s-6"
          paddingBottom="s-16"
          borderRadius="r6"
          bg={
            selectedStepId === 'Grade published' || lastStepState === 'pending'
              ? Token.color.grey50_10
              : undefined
          }
        >
          <ProgressStep.Title>Grade published</ProgressStep.Title>
          {!selectedPeriod.is_manual_stage_switch_enabled && (
            <ProgressStep.Description>{lastStepDescription}</ProgressStep.Description>
          )}
        </ProgressStep>
      </ProgressSteps>
      {selectedStepId && activeTimeLineStep?.type && (
        <Banner
          mt="s-12"
          labelButtonClear="Clear"
          onClear={() => setSelectedStepId(undefined)}
        >
          <Banner.Content>
            <PerfomanceStepDetails
              isManualCycle={selectedPeriod?.is_manual_stage_switch_enabled}
              isClosedCycle={getIsClosedEvent()}
              isEmptyReviewers={isEmptyReviewers}
              publishedResults={lastStepData}
              finalGrade={finalGrade}
              type={activeTimeLineStep?.type}
              eventStartDate={activeTimeLineStep.event?.start_period}
              eventEndDate={activeTimeLineStep.event?.end_period}
              reviewsElements={
                <PerformanceReviewers
                  event={activeTimeLineStep.event}
                  employee={data}
                  selectedPeriod={selectedPeriod}
                  goalsCount={goalsCount}
                  summaryData={summaryData}
                  currentCycleReviewsPublishingDay={currentCycleReviewsPublishingDay}
                />
              }
              actionsElements={
                <PerformanceActionButton
                  category={activeTimeLineStep.type}
                  employee={data}
                  selectedPeriod={selectedPeriod}
                />
              }
              canChangeGradePublishing={canChangeGradePublishing}
              selectedCycleId={selectedPeriod?.id}
              employee={data}
            />
          </Banner.Content>
        </Banner>
      )}
    </>
  )
}
