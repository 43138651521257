import React from 'react'
import { Box } from '@revolut/ui-kit'
import { InterviewResult } from '@src/interfaces/interviewTool'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SectionHeader'
import { RadioItem } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/RadioItem'
import { PerformanceRating } from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'

type Props = {
  disabled?: boolean
  suggestedResult?: InterviewResult
  options: RadioOption[]
  expectedLevel?: PerformanceRating
}

export const ResultHireSection = ({
  disabled,
  suggestedResult,
  options,
  expectedLevel,
}: Props) => {
  return (
    <Box>
      <SectionHeader title="How should we proceed?" />
      <LapeNewRadioButtons
        name="result"
        options={options.map(o => ({ label: o.label, value: o.value.id }))}
        readOnly={disabled}
        renderRadio={(radio, { value }) => (
          <RadioItem
            value={value}
            radio={radio}
            recommendedValue={suggestedResult}
            text={
              expectedLevel
                ? `Based on skills assessment it is recommended to hire a candidate with level ${PerformanceRatingTitle[expectedLevel]} and above for this role and seniority`
                : undefined
            }
          />
        )}
      />
    </Box>
  )
}
