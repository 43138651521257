import React from 'react'
import { employeeWorkAndOrgDetailsRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import { Props, WorkAndOrgDetailsFormContent } from './General'

export const WorkAndOrgDetails = (props: Props) => {
  return (
    <Form api={employeeWorkAndOrgDetailsRequests}>
      <WorkAndOrgDetailsFormContent {...props} />
    </Form>
  )
}
