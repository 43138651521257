import React, { FormEvent, useMemo, useRef, useState } from 'react'

import { CheckboxSelect, Icon, Input, SelectOptionType, VStack } from '@revolut/ui-kit'
import { validateClickupTask } from '@src/api/roadmaps'
import { debounce } from 'lodash'
import { string } from 'zod'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { EpicOption } from '@src/interfaces/kpis'
import { ClickupSelectedTaskWidget } from '../../../features/Roadmaps/Clickup/components/ClickupSelectedTaskWidget'
import { ClickUpSelectOption } from './components/ClickupSelectOption'

// user can insert either task ID or task url from clickup
const taskIdValidator = string()
  .url()
  // next value after /t/ should be the task ID
  .transform(val => val.split(`/t/`)?.[1]?.split(/(\?|\/)/)[0])
  .or(string())

export type ClickupOption = { key: string; name: string; url: string }

interface Props {
  onSelectionChange: (epics: ClickupOption[]) => void
  onDelete: (key: string) => void
  addedTasks: Pick<EpicOption, 'key' | 'url' | 'name'>[] | undefined
  error?: string
}

export const ClickupTasks = ({
  onSelectionChange,
  onDelete,
  addedTasks,
  error,
}: Props) => {
  const inputRef = useRef(null)
  const [searchPending, setSearchPending] = useState(false)
  const [searchValue, setSearchValue] = useState<string>()
  const [options, setOptions] = useState<ClickupOption[]>([])
  const [errorMessage, setErrorMessage] = useState<string>()

  const debouncedSearch = useMemo(() => {
    setErrorMessage(undefined)
    return debounce(async (searchString: string) => {
      try {
        const itemId = taskIdValidator.parse(searchString)
        const {
          data: { exists, data },
        } = await validateClickupTask({ itemId })
        if (exists && data) {
          setOptions([{ key: data.id, name: data.name, url: data.url }])
        }
      } catch (e) {
        setErrorMessage(getMessageFromApiError(e) || undefined)
      } finally {
        setSearchPending(false)
      }
    }, 1000)
  }, [])

  const onSearch = (searchString?: string) => {
    setErrorMessage(undefined)
    if (searchString === searchValue) {
      return
    }
    setOptions([])
    setSearchValue(searchString)
    if (searchString) {
      setSearchPending(true)
      debouncedSearch(searchString)
    }
  }

  return (
    <VStack space="s-16">
      <Input
        invalid={!!errorMessage}
        errorMessage={errorMessage}
        containerRef={inputRef}
        placeholder="Search tasks by ID or URL"
        pending={searchPending}
        onChange={(ev: FormEvent<HTMLInputElement>) => onSearch(ev?.currentTarget?.value)}
        renderAction={() => (searchPending ? undefined : <Icon name="Search" />)}
        value={searchValue}
      />
      <CheckboxSelect<ClickupOption, SelectOptionType<ClickupOption>>
        labelList="Tasks"
        value={[]}
        open={!!searchValue && !searchPending}
        anchorRef={inputRef}
        onClose={() => setSearchValue('')}
        options={options.map(option => ({
          value: option,
          label: option.name,
          key: option.key,
          disabled: !!addedTasks?.find(({ key }) => key === option.key),
        }))}
        fitInAnchor
        labelNoResults="No tasks found"
        labelClear="Cancel"
        type="confirm"
        labelApply="Add task"
        onChange={onSelectionChange}
      >
        {option => (
          <ClickUpSelectOption
            key={option.key}
            label={option.label}
            taskId={option.value?.key}
            url={option.value?.url}
          />
        )}
      </CheckboxSelect>
      <ClickupSelectedTaskWidget
        tasks={addedTasks || []}
        error={error}
        onDelete={onDelete}
      />
    </VStack>
  )
}
