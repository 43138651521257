import React from 'react'
import { Group, VStack, Text, Token, Box } from '@revolut/ui-kit'

import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { capitalize } from 'lodash'

interface TargetTypeFieldsProps {
  disableEdit: boolean
  variant: 'goal' | 'KPI'
}

export const TargetTypeFields = ({ disableEdit, variant }: TargetTypeFieldsProps) => {
  return (
    <VStack space="s-24">
      <Group>
        <VStack space="s-8" p="s-16">
          <Text variant="primary">
            {variant === 'goal' ? capitalize(variant) : variant} types
          </Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            What types of {variant}s are allowed
          </Text>
        </VStack>

        <Box pb="s-8" px="s-12">
          {variant === 'KPI' && (
            <LapeNewMultiSelect
              name="allowed_kpi_types"
              placeholder={
                { goal: 'Allowed goal types', KPI: 'Allowed KPI types' }[variant]
              }
              selector={selectorKeys.goal_target_types}
              message={
                {
                  goal: 'This will restrict what kind of goals can be added in the system.',
                  KPI: 'This will restrict what kind of KPIs can be added in the system.',
                }[variant]
              }
              required
              disabled={disableEdit}
            />
          )}
        </Box>
        <Box pb="s-8" px="s-12">
          <LapeNewMultiSelect
            name="allowed_kpi_strategies"
            placeholder={`Allowed ${variant} strategies`}
            selector={selectorKeys.allowed_kpi_strategies}
            message={
              {
                goal: 'This will restrict the strategies possible for measuring the progress of a goals.',
                KPI: 'This will restrict the strategies possible for measuring the progress of a KPI.',
              }[variant]
            }
            required
            disabled={disableEdit}
          />
        </Box>
      </Group>
      <LapeMultiInput
        title="Measuring units"
        name="kpi_measuring_units"
        valueFieldName={undefined}
        description={
          {
            goal: 'What unit will the goal progress be measured in. Ex: percent (%), dollars ($), etc.',
            KPI: 'What unit will the KPI progress be measured in. Ex: percent (%), dollars ($), etc.',
          }[variant]
        }
        label="E.g. percent (%)"
        withCell
        disabled={disableEdit}
      />
      <Group>
        <LapeNewSwitch
          itemTypeProps={{
            title: {
              goal: 'Enforce the parent goal field as mandatory',
              KPI: 'Enforce the parent KPI field as mandatory',
            }[variant],
            description: {
              goal: 'Enabling this will require all created goal to be connected to a higher level parent goal (e.g. a department goal set as the parent for a team goal).',
              KPI: 'Enabling this will require all created KPI to be connected to a higher level parent KPI (e.g. a department KPI set as the parent for a team KPI).',
            }[variant],
          }}
          name="enable_mandatory_parent_kpi"
        />

        <LapeNewSwitch
          itemTypeProps={{
            title: 'Allow multiple metrics per review cycle to be added to a goal',
          }}
          name="enable_multiple_goal_targets_per_cycle"
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable Proof URL for Mandatory goals',
            description:
              'Enabling this will require users to provide a proof when updating the progress of the mandatory goals',
          }}
          name="enable_mandatory_proof_url_kpi"
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable global goals privacy',
            description:
              'Enabling this will hide the employee individual goals tab for everyone except their managers and system administrators',
          }}
          name="enable_private_employee_goals"
        />
      </Group>
    </VStack>
  )
}
