import React, { useEffect, useState } from 'react'
import { CompetencyMatrixInterface, RoleInterface } from '@src/interfaces/roles'

import { SeniorityInterface } from '@src/interfaces/seniority'
import { getDefaultCompetencyMatrix } from '@src/api/roles'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SenioritiesRange from '@src/pages/Forms/RoleForm/CompetencyMatrix/SenioritiesRange'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  Token,
  Group,
  Item,
  Avatar,
  Box,
  VStack,
  Icon,
  TransitionCollapse,
  Widget,
} from '@revolut/ui-kit'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'

import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { API } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import CompetencyMatrixExample from '@src/pages/Forms/RoleForm/CompetencyMatrix/CompetencyMatrixExample'

import { useNextRoleStepButtonLink } from '@src/pages/Forms/RoleForm/hooks'
import { useGetSkillsSettings } from '@src/api/settings'
import { getDefaultCompetencyFor } from '@src/features/CompetencyMatrixTable/utils'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { PageActions } from '@src/components/Page/PageActions'

const CURRENT_STEP = 'competency_matrix'

const CompetencyMatrix = ({
  matrixValidated,
  seniorities,
}: {
  matrixValidated: boolean
  seniorities: SeniorityInterface[]
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { values } = useLapeContext<RoleInterface>()
  const [filteredSeniorities, setFilteredSeniorities] = useState<SeniorityInterface[]>([])
  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])
  const nextLink = useNextRoleStepButtonLink(CURRENT_STEP)
  const { data: skillsSettings } = useGetSkillsSettings()

  const canEdit =
    !values.id ||
    [PermissionTypes.AddRoles, PermissionTypes.ChangeRoles].some(p =>
      values.field_options?.permissions?.includes(p),
    )

  let competencyMatrix = values.functional_competency_matrix

  if (!competencyMatrix?.length) {
    const competencies = filteredSeniorities.map(seniority => ({
      seniority_id: seniority.id,
      seniority_name: seniority.name,
      seniority_level: seniority.level,
      competency_level: null,
    }))

    competencyMatrix = [
      {
        skill: {
          id: null,
          name: null,
        },
        competencies,
      },
    ]
  }

  useEffect(() => {
    if (values.status === 'draft') {
      workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {}))
    }
  }, [])

  useEffect(() => {
    if (seniorities.length) {
      // it's needed for old roles those don't have seniority_level
      values.functional_competency_matrix = competencyMatrix.map(item => ({
        ...item,
        competencies: item.competencies?.map(competency => {
          const seniorityLevel = seniorities.find(
            seniority => seniority.id === competency.seniority_id,
          )?.level

          return { ...competency, seniority_level: seniorityLevel }
        }),
      }))
    }
  }, [seniorities])

  if (values.function && (!values.seniority_min || !values.seniority_max)) {
    values.seniority_min = seniorities[0]
    values.seniority_max = seniorities[seniorities.length - 1]
  }

  const fetchDefaultMatrix = async () => {
    const response = await getDefaultCompetencyMatrix()

    if (Array.isArray(response.data?.culture_matrix)) {
      setDefaultCultureSkills(response.data.culture_matrix)
    }
  }

  useEffect(() => {
    if (!values.id) {
      fetchDefaultMatrix()
    }
  }, [])

  const onChangeMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data) {
      return
    }

    values.functional_competency_matrix = data
  }

  const onSeniorityRangeIncreased = (seniority: SeniorityInterface) => {
    values.functional_competency_matrix = values.functional_competency_matrix.map(
      ({ skill, competencies }) => {
        if (!competencies) {
          return { skill, competencies }
        }

        const lastCompetency = competencies.reduce((lastFilled, current) => {
          return current.competency_level ? current : lastFilled
        }, competencies[0])
        const lastSeniority = lastCompetency.seniority_level || 0

        if (seniority.level < lastSeniority) {
          return { skill, competencies }
        }

        const resultCompetencies = competencies
          .filter(({ competency_level, seniority_level }) =>
            seniority_level && seniority_level >= lastSeniority
              ? !!competency_level
              : true,
          )
          .concat(
            seniorities
              .filter(({ level }) => level > lastSeniority && level <= seniority.level)
              .map(s =>
                getDefaultCompetencyFor(
                  s,
                  (lastCompetency.competency_level && {
                    min: lastCompetency.competency_level,
                  }) ||
                    undefined,
                ),
              ),
          )

        return {
          skill,
          competencies: resultCompetencies,
        }
      },
    )
  }

  const onSeniorityRangeDescreased = (seniority: SeniorityInterface) => {
    values.functional_competency_matrix = values.functional_competency_matrix.map(
      ({ skill, competencies }) => {
        if (!competencies) {
          return { skill, competencies }
        }

        const firstCompetency = competencies[0]
        const firstSeniority = firstCompetency.seniority_level || 0

        if (seniority.level > firstSeniority) {
          return { skill, competencies }
        }

        const resultCompetencies = [
          ...seniorities
            .filter(({ level }) => level < firstSeniority)
            .map(s =>
              getDefaultCompetencyFor(
                s,
                (firstCompetency.competency_level && {
                  max: firstCompetency.competency_level,
                }) ||
                  undefined,
              ),
            ),
          ...competencies,
        ]

        return {
          skill,
          competencies: resultCompetencies,
        }
      },
    )
  }

  const addSkill = () => {
    const competencies = filteredSeniorities.map(seniority =>
      getDefaultCompetencyFor(seniority),
    )

    values.functional_competency_matrix = [
      ...(values.functional_competency_matrix || []),
      {
        skill: {
          id: null,
          name: null,
        },
        competencies,
      },
    ]
  }

  return (
    <VStack gap="s-16">
      <Widget maxWidth={Token.breakpoint.md}>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="TurboTransfer" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Allowed seniorities</Item.Title>
          </Item.Content>
        </Item>
        <Box px="s-16" pb={0}>
          <SenioritiesRange
            disabled={!canEdit || !seniorities.length}
            seniorities={seniorities}
            seniorityMaxValue={values.seniority_max}
            seniorityMinValue={values.seniority_min}
            onChangeMax={val => {
              values.seniority_max = val
              onSeniorityRangeIncreased(val)
            }}
            onChangeMin={val => {
              values.seniority_min = val
              onSeniorityRangeDescreased(val)
            }}
            onFilter={(minIndex, maxIndex) => {
              setFilteredSeniorities(seniorities.slice(minIndex, maxIndex + 1))
            }}
          />
        </Box>
      </Widget>
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="RepairTool" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Functional skills</Item.Title>
            <Item.Description>
              You can add multiple skills but should use no more than 5 skills for this
              role.
            </Item.Description>
          </Item.Content>
          <Item.Side>
            {canEdit && (
              <PrimaryAction onClick={addSkill} disabled={!seniorities.length}>
                Add skill
              </PrimaryAction>
            )}
          </Item.Side>
        </Item>
        <Box>
          <CompetencyMatrixTable
            isV2Table
            competencyMatrices={[
              {
                children: competencyMatrix,
                sectionTitle: 'Functional skills',
                staticSkill: false,
                onChange: onChangeMatrix,
              },
            ]}
            minSeniority={values.seniority_min}
            maxSeniority={values.seniority_max}
            isAdjustable={false}
            readOnly={!canEdit}
          />
        </Box>
      </Group>
      <Group>
        <Item onClick={() => setIsCollapsed(!isCollapsed)} style={{ cursor: 'pointer' }}>
          <Item.Prefix>
            <Icon
              color={Token.color.greyTone50}
              name={isCollapsed ? 'ChevronDown' : 'ChevronRight'}
              size={24}
            />
          </Item.Prefix>
          <Item.Avatar>
            <Avatar useIcon="RepairTool" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Competency matrix examples</Item.Title>
          </Item.Content>
        </Item>
        <TransitionCollapse in={isCollapsed}>
          <Box>
            <CompetencyMatrixExample
              minSeniority={values.seniority_min}
              maxSeniority={values.seniority_max}
              seniorities={seniorities}
            />
          </Box>
        </TransitionCollapse>
      </Group>
      {!skillsSettings?.behaviours_assessment_enabled && (
        <Group>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="LockClosed" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Culture fit skills</Item.Title>
              <Item.Description>
                Culture fit competency matrix is defined at company level. You cannot make
                any change here
              </Item.Description>
            </Item.Content>
          </Item>
          <Box>
            <CompetencyMatrixTable
              isV2Table
              competencyMatrices={[
                {
                  children: defaultCultureSkills,
                  sectionTitle: 'Culture fit',
                  disabled: true,
                },
              ]}
              minSeniority={values.seniority_min}
              maxSeniority={values.seniority_max}
              isAdjustable={false}
            />
          </Box>
        </Group>
      )}

      <PageActions maxWidthMd={Token.breakpoint.xxl}>
        <>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title="role"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.ROLE.COMPETENCY_MATRIX}
              isNew
              notification={{
                updateMsg: 'Role draft successfully updated.',
                createMsg: 'Role draft successfully created.',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.ROLES}
            type="role"
            step={CURRENT_STEP}
            to={nextLink}
            disabled={!matrixValidated}
          />
        </>
      </PageActions>
    </VStack>
  )
}

export default connect(CompetencyMatrix)
