import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { ImportEmployeesIntro } from './ImportEmployeesIntro'
import { EditableEmployeesTableSimple } from '@src/features/EditableEmployeesTable/TableSimple'
import { EmployeesBulkSessionSimple } from '@src/features/EditableEmployeesTable/TableSimple/BulkSession'
import { pathToUrl } from '@src/utils/router'
import { API } from '@src/constants/api'

import { importEmployeesConfig } from '../common/checkpointsConfig'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { OnboardingChecklistContent } from '../components/OnboardingChecklistContent'
import { SelectFlowPage, SelectFlow } from './SelectFlow'
import { SourceSettings } from '@src/pages/OnboardingChecklistV2/ImportEmployees/SourceSettings'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'
import PageLoading from '@components/PageLoading/PageLoading'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: ImportEmployeesIntro,
  },
  {
    title: 'Choose source',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SELECT_SOURCE,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SELECT_SOURCE,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: SelectFlow,
  },
  {
    title: 'Import',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: () => (
      <EditableEmployeesTableSimple
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
      />
    ),
    isWide: true,
  },
]

export const ImportEmployees = () => {
  const { isLoading, data } = useGetOnboardingCheckpointCategory('importEmployees')

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <Switch>
      {data?.state?.id === 'completed' ? (
        <InternalRedirect
          from={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
          to={ROUTES.APPS.EMPLOYEES.BULK_IMPORT_EMPLOYEES.SESSION}
        />
      ) : null}
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SELECT_FLOW}>
        <SelectFlowPage />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.SOURCE_SETTINGS}>
        <SourceSettings />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}>
        <EmployeesBulkSessionSimple
          importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
          sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
          anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}
          onAfterConfirmRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT}
          apiEndpoint={API.EMPLOYEE_UPLOADS}
          getHeader={title => (
            <OnboardingChecklistHeader
              title={title}
              backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT)}
            />
          )}
        />
      </Route>
      <Route>
        <OnboardingChecklistContent config={importEmployeesConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
