import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { CalendarProviders } from '@src/interfaces/meetings'

export const useIsOneToOnesEnabled = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  return featureFlags.includes(FeatureFlags.OneToOnes)
}

export const useOneToOnesCalendarAvailability = (): Record<
  CalendarProviders,
  boolean
> => {
  const isOneToOnesEnabled = useIsOneToOnesEnabled()
  const isCommercial = useIsCommercial()
  const { settings } = useGlobalSettings()

  if (!isOneToOnesEnabled) {
    return {
      [CalendarProviders.Google]: false,
    }
  }

  return {
    [CalendarProviders.Google]: isCommercial
      ? settings.office_suite_provider === 'google'
      : true,
  }
}
