import { StatusPopup, useStatusPopup, StatusPopupVariant } from '@revolut/ui-kit'
import { StatusPopupContextValue } from '@revolut/ui-kit/types/dist/components/StatusPopupProvider'
import React from 'react'
import { isDev } from '.'

type RenderAction = (statusPopup: StatusPopupContextValue) => React.ReactElement

type UseShowStatusPopupData = {
  title: React.ReactNode
  description?: React.ReactNode
  status?: StatusPopupVariant
  actions?: React.ReactNode | RenderAction
  preventUserClose?: boolean
  onClose?: (statusPopup: StatusPopupContextValue) => void
}

export const useShowStatusPopup = () => {
  const statusPopup = useStatusPopup()

  const popupHandler = ({
    status = 'success',
    title,
    description,
    actions,
    preventUserClose,
    onClose,
  }: UseShowStatusPopupData) => {
    if (isDev() && status === 'error') {
      console.warn('Prefer `useErrorPopup` when handling API errors instead')
    }

    statusPopup.show(
      <StatusPopup
        variant={status}
        preventUserClose={preventUserClose}
        onClose={() => {
          onClose?.(statusPopup)
        }}
      >
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {description && <StatusPopup.Description>{description}</StatusPopup.Description>}
        {actions && typeof actions === 'function'
          ? actions(statusPopup)
          : actions && <StatusPopup.Actions>{actions}</StatusPopup.Actions>}
      </StatusPopup>,
    )
  }
  popupHandler.hide = statusPopup.hide

  return popupHandler
}
