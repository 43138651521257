import {
  Avatar,
  Banner,
  BottomSheet,
  Box,
  Button,
  Icon,
  Token,
  Header as UIKitHeader,
} from '@revolut/ui-kit'
import React from 'react'
import { DependantEntities, GroupedError } from './DependantEntities'

const Header = ({
  title,
  subtitle,
  children,
}: React.PropsWithChildren<{ title: string; subtitle: string }>) => {
  return (
    <UIKitHeader variant="bottom-sheet">
      <UIKitHeader.Title>{title}</UIKitHeader.Title>
      <UIKitHeader.Subtitle>
        <Box mt="s-8">{subtitle}</Box>
      </UIKitHeader.Subtitle>
      {children}
    </UIKitHeader>
  )
}

export const ConfirmDeletePopup = ({
  name,
  open,
  onConfirm,
  onCancel,
}: {
  open: boolean
  name?: string
  onConfirm: () => void
  onCancel: () => void
}) => {
  if (!open) {
    return null
  }
  const title = name ? `Delete ${name}?` : 'Delete entity?'
  return (
    <BottomSheet open onClose={onCancel}>
      <Header
        title={title}
        subtitle="Deleting this item might affect other objects that reference it. You will not be
          able to delete this item while there are existing dependencies."
      />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Yes
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

export const FailedDeletePoopup = ({
  errorDetails,
  open,
  entityId,
  onRetry,
  onCancel,
  prefix,
}: {
  errorDetails: GroupedError[]
  open: boolean
  entityId: number
  onRetry: () => void
  onCancel: () => void
  prefix?: string
}) => {
  if (!open) {
    return null
  }
  const isPartOfReviewCycles = errorDetails.some(error => error.model === 'TimelineItem')
  return (
    <BottomSheet open onClose={onCancel}>
      <Header
        title="Item could not be deleted"
        subtitle="This item is referenced by other objects in the platform. Please review the
          dependencies below, either by assigning them to another item or deleting them
          too."
      >
        <UIKitHeader.Actions>
          <Icon name="16/Warning" color={Token.color.warning} />
        </UIKitHeader.Actions>
      </Header>
      {isPartOfReviewCycles && (
        <Banner mb="s-16">
          <Banner.Avatar>
            <Avatar useIcon="16/ExclamationMarkSign" />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Description>
              This item was a part of review cycle and cannot be deleted. Consider
              archiving it.
            </Banner.Description>
          </Banner.Content>
        </Banner>
      )}
      <DependantEntities entityId={entityId} details={errorDetails} prefix={prefix} />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onRetry}>
          Retry
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
