import React, { ForwardedRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  ActionButton,
  Avatar,
  Box,
  Item,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { AiIcon } from '@components/AiIcon/AiIcon'
import {
  BulletPointTextArea,
  BULLET_POINT_REG_EXP,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/BulletPointTextArea'
import SectionStatus from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/SectionStatus'

interface Props {
  pros?: string
  cons?: string
  onConfirm: (feedback: { pros?: string; cons?: string }) => void
  onReject: VoidFunction
}

export const AiFeedback = React.forwardRef(
  ({ pros, cons, onConfirm, onReject }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const handleConfirm = () => {
      onConfirm({
        pros: pros?.split(BULLET_POINT_REG_EXP).filter(Boolean).join(''),
        cons: cons?.split(BULLET_POINT_REG_EXP).filter(Boolean).join(''),
      })
    }

    return (
      <Box ref={ref} data-testid="performance_scorecard_ai_feedback">
        <VStack space="s-8">
          <Widget p="s-12">
            <VStack space="s-8">
              {pros && (
                <Box>
                  <SectionStatus
                    title={
                      <Text color={Token.color.greyTone50}>
                        <FormattedMessage
                          id="performance.scorecard.ai.feedback.pros.title"
                          defaultMessage="Strengths"
                        />
                      </Text>
                    }
                    isGeneratedByAi
                  />
                  <BulletPointTextArea value={pros} disabled />
                </Box>
              )}
              {cons && (
                <Box>
                  <SectionStatus
                    title={
                      <Text color={Token.color.greyTone50}>
                        <FormattedMessage
                          id="performance.scorecard.ai.feedback.cons.title"
                          defaultMessage="Areas for development"
                        />
                      </Text>
                    }
                    isGeneratedByAi
                  />
                  <BulletPointTextArea value={cons} disabled />
                </Box>
              )}
            </VStack>
          </Widget>
          <Item variant="nested">
            <Item.Avatar>
              <Avatar useIcon={AiIcon} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="performance.scorecard.ai.feedback.title"
                  defaultMessage="Use AI content?"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="performance.scorecard.ai.feedback.description"
                  defaultMessage="You can change the text later"
                />
              </Item.Description>
              <Item.Actions>
                <ActionButton variant="accent" useIcon="Check" onClick={handleConfirm}>
                  <FormattedMessage
                    id="performance.scorecard.ai.feedback.cta.confirm.label"
                    defaultMessage="Yes, use this"
                  />
                </ActionButton>
                <ActionButton useIcon="Cross" onClick={onReject}>
                  <FormattedMessage
                    id="performance.scorecard.ai.feedback.cta.reject.label"
                    defaultMessage="No, clear field"
                  />
                </ActionButton>
              </Item.Actions>
            </Item.Content>
          </Item>
        </VStack>
      </Box>
    )
  },
)
