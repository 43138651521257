import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import {
  AvailableSubscriptionPlanInterface,
  MerchantApiConfigInterface,
  MerchantApiOrder,
  MerchantPaymentMethod,
  SubscriptionInfoInterface,
  UseSubsciptionInfoResult,
  SubscriptionInvoiceInterface,
  AccountClosureReason,
  AccountClosureInterface,
  PayInvoiceResponseInterface,
  SubscriptionPlanInterface,
  CustomPlanInterface,
  UpdatePlanHeadcountInterface,
  SubscriptionPlanInfterface,
  CompanyBillingSettingsInterface,
} from '@src/interfaces/plans'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, LocalStorageKeys, PermissionTypes } from '@src/store/auth/types'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useSelector } from 'react-redux'
import { api, apiV2, apiWithoutHandling } from '.'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { TenantDiscountInterface } from '@src/interfaces/tenants'
import { AxiosError } from 'axios'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { getFileNameFromHeaders } from '@src/utils/files'

export const useAvailableSubscriptionPlans = () => {
  const result = useFetch<GetRequestInterface<AvailableSubscriptionPlanInterface>>(
    '/availableSubscriptionPlans',
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  return { ...result, plans: result.data?.results }
}

export const useDiscounts = () => {
  const result = useFetch<GetRequestInterface<TenantDiscountInterface>>(
    `${API.TENANTS}/discounts`,
  )
  return { discounts: result?.data?.results, ...result }
}

export const getMerchantApiConfig = () =>
  api.get<MerchantApiConfigInterface>(`/revolutMerchantApi/config`)

export const useMerchantApiPaymentMethod = (id?: string) =>
  useFetch<MerchantPaymentMethod>(
    id ? `/revolutMerchantApi/paymentMethods/${id}` : null,
    undefined,
    undefined,
    true,
  )

export const createMerchantApiOrder = (subscription_plan_id: number) =>
  api.post<MerchantApiOrder>(`/revolutMerchantApi/orders/subscribe`, {
    subscription_plan_id,
  })

export const changeMerchantApiPaymentMethod = () =>
  api.post<MerchantApiOrder>(`/revolutMerchantApi/orders/changePaymentMethod`)

export const refreshMerchantApiOrder = (id: number) =>
  apiWithoutHandling.post<MerchantApiOrder>(`/revolutMerchantApi/orders/${id}/refresh`)

export const useSubsciptionInfo = (): UseSubsciptionInfoResult => {
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)

  const subscriptionPlansEnabled = featureFlags.includes(FeatureFlags.SubscriptionPlans)
  const canManageSubscriptionPlans = permissions.includes(
    PermissionTypes.ManageSubscriptionPlans,
  )

  const enabled = subscriptionPlansEnabled && canManageSubscriptionPlans

  const response = useFetch<SubscriptionInfoInterface>(
    API.SUBSCRIPTIONS_INFO_API,
    'v2',
    undefined,
    true,
    { enabled },
  )

  if (
    !!response.data?.revolut_merchant_api_payment_method?.id &&
    !!workspaceLocalStorage.getItem(LocalStorageKeys.ALLOW_CONTINUE_ONBOARDING)
  ) {
    workspaceLocalStorage.removeItem(LocalStorageKeys.ALLOW_CONTINUE_ONBOARDING)
  }

  return {
    enabled,
    info: response.data,
    ...response,
  }
}

export const getSubscriptionInfo = () =>
  apiWithoutHandling.get<SubscriptionInfoInterface>(API.SUBSCRIPTIONS_INFO_API)

export const resetSubscriptions = () => api.post(`/subscriptions/reset`)

export const subscriptionInvoicesRequests: TableRequestInterface<SubscriptionInvoiceInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/subscriptions/invoices`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const useSubscriptionInvoiceDetails = (id: string) =>
  useFetch<SubscriptionInvoiceInterface>(
    `/subscriptions/invoices/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
    },
  )

export const createMissingSubscriptionInvoice = (tenantId: string) =>
  apiWithoutHandling.post(
    `/tenants/${tenantId}/subscriptions/invoices/createMissingSubscriptionInvoice`,
  )

export const downloadInvoice = async (id: string | number) => {
  const res = await api.get<Blob>(`/subscriptions/invoices/${id}/download`, {
    responseType: 'blob',
  })
  const fileName = getFileNameFromHeaders(res.headers) || 'invoice.pdf'

  return { file: URL.createObjectURL(res.data), fileName }
}

export const closeAccount = (data: AccountClosureReason) =>
  api.post(API.ACCOUNT_CLOSURE, data)

export const accountClosureSendConfirmationEmail = () =>
  apiWithoutHandling.post(`${API.ACCOUNT_CLOSURE}/sendConfirmationEmail`)

export const accountClosureConfirm = (token: string) =>
  apiWithoutHandling.post(`${API.ACCOUNT_CLOSURE}/confirm`, { token })

export const reactivateAccount = () =>
  apiWithoutHandling.post(`${API.ACCOUNT_CLOSURE}/cancel`)

export const useAccountClosureInfo = () => {
  const response = useFetch<GetRequestInterface<AccountClosureInterface>>(
    API.ACCOUNT_CLOSURE,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
    },
  )

  return { ...response, data: response.data?.results.at(-1) }
}

export const payInvoice = (id: string | number) =>
  apiWithoutHandling.post<PayInvoiceResponseInterface>(
    `/subscriptions/invoices/${id}/pay`,
  )

export const usePayInvoiceState = (
  invoice_id?: string | number,
  paymentId?: string | number,
) =>
  useFetch<PayInvoiceResponseInterface>(
    paymentId ? `/subscriptions/invoices/payments/${paymentId}` : null,
    undefined,
    { params: { invoice_id } },
    true,
    {
      refetchInterval: session => (session?.status.id === 'pending' ? 1000 : false),
    },
  )

export const getSubscriptionPlans = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<GetRequestInterface<SubscriptionPlanInterface>>(`/subscriptionPlans`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const useSubscriptionPlans = () => {
  const result = useFetch<GetRequestInterface<SubscriptionPlanInterface>>(
    `/subscriptionPlans`,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
    },
  )

  return { subscriptionPlans: result.data?.results, ...result }
}

export const useSubscriptionPlanDetails = (id: string) =>
  useFetch<SubscriptionPlanInterface>(
    `/subscriptionPlans/${id}`,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
    },
  )

export const editSubscriptionPlan = (id: number, data: SubscriptionPlanInfterface) =>
  apiWithoutHandling.patch(`/subscriptionPlans/${id}`, data, undefined, 'v2')

/** Use only for development and testing, when `subscription_plans_devtools` feature flag is enabled  */
export const useTestCreateOpenInvoice = () =>
  useFetch<SubscriptionInvoiceInterface>(
    `/subscriptions/invoices/next`,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

/** Use only for development and testing, when `subscription_plans_devtools` feature flag is enabled  */
export const restrictSubscription = () => api.post(`/subscriptions/restrict`)

/** Use only for development and testing, when `subscription_plans_devtools` feature flag is enabled  */
export const unrestrictSubscription = () => api.post(`/subscriptions/unrestrict`)

export const deleteInvoice = (id: string) => api.delete(`/subscriptions/invoices/${id}`)

export const cancelInvoice = (tenantId: string, id: string) =>
  apiWithoutHandling.post(`/tenants/${tenantId}/subscriptions/invoices/${id}/cancel`)

export const adminDeleteInvoice = (tenantId: string, id: string) =>
  apiWithoutHandling.delete(`/tenants/${tenantId}/subscriptions/invoices/${id}`)

export const markInvoiceAsPaid = (
  id: string,
  tenantId: string,
  data: { settlement_reference: string },
) =>
  apiWithoutHandling.post(
    `/tenants/${tenantId}/subscriptions/invoices/${id}/updateToPaid`,
    data,
  )

export const useLatestInvoice = (enabled: boolean) =>
  useFetch<SubscriptionInvoiceInterface | null, AxiosError>(
    `/subscriptions/invoices/latest`,
    undefined,
    { params: { status: 'open' } },
    true,
    { enabled },
  )

export const getLatestInvoice = () =>
  apiWithoutHandling.get<SubscriptionInvoiceInterface | null>(
    `/subscriptions/invoices/latest`,
    { params: { status: 'open' } },
  )

export const requestManualPlan = () =>
  apiWithoutHandling.post(`/subscriptions/requestManualPlan`, undefined, undefined, 'v2')

export const createCustomPlanAndSubscription = (
  id: string | number,
  data: CustomPlanInterface,
) =>
  apiWithoutHandling.post(
    `${API.TENANTS}/${id}/subscriptions/createPlanAndSubscription`,
    data,
    undefined,
    'v2',
  )

export const updatePlannedHeadcount = (
  tenantId: string | number,
  data: UpdatePlanHeadcountInterface,
) =>
  apiWithoutHandling.post(
    `${API.TENANTS}/${tenantId}/subscriptions/updatePlannedHeadcount`,
    { ...data, effective_date: data.effective_date?.split('T')[0] },
    undefined,
    'v2',
  )

export const companyBillingSettingsRequests: RequestInterfaceNew<CompanyBillingSettingsInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.COMPANY_BILLING_SETTINGS}/${id}`),
    update: async (data, { id }) =>
      apiV2.patch(`${API.COMPANY_BILLING_SETTINGS}/${id}`, data),
    submit: async data => apiV2.post(API.COMPANY_BILLING_SETTINGS, data),
  }
