import { Token } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { organisationViewPermissions } from '@src/pages/Settings/SettingsLandingPage/constants'

export const companyPreferencesItemConfig = {
  path: ROUTES.SETTINGS.GENERAL.PREVIEW,
  title: 'Company preferences',
  description: 'Company details, company logo and more',
  icon: 'Switches' as const,
  color: Token.color.blue,
  canView: [
    PermissionTypes.ViewCompanySettings,
    PermissionTypes.CanManageCompanyLogo,
    PermissionTypes.ChangeCompanyPreferences,
  ],
}

export const structureSettingsItemConfig = {
  path: ROUTES.SETTINGS.STRUCTURES.ALL,
  title: 'Company structure',
  description: 'Manage team and role settings and organisation structure',
  icon: 'Workflow' as const,
  color: Token.color.blue,
  canView: organisationViewPermissions,
}

export const securitySettingsItemConfig = {
  path: ROUTES.SETTINGS.SECURITY,
  title: 'Security settings',
  color: Token.color.blue,
  description: 'Login option, 2-factor authentication and single sign on',
  icon: 'Shield' as const,
  canView: [PermissionTypes.ViewSecuritySettings],
}
