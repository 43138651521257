import React, { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { confirmKPIsUpload, getKPIsUploadSessionDataRequest } from '@src/api/importData'
import { useParams } from 'react-router'
import { useTable } from '@components/Table/hooks'
import {
  ImportKPIsActions,
  ImportKPIsData,
  ImportKPIsDataStats,
  ImportKPIsMeta,
} from '@src/interfaces/importKPIs'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { Button } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  getKPIUploadColumnConfig,
  uploadKpiRowActionColumn,
} from '@src/constants/columns/importKPIs'
import Stat from '@components/Stat/Stat'
import { PageActions } from '@components/Page/PageActions'
import { successNotification } from '@src/actions/NotificationActions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ImportSessionStatus } from '@src/interfaces/importData'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { TableNames } from '@src/constants/table'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import Table from '@src/components/TableV2/Table'
import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'

const getStats = (tableData: ImportKPIsData[]): ImportKPIsDataStats => {
  return tableData.reduce(
    (acc, curr, key) => {
      if (curr.action === ImportKPIsActions.Create) {
        acc.rowsForCreate += 1
      }
      if (curr.action === ImportKPIsActions.Update) {
        acc.rowsForUpdate += 1
      }
      if (curr.errors && !isEmpty(curr.errors)) {
        acc.rowsWithErrors += 1
        acc.errorRowsIndexes.push(key)
      }

      acc.all += 1
      return acc
    },
    {
      all: 0,
      rowsWithErrors: 0,
      rowsForCreate: 0,
      rowsForUpdate: 0,
      errorRowsIndexes: [] as number[],
    },
  )
}

const getOnlyErroredRows = (
  tableData: ImportKPIsData[],
  errorRowsIndexes: ImportKPIsDataStats['errorRowsIndexes'],
) => {
  return errorRowsIndexes.map(index => tableData[index])
}

const notStartedStatuses = [ImportSessionStatus.Valid, ImportSessionStatus.Invalid]

const ReviewData = () => {
  const { id: sessionId } = useParams<{ id: string }>()

  const [isSubmitting, setIsSubmitting] = useState<boolean>()
  const [showOnlyErrors, setShowOnlyErrors] = useState<boolean>()

  const table = useTable<ImportKPIsData, undefined, ImportKPIsMeta>({
    getItems: getKPIsUploadSessionDataRequest(sessionId),
  })
  const status = table.metadata?.status.id

  const row = useMemo(() => {
    if (table.metadata) {
      const cells = table.metadata.columns.map(fieldName => {
        return getKPIUploadColumnConfig(fieldName)
      })
      cells.push(uploadKpiRowActionColumn)

      return { cells }
    }
    return undefined
  }, [table.metadata])

  const stats = useMemo(() => {
    if (table.data.length > 0) {
      return getStats(table.data)
    }
    return undefined
  }, [table.data])

  const isValid = stats && stats.rowsWithErrors === 0

  const submit = async () => {
    try {
      setIsSubmitting(true)
      await confirmKPIsUpload(sessionId)

      navigateTo(pathToUrl(ROUTES.FORMS.IMPORT_DATA.KPIS.STATUS, { id: sessionId }))
      successNotification('Upload started')
    } finally {
      setIsSubmitting(false)
    }
  }

  // If upload is pending or done, redirect to status page
  if (status && !notStartedStatuses.includes(status)) {
    return (
      <InternalRedirect
        to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.KPIS.STATUS, { id: sessionId })}
      />
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Review data"
        backUrl={ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.UPLOAD_FILE}
      />
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="KPIs to import" val={stats?.all} mr="s-32" />
          <Stat label="KPIs to create" val={stats?.rowsForCreate} mr="s-32" />
          <Stat label="KPIs to update" val={stats?.rowsForUpdate} mr="s-32" />
          <Stat label="Errors" color="error" val={stats?.rowsWithErrors} mr="s-32" />
        </Table.Widget.Info>
        <Table.Widget.Actions>
          {!isValid && (
            <SwitchButton
              checked={showOnlyErrors}
              onClick={() => setShowOnlyErrors(!showOnlyErrors)}
            >
              Show only errored rows
            </SwitchButton>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Table>
          {row ? (
            <AdjustableTable
              name={TableNames.KPIImportReview}
              useWindowScroll
              dataType="KPIs"
              row={row}
              {...table}
              loading={table.loading || !stats}
              data={
                showOnlyErrors && stats
                  ? getOnlyErroredRows(table.data, stats.errorRowsIndexes)
                  : table.data
              }
            />
          ) : (
            <TableBodySkeleton rowHeight="large" />
          )}
        </Table.Widget.Table>
      </Table.Widget>

      {stats && (
        <PageActions>
          <Button disabled={!isValid} pending={isSubmitting} onClick={submit}>
            Upload this data
          </Button>
        </PageActions>
      )}
    </PageWrapper>
  )
}
export default ReviewData
