import { IconName } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { BenefitStatus } from '@src/interfaces/rewards'
import { EmployeeTotalCompensationStatsInterface } from '@src/interfaces/totalCompensation'

export const getBenefitStatusText = (status: IdAndName<BenefitStatus>) => {
  if (status.id === 'pending_approval') {
    return 'Pending'
  }

  if (status.id === 'pending_employee') {
    return 'Enrol now'
  }

  return status.name
}

export const getTotalEquityTooltipText = (isCommercial: boolean) =>
  isCommercial
    ? 'The total value of your equity, including vested and unvested shares based on the latest share price valuation, and sold shares based on sell price.'
    : 'The total value of your equity, including vested and unvested shares based on the latest share price valuation.'

export const getBenefitIcon = (status: BenefitStatus): IconName => {
  switch (status) {
    case 'pending_approval':
    case 'pending_employee':
      return '16/SandWatch'
    case 'opted_out':
    case 'rejected':
      return 'Cross'
    default:
      return 'Check'
  }
}

export const getHeight = (data?: EmployeeTotalCompensationStatsInterface) => {
  if (!data) {
    return { height: '55vh', minHeight: 520 }
  }

  if (Number(data.total_equity) && Number(data.total_cash_bonus)) {
    return { height: '70vh', minHeight: 600 }
  }

  if (Number(data.total_equity) || Number(data.total_cash_bonus)) {
    return { height: '55vh', minHeight: 520 }
  }

  return { height: '45vh', minHeight: 500 }
}
