import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import {
  Button,
  Popup,
  StatusPopup,
  VStack,
  Text,
  Checkbox,
  useStatusPopup,
  Color,
} from '@revolut/ui-kit'

import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import {
  EmployeeStatusTransitionsInterface,
  EmployeeStatusInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { UseEmployeeStatusTransitionsReturnType } from '../../StatusChange/hooks'
import { UseEmployeeInactivityDataReturnType } from '../hooks'
import { isInactivityScheduled } from '../helpers'

type Props = {
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
}
const SetInactiveFormContent = ({ statusTransitions, employeeInactivity }: Props) => {
  const { values } = useLapeContext<EmployeeStatusTransitionsInterface>()

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const [isSubmissionAllowed, setIsSubmissionAllowed] = useState(isCommercial)

  useEffect(() => {
    values.status = { id: IdStatuses.inactive } as EmployeeStatusInterface
    const { data } = employeeInactivity

    if (data && isInactivityScheduled(data)) {
      values.inactivity_reason = data.inactivity_reason || {
        id: 'other',
        name: 'Other',
      }
      values.inactivity_start_date = data.inactivity_start_date || ''
      values.inactivity_end_date = data.inactivity_end_date || ''
    }
  }, [])

  useEffect(() => {
    if (!isCommercial) {
      setIsSubmissionAllowed(!values?.inactivity_reason?.will_revoke_access)
    }
  }, [isCommercial, values?.inactivity_reason?.will_revoke_access])

  const statusPopup = useStatusPopup()

  const onSubmit = async () => {
    const updateAction = statusTransitions.isInactiveEmployee
      ? employeeInactivity.update
      : statusTransitions.update
    const refetchAction = statusTransitions.isInactiveEmployee
      ? statusTransitions.refetch
      : employeeInactivity.refetch

    await updateAction(values)
    await refetchAction()

    employeeInactivity.setIsPopupOpened(false)
    statusPopup.show(
      <StatusPopup
        variant="success"
        // @ts-ignore this prop exists, TS definition is lying
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>
          Employee inactivity was successfully scheduled
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const disableSubmit =
    !values.inactivity_reason ||
    !values.inactivity_start_date ||
    !values.inactivity_end_date ||
    !isSubmissionAllowed

  const SubmissionWarning = () => {
    if (!values.inactivity_reason) {
      return null
    }

    if (values.inactivity_reason.will_revoke_access) {
      return (
        <VStack gap="s-8" my="s-4">
          <Text color={Color.RED}>
            All access (Revoluters, Google, Okta, etc.) will be revoked during{' '}
            {values.inactivity_reason.name}.
          </Text>
          <Checkbox
            onChange={() => setIsSubmissionAllowed(prev => !prev)}
            checked={isSubmissionAllowed}
          >
            <Text>I acknowledge the above and wish to proceed.</Text>
          </Checkbox>
        </VStack>
      )
    }

    return (
      <Text color={Color.GREEN} my="s-4">
        User will retain access to internal platforms (Revoluters, Google, Okta, etc.)
        during {values.inactivity_reason.name}.
      </Text>
    )
  }

  return (
    <>
      <VStack space="s-8">
        <LapeRadioSelectInput
          required
          name="inactivity_reason"
          label="Reason"
          selector={selectorKeys.contract_inactivity_reasons}
        />
        <LapeDatePickerInput required name="inactivity_start_date" label="Start date" />
        <LapeDatePickerInput required name="inactivity_end_date" label="End date" />
        <HideIfCommercial>
          <SubmissionWarning />
        </HideIfCommercial>
      </VStack>
      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={e => {
            e.stopPropagation()
            employeeInactivity.setIsPopupOpened(false)
          }}
        >
          Go back
        </Button>
        <Button
          elevated
          disabled={disableSubmit}
          pending={employeeInactivity.pending || statusTransitions.pending}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </Popup.Actions>
    </>
  )
}

export const SetInactiveForm = connect((props: Props) => (
  <Form disableLocalStorageCaching>
    <SetInactiveFormContent {...props} />
  </Form>
))
