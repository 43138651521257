import React, { useMemo } from 'react'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { connect } from 'lape'
import { OfferChecklistTableInterface } from '@src/interfaces/offerCreation'
import {
  offerChecklistCheckColumn,
  offerChecklistCriteriaColumn,
  offerChecklistReqColumn,
  offerChecklistValueColumn,
} from '@src/constants/columns/offerForm/offerChecklist'
import { useGetOfferChecklist } from '@src/api/offerCreation'
import { formatDate, formatMoney } from '@src/utils/format'
import isAfter from 'date-fns/isAfter'
import isNumber from 'lodash/isNumber'
import isEqual from 'lodash/isEqual'
import { Box, Color, Flex, Text, VStack } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

export const ROW: RowInterface<OfferChecklistTableInterface> = {
  cells: [
    {
      ...offerChecklistCriteriaColumn,
      width: 160,
    },
    {
      ...offerChecklistCheckColumn,
      width: 75,
    },
    {
      ...offerChecklistValueColumn,
      width: 200,
    },
    {
      ...offerChecklistReqColumn,
      width: 285,
    },
  ],
}

type Props = {
  id: number
}

const checkIfInRange = (val?: number, lower?: number, upper?: number) =>
  isNumber(lower) && isNumber(upper) && isNumber(val)
    ? Math.floor(lower) <= Math.floor(val) && Math.floor(val) <= Math.floor(upper)
    : undefined

const formatMoneyRange = (
  lower?: number,
  upper?: number,
  lowerLocal?: number,
  upperLocal?: number,
  localCurrencyCode?: string,
) => {
  let range =
    isNumber(lower) && isNumber(upper)
      ? `${formatMoney(Math.floor(lower))} - ${formatMoney(Math.floor(upper))}`
      : undefined

  let localRange =
    isNumber(lowerLocal) && isNumber(upperLocal)
      ? `${formatMoney(Math.floor(lowerLocal), localCurrencyCode)} - ${formatMoney(
          Math.floor(upperLocal),
          localCurrencyCode,
        )}`
      : undefined

  if (!range) {
    return '-'
  }

  return (
    <Text>
      {range} {localRange && <Text color={Color.GREY_TONE_50}>({localRange})</Text>}
    </Text>
  )
}

export const getFormattedMoney = (
  amount?: number,
  currencyCode?: string,
  localAmount?: number,
  localCurrencyCode?: string,
) => {
  let money = isNumber(amount) ? formatMoney(Math.floor(amount), currencyCode) : undefined
  let localMoney = isNumber(localAmount)
    ? formatMoney(Math.floor(localAmount), localCurrencyCode)
    : undefined

  if (!money) {
    return '-'
  }

  return (
    <Text>
      {money} {localMoney && <Text color={Color.GREY_TONE_50}>({localMoney})</Text>}
    </Text>
  )
}

const OfferChecklist = ({ id }: Props) => {
  const { data, isLoading } = useGetOfferChecklist(id)

  const tableData = useMemo<OfferChecklistTableInterface[]>(() => {
    if (!data) {
      return []
    }

    let seniorityReqValue = '-'

    if (
      data.requisition_seniorities?.seniority_min &&
      data.requisition_seniorities?.seniority_max
    ) {
      seniorityReqValue =
        data.requisition_seniorities.seniority_min.name ===
        data.requisition_seniorities.seniority_max.name
          ? data.requisition_seniorities.seniority_min.name
          : `${data.requisition_seniorities.seniority_min.name} - ${data.requisition_seniorities.seniority_max.name}`
    }

    return [
      {
        criteria: 'Compensation',
        check: checkIfInRange(
          data.offer_form_compensation?.converted_amount?.amount,
          data.reference_compensation?.converted_amount?.lower_band,
          data.reference_compensation?.converted_amount?.upper_band,
        ),
        offerValue: getFormattedMoney(
          data.offer_form_compensation?.converted_amount?.amount,
          undefined,
          data.offer_form_compensation?.local_amount?.amount,
          data.offer_form_compensation?.local_amount?.currency_iso_code,
        ),
        reqValue: formatMoneyRange(
          data.reference_compensation?.converted_amount?.lower_band,
          data.reference_compensation?.converted_amount?.upper_band,
          data.reference_compensation?.local_amount?.lower_band,
          data.reference_compensation?.local_amount?.upper_band,
          data.reference_compensation?.local_amount?.currency_iso_code,
        ),
      },
      {
        criteria: 'Requisition budget',
        tooltip: (
          <Box p="s-8" width={320}>
            <VStack gap="s-4">
              <Text display="block" variant="h6" color="background">
                Impact on department budget (monthly)
              </Text>
              <Flex justifyContent="space-between">
                <Text color="background">Approved budget</Text>
                <Text color="background">
                  {getFormattedMoney(data.current_approved_budget?.staff_cost)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text color="background">Current run rate</Text>
                <Text color="background">
                  {getFormattedMoney(data.current_run_rate?.staff_cost)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text color="background">Budget available</Text>
                <Text color="background">
                  {getFormattedMoney(data.available_budget?.staff_cost)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text color="background">Variance of offer with requisition budget</Text>
                <Text color="background">
                  {data.offer_form_compensation?.converted_amount?.amount &&
                  data.requisition_budget?.converted_amount?.amount
                    ? getFormattedMoney(
                        (data.requisition_budget.converted_amount.amount -
                          data.offer_form_compensation.converted_amount.amount) /
                          12,
                      )
                    : '-'}
                </Text>
              </Flex>
            </VStack>
          </Box>
        ),
        check: checkIfInRange(
          data.offer_form_compensation?.converted_amount?.amount,
          0,
          data.requisition_budget?.converted_amount?.amount,
        ),
        offerValue: getFormattedMoney(
          data.offer_form_compensation?.converted_amount?.amount,
        ),
        reqValue: getFormattedMoney(data.requisition_budget?.converted_amount?.amount),
      },
      {
        criteria: 'Sign on bonus',
        check: checkIfInRange(
          data.offer_form_sign_on_bonus?.amount,
          data.reference_sign_on_bonus?.converted_amount?.lower_band,
          data.reference_sign_on_bonus?.converted_amount?.upper_band,
        ),
        offerValue: getFormattedMoney(
          data.offer_form_sign_on_bonus?.amount,
          data.offer_form_sign_on_bonus?.currency_iso_code,
        ),
        reqValue: formatMoneyRange(
          data.reference_sign_on_bonus?.converted_amount?.lower_band,
          data.reference_sign_on_bonus?.converted_amount?.upper_band,
        ),
      },
      {
        criteria: 'Seniority',
        check:
          data.requisition_seniorities?.seniority_min &&
          data.requisition_seniorities?.seniority_max
            ? data.requisition_seniorities.seniority_min.level <=
                data.offer_form_seniority.level &&
              data.offer_form_seniority.level <=
                data.requisition_seniorities.seniority_max.level
            : undefined,
        offerValue: data.offer_form_seniority.name,
        reqValue: seniorityReqValue,
      },
      {
        criteria: 'Location',
        check: data.requisition_locations.includes(data.offer_form_location),
        offerValue: data.offer_form_location,
        reqValue: data.requisition_locations.join(', '),
      },
      {
        criteria: 'Start date',
        check:
          isAfter(
            new Date(data.offer_form_start_date),
            new Date(data.requisition_start_date),
          ) ||
          isEqual(
            new Date(data.offer_form_start_date),
            new Date(data.requisition_start_date),
          ),
        offerValue: formatDate(data.offer_form_start_date),
        reqValue: formatDate(data.requisition_start_date),
      },
    ]
  }, [data])

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.OfferChecklist}
          row={ROW}
          noReset
          hideCountAndButtonSection
          loading={isLoading}
          data={tableData}
          count={tableData.length}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default connect(OfferChecklist)
