import React from 'react'
import { JobPostingsTable } from '@src/features/JobPostingsTable/JobPostingsTable'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { navigateToJobPostingFlow } from '@src/features/JobPostingFlow/utils'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import ReferCandidateButton from '@src/components/ReferCandidateButton/ReferCandidateButton'

const AddJobPostingAction = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddJobDescriptions)

  if (!canAdd) {
    return null
  }
  return (
    <PrimaryAction
      onClick={() => {
        navigateToJobPostingFlow({})
      }}
      useIcon={Plus}
    >
      Add Job posting
    </PrimaryAction>
  )
}

const JobPostingActions = () => {
  return (
    <>
      <AddJobPostingAction />
      <ReferCandidateButton />
      <SettingsButton
        path={ROUTES.SETTINGS.JOBS.LIST}
        canView={settingsConfig.jobs.canView}
      />
    </>
  )
}

export const JobPostingsTableWidget = () => {
  return <JobPostingsTable actions={<JobPostingActions />} />
}
