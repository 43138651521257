import { useTableReturnType } from '@components/TableV2/hooks'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { Stats } from '@src/interfaces/data'
import React, { useMemo } from 'react'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapOwnerColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { EmptyRoadmapsTable } from '@src/features/Goals/components/EmptyRoadmapsTable'
import { useShowRoadmapDetails } from '@src/features/Roadmaps'
import { PermissionTypes } from '@src/store/auth/types'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'

interface QuarterRoadmapsTableProps {
  table: useTableReturnType<RoadmapInterface, Stats, {}>
}

export const QuarterRoadmapsTable = ({ table }: QuarterRoadmapsTableProps) => {
  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
  })
  const { entity } = useOrgEntity()
  const roadmapRow = useMemo(
    () => ({
      cells: [
        {
          ...roadmapGenericNameColumn,
          width: 350,
        },
        {
          ...roadmapProgressColumn,
          width: 100,
        },
        {
          ...roadmapStatusColumn,
          width: 100,
        },
        {
          ...roadmapStartDate,
          width: 100,
        },
        {
          ...roadmapDueDate,
          width: 100,
        },
        {
          ...roadmapResolutionAtColumn,
          width: 100,
        },
        {
          ...roadmapOwnerColumn,
          width: 100,
        },

        {
          ...roadmapEpicUrlColumn,
          width: 100,
        },
      ],
    }),
    [],
  )

  return (
    <>
      <AdjustableTable<RoadmapInterface>
        name={TableNames.DepartmentRoadmap}
        row={roadmapRow}
        hideHeader={table.data?.length === 0}
        emptyState={<EmptyRoadmapsTable />}
        dataType="roadmap item"
        expandableType="chevron"
        onRowClick={showRoadmapDetails}
        {...table}
      />
      {renderSidebar({
        canEdit: entity
          ? !!entity.data.field_options.permissions?.includes(
              PermissionTypes.CanCreateRoadmaps,
            )
          : false,
      })}
    </>
  )
}
