import React, { useEffect, useState } from 'react'
import { ActionButton, MoreBar, Text, Token, VStack } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import {
  cancelImportDepartmentBudget,
  confirmImportDepartmentBudget,
  exportDepermentBudgetSessionData,
  getDepermentBudgetSessionData,
} from '@src/api/importData'
import { useTable } from '@components/TableV2/hooks'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { getBackUrl, goBack, navigateReplace } from '@src/actions/RouterActions'
import { DepartmentBudgetRouteProps, useDepartmentBudgetParams } from './common'
import {
  ImportDepartmentBudgetInterface,
  ImportDepartmentBudgetMetadata,
} from '@src/interfaces/importDepartmentBudget'
import { RowInterface } from '@src/interfaces/data'
import {
  importDepartmentBudgetBonusChangeColumn,
  importDepartmentBudgetCurrentSalaryColumn,
  importDepartmentBudgetEmployeeColumn,
  importDepartmentBudgetJustificationColumn,
  importDepartmentBudgetLocationColumn,
  importDepartmentBudgetNewBonusColumn,
  importDepartmentBudgetNewSalaryColumn,
  importDepartmentBudgetRoleColumn,
  importDepartmentBudgetSalaryBandColumn,
  importDepartmentBudgetSalaryChangeColumn,
  importDepartmentBudgetSalaryChangePercentColumn,
  importDepartmentBudgetSeniorityColumn,
} from '@src/constants/columns/importDepartmentBudget'
import { saveFileByBlob } from '@src/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import { formatMoney } from '@src/utils/format'
import { ImportSessionStatus } from '@src/interfaces/importData'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

const row: RowInterface<ImportDepartmentBudgetInterface> = {
  highlight: data => {
    return Object.keys(data.errors || {}).length ? Token.color.redActionBackground : ''
  },
  cells: [
    {
      ...importDepartmentBudgetEmployeeColumn,
      width: 180,
    },
    {
      ...importDepartmentBudgetSeniorityColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetRoleColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetLocationColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetCurrentSalaryColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetNewSalaryColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetSalaryChangeColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetSalaryChangePercentColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetSalaryBandColumn,
      width: 200,
    },
    {
      ...importDepartmentBudgetNewBonusColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetBonusChangeColumn,
      width: 120,
    },
    {
      ...importDepartmentBudgetJustificationColumn,
      width: 120,
    },
  ],
}

export const ReviewData = ({
  cycle,
  headcount,
  name,
  errors,
  bonusTotal,
  salaryTotal,
}: DepartmentBudgetRouteProps) => {
  const params = useDepartmentBudgetParams()
  const permissions = useSelector(selectPermissions)

  const [importPending, setImportPending] = useState(false)
  const [exportSessionDataPending, setExportSessionDataPending] = useState(false)
  const [cancelSessionPending, setCancelSessionPending] = useState(false)
  const [status, setStatus] = useState<ImportSessionStatus>()

  const table = useTable<
    ImportDepartmentBudgetInterface,
    undefined,
    ImportDepartmentBudgetMetadata
  >({
    getItems: getDepermentBudgetSessionData(params.id, params.sessionId),
  })

  useEffect(() => {
    if (table.metadata?.status.id) {
      setStatus(table.metadata.status.id)
    }
  }, [table.metadata?.status.id])

  const isValid = status === 'valid'
  const isInProgress = status === 'in_progress'
  const isCompleted = status === 'completed'
  const isFailed = status === 'failed'
  const showStatus = isInProgress || isCompleted || isFailed
  const canImport = permissions?.includes(
    PermissionTypes.AddEmployeeCompensationReviewBulkUploadSession,
  )
  const backUrl = ROUTES.ORGANISATION.TEAMS.DEPARTMENTS

  const onExecuteImport = async () => {
    try {
      setImportPending(true)
      const response = await confirmImportDepartmentBudget(params.id, params.sessionId)
      setStatus(response.data.status.id)
      table.refresh()
    } finally {
      setImportPending(false)
    }
  }

  const onExportSessionData = () => {
    setExportSessionDataPending(true)
    exportDepermentBudgetSessionData(params.id, params.sessionId)
      .then(response => {
        saveFileByBlob(
          response.data,
          'session_data.csv',
          response.headers['content-type'],
        )
      })
      .finally(() => setExportSessionDataPending(false))
  }

  const onCancelSession = () => {
    setCancelSessionPending(true)
    cancelImportDepartmentBudget(params.id, params.sessionId)
      .then(() => {
        navigateReplace(getBackUrl(backUrl).pathname)
      })
      .catch(() => {
        setCancelSessionPending(false)
      })
  }

  return (
    <PageWrapper>
      <PageHeader title="Import budget data" subtitle={name} backUrl={backUrl} />
      {showStatus ? (
        <>
          {isInProgress && (
            <SuccessWidget
              title="Task in progress"
              type="pending"
              text="Please wait for the data to upload, this can take several minutes. You can
 refresh this page to update the task status."
              maxWidth={720}
            />
          )}
          {isCompleted && (
            <SuccessWidget
              title="Upload successful"
              type="success"
              text={
                <VStack gap="s-16">
                  <Text>Budget upload is completed</Text>
                  <ActionButton onClick={() => goBack(backUrl)} useIcon="ArrowSend">
                    Go back to budget upload page
                  </ActionButton>
                </VStack>
              }
              maxWidth={720}
            />
          )}
          {isFailed && (
            <SuccessWidget
              title="Task failed"
              type="error"
              text="There was an error with the upload"
              maxWidth={720}
            />
          )}
        </>
      ) : (
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Cycle" val={cycle} />
            <Stat
              label="Total salaries budget"
              val={salaryTotal != null && formatMoney(salaryTotal, 'USD')}
            />
            <Stat
              label="Total bonus budget"
              val={bonusTotal != null && formatMoney(bonusTotal, 'USD')}
            />
            <Stat label="Headcount" val={headcount} />
            <Stat label="Records to import" val={table?.count} />
            <Stat label="Errors" val={errors} color={Token.color.danger} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <MoreBar.Action
                onClick={onExecuteImport}
                pending={importPending}
                disabled={!isValid || !canImport}
                useIcon="ShareIOs"
                variant="accent"
              >
                Execute import
              </MoreBar.Action>
              <MoreBar.Action
                onClick={onExportSessionData}
                pending={exportSessionDataPending}
                useIcon="Download"
              >
                Export table data
              </MoreBar.Action>
              <MoreBar.Action
                onClick={onCancelSession}
                pending={cancelSessionPending}
                useIcon="Cross"
                variant="negative"
              >
                Cancel
              </MoreBar.Action>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable
              name={TableNames.ImportDepartmentBudget}
              useWindowScroll
              dataType="Employee"
              row={row}
              {...table}
            />
          </Table.Widget.Table>
        </Table.Widget>
      )}
    </PageWrapper>
  )
}
