import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Spacer, Tag, Text, Token, VStack } from '@revolut/ui-kit'

interface Props {
  cycleName: string
}

export const MissingSummaryBanner = ({ cycleName }: Props) => (
  <Box p="s-16" border={`1px solid ${Token.color.greyTone10}`} borderRadius={16}>
    <VStack>
      <Text variant="primary">
        <FormattedMessage
          id="performance.summary.missing.cycle.title"
          defaultMessage="This employee has not been reviewed in selected cycle"
        />
      </Text>
      <Text variant="secondary" color={Token.color.greyTone50}>
        <FormattedMessage
          id="performance.summary.missing.cycle.description"
          values={{ name: cycleName }}
          defaultMessage="The data is taken from the latest published review in {name}"
        />
      </Text>
      <Spacer height="s-8" />
      <Tag variant="faded" useIcon="DocumentsPair">
        {cycleName}
      </Tag>
    </VStack>
  </Box>
)
