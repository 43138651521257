import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, Flex, HStack, Spacer, Tag, Text, Token, VStack } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import {
  IndividualJiraMetricsInterface,
  OrgUnitJiraMetricsInterface,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { MetricHighlight } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/MetricHighlight'
import { MetricsItemSkeleton } from '@src/features/AiInsights/Performance/components/Metrics/External/common/MetricsItemSkeleton'
import { MetricDetailsVariants } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'

interface Props {
  orgUnit: BaseOption<string>
  employeeMetrics: IndividualJiraMetricsInterface | null
  orgUnitMetrics: OrgUnitJiraMetricsInterface | null
  isLoading: boolean
}

export const JiraMetrics = ({
  orgUnit,
  employeeMetrics,
  orgUnitMetrics,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <MetricsItemSkeleton />
  }

  if (!employeeMetrics || !orgUnitMetrics) {
    return null
  }

  const {
    employee,
    tickets_closed: individualTicketsClosed,
    tickets_closed_link: ticketsClosedLink,
    bugs_closed: individualBugsClosed,
    bugs_closed_link: bugClosedLink,
    story_points: individualStoryPoints,
    cycle_participation: individualCycleParticipation,
  } = employeeMetrics

  const {
    tickets_closed: averageTicketsClosed,
    bugs_closed: averageBugsClosed,
    story_points: averageStoryPoints,
    cycle_participation: averageCycleParticipation,
  } = orgUnitMetrics

  return (
    <VStack>
      <HStack space="s-16" align="center" p="s-16">
        <Avatar image="https://assets.revolut.com/assets/apps/Jira.png" />
        <Text fontWeight={500}>
          <FormattedMessage id="integrations.jira.label" defaultMessage="Jira" />
        </Text>
        <Spacer />
        <Tag variant="outlined" color={Token.color.deepGrey} useIcon="Link">
          <FormattedMessage
            id="integrations.common.connected.label"
            defaultMessage="Connected"
          />
        </Tag>
      </HStack>
      <VStack space="s-8" px="s-16">
        <Flex gap="s-8" flexWrap="wrap">
          <MetricHighlight
            value={individualTicketsClosed ?? 0}
            average={averageTicketsClosed ?? 0}
            metricName="ticket"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            link={ticketsClosedLink ?? undefined}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.jira.tickets.closed.label"
                defaultMessage="Tickets Closed"
              />
            }
            flex={1}
          />
          <MetricHighlight
            value={individualBugsClosed ?? 0}
            average={averageBugsClosed ?? 0}
            metricName="bug"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            link={bugClosedLink ?? undefined}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.jira.bugs.closed.label"
                defaultMessage="Bug Fixes"
              />
            }
            flex={1}
          />
        </Flex>
        <Flex gap="s-8" flexWrap="wrap">
          <MetricHighlight
            value={individualStoryPoints ?? 0}
            average={averageStoryPoints ?? 0}
            metricName="story point"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.jira.story.points.label"
                defaultMessage="Story Points"
              />
            }
            flex={1}
          />
          <MetricHighlight
            value={individualCycleParticipation ?? 0}
            average={averageCycleParticipation ?? 0}
            metricName="cycle participation"
            employeeName={employee.name}
            orgUnitName={orgUnit.id}
            variant={MetricDetailsVariants.Percentage}
            dataLabel={
              <FormattedMessage
                id="performance.metrics.jira.cycle.participation.label"
                defaultMessage="Cycle Participation"
              />
            }
            flex={1}
          />
        </Flex>
      </VStack>
    </VStack>
  )
}
