import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  ActionButton,
  Item,
  Text,
  VStack,
  Token,
  HStack,
  Spacer,
  Avatar,
  Flex,
  Icon,
  Bullet,
} from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import { useGetSlackPreferences, useUpdatePreferences } from '@src/api/integrations'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { PermissionTypes, FeatureFlags } from '@src/store/auth/types'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { SlackLegacy } from '@src/pages/Hub/Integrations/Slack/SlackLegacy'
import { askSlackWorkspacePermissions } from '@src/api/officeSuiteProvider'
import { AxiosError } from 'axios'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface TitleProps {
  isConnected: boolean
  hideIcon?: boolean
}

const Title = ({ isConnected, hideIcon }: TitleProps) => (
  <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }}>
    <HStack align="start">
      <VStack>
        <Text>
          <FormattedMessage
            id="integrations.slack.title"
            defaultMessage="Slack integration"
          />
        </Text>
        {isConnected && (
          <Text variant="emphasis1" color={Token.color.success}>
            Connected
          </Text>
        )}
        <Text color={Token.color.greyTone50} variant="body2">
          Productivity
        </Text>
      </VStack>
      <Spacer />
      {hideIcon ? null : (
        <Avatar image="https://assets.revolut.com/assets/apps/Slack.png" size={56}>
          {isConnected ? <Avatar.Badge useIcon="Check" bg={Token.color.success} /> : null}
        </Avatar>
      )}
    </HStack>
  </VStack>
)

export const Slack = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const slackAppIntegrationEnabled = featureFlags.includes(
    FeatureFlags.SlackAppIntegration,
  )

  return slackAppIntegrationEnabled ? <SlackIntegration /> : <SlackLegacy />
}

export const SlackIntegration = () => {
  const { data: preferences, isLoading, refetch } = useGetSlackPreferences()
  const isOnboarding = isOnboardingPath()
  const updatePreferences = useUpdatePreferences()
  const permissions = useSelector(selectPermissions)
  const queryClient = useQueryClient()
  const showStatusPopup = useShowStatusPopup()

  const canEdit = permissions.includes(PermissionTypes.ChangeSlackIntegration)

  const botsUrl = isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.BOTS
    : ROUTES.FEATURES.INTEGRATION.SLACK.BOTS

  const isEnabled = Boolean(preferences?.enabled)

  const showFailureToConnectWorkspace = (error?: AxiosError) => {
    showStatusPopup({
      title: 'Failed to connect to Slack',
      description: getMessageFromApiError(error),
      status: 'error',
    })
  }

  const handleSuccessfulConnectionToWorkspace = () => {
    refetch()

    showStatusPopup({
      title: 'Connected to Slack successfully',
      status: 'success',
    })
  }

  window.showFailureToConnectWorkspace = error => {
    showFailureToConnectWorkspace(error)
  }

  window.handleSuccessfulConnectionToWorkspace = () => {
    handleSuccessfulConnectionToWorkspace()
  }

  const disconnect = async () => {
    if (!preferences?.id) {
      return
    }
    await updatePreferences.mutateAsync([
      preferences.id,
      { enabled: !preferences.enabled },
    ])

    // communication settings depend on slack integration
    queryClient.invalidateQueries(API.COMMUNICATION_SETTINGS)

    showStatusPopup({
      title: `Slack integration turned ${preferences.enabled ? 'off' : 'on'}`,
    })
  }

  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title isConnected={isEnabled} hideIcon />}
          backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        />
      ) : (
        <PageHeader
          title={<Title isConnected={isEnabled} />}
          backUrl={pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)}
        />
      )}

      {!isLoading && !isEnabled && (
        <>
          <ActionButton
            useIcon="Link"
            pending={updatePreferences.isLoading}
            aria-disabled={updatePreferences.isLoading || !canEdit}
            variant="accent"
            onClick={() => {
              if (updatePreferences.isLoading || !canEdit) {
                return
              }
              askSlackWorkspacePermissions('slackIntegration')
            }}
          >
            Enable
          </ActionButton>
        </>
      )}

      <PageBody gap="s-16" mt="s-16">
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.slack.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.slack.intro.text"
                defaultMessage="Add Revolut People app to your Slack workspace to receive notifications."
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>

        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.slack.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.slack.intro.tips.integrate.title"
                  defaultMessage="Install the Revolut People app to your Slack workspace"
                />
              ),
              icon: <Bullet variant="filled">1</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.slack.intro.tips.integrate.text"
                  defaultMessage="Click on the Enable button above to start the installation. This will need to be done by a Slack workspace Admin."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.slack.intro.tips.mapping.title"
                  defaultMessage="Stay on top with automatic notifications"
                />
              ),
              icon: <Bullet variant="filled">2</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.slack.intro.tips.mapping.text"
                  defaultMessage="Receive automated reminders on surveys and performance reviews, get notified on time off and much more."
                />
              ),
            },
          ]}
        />

        {!isLoading && isEnabled && (
          <Item color={Token.color.danger} use="button" onClick={disconnect}>
            <Item.Content>
              <Flex gap="s-16">
                <Icon name="Unlink" />
                <Item.Title>Disconnect</Item.Title>
              </Flex>
            </Item.Content>
          </Item>
        )}

        <Item use={InternalLink} to={pathToUrl(botsUrl)}>
          <Item.Content>
            <Flex gap="s-16">
              <Icon name="RepairTool" />
              <Item.Title>Manage custom bots</Item.Title>
            </Flex>
          </Item.Content>
        </Item>
      </PageBody>
    </PageWrapper>
  )
}
