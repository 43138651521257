import React from 'react'
import { Input, RadioSelect, useDropdown } from '@revolut/ui-kit'
import pluralize from 'pluralize'

const options = Array.from({ length: 30 }, (_, i) => ({
  key: i + 1,
  label: pluralize(`${i + 1} day`, i + 1),
  value: i + 1,
}))

interface Props {
  value: number | null
  onChange: (value: number | null) => void
  disabled: boolean
}

export const LatencyDaysSelect = ({ value, onChange, disabled }: Props) => {
  const dropdown = useDropdown()

  return (
    <>
      <Input
        disabled={disabled}
        type="button"
        {...dropdown.getAnchorProps()}
        useIcon={dropdown.state ? 'ChevronDown' : 'ChevronUp'}
        value={pluralize('day', value ?? 0, true)}
        aria-haspopup="listbox"
        data-testid="latency-days-select"
      />
      <RadioSelect
        disabled={disabled}
        {...dropdown.getTargetProps()}
        options={options}
        value={value}
        onChange={val => {
          if (val) {
            onChange(val)
            dropdown.toggle()
          }
        }}
        labelList="Latency Days"
        fitInAnchor
        searchable
      />
    </>
  )
}
