import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { employeesRequests } from '@src/api/employees'
import React, { useEffect, useState } from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { InputGroup } from '@revolut/ui-kit'
import AutoStepper from '@components/Stepper/AutoStepper'
import { selectorKeys } from '@src/constants/api'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const AdditionalSettings = () => {
  const { employeeId } = useParams<{ employeeId: any; id: any }>()

  const [employee, setEmployee] = useState<EmployeeInterface>()

  const backUrl = pathToUrl(
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY,
    {
      id: employeeId,
    },
  )

  useEffect(() => {
    const fetchEmployee = async () => {
      const { data } = await employeesRequests.getItem(employeeId)
      if (data) {
        setEmployee(data)
      }
    }

    fetchEmployee()
  }, [])

  const employeeName = employee?.full_name || ''
  const subtitle = `For ${employeeName} probation`

  return (
    <PageWrapper>
      <PageHeader title="Additional settings" subtitle={subtitle} backUrl={backUrl} />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            <LapeRadioSelectInput
              label="HR manager"
              name="hr_manager"
              selector={selectorKeys.probation_committee_hr_managers}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={backUrl}
          successText="Employee probation settings successfully updated."
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(AdditionalSettings)
