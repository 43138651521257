import React from 'react'
import { Avatar, AvatarProps, IconName } from '@revolut/ui-kit'

interface UploadAvatarPreviewProps {
  useIcon?: IconName | null
  avatar?: string | null
  defaultIcon?: IconName
  iconProps?: Omit<AvatarProps, 'useIcon'>
}

export const UploadAvatarPreview = ({
  useIcon,
  avatar,
  defaultIcon,
  ...iconProps
}: UploadAvatarPreviewProps) => {
  return (
    <Avatar
      useIcon={
        avatar == null && useIcon == null && defaultIcon
          ? defaultIcon
          : avatar
          ? null
          : useIcon
      }
      image={avatar}
      {...(iconProps || {})}
    />
  )
}
