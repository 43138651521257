import React from 'react'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { DepartmentSettingsFields, DepartmentSettingsSaveButton } from './common'

const DepartmentsSettings = () => {
  return (
    <>
      <PageBody>
        <DepartmentSettingsFields />
      </PageBody>

      <PageActions>
        <DepartmentSettingsSaveButton />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
    url: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: DepartmentsSettings,
  },
]

export const Departments = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Departments settings"
      subtitle="Settings that apply to all departments"
    />
  )
}
