import { ManagerRecommendationInterface } from '@src/interfaces/performance'
import { Text, Widget } from '@revolut/ui-kit'
import React from 'react'

export enum RecommendationTypes {
  LM = 'lm',
  FM = 'fm',
  PEER = 'peer',
  PIP = 'pip',
  PIP_V1 = 'pip_v1',
}

interface ListInterface {
  title: string
  items: {
    text: string
  }[]
}

export const getJustificationViewList = (reviews: ManagerRecommendationInterface[]) => {
  return reviews.reduce((ids: ListInterface[], review) => {
    if (review.employee_project_performance.justification) {
      const checkpointNum = review.checkpoint_number
      const title = review.reviewer?.full_name
        ? `${review.reviewer?.full_name}${checkpointNum ? ` (CP${checkpointNum})` : ''}`
        : ''
      ids.push({
        items: [
          {
            text: review.employee_project_performance.justification,
          },
        ],
        title,
      })
    }
    return ids
  }, [])
}

export const ScorecardRecommendationAlertBanner = () => {
  return (
    <Widget backgroundColor="red-action-background">
      <Text use="p" p="s-16" fontWeight={500} color="red">
        This is only a suggestion, and NOT the final grade the employee will get. This
        will be considered along with the scorecard rating as inputs into the overall
        grade.
      </Text>
    </Widget>
  )
}
