import React, { useState } from 'react'
import {
  BottomSheet,
  Header,
  VStack,
  Button,
  Text,
  Token,
  BottomSheetProps,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalContentType, GoalsInterface } from '@src/interfaces/goals'
import { useGoalFormSubmit } from '../../useGoalFormSubmit'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { createDraftGoal } from '@src/api/goals'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { capitalize } from 'lodash'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

const getOrganisationSubLevelContentTypes = (
  goal: GoalsInterface,
  contentTypes: GoalContentType[],
) => {
  if (goal.is_company) {
    return contentTypes.find(({ model }) => model === 'department')
  }
  switch (goal.content_type?.model) {
    case 'department':
      return contentTypes.find(({ model }) => model === 'teams')
    case 'teams':
    default:
      return contentTypes.find(({ model }) => model === 'employees')
  }
}

export const CreateChildGoalPopup = ({
  open,
  onClose,
}: Pick<BottomSheetProps, 'open' | 'onClose'>) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { submit } = useGoalFormSubmit()
  const confirmationDialog = useConfirmationDialog()
  const showErrorPopup = useErrorPopup()
  const isOnboarding = isOnboardingPath()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const [isCreatingGoal, setIsCreatingGoal] = useState(false)
  const { query } = useQuery<{ type?: string }>()

  const createChild = async (parentGoal: GoalsInterface) => {
    try {
      setIsCreatingGoal(true)
      const createEmployeeGoal = parentGoal.content_type?.model === 'teams'
      const childContentType = getOrganisationSubLevelContentTypes(
        parentGoal,
        contentTypes || [],
      )

      if (createEmployeeGoal) {
        const childGoal = await createDraftGoal({
          owner: parentGoal.owner,
          parent: {
            id: parentGoal.id,
            name: parentGoal.name,
          },
          content_type: childContentType,
          object_id: parentGoal.owner.id,
        })
        navigateReplace(
          pathToUrl(
            isOnboarding
              ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
              : ROUTES.FORMS.GOAL.EDIT,
            { id: childGoal.data.id },
          ),
          { isNew: true },
        )
      } else {
        const childGoal = await createDraftGoal({
          owner: parentGoal.owner,
          parent: {
            id: parentGoal.id,
            name: parentGoal.name,
          },
          content_type: childContentType,
        })
        navigateReplace(
          pathToUrl(
            isOnboarding
              ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.FORM
              : ROUTES.FORMS.GOAL.CREATE.FORM,
            { id: childGoal.data.id },
            { type: childContentType?.model || 'teams' },
          ),
        )
      }
    } catch (error) {
      showErrorPopup.show({
        error,
        fallbackTitle: 'Could not create child goal',
      })
    } finally {
      setIsCreatingGoal(false)
      onClose?.()
    }
  }

  const saveParent = async () => {
    try {
      const parentGoal = await submit('submit', { is_auto_cascaded_to_owner: false })
      confirmationDialog.show({
        label: `Goal "${parentGoal.name}" created`,
        preventUserClose: true,
        showCloseButton: false,
        body: '',
        loading: isCreatingGoal || isLoading,
        onConfirm: () => createChild(parentGoal),
        noReject: true,
        yesMessage: 'Create child goal',
      })
    } catch (error) {
      showErrorPopup.show({
        error,
        fallbackTitle: 'Could not save parent goal',
      })
    }
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>
          {values.approval_status.id === ApprovalStatuses.Draft
            ? 'Parent goal not saved'
            : 'Create child goal'}
        </Header.Title>
      </Header>
      <BottomSheet.Description>
        {values.approval_status.id === ApprovalStatuses.Draft
          ? "You're creating a child goal before saving the parent goal. To proceed, save the parent goal."
          : 'Are you sure?'}
      </BottomSheet.Description>
      <BottomSheet.Actions>
        <VStack>
          <Button
            onClick={() =>
              values.approval_status.id === ApprovalStatuses.Draft
                ? saveParent()
                : createChild(values)
            }
            disabled={!values.name || (!values.is_company && !values.content_object?.id)}
          >
            {values.approval_status.id === ApprovalStatuses.Draft
              ? 'Save parent goal and continue'
              : 'Continue'}
          </Button>
          {!values.name && (
            <Text variant="small" pl="s-24" color={Token.color.danger}>
              Goal name is required
            </Text>
          )}
          {!values.is_company && !values.content_object?.id && (
            <Text variant="small" pl="s-24" color={Token.color.danger}>
              {capitalize(query.type) || 'Organisational unit'} is required
            </Text>
          )}
        </VStack>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
