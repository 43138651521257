import React, { useEffect, useRef, useState } from 'react'
import { Box, Dots, IconButton, Input, ItemSkeleton, Token } from '@revolut/ui-kit'

import {
  useCreateEngagementAnswerReply,
  useGetEngagementAnswerReplies,
} from '@src/api/engagement'
import { ReplyWidget } from './ReplyWidget'

interface Props {
  answerId: string | number
  renderInputWrapper?: (input: React.ReactNode) => React.ReactNode
  bottomGap?: number
}
export const RepliesWidget = ({
  answerId,
  renderInputWrapper = input => <>{input}</>,
  bottomGap = 0,
}: Props) => {
  const [replyInputValue, setReplyInputValue] = useState<string>('')

  const commentsBottomRef = useRef<HTMLDivElement>(null)

  const { mutateAsync: sendReply, isLoading: isLoadingSendReply } =
    useCreateEngagementAnswerReply(answerId)

  useEffect(() => {
    commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [isLoadingSendReply, commentsBottomRef])

  const {
    data: repliesData,
    isLoading: isLoadingReplies,
    isRefetching: isRefetchingReplies,
    refetch: refetchReplies,
  } = useGetEngagementAnswerReplies(answerId)
  const replies = repliesData?.results || []

  const handleSendReply = async () => {
    if (replyInputValue) {
      setReplyInputValue('')
      await sendReply({ text: replyInputValue })
      await refetchReplies()
      commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (!replies.length && isLoadingReplies) {
    return (
      <>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </>
    )
  }

  return (
    <>
      {replies.map(reply => (
        <ReplyWidget key={reply.id} employee={reply.employee} answerText={reply.text} />
      ))}
      {(isLoadingSendReply || isRefetchingReplies) && (
        <Dots ml="s-16" color={Token.color.greyTone50} duration={900} />
      )}
      <Box ref={commentsBottomRef} mb={bottomGap} />
      {renderInputWrapper(
        <Input
          label="Reply"
          value={replyInputValue}
          onChange={e => setReplyInputValue(e.currentTarget.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              await handleSendReply()
            }
          }}
          renderAction={() => {
            const disabled = isLoadingReplies || isLoadingSendReply

            return (
              <IconButton
                aria-label="Send reply"
                color={disabled ? Token.color.greyTone20 : Token.color.blue}
                disabled={isLoadingSendReply}
                useIcon="SendMessage"
                onClick={handleSendReply}
              />
            )
          }}
        />,
      )}
    </>
  )
}
