import React from 'react'
import { API } from '@src/constants/api'
import {
  importBandsBenchmarkTypeColumn,
  importBandsCurrencyISOCodeColumn,
  importBandsLocationColumn,
  importBandsLowerBandColumn,
  importBandsOnTargetBonusColumn,
  importBandsSeniorityColumn,
  importBandsSeniorityLevelColumn,
  importBandsSpecialisationColumn,
  importBandsUpperBandColumn,
} from '@src/constants/columns/importBands'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportBandsInterface } from '@src/interfaces/importBands'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportBandsInterface>> => ({
  cells: [
    {
      ...importBandsSpecialisationColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsSeniorityColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsSeniorityLevelColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsLocationColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsBenchmarkTypeColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsCurrencyISOCodeColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsLowerBandColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsUpperBandColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsOnTargetBonusColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportBands = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.BENCHMARKS_BULK_UPLOAD}
      apiVersion="v2"
      anyRoute={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.UPLOAD_FILE}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.REVIEW_DATA}
      category="benchmarks_bulk_upload"
      entity="band"
      name="band"
      row={row}
      tableName={TableNames.UploadBands}
      header={<PageHeader backUrl={ROUTES.MAIN} title="Import bands" />}
      hiddenColumns={{ select: true }}
    />
  )
}
