import React, { useEffect, useState } from 'react'
import { BottomSheet, Button, InputGroup, StatusPopup } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import {
  MergeTeamsPayloadInterface,
  MergeTeamsResponseInterface,
} from '@src/interfaces/updateOrganisationStructure'
import {
  useGetMergeTeamsStatus,
  useMergeTeams,
} from '@src/api/updateOrganisationStructure'
import { selectUser } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface MergeTeamsProps {
  unitsToMerge: { id: number }[]
  onSuccess: (data: MergeTeamsResponseInterface) => void
  showMissionField: boolean
  setCurrentUserAsOwner: boolean
}

export const MergeTeams = ({
  unitsToMerge,
  onSuccess,
  showMissionField,
  setCurrentUserAsOwner,
}: MergeTeamsProps) => {
  const isAsyncMergeEnabled = useHasFeatureFlag(FeatureFlags.AsyncTeamMergeEnabled)
  const [taskId, setTaskId] = useState<string>()
  const [asyncLoading, setAsyncLoading] = useState(false)
  const { data: mergeTeamsResult } = useGetMergeTeamsStatus(taskId)
  const { mutateAsync: mergeTeamsAsync } = useMergeTeams()
  const [formValues, setFormValues] = useState<MergeTeamsPayloadInterface>()
  const showStatusPopup = useShowStatusPopup()

  const isPending = (status: string) =>
    ['PENDING', 'RECEIVED', 'STARTED', 'RETRY'].includes(status)
  const isError = (status: string) =>
    ['FAILURE', 'REVOKED', 'REJECTED', 'IGNORED'].includes(status)

  const handleAsyncMergeTeams = async (values: MergeTeamsPayloadInterface) => {
    try {
      setAsyncLoading(true)
      mergeTeamsAsync({ ...values, teams: unitsToMerge }).then(response => {
        if (response.data.status === 'SUCCESS') {
          onSuccess(response.data)
          return
        }

        handleResponse(response.data)
      })
    } catch (error) {
      setAsyncLoading(false)
      showStatusPopup({
        title: 'Teams merge failed',
        description: getMessageFromApiError(error),
        status: 'error',
      })
    }
  }

  const showErrorPopup = () => {
    showStatusPopup({
      status: 'error',
      title: 'Teams merge failed',
      description: 'Your teams were not merged. Please try again.',
      actions: statusPopup => (
        <StatusPopup.Actions>
          <Button
            elevated
            onClick={() => {
              statusPopup.hide()
              if (formValues) {
                handleAsyncMergeTeams(formValues)
              }
            }}
          >
            Merge teams
          </Button>
        </StatusPopup.Actions>
      ),
    })
  }

  useEffect(() => {
    if (mergeTeamsResult && mergeTeamsResult.status === 'SUCCESS') {
      onSuccess(mergeTeamsResult)
      setAsyncLoading(false)
    }
    if (mergeTeamsResult?.status && isError(mergeTeamsResult.status)) {
      showErrorPopup()
      setAsyncLoading(false)
    }
  }, [mergeTeamsResult])

  const handleResponse = (data: MergeTeamsResponseInterface) => {
    if (data.status && isPending(data.status) && data.task_id) {
      setTaskId(data.task_id)
    } else {
      showErrorPopup()
    }
  }

  const onSubmit = async (form: LapeFormInterface<MergeTeamsPayloadInterface>) => {
    if (isAsyncMergeEnabled) {
      setFormValues(form.values)
      await handleAsyncMergeTeams(form.values)
      return form.values
    }
    const response = await mergeTeamsAsync({ ...form.values, teams: unitsToMerge })
    onSuccess(response.data)
    return form.values
  }

  return (
    <LapeForm<MergeTeamsPayloadInterface> onSubmit={form => onSubmit(form)}>
      <FormValidatorProvider>
        <MergeTeamsForm
          asyncLoading={asyncLoading}
          showMissionField={showMissionField}
          setCurrentUserAsOwner={setCurrentUserAsOwner}
        />
      </FormValidatorProvider>
    </LapeForm>
  )
}

interface MergeTeamsFormProps {
  showMissionField: boolean
  setCurrentUserAsOwner: boolean
  asyncLoading: boolean
}

const MergeTeamsForm = ({
  showMissionField,
  setCurrentUserAsOwner,
  asyncLoading,
}: MergeTeamsFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<MergeTeamsPayloadInterface>()
  const { validate } = useSafeFormValidator()

  const user = useSelector(selectUser)

  useEffect(() => {
    if (setCurrentUserAsOwner) {
      values.team_owner = { id: user.id, name: user.full_name }
    }
  }, [setCurrentUserAsOwner])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="New team name" required />
        <LapeRadioSelectInput
          name="team_owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
        {showMissionField && (
          <LapeNewTextArea
            name="mission"
            label="Mission"
            description="A short summary explaining the goals and the scope of the team."
            rows={2}
            required
          />
        )}
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(() => submit())}
          pending={isSubmitting || asyncLoading}
          elevated
        >
          Save
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
