import React from 'react'
import { Text, Subheader, Token, SubheaderProps } from '@revolut/ui-kit'

interface Props extends SubheaderProps {
  description: React.ReactNode
}

export const SubheaderWithDescription = ({ description, ...props }: Props) => {
  return (
    <>
      <Subheader style={{ paddingBottom: Token.space.s4 }} {...props} />

      <Text
        variant="caption"
        color={Token.color.greyTone50}
        paddingBottom="s-12"
        display="inline-block"
      >
        {description}
      </Text>
    </>
  )
}
