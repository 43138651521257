import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import {
  Avatar,
  Group,
  Icon,
  IconName,
  Item,
  Tag,
  Text,
  Token,
  TransitionCollapse,
  useToggle,
  Flex,
} from '@revolut/ui-kit'
import { GoalsInterface, OrganisationalUnit } from '@src/interfaces/goals'
import { EntityTypes } from '@src/constants/api'
import {
  COMPANY_DEFAULT_ICON,
  DEPARTMENT_DEFAULT_ICON,
  EMPLOYEE_DEFAULT_ICON,
  FUNCTION_DEFAULT_ICON,
  TEAM_DEFAULT_ICON,
} from '@src/constants/common'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  singleTargetAdditionalColumns,
} from '@src/constants/columns/goals'
import { useGetGoalUrl } from '../common/useGetGoalUrl'
import { useTable } from '@src/components/Table/hooks'
import { goalsListTableRequests } from '@src/api/goals'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Statuses } from '@src/interfaces'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import { RowHeight } from '@src/interfaces/table'
import { LocalStorageKeys } from '@src/store/auth/types'
import { toIdAndName } from '@src/utils/toIdAndName'

const HEADERS_OFFSET = 42
const TABLE_MIN_HEIGHT = 200

const TreeList = styled.ol`
  & > li:before {
    content: none; // Do not show the tree structure for top level
  }
  & > li:after {
    content: none !important; // Do not show the tree structure for top level
  }
`

const ChildList = styled.ol`
  padding-top: 1rem;
  margin-left: 52px;
  position: relative;

  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 20px;
    height: 48px;
    transform: translate(-26px, calc(-100% + 54px));
    z-index: 0;
    border-left: 2px solid ${Token.color.greyTone10};
    border-bottom: 2px solid ${Token.color.greyTone10};
    position: absolute;
    border-bottom-left-radius: ${Token.radius.r16};
  }

  :not(:last-child) {
    &:after {
      content: '';
      top: 0;
      left: 0;
      width: 20px;
      height: calc(100% - 40px);
      transform: translate(-26px, 46px);
      z-index: 0;
      border-left: 2px solid ${Token.color.greyTone10};
      position: absolute;
    }
  }
`

const ListItem = styled.li`
  list-style-type: none;

  :not(:first-child) {
    padding-top: 1rem;
  }
`
interface Props {
  isOpen: boolean
  goal: OrganisationalUnit
  onClick?: VoidFunction
}

const mapEntityToAvatar: Record<EntityTypes, IconName> = {
  [EntityTypes.company]: COMPANY_DEFAULT_ICON,
  [EntityTypes.companyV2]: COMPANY_DEFAULT_ICON,
  [EntityTypes.department]: DEPARTMENT_DEFAULT_ICON,
  [EntityTypes.team]: TEAM_DEFAULT_ICON,
  [EntityTypes.function]: FUNCTION_DEFAULT_ICON,
  [EntityTypes.employee]: EMPLOYEE_DEFAULT_ICON,
  [EntityTypes.role]: FUNCTION_DEFAULT_ICON,
  [EntityTypes.specialisation]: FUNCTION_DEFAULT_ICON,
  [EntityTypes.teams]: TEAM_DEFAULT_ICON,
  [EntityTypes.employees]: EMPLOYEE_DEFAULT_ICON,
  [EntityTypes.roles]: FUNCTION_DEFAULT_ICON,
  [EntityTypes.specialisations]: FUNCTION_DEFAULT_ICON,
  [EntityTypes.functions]: FUNCTION_DEFAULT_ICON,
}

const Title = ({ isOpen, goal, onClick }: Props) => {
  return (
    <Item use="button" onClick={onClick}>
      <Item.Prefix>
        <Icon name={isOpen ? 'ChevronDown' : 'ChevronRight'} />
      </Item.Prefix>
      <Item.Avatar>
        <Avatar
          {...(goal.avatar
            ? { image: goal.avatar }
            : { useIcon: goal.icon || mapEntityToAvatar[goal.content_type_model] })}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <Flex alignItems="center" gap="s-16">
            <Text>{goal.name}</Text>
            {goal.is_owner && (
              <Tag
                useIcon="Profile"
                variant="faded"
                color={Token.color.deepGrey}
                bg={Token.color.deepGrey_20}
              >
                Owner
              </Tag>
            )}
          </Flex>
        </Item.Title>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <ColoredPercent percent={goal.overall_progress * 100} />
        </Item.Value>
        <Item.Value variant="secondary">Overall progress</Item.Value>
      </Item.Side>
    </Item>
  )
}

const ChildGroup = ({
  unit,
  defaultOpen,
}: {
  unit: OrganisationalUnit
  defaultOpen?: boolean
}) => {
  const [isOpen, toggle] = useToggle({ defaultState: defaultOpen })
  const getGoalUrl = useGetGoalUrl()
  const { query } = useQuery<{ cycle__id: string }>()
  const rowHeight: RowHeight = useMemo(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)

    if (localStorageRowHeight === 'large') {
      return 'large'
    }
    return 'medium'
  }, [])

  const table = useTable(
    goalsListTableRequests,
    [
      {
        columnName: 'cycle__id',
        filters: query.cycle__id ? [toIdAndName(query.cycle__id)] : [],
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          toIdAndName(Statuses.pending),
          toIdAndName(Statuses.approved),
          toIdAndName(Statuses.requires_changes),
        ],
      },
      {
        columnName: 'content_type_id',
        filters: [toIdAndName(String(unit.content_type_id))],
        nonResettable: true,
        nonInheritable: true,
      },
      {
        columnName: 'object_id',
        filters: [toIdAndName(String(unit.id))],
        nonResettable: true,
        nonInheritable: true,
      },
    ],
    [{ sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC }],
    { parentIdFilterKey: 'parent_id', childrenFetchOmitFilterKeys: ['object_id'] },
  )

  useEffect(() => {
    if (query.cycle__id) {
      table.onFilterChange({
        columnName: 'cycle__id',
        filters: [toIdAndName(query.cycle__id)],
      })
    }
  }, [query.cycle__id])

  const row: RowInterface<GoalsInterface> = useMemo(() => {
    return {
      cells: [
        {
          ...goalsNameColumn(goal => getGoalUrl(goal, [])),
          width: 300,
        },
        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsWeightColumn,
          width: 92,
        },
        ...singleTargetAdditionalColumns,
        { ...goalsProgressColumn, width: 160 },
        {
          ...goalsChangeStatusColumn(
            (status, goalId) =>
              table.updateRows(
                rowItem => rowItem.id === goalId,
                rowItem => ({ ...rowItem, status }),
              ),
            false,
          ),
          width: 100,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 70,
        },
      ],
    }
  }, [])
  return (
    <ListItem>
      <Group>
        <Title isOpen={isOpen} goal={unit} onClick={toggle.switch} />
        <TransitionCollapse in={isOpen}>
          <Flex
            style={{ position: 'relative', fontWeight: 400 }}
            minHeight={
              table.count
                ? table.count * rowWrapperMinHeight[rowHeight] +
                  (!table.count ? 0 : HEADERS_OFFSET)
                : TABLE_MIN_HEIGHT
            }
            flex="1 0"
          >
            <AdjustableTable
              emptyState={<EmptyTableRaw title={`No goals set for ${unit.name}`} />}
              row={row}
              hideCount
              hideHeader={!table.data.length}
              disabledFiltering
              name={TableNames.OrganisationalGoals}
              expandableType="chevron"
              {...table}
            />
          </Flex>
        </TransitionCollapse>
      </Group>
      {unit.children.map(subUnit => (
        <ChildList key={subUnit.id}>
          <ChildGroup unit={subUnit} defaultOpen={false} />
        </ChildList>
      ))}
    </ListItem>
  )
}

export const CollapsableGoalTree = ({ units }: { units?: OrganisationalUnit[] }) => {
  return (
    <TreeList>
      {units?.map(unit => (
        <ChildGroup
          key={unit.id}
          unit={unit}
          defaultOpen={units.length === 1 && !unit.children}
        />
      ))}
    </TreeList>
  )
}
