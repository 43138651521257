import React, { useState } from 'react'
import { VStack } from '@revolut/ui-kit'
import { Country, GradeOption } from '@src/interfaces/selectors'
import {
  PerformanceReviewAnalyticsGroupTypes,
  PerformanceReviewAnalyticsInterface,
} from '@src/interfaces/performance'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import {
  Row,
  RowSkeleton,
} from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/Row'
import { Legend } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/Legend'

interface Props {
  data: PerformanceReviewAnalyticsInterface[]
  grades: GradeOption[]
  countries: Country[]
  isLoading: boolean
}

export const GradeDistributionBarChart = ({
  data,
  grades,
  countries,
  isLoading,
}: Props) => {
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number>(-1)

  if (isLoading) {
    return <GradeDistributionBarChartSkeleton />
  }

  return (
    <VStack space="s-16" width="100%">
      {data.map((datum, rowIndex) => {
        const labelImage =
          datum.group.type === PerformanceReviewAnalyticsGroupTypes.Country
            ? getCountryFlagImageByCode(
                countries.find(({ id }) => id === datum.group.id)?.country_code,
              )
            : undefined

        return (
          <Row
            key={datum.group.id}
            data={datum}
            grades={grades}
            isHighlighted={rowIndex === highlightedRowIndex}
            labelImage={labelImage}
            onMouseEnter={() => setHighlightedRowIndex(rowIndex)}
            onMouseLeave={() => setHighlightedRowIndex(-1)}
          />
        )
      })}
      <Legend items={grades} />
    </VStack>
  )
}

const GradeDistributionBarChartSkeleton = () => (
  <VStack space="s-16" width="100%">
    <RowSkeleton />
    <RowSkeleton />
    <RowSkeleton />
    <RowSkeleton />
    <RowSkeleton />
  </VStack>
)
