import React, { useState } from 'react'
import Form from '@src/features/Form/Form'
import Preview from '@src/pages/Forms/JobPosting/Preview/Preview'
import ApplicationForm from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationForm'
import { Automation } from '@src/pages/Forms/JobPosting/Automation/Automation'
import { jobDescriptionFormRequest } from '@src/api/jobPosting'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Token, Box, Flex, Icon } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Route, Switch, useParams } from 'react-router-dom'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { CommonRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { Subtitle, SubtitleLink } from '@src/components/Page/Header/PageHeaderTitle'
import StatusTag from '@src/pages/Forms/JobPosting/Components/StatusTag'
import TextWithMoreCount from '@src/components/TextWithMoreCount/TextWithMoreCount'
import JobPostingActions from './Components/JobPostingActions'
import { LinkedInTable } from '@src/pages/Forms/JobPosting/LinkedInPostings/LinkedInTable'
import { Candidates } from '@src/pages/Forms/JobPosting/Candidates/Candidates'
import { BookingLinks } from '@src/pages/Forms/JobPosting/BookingLinks/BookingLinks'
import { useGetSpecialisation } from '@src/api/specialisations'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'
import { IndeedTable } from '@src/pages/Forms/JobPosting/IndeedPostings/IndeedTable'

const JobDescriptionTabs = connect(() => {
  const permissions = useSelector(selectPermissions)
  const form = useLapeContext<JobPostingInterface>()
  const { values } = form
  const { data: specialisation } = useGetSpecialisation(values.specialisation?.id)
  const [isApproving, setIsApproving] = useState(false)

  const params = useParams<{ id: string; specId: string }>()

  const backUrl = params.specId
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, { id: params.specId })
    : pathToUrl(ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS)

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.JOB_POSTING.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params),
      component: Preview,
      canView: !!values?.id,
      icon: <Icon name="InfoOutline" size={15} />,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.JOB_POSTING.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.REQUISITIONS, params),
      component: () => (
        <CommonRequisitionTable
          type="jobPosting"
          sortBy={[
            {
              sortBy: 'status',
              direction: SORT_DIRECTION.ASC,
            },
          ]}
          filterBy={[
            {
              filters: initialRequisitionStatusFilter,
              columnName: 'status',
              nonResettable: true,
            },
            {
              columnName: 'requisition_posting__job_posting',
              filters: [{ id: values.id, name: String(values.name) }],
              nonResettable: true,
            },
          ]}
          newItemInitialValues={
            specialisation
              ? {
                  specialisation: {
                    id: specialisation.id,
                    name: specialisation.name,
                    role: specialisation.role,
                    icon: null,
                  },
                }
              : undefined
          }
          jobPostingId={values?.id}
        />
      ),
      canView: !!values?.id && permissions.includes(PermissionTypes.ViewRequisitions),
      icon: <Icon name="AddContact" size={15} />,
    },
    {
      title: 'Candidates',
      path: ROUTES.FORMS.JOB_POSTING.CANDIDATES,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.CANDIDATES, params),
      component: Candidates,
      icon: <Icon name="Profile" size={15} />,
    },
  ]

  const filteredTabs = tabs.filter(tab =>
    tab.canView === undefined ? true : tab.canView,
  )

  const locationNames = (values.locations ?? []).map(({ location_name }) => location_name)

  return (
    <PageWrapper>
      <PageHeader
        title={
          <Box maxWidth={Token.breakpoint.xl}>
            <PageHeader.Title
              actions={<JobPostingActions onApproving={setIsApproving} />}
              avatar={
                values.specialisation ? (
                  <EntityAvatar
                    data={{
                      id: +values.specialisation.id,
                      icon: values.specialisation.icon,
                      avatar: values.specialisation.avatar,
                    }}
                    defaultIcon={FUNCTION_DEFAULT_ICON}
                    canEdit={false}
                  />
                ) : undefined
              }
              labels={
                <>
                  <StatusTag />
                  {values.recruiter && (
                    <SubtitleLink
                      iconName="Profile"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: values.recruiter?.id,
                      })}
                    >
                      {values.recruiter?.name}
                    </SubtitleLink>
                  )}
                  {values.specialisation && (
                    <SubtitleLink
                      iconName="RepairTool"
                      to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                        id: values.specialisation.id,
                      })}
                    >
                      {values.specialisation.name}
                    </SubtitleLink>
                  )}

                  {!!locationNames.length && (
                    <Subtitle iconName="LocationPin">
                      <TextWithMoreCount
                        expandable
                        hideSuffix
                        items={locationNames}
                        visibleCount={3}
                      />
                    </Subtitle>
                  )}
                </>
              }
              title={values.name}
            />
          </Box>
        }
        backUrl={backUrl}
      >
        <Box pb="s-8" maxWidth={Token.breakpoint.xl}>
          <TabBarNavigation tabs={filteredTabs} />
        </Box>
      </PageHeader>
      <Flex
        pt="s-8"
        flex="1 0"
        flexDirection="column"
        width="100%"
        maxWidth={Token.breakpoint.xl}
      >
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component isApproving={isApproving} />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
})

const JobDescriptionPage = () => {
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const permissions = useSelector(selectPermissions)
  const canViewLinkedInPostings = permissions.includes(
    PermissionTypes.ViewLinkedInApiPosting,
  )
  const featureFlags = useSelector(selectFeatureFlags)
  const linkedinIntegration = featureFlags.includes(FeatureFlags.LinkedinIntegration)
  const indeedIntegration = featureFlags.includes(FeatureFlags.IndeedIntegration)
  return (
    <Form api={jobDescriptionFormRequest} validator={{}}>
      <Switch>
        <Route exact path={ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM}>
          <ApplicationForm />
        </Route>
        <Route exact path={ROUTES.FORMS.JOB_POSTING.AUTOMATION}>
          <Automation />
        </Route>
        <Route exact path={ROUTES.FORMS.JOB_POSTING.BOOKING_LINKS}>
          <BookingLinks />
        </Route>
        {jobPostingSettings?.enable_linkedin_integration &&
          canViewLinkedInPostings &&
          linkedinIntegration && (
            <Route exact path={ROUTES.FORMS.JOB_POSTING.LINKEDIN_POSTINGS}>
              <LinkedInTable />
            </Route>
          )}
        {jobPostingSettings?.indeed_connected && indeedIntegration && (
          <Route exact path={ROUTES.FORMS.JOB_POSTING.INDEED_POSTINGS}>
            <IndeedTable />
          </Route>
        )}
        <JobDescriptionTabs />
      </Switch>
    </Form>
  )
}

export default connect(JobDescriptionPage)
