import React from 'react'
import { Box, Flex, Text } from '@revolut/ui-kit'
import { getTimeZoneLabel } from '@src/utils/timezones'
import { DaySlotGroupItemInterface } from '@src/pages/Forms/Candidate/ScheduleSidebar/DaySlotsGroup/DaySlotsGroup'
import { MultipleEmployees } from '@components/ColumnInserts/MultipleEmployees/MultipleEmployees'

interface Props {
  day: string
  items: DaySlotGroupItemInterface[]
  timeZone: string
}

const DayTimeAvatar = ({ day, items, timeZone }: Props) => {
  return (
    <Box width="100%" aria-label={`day-${day}`}>
      <Text fontWeight={500} fontSize="primary" use="div" pb="s-8">
        {day}
      </Text>
      {items.map(slot => (
        <Flex
          key={slot.event_start_datetime}
          alignItems="center"
          justifyContent="space-between"
          py="s-2"
        >
          <Text use="div">{getTimeZoneLabel(slot.event_start_datetime, timeZone)}</Text>
          <MultipleEmployees employees={slot.interviewers} hideName maxEmployees={10} />
        </Flex>
      ))}
    </Box>
  )
}

export default DayTimeAvatar
