import React from 'react'
import {
  EpicOption,
  KPIEpicsTargetRow,
  KpiTargetEpics,
  KpiTargets,
} from '@src/interfaces/kpis'
import { Flex, TextButton } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'

export interface KpiTargetActionsOptions {
  allTargets: KpiTargets[] | KpiTargetEpics[]
  onDeleteEpic?: (targetIndex: number, epicIndex: number) => void
  onView?: (targetIndex: number) => void
}

export interface KPITargetActionsProps {
  parentIndexes: number[]
  rowData: KPIEpicsTargetRow | KpiTargets | EpicOption
  options: KpiTargetActionsOptions
}

const KPITargetActions = ({ parentIndexes, rowData, options }: KPITargetActionsProps) => {
  const { disabled } = useLapeContext()

  const { allTargets, onView, onDeleteEpic } = options
  const targetIndex = parentIndexes[0]

  if ('url' in rowData && parentIndexes.length > 1 && !disabled) {
    const reviewCycle = allTargets[targetIndex].review_cycle

    return reviewCycle?.status !== ReviewCycleStatus.closed ? (
      <Flex>
        <TextButton
          onClick={e => {
            onDeleteEpic?.(targetIndex, parentIndexes[1])
            e.stopPropagation()
          }}
          color="red"
        >
          Delete
        </TextButton>
      </Flex>
    ) : null
  }

  const canEdit = rowData.review_cycle?.status !== 'closed' && !disabled

  return (
    <>
      <Flex>
        {onView && !canEdit && (
          <TextButton
            mr="10px"
            color="blue"
            fontWeight={500}
            onClick={e => {
              onView(targetIndex)
              e.stopPropagation()
            }}
          >
            View
          </TextButton>
        )}
      </Flex>
    </>
  )
}

export default KPITargetActions
