import React from 'react'
import { Avatar, Box, Item, MoreBar } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { OrganisationStructure } from '@src/pages/Settings/Structures/types'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { DeleteButton } from '@src/features/SettingsButtons'
import { deleteStructure } from '../api'
import { PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const StructurePreview = () => {
  const { values } = useLapeContext<OrganisationStructure>()

  const canEdit = useHasGlobalPermission(PermissionTypes.ChangeOrganisationPreferences)

  return (
    <>
      <PageHeader
        pb="s-8"
        title={`${values.name} structure`}
        backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
      />

      <PageBody>
        <Box mb="s-32">
          <MoreBar>
            {canEdit && (
              <MoreBar.Action
                useIcon="Pencil"
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.STRUCTURE.GENERAL, { id: values.id })}
              >
                Edit details
              </MoreBar.Action>
            )}

            <DeleteButton
              title="structure"
              deleteApi={() => deleteStructure(values.id)}
              backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
              globalPermissions={[PermissionTypes.ChangeOrganisationPreferences]}
              dialogProps={{
                label: 'Delete structure',
                body: 'Please confirm that you want to delete this structure. This operation is final and cannot be undone.',
                noMessage: 'Cancel',
                yesBtnVariant: 'negative',
                yesMessage: 'Delete',
              }}
            />
          </MoreBar>
        </Box>

        <Item>
          <Item.Avatar>
            <Avatar useIcon="QuestionOutline" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>No structural levels yet</Item.Title>
            <Item.Description>
              Add a structural level to define the hierarchy and organise this space
              effectively. Structural levels help establish clear roles, responsibilities,
              and reporting relationships within your organisational structure.
            </Item.Description>
          </Item.Content>
        </Item>
      </PageBody>
    </>
  )
}
