import React, { useEffect, useState } from 'react'
import SectionTitle from '../components/SectionTitle'
import { Message } from '../components/Message'
import { TimelinePreviewSectionInterface, WithTimelinePreview } from './common'
import {
  DataPoint,
  ProgressStepState,
  Spacer,
  Token,
  VStack,
  chain,
} from '@revolut/ui-kit'
import {
  reviewCyclesEndDateColumn,
  reviewCyclesNameColumn,
  reviewCyclesStartDateColumn,
  reviewCyclesStatusColumn,
} from '@src/constants/columns/reviewCycles'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { ReviewCycleCategory, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { TableNames } from '@src/constants/table'
import { ColumnInterface, RowInterface } from '@src/interfaces/data'
import { CYCLE_DURATION, PerformanceSettingsInterface } from '@src/interfaces/settings'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import Table from '@src/components/TableV2/Table'

const getCycleReference = (year: number) => ({
  quarterly: [
    {
      start_date_time: new Date(`01/01/${year}`),
      end_date_time: new Date(`03/31/${year}`),
      timeline: {
        goalSetting: `9 Dec - 1 Jan ${year + 1} (23 days)`,
        assestment: `20 Mar - 5 Apr ${year + 1} (17 days)`,
        calibration: `3 Apr - 7 Apr ${year + 1} (5 days)`,
        publishToManagers: `11 Apr ${year + 1}`,
        publish: `18 Apr ${year + 1}`,
      },
    },
    {
      start_date_time: new Date(`04/01/${year}`),
      end_date_time: new Date(`06/30/${year}`),
      timeline: {
        goalSetting: `1 Apr - 30 Apr ${year} (30 days)`,
        assestment: `20 Jun - 5 Jul ${year} (17 days)`,
        calibration: `3 Jul - 7 Jul ${year} (5 days)`,
        publishToManagers: `11 Jul ${year}`,
        publish: `18 Jul ${year + 1}`,
      },
    },
    {
      start_date_time: new Date(`07/01/${year}`),
      end_date_time: new Date(`09/30/${year}`),
      timeline: {
        goalSetting: `1 Jul - 30 Sep ${year} (30 days)`,
        assestment: `20 Sep - 5 Oct ${year} (17 days)`,
        calibration: `3 Oct - 7 Oct ${year} (5 days)`,
        publishToManagers: `11 Oct ${year}`,
        publish: `18 Oct ${year + 1}`,
      },
    },
    {
      start_date_time: new Date(`10/01/${year}`),
      end_date_time: new Date(`12/31/${year}`),
      timeline: {
        goalSetting: `1 Oct - 31 Oct ${year} (31 days)`,
        assestment: `20 Nov - 5 Dec ${year} (17 days)`,
        calibration: `3 Dec - 7 Dec ${year} (5 days)`,
        publishToManagers: `11 Dec ${year}`,
        publish: `18 Dec ${year + 1}`,
      },
    },
  ],
  biannually: [
    {
      start_date_time: new Date(`01/01/${year}`),
      end_date_time: new Date(`06/30/${year}`),
      timeline: {
        goalSetting: `11 Dec ${year - 1} - 1 Jan ${year} (21 days)`,
        assestment: `17 Jun - 5 Jul ${year} (18 days)`,
        calibration: `5 Jul - 12 Jul ${year} (7 days)`,
        publishToManagers: `15 Jul ${year}`,
        publish: `22 Jul ${year}`,
      },
    },
    {
      start_date_time: new Date(`07/01/${year}`),
      end_date_time: new Date(`12/31/${year}`),
      timeline: {
        goalSetting: `10 Jun - 1 Jul ${year} (21 days)`,
        assestment: `23 Dec ${year} - 10 Jan ${year + 1} (18 days)`,
        calibration: `10 Jan - 17 Jan ${year + 1} (7 days)`,
        publishToManagers: `20 Jan ${year + 1}`,
        publish: `27 Jan ${year + 1}`,
      },
    },
  ],
  annually: [
    {
      start_date_time: new Date(`01/01/${year}`),
      end_date_time: new Date(`12/30/${year}`),
      timeline: {
        goalSetting: `9 Dec ${year - 1} - 1 Jan ${year} (23 days)`,
        assestment: `22 Dec ${year} - 9 Jan ${year + 1} (18 days)`,
        calibration: `9 Jan - 16 Jan ${year + 1} (7 days)`,
        publishToManagers: `19 Jan ${year + 1}`,
        publish: `26 Jan ${year + 1}`,
      },
    },
  ],
})

const getCurrentCycleOffset = (duration: CYCLE_DURATION, today: Date) => {
  const reference = getCycleReference(today.getFullYear())
  switch (true) {
    case duration === 'biannually':
      return today >= reference.biannually[1].start_date_time ? 1 : 0
    case duration === 'quarterly' && today >= reference.quarterly[3].start_date_time:
      return 3
    case duration === 'quarterly' && today >= reference.quarterly[2].start_date_time:
      return 2
    case duration === 'quarterly' && today >= reference.quarterly[1].start_date_time:
      return 1
    case duration === 'annually':
    default:
      return 0
  }
}

const getCycleName = (offset: number, duration: CYCLE_DURATION, year: number) => {
  switch (duration) {
    case 'quarterly':
      return `Q${offset + 1} ${year}`
    case 'biannually':
      return `H${offset + 1} ${year}`
    case 'annually':
    default:
      return String(year)
  }
}

const generateCycle = (offset: number, duration: CYCLE_DURATION, year: number) => {
  const reference = getCycleReference(year)
  const cycleName = getCycleName(offset, duration, year)
  return {
    ...reference[duration][offset],
    name: cycleName,
    id: cycleName,
    category: ReviewCycleCategory.Performance,
    status: ReviewCycleStatus.planned,
    enabled_eligible_updates: false,
    enabled_scorecards_generations: false,
    offset,
    probation_version: 2,
  }
}

const generateCycles = (duration: CYCLE_DURATION) => {
  const today = new Date()
  let currentYear = today.getFullYear()
  const firstCycleOffset = getCurrentCycleOffset(duration, today)
  const firstCycle = generateCycle(firstCycleOffset, duration, currentYear)
  firstCycle.status = ReviewCycleStatus.ongoing
  const cycles = [firstCycle]
  const maxCycles = duration === 'annually' ? 5 : 8
  const cyclesPerYearPerDuratin = {
    annually: 1,
    biannually: 2,
    quarterly: 4,
  }
  const cyclesPerYear = cyclesPerYearPerDuratin[duration]

  while (cycles.length < maxCycles) {
    const totalCycleOffset = firstCycleOffset + cycles.length
    const nextCycleOffset = totalCycleOffset % cyclesPerYear
    if (nextCycleOffset === 0) {
      currentYear += 1
    }
    const cycle = generateCycle(nextCycleOffset, duration, currentYear)
    cycles.push(cycle)
  }

  return cycles
}

const generateTimelineFor = (
  { timeline }: ReturnType<typeof generateCycle>,
  settings: PerformanceSettingsInterface,
): TimelinePreviewSectionInterface[] => {
  return [
    settings.enable_kpi_setting_dates
      ? {
          label: 'Goal settings',
          description: timeline.goalSetting,
        }
      : null,
    {
      label: 'Performance review assessment',
      description: timeline.assestment,
    },
    settings.enable_calibration
      ? {
          label: 'Calibration',
          description: timeline.calibration,
          state: ProgressStepState.DEFAULT,
        }
      : null,
    settings.publish_grades_to_managers_before_employees
      ? {
          label: 'Publish results to managers',
          description: timeline.publishToManagers,
        }
      : null,
    {
      label: 'Publish results to employees',
      description: timeline.publish,
    },
  ].filter(Boolean)
}

export const PerfrormanceCycles = () => {
  const { data: settings } = useGetPerformanceSettings({ cacheTime: 0, staleTime: 0 })
  const [cycles, setCycles] = useState<any[]>([])
  const [selectedCycle, setSelectedCycle] = useState<ReturnType<typeof generateCycle>>()

  useEffect(() => {
    if (settings?.cycle_duration.id) {
      const generated = generateCycles(settings.cycle_duration.id)
      setCycles(generated)
      setSelectedCycle(generated[0])
    }
  }, [settings?.cycle_duration.id])

  const rows: RowInterface<ReturnType<typeof generateCycle>> = {
    linkToForm: setSelectedCycle,
    highlight: cycle =>
      cycle.name === selectedCycle?.name
        ? (Token.color.blue_5 as string)
        : Token.color.widgetBackground,
    cells: [
      {
        ...(reviewCyclesNameColumn as unknown as ColumnInterface<
          ReturnType<typeof generateCycle>
        >),
        sortKey: null,
        filterKey: null,
        width: 150,
      },
      {
        ...(reviewCyclesStartDateColumn as unknown as ColumnInterface<
          ReturnType<typeof generateCycle>
        >),
        sortKey: null,
        filterKey: null,
        width: 200,
      },
      {
        ...(reviewCyclesEndDateColumn as unknown as ColumnInterface<
          ReturnType<typeof generateCycle>
        >),
        sortKey: null,
        filterKey: null,
        width: 200,
      },
      {
        ...(reviewCyclesStatusColumn as unknown as ColumnInterface<
          ReturnType<typeof generateCycle>
        >),
        sortKey: null,
        filterKey: null,
        width: 150,
      },
    ],
  }

  return (
    <WithTimelinePreview
      sections={
        cycles.length && settings && selectedCycle
          ? generateTimelineFor(selectedCycle, settings)
          : []
      }
      title={chain([selectedCycle?.name, 'Timeline preview'])}
    >
      <VStack>
        <SectionTitle
          title="Confirm your performance review cycles"
          subtitle="You can check the timeline preview by hovering cycle in the table"
        />
        <Message
          storageKey="onboarding_performance_cycles_message"
          title="We have created some cycles for you!"
          description="Feel free to preview the timelines. Adjustments can be made in the settings at any time."
        />
        <Spacer height={Token.space.s16} />
        <Table.Widget>
          <Table.Widget.Info>
            <DataPoint>
              <DataPoint.Value use="h3">{cycles.length}</DataPoint.Value>
              <DataPoint.Label>Performance Cycles</DataPoint.Label>
            </DataPoint>
          </Table.Widget.Info>
          <Table.Widget.Table>
            <AdjustableTable<ReturnType<typeof generateCycle>>
              name={TableNames.ReviewCycles}
              useWindowScroll
              dataType="Review Cycle"
              row={rows}
              data={cycles}
              count={cycles.length}
              noDataMessage="Review cycles will appear here."
            />
          </Table.Widget.Table>
        </Table.Widget>
      </VStack>
    </WithTimelinePreview>
  )
}
