import React, { useState } from 'react'
import { HStack, MoreBar } from '@revolut/ui-kit'
import { HeaderItem } from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/HeaderItem'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import ChangeJobPosting from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/ChangeJobPosting'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { navigateToJobPostingFlow } from '@src/features/JobPostingFlow/utils'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'

type OverviewProps = {
  jobPosting: JobPostingInterface
  onRefresh: () => Promise<void>
}

const Overview = ({ jobPosting, onRefresh }: OverviewProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const [openJobPostingSidebar, setOpenJobPostingSidebar] = useState(false)

  return (
    <>
      <JobDescription
        header={
          <>
            <HeaderItem title={jobPosting.name} />
            <HStack px="s-16">
              <MoreBar>
                <MoreBar.Action
                  use={InternalLink}
                  useIcon="LinkExternal"
                  target="_blank"
                  to={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                    specId: values.specialisation?.id,
                    id: jobPosting.id,
                  })}
                >
                  View
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="Plus"
                  onClick={() => {
                    navigateToJobPostingFlow({
                      specialisationId: values.specialisation?.id,
                      requisitionId: values.id,
                    })
                  }}
                >
                  Create new
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="ArrowRightLeft"
                  onClick={() => {
                    setOpenJobPostingSidebar(true)
                  }}
                >
                  Change job posting
                </MoreBar.Action>
              </MoreBar>
            </HStack>
          </>
        }
        id={jobPosting.id}
        locations={jobPosting.locations}
      />
      {openJobPostingSidebar && (
        <ChangeJobPosting
          onClose={() => {
            setOpenJobPostingSidebar(false)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}

export default Overview
