import React, { useMemo, useState } from 'react'
import { Box, DataPoint, Flex, Text, Token, Widget } from '@revolut/ui-kit'
import { EmployeeTotalCompensationEquityTimeSeriesInterface } from '@src/interfaces/totalCompensation'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { BarChart } from '../Charts/BarChart'
import { getColorByIndex, useBarProjectedData } from '../Charts/helpers'
import { MIN_HEIGHT } from './EquityTotal'

interface Props {
  data?: EmployeeTotalCompensationEquityTimeSeriesInterface[]
  isLoading: boolean
}

export interface BarChartDataSeriesInterface {
  [s: string]: string
}

interface BarChartDataInterface
  extends EmployeeTotalCompensationEquityTimeSeriesInterface {
  label: string
  tooltip: string
  total: string
}

const selectOptions = [
  {
    label: 'Granted equity',
    value: {
      id: 'granted',
      name: 'Granted equity',
      description: 'The total equity awarded to you, reported in the granted year.',
    },
  },
  {
    label: 'Vested equity',
    value: {
      id: 'vested',
      name: 'Vested equity',
      description: 'The total equity awarded to you, reported in the vesting year.',
    },
  },
]

export const EquityOverTime = ({ data, isLoading }: Props) => {
  const [selected, setSelected] = useState(selectOptions[0].value)
  const { dataKeysGranted, dataKeysVested } = useMemo<{
    dataKeysGranted: DataKey<BarChartDataSeriesInterface>[]
    dataKeysVested: DataKey<BarChartDataSeriesInterface>[]
  }>(() => {
    if (!data) {
      return { dataKeysGranted: [], dataKeysVested: [] }
    }
    const { uniqueGrantsCategories, uniqueSeriesCategories } = data.reduce(
      (acc, item) => {
        item.grants.forEach(grant => {
          if (!acc.uniqueGrantsCategories.has(grant.category)) {
            acc.uniqueGrantsCategories.add(grant.category)
          }
        })
        item.series.forEach(series => {
          if (!acc.uniqueSeriesCategories.has(series.category)) {
            acc.uniqueSeriesCategories.add(series.category)
          }
        })
        return acc
      },
      {
        uniqueGrantsCategories: new Set<string>(),
        uniqueSeriesCategories: new Set<string>(),
      },
    )

    const mapUniqueCategoriesToKeys = (categories: Set<string>) =>
      Array.from(categories).map((category, index) => ({
        id: index,
        color: Token.colorChannel[getColorByIndex(index)],
        label: category,
        value: category,
      }))

    return {
      dataKeysGranted: mapUniqueCategoriesToKeys(uniqueGrantsCategories),
      dataKeysVested: mapUniqueCategoriesToKeys(uniqueSeriesCategories),
    }
  }, [data])

  const { chartDataGranted, chartDataVested } = useMemo<{
    chartDataGranted: BarChartDataInterface[]
    chartDataVested: BarChartDataInterface[]
  }>(() => {
    if (!data) {
      return { chartDataGranted: [], chartDataVested: [] }
    }

    const dataGranted = data
      .filter(value => value.grants.length)
      .map(value => {
        const grants = value.grants.reduce(
          (grant, key) => ({ ...grant, [key.category]: key.amount }),
          {},
        )

        return {
          ...value,
          ...grants,
          total: value.grant_total,
          label: '',
          tooltip: '',
        }
      })

    const dataVested = data
      .filter(value => value.series.length)
      .map(value => {
        const series = value.series.reduce(
          (seriesItem, key) => ({ ...seriesItem, [key.category]: key.amount }),
          {},
        )

        return {
          ...value,
          ...series,
          total: value.total,
          label: '',
          tooltip: '',
        }
      })

    return { chartDataGranted: dataGranted, chartDataVested: dataVested }
  }, [data])

  const showGranted = selected.id === 'granted'

  const projectedDataIdx = useBarProjectedData(
    showGranted ? chartDataGranted : chartDataVested,
  )

  if (!isLoading && !data?.length) {
    return null
  }

  return (
    <Widget
      height="50vh"
      minHeight={MIN_HEIGHT}
      p="s-16"
      data-testid="equity_over_time_section"
    >
      <Flex flexDirection="column" height="100%">
        <DataPoint>
          <DataPoint.Value>
            <RadioSelectInput
              onChange={value => {
                if (value) {
                  setSelected(value)
                }
              }}
              options={selectOptions}
              renderInput={(open, setOpen, ref) => (
                <Box ref={ref} width={Token.size.s72}>
                  <DropdownStatAction
                    open={open}
                    onClick={() => setOpen(!open)}
                    ref={ref}
                  >
                    {selected.name}
                  </DropdownStatAction>
                </Box>
              )}
              searchable={false}
            >
              {option => {
                return (
                  <Flex flexDirection="column" gap="s-6">
                    <Text variant="body1">{option.value.name}</Text>
                    <Text color={Token.color.greyTone50} variant="body2">
                      {option.value.description}
                    </Text>
                  </Flex>
                )
              }}
            </RadioSelectInput>
          </DataPoint.Value>
        </DataPoint>
        <BarChart
          data={showGranted ? chartDataGranted : chartDataVested}
          dataKeys={showGranted ? dataKeysGranted : dataKeysVested}
          isLoading={isLoading}
          projectedDataIdx={projectedDataIdx}
        />
      </Flex>
    </Widget>
  )
}
