import React from 'react'
import {
  Avatar,
  Cell,
  VStack,
  Text,
  Subheader,
  Columns,
  Box,
  Token,
  Spacer,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { HUB_INTEGRATIONS_FLAT } from '@src/constants/hub'
import { navigateTo } from '@src/actions/RouterActions'
import { HubIntegrationInterface } from '@src/interfaces/hub'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import useIsCommercial from '@src/hooks/useIsCommercial'

interface IntegrationItemProps {
  item: HubIntegrationInterface
}

const IntegrationItem = ({ item }: IntegrationItemProps) => {
  const permissions = useSelector(selectPermissions)

  const enabled = item.canView
    ? item.canView.some(permission => permissions.includes(permission))
    : true

  return (
    <Cell
      onClick={() => navigateTo(item.url)}
      use="button"
      disabled={!enabled}
      height={156}
    >
      <VStack height="100%" style={{ justifyContent: 'flex-start' }}>
        {typeof item.imageV2 === 'string' ? (
          <Avatar variant="brand" image={item.imageV2} bg="transparent" />
        ) : (
          item.imageV2
        )}

        <Spacer />

        <VStack space="s-8">
          <Text variant="primary">{item.title}</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            {item.description}
          </Text>
        </VStack>
      </VStack>
    </Cell>
  )
}

const IntegrationGrid: React.FC = ({ children }) => {
  return (
    <Columns gap="s-16" defaultCount={3} size={320}>
      {children}
    </Columns>
  )
}

type IntegrationSectionProps = {
  items: HubIntegrationInterface[]
  title?: React.ReactNode
}

export const IntegrationSection = ({ items, title }: IntegrationSectionProps) => {
  if (!items.length) {
    return null
  }
  return (
    <Box>
      {title && (
        <Subheader>
          <Subheader.Title>{title}</Subheader.Title>
        </Subheader>
      )}
      <IntegrationGrid>
        {items.map(item => (
          <IntegrationItem item={item} key={item.id} />
        ))}
      </IntegrationGrid>
    </Box>
  )
}

export const useFilterIntegrations = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = useIsCommercial()
  const filterIntegrations = <T extends HubIntegrationInterface>(array: T[]) =>
    array
      .filter(item => {
        const flags = item.featureFlags
          ? item.featureFlags.some(flag =>
              featureFlags ? featureFlags.includes(flag) : false,
            )
          : true
        if (item.commercialOnly) {
          return isCommercial && flags
        }
        return flags
      })
      .filter(Boolean)
  return filterIntegrations
}

type JobBoardsSectionProps = {
  title?: React.ReactNode
}

export const JobBoardsSection = ({ title }: JobBoardsSectionProps) => {
  const filterIntegrations = useFilterIntegrations()
  const jobBoardIntegrations = filterIntegrations([
    HUB_INTEGRATIONS_FLAT.linkedin,
    HUB_INTEGRATIONS_FLAT.indeed,
    HUB_INTEGRATIONS_FLAT.monsterJobs,
  ])
  return <IntegrationSection items={jobBoardIntegrations} title={title} />
}

export const Integrations = () => {
  const filterIntegrations = useFilterIntegrations()

  const collaborationGroup = filterIntegrations([
    HUB_INTEGRATIONS_FLAT.google,
    HUB_INTEGRATIONS_FLAT.microsoft,
    HUB_INTEGRATIONS_FLAT.slack,
    HUB_INTEGRATIONS_FLAT.okta,
  ])

  const productivityGroup = filterIntegrations([
    HUB_INTEGRATIONS_FLAT.docusign,
    HUB_INTEGRATIONS_FLAT.jira,
    HUB_INTEGRATIONS_FLAT.looker,
    HUB_INTEGRATIONS_FLAT.clickup,
    HUB_INTEGRATIONS_FLAT.linear,
    HUB_INTEGRATIONS_FLAT.tableau,
    HUB_INTEGRATIONS_FLAT.salesforce,
  ])

  const hrisGroup = filterIntegrations([
    HUB_INTEGRATIONS_FLAT.bamboo,
    HUB_INTEGRATIONS_FLAT.hibob,
    HUB_INTEGRATIONS_FLAT.personio,
  ])

  const payrollGroup = filterIntegrations([HUB_INTEGRATIONS_FLAT.deel])

  return (
    <VStack space="s-16">
      <IntegrationSection items={collaborationGroup} title="Collaboration" />
      <IntegrationSection items={productivityGroup} title="Productivity" />
      <IntegrationSection items={payrollGroup} title="Payroll" />
      <IntegrationSection items={hrisGroup} title="HR Platforms" />
      <JobBoardsSection title="Job boards" />
    </VStack>
  )
}
