import React, { useEffect, useRef, useState } from 'react'
import { Box, Group, Header, Popup, Relative, Scrollbar, VStack } from '@revolut/ui-kit'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { ContentTypeOption, SpecialisationOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import {
  GrowthPlanActionInterface,
  GrowthPlanInterface,
  GrowthPlanPerformanceMetricInterface,
} from '@src/interfaces/growthPlans'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { SpecialisationSelector } from '@src/features/GrowthPlans/GrowthPlan/components/SpecialisationSelector/SpecialisationSelector'
import { MetricItem } from '@src/features/GrowthPlans/GrowthPlan/components/MetricsConnector/MetricItem'
import {
  EmptyView,
  LoadingView,
} from '@src/features/GrowthPlans/GrowthPlan/components/MetricsConnector/ConnectMetricPopupBody'
import { useGetPerformanceSummaryForGrowthPlan } from '@src/api/growthPlans'
import { compareGrowthPlanMetricsByPerformanceRating } from '@src/utils/performance'
import { PerformanceSelector } from '@src/interfaces/performance'

export type MetricType = 'skillset' | 'companyvalue'

interface Props {
  growthPlan: GrowthPlanInterface
  reviewCycle: PerformanceSelector | null
  action: GrowthPlanActionInterface | null
  metricTypes: MetricType[]
  isOpen: boolean
  isLoading: boolean
  onClose: VoidFunction
  onConnect: (
    contentOption: ContentTypeOption<MetricType>,
    metric: GrowthPlanPerformanceMetricInterface,
  ) => void
}

enum MetricTabs {
  Skills = 'skills',
  Values = 'values',
}

const metricTabToContentModelMap: Record<MetricTabs, MetricType> = {
  [MetricTabs.Skills]: 'skillset',
  [MetricTabs.Values]: 'companyvalue',
}

const getMetricItemLabels = (
  metric: GrowthPlanPerformanceMetricInterface,
  action: GrowthPlanActionInterface | null,
  metricType?: MetricType,
): string[] => {
  if (!action || !metricType || !action.content_type) {
    return []
  }

  if (metric.id === action.object_id && metricType === action.content_type.model) {
    return ['connected']
  }

  return []
}

interface ContentProps {
  title: string
  description: string
  tabs: MetricTabs[]
}

const getContentProps = (metricTypes: MetricType[]): ContentProps => {
  if (metricTypes.includes('companyvalue')) {
    return {
      title: 'Skills and values',
      description: 'Select a skill or value to be associated with this to-do item',
      tabs: [MetricTabs.Skills, MetricTabs.Values],
    }
  }

  return {
    title: 'Skills',
    description: 'Select a skill to be associated with this to-do item',
    tabs: [MetricTabs.Skills],
  }
}

export const ConnectMetricPopup = ({
  growthPlan,
  reviewCycle,
  action,
  metricTypes,
  isOpen,
  isLoading,
  onClose,
  onConnect,
}: Props) => {
  const [selectedSpecialisation, setSelectedSpecialisation] =
    useState<SpecialisationOption>()
  const [defaultSpecialisation, setDefaultSpecialisation] =
    useState<SpecialisationOption>()
  const [contentType, setContentType] = useState<ContentTypeOption<MetricType>>()

  const { options: specialisationOptions, asyncState: specialisationOptionsAsyncState } =
    useFetchOptions<SpecialisationOption>(selectorKeys.specialisations)
  const { data: performanceSummary, isLoading: isPerformanceSummaryLoading } =
    useGetPerformanceSummaryForGrowthPlan(
      {
        employee_id: growthPlan.employee.id,
        target_seniority_id: growthPlan.target_seniority.id,
        target_specialisation_id: selectedSpecialisation
          ? Number(selectedSpecialisation.id)
          : growthPlan.target_specialisation.id,
        cycle_id: reviewCycle?.id,
        cycle_category: reviewCycle?.category,
      },
      !isLoading,
    )
  const { options: contentOptions, asyncState: contentOptionsAsyncState } =
    useFetchOptions<ContentTypeOption<MetricType>>(
      selectorKeys.growth_plan_action_content_types,
    )

  const scrollRef = useRef<HTMLDivElement>(null)
  const { title, description, tabs } = getContentProps(metricTypes)
  const { tabBar: TabBar, currentTab = MetricTabs.Skills } =
    useTabBarSwitcher<MetricTabs>({
      tabs,
      defaultTab: MetricTabs.Skills,
      capitalize: true,
      highlightSelected: false,
    })

  useEffect(() => {
    if (!specialisationOptions.length) {
      return
    }

    const targetSpecialisation = specialisationOptions.find(
      ({ value }) => String(value.id) === String(growthPlan.target_specialisation.id),
    )

    if (!targetSpecialisation) {
      return
    }

    setSelectedSpecialisation(targetSpecialisation.value)
    setDefaultSpecialisation(targetSpecialisation.value)
  }, [growthPlan.target_specialisation.id, specialisationOptions])

  useEffect(() => {
    setContentType(
      contentOptions
        .map(({ value }) => value)
        .find(
          contentOption => contentOption.name === metricTabToContentModelMap[currentTab],
        ),
    )
  }, [currentTab, contentOptions])

  const handleConnect = (metric: GrowthPlanPerformanceMetricInterface) => {
    if (!contentType) {
      return
    }

    onConnect(contentType, metric)
  }

  const handleSpecialisationChange = (option?: SpecialisationOption) => {
    setSelectedSpecialisation(option ?? defaultSpecialisation)
  }

  const isSpecialisationsLoading = specialisationOptionsAsyncState === 'pending'

  const functionalSkills = [...(performanceSummary?.functional_skills ?? [])].sort(
    compareGrowthPlanMetricsByPerformanceRating,
  )
  const culturalValues = [...(performanceSummary?.culture_values ?? [])].sort(
    compareGrowthPlanMetricsByPerformanceRating,
  )

  const isLoadingView =
    isPerformanceSummaryLoading || contentOptionsAsyncState === 'pending'
  const isEmptySkillsView =
    !isLoadingView && currentTab === 'skills' && functionalSkills.length === 0
  const isEmptyValuesView =
    !isLoadingView && currentTab === 'values' && culturalValues.length === 0
  const isSkillsView =
    !isLoadingView && currentTab === 'skills' && functionalSkills.length > 0
  const isValuesView =
    !isLoadingView && currentTab === 'values' && culturalValues.length > 0

  return (
    <Popup open={isOpen} size="sm" onClose={onClose}>
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
      </Header>
      <VStack space="s-16">
        {tabs.length > 1 && TabBar}
        {!isSpecialisationsLoading && currentTab === 'skills' && selectedSpecialisation && (
          <Box width="100%">
            <SpecialisationSelector
              value={selectedSpecialisation}
              options={specialisationOptions.map(({ value }) => value)}
              onChange={handleSpecialisationChange}
            />
          </Box>
        )}
        <Relative>
          <Group maxHeight={300} overflow="auto" ref={scrollRef}>
            {isLoadingView && <LoadingView />}
            {isEmptySkillsView && (
              <EmptyView title="There are no skills matching your query" />
            )}
            {isEmptyValuesView && (
              <EmptyView title="There are no values matching your query" />
            )}
            {isSkillsView &&
              functionalSkills.map(skill => (
                <MetricItem
                  key={skill.id}
                  value={skill}
                  iconName="RepairTool"
                  labels={getMetricItemLabels(skill, action, action?.content_type?.model)}
                  onClick={handleConnect}
                />
              ))}
            {isValuesView &&
              culturalValues.map(value => (
                <MetricItem
                  key={value.id}
                  value={value}
                  iconName="Heart"
                  labels={getMetricItemLabels(value, action, action?.content_type?.model)}
                  onClick={handleConnect}
                />
              ))}
          </Group>
          <Scrollbar scrollRef={scrollRef} cornerSpace="s-24" offset="s-2" />
        </Relative>
      </VStack>
    </Popup>
  )
}
