import React from 'react'
import { SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar, FilterButton } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import {
  departmentAuditColumn,
  departmentBudgetColumn,
  departmentCommunicationColumn,
  departmentCultureColumn,
  departmentHeadcountColumn,
  departmentRequisitionsColumn,
  departmentRoadmapColumn,
  departmentStatusColumn,
  nipsColumn,
  departmentKPIColumn,
  departmentNameIconColumn,
} from '@src/constants/columns/department'
import { circlesKriColumn } from '@src/constants/columns/team'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { Statuses } from '@src/interfaces'
import { karmaPercentColumn } from '@src/constants/columns/karma'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { departmentRequests } from '@src/api/department'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import {
  useGetOrganisationSettings,
  useGetRoadmapSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { useGetSlackPreferences } from '@src/api/integrations'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import Table from '@src/components/TableV2/Table'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { useGetTeamsStats } from '@src/api/teams'
import { teamsConfig } from '@src/pages/Organisation/OrganisationSubTabs/common'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const ROW = {
  linkToForm: ({ id }: { id: number }) => {
    if (id) {
      navigateTo(pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id }))
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, { id }))
    }
  },
  cells: [
    {
      ...departmentNameIconColumn,
      width: 180,
    },
    {
      ...ownerNameColumn,
      width: 300,
    },
    {
      ...departmentHeadcountColumn,
      width: 100,
    },
    {
      ...departmentRequisitionsColumn,
      width: 100,
    },
    {
      ...departmentKPIColumn,
      width: 150,
    },
    {
      ...departmentRoadmapColumn,
      width: 100,
    },
    {
      ...nipsColumn,
      width: 100,
    },
    {
      ...departmentAuditColumn,
      width: 120,
    },
    {
      ...circlesKriColumn,
      width: 130,
    },
    {
      ...karmaPercentColumn,
      width: 100,
    },
    {
      ...departmentBudgetColumn,
      width: 120,
    },
    {
      ...departmentCommunicationColumn,
      width: 160,
    },
    {
      ...departmentStatusColumn,
      width: 130,
    },
  ],
}

const DepartmentsTable = () => {
  const user = useSelector(selectUser)
  const [showMyDepartments, setShowMyDepartments] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_DEPARTMENTS,
    false,
  )
  const [showArchivedDepartments, setShowArchivedDepartments] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_DEPARTMENTS,
    false,
  )

  const { data: settings } = useGetOrganisationSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()
  const { settings: globalSettings } = useGlobalSettings()

  const roadmapEnabled = !!roadmapSettings?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const karmaEnabled = !!settings?.karma?.enabled
  const budgetEnabled = !!settings?.enable_budget_management
  const approvalsEnabled = !!settings?.enable_departments_approvals
  const slackEnabled = !!slackSettings?.enabled
  const engagementEnabled = !!globalSettings?.engagement_enabled

  const { FavouritesFilter, initialFilter } = useFavouritesFilter('department')

  const getFilterByOwner = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push(getFilterByStatus(showArchivedDepartments))
    filter.push(getFilterByOwner(showMyDepartments))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const filterBy = getInitialFilter()
  const table = useTable<DepartmentInterface>(departmentRequests, filterBy, sortBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddDepartment)
  const canManage = permissions.includes(PermissionTypes.ManageDepartments)

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const onToggleMyDepartments = () => {
    setShowMyDepartments(!showMyDepartments)
    table.onFilterChange(getFilterByOwner(!showMyDepartments))
  }

  const onToggleArchivedDepartments = () => {
    setShowArchivedDepartments(!showArchivedDepartments)
    table.onFilterChange(getFilterByStatus(!showArchivedDepartments))
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatNavigation api={useGetTeamsStats} config={teamsConfig} />
      </Table.Widget.Info>
      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by department name"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {canAdd && (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS)}
              useIcon="Plus"
            >
              Add Department
            </PrimaryAction>
          )}
          {canManage && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.DEPARTMENT)}
              useIcon="Pencil"
            >
              Edit
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.ORGANISATION.LIST}
            canView={settingsConfig.teams.canView}
          />
          <ExportMenu
            fileName="Departments"
            request={departmentRequests.getExport}
            filterQuery={filterQuery}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by department name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton onClick={onToggleMyDepartments} active={showMyDepartments}>
          My departments
        </FilterButton>
        <FilterButton
          onClick={onToggleArchivedDepartments}
          active={showArchivedDepartments}
        >
          Archived departments
        </FilterButton>
        <FavouritesFilter table={table} />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<DepartmentInterface>
          name={TableNames.Departments}
          useWindowScroll
          dataType="Department"
          row={ROW}
          {...table}
          noDataMessage="Departments will appear here."
          hideCount
          hiddenCells={{
            [departmentRoadmapColumn.idPoint]: !roadmapEnabled,
            [circlesKriColumn.idPoint]: !riskEnabled,
            [departmentCultureColumn.idPoint]: !engagementEnabled,
            [departmentAuditColumn.idPoint]: !findingsEnabled,
            [karmaPercentColumn.idPoint]: !karmaEnabled,
            [departmentBudgetColumn.idPoint]: !budgetEnabled,
            [departmentCommunicationColumn.idPoint]: !slackEnabled,
            [departmentStatusColumn.idPoint]: !approvalsEnabled,
          }}
          tableSettings={{
            visible: [],
            hidden: [
              departmentAuditColumn.title,
              circlesKriColumn.title,
              karmaPercentColumn.title,
              departmentCommunicationColumn.title,
              departmentStatusColumn.title,
            ],
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default withFavourites(DepartmentsTable)
