import React, { useMemo, useRef } from 'react'
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart as PieChartComponent,
  ResponsiveContainer,
} from 'recharts'
import { PolarViewBox } from 'recharts/types/util/types'
import {
  Absolute,
  Box,
  Color,
  Flex,
  HStack,
  Relative,
  Text,
  Token,
  useResizeObserver,
} from '@revolut/ui-kit'

import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { formatPercentage } from '@src/utils/format'

type DistributionCategory = 'promoters' | 'passives' | 'detractors' | 'ambivalents'
type DistributionDatum = {
  id: DistributionCategory
  name: string
  value: number
  color: Color
}

interface Props {
  data: EngagementResultV2Interface
  containerHeight: number
}
export const PieChart = ({ data, containerHeight }: Props) => {
  const ref = useRef(null)
  const { width, height } = useResizeObserver<HTMLDivElement>(ref)

  const distributionData: DistributionDatum[] = useMemo(
    () => [
      {
        id: 'promoters',
        name: 'Promoters',
        value: data.promoters,
        color: Token.color.success,
      },
      {
        id: 'passives',
        name: 'Passives',
        value: data.passives,
        color: Token.color.yellow,
      },
      {
        id: 'detractors',
        name: 'Detractors',
        value: data.detractors,
        color: Token.color.danger,
      },
      {
        id: 'ambivalents',
        name: "Don't know",
        value: data.ambivalents,
        color: Token.color.greyTone20,
      },
    ],
    [data],
  )

  const pieChartCells = useMemo(() => {
    return distributionData.map((datum, index) => {
      return (
        <Cell
          key={`outer-cell-${index}`}
          fill={datum.color}
          stroke={datum.color}
          strokeWidth={2}
        />
      )
    })
  }, [distributionData])

  return (
    <Relative ref={ref} height="100%" width="100%" minWidth={200}>
      <Absolute bottom={0} left={0} right={0} top={0}>
        {height ? (
          <ResponsiveContainer height={containerHeight} width="100%">
            <PieChartComponent width={width} height={height}>
              <Pie
                cx="50%"
                cy="60%"
                data={distributionData}
                dataKey="value"
                innerRadius="90%"
                outerRadius="100%"
                startAngle={220}
                endAngle={-40}
                cornerRadius={50}
                paddingAngle={3}
                animationDuration={300}
              >
                {pieChartCells}
                <Label
                  content={props => {
                    const viewBox = (props.viewBox || {}) as PolarViewBox
                    const cx = Number(viewBox.cx || 0)
                    const cy = Number(viewBox.cy || 0)

                    const positioningProps = {
                      x: cx,
                      y: cy,
                      textAnchor: 'middle',
                    }
                    return (
                      <text pointerEvents="none">
                        <tspan
                          {...positioningProps}
                          dy="-8px"
                          fill={Token.color.foreground}
                          fontSize={Token.fontSize.h0}
                          fontWeight={Token.fontWeight.heading1}
                        >
                          {data.average_score}
                        </tspan>
                        <tspan
                          {...positioningProps}
                          dy="20px"
                          fill={Token.color.foreground}
                          fontSize={Token.fontSize.emphasis1}
                          fontWeight={500}
                        >
                          Normalized score
                        </tspan>
                        <tspan
                          {...positioningProps}
                          dy="40px"
                          fill={Token.color.greyTone50}
                          fontSize={Token.fontSize.small}
                          fontWeight={500}
                        >
                          Adjusted to go from 0 to 10
                        </tspan>
                      </text>
                    )
                  }}
                />
              </Pie>
              <Legend
                content={() => (
                  <Flex gap="s-8" justifyContent="center" px="s-16" flexWrap="wrap">
                    {distributionData?.map(datum => (
                      <Flex key={datum.id} flexDirection="column" gap="s-4">
                        <HStack space="s-8">
                          <Box
                            borderRadius={Token.radius.r2}
                            bg={datum.color}
                            height="s-12"
                            width="s-12"
                          />
                          <Text fontSize="small">
                            {typeof data.respondents === 'number'
                              ? formatPercentage(datum.value / data.respondents)
                              : 'N/A'}
                          </Text>
                        </HStack>
                        <Text color={Token.color.greyTone50} fontSize="small">
                          {datum.name}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                )}
              />
            </PieChartComponent>
          </ResponsiveContainer>
        ) : null}
      </Absolute>
    </Relative>
  )
}
