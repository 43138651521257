import React from 'react'
import { Box, Button, Header, Popup } from '@revolut/ui-kit'
import { CompetencyMatrixInterface, SpecialisationInterface } from '@src/interfaces/roles'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'

interface Props {
  onClose: VoidFunction
  onChange: (competencyMatrix: CompetencyMatrixInterface[]) => void
  specialisation: SpecialisationInterface
  competencyMatrix: CompetencyMatrixInterface[]
}

export const DefineCompetencyMatrixPopup = ({
  onClose,
  onChange,
  specialisation,
  competencyMatrix,
}: Props) => {
  return (
    <Popup open onClose={onClose} size="lg" data-testid="competency-matrix-popup">
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{specialisation.name}</Header.Title>
        <Header.Description>Competency preview</Header.Description>
      </Header>
      <Box>
        <CompetencyMatrixTable
          isV2Table
          competencyMatrices={[
            {
              sectionTitle: 'Skills',
              children: competencyMatrix,
              onChange: data => {
                onChange(data || [])
              },
            },
          ]}
          firstRowTitle="Skills"
          minSeniority={specialisation.seniority_min}
          maxSeniority={specialisation.seniority_max}
          isAdjustable={false}
        />
      </Box>

      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
