import React from 'react'
import { Box, Token, VStack } from '@revolut/ui-kit'

import {
  FeatureBlock,
  FeatureBlockVariant,
} from '@src/pages/Landing/components/FeatureBlock'
import { FeatureBlockSummary } from './components/FeatureBlockSummary'
import {
  CARDINO_HERO,
  CARDINO_1,
  CARDINO_2,
  CARDINO_3,
  CARDINO_STORY_1,
} from './constants'
import { LandingHeader } from '@src/pages/Landing/components/Header'
import { CompanyDescription } from './components/CompanyDescription'
import { CustomerStory } from './components/CustomerStory'
import { Footer } from '../Landing/components/Footer'
import { ToolsUsed } from './components/ToolsUsed'
import { NextStep } from './components/NextStep'
import { DEMO_PERFORMANCE_URL } from '../Landing/constants'

export const Cardino = () => {
  return (
    <Box>
      <LandingHeader
        style={{ backdropFilter: 'blur(4px)' }}
        bg="rgba(0,0,0,0.3)"
        pb="s-16"
      />

      <Box
        style={{
          backgroundImage: `url(${CARDINO_HERO})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        py={100}
      >
        <FeatureBlock
          title="CARDINO STREAMLINES HR OPERATIONS FOR HYPERGROWTH"
          description={
            <FeatureBlockSummary
              industry="Marketplace"
              size="30+ employees"
              headquarters="Berlin"
              bullets={[
                '20 hours saved per month on HR operations',
                '80% of HR processes digitalised in first quarter',
                '100% employee participation in first performance review cycle',
              ]}
            />
          }
          caption="Case study"
          withPadding
          variant={FeatureBlockVariant.Right}
          url={DEMO_PERFORMANCE_URL}
          contentProps={{
            p: 's-24',
            style: {
              backdropFilter: 'blur(3px)',
            },
            radius: Token.radius.r16,
            backgroundColor: 'rgba(0,0,0,0.6)',
            minWidth: '40vw',
          }}
        />
      </Box>

      <CompanyDescription>
        Cardino is revolutionising the European used electric vehicle market with their
        data-driven platform that connects sellers and buyers across the EU
      </CompanyDescription>

      <CustomerStory
        story={{
          ...CARDINO_STORY_1,
          label: CARDINO_STORY_1.quoteBy,
          quoteBy: undefined,
          url: undefined,
        }}
      />

      <ToolsUsed
        label="The tools Cardino used"
        tools={[
          'Time Management',
          'Employee Onboarding',
          'Performance Reviews',
          '1:1 Meetings',
        ]}
      />

      <VStack space={{ lg: 's-64', all: 's-32' }} my="s-64">
        <FeatureBlock
          title="LACKING STRUCTURE FOR HYPERGROWTH"
          description={
            <>
              Following a €4M Seed round, Cardino faced the challenge of rapidly scaling
              from 10 to 30+ employees across the EU. As one of the fastest-growing
              startups in the DACH region, their manual HR processes weren't keeping pace.
              The company stored critical HR data in Google Sheets, lacking the compliance
              and efficiency needed for their expanding team.{'\n'}Without aligned
              objectives or role clarity, they risked mismatched expectations and unclear
              career trajectories for their high-performing team.
            </>
          }
          caption="Challenge"
          image={CARDINO_1}
          variant={FeatureBlockVariant.Right}
          showBookDemoButton={false}
          imageProps={{ height: 600 }}
          baseProps={{ maxWidth: 1000 }}
        />

        <FeatureBlock
          title="BUILDING A SCALABLE HR FOUNDATION"
          description={
            <>
              The decision to adopt Revolut People was driven by several key factors: its
              startup-centric approach, rapid implementation timeline, and integration of
              best HR practices from Revolut's innovative culture. The platform aimed to
              establish a central, compliant HR system while implementing robust
              performance management processes.{'\n\n'}The onboarding process proved
              seamless, with Cardino successfully running their first 360-degree
              performance review just three months after the implementation. The hands-on
              support from Revolut People's team enabled rapid feature iterations and
              quick adoption of the platform.
            </>
          }
          caption="Solution"
          image={CARDINO_2}
          showBookDemoButton={false}
          imageProps={{ height: 600 }}
          baseProps={{ maxWidth: 1000 }}
        />

        <FeatureBlock
          title="ACCELERATED GROWTH, ENHANCED TRANSPARENCY"
          description={
            <>
              Since transitioning to Revolut People, Cardino has experienced a significant
              transformation in their HR operations:{'\n\n'}
              <ul style={{ marginLeft: 18 }}>
                <li>
                  Reduced manual HR work by 20 hours monthly through process automation
                </li>
                <li>
                  Migrated 80% of HR processes from Google Sheets and Notion within the
                  first quarter
                </li>
                <li>
                  Achieved 100% employee participation in their first structured
                  performance review
                </li>
                <li>
                  Established transparent career paths and role requirements clarity
                  across all levels
                </li>
                <li>
                  Implemented effective time management for their hybrid work environment
                </li>
              </ul>
            </>
          }
          caption="Results"
          image={CARDINO_3}
          variant={FeatureBlockVariant.Right}
          showBookDemoButton={false}
          imageProps={{ height: 600 }}
          baseProps={{ maxWidth: 1000 }}
        />
      </VStack>

      <NextStep tourUrl={DEMO_PERFORMANCE_URL} />

      <Footer />
    </Box>
  )
}
