import {
  VStack,
  Text,
  Token,
  Item,
  Separator,
  HStack,
  Icon,
  Grid,
  Color,
  Button,
  Box,
  Tag,
  Flex,
} from '@revolut/ui-kit'
import { PlanFeatures } from '@src/pages/Forms/Plans/config'
import React from 'react'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { AvailableSubscriptionPlanInterface } from '@src/interfaces/plans'
import { PlanType, DiscountOption } from '@src/pages/Forms/Plans/types'
import { formatMoney } from '@src/utils/format'

interface PlanWidgetProps {
  backgroundColor: Color
  planDescription: string
  features: PlanFeatures[]
  plan: AvailableSubscriptionPlanInterface['subscription_plan']
  selectedPlan?: AvailableSubscriptionPlanInterface
  planType: PlanType
  discount: DiscountOption
}

export const PlanWidget = ({
  backgroundColor,
  planDescription,
  features,
  plan,
  selectedPlan,
  planType,
  discount,
}: PlanWidgetProps) => {
  const isSelectedPlan = selectedPlan?.subscription_plan.id === plan.id

  return (
    <Grid
      style={{ gridTemplateRows: 'subgrid', gridRow: 'span 6' }}
      backgroundColor={backgroundColor}
      py="s-16"
      radius="widget"
      gap="s-16"
    >
      <Flex px="s-24" justifyContent="space-between">
        <Text variant="heading2">{plan.name}</Text>

        {plan.tag ? (
          <Tag useIcon="Lightning" color={Token.color.deepPurple}>
            {plan.tag}
          </Tag>
        ) : null}
      </Flex>

      <Text variant="caption" px="s-24" color={Token.color.greyTone50}>
        {plan.description}
      </Text>

      <VStack align={{ all: 'start', md: 'center' }} px="s-24">
        <Text variant="h1">
          {formatMoney(
            Number(
              planType === 'monthly'
                ? plan.monthly_fee
                : Math.floor(
                    Number(plan.monthly_fee) * 12 * (1 - discount.discount / 100),
                  ),
            ),
            plan.currency,
          )}
        </Text>
        <Text variant="caption">per active employee/month</Text>
      </VStack>

      <Box px="s-32" display={{ all: 'none', md: 'block' }}>
        <Button
          useIcon={isSelectedPlan ? 'CheckSuccess' : undefined}
          variant={isSelectedPlan ? 'primary' : 'secondary'}
        >
          {isSelectedPlan
            ? 'Your current plan'
            : planType === 'monthly'
            ? 'Get started'
            : 'Contact us'}
        </Button>
      </Box>

      <Separator mx="s-16" />

      <VStack>
        <Text variant="h6" px="s-16">
          {planDescription}
        </Text>

        {features.map(feature => (
          <Item
            useIcon={feature.icon}
            iconColor={Token.color.foreground}
            key={feature.title}
            style={transparentThemeBackgroundOverrides}
          >
            <Item.Content>
              {feature.title ? (
                <Item.Title>
                  <Text variant="h6">{feature.title}</Text>
                </Item.Title>
              ) : null}
              <Item.Description>
                <VStack space="s-2" pt="s-8">
                  {feature.items?.map(item => (
                    <HStack space="s-8" align="center" key={item}>
                      <Icon name="16/CheckCircle" size={16} />
                      <Text>{item}</Text>
                    </HStack>
                  ))}
                </VStack>
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </VStack>
    </Grid>
  )
}
