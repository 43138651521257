import { MergeConnectionInterface } from '@src/interfaces/integrations'
import { SyncBanner } from '@src/pages/Hub/Integrations/components/SyncBanner'
import React from 'react'

interface MergeIntegrationItemsProps {
  integration?: MergeConnectionInterface
}
export const MergeIntegrationItems = ({ integration }: MergeIntegrationItemsProps) => {
  if (!integration) {
    return null
  }

  return (
    <>
      <SyncBanner integration={integration} />
    </>
  )
}
