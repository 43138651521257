import React, { useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import { Cell, Label, Pie, PieChart as PieChartComponent, Legend } from 'recharts'
import type { PolarViewBox } from 'recharts/types/util/types'
import { Absolute, Token, Relative } from '@revolut/ui-kit'
import { formatMoney } from '@src/utils/format'
import { PieLegend } from './PieLegend'
import { getColorByIndex } from './helpers'

import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import {
  getProjectedShapeColor,
  getSecondaryShapeColor,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'

const END_ANGLE = -40
const START_ANGLE = 220
const INNER_CIRCLE_SIZE = '90%'
const OUTER_CIRCLE_SIZE = '100%'
const CORNER_RADIUS = 50
const PADDING_ANGLE = 3
const ANIMATION_DURATION = 500
const FONT_RADIUS_RATIO_AMOUNT = 0.2
const FONT_RADIUS_RATIO_LABEL = 0.15
const STROKE_DASHARRAY = 3
const STROKE_WIDTH = 2

type ValueOf<T> = T[keyof T]

type ListItem = {
  color?: ValueOf<typeof Token.colorChannel>
  isProjected?: boolean
  name: string
  value: number
}

type PieGraphProps = {
  currency?: string
  data: ListItem[]
  hideLegend?: boolean
  isLoading?: boolean
  label?: string
  legendTooltipData?: {
    key: string
    tooltip: JSX.Element
  }[]
  total?: number
}

export const PieChart = ({
  currency,
  data,
  hideLegend = false,
  isLoading,
  label,
  legendTooltipData,
  total,
}: PieGraphProps) => {
  const { ref, width, height } = useResizeObserver<HTMLDivElement>()
  const fallbackHeight = height || 300

  const pieChartCells = useMemo(() => {
    return data.map(({ color, isProjected }, index) => {
      const fill = isProjected ? getProjectedShapeColor : getSecondaryShapeColor
      const strokeDasharray = isProjected ? STROKE_DASHARRAY : 0
      const strokeWidth = isProjected ? STROKE_WIDTH : 0

      return (
        <Cell
          className="pieChartCellsDashed"
          key={`outer-cell-${index}`}
          fill={fill(color || Token.colorChannel[getColorByIndex(index)])}
          stroke={Token.color.widgetBackground}
          strokeDasharray={strokeDasharray}
          strokeWidth={strokeWidth}
          style={{ outline: 'none' }}
        />
      )
    })
  }, [data])

  if (isLoading) {
    return <ChartSkeleton height={fallbackHeight} />
  }

  return (
    <Relative ref={ref} height="100%" width="100%">
      <Absolute bottom={0} left={0} right={0} top={0}>
        {height ? (
          <ResponsiveContainer height={height} width="100%">
            <PieChartComponent width={width} height={height}>
              <Pie
                cx="50%"
                cy="60%"
                data={data}
                dataKey="value"
                innerRadius={INNER_CIRCLE_SIZE}
                outerRadius={OUTER_CIRCLE_SIZE}
                startAngle={START_ANGLE}
                endAngle={END_ANGLE}
                cornerRadius={CORNER_RADIUS}
                paddingAngle={PADDING_ANGLE}
                animationDuration={ANIMATION_DURATION}
              >
                {pieChartCells}
                <Label
                  content={props => {
                    const viewBox = (props.viewBox || {}) as PolarViewBox
                    const cx = Number(viewBox.cx || 0)
                    const cy = Number(viewBox.cy || 0)

                    const positioningProps = {
                      x: cx,
                      y: cy,
                      textAnchor: 'middle',
                    }

                    const labelFontSize = viewBox?.innerRadius
                      ? Math.floor(viewBox.innerRadius * FONT_RADIUS_RATIO_LABEL)
                      : Token.fontSize.emphasis1
                    const amountFontSize = viewBox?.innerRadius
                      ? Math.floor(viewBox.innerRadius * FONT_RADIUS_RATIO_AMOUNT)
                      : Token.fontSize.heading2

                    return (
                      <text pointerEvents="none">
                        {label && (
                          <tspan
                            {...positioningProps}
                            dy="-1.5em"
                            fill={Token.color.greyTone50}
                            fontSize={labelFontSize}
                          >
                            {label}
                          </tspan>
                        )}
                        <tspan
                          {...positioningProps}
                          dy="0em"
                          fill={Token.color.foreground}
                          fontSize={amountFontSize}
                          fontWeight={700}
                        >
                          {formatMoney(total, currency)}
                        </tspan>
                      </text>
                    )
                  }}
                />
              </Pie>

              {!hideLegend && (
                <Legend
                  content={props => (
                    <PieLegend
                      currency={currency}
                      legendTooltipData={legendTooltipData}
                      {...props}
                    />
                  )}
                />
              )}
            </PieChartComponent>
          </ResponsiveContainer>
        ) : null}
      </Absolute>
    </Relative>
  )
}
