import React, { useMemo } from 'react'
import {
  ActionButton,
  ExpandableCell,
  HStack,
  Icon,
  Spacer,
  Tag,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { RequirementsList } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/Requirements'
import { MetricType } from '@src/features/GrowthPlans/GrowthPlan/components/MetricsConnector/ConnectMetricPopup'

interface Props {
  name: string
  action: GrowthPlanActionInterface
  metricTypes: MetricType[]
  requirements: string[]
  requirementsTitle: string
  onDelete: VoidFunction
  onConnectMetric: VoidFunction
  onDisconnectMetric: VoidFunction
}

export const ActionItem = ({
  name,
  action,
  metricTypes,
  requirements,
  requirementsTitle,
  onDelete,
  onConnectMetric,
  onDisconnectMetric,
}: Props) => {
  const connectMetricLabel = useMemo<string>(
    () =>
      metricTypes.includes('companyvalue') ? 'Connect skill or value' : 'Connect skill',
    [metricTypes],
  )

  return (
    <VStack
      p="s-16"
      space="s-16"
      borderRadius="r16"
      border={`1px solid ${Token.color.grey50_20}`}
    >
      <HStack space="s-8" align="center">
        <Tag>
          <TextButton color={Token.color.white} onClick={onConnectMetric}>
            <HStack p="s-4" align="center" space="s-6">
              <Icon name={action.object_id ? 'Pencil' : 'Plus'} size={12} />
              {!action.object_id && <Text>{connectMetricLabel}</Text>}
            </HStack>
          </TextButton>
        </Tag>
        {action.object_id && action.content_object && (
          <Tag variant="faded" useIcon="Palette">
            <HStack p="s-4" align="center" space="s-6">
              {action.content_object.name}
              <TextButton color={Token.color.foreground} onClick={onDisconnectMetric}>
                <Icon name="Cross" size={12} />
              </TextButton>
            </HStack>
          </Tag>
        )}
        <Spacer />
        <ActionButton useIcon="Delete" iconOnly onClick={onDelete}>
          Delete
        </ActionButton>
      </HStack>
      {requirements.length > 0 && (
        <ExpandableCell
          prefersExpanded
          style={{
            border: `1px solid ${Token.color.greyTone10}`,
            backgroundColor: Token.color.widgetBackground,
          }}
        >
          <ExpandableCell.Title>{requirementsTitle}</ExpandableCell.Title>
          <ExpandableCell.Note>
            <RequirementsList requirements={requirements} />
          </ExpandableCell.Note>
        </ExpandableCell>
      )}
      <LapeHTMLEditor
        name={name}
        placeholder="To-do"
        required
        resetEmptyContent
        height={80}
      />
    </VStack>
  )
}
