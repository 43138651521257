import React from 'react'
import styled from 'styled-components'
import { CalibratedGradeInterface } from '@src/interfaces/supportTool'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'
import { ContributorType } from '@src/interfaces/talent/performance'
import { Box, Text, Token } from '@revolut/ui-kit'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { ROUTES } from '@src/constants/routes'
import { GradesMapInterface } from '@src/utils/grades'
import { getColorsAndGrade } from '@src/interfaces/scorecard'
import { CalibrationFlagsCell } from './CalibrationFlagsCell'

const TooltipContainer = ({ children }: { children: React.ReactNode }) => (
  <Box p="s-12" minWidth={140}>
    {children}
  </Box>
)

const Cell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const calibratedGradeEmployeeNameColumn: ColumnInterface<CalibratedGradeInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.all_employees,
    title: 'Name',
    dynamicHyperlinks: data =>
      pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id }),
  }

export const calibratedGradeCycleColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.text,
  idPoint: 'cycle.id',
  dataPoint: 'cycle.name',
  sortKey: 'cycle__offset',
  filterKey: 'cycle__id',
  selectorsKey: selectorKeys.all_review_cycles,
  title: 'Cycle',
}

export const calibratedDeliverablesRatingColumn: ColumnInterface<CalibratedGradeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'deliverables_rating_label',
    dataPoint: 'deliverables_rating_label',
    sortKey: 'deliverables_rating_score__value',
    filterKey: 'deliverables_rating_label',
    selectorsKey: selectorKeys.performance_ratings,
    title: 'Delivery (LM)',
    masked: false,
    maskTooltip: {
      isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
      isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
    },
    insert: ({ data }) => (
      <Cell>
        {data.deliverables_rating_label ? (
          <PerformanceRatingLabelTag
            rating={data.deliverables_rating_label}
            fontWeight={400}
            fontSize="small"
          />
        ) : (
          '-'
        )}
      </Cell>
    ),
  }

export const calibratedLMRatingColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager_grade_suggestion',
  dataPoint: 'line_manager_grade_suggestion',
  sortKey: 'line_manager_grade_suggestion_score__value',
  filterKey: 'line_manager_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'LM Grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const calibratedFMRatingColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'functional_manager_grade_suggestion',
  dataPoint: 'functional_manager_grade_suggestion',
  sortKey: 'functional_manager_grade_suggestion_score__value',
  filterKey: 'functional_manager_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'FM Grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const calibratedBarRaiserColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'bar_raiser',
  dataPoint: 'bar_raiser',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bar raiser',
}

export const getCalibratedDeliverablesGradeColumn: (
  gradesMap: GradesMapInterface,
) => ColumnInterface<CalibratedGradeInterface> = gradesMap => ({
  type: CellTypes.insert,
  idPoint: 'deliverables_recommended_grade',
  dataPoint: 'deliverables_recommended_grade',
  sortKey: 'deliverables_recommended_grade',
  filterKey: 'deliverables_recommended_grade',
  selectorsKey: selectorKeys.grades,
  title: 'Delivery (LM)',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  insert: ({ data }) => {
    if (!data.deliverables_recommended_grade) {
      return null
    }
    const gradeData = getColorsAndGrade(data.deliverables_recommended_grade, gradesMap)
    return (
      <Cell>
        {gradeData.grade ? (
          <Text color={gradeData.noBgColor} fontWeight={400} fontSize="small">
            {gradeData.grade}
          </Text>
        ) : (
          '-'
        )}
      </Cell>
    )
  },
})

export const calibratedHofGradeColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'function_owner_grade_comment',
  dataPoint: 'function_owner_grade_comment',
  sortKey: 'function_owner_grade_suggestion__value',
  filterKey: 'function_owner_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'HoF',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const calibratedContributorTypeColumn: ColumnInterface<CalibratedGradeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'contributor_type',
    dataPoint: 'contributor_type',
    sortKey: 'reviewed_employee_type',
    filterKey: 'reviewed_employee_type',
    selectorsKey: selectorKeys.performance_reviewed_employee_type,
    title: 'Type',
    insert: ({ data }) =>
      data.reviewed_employee_type === ContributorType.Mgr ? (
        <Cell>Managers</Cell>
      ) : (
        <Cell>Individual Contributors</Cell>
      ),
  }

export const calibratedEligibilityStatusColumn: ColumnInterface<CalibratedGradeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'is_disabled',
    dataPoint: 'is_disabled',
    sortKey: 'eligibility__is_disabled',
    filterKey: 'eligibility__is_disabled',
    selectorsKey: () =>
      Promise.resolve({
        options: [
          { id: 'false', name: 'Eligible' },
          { id: 'true', name: 'Ineligible' },
        ],
      }),
    title: 'Eligibility status',
    insert: ({ data }) => (
      <Cell>
        {data.is_disabled ? (
          <Text color={Token.color.danger}>Ineligible</Text>
        ) : (
          <Text color={Token.color.success}>Eligible</Text>
        )}
      </Cell>
    ),
  }

export const calibratedHodGradeColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'department_owner_grade_suggestion',
  dataPoint: 'department_owner_grade_suggestion',
  sortKey: 'department_owner_grade_suggestion__value',
  filterKey: 'department_owner_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'HoD',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const calibratedSkillsRatingColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'functional_skills_label',
  dataPoint: 'functional_skills_label',
  sortKey: 'functional_skills__ordering_value',
  filterKey: 'functional_skills_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Skills (FM)',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  insert: ({ data }) => (
    <Cell>
      {data.functional_skills_label ? (
        <PerformanceRatingLabelTag
          rating={data.functional_skills_label}
          fontWeight={400}
          fontSize="small"
        />
      ) : (
        '-'
      )}
    </Cell>
  ),
}

export const calibratedGradeDisplayGradeColumn: ColumnInterface<CalibratedGradeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'display_grade',
    dataPoint: 'display_grade',
    sortKey: 'display_grade__value',
    filterKey: 'display_grade',
    selectorsKey: selectorKeys.performance_grades,
    title: 'Final grade',
    masked: false,
    maskTooltip: {
      isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
      isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
    },
  }

export const calibratedOverallRatingColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'absolute_rating_label',
  dataPoint: 'absolute_rating_label',
  sortKey: 'absolute_rating_score__value',
  filterKey: 'absolute_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Overall rating',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const calibrationNeededColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'grade_calibration_needed',
  dataPoint: 'grade_calibration_needed',
  sortKey: 'calibration_flags',
  filterKey: 'calibration_flags',
  selectorsKey: selectorKeys.performance_calibration_flags,
  title: 'Flags',
  insert: ({ data }) => <CalibrationFlagsCell data={data} />,
}

export const commentColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'performance_team_comment',
  dataPoint: 'performance_team_comment',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Comment',
}

export const selfReviewStatusColumn: ColumnInterface<CalibratedGradeInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_self_review_completed',
  dataPoint: 'is_self_review_completed',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Self Review',
  insert: ({ data }) => (data.is_self_review_completed ? 'Completed' : '-'),
}
