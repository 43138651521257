import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes } from '@src/interfaces/data'
import { ImportBonusesInterface } from '@src/interfaces/importBonuses'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import {
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportBonusesColumn = GenericEditableTableColumn<ImportBonusesInterface>

export const importBonusesEmployeeEmailColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'employee_email',
  dataPoint: 'employee_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee Email',
  insert: ({ data }) => (
    <TextCell data={data} field="employee_email" onChange={onChange} />
  ),
})

export const importBonusesBonusCycleColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_cycle',
  dataPoint: 'bonus_cycle',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Cycle',
  insert: ({ data }) => <TextCell data={data} field="bonus_cycle" onChange={onChange} />,
})

export const importBonusesBonusCategoryColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_category',
  dataPoint: 'bonus_category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Category',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="bonus_category"
      onChange={onChange}
      selector={selectorKeys.employee_bonus_categories}
      selectorField="name"
    />
  ),
})

export const importBonusesTargetColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_amount',
  dataPoint: 'bonus_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Amount',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="bonus_amount"
      onChange={onChange}
      type={TableCellInputType.positiveFloat}
    />
  ),
})

export const importBonusesBonusCurrencyISOCodeColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_currency_iso_code',
  dataPoint: 'bonus_currency_iso_code',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.currencies,
  title: 'Bonus Currency ISO Code',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="bonus_currency_iso_code"
      onChange={onChange}
      selector={selectorKeys.currencies}
      selectorField="iso_code"
    />
  ),
})

export const importBonusesBonusDateColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_date',
  dataPoint: 'bonus_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Date',
  insert: ({ data }) => <TextCell data={data} field="bonus_date" onChange={onChange} />,
})

export const importBonusesTaxabilityColumn: ImportBonusesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'taxability',
  dataPoint: 'taxability',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.employee_bonus_taxabilities,
  title: 'Taxability',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="taxability"
      onChange={onChange}
      selector={selectorKeys.employee_bonus_taxabilities}
      selectorField="name"
    />
  ),
})
