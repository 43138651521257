import React from 'react'
import { ReviewCategory, SummarySkillCardInterface } from '@src/interfaces/performance'
import RatingTooltip from '@src/pages/EmployeeProfile/Preview/Performance/Common/RatingTooltip'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { pathToUrl } from '@src/utils/router'
import { Link, useLocation } from 'react-router-dom'
import { RatingLabelTypes } from '@src/features/EmployeePerformance/RatingLabel'
import SummaryJustification from '@components/ColumnInserts/Justification/SummaryJustification'
import { Box, Flex, Text } from '@revolut/ui-kit'
import { getLocationDescriptor } from '@src/actions/RouterActions'

interface Props {
  data: SummarySkillCardInterface
  cycleId?: string
  employeeId?: number
  category: ReviewCategory
  hideJustification?: boolean
}

const SummaryCardRating = ({
  data,
  cycleId,
  employeeId,
  category,
  hideJustification,
}: Props) => {
  const location = useLocation()

  if (!data.rating) {
    return <>N/A</>
  }

  const path = getReviewsUrl(category, cycleId, employeeId)
  const hashKey = data.skill_id || data.criteria_key

  return (
    <Flex justifyContent="space-between" width="100%">
      <Flex>
        {data.ratings?.length ? (
          <Link
            to={{
              ...getLocationDescriptor(pathToUrl(path, { id: cycleId, employeeId })),
              hash: hashKey ? `#${hashKey}` : '',
              search: location.search,
            }}
          >
            <RatingTooltip
              ratings={data.ratings!}
              totalRating={data.rating}
              placement="top"
              type={RatingLabelTypes.InverseUnderline}
            />
          </Link>
        ) : (
          <RatingTooltip
            ratings={data.ratings!}
            totalRating={data.rating}
            placement="top"
            type={RatingLabelTypes.InverseUnderline}
          />
        )}
        {data.ratings?.length ? (
          <Text fontSize="tiny" pl="s-8">
            {data.ratings.length}
          </Text>
        ) : null}
      </Flex>
      {hideJustification ? null : (
        <Box width="h5" ml="10px">
          <SummaryJustification data={data} />
        </Box>
      )}
    </Flex>
  )
}

export default SummaryCardRating
