import React from 'react'
import { format } from 'date-fns'

import Tooltip from '@components/Tooltip/Tooltip'
import { Box, chain, Flex, Text } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeTimeOffRequestCalendar,
  EmployeeTimeOffRequestsCalendarInterface,
  PublicHolidayCalendarItem,
} from '@src/interfaces/timeOff'
import { pathToUrl } from '@src/utils/router'
import {
  BORDER_WIDTH,
  MONTH_CELL_HEIGHT,
  MONTH_CELL_WIDTH,
  TooltipContainer,
} from './constants'
import {
  approvalStatusIdToIconColor,
  defaultBoxProps,
  getCellBoxProps,
  getRequestDayInfo,
  getRequestDayTextPeriod,
  getTimeOffCalendarInfoByDay,
  requestToIcon,
} from './helpers'
import { useOpenNewTab } from '@src/actions/RouterActions'

type MonthCellContentProps = {
  request: EmployeeTimeOffRequestCalendar | undefined
  publicHoliday: PublicHolidayCalendarItem | undefined
  isNonWorking: boolean
  canViewPolicy: boolean
  nonWorkingBody?: React.ReactNode
  isSingleRequest?: boolean
  onClick?: VoidFunction
  style?: React.CSSProperties
}
const MonthCellContent = ({
  request,
  isNonWorking,
  publicHoliday,
  canViewPolicy,
  nonWorkingBody,
  isSingleRequest,
  onClick,
  style,
}: MonthCellContentProps) => {
  if (isNonWorking && nonWorkingBody) {
    return <>{nonWorkingBody}</>
  }
  const boxProps = getCellBoxProps({
    request,
    isNonWorking,
    publicHoliday,
    canViewPolicy,
  })
  const nonWorking = isNonWorking || !!publicHoliday
  const requestDayInfo = getRequestDayInfo(request)
  const hasApprovalStatus = !!request?.approval_status.name

  const cardIcon = nonWorking ? (
    <Tooltip
      placement="bottom"
      body={
        publicHoliday ? (
          <TooltipContainer>
            <Text whiteSpace="pre">{publicHoliday.name}</Text>
          </TooltipContainer>
        ) : null
      }
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width={MONTH_CELL_WIDTH - BORDER_WIDTH * 2}
        height={MONTH_CELL_HEIGHT - BORDER_WIDTH * 2}
        aria-label={publicHoliday ? 'Bank holiday' : 'Non working'}
      >
        {requestToIcon({
          isNonWorking,
          publicHoliday,
        })}
      </Flex>
    </Tooltip>
  ) : (
    <Tooltip
      placement="bottom"
      body={
        request ? (
          <TooltipContainer>
            {chain(
              <Text whiteSpace="pre" fontWeight="600">
                {canViewPolicy ? request?.category.name : 'Off'}
              </Text>,
              <Text whiteSpace="pre">{getRequestDayTextPeriod(request)}</Text>,
              hasApprovalStatus && (
                <Text
                  whiteSpace="pre"
                  color={approvalStatusIdToIconColor(request?.approval_status.id)}
                >
                  {request?.approval_status.name}
                </Text>
              ),
            )}
          </TooltipContainer>
        ) : null
      }
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width={30}
        height={22}
        aria-label={`${
          canViewPolicy ? request?.category.name : 'Off'
        } · ${getRequestDayTextPeriod(request)}${
          hasApprovalStatus ? ` · ${request?.approval_status.name}` : ''
        }`}
      >
        {requestToIcon({ request, canViewPolicy })}
      </Flex>
    </Tooltip>
  )

  if (nonWorking || requestDayInfo?.isAllDay) {
    return (
      <Flex flex="1 0" {...boxProps} onClick={onClick} style={style}>
        {cardIcon}
      </Flex>
    )
  }
  if (requestDayInfo?.isMorning) {
    return (
      <>
        <Flex
          flex="1 0"
          {...boxProps}
          borderRadius="10px 10px 0 0"
          onClick={onClick}
          style={style}
        >
          {cardIcon}
        </Flex>
        {isSingleRequest && (
          <Flex flex="1 0" {...defaultBoxProps} borderRadius="0 0 10px 10px" />
        )}
      </>
    )
  }
  if (requestDayInfo?.isAfternoon) {
    return (
      <>
        {isSingleRequest && (
          <Flex flex="1 0" {...defaultBoxProps} borderRadius="10px 10px 0 0" />
        )}
        <Flex
          flex="1 0"
          {...boxProps}
          borderRadius="0 0 10px 10px"
          onClick={onClick}
          style={style}
        >
          {cardIcon}
        </Flex>
      </>
    )
  }
  return <Box {...boxProps} height="s-48" style={style} />
}

const MonthCellWrapper: React.FC<{ day: Date }> = ({ day, children }) => {
  return (
    <Flex
      aria-label={format(day, 'yyyy-MM-dd')}
      flexDirection="column"
      height={MONTH_CELL_HEIGHT}
      width={MONTH_CELL_WIDTH}
    >
      {children}
    </Flex>
  )
}

type Props = {
  day: Date
  requestsCalendar: EmployeeTimeOffRequestsCalendarInterface
  canViewPolicy?: boolean
  emptyRequestBody?: React.ReactNode
  nonWorkingBody?: React.ReactNode
}
export const TimeOffTableMonthCell = ({
  day,
  requestsCalendar,
  canViewPolicy,
  emptyRequestBody,
  nonWorkingBody,
}: Props) => {
  const { requests, isNonWorking, publicHoliday } = getTimeOffCalendarInfoByDay(
    requestsCalendar,
    day,
  )
  const nonWorking = isNonWorking || !!publicHoliday
  const isEmpty = requests.length === 0 && !nonWorking

  const openInNewTab = useOpenNewTab()

  if (isEmpty && emptyRequestBody) {
    return <>{emptyRequestBody}</>
  }

  if (nonWorking) {
    return (
      <MonthCellWrapper day={day}>
        <MonthCellContent
          canViewPolicy={!!canViewPolicy}
          isNonWorking={isNonWorking}
          nonWorkingBody={nonWorkingBody}
          publicHoliday={publicHoliday}
          request={undefined}
        />
      </MonthCellWrapper>
    )
  }

  return (
    <MonthCellWrapper day={day}>
      {requests.map(request => {
        return (
          <MonthCellContent
            canViewPolicy={!!canViewPolicy}
            isNonWorking={isNonWorking}
            isSingleRequest={requests.length === 1}
            key={request.id}
            nonWorkingBody={nonWorkingBody}
            onClick={() => {
              openInNewTab(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                  id: request.id,
                  employeeId: requestsCalendar.employee.id,
                }),
              )
            }}
            publicHoliday={publicHoliday}
            request={request}
            style={{ cursor: 'pointer' }}
          />
        )
      })}
    </MonthCellWrapper>
  )
}
