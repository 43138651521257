import React, { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { navigateReplace } from '@src/actions/RouterActions'
import {
  addRoadmaps,
  getUnassignedCompanyRoadmaps,
  getUnassignedRoadmaps,
  roadmapsRequestsNew,
} from '@src/api/roadmaps'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import {
  RoadmapInterface,
  JiraRoadmapPriorities,
  UnassignedRoadmapInterface,
} from '@src/interfaces/roadmaps'
import {
  Box,
  chain,
  InputGroup,
  Input,
  IconButton,
  Button,
  Item,
  VStack,
  HStack,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

import { useLocation } from 'react-router-dom'
import { CreateRoadmapLocationState } from './common/AddRoadmapMoreBarAction'
import { PageBody } from '@src/components/Page/PageBody'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { useGetDepartment } from '@src/api/department'
import { useGetTeam } from '@src/api/teams'
import { parseUnassignedRoadmaps } from '../AddRoadmap/AddRoadmap'

import { EmptySearchDescription } from '../AddRoadmap/EmptySearchDescription'
import { InitialSearchDescription } from '../AddRoadmap/InitialSearchDescription'
import { Tags } from '../AddRoadmap/Tags'
import { SidebarOption } from '@src/components/Inputs/SidebarMultiselect/types'
import { SelectMode } from '@src/components/Inputs/SidebarMultiselect/components/SelectMode'
import { SearchMode } from '@src/components/Inputs/SidebarMultiselect/components/SearchMode'
import { useRoadmapTypeOptions } from './common/useRoadmapTypeOptions'
import { ItemType } from '@src/interfaces/deliverables'

import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { isPlainObject } from 'lodash'
import { IdAndName } from '@src/interfaces'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { ClickupTasks } from '@src/features/Roadmaps/Clickup/ClickupTasks'
import { LinearTasks } from '@src/features/Roadmaps/Linear/LinearTasks'
import { type EpicOption } from '@src/interfaces/kpis'

const parseKeys = (roadmaps: SidebarOption[]) => {
  return roadmaps.map(option => option?.link?.name || '')
}

const getData = (
  values: LapeFormInterface<RoadmapInterface>['values'],
  entityType: 'company' | 'team' | 'department' | undefined,
) => {
  switch (entityType) {
    case 'department':
      return {
        entityType: EntityTypes.department,
        id: values.department?.id,
        settingUrl: pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, {
          id: values.department?.id,
        }),
        url: pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, {
          id: values.department?.id,
        }),
      }
    case 'team':
      return {
        entityType: EntityTypes.team,
        id: values.team?.id,
        settingUrl: pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {
          id: values.team?.id,
        }),
        url: pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, {
          id: values.team?.id,
        }),
      }
    default:
      return {
        entityType: EntityTypes.companyV2,
        url: pathToUrl(ROUTES.PERFORMANCE.GOALS.ROADMAPS),
      }
  }
}

type SidebarSelection = { [key: string]: SidebarOption }

/**
 * This is mostly copy & paste from /src/pages/Forms/AddRoadmap/AddRoadmap.tsx and /src/components/Inputs/SidebarMultiselect/SidebarMultiselect.tsx
 * with few adjmustments so that it works as a separate page for adding JIRA roadmaps on various levels
 */
export const NewIntegrationRoadmapForm = () => {
  const location = useLocation<CreateRoadmapLocationState>()

  const subtitle = location.state?.name || 'Integration roadmap'
  const [result, setResult] = useState<SidebarSelection>({})
  const [selectedTasks, setSelectedTasks] = useState<
    Pick<EpicOption, 'key' | 'url' | 'name' | 'type'>[]
  >([])
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false)
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [options, setOptions] = useState<SidebarOption[]>([])
  const [showSelected, setShowSelected] = useState(false)
  const backUrl = location.state?.backUrl || ROUTES.PERFORMANCE.GOALS.ROADMAPS
  const genericRoadmapCreationType = location.state.genericRoadmapCreationType
  const { values, apiErrors } = useLapeContext<RoadmapInterface>()
  const [searchValue, setSearchValue] = useState('')

  const showStatusPopup = useShowStatusPopup()
  const { data: departmentData } = useGetDepartment(values.department?.id)
  const { data: teamData } = useGetTeam(values.team?.id)
  const { options: roadmapTypeOptions, isLoading } = useRoadmapTypeOptions()

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    if (value) {
      setIsSearchPending(true)
      fetchOptions(value)
    } else {
      setOptions([])
    }
  }

  useEffect(() => {
    if (roadmapTypeOptions.length) {
      values.item_type = roadmapTypeOptions[0].id
    }
  }, [isLoading])

  let tags: string[] = []
  switch (genericRoadmapCreationType) {
    case 'department':
      tags = departmentData?.jira_projects || []
      break
    case 'team':
      tags = teamData?.jira_projects || []
      break
  }

  const selectedData = getData(values, genericRoadmapCreationType)

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        let request: Promise<UnassignedRoadmapInterface[]>
        if (selectedData.entityType === EntityTypes.companyV2) {
          request = getUnassignedCompanyRoadmaps(search).then(resp => resp.data.results)
        } else {
          if (!selectedData.id) {
            return
          }
          request = getUnassignedRoadmaps(
            selectedData.entityType,
            selectedData.id,
            search,
          ).then(resp => resp.data.results)
        }

        try {
          const roadmaps = await request
          setOptions(parseUnassignedRoadmaps(roadmaps))
        } finally {
          setIsSearchPending(false)
        }
      }, 1000),
    [selectedData.entityType, selectedData.id],
  )

  const handleClearAll = () => {
    setResult({})
    setShowSelected(false)
  }

  const handleItemClick = (item: SidebarOption, checked: boolean) => {
    const copyOfResult = { ...result }
    if (checked) {
      delete copyOfResult[`${item.id}`]
      setResult(copyOfResult)

      if (showSelected && !Object.keys(copyOfResult).length) {
        setShowSelected(false)
      }
    } else {
      copyOfResult[`${item.id}`] = item
      setResult(copyOfResult)
    }
  }

  const emptyDescription =
    searchValue === '' ? <InitialSearchDescription /> : <EmptySearchDescription />

  const onSubmit = async () => {
    setIsSubmitPending(true)
    if (!values.item_type) {
      setIsSubmitPending(false)
      return
    }
    const keys = parseKeys(Object.values(result))
    try {
      if (values.item_type === ItemType.Manual) {
        const priorityData: IdAndName<JiraRoadmapPriorities, JiraRoadmapPriorities> =
          isPlainObject(values.priority)
            ? (values.priority as unknown as IdAndName<
                JiraRoadmapPriorities,
                JiraRoadmapPriorities
              >)
            : { id: values.priority, name: values.priority }

        await roadmapsRequestsNew.submit(
          {
            is_company: values.is_company,
            ...(values.department && {
              department: values.department,
            }),
            ...(values.team && {
              team: values.team,
            }),
            owner: values.owner,
            review_cycle: values.review_cycle,
            name: values.name,
            priority: priorityData,
            start_date: values.start_date,
            due_date: values.due_date,
          },
          {},
        )
        showStatusPopup({ status: 'success', title: 'Roadmap Created' })
      } else if (selectedData.entityType === EntityTypes.companyV2) {
        await addRoadmaps({
          is_company: true,
          keys: [ItemType.Clickup, ItemType.Linear].includes(values.item_type)
            ? selectedTasks.map(task => task.key)
            : keys,
          item_type: values.item_type,
          review_cycle: values.review_cycle?.id,
        })
      } else if (selectedData.id) {
        await addRoadmaps({
          entityType: selectedData.entityType,
          id: selectedData.id,
          keys: [ItemType.Clickup, ItemType.Linear].includes(values.item_type)
            ? selectedTasks.map(task => task.key)
            : keys,
          item_type: values.item_type,
          review_cycle: values.review_cycle?.id,
        })
      } else {
        setIsSubmitPending(false)
        showStatusPopup({
          status: 'error',
          title: 'Failed to update accrual amount',
          description: 'Try again',
          actions: (
            <Button
              onClick={() => {
                navigateReplace(ROUTES.FORMS.ROADMAP.SELECT_LEVEL, location.state)
              }}
              elevated
            >
              Close
            </Button>
          ),
        })

        return
      }
      navigateReplace(location.state.backUrl || selectedData.url)
    } catch (err) {
      if (isPlainObject(err?.response?.data)) {
        apiErrors.name = err.response.data.name
        apiErrors.due_date = err.response.data.due_date
        apiErrors.start_date = err.response.data.start_date
        apiErrors.owner = err.response.data.owner
        apiErrors.review_cycle = err.response.data.review_cycle
        apiErrors.priority = err.response.data.priority
        apiErrors.department = err.response.data.department
        apiErrors.team = err.response.data.team
      } else {
        showStatusPopup({
          status: 'error',
          title: 'Roadmap not created',
          description: getMessageFromApiError(err),
        })
      }
    } finally {
      setIsSubmitPending(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader title="Create Roadmap" subtitle={subtitle} backUrl={backUrl} />
      <PageBody>
        <InputGroup>
          {values.item_type === ItemType.Manual && (
            <Item useIcon="TurboTransfer">
              <Item.Content>
                <Item.Title>Creating Roadmaps</Item.Title>
                <Item.Description>
                  Roadmaps are a high-level planning tool used to define an objective or
                  goal and major deliverables, tasks, or milestone steps required to
                  achieve it. You can create multiple roadmaps for your organisation in
                  each performance review cycle.
                </Item.Description>
              </Item.Content>
            </Item>
          )}
          {genericRoadmapCreationType === 'department' && (
            <LapeRadioSelectInput
              required
              name="department"
              selector={selectorKeys.department}
              label="Department"
            />
          )}
          {genericRoadmapCreationType === 'team' && (
            <LapeRadioSelectInput
              required
              name="team"
              selector={selectorKeys.team}
              label="Team"
            />
          )}
          <LapeRadioSelectInput
            label="Roadmap type"
            searchable={false}
            value={values.item_type && { id: values.item_type, name: values.item_type }}
            options={roadmapTypeOptions}
            loading={isLoading}
            onChange={val => {
              if (val) {
                values.item_type = val.id
              }
            }}
            name="item_type"
          />
          {(values.department || values.team || values.is_company) && values.item_type && (
            <>
              <LapeRadioSelectInput
                required
                name="review_cycle"
                label="Review Cycle"
                selector={selectorKeys.review_cycles}
              />
              {values.item_type === ItemType.Manual && (
                <>
                  <LapeNewTextArea label="Roadmap name" name="name" required />

                  <LapeRadioSelectInput
                    required
                    name="owner"
                    label="Owner"
                    selector={selectorKeys.employee}
                  />
                  <VStack space="s-16">
                    <LapeRadioSelectInput
                      name="priority"
                      label="Priority"
                      selector={selectorKeys.roadmap_priority}
                    />
                    <HStack space="s-16">
                      <LapeDatePickerInput
                        name="start_date"
                        label="Start Date"
                        hideOptional
                      />
                      <LapeDatePickerInput
                        name="due_date"
                        label="Due Date"
                        hideOptional
                      />
                    </HStack>
                  </VStack>
                  <PageActions>
                    <NewSaveButtonWithPopup
                      useValidator
                      onClick={onSubmit}
                      noPopup
                      preventUserClose
                    >
                      Submit
                    </NewSaveButtonWithPopup>
                  </PageActions>
                </>
              )}
              {values.item_type === ItemType.Clickup && (
                <>
                  <ClickupTasks
                    onSelectionChange={selectedOptions =>
                      setSelectedTasks(selectedOptions)
                    }
                    onDelete={key => {
                      setSelectedTasks(prev => prev.filter(item => item.key !== key))
                    }}
                    addedTasks={selectedTasks}
                  />
                  {!!selectedTasks.length && (
                    <Button
                      data-testid="jira-form-submit-button"
                      onClick={() => onSubmit()}
                      pending={isSubmitPending}
                      elevated
                    >
                      {chain('Add roadmaps', selectedTasks.length)}
                    </Button>
                  )}
                </>
              )}
              {values.item_type === ItemType.Linear && (
                <>
                  <LinearTasks
                    onSelectionChange={selectedOptions => {
                      const mappedOptions = selectedOptions.map(option => ({
                        ...option,
                        issue_type: option.type,
                        type: undefined,
                        key: option.id,
                      }))
                      setSelectedTasks(mappedOptions)
                    }}
                    onDelete={key => {
                      setSelectedTasks(prev => prev.filter(item => item.key !== key))
                    }}
                    addedTasks={selectedTasks}
                  />
                  {!!selectedTasks.length && (
                    <Button
                      data-testid="jira-form-submit-button"
                      onClick={() => onSubmit()}
                      pending={isSubmitPending}
                      elevated
                    >
                      {chain('Add roadmaps', selectedTasks.length)}
                    </Button>
                  )}
                </>
              )}
              {values.item_type === ItemType.Jira && (
                <>
                  {selectedData.entityType !== EntityTypes.companyV2 && (
                    <Tags
                      tags={tags}
                      onEditClick={() =>
                        selectedData.settingUrl &&
                        navigateReplace(selectedData.settingUrl)
                      }
                    />
                  )}
                  <Box mb="s-24">
                    <Input
                      label="Search jira"
                      value={searchValue}
                      data-testid="sidebar-multiselect-new-input"
                      pending={isSearchPending}
                      onChange={e => handleSearchChange(e.currentTarget.value)}
                      renderAction={() =>
                        searchValue && (
                          <IconButton
                            useIcon="CrossCircle"
                            size={24}
                            onClick={e => {
                              e.preventDefault()
                              setSearchValue('')
                              handleSearchChange('')
                            }}
                          />
                        )
                      }
                    />
                  </Box>
                  {showSelected ? (
                    <SelectMode
                      pending={isSearchPending}
                      selection={result}
                      handleClearAll={handleClearAll}
                      handleItemClick={handleItemClick}
                    />
                  ) : (
                    <SearchMode
                      pending={isSearchPending}
                      selection={result}
                      emptyDescription={emptyDescription}
                      searchValue={searchValue}
                      handleClearAll={handleClearAll}
                      handleItemClick={handleItemClick}
                      options={options}
                      emptySearchMessage={chain('Showing items', options.length)}
                    />
                  )}
                </>
              )}
              {!!Object.keys(result).length && (
                <Button
                  data-testid="jira-form-submit-button"
                  onClick={() => onSubmit()}
                  pending={isSubmitPending}
                  elevated
                >
                  {chain('Add roadmap', Object.keys(result).length)}
                </Button>
              )}
            </>
          )}
        </InputGroup>
      </PageBody>
    </PageWrapper>
  )
}
