import { ROUTES } from '@src/constants/routes'
import { GlobalSearchTypes } from '@src/interfaces/globalSearch'
import { pathToUrl } from '@src/utils/router'
import { IconName } from '@revolut/ui-kit/types/dist/components/Icon/types'
import {
  TEAM_DEFAULT_ICON,
  DEPARTMENT_DEFAULT_ICON,
  SKILLS_DEFAULT_ICON,
  FUNCTION_DEFAULT_ICON,
  GOAL_DEFAULT_ICON,
} from '@src/constants/common'

export const getGlobalSearchItemFormUrl = (type: GlobalSearchTypes, id?: number) => {
  switch (type) {
    case 'employee':
      return pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })
    case 'requisition':
      return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id })
    case 'team':
      return pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id })
    case 'department':
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id })
    case 'company':
      return pathToUrl(ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL)
    case 'function':
      return pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, { id })
    case 'role':
      return pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id })
    case 'specialisation':
      return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id })
    case 'skill':
      return pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id })
    case 'team_kpi':
    case 'department_kpi':
    case 'employee_kpi':
    case 'company_kpi':
    case 'company_goal':
      return pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id })
    default:
      return undefined
  }
}

export const typeToIconMap: Partial<Record<GlobalSearchTypes, IconName>> = {
  team: TEAM_DEFAULT_ICON,
  company: DEPARTMENT_DEFAULT_ICON,
  department: DEPARTMENT_DEFAULT_ICON,
  skill: SKILLS_DEFAULT_ICON,
  function: FUNCTION_DEFAULT_ICON,
  requisition: FUNCTION_DEFAULT_ICON,
  role: FUNCTION_DEFAULT_ICON,
  specialisation: FUNCTION_DEFAULT_ICON,
  team_kpi: GOAL_DEFAULT_ICON,
  department_kpi: GOAL_DEFAULT_ICON,
  employee_kpi: GOAL_DEFAULT_ICON,
  company_kpi: GOAL_DEFAULT_ICON,
  company_goal: GOAL_DEFAULT_ICON,
}
