import React from 'react'
import { Box, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import {
  TimeSeriesInterface,
  TimeSeriesBreakdownInterface,
} from '@src/interfaces/totalCompensation'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { formatMoney, formatNumber } from '@src/utils/format'
import {
  ON_TARGET_BONUS_LABEL,
  VESTED_EQUITY_LABEL,
} from '../Components/CompensationOverTime'
import { BarChartDataSeriesInterface } from '../Components/EquityOverTime'

interface TooltipValueInterface {
  color?: string
  label: string
  value: string
}

const renderBonusBreakdown = (breakdown: TimeSeriesBreakdownInterface[]) => {
  if (breakdown.length === 0) {
    return null
  }

  return (
    <Box pl="s-24">
      <ul>
        {breakdown.map((item: TimeSeriesBreakdownInterface) => (
          <li key={item.year}>
            <Flex gap="s-20">
              <Text color={Token.color.background} variant="body3" use="div">
                {`Bonus from ${item.year}:`}
              </Text>
              <Text color={Token.color.background} ml="auto" variant="body3" use="div">
                {formatNumber(Number(item.value))}
              </Text>
            </Flex>
          </li>
        ))}
      </ul>
    </Box>
  )
}

const renderEquityBreakdown = (breakdown: TimeSeriesBreakdownInterface[]) => {
  if (breakdown.length === 0) {
    return null
  }

  const aggregatedByYearData = breakdown.reduce<Record<string, TimeSeriesInterface>>(
    (acc, item) => {
      if (!acc[item.year]) {
        acc[item.year] = { breakdown: [], value: '0.00' }
      }
      acc[item.year].breakdown.push(item)
      return acc
    },
    {},
  )

  return (
    <Box pl="s-24">
      <ul>
        {Object.keys(aggregatedByYearData).map(year => (
          <li key={year}>
            <VStack>
              <Text color={Token.color.background} variant="body3" use="div">
                {`Equity from ${year}:`}
              </Text>
              <ul>
                {aggregatedByYearData[year].breakdown.map(({ reason, value }, i) => (
                  <Flex gap="s-20" key={`${reason}_${i}`}>
                    <Text
                      color={Token.color.background}
                      variant="body3"
                      use="div"
                    >{`${reason}:`}</Text>
                    <Text
                      color={Token.color.background}
                      ml="auto"
                      variant="body3"
                      use="div"
                    >
                      {formatNumber(Number(value))}
                    </Text>
                  </Flex>
                ))}
              </ul>
            </VStack>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export const BarChartTooltip = (
  props: any,
  dataKeys?: DataKey<BarChartDataSeriesInterface>[],
) => {
  const values = dataKeys?.reduce((acc: TooltipValueInterface[], dataKey) => {
    if (Number(props[dataKey.value])) {
      acc.push({
        color: dataKey.color ? `rgb(${dataKey.color})` : undefined,
        label: dataKey.label,
        value: props[dataKey.value],
      })
    }
    return acc
  }, [])

  return (
    <VStack space="s-4">
      {props.total && (
        <Flex justifyContent="space-between">
          <Text color={Token.color.background} pr="s-16" variant="emphasis2">
            {props.year}
          </Text>
          <Text color={Token.color.background} variant="emphasis2">
            {formatMoney(Number(props.total), props.currency.iso_code)}
          </Text>
        </Flex>
      )}

      {dataKeys &&
        values?.map((value, id) => (
          <Box key={id}>
            <Flex alignItems="center" justifyContent="flex-start" gap="s-12">
              {value.color ? (
                <Box
                  bg={value.color}
                  border={`1px solid ${Token.color.greyTone50}`}
                  borderRadius={Token.radius.r2}
                  height="s-12"
                  width="s-12"
                />
              ) : null}
              <Text color={Token.color.background} variant="body3" use="div">
                {value.label}:
              </Text>
              <Text color={Token.color.background} ml="auto" variant="body3" use="div">
                {formatNumber(Number(value.value))}
              </Text>
            </Flex>
            {value.label === ON_TARGET_BONUS_LABEL &&
              renderBonusBreakdown(props.on_target_bonus.breakdown)}
            {value.label === VESTED_EQUITY_LABEL &&
              renderEquityBreakdown(props.vested_equity.breakdown)}
          </Box>
        ))}
    </VStack>
  )
}
