import React, { useState } from 'react'
import {
  VStack,
  Text,
  TabBar,
  Token,
  TabBarSkeleton,
  Grid,
  Box,
  useMatchMedia,
  Button,
  chain,
} from '@revolut/ui-kit'
import { plansConfig } from './config'
import { ROUTES } from '@src/constants/routes'
import { useSubscriptionPlanContext } from './SubscriptionPlanProvider'
import { useAvailableSubscriptionPlans, useSubsciptionInfo } from '@src/api/plans'
import { pathToUrl } from '@src/utils/router'
import { useScrollToTop } from '@src/hooks/useScrollToTop'
import { PlanWidget } from '@src/pages/Forms/Plans/PlanWidget'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { formatPercentage } from '@src/utils/format'
import { PlanType, DiscountOption } from '@src/pages/Forms/Plans/types'
import { PageActions } from '@components/Page/PageActions'

const MAX_ANNUAL_DISCOUNT = 0.4

const COMPANY_SIZES: DiscountOption[] = [
  { id: 1, name: '1-10 employees', discount: 0 },
  { id: 10, name: '10-50 employees', discount: 5 },
  { id: 50, name: '50-100 employees', discount: 10 },
  { id: 100, name: '100-250 employees', discount: 15 },
  { id: 250, name: '250-500 employees', discount: 20 },
  { id: 500, name: '500-1000 employees', discount: 25 },
  { id: 1000, name: '1000+ employees', discount: MAX_ANNUAL_DISCOUNT * 100 },
]

const COMPANY_SIZES_OPTIONS = COMPANY_SIZES.map(size => ({
  value: size,
  label: size.name,
}))

export const SelectPlanNew = () => {
  const { plans } = useAvailableSubscriptionPlans()
  const { selectedPlan, setSelectedPlan } = useSubscriptionPlanContext()
  const [mobilePlanId, setMobilePlanId] = useState<number>()
  const { info, isLoading: isInfoLoading } = useSubsciptionInfo()
  const isMd = useMatchMedia(Token.media.md)

  useScrollToTop()

  const [planType, setPlanType] = useState<PlanType>()
  const [companySize, setCompanySize] = useState<DiscountOption>(COMPANY_SIZES[0])
  const [hasAppliedForAnnualPlan, setHasAppliedForAnnualPlan] = useState(false)

  const isOnCustomPlan = !!info?.subscription_plan?.manual_payment
  const isOnMonthlyPlan = info?.subscription_plan?.manual_payment === null
  const subscribedPlan = info?.subscription_plan
  const subscribedPlanId = info?.subscription_plan?.id
  const isCurrentPlanSelected =
    selectedPlan && subscribedPlanId === selectedPlan.subscription_plan.id

  if (!selectedPlan && plans?.[0]) {
    setSelectedPlan(plans[0])
  }

  if (info?.custom_plan_requested_at && !hasAppliedForAnnualPlan) {
    setHasAppliedForAnnualPlan(true)
  }

  if (!planType && (isOnCustomPlan || hasAppliedForAnnualPlan)) {
    setPlanType('annual')
  }

  if (
    !planType &&
    (isOnMonthlyPlan || isCurrentPlanSelected || (!isInfoLoading && !subscribedPlanId))
  ) {
    setPlanType('monthly')
  }

  if (!mobilePlanId && plans?.[0]) {
    setMobilePlanId(plans[0].subscription_plan.id)
  }

  return (
    <PageWrapper fullWidth>
      <PageHeader
        title={subscribedPlan ? 'Manage your plan' : 'Find your perfect plan'}
        backUrl={pathToUrl(ROUTES.MAIN)}
      />
      <PageBody maxWidth={Token.breakpoint.xl}>
        <VStack gap="s-24">
          <TabBar
            display={{ all: 'flex', md: 'none' }}
            value={String(mobilePlanId)}
            onChange={value => {
              if (value) {
                setMobilePlanId(Number(value))
              }
            }}
          >
            {plans?.map(plan => (
              <TabBar.Item
                to={String(plan.subscription_plan.id)}
                key={plan.subscription_plan.id}
              >
                {plan.subscription_plan.name}
              </TabBar.Item>
            ))}
          </TabBar>

          <VStack space="s-16" align="center">
            {planType ? (
              <TabBar
                value={planType}
                onChange={value => {
                  if (value) {
                    setPlanType(value)
                  }
                }}
                variant="segmented fit"
                mx="auto"
                display={{ all: 'none', md: 'flex' }}
              >
                <TabBar.Item to="monthly">Monthly</TabBar.Item>
                <TabBar.Item to="annual">
                  {chain('Annual', `Save ${formatPercentage(MAX_ANNUAL_DISCOUNT)}`)}
                </TabBar.Item>
              </TabBar>
            ) : (
              <TabBarSkeleton variant="segmented" width={300}>
                <TabBarSkeleton.Item aria-selected />
                <TabBarSkeleton.Item />
              </TabBarSkeleton>
            )}

            {planType === 'annual' ? (
              <Box
                width={{ all: '100%', md: undefined }}
                maxWidth={{ all: '100%', md: 460 }}
              >
                <RadioSelectInput
                  options={COMPANY_SIZES_OPTIONS}
                  label="How big is your company?"
                  onChange={val => {
                    if (val) {
                      setCompanySize(val)
                    }
                  }}
                  value={companySize}
                />
              </Box>
            ) : null}
          </VStack>

          <Grid columns={{ all: 1, md: 4 }} gap="s-24">
            {planType &&
              plans?.map((plan, key) => {
                if (!isMd && plan.subscription_plan.id !== mobilePlanId) {
                  return null
                }

                return (
                  <PlanWidget
                    plan={plan.subscription_plan}
                    selectedPlan={selectedPlan}
                    backgroundColor={
                      !isMd ? Token.color.widgetBackground : plansConfig[key].bgColor
                    }
                    features={plansConfig[key].features}
                    planDescription={plansConfig[key].planDescription}
                    planType={planType}
                    key={plan.subscription_plan.id}
                    discount={companySize}
                  />
                )
              })}
          </Grid>

          <Text
            color={Token.color.greyTone20}
            variant="emphasis2"
            textAlign="center"
            use="div"
            pb="s-32"
          >
            All prices exclude tax, which is charged as 20% on your base price.
          </Text>
        </VStack>
      </PageBody>

      <PageActions display={{ all: 'flex', md: 'none' }}>
        <VStack gap="s-16" pb="s-32">
          <Button>{planType === 'monthly' ? 'Get started' : 'Contact us'}</Button>
          <Button
            variant="secondary"
            onClick={() => setPlanType(planType === 'monthly' ? 'annual' : 'monthly')}
          >
            {planType === 'monthly' ? 'View annual plans' : 'View monthly plans'}
          </Button>
        </VStack>
      </PageActions>
    </PageWrapper>
  )
}
