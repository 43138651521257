import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { StructuresRolesGeneral } from './RolesGeneral'
import { StructuresFunctionsSettings } from './FunctionSettings'
import { StructuresRolesSettings } from './RoleSettings'
import { StructuresSpecialisationsSettings } from './SepcialisationSettings'
import { StructuresRolesGeneralSettings } from './RolesGeneralSettings'

export const StructuresRoles = () => {
  return (
    <Switch>
      <Route path={ROUTES.SETTINGS.STRUCTURES.ROLES.ALL} exact>
        <StructuresRolesGeneral />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.ROLES.FUNCTIONS}>
        <StructuresFunctionsSettings />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.ROLES.ROLES}>
        <StructuresRolesSettings />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.ROLES.SPECIALISATIONS}>
        <StructuresSpecialisationsSettings />
      </Route>
      <Route path={ROUTES.SETTINGS.STRUCTURES.ROLES.GENERAL}>
        <StructuresRolesGeneralSettings />
      </Route>
    </Switch>
  )
}
