import {
  MEETINGS_EXTENSION_COMMERCIAL,
  MEETINGS_EXTENSION_INTERNAL,
} from '@src/constants/externalLinks'
import useIsCommercial from '@src/hooks/useIsCommercial'

const INTERNAL_EXTENSION_ID = 'epeiijalnefjildjmbmmoggkgijbmdkk'
const EXTERNAL_EXTENSION_ID = 'lbmhgmdffeiempijemglnnijljkinfie'

export const useExtensionData = () => {
  const isCommercial = useIsCommercial()
  const extensionLink = isCommercial
    ? MEETINGS_EXTENSION_COMMERCIAL
    : MEETINGS_EXTENSION_INTERNAL
  const extensionId = isCommercial ? EXTERNAL_EXTENSION_ID : INTERNAL_EXTENSION_ID

  return { extensionLink, extensionId }
}
