import React, { useEffect, useState } from 'react'
import {
  Box,
  Item,
  ItemSkeleton,
  Subheader,
  TopNav,
  Popover,
  Popup,
  Header,
} from '@revolut/ui-kit'
import { format } from 'date-fns'
import { useQueryClient } from 'react-query'
import { useTable } from '@components/TableV2/hooks'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  getSystemNotifications,
  markNotificationAsRead,
  dismissNotification,
  markAllNotificationsAsRead,
} from '@src/api/systemNotifications'
import { SystemNotificationInterface } from '@src/interfaces/systemNotifications'
import { NotificationsListItem } from '@src/features/Notifications/NotificationsListItem'
import produce from 'immer'
import { NotificationAction } from '@src/interfaces/notificationTemplate'
import { EditorView } from '@components/Editor/EditorView'

import { useOpenNewTab } from '@src/actions/RouterActions'
import { API } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface NotificationsListProps {
  onSetNotification: (data?: SystemNotificationInterface) => void
  selectedNotification?: SystemNotificationInterface
  hasDot?: boolean
}

export const NotificationsList = ({
  onSetNotification,
  selectedNotification,
  hasDot,
}: NotificationsListProps) => {
  const [currentGroups, setCurrentGroups] = useState<string[]>([])
  const [currentItemsByGroup, setCurrentItemsByGroup] = useState<
    SystemNotificationInterface[][]
  >([])

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const queryClient = useQueryClient()

  // TODO: https://revolut.atlassian.net/browse/REVCOR-2712 revert after infra is fixed
  const invalidateUnreadNotifications = () => {
    if (isCommercial) {
      queryClient.invalidateQueries(`${API.SYSTEM_NOTIFICATIONS}/unread`)
    }
  }

  const openActionUrl = useOpenNewTab()

  const sortBy = [
    {
      sortBy: 'creation_date',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<SystemNotificationInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getSystemNotifications(requestData),
    },
    undefined,
    sortBy,
  )

  const getGroup = (item: SystemNotificationInterface) => {
    const date = new Date(item.creation_date)
    return format(date, 'd MMMM yyyy')
  }

  const updateGroups = (items: SystemNotificationInterface[]) => {
    const groups: string[] = []
    const groupCounts: number[] = []
    const itemsByGroup: SystemNotificationInterface[][] = []
    for (const item of items) {
      const groupName = getGroup(item)
      /** push a group name to array if it doesn't exist (list is ordered so checking if it doesn't match latest is enough) */
      if (groups.at(-1) !== groupName) {
        groups.push(groupName)
      }
      /**
       * Increase count of items by one for current group.
       * If it's first item in a group then "groupCounts[groups.length - 1]" will be undefined, so we use 0 instead and add 1 to it.
       */
      groupCounts[groups.length - 1] = (groupCounts[groups.length - 1] || 0) + 1
      itemsByGroup[groups.length - 1] = itemsByGroup[groups.length - 1]
        ? [...itemsByGroup[groups.length - 1], item]
        : [item]
    }

    setCurrentItemsByGroup(itemsByGroup)
    setCurrentGroups(groups)
  }

  useEffect(() => {
    updateGroups(table.data)
  }, [table.data])

  const markAsRead = async (notification: SystemNotificationInterface) => {
    if (notification.status === 'read') {
      return
    }
    table.setData(
      produce(table.data, draft => {
        const row = draft.find(item => item.id === notification.id)
        if (row) {
          row.status = 'read'
        }
      }),
    )

    markNotificationAsRead(notification.id).finally(() => {
      invalidateUnreadNotifications()
    })
  }

  const dismiss = (notificationId: number) => {
    table.setData(table.data.filter(row => row.id !== notificationId))
    dismissNotification(notificationId)
      .catch(() => {
        table.refresh()
      })
      .finally(() => {
        invalidateUnreadNotifications()
      })
  }

  const handleClick = async (notification: SystemNotificationInterface) => {
    await markAsRead(notification)

    if (notification.action === NotificationAction.url && notification.action_url) {
      openActionUrl(notification.action_url)
      return
    }
    TopNav.closePopover()

    onSetNotification(notification)
  }

  const markAllAsRead = () => {
    table.setData(
      produce(table.data, draft => {
        draft.forEach(row => {
          row.status = 'read'
        })
      }),
    )

    markAllNotificationsAsRead()
      .catch(() => {
        table.refresh()
      })
      .finally(() => {
        invalidateUnreadNotifications()
      })
  }

  return (
    <>
      <TopNavWrapper
        showMarkAllAsRead={table.count > 0}
        onMarkAllAsRead={markAllAsRead}
        hasDot={hasDot}
      >
        <Refresher refresh={table.refresh} />

        {currentGroups.map((_, groupIndex) => {
          return (
            <React.Fragment key={groupIndex}>
              <GroupSubheader index={groupIndex} currentGroups={currentGroups} />
              {currentItemsByGroup[groupIndex].map(item => (
                <Box mx="-s-16" key={item.id}>
                  <NotificationsListItem
                    notification={item}
                    onNotificationClick={() => handleClick(item)}
                    onDismiss={dismiss}
                    markAsRead={markAsRead}
                  />
                </Box>
              ))}
            </React.Fragment>
          )
        })}

        {table.loading && <ItemSkeleton data-testid="item-skeleton" />}
        {!table.loading && table.count === 0 && (
          <Item useIcon="InfoOutline" mx="-s-16">
            <Item.Content>
              <Item.Title color="grey-tone-50">No notifications</Item.Title>
            </Item.Content>
          </Item>
        )}
      </TopNavWrapper>

      <Popup open={!!selectedNotification} size="md" onClose={() => onSetNotification()}>
        <Header variant="compact">
          <Header.CloseButton onClick={() => onSetNotification()} />
          <Header.Title>{selectedNotification?.title}</Header.Title>
        </Header>

        {selectedNotification ? (
          <EditorView html={selectedNotification.description} />
        ) : null}
      </Popup>
    </>
  )
}

interface TopNavWrapperProps {
  showMarkAllAsRead: boolean
  onMarkAllAsRead: () => void
  hasDot?: boolean
}

const TopNavWrapper: React.FC<TopNavWrapperProps> = ({
  showMarkAllAsRead,
  onMarkAllAsRead,
  children,
  hasDot,
}) => {
  return (
    <TopNav.Notifications
      action={
        showMarkAllAsRead && (
          <Popover.Action onClick={onMarkAllAsRead}>Mark all as read</Popover.Action>
        )
      }
      hasDot={hasDot}
    >
      {children}
    </TopNav.Notifications>
  )
}

interface GroupSubheaderProps {
  index: number
  currentGroups: string[]
}

const GroupSubheader = ({ index, currentGroups }: GroupSubheaderProps) => (
  <Box pt={index === 0 ? 0 : 's-16'}>
    <Subheader variant="nested">
      <Subheader.Title>{currentGroups[index]}</Subheader.Title>
    </Subheader>
  </Box>
)

interface RefresherProps {
  refresh: () => void
}

const Refresher = ({ refresh }: RefresherProps) => {
  useEffect(() => {
    refresh()
  }, [])

  return null
}
