import uniq from 'lodash/uniq'

import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  createCustomFieldColumn,
  employeesAccessGroupColumn,
  employeesAccessLevelColumn,
  employeesCountryColumn,
  employeesDepartmentColumn,
  employeesNameColumn,
  employeesEmailColumn,
  employeesEntityColumn,
  employeesFirstNameColumn,
  employeesFunctionalManagerColumn,
  employeesJobTitleColumn,
  employeesLastNameColumn,
  employeesLineManagerColumn,
  employeesLocationColumn,
  employeesMiddleNameColumn,
  employeesPreferredNameColumn,
  employeesRoleColumn,
  employeesSeniorityColumn,
  employeesStartDateColumn,
  employeesTeamColumn,
  employeesContractTypeColumn,
  employeesPersonalEmailColumn,
  employeesPhoneCountryCodeColumn,
  employeesPhoneNumberShortColumn,
  employeesAddressLine1Column,
  employeesAddressLine2Column,
  employeesAddressLine3Column,
  employeesEmergencyContactEmailColumn,
  employeesEmergencyContactFullNameColumn,
  employeesEmergencyContactRelationshipColumn,
  employeesEmergencyContactMobilePhoneColumn,
  employeesEmergencyContactPhoneCountryCodeColumn,
  employeesEmergencyContactPhoneNumberColumn,
  employeesLegalSexColumn,
  employeesMaritalStatusColumn,
  employeesBankNameColumn,
  employeesBankAccountNameColumn,
  employeesBankAccountCurrencyColumn,
  employeesBirthDateColumn,
  employeesLanguagesColumn,
  employeesNationalitiesColumn,
  employeesChangesEffectiveDateColumn,
} from '@src/constants/columns/employeesV2'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'
import { supportedEmployeeCustomFieldsTypes } from '@src/features/EditableEmployeesTable/common/constants'

export const getRow =
  (showEmployeeNameColumn?: boolean) =>
  (
    onCreate: CreateCallback,
    mode: EditableTableRenderMode,
    customFields: CustomFieldsForSectionInterface[] = [],
  ) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
    cells: [
      mode !== 'bulkSession' &&
        showEmployeeNameColumn && {
          ...employeesNameColumn(options.onChange),
          width: 200,
        },
      {
        ...employeesEmailColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesPersonalEmailColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesFirstNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesMiddleNameColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesLastNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesPreferredNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesContractTypeColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesTeamColumn(
          options.onChange,
          onChangeAction => onCreate('team', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesRoleColumn(
          options.onChange,
          onChangeAction => onCreate('role', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesSeniorityColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesLineManagerColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesFunctionalManagerColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesDepartmentColumn(
          options.onChange,
          onChangeAction => onCreate('department', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesEntityColumn(
          options.onChange,
          onChangeAction => onCreate('companyEntity', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesLocationColumn(
          options.onChange,
          onChangeAction => onCreate('location', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesBirthDateColumn(options.onChange, mode),
        width: 145,
      },
      {
        ...employeesLegalSexColumn(options.onChange, mode),
        width: 110,
      },
      {
        ...employeesMaritalStatusColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesCountryColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesLanguagesColumn(options.onChange, mode),
        width: 250,
      },
      {
        ...employeesNationalitiesColumn(options.onChange, mode),
        width: 250,
      },
      {
        ...employeesPhoneCountryCodeColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesPhoneNumberShortColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesAddressLine1Column(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesAddressLine2Column(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesAddressLine3Column(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesBankNameColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesBankAccountNameColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesBankAccountCurrencyColumn(options.onChange, mode),
        width: 110,
      },
      {
        ...employeesEmergencyContactEmailColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesEmergencyContactFullNameColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesEmergencyContactRelationshipColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesEmergencyContactMobilePhoneColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesEmergencyContactPhoneCountryCodeColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesEmergencyContactPhoneNumberColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesJobTitleColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesStartDateColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...(mode === 'bulkSession'
          ? employeesAccessLevelColumn(options.onChange)
          : employeesAccessGroupColumn(options.onChange)),
        width: 200,
      },
      {
        ...employeesChangesEffectiveDateColumn(options.onChange, mode),
        width: 200,
      },
      ...customFields.map(cf => {
        const isSupported = supportedEmployeeCustomFieldsTypes.includes(cf.input_type.id)

        if (!isSupported) {
          return null
        }
        const customFieldColumn = createCustomFieldColumn(cf)
        return {
          ...customFieldColumn(options.onChange),
          width: 200,
        }
      }),
    ].filter(Boolean),
  })

export const mandatoryBulkSessionFields = ['email']
export const optionalBulkSessionFields = uniq([
  ...baseFieldsForBulkEdit,
  'first_name',
  'last_name',
  'line_manager',
  'location',
  'access_level',
  'middle_name',
  'preferred_name',
  'functional_manager',
  'department',
  'entity',
  'country',
  'languages',
  'nationalities',
  'job_title',
  'start_date',
  'contract_type',
  'birth_date',
  'legal_sex',
  'marital_status',
  'personal_email',
  'phone_country_code',
  'phone_number_short',
  'address_line_1',
  'address_line_2',
  'address_line_3',
  'bank_name',
  'account_name',
  'bank_currency',
  'emergency_contact_email',
  'emergency_contact_full_name',
  'emergency_contact_relationship',
  'emergency_contact_mobile_phone',
  'emergency_contact_phone_country_code',
  'emergency_contact_phone_number',
])

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  location: ['location'],
  line_manager: ['line_manager'],
}
