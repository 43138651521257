import { Color } from '@revolut/ui-kit'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import Cookies from 'js-cookie'
import { COOKIE } from '@src/constants/api'
import { getTenantSubdomain, isDev } from '@src/utils/index'
import { useCurrentTenantInfo } from '@src/api/tenants'
import { CycleOption } from '@src/interfaces/selectors'

export const getReviewCycleStatusColor = (
  status?: ReviewCycleStatus,
  options?: { showClosedAsGrey?: boolean },
): Color => {
  switch (status) {
    case 'ongoing':
      return Color.GREEN
    case 'planned':
      return Color.WARNING
    case 'closed':
      return options?.showClosedAsGrey ? Color.GREY_TONE_50 : Color.RED
    default:
      return Color.FOREGROUND
  }
}

export const reviewCycleStatusNames = {
  [ReviewCycleStatus.ongoing]: 'ongoing',
  [ReviewCycleStatus.planned]: 'future',
  [ReviewCycleStatus.closed]: 'closed',
}

export const isEmployeeCycle = (id: number | string) => String(id).startsWith('E-')

// in several places BE requires cycle_id without E- prefix because it’s an old API, and they have to fit a new url to an old list
// in some cases it’s easy to do, but somewhere it requires ugly changes, so backend accepts only integer part
export const getReviewCycleIdWithoutPrefix = (id?: number | string) => {
  return String(id)?.replace('E-', '')
}

export const hasTestCycleCookie = () => {
  const workspace = getTenantSubdomain('hasTestCycleCookie')

  if (workspace && Cookies.get(`${COOKIE.SANDBOX_MODE_ENABLED}_${workspace}`)) {
    return Cookies.get(`${COOKIE.SANDBOX_MODE_ENABLED}_${workspace}`) === 'True'
  }

  return Cookies.get(COOKIE.SANDBOX_MODE_ENABLED) === 'True'
}

export const useGetCanEnableTestCycleSandbox = () => {
  const testCycleCookie = hasTestCycleCookie()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const { isDemoMode } = useCurrentTenantInfo()

  if (isDemoMode) {
    return false
  }

  // changing cycle stages is also working in DEV(selected stage is being applied for ongoing cycle),
  // but enabling playground for test cycle is only available for tenants
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canEnableInCommercial =
    isCommercial &&
    permissions.includes(PermissionTypes.CanEnableSandboxMode) &&
    featureFlags?.includes(FeatureFlags.TenantSandboxMode)
  const canEnableInDevCommercial =
    isDev() &&
    isCommercial &&
    permissions.includes(PermissionTypes.CanEnableSandboxMode) &&
    featureFlags?.includes(FeatureFlags.TenantSandboxMode)

  return testCycleCookie || canEnableInCommercial || canEnableInDevCommercial
}

export const isFutureScheduledCycleOption = ({
  is_test,
  is_adhoc,
  offset,
}: CycleOption) => !is_test && !is_adhoc && (offset ?? 0) < 0
