import React from 'react'
import { SingleTimelineEventReviewInterface } from '@src/interfaces/performance'
import { getAboveBelowSuggestedGrades } from '@src/pages/Forms/CommonTalentTab/utils'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import { isObject } from 'lodash'
import { VStack, HStack, Text, Icon, Flex, Avatar, Token, Spacer } from '@revolut/ui-kit'
import { getInitials } from '@src/utils/employees'
import { relationToString } from '@components/ScorecardGeneral/constants'

export const CalibratedTooltipBody = ({
  item,
}: {
  item: SingleTimelineEventReviewInterface | GradedRequestInterface
}) => {
  if (!isObject(item.grade)) {
    return null
  }
  const attitude = getAboveBelowSuggestedGrades(item.grade.id, item.recommended_grade?.id)
  return (
    <VStack width={400} p="s-16" color={Token.color.foreground} gap="s-8">
      <HStack gap="s-8">
        <VStack>
          <Text variant="emphasis1">{item.reviewer?.full_name}</Text>
          <Text color={Token.color.greyTone50}>
            {'is_delegated' in item && item.is_delegated
              ? 'Calibrator'
              : relationToString(item.reviewer_relation, false)}
          </Text>
        </VStack>
        <Spacer />

        <Flex alignItems="flex-end" flexDirection="column">
          <HStack align="center" gap="s-4">
            {attitude !== 'neutral' && (
              <Icon
                name={attitude === 'above' ? 'ArrowUp' : 'ArrowDown'}
                size={16}
                color={attitude === 'above' ? Token.color.success : Token.color.red}
              />
            )}
            <Text variant="emphasis1">{item.grade.label}</Text>
          </HStack>
          {attitude !== 'neutral' && (
            <Text color={Token.color.greyTone50}>
              Calibrated {attitude === 'above' ? 'up' : 'down'}
            </Text>
          )}
        </Flex>

        <Avatar
          style={{ gridRow: '1 / 3' }}
          image={item.reviewer?.avatar}
          label={getInitials(item.reviewer?.full_name || '')}
        />
      </HStack>
      {item.grade_justification && (
        <>
          <Text variant="emphasis3" color={Token.color.greyTone50}>
            Justification
          </Text>
          <Text use="p">{item.grade_justification}</Text>
        </>
      )}
      <Text color={Token.color.greyTone50}>
        Note: Suggestions from the Heads of Departments are not final, and the aggregated
        input is subject to review by the Performance Review Committee (PRC).
      </Text>
    </VStack>
  )
}
