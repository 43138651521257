import React from 'react'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { OptionInterface } from '@src/interfaces/selectors'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { useCreateOptionInNewTab } from '@src/utils/useCreateOptionInNewTab'

type LapeScorecardFieldProps = {
  canAddNewScorecard?: boolean
  isClearable: boolean
  isDisabled?: boolean
  isOnboarding?: boolean
}

const LapeScorecardField = ({
  canAddNewScorecard = false,
  isClearable,
  isDisabled,
  isOnboarding = false,
}: LapeScorecardFieldProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const { openNewTab } = useCreateOptionInNewTab(
    LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD,
    {
      ref: isOnboarding ? 'onboarding' : 'fill-scorecard-after-submit',
      route: ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS,
      selector: selectorKeys.interview_scorecard_templates,
      onLoad: template => {
        if (template) {
          values.scorecard_template = template
        }
      },
    },
  )
  return (
    <LapeRadioSelectInput<OptionInterface>
      disabled={isDisabled}
      name="scorecard_template"
      required
      label="Scorecard"
      clearable={isClearable}
      selector={selectorKeys.interview_scorecard_templates}
      useQuery
      useQueryOptions={{
        refetchOnWindowFocus: true,
      }}
      showCreateNewButton={canAddNewScorecard}
      onChange={option => {
        if (option) {
          if (option?.id === createNewKey) {
            openNewTab()
          } else {
            values.scorecard_template = option
          }
        }
      }}
      referenceUrl={
        values.scorecard_template?.id
          ? getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
                id: values.scorecard_template.id,
              }),
            )
          : undefined
      }
    />
  )
}

export default LapeScorecardField
