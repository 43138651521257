import React, { useContext } from 'react'
import { createContext, useMemo, useState } from 'react'
import ConfirmationDialog, {
  ConfirmationDialogInterface,
} from '@src/features/Popups/ConfirmationDialog'

type DialogProps = Omit<ConfirmationDialogInterface, 'open' | 'onClose'> & {
  noReject?: boolean
}

export interface ConfirmationDialogContextValue {
  show: (dialogProps: DialogProps) => void
  hide: () => void
}

type ConfirmationDialogProviderProps = {
  children: React.ReactNode
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContextValue | null>(
  null,
)

export function ConfirmationDialogProvider({
  children,
}: ConfirmationDialogProviderProps) {
  const [loading, setLoading] = useState(false)
  const [dialogProps, setDialogProps] = useState<DialogProps>()

  const value = useMemo<ConfirmationDialogContextValue>(
    () => ({
      show: props => {
        setDialogProps(props)
      },
      hide: () => {
        setDialogProps(undefined)
      },
    }),
    [],
  )

  return (
    <ConfirmationDialogContext.Provider value={value}>
      {children}
      {dialogProps && (
        <ConfirmationDialog
          {...dialogProps}
          loading={dialogProps.loading || loading}
          onConfirm={async () => {
            setLoading(true)

            try {
              await dialogProps.onConfirm?.()
              value.hide()
            } finally {
              setLoading(false)
            }
          }}
          submitDisabled={dialogProps.submitDisabled || loading}
          open
          onReject={
            dialogProps.noReject
              ? undefined
              : () => {
                  dialogProps.onReject?.()
                  value.hide()
                }
          }
          onClose={value.hide}
        />
      )}
    </ConfirmationDialogContext.Provider>
  )
}

export const useConfirmationDialog = () => {
  const value = useContext(ConfirmationDialogContext)

  if (value === null) {
    throw new Error(
      'You can access context only in components inside `ConfirmationDialogProvider` component',
    )
  }

  return value
}
