import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'
import { ROUTES } from '@src/constants/routes'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { IdAndName } from '@src/interfaces'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { ScoreType, ViewType } from '../types'
import { ResultCategories } from './ResultCategories'
import { ResultComments } from './ResultComments'
import { ResultQuestions } from './ResultQuestions'

interface Props {
  view: ViewType
  scoreType: ScoreType
  selectedRound?: IdAndName
  surveyId: number
  entity?: { type: EngagementResultsScope; id: number }
  canSeeComments: boolean
}
export const ResultsTabContent = ({ selectedRound, ...rest }: Props) => {
  if (!selectedRound) {
    return <TableBodySkeleton rowHeight="large" />
  }
  return (
    <Switch>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.COMMENTS,
          ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.COMMENTS,
          ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.COMMENTS,
          ROUTES.FORMS.TEAM.ENGAGEMENT.COMMENTS,
          ROUTES.FORMS.ROLE.ENGAGEMENT.COMMENTS,
          ROUTES.FORMS.FUNCTION.ENGAGEMENT.COMMENTS,
          ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.COMMENTS,
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.COMMENTS,
          ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.COMMENTS,
        ]}
      >
        {rest.canSeeComments ? (
          <ResultComments {...rest} selectedRound={selectedRound} />
        ) : (
          <NoAccessError />
        )}
      </Route>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.QUESTIONS,
          ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.QUESTIONS,
          ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.QUESTIONS,
          ROUTES.FORMS.TEAM.ENGAGEMENT.QUESTIONS,
          ROUTES.FORMS.ROLE.ENGAGEMENT.QUESTIONS,
          ROUTES.FORMS.FUNCTION.ENGAGEMENT.QUESTIONS,
          ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.QUESTIONS,
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.QUESTIONS,
          ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.QUESTIONS,
        ]}
      >
        <ResultQuestions {...rest} selectedRound={selectedRound} />
      </Route>
      <Route>
        <ResultCategories {...rest} selectedRound={selectedRound} />
      </Route>
    </Switch>
  )
}
