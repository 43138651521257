import React from 'react'
import { LinkProps } from 'react-router-dom'
import { CellTypes } from '@src/interfaces/data'
import { StyledLink } from '@components/Atoms/StyledLink'
import styled from 'styled-components'

const StyledTableLink = styled(StyledLink)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export interface TableCellLinkProps extends LinkProps {
  cellType?: CellTypes
}

const TableCellLink = ({
  cellType = CellTypes.text,
  children,
  ...props
}: TableCellLinkProps) => {
  return (
    <StyledTableLink
      onClick={e => {
        if (e.altKey && cellType === CellTypes.text) {
          e.preventDefault()
        }
        e.stopPropagation()
      }}
      {...props}
    >
      {children}
    </StyledTableLink>
  )
}

export default TableCellLink
