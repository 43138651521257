import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, StatusPopup, Text } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { GoalsInterface } from '@src/interfaces/goals'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { getGoalContentTypeLabel } from '@src/pages/Forms/GoalForm/helpers'

interface Props {
  submitValues: GoalsInterface
  handlePreviewGoal: VoidFunction
}

export const useSubmitStatusPopup = ({ submitValues, handlePreviewGoal }: Props) => {
  const showStatusPopup = useShowStatusPopup()

  const showSuccessPopup = (afterSubmitValues: GoalsInterface) => {
    const isFirstSubmit =
      (!submitValues.approval_status ||
        submitValues.approval_status.id === ApprovalStatuses.Draft) &&
      afterSubmitValues.approval_status.id !== ApprovalStatuses.Draft
    const { owner, is_auto_cascaded_to_owner: isAutoCascaded } = afterSubmitValues

    const contentTypeLabel = getGoalContentTypeLabel(afterSubmitValues)

    return showStatusPopup({
      status: isFirstSubmit ? 'success-result' : 'success',
      title: isFirstSubmit ? (
        <FormattedMessage
          id="goals.created.success.title"
          values={{ label: contentTypeLabel }}
          defaultMessage="New {label} goal was created"
        />
      ) : (
        <FormattedMessage
          id="goals.updated.success.title"
          defaultMessage="The goal was updated"
        />
      ),
      description:
        isFirstSubmit && isAutoCascaded ? (
          <FormattedMessage
            id="goals.created.success.description"
            values={{ ownerName: <Text fontWeight={700}>{owner.name}</Text> }}
            defaultMessage="The goal was auto-cascaded to the goal owner {ownerName}"
          />
        ) : undefined,
      actions: isFirstSubmit
        ? statusPopup => (
            <StatusPopup.Actions>
              <Button
                onClick={() => {
                  handlePreviewGoal()
                  statusPopup.hide()
                }}
                use="a"
              >
                <FormattedMessage
                  id="goals.created.success.preview.action.label"
                  defaultMessage="See goal"
                />
              </Button>
            </StatusPopup.Actions>
          )
        : undefined,
      onClose: statusPopup => (isFirstSubmit ? statusPopup.hide() : handlePreviewGoal()),
    })
  }

  return { showSuccessPopup }
}
