import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/HelpSections'
import { ManagementBehavioursHelp } from './ManagementBehavioursHelp'
import { Link, Text } from '@revolut/ui-kit'
import { HELP_SKILLS_LINK, HELP_PERF_REVIEW_PLAYBOOK } from '@src/constants/externalLinks'

interface Props {
  isManagerBehaviour?: boolean
  isCommercial?: boolean
}

const SkillsHelp = ({ isManagerBehaviour, isCommercial }: Props) => {
  if (isManagerBehaviour) {
    return <ManagementBehavioursHelp />
  }

  const sections: React.ReactNode[] = [
    `The review scorecard has been generated based on the competency matrix of the employee’s specialisation. You can see the expected ratings in the summary details.`,
    `Skills are assessed by reviewing the criteria for each rating level, and confirming which ones the employee has demonstrated competency in.`,
    `Criteria for higher rating levels only appear once all of the lower levels are confirmed. Start from Basic and work up.`,
    `If a selected rating is different from the expectations or recommendation for an employee, you will need to add a comment to justify your review.`,
  ]

  if (!isCommercial) {
    sections.push(
      <Text>
        Please review the{' '}
        <Link target="_blank" href={HELP_PERF_REVIEW_PLAYBOOK} textDecoration="underline">
          Performance Assessment playbook
        </Link>{' '}
        for further guidance. You can read about how to review skills{' '}
        <Link target="_blank" href={HELP_SKILLS_LINK} textDecoration="underline">
          here
        </Link>
        .
      </Text>,
    )
  }
  return <HelpSections sections={sections} />
}

export default SkillsHelp
