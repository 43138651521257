import { ParsableError } from '@src/features/Errors/parseError'

export const getRequestId = (error?: ParsableError) =>
  error?.response?.headers?.['request-id']

export const getTechnicalErrorMessage = (error?: ParsableError) => {
  const requestId = getRequestId(error)

  return `${requestId ? `${requestId}\n` : ''}${JSON.stringify(
    error?.response?.data,
    null,
    2,
  )}`
}
